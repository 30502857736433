import React from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import { array, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccordionItem, Checkbox, Field, Form, FormSection, IconButton, Notification, Text, } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
const validationSchema = object({
    permissions: array().of(string()).required(),
});
export const SchedulePermissionListItem = (props) => {
    const { schedulePermissionRef } = props;
    const data = useFragment(graphql `
      fragment SchedulePermissionListItem_schedulePermission on SchedulePermission {
        id
        permissions
        role {
          id
          name
        }
        schedule {
          currentUserPermissions
        }
        scheduleId
        user {
          id
          firstname
          lastname
        }
      }
    `, schedulePermissionRef);
    const { schedulePermissionGroups } = useLazyLoadQuery(graphql `
      query SchedulePermissionListItemQuery {
        schedulePermissionGroups {
          name
          description
          permissions {
            name
            value
          }
        }
      }
    `, {});
    const [deleteSchedulePermissionMutation, deleteSchedulePermissionMutationLoading] = useMutation(graphql `
        mutation SchedulePermissionListItem_deleteSchedulePermissionMutation($model: DeleteSchedulePermissionInput!) {
          deleteSchedulePermission(model: $model) {
            schedulePermissionEdge {
              node {
                id
              }
            }
          }
        }
      `);
    const { control, handleSubmit, register, reset } = useForm({
        defaultValues: {
            permissions: data.permissions,
        },
        resolver: yupResolver(validationSchema),
    });
    const [updateSchedulePermissionMutation, updateSchedulePermissionMutationLoading] = useMutation(graphql `
        mutation SchedulePermissionListItem_updateSchedulePermissionMutation($model: UpdateSchedulePermissionInput!) {
          updateSchedulePermission(model: $model) {
            schedulePermissionEdge {
              node {
                id
                permissions
                ...SchedulePermissionListItem_schedulePermission
              }
            }
          }
        }
      `);
    return (<AccordionItem actions={<IconButton appearance="text" disabled={deleteSchedulePermissionMutationLoading} icon={faTimes} onClick={() => {
                deleteSchedulePermissionMutation({
                    configs: [
                        {
                            connectionKeys: [
                                {
                                    key: 'SchedulePermissionList_schedule_permissions',
                                },
                            ],
                            parentID: data.scheduleId,
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['schedulePermissionEdge', 'node'],
                            pathToConnection: [data.scheduleId, 'permissions'],
                        },
                    ],
                    variables: {
                        model: {
                            id: data.id,
                        },
                    },
                });
            }} size="small"/>} size="medium" title={data.user ? (<Text>
            {data.user.firstname} {data.user.lastname}
          </Text>) : data.role ? (<Text>{data.role.name} (grupp)</Text>) : null}>
      <Form style={{ padding: '0.5rem 1rem' }}>
        {schedulePermissionGroups.map((group, index) => (<React.Fragment key={`schedulePermissionGroup_${index}`}>
            <FormSection columns="1fr 1fr" key={group.name} title={group.name}>
              {group.permissions.map((permission) => (<Field direction="row-reverse" key={permission.value} label={permission.name}>
                  <Controller control={control} name="permissions" render={({ field }) => (<Checkbox disabled={updateSchedulePermissionMutationLoading} defaultChecked={data.permissions.includes(permission.value)} onChange={(event) => {
                        if (event.target.checked) {
                            field.onChange([...field.value, permission.value]);
                        }
                        else {
                            field.onChange([...field.value].filter((p) => p !== permission.value));
                        }
                        handleSubmit((form) => {
                            updateSchedulePermissionMutation({
                                onCompleted: () => {
                                    reset(form);
                                },
                                variables: {
                                    model: {
                                        permissions: form.permissions,
                                        id: data.id,
                                    },
                                },
                            });
                        })();
                    }} value={permission.value}/>)}/>
                </Field>))}
            </FormSection>
            {group.description && <Notification color={sonikaTheme.colors.system.wait} title={group.description}/>}
          </React.Fragment>))}
      </Form>
    </AccordionItem>);
};
export default SchedulePermissionListItem;
