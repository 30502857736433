/**
 * @generated SignedSource<<4d5592a204212b01f1840b229d5dbca5>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "DeleteScheduleShiftPayload",
            "kind": "LinkedField",
            "name": "deleteScheduleShiftDuring",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfScheduleShift",
                    "kind": "LinkedField",
                    "name": "shiftEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShift",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "end",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "start",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesDeleteShiftsWizard_deleteScheduleShiftDuringMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RunningSchedulesDeleteShiftsWizard_deleteScheduleShiftDuringMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "4d21d85e9ecab4b92a098093164db2a9",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesDeleteShiftsWizard_deleteScheduleShiftDuringMutation",
            "operationKind": "mutation",
            "text": "mutation RunningSchedulesDeleteShiftsWizard_deleteScheduleShiftDuringMutation(\n  $model: DeleteScheduleShiftDuringRequestInput!\n) {\n  deleteScheduleShiftDuring(model: $model) {\n    shiftEdge {\n      node {\n        id\n        end\n        start\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "a2035b3e55b91ce4d4c4e94af0d43db3";
export default node;
