/**
 * @generated SignedSource<<499fc93a5d93bfee28fa9bc652c28258>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "where"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftVacancyNotifyModalForm_queryQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftVacancyNotifyModalForm_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleShiftVacancyNotifyModalForm_queryQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "firstname",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastname",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "0717869b54069d377ad4f0b1a9275594",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftVacancyNotifyModalForm_queryQuery",
            "operationKind": "query",
            "text": "query ScheduleShiftVacancyNotifyModalForm_queryQuery(\n  $where: UserFilterInput\n) {\n  ...ScheduleShiftVacancyNotifyModalForm_query_3FC4Qo\n}\n\nfragment ScheduleShiftVacancyNotifyModalForm_query_3FC4Qo on Query {\n  users(where: $where) {\n    nodes {\n      id\n      firstname\n      lastname\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "7a374d0d96e049115fec87d0bd143f6f";
export default node;
