import React, { useContext } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import RunningSchedulesPageContext from '../../../RunningSchedulesPageContext';
import { FormattedMessage } from 'react-intl';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        assignmentTypeName: {
            backgroundColor: '#FFF',
            marginLeft: pxToRem(-8),
            marginTop: pxToRem(-1),
            color: '#6A6A6A',
            fontSize: pxToRem(12),
            padding: `${pxToRem(0)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(10)}`,
            position: 'sticky',
            textAlign: 'left',
            zIndex: 1,
            top: pxToRem(40),
            whiteSpace: 'noWrap',
        },
        assignmentTypeNameFirst: {
            marginLeft: 0,
            paddingLeft: pxToRem(8),
        },
        scheduleName: {
            backgroundColor: '#FFF',
            fontSize: pxToRem(14),
            zIndex: 1,
            fontWeight: 600,
            padding: `${pxToRem(16)} ${pxToRem(8)} ${pxToRem(10)} ${pxToRem(8)}`,
            position: 'sticky',
            textAlign: 'left',
            top: 0,
            whiteSpace: 'nowrap',
            '-webkit-text-size-adjust': '100%',
            '-moz-text-size-adjust': '100%',
            '-ms-text-size-adjust': '100%',
            'text-size-adjust': '100%',
        },
    };
});
export const RunningSchedulesGridHeader = (props) => {
    const { schedule } = props;
    const { filters } = useContext(RunningSchedulesPageContext);
    const { hideEmptySchedules } = filters;
    const classes = useStyles();
    const data = useFragment(graphql `
      fragment RunningSchedulesGridHeader_schedule on Schedule
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int" }
        fromDate: { type: "DateTime!" }
        toDate: { type: "DateTime!" }
        where: { type: "ScheduleShiftFilterInput" }
        shiftsFilter: { type: "ScheduleShiftsFilterInput" }
      ) {
        id
        runningSchedule(
          after: $after
          first: $first
          model: { fromDate: $fromDate, toDate: $toDate }
          where: $where
          filter: $shiftsFilter
        ) @connection(key: "RunningSchedulesGridHeader_runningSchedule", filters: []) {
          edges {
            node {
              id
              assignmentTypeId
              assignmentType {
                id
                title
              }
            }
          }
        }
        id
        name
      }
    `, schedule);
    const assignmentTypesDict = data.runningSchedule.edges.reduce((prev, cur) => {
        return Object.assign(Object.assign({}, prev), { [cur.node.assignmentType.id]: cur.node.assignmentType.title });
    }, {});
    const assignmentTypes = Object.keys(assignmentTypesDict)
        .map((key) => {
        return [key, assignmentTypesDict[key]];
    })
        .sort(function (first, second) {
        return 0 - (second[1] > first[1] ? 1 : -1);
    });
    if (assignmentTypes.length === 0 && hideEmptySchedules) {
        return null;
    }
    return (<>
      <div className={classes.scheduleName} style={{
            gridColumn: `auto / span ${assignmentTypes.length || 1}`,
            gridRow: 1,
        }}>
        {data.name}
      </div>
      {assignmentTypes.map((assignmentType, assignmentTypeIndex) => (<div className={[classes.assignmentTypeName, assignmentTypeIndex === 0 && classes.assignmentTypeNameFirst].join(' ')} key={`${data.name}_${assignmentType[0]}_${assignmentType[1]}`} style={{
                gridColumn: 'auto',
                gridRow: 2,
            }}>
          {assignmentType[1]}
        </div>))}
      {assignmentTypes.length === 0 && (<div className={[classes.assignmentTypeName, classes.assignmentTypeNameFirst].join(' ')} style={{
                gridColumn: 'auto',
                gridRow: 2,
            }}>
          <span style={{ fontStyle: 'italic' }}>
            <FormattedMessage defaultMessage="Schemat är tomt" id="RunningSchedulesGridHeader.empty_schedule"/>
          </span>
        </div>)}
    </>);
};
export default RunningSchedulesGridHeader;
