var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Avatar } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
const Asset = (props) => {
    const { parent, onClick } = props, rest = __rest(props, ["parent", "onClick"]);
    const { name, color } = useFragment(graphql `
      fragment Asset on Asset {
        name
        color
      }
    `, parent);
    return (<>
      <Avatar {...rest} text={name} color={color || '#0C2AF3'} onClick={onClick}/>
    </>);
};
export default Asset;
