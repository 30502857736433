import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, array, mixed } from 'yup';
import { FieldList, Form, FormFooter, FormSection, Input, Field, Checkbox, MultiSelect, Textarea, Select2, } from '@sonika-se/ui-kit/components';
import { useLazyLoadQuery, graphql } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import enums from '~/enums.json';
import EventField from '~/components/EventField';
export const calcOffsetInMinutes = (dayIndex, time) => {
    var _a;
    const [hours, minutes] = (_a = time.split(':')) === null || _a === void 0 ? void 0 : _a.map((value) => +value);
    return dayIndex * 24 * 60 + hours * 60 + minutes;
};
const validationSchema = object({
    assignmentTypeId: string().required('Du måste ange en roll'),
    endTimeOfDay: string().required('Du måste ange sluttid'),
    startTimeOfDay: string().required('Du måste ange starttid'),
    eventId: string().required('Du måste ange en händelse'),
    description: string(),
    assignedEntityId: string().nullable(),
    breaks: array()
        .of(object().shape({
        offsetEnd: string().matches(/[0-9][0-9]:[0-9][0-9]/),
        offsetStart: string().matches(/[0-9][0-9]:[0-9][0-9]/),
        type: mixed().oneOf(Object.keys(enums.BreakEnum)),
    }))
        .notRequired(),
});
export const ScheduleTemplateShiftForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { actions, defaultValues, disabled, onClose, onSubmit, scheduleTemplateId, assignmentTypeId } = props;
    const intl = useIntl();
    const scheduleShiftBreakType = enums.BreakEnum;
    const { scheduleTemplate } = useLazyLoadQuery(graphql `
      query ScheduleTemplateShiftFormQuery($scheduleTemplateId: ID!) {
        scheduleTemplate(id: $scheduleTemplateId) {
          id
          periodLength
          periods(order: { order: ASC }) {
            nodes {
              id
              name
            }
          }
          schedule {
            assignmentTypes {
              nodes {
                id
                assignmentType {
                  id
                  title
                }
                resources {
                  nodes {
                    schedulableEntity {
                      ... on ISchedulableEntity {
                        id
                        displayName
                      }
                    }
                  }
                }
              }
            }
          }
          startsOnWeekday
        }
      }
    `, {
        scheduleTemplateId,
    });
    const { control, formState: { errors }, handleSubmit, setValue, } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
    const findSchedulableEntityByAssignmentTypeId = (assignmentTypeId) => {
        var _a, _b, _c;
        return ((_c = (_b = (_a = scheduleTemplate.schedule.assignmentTypes.nodes
            .find((assignmentType) => { var _a; return ((_a = assignmentType.assignmentType) === null || _a === void 0 ? void 0 : _a.id) === assignmentTypeId; })) === null || _a === void 0 ? void 0 : _a.resources) === null || _b === void 0 ? void 0 : _b.nodes) === null || _c === void 0 ? void 0 : _c.map((resource) => resource === null || resource === void 0 ? void 0 : resource.schedulableEntity)) || [];
    };
    const [selectedAssignedEntityId, setSelectedAssignedEntityId] = useState(defaultValues.assignedEntityId);
    const [selectedAssignmentTypeId, setSelectedAssignmentTypeId] = useState(defaultValues.assignmentTypeId);
    useEffect(() => {
        //setValue('assignedEntityId', null)
        const schedulableEntities = findSchedulableEntityByAssignmentTypeId(selectedAssignmentTypeId);
        if (!(schedulableEntities === null || schedulableEntities === void 0 ? void 0 : schedulableEntities.map((entity) => entity.id).includes(selectedAssignedEntityId))) {
            setValue('assignedEntityId', null);
        }
    }, [selectedAssignmentTypeId]);
    const [hasBreak, setHasBreak] = useState(!!defaultValues.breaks);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
      <FormSection columns="1fr 1fr">
        <Field error={(_a = errors.startTimeOfDay) === null || _a === void 0 ? void 0 : _a.message.toString()} label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.start.label',
            defaultMessage: 'Starttid',
        })}>
          <Controller control={control} name="startTimeOfDay" render={({ field }) => (<Input type="time" disabled={disabled} onChange={field.onChange} value={field.value}/>)}/>
        </Field>
        <Field error={(_b = errors.endTimeOfDay) === null || _b === void 0 ? void 0 : _b.message.toString()} label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.end.label',
            defaultMessage: 'Sluttid',
        })}>
          <Controller control={control} name="endTimeOfDay" render={({ field }) => (<Input type="time" disabled={disabled} onChange={field.onChange} value={field.value}/>)}/>
        </Field>
        <Field label={intl.formatMessage({
            defaultMessage: 'Passet innehåller raster',
            id: 'general.misc.schedule_shift.fields.contains_breaks.placeholder',
        })} direction="row-reverse" columnSpan={2}>
          <Checkbox inputName="hasBreak" disabled={disabled} onChange={(e) => setHasBreak(e.target.checked)} checked={hasBreak}/>
        </Field>
      </FormSection>
      {hasBreak && (<FormSection>
          <FieldList appendText={intl.formatMessage({
                defaultMessage: 'Lägg till rast',
                id: 'general.misc.breaks.actions.create',
            })} compact control={control} disabled={disabled} name="breaks" label={intl.formatMessage({
                defaultMessage: 'Raster',
                id: 'general.misc.breaks.name.plural',
            })} render={(field, index) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                return (<FormSection columns="1fr 1fr 1fr" key={field.id}>
                <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_c = (_b = (_a = errors.breaks[index]) === null || _a === void 0 ? void 0 : _a.offsetStart) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.toString())}>
                  <Controller control={control} name={`breaks.${index}.offsetStart`} render={({ field }) => (<Input value={field.value} type="time" disabled={disabled} onChange={field.onChange}/>)}/>
                </Field>
                <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_f = (_e = (_d = errors.breaks[index]) === null || _d === void 0 ? void 0 : _d.offsetEnd) === null || _e === void 0 ? void 0 : _e.message) === null || _f === void 0 ? void 0 : _f.toString())}>
                  <Controller control={control} name={`breaks.${index}.offsetEnd`} render={({ field }) => (<Input disabled={disabled} type="time" onChange={field.onChange} value={field.value}/>)}/>
                </Field>
                <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_h = (_g = errors.breaks[index]) === null || _g === void 0 ? void 0 : _g.type) === null || _h === void 0 ? void 0 : _h.toString())}>
                  <Controller control={control} name={`breaks.${index}.type`} render={({ field }) => {
                        var _a;
                        return (<MultiSelect disabled={disabled} onChange={(value) => field.onChange(value ? value[0] : undefined)} options={(_a = Object.keys(scheduleShiftBreakType)) === null || _a === void 0 ? void 0 : _a.map((key) => {
                                var _a;
                                return ({
                                    label: intl.formatMessage({
                                        id: `BreakEnum_${key}`,
                                        defaultMessage: (_a = scheduleShiftBreakType[key]) === null || _a === void 0 ? void 0 : _a.description,
                                    }),
                                    value: key,
                                });
                            })} placeholder={intl.formatMessage({
                                defaultMessage: 'Välj typ',
                                id: 'general.misc.breaks.fields.type.placeholder',
                            })} defaultValue={field.value}/>);
                    }}/>
                </Field>
              </FormSection>);
            }}/>
        </FormSection>)}
      <FormSection columns="1fr 1fr">
        <Field error={(_c = errors.description) === null || _c === void 0 ? void 0 : _c.message.toString()} label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.description.label',
            defaultMessage: 'Beskrivning',
        })} columnSpan={2}>
          <Controller control={control} name="description" render={({ field }) => (<Textarea placeholder={intl.formatMessage({
                id: 'general.misc.schedule_shift.fields.description.placehodler',
                defaultMessage: 'Lägg till beskrivning',
            })} disabled={disabled} onChange={(event) => field.onChange(event.target.value)} value={field.value}/>)}/>
        </Field>
        <Field error={(_e = (_d = errors === null || errors === void 0 ? void 0 : errors.assignmentTypeId) === null || _d === void 0 ? void 0 : _d.message) === null || _e === void 0 ? void 0 : _e.toString()} label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.assignment_type_id.label',
            defaultMessage: 'Välj roll',
        })}>
          <Controller control={control} name="assignmentTypeId" render={({ field }) => {
            var _a;
            return (<MultiSelect disabled={disabled} defaultValue={field.value} onChange={(option) => {
                    field.onChange(option ? option[0] : undefined);
                    setSelectedAssignmentTypeId(option ? option[0] : undefined);
                    setSelectedAssignedEntityId(undefined);
                }} placeholder={intl.formatMessage({
                    id: 'general.misc.schedule_shift.fields.assignment_type_id.placeholder',
                    defaultMessage: 'Välj en roll',
                })} options={(_a = scheduleTemplate.schedule.assignmentTypes.nodes) === null || _a === void 0 ? void 0 : _a.map((assignmentType) => ({
                    label: assignmentType.assignmentType.title,
                    value: assignmentType.assignmentType.id,
                }))}/>);
        }}/>
        </Field>
        <Field error={(_g = (_f = errors === null || errors === void 0 ? void 0 : errors.eventId) === null || _f === void 0 ? void 0 : _f.message) === null || _g === void 0 ? void 0 : _g.toString()} label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.schedule_event_id.label',
            defaultMessage: 'Händelse',
        })}>
          <Controller control={control} name="eventId" render={({ field }) => {
            var _a, _b;
            return (<EventField assignmentTypeId={selectedAssignmentTypeId} where={{ isWork: { eq: true } }} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.eventId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} multiple={false} defaultValue={field.value} disabled={disabled} onChange={(value) => field.onChange(value ? value[0] : undefined)} placeholder={intl.formatMessage({
                    id: 'general.misc.schedule_shift.fields.schedule_event_id.placeholder',
                    defaultMessage: 'Välj händelse',
                })}/>);
        }}/>
        </Field>
        <Field error={(_j = (_h = errors === null || errors === void 0 ? void 0 : errors.assignedEntityId) === null || _h === void 0 ? void 0 : _h.message) === null || _j === void 0 ? void 0 : _j.toString()} label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.assigned_entity_id.label',
            defaultMessage: 'Välj anställd/Enhet',
        })}>
          <Controller control={control} name="assignedEntityId" render={({ field }) => {
            var _a;
            return (<Select2 defaultValue={selectedAssignedEntityId} disabled={disabled} placeholder={intl.formatMessage({
                    id: 'general.misc.schedule_shift.fields.assigned_entity_id.placeholder',
                    defaultMessage: 'Välj resurs',
                })} onChange={({ single }) => {
                    field.onChange(single);
                    setSelectedAssignedEntityId(single);
                }} options={[
                    {
                        label: intl.formatMessage({
                            defaultMessage: 'Vakant',
                            id: 'general.misc.schedule_shift.vacant.singular',
                        }),
                        value: undefined,
                    },
                    ...(selectedAssignmentTypeId
                        ? (_a = findSchedulableEntityByAssignmentTypeId(selectedAssignmentTypeId)) === null || _a === void 0 ? void 0 : _a.map((entity) => ({
                            value: entity.id,
                            label: entity.displayName,
                        }))
                        : []),
                ]}/>);
        }}/>
        </Field>
        <Field></Field>
      </FormSection>
      <FormFooter disabled={disabled} onCancel={() => {
            onClose();
        }} submitText={intl.formatMessage({
            id: 'general.actions.save',
            defaultMessage: 'Spara',
        })}>
        {actions}
      </FormFooter>
    </Form>);
};
