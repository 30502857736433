/**
 * @generated SignedSource<<005005592cddb5ff9b5cb83bc830fc6e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v3 = [
        (v2 /*: any*/),
        (v1 /*: any*/)
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "assignmentTypesFilter"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "model"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "schedulesFilter"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./MyScheduleContent_shiftsQuery.graphql'),
                "identifierField": "id"
            }
        },
        "name": "MyScheduleContent_shifts",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "assignmentTypesFilter",
                        "variableName": "assignmentTypesFilter"
                    },
                    {
                        "kind": "Variable",
                        "name": "model",
                        "variableName": "model"
                    },
                    {
                        "kind": "Variable",
                        "name": "schedulesFilter",
                        "variableName": "schedulesFilter"
                    }
                ],
                "concreteType": "ICanBeUsedToCalculateScheduleTimesUnionTypeConnection",
                "kind": "LinkedField",
                "name": "scheduleItems",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ICanBeUsedToCalculateScheduleTimesUnionTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v0 /*: any*/)
                                        ],
                                        "type": "Node",
                                        "abstractKey": "__isNode"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v1 /*: any*/),
                                            (v2 /*: any*/)
                                        ],
                                        "type": "PlannedAbsence",
                                        "abstractKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": (v3 /*: any*/),
                                        "type": "ScheduleShift",
                                        "abstractKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": (v3 /*: any*/),
                                        "type": "ScheduleShiftAssigneeReplacement",
                                        "abstractKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "PlannedAbsenceCard_plannedAbsence"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleShiftCard_scheduleShift"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleShiftAbsenceCard_scheduleShiftAssigneeReplacement"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v0 /*: any*/)
        ],
        "type": "SchedulableUser",
        "abstractKey": null
    };
})();
node.hash = "1512c5014765cf3b903d338c9cf10c13";
export default node;
