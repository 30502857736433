/**
 * @generated SignedSource<<a5f116343672b76ea1b78c6369bbad3a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleId"
        }
    ], v1 = [
        {
            "kind": "Literal",
            "name": "where",
            "value": {
                "disabledDate": {
                    "eq": null
                }
            }
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "scheduleId"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditAssignmentTypesOnScheduleModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AssignmentTypeConnection",
                    "kind": "LinkedField",
                    "name": "assignmentTypes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentType",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "AssignmentTypeOnScheduleSwitch_assignmentType"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "assignmentTypes(where:{\"disabledDate\":{\"eq\":null}})"
                },
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditAssignmentTypesOnScheduleModal_schedule"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditAssignmentTypesOnScheduleModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AssignmentTypeConnection",
                    "kind": "LinkedField",
                    "name": "assignmentTypes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentType",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "assignmentTypes(where:{\"disabledDate\":{\"eq\":null}})"
                },
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleAssignmentTypeConnection",
                            "kind": "LinkedField",
                            "name": "assignmentTypes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleAssignmentType",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "assignmentTypeId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                            "kind": "LinkedField",
                                            "name": "resources",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalCount",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "4bafde9d3f0a602a40e9fc64c372df4e",
            "id": null,
            "metadata": {},
            "name": "EditAssignmentTypesOnScheduleModalQuery",
            "operationKind": "query",
            "text": "query EditAssignmentTypesOnScheduleModalQuery(\n  $scheduleId: ID!\n) {\n  assignmentTypes(where: {disabledDate: {eq: null}}) {\n    nodes {\n      id\n      ...AssignmentTypeOnScheduleSwitch_assignmentType\n    }\n  }\n  schedule(id: $scheduleId) {\n    id\n    ...EditAssignmentTypesOnScheduleModal_schedule\n  }\n}\n\nfragment AssignmentTypeOnScheduleSwitch_assignmentType on AssignmentType {\n  id\n  title\n}\n\nfragment EditAssignmentTypesOnScheduleModal_schedule on Schedule {\n  assignmentTypes {\n    nodes {\n      assignmentTypeId\n      resources {\n        totalCount\n      }\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "ffaa3e856b9d780254a9904a54d419be";
export default node;
