/**
 * @generated SignedSource<<aeb92ef26422632c14b36ecd495d3a8d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "model"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "schedulesFilter"
    }, v3 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v4 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        },
        {
            "kind": "Variable",
            "name": "schedulesFilter",
            "variableName": "schedulesFilter"
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "event",
        "plural": false,
        "selections": [
            (v5 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unit",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "MyScheduleInformationDrawerContent_shiftsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v4 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "MyScheduleInformationDrawerContent_shifts"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "MyScheduleInformationDrawerContent_shiftsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v5 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "ScheduleTimesPayload",
                                    "kind": "LinkedField",
                                    "name": "scheduleTimes",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                                                    "kind": "LinkedField",
                                                    "name": "timesByEntityAndAssignmentType",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "CalculateTimePartsPayload",
                                                            "kind": "LinkedField",
                                                            "name": "calculatedTimes",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": "employmentSummaries",
                                                                    "args": null,
                                                                    "concreteType": "CalculateTimePartsSummaryByEmployment",
                                                                    "kind": "LinkedField",
                                                                    "name": "summaryByEmployment",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Employment",
                                                                            "kind": "LinkedField",
                                                                            "name": "employment",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "EmploymentSetting",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "employmentSetting",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "kind": "ScalarField",
                                                                                            "name": "title",
                                                                                            "storageKey": null
                                                                                        },
                                                                                        (v5 /*: any*/)
                                                                                    ],
                                                                                    "storageKey": null
                                                                                },
                                                                                (v5 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": "eventSummaries",
                                                                            "args": null,
                                                                            "concreteType": "TimePartsSummary",
                                                                            "kind": "LinkedField",
                                                                            "name": "calculatedSummary",
                                                                            "plural": true,
                                                                            "selections": [
                                                                                (v6 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "isWork",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "TimeSpecificationPartSummary",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "nonRegularPartsSummary",
                                                                                    "plural": true,
                                                                                    "selections": [
                                                                                        (v6 /*: any*/),
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": [
                                                                                                {
                                                                                                    "kind": "Literal",
                                                                                                    "name": "unit",
                                                                                                    "value": "SECOND"
                                                                                                }
                                                                                            ],
                                                                                            "concreteType": "TimeSpanUnitValue",
                                                                                            "kind": "LinkedField",
                                                                                            "name": "duration",
                                                                                            "plural": false,
                                                                                            "selections": [
                                                                                                (v7 /*: any*/),
                                                                                                (v8 /*: any*/)
                                                                                            ],
                                                                                            "storageKey": "duration(unit:\"SECOND\")"
                                                                                        }
                                                                                    ],
                                                                                    "storageKey": null
                                                                                },
                                                                                (v8 /*: any*/),
                                                                                (v7 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "IHaveScheduleTimes",
                                            "abstractKey": "__isIHaveScheduleTimes"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "SchedulableUser",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "08db150947a67f5d8da887c3a3f58024",
            "id": null,
            "metadata": {},
            "name": "MyScheduleInformationDrawerContent_shiftsQuery",
            "operationKind": "query",
            "text": "query MyScheduleInformationDrawerContent_shiftsQuery(\n  $model: GetRunningScheduleRequestInput!\n  $schedulesFilter: [ID!]\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...MyScheduleInformationDrawerContent_shifts_hZDLF\n    id\n  }\n}\n\nfragment MyScheduleInformationDrawerContent_shifts_hZDLF on SchedulableUser {\n  scheduleTimes(model: $model, schedulesFilter: $schedulesFilter) {\n    ...ScheduleTimesPayload_iHaveScheduleTimes\n  }\n  id\n}\n\nfragment ScheduleTimesPayload_iHaveScheduleTimes on IHaveScheduleTimes {\n  __isIHaveScheduleTimes: __typename\n  timesByEntityAndAssignmentType {\n    calculatedTimes {\n      employmentSummaries: summaryByEmployment {\n        employment {\n          employmentSetting {\n            title\n            id\n          }\n          id\n        }\n        eventSummaries: calculatedSummary {\n          event {\n            id\n            name\n          }\n          isWork\n          nonRegularPartsSummary {\n            event {\n              id\n              name\n            }\n            duration(unit: SECOND) {\n              value\n              unit\n            }\n          }\n          unit\n          value\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "62229b3c7f3eac9f2e932996d18311d5";
export default node;
