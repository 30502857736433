import React, { useContext } from 'react';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { faEye as faEyeSolid } from '@fortawesome/pro-solid-svg-icons';
import { IconButton, Flexbox, Tooltip, Switch, Label, Checkbox, Button, Divider, PopoverToSheet, PopoverToSheetHeader, PopoverToSheetContent, PopoverToSheetFooter, Badge, Placeholder, } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import { graphql, useFragment } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import AbsenceCalendarPageContext from '../AbsenceCalendarPageContext';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles((theme) => ({
    container: {
        padding: '10px 16px',
        margin: 0,
        borderBottom: '1px solid #ccc',
    },
    smallHeader: {
        color: theme.colors.foreground.tertiary,
    },
    resourceCheckbox: {
        marginRight: 10,
        cursor: 'pointer',
    },
    icon: {
        color: theme.colors.primary,
    },
}));
const AbsenceCalendarFilter = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { updateFilters, queryRef } = props;
    const intl = useIntl();
    const data = useFragment(graphql `
      fragment AbsenceCalendarFilter_options on Query {
        assignmentTypes(where: { disabledDate: { eq: null } }) {
          edges {
            node {
              id
              title
            }
          }
        }
        events(where: { isAbsence: { eq: true } }) {
          edges {
            node {
              name
              id
            }
          }
        }
      }
    `, queryRef);
    const classes = useStyles();
    const { hideEmptyResources, absenceReasons, assignmentTypes } = useContext(AbsenceCalendarPageContext).filters;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { size: pageSize } = useViewport();
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const nrOfFilters = (hideEmptyResources ? 1 : 0) + (absenceReasons === null || absenceReasons === void 0 ? void 0 : absenceReasons.length) + (assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.length);
    return (<>
      <Tooltip content={intl.formatMessage({
            id: 'AbsenceCalendarFilter.title',
            defaultMessage: 'Visningsalternativ',
        })}>
        <Badge text={`${nrOfFilters}`} appearance={nrOfFilters ? 'danger' : 'none'}>
          <IconButton appearance={open ? 'filled' : 'outlined'} onClick={handleClick} icon={open ? faEyeSolid : faEye} size={pageSize === 'small' ? 'small' : 'medium'}/>
        </Badge>
      </Tooltip>
      <PopoverToSheet size="auto" style={{ minWidth: '20.5rem' }} anchorEl={anchorEl} onClose={handleClose} height="70vh">
        <PopoverToSheetHeader onClose={handleClose} closeButtonLabel={intl.formatMessage({ id: 'general.actions.close', defaultMessage: 'Stäng' })}>
          <FormattedMessage id="AbsenceCalendarFilter.title" defaultMessage="Visningsalternativ"/>
        </PopoverToSheetHeader>
        <PopoverToSheetContent>
          <Flexbox direction="column" gap="medium">
            <Flexbox justify="space" align="center">
              <Label text={intl.formatMessage({
            defaultMessage: 'Typer av frånvaro',
            id: 'AbsenceCalendarFilter.filters.type.title',
        })} className={classes.smallHeader}/>
              {!!(absenceReasons === null || absenceReasons === void 0 ? void 0 : absenceReasons.length) && (<Button appearance="text" size="small" style={{ padding: 0 }} onClick={() => {
                updateFilters({
                    absenceReasons: [],
                });
            }}>
                  <FormattedMessage id="general.actions.clear" defaultMessage="Rensa"/>
                </Button>)}
            </Flexbox>
            {!((_b = (_a = data === null || data === void 0 ? void 0 : data.events) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length) && <Placeholder text="Det finngs inga typer av frånvaro"/>}
            {(_d = (_c = data === null || data === void 0 ? void 0 : data.events) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(({ node }) => {
            return (<Flexbox key={`assigneeChangeReason_checkbox_${node.id}`} gap="xsmall" align="center">
                  <Checkbox id={`assigneeChangeReason_${node.id}`} checked={!!absenceReasons.find((id) => id === node.id)} className={classes.resourceCheckbox} onChange={(event) => {
                    if (!event.target.checked) {
                        updateFilters({
                            absenceReasons: absenceReasons.filter((id) => id !== node.id),
                        });
                    }
                    else {
                        updateFilters({
                            absenceReasons: [...absenceReasons, node === null || node === void 0 ? void 0 : node.id],
                        });
                    }
                }}/>
                  <Label style={{ fontSize: '1rem' }} text={node.name} htmlFor={`assigneeChangeReason_checkbox_${node.id}`}/>
                </Flexbox>);
        })}
            <Divider />
            <Flexbox justify="space" align="center">
              <Label text={intl.formatMessage({
            defaultMessage: 'Visa frånvaro för',
            id: 'AbsenceCalendarFilter.filters.assignmentType.title',
        })} className={classes.smallHeader}/>
              {!!(assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.length) && (<Button appearance="text" size="small" style={{ padding: 0 }} onClick={() => {
                updateFilters({
                    assignmentTypes: [],
                });
            }}>
                  <FormattedMessage id="general.actions.clear" defaultMessage="Rensa"/>
                </Button>)}
            </Flexbox>
            {!((_e = data.assignmentTypes.edges) === null || _e === void 0 ? void 0 : _e.length) && <Placeholder text="Det finngs inga roller"/>}
            {(_f = data.assignmentTypes.edges) === null || _f === void 0 ? void 0 : _f.map(({ node }) => {
            return (<Flexbox key={`assignmentType_checkbox_${node.id}`} gap="xsmall" align="center">
                  <Checkbox id={`assignmentType_${node.id}`} checked={!!assignmentTypes.find((checkedId) => checkedId === node.id)} className={classes.resourceCheckbox} onChange={(event) => {
                    if (!event.target.checked) {
                        updateFilters({
                            assignmentTypes: assignmentTypes.filter((checkedValue) => checkedValue !== node.id),
                        });
                    }
                    else {
                        updateFilters({ assignmentTypes: [...assignmentTypes, node.id] });
                    }
                }}/>
                  <Label style={{ fontSize: '1rem' }} text={node.title} htmlFor={`assignmentType_checkbox_${node.id}`}/>
                </Flexbox>);
        })}
          </Flexbox>
        </PopoverToSheetContent>
        <PopoverToSheetFooter>
          <Flexbox direction="column">
            <div style={{ marginBottom: '.25rem' }}>
              <Switch label={intl.formatMessage({
            id: 'AbsenceCalendarFilter.hide_empty_resources',
            defaultMessage: 'Visa endast användare med frånvaro',
        })} defaultChecked={hideEmptyResources} onChange={(event) => updateFilters({ hideEmptyResources: event.target.checked })}/>
            </div>
          </Flexbox>
        </PopoverToSheetFooter>
      </PopoverToSheet>
    </>);
};
export default AbsenceCalendarFilter;
