import React, { Suspense, useMemo } from 'react';
import { Chip, Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import ScheduleGeneralInfoUpdateModal, { scheduleScheduleUpdateModalQuery, } from '~/modals/ScheduleGeneralInfoUpdateModal';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
export const ScheduleGeneralInfoPanel = (props) => {
    const { scheduleRef } = props;
    const schedule = useFragment(graphql `
      fragment ScheduleGeneralInfoPanel_schedule on Schedule {
        archived
        inactivatedFrom
        currentUserPermissions
        id
        name
      }
    `, scheduleRef);
    const [scheduleGeneralInfoUpdateModalRef, loadScheduleGeneralInfoUpdateModal, disposeScheduleGeneralInfoUpdateModal] = useQueryLoader(scheduleScheduleUpdateModalQuery);
    const canEditSchedules = usePermissions()(['EDIT_SCHEDULES']);
    const canEditInformation = useMemo(() => canEditSchedules || schedule.currentUserPermissions.includes('EDIT_INFORMATION'), [canEditSchedules, schedule]);
    return (<>
      <Panel actions={canEditInformation && (<PanelButton onClick={() => loadScheduleGeneralInfoUpdateModal({ scheduleId: schedule.id })}>
              Ändra
            </PanelButton>)} title="Info">
        <Flexbox direction="column" gap="xlarge">
          <KeyValue label="Namn" value={schedule.name}/>
          <KeyValue label="Dolt i översikt">
            <div>
              {schedule.archived && <Chip color={sonikaTheme.colors.system.stop} size="xsmall" text="Ja"/>}
              {!schedule.archived && <Chip color={sonikaTheme.colors.system.go} size="xsmall" text="Nej"/>}
            </div>
          </KeyValue>
          {schedule.inactivatedFrom && (<KeyValue label="Inaktivt sedan">
              <div>
                <Chip color={sonikaTheme.colors.system.stop} size="xsmall" text={`${formatDate(toDate(schedule.inactivatedFrom), 'dateTime')}`}/>
              </div>
            </KeyValue>)}
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {scheduleGeneralInfoUpdateModalRef && (<ScheduleGeneralInfoUpdateModal onClose={() => {
                disposeScheduleGeneralInfoUpdateModal();
            }} preloadedQuery={scheduleGeneralInfoUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleGeneralInfoPanel;
