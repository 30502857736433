import React, { useContext, useEffect, useRef, useState, Suspense } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { Flexbox, ModalToSheetSkeleton, Tooltip } from '@sonika-se/ui-kit/components';
import { ScheduleTemplateShift } from '../ScheduleTemplateShift/ScheduleTemplateShift';
import { ScheduleTemplateDetailsPageContext } from '../../ScheduleTemplateDetailsPageContext';
import NewScheduleTemplateShiftModal from '../NewScheduleTemplateShiftModal/NewScheduleTemplateShiftModal';
import enums from '~/enums.json';
import { isEmptyObject } from '~/utils/isEmptyObject';
import { formatDuration } from '@sonika-se/ui-kit/utils/time';
import ScheduleTemplateShiftAssetValidationGroup from '~/components/ScheduleTemplateShiftAssetValidationGroup';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            background: '#F2F4F6',
            borderRadius: pxToRem(4),
            padding: pxToRem(8),
        },
        assignmentTypes: {
            display: 'flex',
            '& > *': {
                width: pxToRem(192),
            },
            '& > *:not(:first-child)': {
                marginLeft: pxToRem(16),
            },
            marginTop: pxToRem(4),
        },
        assignmentTypeTitle: {
            color: '#6A6A6A',
            fontSize: pxToRem(12),
            marginBottom: pxToRem(8),
            marginTop: pxToRem(4),
        },
        shift: {
            marginTop: pxToRem(4),
        },
        weekday: {
            fontSize: pxToRem(14),
            fontWeight: 600,
            minWidth: pxToRem(192),
            '&:hover': {
                color: theme.colors.primary,
                textDecoration: 'underline',
                cursor: 'pointer',
            },
        },
        time: {
            color: '#484848',
            fontSize: pxToRem(10),
            fontWeight: 600,
            marginTop: '.25rem',
            cursor: 'default',
        },
    };
});
export const ScheduleTemplatePeriodDay = (props) => {
    var _a;
    const classes = useStyles();
    const context = useContext(ScheduleTemplateDetailsPageContext);
    const { assignmentTypeIds, onlyVacant, resourceIds } = context.filters;
    const weekday = enums.Weekday;
    const [day, refetch] = useRefetchableFragment(graphql `
      fragment ScheduleTemplatePeriodDay_day on ScheduleTemplatePeriodDay
      @argumentDefinitions(filter: { type: "ScheduleTemplateShiftFilterRequestInput" })
      @refetchable(queryName: "ScheduleTemplatePeriodDayQuery") {
        id
        dayInPeriod
        weekday
        period {
          id
          scheduleTemplateId
        }
        groupedShifts(filter: $filter) {
          group {
            assignmentType {
              id
              title
            }
          }
          shiftsGroups {
            group {
              startTimeOfDay
              endTimeOfDay
              duration(unit: MINUTE) {
                value
              }
              event {
                id
                name
              }
            }
            shifts {
              id
              duration(unit: HOUR) {
                value
              }
              assignmentType {
                id
                title
              }
              breaks {
                offsetEnd(unit: MINUTE) {
                  value
                }
                offsetStart(unit: MINUTE) {
                  value
                }
                type
              }
              endTimeOfDay
              startTimeOfDay
              ...ScheduleTemplateShift_shift
            }
            ...ScheduleTemplateShiftAssetValidationGroup_scheduleTemplateShift
          }
        }
      }
    `, props.day);
    const isInitialMount = useRef(true);
    const [newScheduleTemplatePeriodShiftProps, setNewScheduleTemplatePeriodShiftProps] = useState();
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        const where = {};
        if (assignmentTypeIds.length) {
            where.assignmentTypeIds = assignmentTypeIds;
        }
        if (resourceIds.length) {
            where.assignedResources = resourceIds;
        }
        if (onlyVacant) {
            where.vacant = true;
        }
        refetch({
            filter: isEmptyObject(where) ? undefined : where,
        });
    }, [assignmentTypeIds, onlyVacant, resourceIds]);
    return (<>
      <div className={classes.root}>
        <p className={classes.weekday} onClick={() => setNewScheduleTemplatePeriodShiftProps({
            initialPeriodId: day.period.id,
            initialDayInPeriod: day.dayInPeriod,
        })}>
          <FormattedMessage defaultMessage={(_a = weekday[day.weekday]) === null || _a === void 0 ? void 0 : _a.description} id={`Weekday_${day.weekday}`}/>
        </p>
        <div className={classes.assignmentTypes}>
          {day.groupedShifts.map((group, index) => (<div key={`group-${index}`}>
              <p className={classes.assignmentTypeTitle}>{group.group.assignmentType.title}</p>
              {group.shiftsGroups.map((shiftGroup, shiftGroupIndex) => (<React.Fragment key={`shiftGroup-${shiftGroupIndex}`}>
                  <Flexbox direction="row" justify="space" style={{ flex: 1 }}>
                    <Tooltip placement="right" content={`${formatDuration({
                    hours: shiftGroup.group.duration.value,
                })}`}>
                      <p className={classes.time}>
                        {shiftGroup.group.startTimeOfDay}-{shiftGroup.group.endTimeOfDay}
                      </p>
                    </Tooltip>
                    <span>
                      <ScheduleTemplateShiftAssetValidationGroup parent={shiftGroup}></ScheduleTemplateShiftAssetValidationGroup>
                    </span>
                  </Flexbox>
                  {shiftGroup.shifts.map((shift) => (<div key={shift.id}>
                      <ScheduleTemplateShift className={classes.shift} key={shift.id} shift={shift} updateHighlightedResources={props.updateHighlightedResources}/>
                    </div>))}
                </React.Fragment>))}
            </div>))}
        </div>
      </div>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
        {newScheduleTemplatePeriodShiftProps && (<NewScheduleTemplateShiftModal onCancel={() => setNewScheduleTemplatePeriodShiftProps(null)} onSubmit={() => {
                setNewScheduleTemplatePeriodShiftProps(null);
            }} scheduleTemplateId={day.period.scheduleTemplateId} {...newScheduleTemplatePeriodShiftProps}/>)}
      </Suspense>
    </>);
};
export default ScheduleTemplatePeriodDay;
