import React from 'react';
import { Button, Divider, Flexbox, H6, ModalToSheetContent, ModalToSheetHeader, ModalToSheet, Text, } from '@sonika-se/ui-kit/components';
import { graphql } from 'react-relay';
import { usePreloadedQuery } from 'react-relay/hooks';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import User from './User/User';
import { MySchedulePaginationContent } from '~/pages/SchedulePages/MySchedule/components/MySchedulePaginationContent/MySchedulePaginationContent';
export const colleagueModalQuery = graphql `
  query ColleagueModalQuery($id: ID!, $myScheduleModel: GetRunningScheduleRequestInput!) {
    user(id: $id) {
      id
      email
      firstname
      lastname
      phone
      ...User
      schedulableEntity {
        id
        ... on SchedulableUser {
          ...MySchedulePaginationContent_shifts @arguments(model: $myScheduleModel)
        }
      }
    }
  }
`;
export const ColleagueModal = (props) => {
    const { onClose, preloadedQuery, fromDate, toDate } = props;
    const { user } = usePreloadedQuery(colleagueModalQuery, preloadedQuery);
    return (<>
      <ModalToSheet onClose={onClose}>
        <ModalToSheetHeader onClose={onClose} closeButtonLabel="Stäng">
          Visa kollega
        </ModalToSheetHeader>
        <ModalToSheetContent>
          <Flexbox direction="column" gap="large">
            <Flexbox align="center" gap="xsmall">
              <User parent={user} showAvatarLightBox/>
              <Flexbox direction="column">
                <Text>{`${user.firstname} ${user.lastname}`}</Text>
              </Flexbox>
            </Flexbox>
            {(user.email || user.phone) && (<Flexbox align="start" direction="column" gap="medium">
                {user.email && (<a href={`mailto:${user.email}`}>
                    <Button appearance="text" padding={false} icon={faEnvelope} iconProps={{ fixedWidth: true }}>
                      {user.email}
                    </Button>
                  </a>)}
                {user.phone && (<a href={`tel:${user.phone}`}>
                    <Button appearance="text" padding={false} iconProps={{ fixedWidth: true }}>
                      {user.phone}
                    </Button>
                  </a>)}
              </Flexbox>)}
            {!!user.schedulableEntity && (<Flexbox direction="column" gap="xsmall">
                <H6>Kommmande pass</H6>
                <Divider />
                <div>
                  <MySchedulePaginationContent fromDate={fromDate} toDate={toDate} scheduleShifts={user === null || user === void 0 ? void 0 : user.schedulableEntity}/>
                </div>
              </Flexbox>)}
          </Flexbox>
        </ModalToSheetContent>
      </ModalToSheet>
    </>);
};
export default ColleagueModal;
