import React, { Suspense, useContext, useMemo } from 'react';
import { usePermissions, useToasts } from '@sonika-se/ui-kit/hooks';
import { useFragment, graphql, useMutation, useQueryLoader } from 'react-relay/hooks';
import { Button, Flexbox, Input, ModalToSheetSkeleton, IconButton, Tooltip } from '@sonika-se/ui-kit/components';
import { faInfo } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationTriangle, faInfo as faInfoSolid } from '@fortawesome/pro-solid-svg-icons';
import { createUseStyles } from 'react-jss';
import { FormattedMessage, useIntl } from 'react-intl';
import PublishScheduleModal, { publishScheduleModalQuery } from '../PublishScheduleModal/PublishScheduleModal';
import ScheduleTemplateAssignmentTypeFilter from '../ScheduleTemplateAssignmentTypeFilter/ScheduleTemplateAssignmentTypeFilter';
import ScheduleTemplateResourceFilter from '../ScheduleTemplateResourceFilter/ScheduleTemplateResourceFilter';
import ScheduleTemplateDetailsPageContext from '../../ScheduleTemplateDetailsPageContext';
import ScheduleTemplateShiftClashes from '../ScheduleTemplateShiftClashes/ScheduleTemplateShiftClashes';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        header: {
            display: 'flex',
        },
        scheduleName: {
            alignItems: 'center',
            backgroundColor: '#F2F4F6',
            border: `${pxToRem(1)} solid #D5D6D8`,
            borderRadius: `${pxToRem(2)} 0 0 ${pxToRem(2)}`,
            borderRight: 'none',
            whiteSpace: 'nowrap',
            display: 'flex',
            fontSize: '1rem',
            padding: '0 0.5rem',
        },
        templateName: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
    };
});
export const ScheduleTemplateHeader = (props) => {
    const { onToggleInformationDrawer, scheduleTemplate, updateFilters } = props;
    const { addToast } = useToasts();
    const classes = useStyles();
    const navigate = useNavigate();
    const [publishScheduleModalQueryRef, loadPublishScheduleModalQuery, disposePublishScheduleModalQuery] = useQueryLoader(publishScheduleModalQuery);
    const { showInformationDrawer } = useContext(ScheduleTemplateDetailsPageContext);
    const template = useFragment(graphql `
      fragment ScheduleTemplateHeader_scheduleTemplate on ScheduleTemplate {
        id
        name
        schedule {
          id
          currentUserPermissions
          name
          ...ScheduleTemplateAssignmentTypeFilter_assignmentTypes
          ...ScheduleTemplateResourceFilterList_resources
        }
        ...ScheduleTemplateShiftClashes_scheduleTemplate
      }
    `, scheduleTemplate);
    const intl = useIntl();
    const [updateTemplate, updateTemplateLoading] = useMutation(graphql `
    mutation ScheduleTemplateHeaderUpdateTemplateMutation($model: UpdateTemplateRequestInput!) {
      updateScheduleTemplate(model: $model) {
        templateEdge {
          node {
            id
            name
          }
        }
      }
    }
  `);
    const hasPermission = usePermissions();
    const canPublishTemplates = useMemo(() => hasPermission(['EDIT_SCHEDULES']) || template.schedule.currentUserPermissions.includes('PUBLISH_TEMPLATES'), [hasPermission, template]);
    return (<>
      <Flexbox gap="xsmall" justify="space">
        <Flexbox gap="xsmall">
          <div className={classes.header}>
            <p className={classes.scheduleName}>{template.schedule.name}</p>
            <Input className={classes.templateName} defaultValue={template.name} disabled={updateTemplateLoading} onBlur={(event) => {
            const { value } = event.target;
            if (value === (template === null || template === void 0 ? void 0 : template.name))
                return;
            updateTemplate({
                variables: {
                    model: {
                        name: value,
                        id: template.id,
                    },
                },
                onCompleted: () => {
                    addToast(intl.formatMessage({
                        id: 'ScheduleTemplateDetailsPage.ScheduleTemplateHeader.new_name_toast.success',
                        defaultMessage: 'Nytt namn på mall "{value}"',
                    }, { value }), { color: 'success' });
                },
            });
        }}/>
          </div>
        </Flexbox>
        <Flexbox gap="xsmall">
          <Suspense fallback={<IconButton color="danger" appearance="filled" disabled icon={faExclamationTriangle}/>}>
            <ScheduleTemplateShiftClashes scheduleTemplateRef={template}/>
          </Suspense>
          <div>
            <ScheduleTemplateResourceFilter scheduleId={template.schedule.id} scheduleTemplate={template.schedule} updateFilters={updateFilters} title={intl.formatMessage({
            id: 'general.objects.asset.name.plural',
            defaultMessage: 'Enheter',
        })} type="asset"/>
          </div>
          <div>
            <ScheduleTemplateResourceFilter scheduleId={template.schedule.id} scheduleTemplate={template.schedule} updateFilters={updateFilters} title={intl.formatMessage({
            id: 'general.objects.asset.user.plural',
            defaultMessage: 'Användare',
        })} type="user"/>
          </div>
          <ScheduleTemplateAssignmentTypeFilter scheduleTemplate={template.schedule} updateFilters={updateFilters}/>
          <Tooltip content={intl.formatMessage({
            defaultMessage: 'Schemainformation',
            id: 'general.misc.schedule_information.name.singular',
        })}>
            <IconButton appearance={showInformationDrawer ? 'filled' : 'outlined'} icon={showInformationDrawer ? faInfoSolid : faInfo} onClick={() => {
            onToggleInformationDrawer();
        }}/>
          </Tooltip>
          {canPublishTemplates && (<Button disabled={updateTemplateLoading} onClick={() => {
                loadPublishScheduleModalQuery({ scheduleTemplateId: template.id });
            }}>
              <FormattedMessage id="general.actions.publish" defaultMessage="Tillämpa"/>
            </Button>)}
          <Link to={`/schedules/${template.schedule.id}/templates`}>
            <Button type="button" appearance="outlined" disabled={updateTemplateLoading}>
              <FormattedMessage defaultMessage="Stäng" id="general.actions.close"/>
            </Button>
          </Link>
        </Flexbox>
      </Flexbox>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
        {publishScheduleModalQueryRef && (<PublishScheduleModal preloadedQuery={publishScheduleModalQueryRef} onCancel={() => disposePublishScheduleModalQuery()}/>)}
      </Suspense>
    </>);
};
export default ScheduleTemplateHeader;
