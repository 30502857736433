/**
 * @generated SignedSource<<1c4e04f1688bcb77db79c2f6537ce3ca>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "assignmentTypesFilter"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "entitiesFilter"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "fromDate"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "schedulesFilter"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "toDate"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "whereChangeRequest"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "assignmentTypesFilter",
            "variableName": "assignmentTypesFilter"
        },
        {
            "kind": "Variable",
            "name": "entitiesFilter",
            "variableName": "entitiesFilter"
        },
        {
            "fields": [
                {
                    "kind": "Variable",
                    "name": "fromDate",
                    "variableName": "fromDate"
                },
                {
                    "kind": "Variable",
                    "name": "toDate",
                    "variableName": "toDate"
                }
            ],
            "kind": "ObjectValue",
            "name": "model"
        },
        {
            "kind": "Variable",
            "name": "schedulesFilter",
            "variableName": "schedulesFilter"
        },
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "whereChangeRequest"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ChangeRequestDropdownQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleShiftChangeRequestConnection",
                    "kind": "LinkedField",
                    "name": "scheduleShiftChangeRequests",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShiftChangeRequestEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShiftChangeRequest",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "requestedBy",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "args": null,
                                                    "kind": "FragmentSpread",
                                                    "name": "User"
                                                },
                                                (v6 /*: any*/),
                                                (v7 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleShift",
                                            "kind": "LinkedField",
                                            "name": "scheduleShift",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v2 /*: any*/),
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AssignmentType",
                                                    "kind": "LinkedField",
                                                    "name": "assignmentType",
                                                    "plural": false,
                                                    "selections": [
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Schedule",
                                                    "kind": "LinkedField",
                                                    "name": "schedule",
                                                    "plural": false,
                                                    "selections": [
                                                        (v9 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ChangeRequestDropdownQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleShiftChangeRequestConnection",
                    "kind": "LinkedField",
                    "name": "scheduleShiftChangeRequests",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShiftChangeRequestEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShiftChangeRequest",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "requestedBy",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "color",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Avatar",
                                                    "kind": "LinkedField",
                                                    "name": "avatar",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "storageId",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": [
                                                                {
                                                                    "kind": "Literal",
                                                                    "name": "queryTransform",
                                                                    "value": "width=68&height=68&mode=crop&scale=both"
                                                                }
                                                            ],
                                                            "kind": "ScalarField",
                                                            "name": "url",
                                                            "storageKey": "url(queryTransform:\"width=68&height=68&mode=crop&scale=both\")"
                                                        },
                                                        {
                                                            "alias": "imageUrl",
                                                            "args": [
                                                                {
                                                                    "kind": "Literal",
                                                                    "name": "queryTransform",
                                                                    "value": "width=400&height=400&mode=crop&scale=both"
                                                                }
                                                            ],
                                                            "kind": "ScalarField",
                                                            "name": "url",
                                                            "storageKey": "url(queryTransform:\"width=400&height=400&mode=crop&scale=both\")"
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v7 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleShift",
                                            "kind": "LinkedField",
                                            "name": "scheduleShift",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v2 /*: any*/),
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AssignmentType",
                                                    "kind": "LinkedField",
                                                    "name": "assignmentType",
                                                    "plural": false,
                                                    "selections": [
                                                        (v8 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Schedule",
                                                    "kind": "LinkedField",
                                                    "name": "schedule",
                                                    "plural": false,
                                                    "selections": [
                                                        (v9 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "5f6a93e15395594a75b3a057475df386",
            "id": null,
            "metadata": {},
            "name": "ChangeRequestDropdownQuery",
            "operationKind": "query",
            "text": "query ChangeRequestDropdownQuery(\n  $assignmentTypesFilter: [ID!]\n  $entitiesFilter: ScheduleTemplateShiftFilterRequestInput\n  $fromDate: DateTime!\n  $schedulesFilter: [ID!]\n  $toDate: DateTime!\n  $whereChangeRequest: ScheduleShiftChangeRequestFilterInput\n) {\n  scheduleShiftChangeRequests(model: {fromDate: $fromDate, toDate: $toDate}, where: $whereChangeRequest, schedulesFilter: $schedulesFilter, entitiesFilter: $entitiesFilter, assignmentTypesFilter: $assignmentTypesFilter) {\n    edges {\n      node {\n        id\n        status\n        start\n        end\n        requestedBy {\n          id\n          ...User\n          firstname\n          lastname\n        }\n        scheduleShift {\n          start\n          id\n          end\n          assignmentType {\n            title\n            id\n          }\n          schedule {\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment User on User {\n  firstname\n  color\n  avatar {\n    id\n    storageId\n    url(queryTransform: \"width=68&height=68&mode=crop&scale=both\")\n    ...UserImageLightBox\n  }\n}\n\nfragment UserImageLightBox on Avatar {\n  imageUrl: url(queryTransform: \"width=400&height=400&mode=crop&scale=both\")\n}\n"
        }
    };
})();
node.hash = "db5dfaf413bbfb327825cf6bce641dbc";
export default node;
