import React, { Suspense, useContext, useMemo } from 'react';
import { Flexbox, MonthInput, IconButton, Tooltip, MultiSelect } from '@sonika-se/ui-kit/components';
import { faInfo, faUserClock } from '@fortawesome/pro-regular-svg-icons';
import { faInfo as faInfoSolid } from '@fortawesome/pro-solid-svg-icons';
import { useIntl } from 'react-intl';
import { graphql, useFragment, usePreloadedQuery } from 'react-relay/hooks';
import MySchedulePageContext from '../../MySchedulePageContext';
import MyScheduleFilter from '../MyScheduleFilter/MyScheduleFilter';
import ChangeRequestDropdown from '../../../components/ChangeRequestDropdown/ChangeRequestDropdown';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { mySchedulePageQuery } from '../../MySchedulePage';
export const MyScheduleHeader = (props) => {
    var _a, _b, _c;
    const { date, onDateChange, onToggleInformationDrawer, updateFilters, setRefetchGrid, preloadedQuery, selectedUserId, setNewUser, } = props;
    const hasPermissions = usePermissions();
    const data = usePreloadedQuery(mySchedulePageQuery, preloadedQuery);
    const { schedulableEntities } = useFragment(graphql `
      fragment MyScheduleHeader_query on Query {
        schedulableEntities(where: { schedulable: { eq: true } }) {
          edges {
            node {
              ... on SchedulableUser {
                id
                userId
                name
              }
            }
          }
        }
      }
    `, data);
    const intl = useIntl();
    const { showInformationDrawer, filters } = useContext(MySchedulePageContext);
    const changeRequestVariables = useMemo(() => {
        const { assignmentTypeIds, onlyVacant, resourceIds, scheduleIds } = filters;
        return {
            assignmentTypesFilter: assignmentTypeIds,
            entitiesFilter: {
                assignedResources: resourceIds,
                vacant: onlyVacant,
            },
            fromDate: date[0],
            schedulesFilter: scheduleIds,
            toDate: date[1],
            whereChangeRequest: {
                requestedByUserId: { in: [selectedUserId] },
            },
        };
    }, [filters, date, selectedUserId]);
    return (<Flexbox justify="space">
      <Flexbox gap="xsmall" justify="equal">
        <MonthInput defaultValue={date[0]} onChange={(date) => onDateChange(date)} onUpdate={() => setRefetchGrid(Date.now())}/>
        {hasPermissions(['EDIT_SCHEDULES']) && (<MultiSelect onChange={(value) => {
                setNewUser(value[0]);
            }} multiple={false} options={(_b = (_a = schedulableEntities === null || schedulableEntities === void 0 ? void 0 : schedulableEntities.edges) === null || _a === void 0 ? void 0 : _a.filter(({ node }) => !!(node === null || node === void 0 ? void 0 : node.id))) === null || _b === void 0 ? void 0 : _b.map(({ node }) => ({
                value: node === null || node === void 0 ? void 0 : node.userId,
                label: node === null || node === void 0 ? void 0 : node.name,
            }))} isSearchable defaultValue={selectedUserId}/>)}
      </Flexbox>
      <Flexbox gap="xsmall">
        <Suspense fallback={<IconButton color="danger" disabled icon={faUserClock}/>}>
          <ChangeRequestDropdown variables={changeRequestVariables} userId={selectedUserId}/>
        </Suspense>
        <MyScheduleFilter queryRef={(_c = data === null || data === void 0 ? void 0 : data.user) === null || _c === void 0 ? void 0 : _c.schedulableEntity} updateFilters={updateFilters}/>
        <Tooltip content={intl.formatMessage({
            defaultMessage: 'Schemainformation',
            id: 'general.misc.schedule_information.name.singular',
        })}>
          <IconButton appearance={showInformationDrawer ? 'filled' : 'outlined'} icon={showInformationDrawer ? faInfoSolid : faInfo} onClick={() => {
            onToggleInformationDrawer();
        }}/>
        </Tooltip>
      </Flexbox>
    </Flexbox>);
};
