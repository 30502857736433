import React from 'react';
import { eachDayOfInterval } from 'date-fns';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { MyScheduleList } from '../MyScheduleList/MyScheduleList';
import { Button, Flexbox, Icon, Text } from '@sonika-se/ui-kit/components';
import { faCalendarAlt, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import NoDataPlaceholder from '~/components/NoDataPlaceholder';
import { FormattedMessage } from 'react-intl';
export const MySchedulePaginationContentAsset = (props) => {
    var _a;
    const { fromDate, toDate, scheduleShifts } = props;
    const dates = eachDayOfInterval({
        start: fromDate,
        end: toDate,
    });
    const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment(graphql `
      fragment MySchedulePaginationContentAsset_shifts on SchedulableAsset
      @argumentDefinitions(
        model: { type: "GetRunningScheduleRequestInput!" }
        first: { type: "Int", defaultValue: 20 }
        after: { type: "String" }
      )
      @refetchable(queryName: "MySchedulePaginationContentAsset_shiftsQuery") {
        scheduleItems(model: $model, first: $first, after: $after)
          @connection(key: "MySchedulePaginationContentAsset_scheduleItems") {
          edges {
            node {
              ... on Node {
                id
              }
              ... on ScheduleShift {
                start
              }
              ... on ScheduleShiftAssigneeReplacement {
                scheduleShift {
                  start
                }
              }
              __typename
              ...ScheduleShiftCard_scheduleShift
              ...ScheduleShiftAbsenceCard_scheduleShiftAssigneeReplacement
            }
          }
          totalCount
        }
      }
    `, scheduleShifts);
    if (!data || !(data === null || data === void 0 ? void 0 : data.scheduleItems) || ((_a = data === null || data === void 0 ? void 0 : data.scheduleItems) === null || _a === void 0 ? void 0 : _a.totalCount) === 0) {
        return (<NoDataPlaceholder icon={faCalendarAlt} appearance="gray">
        <Text>
          <FormattedMessage id="MySchedulePage.MySchedulePaginationContent.no_data" defaultMessage="Inga kommande pass"/>
        </Text>
      </NoDataPlaceholder>);
    }
    return (<>
      <MyScheduleList scheduleItems={data.scheduleItems} dates={dates} skipEmptyDays type="ASSET"/>
      <Flexbox align="center" direction="column" gap="large">
        {isLoadingNext && <Icon icon={faSpinner} style={{ fontSize: '2rem', padding: '1rem' }} spin/>}
        {hasNext && (<Button onClick={() => loadNext(10)} size="large" display="block" color="secondary" disabled={isLoadingNext}>
            <FormattedMessage id="general.actions.load_more" defaultMessage="Ladda fler"/>
          </Button>)}
      </Flexbox>
    </>);
};
