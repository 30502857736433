import React, { Suspense } from 'react';
import { SidebarLayout, SidebarNavigation, SidebarNavigationLink, Skeleton } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useRoutes, useMatch } from 'react-router-dom';
import ErrorBoundary from '~/shared/components/ErrorBoundary';
import SimultaneousShiftsPage from './pages/SimultaneousShiftsPage/SimultaneousShiftsPage';
import SystemSettingsPage from './pages/SystemSettingsPage/SystemSettingsPage';
export const SettingsPages = () => {
    const intl = useIntl();
    const homeUrl = '/settings';
    const loc = useLocation();
    const navigate = useNavigate();
    const links = [
        {
            label: 'Allmänt',
            route: '/settings/system',
        },
        {
            label: 'Samtidiga pass',
            route: '/settings/simultaneous-shifts',
        },
    ];
    const routes = useRoutes([
        {
            path: 'system',
            element: <SystemSettingsPage />,
        },
        {
            path: 'simultaneous-shifts',
            element: <SimultaneousShiftsPage />,
        },
    ]);
    return (<SidebarLayout routes={<ErrorBoundary>
          <Suspense fallback={<Skeleton height="100%" type="page"/>}>{routes}</Suspense>
        </ErrorBoundary>}>
      <SidebarNavigation appearance="gray" onClick={() => navigate(homeUrl)} navigationHeaderText={intl.formatMessage({
            id: 'general.objects.settings.name.plural',
            defaultMessage: 'Inställningar',
        })} homePage={homeUrl === loc.pathname}>
        {links === null || links === void 0 ? void 0 : links.map((link) => {
            const match = useMatch(link === null || link === void 0 ? void 0 : link.route);
            return (<SidebarNavigationLink onClick={() => navigate(link === null || link === void 0 ? void 0 : link.route)} label={link === null || link === void 0 ? void 0 : link.label} key={link === null || link === void 0 ? void 0 : link.label} active={!!match}/>);
        })}
      </SidebarNavigation>
    </SidebarLayout>);
};
export default SettingsPages;
