/**
 * @generated SignedSource<<5d0d39a36372ec9dffe9c1b0957ced48>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "schedule",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentUserPermissions",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditScheduleModalQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditScheduleModalQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "e9958f806976de793e13673565acca4c",
            "id": null,
            "metadata": {},
            "name": "EditScheduleModalQuery",
            "operationKind": "query",
            "text": "query EditScheduleModalQuery(\n  $id: ID!\n) {\n  schedule(id: $id) {\n    id\n    currentUserPermissions\n    name\n  }\n}\n"
        }
    };
})();
node.hash = "71522401b0c5bf2b23f269b3d3f42e5f";
export default node;
