import React, { Suspense, useContext, useMemo } from 'react';
import { useFragment, graphql, useQueryLoader } from 'react-relay/hooks';
import { Flexbox, Chip, IconButton, Placeholder } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { dragSchedulableEntity } from '../../../SchedulePages/utils/dragSchedulableEntity';
import ScheduleTemplateDetailsPageContext from '../../ScheduleTemplateDetailsPageContext';
import { scheduleAssignmentTypeResourcesUpdateModalQuery, ScheduleAssignmentTypeResourcesUpdateModal, } from '../../../../modals/ScheduleAssignmentTypeResourcesUpdateModal';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            padding: `${pxToRem(8)} ${pxToRem(16)}`,
        },
    };
});
export const ScheduleAssignmentType = (props) => {
    var _a;
    const { updateHighlightedResources, scheduleAssignmentType, shifts } = props;
    const { highlightedShifts, highlightedSidebar } = useContext(ScheduleTemplateDetailsPageContext);
    const assignmentType = useFragment(graphql `
      fragment ScheduleAssignmentType_scheduleAssignmentType on ScheduleAssignmentType
      @refetchable(queryName: "ScheduleAssignmentType_scheduleAssignmentTypeQuery") {
        id
        assignmentType {
          id
          title
        }
        resources {
          edges {
            node {
              id
              schedulableEntity {
                ... on ISchedulableEntity {
                  id
                  color
                  displayName
                  ... on SchedulableAsset {
                    asset {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        schedule {
          currentUserPermissions
        }
        scheduleId
      }
    `, scheduleAssignmentType);
    const classes = useStyles();
    const [scheduleAssignmentTypeResourcesUpdateModalQueryRef, loadScheduleAssignmentTypeResourcesUpdateModalQuery, disposeScheduleAssignmentTypeResourcesUpdateModalQuery,] = useQueryLoader(scheduleAssignmentTypeResourcesUpdateModalQuery);
    const setHighlighted = (checked, id) => {
        var _a;
        const checkedShifts = (_a = (shifts.filter((shift) => shift.assignedId === id && shift.assignmentTypeId === assignmentType.assignmentType.id) || [])) === null || _a === void 0 ? void 0 : _a.map((shift) => shift === null || shift === void 0 ? void 0 : shift.id);
        if (checked) {
            updateHighlightedResources([
                ...highlightedSidebar,
                {
                    schedulableEntityId: id,
                    assignmentTypeId: assignmentType.assignmentType.id,
                },
            ], [...highlightedShifts, ...checkedShifts]);
        }
        else {
            updateHighlightedResources(highlightedSidebar.filter((entity) => !(entity.schedulableEntityId === id && entity.assignmentTypeId === assignmentType.assignmentType.id)), highlightedShifts.filter((shift) => checkedShifts.indexOf(shift) < 0));
        }
    };
    const hasPermission = usePermissions();
    const canEditAssignmentTypes = useMemo(() => hasPermission(['EDIT_SCHEDULES']) ||
        assignmentType.schedule.currentUserPermissions.includes('EDIT_ASSIGNMENT_TYPES'), [assignmentType, hasPermission]);
    return (<>
      <div className={classes.root}>
        <Flexbox align="center" justify="space">
          <span>{assignmentType.assignmentType.title}</span>
          {canEditAssignmentTypes && (<IconButton appearance="text" color="default" icon={faCog} onClick={() => {
                loadScheduleAssignmentTypeResourcesUpdateModalQuery({
                    scheduleAssignmentTypeId: assignmentType.id,
                }, { fetchPolicy: 'network-only' });
            }} size="small"/>)}
        </Flexbox>
        <Flexbox direction="column" gap="xsmall" style={{ marginTop: '0.5rem' }}>
          {!((_a = assignmentType.resources.edges) === null || _a === void 0 ? void 0 : _a.length) && <Placeholder text="Rollen är tom"/>}
          {assignmentType.resources.edges.map(({ node: resource }) => {
            const { asset, displayName, color } = resource.schedulableEntity;
            const type = asset ? 'asset' : 'user';
            return (<div key={resource.id}>
                <div draggable="true" onDragStart={(event) => dragSchedulableEntity(event, type, resource.schedulableEntity.id, assignmentType.assignmentType.id)}>
                  <Chip color={color} fullWidth={true} text={displayName} checkboxProps={{
                    checked: !!highlightedSidebar.find((highlighted) => highlighted.schedulableEntityId === resource.schedulableEntity.id &&
                        highlighted.assignmentTypeId === assignmentType.assignmentType.id),
                    onChange: (checked) => {
                        setHighlighted(checked, resource.schedulableEntity.id);
                    },
                }}/>
                </div>
              </div>);
        })}
        </Flexbox>
      </div>
      <Suspense fallback={<></>}>
        {scheduleAssignmentTypeResourcesUpdateModalQueryRef && (<ScheduleAssignmentTypeResourcesUpdateModal onClose={() => disposeScheduleAssignmentTypeResourcesUpdateModalQuery()} preloadedQuery={scheduleAssignmentTypeResourcesUpdateModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleAssignmentType;
