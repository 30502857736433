import React, { useMemo, useState } from 'react';
import { Wizard, Button, ModalToSheetContent, Flexbox, H6, Text, Notification, Accordion, AccordionItem, Icon, } from '@sonika-se/ui-kit/components';
import { graphql } from 'react-relay';
import { useMutation, useFragment } from 'react-relay/hooks';
import { formatISO, addDays } from 'date-fns';
import { useIntl } from 'react-intl';
import RunningSchedulesSelectScheduleForm from './RunningSchedulesSelectScheduleForm';
import RunningSchedulesSelectFromUserForm from './RunningSchedulesSelectFromUserForm';
import RunningSchedulesSelectToUserForm from './RunningSchedulesSelectToUserForm';
import RunningSchedulesSelectDateForm from './RunningSchedulesSelectDateForm';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { formatDateSpan, toDate } from '@sonika-se/ui-kit/utils/time';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
export const RunningSchedulesChangeResourceForm = (props) => {
    const { onCancel, queryRef } = props;
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const xdata = useFragment(graphql `
      fragment RunningSchedulesChangeResourceForm_query on Query {
        ...RunningSchedulesSelectScheduleForm_query
        schedules(filterModel: { withCurrentUserPermissions: [EDIT_RUNNING] }) {
          nodes {
            id
          }
        }
      }
    `, queryRef);
    const [data, setData] = useState({
        scheduleId: '',
        assignmentTypeId: '',
        currentlyAssignedEntityId: '',
        fromDate: undefined,
        newAssignedEntityId: '',
        reasonEventId: null,
        dryRun: false,
        toDate: undefined,
    });
    const [dryRunData, setDryRunData] = useState();
    const [errors, setErrors] = useState([]);
    const shiftAndValidations = useMemo(() => {
        if (!dryRunData) {
            return [];
        }
        let result = dryRunData.updateScheduleShiftAssigneeDuring.map((x) => ({
            shift: x.shiftEdge.node,
            validations: x.shiftEdge.node.potentialAssetValidations
                .flatMap((y) => y)
                .filter((y) => !y.assetProficiency.isValid),
        }));
        result = result.filter((x) => x.validations.length);
        return result;
    }, [dryRunData]);
    const [updateRunningSchedulesResourceDuring, loading] = useMutation(graphql `
      mutation RunningSchedulesChangeResourceFormMutation($model: UpdateScheduleShiftAssigneeDuringRequestInput!) {
        updateScheduleShiftAssigneeDuring(model: $model) {
          shiftEdge {
            node {
              ...ScheduleShift_shift
            }
          }
        }
      }
    `);
    const [updateRunningSchedulesResourceDuringDryRun, loadingDryRun] = useMutation(graphql `
        mutation RunningSchedulesChangeResourceFormDryRunMutation(
          $model: UpdateScheduleShiftAssigneeDuringRequestInput!
          $potentialValidationsModel: PotentialScheduleShiftAssetValidationsInput!
        ) {
          updateScheduleShiftAssigneeDuring(model: $model) {
            shiftEdge {
              node {
                id
                start
                end
                potentialAssetValidations(model: $potentialValidationsModel) {
                  assetProficiency {
                    isValid
                  }
                  schedulableAsset {
                    asset {
                      id
                      name
                      assetNumber
                      validateProficiency
                    }
                  }
                  schedulableUser {
                    user {
                      fullName
                    }
                  }
                  userAssignmentType {
                    title
                  }
                  assetProficiency {
                    isValid
                  }
                }
              }
            }
          }
        }
      `);
    const onSubmit = (formData) => {
        setData(Object.assign(Object.assign({}, (data || {})), formData));
        setCurrentStepIndex(currentStepIndex + 1);
        setErrors([]);
    };
    const steps = [
        {
            title: 'Inom schema',
            render: (props) => (<RunningSchedulesSelectScheduleForm {...props} queryRef={xdata} onSubmit={(formData) => {
                    onSubmit(formData);
                }}/>),
        },
        {
            title: 'Byt från',
            render: (props) => (<RunningSchedulesSelectFromUserForm {...props} scheduleId={data.scheduleId} onSubmit={(formData) => {
                    onSubmit(formData);
                }}/>),
        },
        {
            title: 'Byt till',
            render: (props) => (<RunningSchedulesSelectToUserForm {...props} scheduleId={data.scheduleId} assignmentTypeId={data.assignmentTypeId} onSubmit={(formData) => {
                    onSubmit(formData);
                }}/>),
        },
        {
            title: 'Datum och anledning',
            render: (props) => (<RunningSchedulesSelectDateForm {...props} currentlyAssignedAssignmentType={data.assignmentTypeId} currentlyAssignedEntityId={data.currentlyAssignedEntityId} onSubmit={(formData) => {
                    updateRunningSchedulesResourceDuringDryRun({
                        variables: {
                            model: Object.assign(Object.assign(Object.assign({}, data), formData), { fromDate: formatISO(formData.fromDate, { representation: 'date' }), toDate: formatISO(addDays(formData.toDate, 1), { representation: 'date' }), reasonEventId: (formData === null || formData === void 0 ? void 0 : formData.reasonEventId) === 'null' ? null : formData.reasonEventId, dryRun: true, currentlyAssignedEntityId: data.currentlyAssignedEntityId === 'vakant' ? null : data.currentlyAssignedEntityId, newAssignedEntityId: data.newAssignedEntityId === 'vakant' ? null : data.newAssignedEntityId }),
                            potentialValidationsModel: {
                                newAssignedEntityId: data.newAssignedEntityId,
                            },
                        },
                        onError: (error) => {
                            const errorObject = Object.assign({ source: { errors: [] } }, error);
                            setErrors(errorObject.source.errors);
                        },
                        onCompleted: (item) => {
                            console.log({ item });
                            setDryRunData(item);
                            onSubmit(Object.assign(Object.assign({}, formData), { reasonEventId: (formData === null || formData === void 0 ? void 0 : formData.reasonEventId) === 'null' ? null : formData.reasonEventId, fromDate: formatISO(formData.fromDate, { representation: 'date' }), toDate: formatISO(addDays(formData.toDate, 1), { representation: 'date' }) }));
                            setErrors([]);
                        },
                    });
                }}/>),
        },
        {
            title: 'Summering',
            render: () => (<>
          <H6>{dryRunData.updateScheduleShiftAssigneeDuring.length} pass kommer uppdateras</H6>
          <Flexbox direction="column" gap="small">
            {shiftAndValidations.length > 0 && (<Notification color="error" shadow={false} 
                // icon={}
                title={`Behörighet saknas för ${shiftAndValidations.length} pass`} preTitle={<>
                    <Icon icon={faExclamationTriangle} color={sonikaTheme.colors.system.stop}/>
                    &nbsp;
                  </>}>
                <Accordion>
                  <AccordionItem size="medium" title={<span>Visa pass</span>}>
                    {shiftAndValidations.map((x, indx) => (<React.Fragment key={`${indx}-validation`}>
                        {formatDateSpan(toDate(x.shift.start), toDate(x.shift.end), 'dateTime')}
                        {x.validations.map((validation, indx2) => (<div key={`${indx2}-x-validation`}>
                            {validation.schedulableUser.user.fullName} saknar behörighet{' '}
                            {validation.userAssignmentType.title} för {validation.schedulableAsset.asset.name}
                          </div>))}
                        <br />
                      </React.Fragment>))}
                  </AccordionItem>
                </Accordion>
              </Notification>)}
          </Flexbox>
        </>),
        },
    ];
    const intl = useIntl();
    return (<>
      <Wizard loading={loading || loadingDryRun} steps={steps} currentStep={currentStepIndex} onStepChange={setCurrentStepIndex}/>
      <ModalToSheetContent>
        {!!errors.length && (<Flexbox direction="column" gap="xsmall" style={{ marginBottom: '1rem' }}>
            {errors.map((error, index) => {
                var _a;
                return (<Text key={`error-${index}`} style={{ color: '#F13E13' }} size="small">
                {intl.formatMessage((_a = error === null || error === void 0 ? void 0 : error.extensions) === null || _a === void 0 ? void 0 : _a.code)}
              </Text>);
            })}
          </Flexbox>)}
        <Flexbox gap="xsmall" justify="end">
          <Button disabled={currentStepIndex + 1 !== steps.length || loading} onClick={() => {
            updateRunningSchedulesResourceDuring({
                variables: {
                    model: Object.assign(Object.assign({}, data), { currentlyAssignedEntityId: data.currentlyAssignedEntityId === 'vakant' ? null : data.currentlyAssignedEntityId, newAssignedEntityId: data.newAssignedEntityId === 'vakant' ? null : data.newAssignedEntityId }),
                },
                onCompleted: () => onCancel(),
            });
        }}>
            Genomför byte
          </Button>
        </Flexbox>
      </ModalToSheetContent>
    </>);
};
export default RunningSchedulesChangeResourceForm;
