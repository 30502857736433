/**
 * @generated SignedSource<<aaf09f3a5f19f3e295ba7f42831b1c43>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleTemplateId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "scheduleTemplateId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Literal",
            "name": "order",
            "value": {
                "order": "ASC"
            }
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "PublishScheduleModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleTemplate",
                    "kind": "LinkedField",
                    "name": "scheduleTemplate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": (v4 /*: any*/),
                            "concreteType": "ScheduleTemplatePeriodConnection",
                            "kind": "LinkedField",
                            "name": "periods",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleTemplatePeriodEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleTemplatePeriod",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "periods(order:{\"order\":\"ASC\"})"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PublishScheduleWizardPeriod_periods"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "PublishScheduleModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleTemplate",
                    "kind": "LinkedField",
                    "name": "scheduleTemplate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": (v4 /*: any*/),
                            "concreteType": "ScheduleTemplatePeriodConnection",
                            "kind": "LinkedField",
                            "name": "periods",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleTemplatePeriodEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleTemplatePeriod",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "periods(order:{\"order\":\"ASC\"})"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f53544536fe8de6b309397ac49a448f1",
            "id": null,
            "metadata": {},
            "name": "PublishScheduleModalQuery",
            "operationKind": "query",
            "text": "query PublishScheduleModalQuery(\n  $scheduleTemplateId: ID!\n) {\n  scheduleTemplate(id: $scheduleTemplateId) {\n    id\n    name\n    periods(order: {order: ASC}) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    ...PublishScheduleWizardPeriod_periods\n  }\n}\n\nfragment PublishScheduleWizardPeriod_periods on ScheduleTemplate {\n  periods(order: {order: ASC}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "9798fb2beae84449f435ccc51f0d78f7";
export default node;
