import React, { useContext } from 'react';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useIntl } from 'react-intl';
import { Flexbox, Divider } from '@sonika-se/ui-kit/components';
import RunningSchedulesPageContext from '../../RunningSchedulesPageContext';
import ScheduleSwitch from '../../../components/ScheduleSwitch/ScheduleSwitch';
import MonthSelect from '../../../components/MonthSelect/MonthSelect';
import { graphql, useFragment } from 'react-relay/hooks';
export const RunningSchedulesHeaderMobile = (props) => {
    const { onDateChange, userRef } = props;
    const { fromDate } = useContext(RunningSchedulesPageContext);
    const intl = useIntl();
    const data = useFragment(graphql `
      fragment RunningSchedulesHeaderMobile_user on User {
        ...ScheduleSwitch_user
      }
    `, userRef);
    return (<>
      <div style={{ padding: '.75rem 1rem' }}>
        <Flexbox align="center" justify="space">
          <ScheduleSwitch label={intl.formatMessage({
            defaultMessage: 'Alla scheman',
            id: 'general.objects.schedule.fields.all_schedules',
        })} userRef={data}/>
          <Flexbox gap="xsmall">{props.headerElements}</Flexbox>
        </Flexbox>
      </div>
      <Divider />
      <MonthSelect date={new Date(startOfMonth(new Date(fromDate)))} onDateChange={(date) => {
            onDateChange({
                fromDate: date,
                toDate: endOfMonth(date),
            });
        }}/>
    </>);
};
