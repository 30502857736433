import React, { useMemo } from 'react';
import { string, object, array } from 'yup';
import { Form, Field, FormFooter, Flexbox, Notification, Icon } from '@sonika-se/ui-kit/components';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, graphql, usePreloadedQuery } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import EventField from '~/components/EventField';
import { formatDateSpan, toDate } from '@sonika-se/ui-kit/utils/time';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
const validationSchema = (intl) => {
    return object({
        assignmentTypes: array()
            .of(object({
            assignmentTypeId: string().notRequired(),
            reasonEventId: string().required(intl.formatMessage({
                defaultMessage: 'Du måste ange en händelse',
                id: 'general.misc.event.error',
            })),
        }))
            .required(),
    });
};
export const updateScheduleShiftAssigneesFormQuery = graphql `
  query UpdateScheduleShiftAssigneesFormQuery(
    $where: ScheduleShiftFilterInput
    $potentialValidationsModel: PotentialScheduleShiftAssetValidationsInput!
  ) {
    scheduleShifts(where: $where) {
      edges {
        node {
          id
          assignmentType {
            id
            title
          }
          start
          end
          potentialAssetValidations(model: $potentialValidationsModel) {
            assetProficiency {
              isValid
            }
            schedulableAsset {
              asset {
                id
                name
                assetNumber
                validateProficiency
              }
            }
            schedulableUser {
              user {
                fullName
              }
            }
            userAssignmentType {
              title
            }
            assetProficiency {
              isValid
            }
          }
        }
      }
    }
  }
`;
const UpdateScheduleShiftAssigneesForm = (props) => {
    var _a;
    const { onCancel, preloadedQuery, newEntityId } = props;
    const { scheduleShifts } = usePreloadedQuery(updateScheduleShiftAssigneesFormQuery, preloadedQuery);
    const [updateScheduleTemplateShift, updateScheduleTemplateShiftLoading] = useMutation(graphql `
        mutation UpdateScheduleShiftAssigneesFormMutation($model: [UpdateScheduleShiftAssigneeRequestInput!]!) {
          updateScheduleShiftAssignees(model: $model) {
            scheduleShiftHistoryAssigneeChangeEdge {
              node {
                id
                scheduleShift {
                  ...ScheduleShift_shift
                  ...ScheduleShiftHistory_scheduleShift
                }
                ...ScheduleShiftHistoryAssigneeChange_history
              }
            }
          }
        }
      `);
    const shifts = useMemo(() => scheduleShifts.edges.map((edge) => edge), [scheduleShifts]);
    const shiftAndValidations = useMemo(() => {
        let result = scheduleShifts.edges.map((x) => ({
            shift: x.node,
            validations: x.node.potentialAssetValidations.flatMap((y) => y).filter((y) => !y.assetProficiency.isValid),
        }));
        result = result.filter((x) => x.validations.length);
        return result;
    }, [scheduleShifts]);
    const intl = useIntl();
    const shiftsPerAssignmentType = shifts.reduce((prevItem, newItem) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return Object.assign(Object.assign({}, prevItem), { [(_b = (_a = newItem === null || newItem === void 0 ? void 0 : newItem.node) === null || _a === void 0 ? void 0 : _a.assignmentType) === null || _b === void 0 ? void 0 : _b.id]: {
                assignmentTypeTitle: (_d = (_c = newItem === null || newItem === void 0 ? void 0 : newItem.node) === null || _c === void 0 ? void 0 : _c.assignmentType) === null || _d === void 0 ? void 0 : _d.title,
                shifts: [...(((_g = prevItem[(_f = (_e = newItem === null || newItem === void 0 ? void 0 : newItem.node) === null || _e === void 0 ? void 0 : _e.assignmentType) === null || _f === void 0 ? void 0 : _f.id]) === null || _g === void 0 ? void 0 : _g.shifts) || []), (_h = newItem === null || newItem === void 0 ? void 0 : newItem.node) === null || _h === void 0 ? void 0 : _h.id],
            } });
    }, {});
    const { control, formState: { errors }, handleSubmit, } = useForm({
        resolver: yupResolver(validationSchema(intl)),
        defaultValues: {
            assignmentTypes: (_a = Object.keys(shiftsPerAssignmentType)) === null || _a === void 0 ? void 0 : _a.map((key) => ({ assignmentTypeId: key })),
        },
    });
    const { fields } = useFieldArray({ control, name: 'assignmentTypes' });
    return (<Form onSubmit={handleSubmit((data) => {
            var _a;
            updateScheduleTemplateShift({
                variables: {
                    model: (_a = data === null || data === void 0 ? void 0 : data.assignmentTypes) === null || _a === void 0 ? void 0 : _a.reduce((prevItem, newItem) => {
                        var _a;
                        const assignmentType = shiftsPerAssignmentType[newItem === null || newItem === void 0 ? void 0 : newItem.assignmentTypeId];
                        return [
                            ...prevItem,
                            ...(_a = assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.shifts) === null || _a === void 0 ? void 0 : _a.map((shiftId) => ({
                                reasonEventId: newItem === null || newItem === void 0 ? void 0 : newItem.reasonEventId,
                                shiftId,
                                newAssignedEntityId: newEntityId,
                            })),
                        ];
                    }, []),
                },
                onCompleted: () => {
                    onCancel();
                },
            });
        })}>
      <Flexbox direction="column" gap="small">
        {shiftAndValidations.length > 0 && (<Notification color="error" shadow={false} 
        // icon={faExclamationTriangle}
        title="Behörighet" preTitle={<>
                <Icon icon={faExclamationTriangle} color={sonikaTheme.colors.system.stop}/>
                &nbsp;
              </>}>
            {shiftAndValidations.map((x, indx) => (<React.Fragment key={`${indx}-validation`}>
                {formatDateSpan(toDate(x.shift.start), toDate(x.shift.end), 'dateTime')}
                {x.validations.map((validation, indx2) => (<div key={`${indx2}-x-validation`}>
                    {validation.schedulableUser.user.fullName} saknar behörighet {validation.userAssignmentType.title}{' '}
                    för {validation.schedulableAsset.asset.name}
                  </div>))}
                <br />
              </React.Fragment>))}
          </Notification>)}
      </Flexbox>
      <Flexbox direction="column" gap="large">
        {fields === null || fields === void 0 ? void 0 : fields.map((field, index) => {
            var _a, _b, _c, _d;
            const assignmentType = shiftsPerAssignmentType[field === null || field === void 0 ? void 0 : field.assignmentTypeId];
            return (<Field key={field.id} label={intl.formatMessage({
                    defaultMessage: 'Anledning till ersättning för {name} ({number} pass)',
                    id: 'UpdateScheduleShiftAssigneesForm.fields.event.label',
                }, { name: assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.assignmentTypeTitle, number: (_a = assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.shifts) === null || _a === void 0 ? void 0 : _a.length })} error={(errors === null || errors === void 0 ? void 0 : errors.assignmentTypes) && ((_d = (_c = (_b = errors.assignmentTypes[index]) === null || _b === void 0 ? void 0 : _b.reasonEventId) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString())}>
              <Controller control={control} name={`assignmentTypes.${index}.reasonEventId`} render={({ field: field2 }) => {
                    var _a, _b, _c;
                    return (<EventField assignmentTypeId={field === null || field === void 0 ? void 0 : field.assignmentTypeId} where={{ isAssigneeReplacementReason: { eq: true } }} error={(errors === null || errors === void 0 ? void 0 : errors.assignmentTypes) && ((_c = (_b = (_a = errors.assignmentTypes[index]) === null || _a === void 0 ? void 0 : _a.reasonEventId) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.toString())} id={`assignmentTypes[${index}].reasonEventId`} disabled={updateScheduleTemplateShiftLoading} onChange={(value) => field2.onChange(value ? value[0] : undefined)} placeholder={intl.formatMessage({
                            defaultMessage: 'Välj händelse',
                            id: 'general.misc.schedule_shift.fields.event_id.placeholder',
                        })} defaultValue={field2.value}/>);
                }}/>
              <Controller control={control} name={`assignmentTypes.${index}.assignmentTypeId`} render={({ field: field2 }) => (<input type="hidden" onChange={field2.onChange} value={field.assignmentTypeId}/>)}/>
            </Field>);
        })}
      </Flexbox>
      <FormFooter onCancel={onCancel} submitText={intl.formatMessage({ id: 'general.actions.save', defaultMessage: 'Spara' })} disabled={updateScheduleTemplateShiftLoading}/>
    </Form>);
};
UpdateScheduleShiftAssigneesForm.propTypes = {};
export default UpdateScheduleShiftAssigneesForm;
