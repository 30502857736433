/**
 * @generated SignedSource<<8d647e89c5271fbc5eeee90c14b84600>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "Schedule",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ScheduleShiftClashListItem_iCanBeUsedToCalculateScheduleTimesConnection",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    (v2 /*: any*/),
                    (v3 /*: any*/)
                ],
                "type": "PlannedAbsence",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    (v2 /*: any*/),
                    (v4 /*: any*/),
                    (v3 /*: any*/)
                ],
                "type": "ScheduleShift",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleShift",
                        "kind": "LinkedField",
                        "name": "scheduleShift",
                        "plural": false,
                        "selections": [
                            (v1 /*: any*/),
                            (v2 /*: any*/),
                            (v4 /*: any*/),
                            (v3 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "type": "ScheduleShiftAssigneeReplacement",
                "abstractKey": null
            }
        ],
        "type": "ICanBeUsedToCalculateScheduleTimes",
        "abstractKey": "__isICanBeUsedToCalculateScheduleTimes"
    };
})();
node.hash = "a18c0f1a66aa4b3e05c95501d0162f9f";
export default node;
