/**
 * @generated SignedSource<<c2d20feebb1c5cb03ca7604f1c6aec5e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v1 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "SchedulePermissionGroup",
            "kind": "LinkedField",
            "name": "schedulePermissionGroups",
            "plural": true,
            "selections": [
                (v0 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchedulePermissionItem",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "SchedulePermissionListItemQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "SchedulePermissionListItemQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "f0fd842a0833505c7e313477e5cd48b7",
            "id": null,
            "metadata": {},
            "name": "SchedulePermissionListItemQuery",
            "operationKind": "query",
            "text": "query SchedulePermissionListItemQuery {\n  schedulePermissionGroups {\n    name\n    description\n    permissions {\n      name\n      value\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "79d6fc09b60a2b4f4b4955b8d91c8025";
export default node;
