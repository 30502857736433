/**
 * @generated SignedSource<<5d6eb0721bf511e4b89d868ff4547710>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startTimeOfDay",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endTimeOfDay",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "MINUTE"
        }
    ], v5 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        }
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "filter"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./ScheduleTemplatePeriodDayQuery.graphql'),
                "identifierField": "id"
            }
        },
        "name": "ScheduleTemplatePeriodDay_day",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dayInPeriod",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weekday",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTemplatePeriod",
                "kind": "LinkedField",
                "name": "period",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduleTemplateId",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "filter",
                        "variableName": "filter"
                    }
                ],
                "concreteType": "ScheduleTemplatePeriodDayShiftGroupByAssignmentType",
                "kind": "LinkedField",
                "name": "groupedShifts",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleTemplatePeriodDayShiftGroupByAssignmentTypeGroupingKey",
                        "kind": "LinkedField",
                        "name": "group",
                        "plural": false,
                        "selections": [
                            (v1 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleTemplateShiftGroup",
                        "kind": "LinkedField",
                        "name": "shiftsGroups",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleTemplateShiftGroupGroupingKey",
                                "kind": "LinkedField",
                                "name": "group",
                                "plural": false,
                                "selections": [
                                    (v2 /*: any*/),
                                    (v3 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": (v4 /*: any*/),
                                        "concreteType": "TimeSpanUnitValue",
                                        "kind": "LinkedField",
                                        "name": "duration",
                                        "plural": false,
                                        "selections": (v5 /*: any*/),
                                        "storageKey": "duration(unit:\"MINUTE\")"
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Event",
                                        "kind": "LinkedField",
                                        "name": "event",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "name",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleTemplateShift",
                                "kind": "LinkedField",
                                "name": "shifts",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "unit",
                                                "value": "HOUR"
                                            }
                                        ],
                                        "concreteType": "TimeSpanUnitValue",
                                        "kind": "LinkedField",
                                        "name": "duration",
                                        "plural": false,
                                        "selections": (v5 /*: any*/),
                                        "storageKey": "duration(unit:\"HOUR\")"
                                    },
                                    (v1 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ScheduleTemplateShiftBreak",
                                        "kind": "LinkedField",
                                        "name": "breaks",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": (v4 /*: any*/),
                                                "concreteType": "TimeSpanUnitValue",
                                                "kind": "LinkedField",
                                                "name": "offsetEnd",
                                                "plural": false,
                                                "selections": (v5 /*: any*/),
                                                "storageKey": "offsetEnd(unit:\"MINUTE\")"
                                            },
                                            {
                                                "alias": null,
                                                "args": (v4 /*: any*/),
                                                "concreteType": "TimeSpanUnitValue",
                                                "kind": "LinkedField",
                                                "name": "offsetStart",
                                                "plural": false,
                                                "selections": (v5 /*: any*/),
                                                "storageKey": "offsetStart(unit:\"MINUTE\")"
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "type",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    (v3 /*: any*/),
                                    (v2 /*: any*/),
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleTemplateShift_shift"
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ScheduleTemplateShiftAssetValidationGroup_scheduleTemplateShift"
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ScheduleTemplatePeriodDay",
        "abstractKey": null
    };
})();
node.hash = "9baba52094eac94ddae7ae9e05cd968a";
export default node;
