/**
 * @generated SignedSource<<2c28204efb36678c70f1707cee19f78a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "after"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "filterModel"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "first"
    }, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "fromDate"
    }, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "toDate"
    }, v5 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "where"
    }, v6 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "whereSchedules"
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v8 = [
        {
            "kind": "Variable",
            "name": "filterModel",
            "variableName": "filterModel"
        },
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "whereSchedules"
        }
    ], v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v10 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
        },
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
        },
        {
            "fields": [
                {
                    "kind": "Variable",
                    "name": "fromDate",
                    "variableName": "fromDate"
                },
                {
                    "kind": "Variable",
                    "name": "toDate",
                    "variableName": "toDate"
                }
            ],
            "kind": "ObjectValue",
            "name": "model"
        },
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "where"
        }
    ], v11 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            (v9 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v14 = {
        "kind": "InlineFragment",
        "selections": [
            (v9 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
            }
        ],
        "type": "ISchedulableEntity",
        "abstractKey": "__isISchedulableEntity"
    }, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isVacant",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/),
                (v6 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "PrintRunningSchedulePageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "currentCompany",
                    "plural": false,
                    "selections": [
                        (v7 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Company"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "concreteType": "ScheduleConnection",
                    "kind": "LinkedField",
                    "name": "schedules",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v9 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v10 /*: any*/),
                                            "concreteType": "ScheduleShiftConnection",
                                            "kind": "LinkedField",
                                            "name": "runningSchedule",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleShiftEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleShift",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v9 /*: any*/),
                                                                (v11 /*: any*/),
                                                                (v12 /*: any*/),
                                                                (v13 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": null,
                                                                    "kind": "LinkedField",
                                                                    "name": "assignedEntity",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v14 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v15 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/),
                (v6 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "PrintRunningSchedulePageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "currentCompany",
                    "plural": false,
                    "selections": [
                        (v7 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "alias": "avatarImage",
                            "args": null,
                            "concreteType": "Avatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                                (v9 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "storageId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "queryTransform",
                                            "value": "?width=68&height=68"
                                        }
                                    ],
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": "url(queryTransform:\"?width=68&height=68\")"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "concreteType": "ScheduleConnection",
                    "kind": "LinkedField",
                    "name": "schedules",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v9 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v10 /*: any*/),
                                            "concreteType": "ScheduleShiftConnection",
                                            "kind": "LinkedField",
                                            "name": "runningSchedule",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleShiftEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleShift",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v9 /*: any*/),
                                                                (v11 /*: any*/),
                                                                (v12 /*: any*/),
                                                                (v13 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": null,
                                                                    "kind": "LinkedField",
                                                                    "name": "assignedEntity",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "__typename",
                                                                            "storageKey": null
                                                                        },
                                                                        (v14 /*: any*/),
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                (v9 /*: any*/)
                                                                            ],
                                                                            "type": "Node",
                                                                            "abstractKey": "__isNode"
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v15 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b165fce7ba58cfe0b9db6aa4c5067353",
            "id": null,
            "metadata": {},
            "name": "PrintRunningSchedulePageQuery",
            "operationKind": "query",
            "text": "query PrintRunningSchedulePageQuery(\n  $after: String\n  $first: Int\n  $fromDate: DateTime!\n  $toDate: DateTime!\n  $where: ScheduleShiftFilterInput\n  $whereSchedules: ScheduleFilterInput\n  $filterModel: ScheduleFilterRequestInput\n) {\n  currentCompany {\n    name\n    ...Company\n    id\n  }\n  schedules(where: $whereSchedules, filterModel: $filterModel) {\n    edges {\n      node {\n        id\n        runningSchedule(after: $after, first: $first, model: {fromDate: $fromDate, toDate: $toDate}, where: $where) {\n          edges {\n            node {\n              id\n              assignmentType {\n                id\n                title\n              }\n              end\n              start\n              assignedEntity {\n                __typename\n                ... on ISchedulableEntity {\n                  __isISchedulableEntity: __typename\n                  id\n                  displayName\n                  color\n                }\n                ... on Node {\n                  __isNode: __typename\n                  id\n                }\n              }\n              isVacant\n            }\n          }\n        }\n        name\n      }\n    }\n  }\n}\n\nfragment Company on Company {\n  id\n  name\n  avatarImage: avatar {\n    id\n    storageId\n    url(queryTransform: \"?width=68&height=68\")\n  }\n}\n"
        }
    };
})();
node.hash = "066ffdad7ca12edf57d4653538a36625";
export default node;
