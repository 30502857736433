import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InlineSelect, ReadViewContainer } from '@sonika-se/ui-kit/components';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { useFragment, graphql, useMutation, useLazyLoadQuery } from 'react-relay/hooks';
export const editScheduleShiftEventQuery = graphql `
  query EditScheduleShiftEventQuery {
    events(where: { isWork: { eq: true } }) {
      edges {
        node {
          id
          name
          assignmentTypes {
            id
            title
          }
        }
      }
    }
  }
`;
export const EditScheduleShiftEvent = (props) => {
    var _a;
    const { scheduleShift, placeholder } = props;
    const { events } = useLazyLoadQuery(editScheduleShiftEventQuery, {
        assignmentTypeId: props.assignmentTypeId,
    });
    const { id: shiftId, event, assignmentTypeId, schedule, } = useFragment(graphql `
      fragment EditScheduleShiftEvent on ScheduleShift {
        id
        assignmentTypeId
        event {
          id
          name
        }
        schedule {
          currentUserPermissions
        }
      }
    `, scheduleShift);
    const [updateScheduleShiftEvent] = useMutation(graphql `
    mutation EditScheduleShiftEventMutation($model: UpdateScheduleShiftEventRequestInput!) {
      updateScheduleShiftEvent(model: $model) {
        shiftEdge {
          node {
            id
            event {
              id
              name
            }
          }
        }
      }
    }
  `);
    const { control, reset } = useForm({
        defaultValues: { newEventId: event === null || event === void 0 ? void 0 : event.id },
    });
    const hasPermissions = usePermissions();
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || schedule.currentUserPermissions.includes('EDIT_RUNNING'), [hasPermissions, schedule]);
    const filteredEvents = (_a = events === null || events === void 0 ? void 0 : events.edges) === null || _a === void 0 ? void 0 : _a.filter(({ node }) => { var _a; return !!((_a = node === null || node === void 0 ? void 0 : node.assignmentTypes) === null || _a === void 0 ? void 0 : _a.find((assignmentType) => (assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.id) === assignmentTypeId)); });
    return (<Controller control={control} name="newEventId" render={({ field }) => (<InlineSelect inputName={undefined} inputRef={undefined} view={(setEditViewOpen, value) => (filteredEvents === null || filteredEvents === void 0 ? void 0 : filteredEvents.length) <= 1 && canEditRunning ? (<ReadViewContainer>{value}</ReadViewContainer>) : (<div>{value}</div>)} options={filteredEvents === null || filteredEvents === void 0 ? void 0 : filteredEvents.map(({ node: item }) => ({
                value: item.id,
                label: item.name,
            }))} disabled={!canEditRunning || (filteredEvents === null || filteredEvents === void 0 ? void 0 : filteredEvents.length) <= 1} defaultValue={event === null || event === void 0 ? void 0 : event.id} placeholder={placeholder} isSearchable={false} onChange={(value) => {
                field.onChange(value);
                reset({ newEventId: value });
                updateScheduleShiftEvent({
                    variables: {
                        model: {
                            shiftId,
                            newEventId: value,
                        },
                    },
                });
            }}/>)}/>);
};
export default EditScheduleShiftEvent;
