import React from 'react';
import { Flexbox, H5, Text } from '@sonika-se/ui-kit/components';
export const KpiBarItem = (props) => {
    const { label, value } = props;
    return (<Flexbox direction="column" gap="none" style={{ flex: 1, padding: '0.5rem' }}>
      <H5>{value}</H5>
      <Text appearance="light" size="medium">
        {label}
      </Text>
    </Flexbox>);
};
export default KpiBarItem;
