/**
 * @generated SignedSource<<b8d1cd5eb7941ed2e73eb6e4ddbf489d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SchedulableEntity_schedulableEntity",
    "selections": [
        {
            "kind": "InlineFragment",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                }
            ],
            "type": "ISchedulableEntity",
            "abstractKey": "__isISchedulableEntity"
        }
    ],
    "type": "SchedulableEntity",
    "abstractKey": "__isSchedulableEntity"
};
node.hash = "be4be8a0b3bc5bf517728c3c4ad633b0";
export default node;
