/**
 * @generated SignedSource<<4e57a23c6b079218db70352f4d76e9fa>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "MyScheduleFilter_schedulableUser",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleAssignmentTypeResourceConnection",
                "kind": "LinkedField",
                "name": "resourceInScheduleAssignmentTypes",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleAssignmentTypeResourceEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleAssignmentTypeResource",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ScheduleAssignmentType",
                                        "kind": "LinkedField",
                                        "name": "scheduleAssignmentType",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AssignmentType",
                                                "kind": "LinkedField",
                                                "name": "assignmentType",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "title",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Schedule",
                                                "kind": "LinkedField",
                                                "name": "schedule",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/)
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleConnection",
                "kind": "LinkedField",
                "name": "resourceInSchedules",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Schedule",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "SchedulableUser",
        "abstractKey": null
    };
})();
node.hash = "b8c864d39959a4662c17e4349a29abc4";
export default node;
