import React, { useState } from 'react';
import { string, object, array, mixed, date } from 'yup';
import { ModalToSheet, ModalToSheetContent, FieldList, Form, FormSection, Field, Checkbox, TimeInput, FormFooter, MultiSelect, ModalToSheetHeader, } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, graphql, usePreloadedQuery } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import { addDays, setHours, setMinutes, setSeconds } from 'date-fns';
import { toDate } from '@sonika-se/ui-kit/utils/time';
import enums from '~/enums.json';
const validationSchema = object({
    end: date().required('Du måste ange sluttid'),
    start: date().required('Du måste ange starttid'),
    assignedEntityId: string(),
    breaks: array()
        .of(object({
        end: date(),
        start: date(),
        type: mixed().oneOf(Object.keys(enums.BreakEnum)),
    }))
        .notRequired(),
});
export const runningSchedulesEditUpdateTimesModalQuery = graphql `
  query RunningSchedulesEditUpdateTimesModalQuery($where: ScheduleShiftFilterInput) {
    schedules {
      edges {
        node {
          id
          shifts(where: $where) {
            edges {
              node {
                id
                start
                end
              }
            }
          }
        }
      }
    }
  }
`;
const RunningSchedulesEditUpdateTimesModal = (props) => {
    var _a, _b, _c;
    const { onCancel, preloadedQuery } = props;
    const [hasBreak, setHasBreak] = useState(false);
    const intl = useIntl();
    const scheduleShiftBreakType = enums.BreakEnum;
    const { formState: { errors }, handleSubmit, control, } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {},
    });
    const { schedules } = usePreloadedQuery(runningSchedulesEditUpdateTimesModalQuery, preloadedQuery);
    const [updateScheduleTemplateShift, updateScheduleTemplateShiftLoading] = useMutation(graphql `
        mutation RunningSchedulesEditUpdateTimesModalMutation($model: [UpdateScheduleShiftTimeRequestInput!]!) {
          updateScheduleShiftTimes(model: $model) {
            scheduleShiftHistoryTimeChangeEdge {
              node {
                id
                scheduleShift {
                  ...ScheduleShift_shift
                  ...ScheduleShiftHistory_scheduleShift
                }
                ...ScheduleShiftHistoryTimeChange_history
              }
            }
          }
        }
      `);
    const shifts = (_a = schedules === null || schedules === void 0 ? void 0 : schedules.edges) === null || _a === void 0 ? void 0 : _a.reduce((newItem = [], item) => {
        var _a, _b;
        return [...newItem, ...(((_b = (_a = item === null || item === void 0 ? void 0 : item.node) === null || _a === void 0 ? void 0 : _a.shifts) === null || _b === void 0 ? void 0 : _b.edges) || [])];
    }, []);
    return (<ModalToSheet onClose={onCancel}>
      <ModalToSheetHeader onClose={onCancel} closeButtonLabel="Avbryt">
        {`Uppdatera tiden för ${shifts === null || shifts === void 0 ? void 0 : shifts.length} pass`}
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Form onSubmit={handleSubmit((data) => {
            const updateDateWithNewHours = (date, newHoursAndMinutes) => {
                return setHours(setMinutes(setSeconds(date, 0), newHoursAndMinutes.getMinutes()), newHoursAndMinutes.getHours());
            };
            updateScheduleTemplateShift({
                variables: {
                    model: shifts === null || shifts === void 0 ? void 0 : shifts.map(({ node: shift }) => {
                        var _a;
                        const shiftStart = updateDateWithNewHours(toDate(shift.start), data.start);
                        let shiftEnd = updateDateWithNewHours(toDate(shift.start), data.end);
                        if (shiftEnd.getTime() <= shiftStart.getTime()) {
                            shiftEnd = addDays(shiftEnd, 1);
                        }
                        return {
                            shiftId: shift.id,
                            start: shiftStart,
                            end: shiftEnd,
                            breaks: ((_a = data === null || data === void 0 ? void 0 : data.breaks) === null || _a === void 0 ? void 0 : _a.map((item) => {
                                let breakStart = updateDateWithNewHours(toDate(shift.start), item.start);
                                if (breakStart.getTime() < shiftStart.getTime()) {
                                    breakStart = addDays(breakStart, 1);
                                }
                                let breakEnd = updateDateWithNewHours(toDate(shift.start), item.end);
                                if (breakEnd.getTime() <= breakStart.getTime()) {
                                    breakEnd = addDays(breakEnd, 1);
                                }
                                return Object.assign(Object.assign({}, item), { start: breakStart, end: breakEnd });
                            })) || null,
                        };
                    }),
                },
                onCompleted: () => {
                    onCancel();
                },
            });
        })}>
          <FormSection columns="1fr 1fr">
            <Field error={(_b = errors.start) === null || _b === void 0 ? void 0 : _b.message.toString()} label="Starttid">
              <Controller control={control} name="start" render={({ field }) => {
            var _a;
            return (<TimeInput disabled={updateScheduleTemplateShiftLoading} error={(_a = errors.start) === null || _a === void 0 ? void 0 : _a.message} onChange={field.onChange} value={field.value}/>);
        }}/>
            </Field>
            <Field error={(_c = errors.end) === null || _c === void 0 ? void 0 : _c.message.toString()} label="Sluttid">
              <Controller control={control} name="end" render={({ field }) => {
            var _a;
            return (<TimeInput disabled={updateScheduleTemplateShiftLoading} error={(_a = errors.end) === null || _a === void 0 ? void 0 : _a.message} onChange={field.onChange} value={field.value}/>);
        }}/>
            </Field>
            <Field label="Passet innehåller raster" direction="row-reverse" columnSpan={2}>
              <Checkbox inputName="hasBreak" disabled={updateScheduleTemplateShiftLoading} onChange={(e) => setHasBreak(e.target.checked)} checked={hasBreak}/>
            </Field>
          </FormSection>
          {hasBreak && (<FormSection>
              <FieldList appendText={intl.formatMessage({
                id: 'general.misc.breaks.actions.create',
                defaultMessage: 'Lägg till rast',
            })} compact control={control} disabled={updateScheduleTemplateShiftLoading} name="breaks" label={intl.formatMessage({
                id: 'general.misc.breaks.name.plural',
                defaultMessage: 'Raster',
            })} render={(field, index) => {
                var _a, _b, _c, _d;
                return (<FormSection columns="1fr 1fr 1fr" key={field.id}>
                    <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_b = (_a = errors.breaks[index]) === null || _a === void 0 ? void 0 : _a.start) === null || _b === void 0 ? void 0 : _b.message)}>
                      <Controller control={control} name={`breaks.${index}.start`} render={({ field }) => {
                        var _a, _b;
                        return (<TimeInput disabled={updateScheduleTemplateShiftLoading} error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_b = (_a = errors.breaks[index]) === null || _a === void 0 ? void 0 : _a.start) === null || _b === void 0 ? void 0 : _b.message)} onChange={field.onChange} value={field.value}/>);
                    }}/>
                    </Field>
                    <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_d = (_c = errors.breaks[index]) === null || _c === void 0 ? void 0 : _c.end) === null || _d === void 0 ? void 0 : _d.message)}>
                      <Controller control={control} name={`breaks.${index}.end`} render={({ field }) => {
                        var _a, _b;
                        return (<TimeInput disabled={updateScheduleTemplateShiftLoading} error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_b = (_a = errors.breaks[index]) === null || _a === void 0 ? void 0 : _a.end) === null || _b === void 0 ? void 0 : _b.message)} onChange={field.onChange} value={field.value}/>);
                    }}/>
                    </Field>
                    <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && errors.breaks[index].type.toString()}>
                      <Controller control={control} name={`breaks.${index}.type`} render={({ field }) => {
                        var _a;
                        return (<MultiSelect defaultValue={field.value} disabled={updateScheduleTemplateShiftLoading} onChange={(value) => (value ? field.onChange(value[0]) : undefined)} options={(_a = Object.keys(scheduleShiftBreakType)) === null || _a === void 0 ? void 0 : _a.map((key) => {
                                var _a;
                                return ({
                                    label: intl.formatMessage({
                                        id: `BreakEnum_${key}`,
                                        defaultMessage: (_a = scheduleShiftBreakType[key]) === null || _a === void 0 ? void 0 : _a.description,
                                    }),
                                    value: key,
                                });
                            })} placeholder="Välj typ"/>);
                    }}/>
                    </Field>
                  </FormSection>);
            }}/>
            </FormSection>)}
          <FormFooter onCancel={onCancel} submitText="Uppdatera tid"/>
        </Form>
      </ModalToSheetContent>
    </ModalToSheet>);
};
RunningSchedulesEditUpdateTimesModal.propTypes = {};
export default RunningSchedulesEditUpdateTimesModal;
