/**
 * @generated SignedSource<<9037b8fd01c6fe8638799b0f7161e34f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "kind": "Literal",
        "name": "first",
        "value": 1000
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": [
                (v0 /*: any*/)
            ],
            "concreteType": "AssignmentTypeConnection",
            "kind": "LinkedField",
            "name": "assignmentTypes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "assignmentTypes(first:1000)"
        },
        {
            "alias": null,
            "args": [
                (v0 /*: any*/),
                {
                    "kind": "Literal",
                    "name": "order",
                    "value": {
                        "name": "ASC"
                    }
                },
                {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                        "isWork": {
                            "eq": true
                        }
                    }
                }
            ],
            "concreteType": "EventConnection",
            "kind": "LinkedField",
            "name": "events",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Event",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "events(first:1000,order:{\"name\":\"ASC\"},where:{\"isWork\":{\"eq\":true}})"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "SimultaneousShiftsFilterFormQuery",
            "selections": (v2 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "SimultaneousShiftsFilterFormQuery",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "90cd7e57b8e6f57ac20cd22a7ce717d8",
            "id": null,
            "metadata": {},
            "name": "SimultaneousShiftsFilterFormQuery",
            "operationKind": "query",
            "text": "query SimultaneousShiftsFilterFormQuery {\n  assignmentTypes(first: 1000) {\n    edges {\n      node {\n        id\n        title\n      }\n    }\n  }\n  events(first: 1000, where: {isWork: {eq: true}}, order: {name: ASC}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "46c59f4ef66d272f407258f23fbd9578";
export default node;
