import React, { useMemo } from 'react';
import { graphql } from 'react-relay';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay/hooks';
import { Flexbox, Icon, Button } from '@sonika-se/ui-kit/components';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { RunningSchedulesInformationByMonthRow } from './RunningSchedulesInformationByMonthRow';
export const RunningSchedulesInformationByMonth = (props) => {
    const { month, searchFilter } = props;
    const variables = useMemo(() => {
        return {
            month: month,
            model: {
                nameContains: searchFilter,
                onlyActive: true,
                types: ['USER'],
                isScheduledDuringMonth: searchFilter == null || searchFilter == '' ? month : null,
            },
        };
    }, [month, searchFilter]);
    const queryData = useLazyLoadQuery(graphql `
      query RunningSchedulesInformationByMonthQuery($month: DateTime!, $model: SchedulableEntitiesQueryRequestInput) {
        ...RunningSchedulesInformationByMonth_fragment @arguments(month: $month, model: $model)
      }
    `, {
        month: month,
        model: variables.model,
    }, { fetchPolicy: 'network-only', fetchKey: JSON.stringify(props) });
    const { data, refetch, loadNext, hasNext, isLoadingNext } = usePaginationFragment(graphql `
      fragment RunningSchedulesInformationByMonth_fragment on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        month: { type: "DateTime!" }
        model: { type: "SchedulableEntitiesQueryRequestInput" }
      )
      @refetchable(queryName: "RunningSchedulesInformationByMonth_fragmentQuery") {
        schedulableEntities(first: $first, after: $after, model: $model)
          @connection(key: "RunningSchedulesInformationByMonthQuery_schedulableEntities") {
          edges {
            node {
              ... on Node {
                id
              }
              ... on ISchedulableEntity {
                ...RunningSchedulesInformationByMonthRow_fragment @arguments(month: $month)
              }
            }
          }
        }
      }
    `, queryData);
    return (<>
      <div style={{ padding: '1rem 0' }}>
        <Flexbox direction="column" gap="xxsmall" justify="space">
          {data.schedulableEntities.edges.map(({ node: schedulableEntity }) => (<RunningSchedulesInformationByMonthRow queryRef={schedulableEntity} key={schedulableEntity.id}/>))}
        </Flexbox>
      </div>
      {hasNext && (<>
          <Button disabled={isLoadingNext} onClick={() => {
                loadNext(30, {
                    UNSTABLE_extraVariables: variables,
                });
            }}>
            Ladda fler (30)
          </Button>{' '}
          <span>{isLoadingNext && <Icon icon={faSpinner} spin pulse/>}</span>
        </>)}
    </>);
};
