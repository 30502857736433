import React, { Suspense, useEffect, useState } from 'react';
import { SearchSelect } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import { graphql } from 'react-relay';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay/hooks';
import { useDebounce } from '@sonika-se/ui-kit/hooks';
const QuerySchedulableEntitySelectData = (props) => {
    const { search, onResult, defaultFilterType, onSearch, filterModel } = props;
    const dataQuery = useLazyLoadQuery(graphql `
      query QuerySchedulableEntitySelectDataQuery {
        ...QuerySchedulableEntitySelectData_query
      }
    `, {});
    const { data: schedulableEntitesResponse, hasNext, isLoadingNext, loadNext, refetch, } = usePaginationFragment(graphql `
      fragment QuerySchedulableEntitySelectData_query on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        where: { type: "SchedulableEntityFilterInput" }
        model: { type: "SchedulableEntitiesQueryRequestInput" }
      )
      @refetchable(queryName: "QuerySchedulableEntitySelectDataQuery_query") {
        schedulableEntities(first: $first, after: $after, where: $where, model: $model)
          @connection(key: "QuerySchedulableEntitySelectData_query_schedulableEntities") {
          edges {
            node {
              ... on SchedulableAsset {
                asset {
                  ...Asset
                  assignmentTypes {
                    nodes {
                      title
                      id
                    }
                  }
                }
              }
              ... on SchedulableUser {
                user {
                  ...User
                  assignmentTypes {
                    nodes {
                      title
                      id
                    }
                  }
                }
              }
              ... on ISchedulableEntity {
                id
                displayName
              }
            }
          }
        }
      }
    `, dataQuery);
    useEffect(() => {
        if (onResult) {
            onResult({ result: schedulableEntitesResponse, hasNext: hasNext });
        }
    }, [schedulableEntitesResponse]);
    useEffect(() => {
        if (onSearch) {
            onSearch();
        }
        refetch({
            where: {
                name: { contains: search.query },
                type: { eq: defaultFilterType },
            },
            model: filterModel,
        });
    }, [search.query, props.defaultFilterType]);
    useEffect(() => {
        if (onSearch) {
            onSearch();
        }
        loadNext(10);
    }, [search.afterCursor]);
    return null;
};
const QuerySchedulableEntitySelect = (props) => {
    const { multiple, onSingleSelected, onMultipleSelected, entityFilterType, disabled, filterModel } = props;
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchedItems, setSearchedItems] = useState();
    const [hasMore, setHasMore] = useState(false);
    const [search, setSearch] = useState({ query: '', afterCursor: null });
    const delayedSearch = useDebounce(search, 200);
    useEffect(() => {
        multiple && onMultipleSelected && onMultipleSelected(selectedItems);
        !multiple && onSingleSelected && onSingleSelected(selectedItems.length ? selectedItems[0] : null);
    }, [selectedItems]);
    return (<>
      <Suspense fallback>
        <QuerySchedulableEntitySelectData search={search} onResult={(result) => {
            setIsLoading(false);
            setSearchedItems(result.result);
            setHasMore(result.hasNext);
        }} onSearch={() => setIsLoading(true)} defaultFilterType={entityFilterType} filterModel={filterModel}/>
      </Suspense>
      <SearchSelect disabled={disabled} placeholder={intl.formatMessage({
            id: 'QuerySchedulableEntitySelect.fields.assigned_entity_id.placeholder',
            defaultMessage: 'Sök {name}',
        }, {
            name: props.entityFilterType === 'USER'
                ? intl.formatMessage({
                    id: 'QuerySchedulableEntitySelect.fields.assigned_entity_id.label',
                    defaultMessage: 'användare',
                })
                : intl.formatMessage({
                    id: 'general.objects.asset.name.singular',
                    defaultMessage: 'enhet',
                }),
        })} onSearch={(value) => {
            setSearch({ query: value, afterCursor: null });
        }} isLoading={isLoading} multiple={multiple} error={props.error} autoFocus={false} options={(searchedItems === null || searchedItems === void 0 ? void 0 : searchedItems.schedulableEntities.edges.map(({ node }) => ({
            label: node.displayName,
            value: node,
            disabled: !!selectedItems.find((x) => x.id === node.id),
        }))) || []} selectedOptions={selectedItems.map((x) => ({
            label: x.displayName,
            value: x,
        }))} onChange={(value) => {
            const mappedResult = value.map((x) => {
                var _a, _b, _c, _d, _e, _f;
                const assignmentTypes = ((_f = (_c = (_b = (_a = x.asset) === null || _a === void 0 ? void 0 : _a.assignmentTypes) === null || _b === void 0 ? void 0 : _b.nodes) !== null && _c !== void 0 ? _c : (_e = (_d = x.user) === null || _d === void 0 ? void 0 : _d.assignmentTypes) === null || _e === void 0 ? void 0 : _e.nodes) !== null && _f !== void 0 ? _f : []).map((x) => x);
                return {
                    displayName: x.displayName,
                    id: x.id,
                    assignmentTypes: assignmentTypes,
                };
            });
            setSelectedItems(mappedResult);
        }} onLoadMore={() => setSearch({ query: search.query, afterCursor: search.afterCursor + 'a' })} hasMore={hasMore}/>
    </>);
};
export default QuerySchedulableEntitySelect;
