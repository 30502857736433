import React, { useEffect, useState, useContext } from 'react';
import { eachDayOfInterval, eachWeekOfInterval, getWeek, addDays, getDay, startOfMonth, getDaysInMonth, subDays, endOfMonth, getWeeksInMonth, formatISO, } from 'date-fns';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import { sv as locale } from 'date-fns/locale';
import { useRefetchableFragment, graphql, usePreloadedQuery } from 'react-relay/hooks';
import MySchedulePageContext from '../../MySchedulePageContext';
import { MyScheduleList } from '../MyScheduleList/MyScheduleList';
import { MyScheduleGrid } from '../MyScheduleGrid/MyScheduleGrid';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
import { mySchedulePageQuery } from '../../MySchedulePage';
export const MyScheduleContent = (props) => {
    var _a;
    const { fromDate, toDate, preloadedQuery, layout, skipEmptyDays, refetchGrid, style } = props;
    const queryRef = usePreloadedQuery(mySchedulePageQuery, preloadedQuery);
    const { size: pageSize } = useViewport();
    const [calcLayout, setLayout] = useState(layout || pageSize === 'small' ? 'list' : 'grid');
    const { scheduleIds, assignmentTypeIds } = useContext(MySchedulePageContext).filters;
    const [dates, setDates] = useState(null);
    const [weeks, setWeeks] = useState(null);
    const [oldRefetchGrid, setOldRefetchGrid] = useState(refetchGrid);
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment MyScheduleContent_shifts on SchedulableUser
      @argumentDefinitions(
        model: { type: "GetRunningScheduleRequestInput!" }
        assignmentTypesFilter: { type: "[ID!]" }
        schedulesFilter: { type: "[ID!]" }
      )
      @refetchable(queryName: "MyScheduleContent_shiftsQuery") {
        scheduleItems(model: $model, assignmentTypesFilter: $assignmentTypesFilter, schedulesFilter: $schedulesFilter) {
          edges {
            node {
              ... on Node {
                id
              }
              ... on PlannedAbsence {
                start
                end
              }
              ... on ScheduleShift {
                end
                start
              }
              ... on ScheduleShiftAssigneeReplacement {
                end
                start
              }
              __typename
              ...PlannedAbsenceCard_plannedAbsence
              ...ScheduleShiftCard_scheduleShift
              ...ScheduleShiftAbsenceCard_scheduleShiftAssigneeReplacement
            }
          }
        }
      }
    `, (_a = queryRef === null || queryRef === void 0 ? void 0 : queryRef.user) === null || _a === void 0 ? void 0 : _a.schedulableEntity);
    useDidMountEffect(() => {
        if (layout) {
            setLayout(layout);
        }
        else {
            setLayout(pageSize === 'small' ? 'list' : 'grid');
        }
    }, [pageSize]);
    useEffect(() => {
        var _a;
        const weeks = eachWeekOfInterval({
            start: fromDate,
            end: toDate,
        }, { weekStartsOn: 1 });
        setWeeks((_a = weeks === null || weeks === void 0 ? void 0 : weeks.map((week) => getWeek(week, { weekStartsOn: 1, firstWeekContainsDate: 4 }))) !== null && _a !== void 0 ? _a : null);
        const monthStartWeekday = getDay(startOfMonth(fromDate)) || 7;
        const daysInMonth = getDaysInMonth(fromDate);
        const startDate = subDays(startOfMonth(fromDate), monthStartWeekday - 1);
        const nrOfWeeks = getWeeksInMonth(fromDate, { locale });
        const endDate = addDays(endOfMonth(fromDate), nrOfWeeks * 7 - daysInMonth - monthStartWeekday + 1);
        const daysArray = eachDayOfInterval({
            start: calcLayout === 'list' ? fromDate : startDate,
            end: calcLayout === 'list' ? toDate : endDate,
        });
        setDates(daysArray);
        refetch({
            model: {
                fromDate: formatISO(calcLayout === 'list' ? fromDate : startDate),
                toDate: formatISO(calcLayout === 'list' ? toDate : endDate),
            },
            schedulesFilter: (scheduleIds === null || scheduleIds === void 0 ? void 0 : scheduleIds.length) ? scheduleIds : undefined,
            assignmentTypesFilter: (assignmentTypeIds === null || assignmentTypeIds === void 0 ? void 0 : assignmentTypeIds.length) ? assignmentTypeIds : undefined,
        }, { fetchPolicy: refetchGrid !== oldRefetchGrid ? 'network-only' : 'store-or-network' });
        if (refetchGrid !== oldRefetchGrid) {
            setOldRefetchGrid(refetchGrid);
        }
    }, [fromDate, toDate, scheduleIds, assignmentTypeIds, refetchGrid]);
    if (calcLayout === 'list') {
        return (<MyScheduleList scheduleItems={data.scheduleItems} dates={dates} skipEmptyDays={skipEmptyDays} style={style}/>);
    }
    return <MyScheduleGrid scheduleItems={data.scheduleItems} dates={dates} weeks={weeks} fromDate={fromDate}/>;
};
