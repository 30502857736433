/**
 * @generated SignedSource<<7088c8a0f0c2430faf3fd5cc1a3bd6e2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v2 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "HOUR"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unit",
        "storageKey": null
    }, v5 = [
        (v3 /*: any*/),
        (v4 /*: any*/)
    ], v6 = [
        (v0 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
        }
    ], v7 = [
        (v0 /*: any*/),
        (v1 /*: any*/)
    ], v8 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "event",
        "plural": false,
        "selections": (v7 /*: any*/),
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "month"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./RunningSchedulesInformationByMonthRow_fragmentQuery.graphql'),
                "identifierField": "id"
            }
        },
        "name": "RunningSchedulesInformationByMonthRow_fragment",
        "selections": [
            (v0 /*: any*/),
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "month",
                        "variableName": "month"
                    }
                ],
                "concreteType": "ScheduleTimesByMonthPayload",
                "kind": "LinkedField",
                "name": "scheduleTimesByMonth",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimeReport",
                        "kind": "LinkedField",
                        "name": "timeReport",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleTimesByMonthSummary",
                        "kind": "LinkedField",
                        "name": "summary",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": (v2 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "totalTime",
                                "plural": false,
                                "selections": (v5 /*: any*/),
                                "storageKey": "totalTime(unit:\"HOUR\")"
                            },
                            {
                                "alias": null,
                                "args": (v2 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "workedTime",
                                "plural": false,
                                "selections": (v5 /*: any*/),
                                "storageKey": "workedTime(unit:\"HOUR\")"
                            },
                            {
                                "alias": null,
                                "args": (v2 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "absenceTime",
                                "plural": false,
                                "selections": (v5 /*: any*/),
                                "storageKey": "absenceTime(unit:\"HOUR\")"
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimeSummaryByEmployment",
                        "kind": "LinkedField",
                        "name": "employmentSummaries",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Employment",
                                "kind": "LinkedField",
                                "name": "employment",
                                "plural": false,
                                "selections": (v6 /*: any*/),
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeReportSummaryByEmployment",
                                "kind": "LinkedField",
                                "name": "timeReportSummary",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": (v2 /*: any*/),
                                        "concreteType": "TimeSpanUnitValue",
                                        "kind": "LinkedField",
                                        "name": "expectedWorkTime",
                                        "plural": false,
                                        "selections": (v5 /*: any*/),
                                        "storageKey": "expectedWorkTime(unit:\"HOUR\")"
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TimeReportSummaryByEmploymentAndEvent",
                                        "kind": "LinkedField",
                                        "name": "eventSummaries",
                                        "plural": true,
                                        "selections": [
                                            (v8 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "isWork",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "TimeReportSummaryByEmploymentAndEventPartsSummary",
                                                "kind": "LinkedField",
                                                "name": "nonRegularPartsSummary",
                                                "plural": true,
                                                "selections": [
                                                    (v8 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "unit",
                                                                "value": "SECOND"
                                                            }
                                                        ],
                                                        "concreteType": "TimeSpanUnitValue",
                                                        "kind": "LinkedField",
                                                        "name": "duration",
                                                        "plural": false,
                                                        "selections": (v5 /*: any*/),
                                                        "storageKey": "duration(unit:\"SECOND\")"
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            (v4 /*: any*/),
                                            (v3 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompensatoryTimesByTimeStore",
                        "kind": "LinkedField",
                        "name": "compensatoryTimes",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "EmploymentSetting",
                                "kind": "LinkedField",
                                "name": "employmentSetting",
                                "plural": false,
                                "selections": (v6 /*: any*/),
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CompensatoryTimeSetting",
                                "kind": "LinkedField",
                                "name": "timeStore",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Event",
                                        "kind": "LinkedField",
                                        "name": "timeStoreEvent",
                                        "plural": false,
                                        "selections": (v7 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "CompensatoryTime",
                                "kind": "LinkedField",
                                "name": "times",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "description",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": (v2 /*: any*/),
                                        "concreteType": "TimeSpanUnitValue",
                                        "kind": "LinkedField",
                                        "name": "time",
                                        "plural": false,
                                        "selections": (v5 /*: any*/),
                                        "storageKey": "time(unit:\"HOUR\")"
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "totalTime",
                                "plural": false,
                                "selections": (v5 /*: any*/),
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ISchedulableEntity",
        "abstractKey": "__isISchedulableEntity"
    };
})();
node.hash = "661f4a1ed5a45bd9311f1ade2bd3d03d";
export default node;
