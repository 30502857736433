import React, { useState, Suspense, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { faSync, faCalendarEdit } from '@fortawesome/pro-regular-svg-icons';
import { useIntl } from 'react-intl';
import { add } from 'date-fns';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { Drawer, Flexbox, Skeleton, Field, IntervalInput, MultiSelect, ToggleButtonGroup, DrawerHeader, IconButton, Placeholder, } from '@sonika-se/ui-kit/components';
import { ScheduleTemplateInformationResource, scheduleTemplateInformationResourceQuery, } from './components/ScheduleTemplateInformationResource';
import { ScheduleTemplateInformationPeriods, scheduleTemplateInformationPeriodsQuery, } from './components/ScheduleTemplateInformationPeriods';
const useStyles = createUseStyles((theme) => {
    const { pxToRem, spacing } = theme.helpers;
    return {
        root: {},
        tabs: {
            marginTop: pxToRem(3),
            padding: `0 ${pxToRem(24)}`,
        },
        top: {
            borderBottom: '1px solid #D5D6D8',
            padding: `${pxToRem(24)} ${pxToRem(24)}`,
        },
        title: {
            fontSize: pxToRem(20),
            fontWeight: 600,
            '& span': {
                color: '#6A6A6A',
                display: 'block',
                marginBottom: spacing(1),
                fontSize: pxToRem(14),
            },
        },
        buttonGroup: {
            '&> *': {
                flex: 1,
                textAlign: 'center',
            },
        },
        noDataContainer: {
            height: '100%',
            '& > *': {
                margin: 'auto',
                textAlign: 'center',
                '& > svg': {
                    color: theme.colors.primary,
                    fontSize: '4rem',
                    marginBottom: '2rem',
                },
            },
        },
    };
});
const ScheduleTemplateInformationDrawer = (props) => {
    var _a, _b;
    const { onClose, open, scheduleTemplate, selectedPeriodId } = props;
    const data = useFragment(graphql `
      fragment ScheduleTemplateInformationDrawer on ScheduleTemplate {
        id
        preliminaryStartDate
        periods(first: null, order: { order: ASC }) @connection(key: "ScheduleTemplate_periods") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, scheduleTemplate);
    const [selectedPeriod, setSelectedPeriod] = useState(selectedPeriodId);
    const [subTab, setSubTab] = useState('date');
    useEffect(() => {
        setSelectedPeriod(selectedPeriodId);
    }, [selectedPeriodId]);
    const [scheduleTemplateInformationPeriodsQueryRef, loadScheduleTemplateInformationPeriodsQuery, disposeScheduleTemplateInformationPeriodsQuery,] = useQueryLoader(scheduleTemplateInformationPeriodsQuery);
    const [scheduleTemplateInformationResourceQueryRef, loadScheduleTemplateInformationResourceQuery, disposeScheduleTemplateInformationResourceQuery,] = useQueryLoader(scheduleTemplateInformationResourceQuery);
    const [date, setDate] = useState({
        fromDate: new Date(data === null || data === void 0 ? void 0 : data.preliminaryStartDate),
        toDate: add(new Date(data === null || data === void 0 ? void 0 : data.preliminaryStartDate), { months: 1 }),
    });
    const [selectedTab, setSelectedTab] = useState('USER');
    const [update, setUpdate] = useState();
    const intl = useIntl();
    const classes = useStyles();
    useEffect(() => {
        if (selectedPeriod) {
            if (subTab === 'periods') {
                loadScheduleTemplateInformationPeriodsQuery({
                    typeFilter: [selectedTab],
                    id: data.id,
                });
            }
            else if (subTab === 'date') {
                loadScheduleTemplateInformationResourceQuery({
                    endDate: date.toDate,
                    startDate: date.fromDate,
                    typeFilter: [selectedTab],
                    id: data.id,
                    startPeriodId: selectedPeriod,
                });
            }
        }
    }, [data, selectedPeriod, subTab]);
    return (<Drawer open={open} onClose={() => {
            onClose();
        }}>
      <DrawerHeader tabs={{
            initialValue: 'USER',
            onChange: (value) => {
                setSelectedTab(value);
            },
            tabs: [
                {
                    key: 'USER',
                    label: intl.formatMessage({
                        id: 'general.objects.user.name.plural',
                        defaultMessage: 'Användare',
                    }),
                    value: 'USER',
                },
                {
                    key: 'ASSET',
                    label: intl.formatMessage({
                        id: 'general.objects.asset.name.plural',
                        defaultMessage: 'Enheter',
                    }),
                    value: 'ASSET',
                },
            ],
        }} title={intl.formatMessage({
            defaultMessage: 'Schemainformation',
            id: 'general.misc.schedule_information.name.singular',
        })}/>
      <div className={classes.top}>
        <Flexbox gap="xsmall">
          <ToggleButtonGroup type="radio" className={classes.buttonGroup} defaultValue={subTab} onChange={(event) => {
            setSubTab(event.target.value);
        }} options={[
            {
                label: intl.formatMessage({
                    defaultMessage: 'Datumintervall',
                    id: 'general.misc.schedule_information.fields.date_interval.label',
                }),
                value: 'date',
            },
            {
                label: intl.formatMessage({
                    defaultMessage: 'Perioder',
                    id: 'general.misc.period.name.plural',
                }),
                value: 'periods',
            },
        ]} style={{
            flex: 1,
        }}/>
          <IconButton appearance="text" onClick={() => {
            setUpdate(Date.now());
        }} icon={faSync}/>
        </Flexbox>
        {subTab === 'date' && (<div>
            <Flexbox justify="equal" gap="xsmall" style={{ marginTop: '1.5rem' }}>
              <Field label={intl.formatMessage({
                defaultMessage: 'Datumintervall',
                id: 'general.misc.schedule_information.fields.date_interval.label',
            })}>
                <IntervalInput value={date} onChange={(date) => setDate(date)}/>
              </Field>
              <Field label={intl.formatMessage({
                defaultMessage: 'Startperiod',
                id: 'general.objects.schedule.fields.start_period.label',
            })}>
                <MultiSelect placeholder={intl.formatMessage({
                defaultMessage: 'Välj period',
                id: 'general.misc.period.actions.select',
            })} defaultValue={selectedPeriod} isSearchable={false} options={(_b = (_a = data === null || data === void 0 ? void 0 : data.periods) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(({ node: period }) => ({ value: period.id, label: period.name }))} onChange={(value) => setSelectedPeriod(value ? value[0] : undefined)}/>
              </Field>
            </Flexbox>
          </div>)}
      </div>
      {subTab === 'date' && selectedPeriod && scheduleTemplateInformationResourceQueryRef && (<Suspense fallback={<Skeleton type="table" height="100%"/>}>
          <ScheduleTemplateInformationResource date={{ endDate: date.toDate, startDate: date.fromDate }} selectedPeriodId={selectedPeriod} type={selectedTab} open={open} id={data.id} update={update} preloadedQuery={scheduleTemplateInformationResourceQueryRef}/>
        </Suspense>)}
      {subTab === 'periods' && scheduleTemplateInformationPeriodsQueryRef && (<Suspense fallback={<Skeleton type="table" height="100%"/>}>
          <ScheduleTemplateInformationPeriods date={{ endDate: date.toDate, startDate: date.fromDate }} selectedPeriodId={selectedPeriod} type={selectedTab} open={open} id={data.id} update={update} preloadedQuery={scheduleTemplateInformationPeriodsQueryRef}/>
        </Suspense>)}
      {subTab === 'date' && !selectedPeriod && (<Placeholder height="full" icon={faCalendarEdit} text={intl.formatMessage({
                id: 'ScheduleTemplateDetailsPage.ScheduleTemplateInformationDrawer.no_periods',
                defaultMessage: 'Det finns inga perioder i schemat',
            })}/>)}
    </Drawer>);
};
export default ScheduleTemplateInformationDrawer;
