import React, { useEffect, useRef } from 'react';
import { Button, Flexbox, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import ScheduleShiftVacancyCard from '../cards/ScheduleShiftVacancyCard';
export const ScheduleShiftVacancyList = (props) => {
    const { model, queryRef, where } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment ScheduleShiftVacancyList_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 50 }
        model: { type: "GetRunningScheduleRequestInput" }
        where: { type: "ScheduleShiftFilterInput", defaultValue: { assignedEntityId: { eq: null } } }
      )
      @refetchable(queryName: "ScheduleShiftVacancyList_queryQuery") {
        scheduleShifts(after: $after, first: $first, model: $model, where: $where)
          @connection(key: "ScheduleShiftVacancyList_query_scheduleShifts") {
          edges {
            node {
              ...ScheduleShiftVacancyCard_scheduleShift
              id
            }
          }
        }
      }
    `, queryRef);
    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }
        refetch({
            model,
            where: Object.assign({ assignedEntityId: {
                    eq: null,
                } }, where),
        }, {
            fetchPolicy: 'network-only',
        });
    }, [model, where]);
    return data.scheduleShifts.edges.length === 0 ? (<Placeholder text="Inga vakanta pass."/>) : (<Flexbox direction="column" gap="large">
      <Flexbox direction="column" gap="medium">
        {data.scheduleShifts.edges.map(({ node: scheduleShift }) => (<ScheduleShiftVacancyCard key={scheduleShift.id} scheduleShiftRef={scheduleShift}/>))}
      </Flexbox>
      {hasNext && (<Button disabled={isLoadingNext} onClick={() => {
                loadNext(50);
            }}>
          Ladda fler
        </Button>)}
    </Flexbox>);
};
export default ScheduleShiftVacancyList;
