/**
 * @generated SignedSource<<f4f298192890bd726f570d9f1a809d71>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "selectedTagIds"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ], v1 = {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v4 = {
        "alias": "selectedTags",
        "args": [
            {
                "fields": [
                    {
                        "fields": [
                            {
                                "kind": "Variable",
                                "name": "in",
                                "variableName": "selectedTagIds"
                            }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                    }
                ],
                "kind": "ObjectValue",
                "name": "where"
            }
        ],
        "concreteType": "TagConnection",
        "kind": "LinkedField",
        "name": "tags",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                    (v2 /*: any*/),
                    (v3 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v5 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 20
        },
        {
            "kind": "Literal",
            "name": "order",
            "value": {
                "name": "ASC"
            }
        },
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TagsFilterQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "TagsFilterListFragment_query"
                },
                (v4 /*: any*/)
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TagsFilterQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "TagConnection",
                    "kind": "LinkedField",
                    "name": "tags",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalCount",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TagEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Tag",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "filters": [
                        "where",
                        "order"
                    ],
                    "handle": "connection",
                    "key": "TagsFilterList_tags",
                    "kind": "LinkedHandle",
                    "name": "tags"
                },
                (v4 /*: any*/)
            ]
        },
        "params": {
            "cacheID": "cc655563fda706aacbe33eb3b20b9947",
            "id": null,
            "metadata": {},
            "name": "TagsFilterQuery",
            "operationKind": "query",
            "text": "query TagsFilterQuery(\n  $selectedTagIds: [ID]!\n  $where: TagFilterInput\n) {\n  ...TagsFilterListFragment_query_3FC4Qo\n  selectedTags: tags(where: {id: {in: $selectedTagIds}}) {\n    nodes {\n      id\n      name\n    }\n  }\n}\n\nfragment TagsFilterListFragment_query_3FC4Qo on Query {\n  tags(where: $where, order: {name: ASC}, first: 20) {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "e1dc6b96b24831d1616c81601976db04";
export default node;
