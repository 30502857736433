/**
 * @generated SignedSource<<360d101b12948aff4e6e6757b5553d52>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "messageId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "messageId",
            "variableName": "messageId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "QueuedJobStatus_queuedJobQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "QueuedJobStatus_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "QueuedJobStatus_queuedJobQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "QueuedJob",
                    "kind": "LinkedField",
                    "name": "queuedJobByMessageId",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTime",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTime",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "QueuedJobAttachment",
                            "kind": "LinkedField",
                            "name": "attachments",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "5f02b7a7924086af0311ae5447ea519a",
            "id": null,
            "metadata": {},
            "name": "QueuedJobStatus_queuedJobQuery",
            "operationKind": "query",
            "text": "query QueuedJobStatus_queuedJobQuery(\n  $messageId: String!\n) {\n  ...QueuedJobStatus_query_31kHET\n}\n\nfragment QueuedJobStatus_query_31kHET on Query {\n  queuedJobByMessageId(messageId: $messageId) {\n    id\n    status\n    startTime\n    endTime\n    attachments {\n      name\n      url\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "900bf6fd99f3010df08ff1e36885fddc";
export default node;
