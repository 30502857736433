import React, { Suspense, useMemo } from 'react';
import { ModalToSheetSkeleton, Panel, PanelButton, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import ScheduleAssignmentTypeResourceTable from '~/tables/ScheduleAssignmentTypeResourceTable';
import { ScheduleAssignmentTypeResourcesUpdateModal, scheduleAssignmentTypeResourcesUpdateModalQuery, } from '~/modals/ScheduleAssignmentTypeResourcesUpdateModal';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
export const ScheduleAssignmentTypeResourceTablePanel = (props) => {
    const { scheduleAssignmentTypeRef } = props;
    const scheduleAssignmentType = useFragment(graphql `
      fragment ScheduleAssignmentTypeResourceTablePanel_scheduleAssignmentType on ScheduleAssignmentType {
        ...ScheduleAssignmentTypeResourceTable_scheduleAssignmentType
        assignmentType {
          title
        }
        id
        schedule {
          currentUserPermissions
        }
      }
    `, scheduleAssignmentTypeRef);
    const [scheduleAssignmentTypeResourcesUpdateModalQueryRef, loadScheduleAssignmentTypeResourcesUpdateModalQuery, disposeScheduleAssignmentTypeResourcesUpdateModalQuery,] = useQueryLoader(scheduleAssignmentTypeResourcesUpdateModalQuery);
    const canEditSchedules = usePermissions()(['EDIT_SCHEDULES']);
    const canEditAssignmentTypes = useMemo(() => canEditSchedules || scheduleAssignmentType.schedule.currentUserPermissions.includes('EDIT_ASSIGNMENT_TYPES'), [canEditSchedules, scheduleAssignmentType]);
    return (<>
      <Panel actions={canEditAssignmentTypes && (<PanelButton onClick={() => {
                loadScheduleAssignmentTypeResourcesUpdateModalQuery({
                    scheduleAssignmentTypeId: scheduleAssignmentType.id,
                }, {
                    fetchPolicy: 'network-only',
                });
            }}>
              Ändra
            </PanelButton>)} title={scheduleAssignmentType.assignmentType.title}>
        <Suspense fallback={<Skeleton type="table"/>}>
          <ScheduleAssignmentTypeResourceTable scheduleAssignmentTypeRef={scheduleAssignmentType}/>
        </Suspense>
      </Panel>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {scheduleAssignmentTypeResourcesUpdateModalQueryRef && (<ScheduleAssignmentTypeResourcesUpdateModal onClose={() => {
                disposeScheduleAssignmentTypeResourcesUpdateModalQuery();
            }} preloadedQuery={scheduleAssignmentTypeResourcesUpdateModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleAssignmentTypeResourceTablePanel;
