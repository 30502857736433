import React, { useEffect } from 'react';
import { graphql } from 'react-relay';
import { usePreloadedQuery, useRefetchableFragment } from 'react-relay/hooks';
import { Divider, Placeholder } from '@sonika-se/ui-kit/components';
import { faCalendarEdit } from '@fortawesome/pro-regular-svg-icons';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import { ScheduleTemplateInformationPeriodTable } from './ScheduleTemplateInformationPeriodTable';
export const scheduleTemplateInformationPeriodsQuery = graphql `
  query ScheduleTemplateInformationPeriodsQuery($id: ID!, $typeFilter: [SchedulableEntityType!]) {
    ...ScheduleTemplateInformationPeriods_periods @arguments(typeFilter: $typeFilter, id: $id)
  }
`;
const useStyles = createUseStyles((theme) => ({
    root: {
        overflowY: 'auto',
        background: '#F8F9FA',
        height: '100%',
    },
    noDataContainer: {
        height: '100%',
        '& > *': {
            margin: 'auto',
            textAlign: 'center',
            '& > svg': {
                color: theme.colors.primary,
                fontSize: '4rem',
                marginBottom: '2rem',
            },
        },
    },
}));
export const ScheduleTemplateInformationPeriods = (props) => {
    const { date, type, preloadedQuery, selectedPeriodId, id, open, update } = props;
    const classes = useStyles();
    const intl = useIntl();
    const periods = usePreloadedQuery(scheduleTemplateInformationPeriodsQuery, preloadedQuery);
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment ScheduleTemplateInformationPeriods_periods on Query
      @argumentDefinitions(id: { type: "ID!" }, typeFilter: { type: "[SchedulableEntityType!]" })
      @refetchable(queryName: "ScheduleTemplateInformationperiods_periodsQuery") {
        scheduleTemplateTimesPerPeriod(scheduleTemplateId: $id, typeFilter: { types: $typeFilter }) {
          periods {
            period {
              id
              name
            }
            timesByEntityAndAssignmentType {
              assignedDurationWithoutUnpaidBreaks(unit: HOUR) {
                unit
                value
              }
              assignmentType {
                id
                title
              }
              schedulableEntity {
                ... on ISchedulableEntity {
                  id
                  color
                  displayName
                }
              }
            }
          }
        }
      }
    `, periods);
    useEffect(() => {
        if (!selectedPeriodId)
            return;
        refetch({
            selectedPeriodId,
            endDate: date.endDate,
            startDate: date.startDate,
            typeFilter: [type],
            id,
        }, { fetchPolicy: 'network-only' });
    }, [date, selectedPeriodId, update, type]);
    return (<div className={classes.root}>
      {data.scheduleTemplateTimesPerPeriod.periods.length !== 0 &&
            data.scheduleTemplateTimesPerPeriod.periods[0].timesByEntityAndAssignmentType.length !== 0 &&
            data.scheduleTemplateTimesPerPeriod.periods.map((period) => {
                return (<div key={`schedule_time_information_${period.period.id}`}>
              <ScheduleTemplateInformationPeriodTable period={period} templateId={id} type={type}/>
              <Divider />
            </div>);
            })}
      {(data.scheduleTemplateTimesPerPeriod.periods.length === 0 ||
            data.scheduleTemplateTimesPerPeriod.periods[0].timesByEntityAndAssignmentType.length === 0) && (<Placeholder height="full" icon={faCalendarEdit} text={data.scheduleTemplateTimesPerPeriod.periods.length === 0
                ? intl.formatMessage({
                    defaultMessage: 'Det finns inga perioder i schemat',
                    id: 'ScheduleTemplateDetailsPage.ScheduleTemplateInformationPeriods.no_periods_in_schedule',
                })
                : intl.formatMessage({
                    defaultMessage: 'Det finns inga pass för {type} i schemat',
                    id: 'ScheduleTemplateDetailsPage.ScheduleTemplateInformationPeriods.no_shifts_for_type_in_schedule',
                }, {
                    type: type === 'USER'
                        ? intl.formatMessage({ id: 'general.objects.user.name.plural', defaultMessage: 'Användare' })
                        : intl.formatMessage({ id: 'general.objects.asset.name.plural', defaultMessage: 'Enheter' }),
                })}/>)}
    </div>);
};
