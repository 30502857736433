import React from 'react';
import { usePreloadedQuery, graphql, useMutation } from 'react-relay/hooks';
import { formatISO, isWithinInterval } from 'date-fns';
import { ModalToSheet, ModalToSheetContent, ModalToSheetHeader } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import { AddScheduleShiftForm } from './AddScheduleShiftForm';
import { toDate } from '@sonika-se/ui-kit/utils/time';
export const addScheduleShiftModalQuery = graphql `
  query AddScheduleShiftModalQuery {
    ...AddScheduleShiftForm_query
  }
`;
export const AddScheduleShiftModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const intl = useIntl();
    const data = usePreloadedQuery(addScheduleShiftModalQuery, preloadedQuery);
    const [createScheduleShifts, createScheduleShiftsLoading] = useMutation(graphql `
        mutation AddScheduleShiftModal_createScheduleShiftsMutation($model: [CreateScheduleShiftRequestInput!]!) {
          createScheduleShifts(model: $model) {
            shiftEdge {
              node {
                id
              }
            }
          }
        }
      `);
    return (<ModalToSheet onClose={() => {
            onClose(false);
        }} size="large">
      <ModalToSheetHeader onClose={() => {
            onClose(false);
        }} closeButtonLabel="Avbryt">
        Lägg till pass
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <AddScheduleShiftForm disabled={createScheduleShiftsLoading} onCancel={() => {
            onClose(false);
        }} onSubmit={(form) => {
            const model = [];
            form.dates.map((d) => {
                form.assignees.map((assignee) => {
                    var _a;
                    model.push({
                        eventId: assignee.eventId,
                        assignedEntityId: !assignee.assignedEntityId || assignee.assignedEntityId === 'undefined'
                            ? null
                            : assignee.assignedEntityId,
                        assignmentTypeId: assignee.assignmentTypeId,
                        breaks: form.breaks
                            ? (_a = form.breaks) === null || _a === void 0 ? void 0 : _a.filter((b) => isWithinInterval(new Date(b.start), { start: new Date(d.start), end: new Date(d.end) }) &&
                                isWithinInterval(new Date(b.end), { start: new Date(d.start), end: new Date(d.end) })).map((x) => ({
                                end: formatISO(toDate(x.end)),
                                start: formatISO(toDate(x.start)),
                                type: x.type,
                            }))
                            : null,
                        end: formatISO(toDate(d.end)),
                        scheduleId: form.scheduleId,
                        start: formatISO(toDate(d.start)),
                    });
                });
            });
            createScheduleShifts({
                onCompleted: () => {
                    onClose();
                },
                onError: (error) => {
                    console.log({ error });
                },
                variables: {
                    model,
                },
            });
        }} query={data}/>
      </ModalToSheetContent>
    </ModalToSheet>);
};
