import React, { useState, Suspense, useEffect, useContext, useMemo, useTransition } from 'react';
import { graphql } from 'react-relay';
import { usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { formatISO } from 'date-fns';
import { usePermissions, useViewport, useToasts } from '@sonika-se/ui-kit/hooks';
import { AppContext, IconButton, Skeleton, Tooltip } from '@sonika-se/ui-kit/components';
import { toDate as toDateFn } from '@sonika-se/ui-kit/utils/time';
import { useIntl } from 'react-intl';
import { faInfo, faTimes, faUserClock } from '@fortawesome/pro-regular-svg-icons';
import { faInfo as faInfoSolid } from '@fortawesome/pro-solid-svg-icons';
import { ScheduleLayout, ScheduleLayoutHeader, ScheduleLayoutMain, ScheduleLayoutContent, } from '../components/ScheduleLayout/ScheduleLayout';
import { RunningSchedulesHeader } from './components/RunningSchedulesHeader/RunningSchedulesHeader';
import { RunningSchedulesPageContextProvider, defaultScheduleTemplateDetailsPagevalues, } from './RunningSchedulesPageContext';
import RunningSchedulesInformationDrawer from './components/RunningSchedulesInformationDrawer/RunningSchedulesInformationDrawer';
import { RunningSchedulesHeaderMobile } from './components/RunningSchedulesHeaderMobile/RunningSchedulesHeaderMobile';
import RunningSchedulesEditContainer from './components/RunningSchedulesEditContainer/RunningSchedulesEditContainer';
import RunningSchedulesGrid from './components/RunningSchedulesGrid/RunningSchedulesGrid';
import { createChangeRequestFiltersFromUrl, createRunningSchedulesFilterFromUrl, getDefaultRunningSchedulesFilterContextFromUrl, setRunningSchedulesFilterContextToUrl, } from '../utils/filters';
import ChangeRequestDropdown from '../components/ChangeRequestDropdown/ChangeRequestDropdown';
import SchedulableResourceFilter from '~/components/SchedulableResourceFilter/SchedulableResourceFilter';
import RunningSchedulesScheduleFilter from './components/RunningSchedulesScheduleFilter/RunningSchedulesScheduleFilter';
import RunningSchedulesSaveSearch from './components/RunningSchedulesSaveSearch/RunningSchedulesSaveSearch';
import RunningSchedulePrintSchedule from './components/RunningSchedulePrintSchedule/RunningSchedulePrintSchedule';
import TagsFilter from '~/components/TagsFilter/TagsFilter';
import ScheduleShiftVacancyDrawer, { scheduleShiftVacancyDrawerQuery } from '~/drawers/ScheduleShiftVacancyDrawer';
import ScheduleShiftClashDrawerAction from '~/actions/ScheduleShiftClashDrawerAction';
import ScheduleShiftVacancyDrawerAction from '~/actions/ScheduleShiftVacancyDrawerAction';
import ErrorBoundary from '~/shared/components/ErrorBoundary';
export const runningSchedulesPageQuery = graphql `
  query RunningSchedulesPageQuery(
    $fromDate: DateTime!
    $toDate: DateTime!
    $where: ScheduleShiftFilterInput
    $whereSchedules: ScheduleFilterInput
    $shiftsFilter: ScheduleShiftsFilterInput
    $filterModel: ScheduleFilterRequestInput
  ) {
    currentCompany {
      id
    }
    currentUser {
      me {
        id
        ...RunningSchedulesHeaderMobile_user
      }
    }
    ...RunningSchedulesGrid_schedules
      @arguments(
        fromDate: $fromDate
        toDate: $toDate
        whereSchedules: $whereSchedules
        where: $where
        shiftsFilter: $shiftsFilter
        filterModel: $filterModel
      )
    ...RunningSchedulesHeader_query
    ...RunningSchedulesInformationDrawer
  }
`;
export const RunningSchedulesPage = () => {
    const hasPermissions = usePermissions();
    const { currentUser } = useContext(AppContext);
    const [queryRef, loadQuery, disposeQuery] = useQueryLoader(runningSchedulesPageQuery);
    useEffect(() => {
        loadQuery(Object.assign(Object.assign({}, createRunningSchedulesFilterFromUrl()), createChangeRequestFiltersFromUrl(hasPermissions(['EDIT_SCHEDULES']) ? undefined : currentUser.id)));
        return () => {
            disposeQuery();
        };
    }, []);
    if (queryRef === null)
        return null;
    return <RunningSchedulesPageContent queryRef={queryRef}/>;
};
export const RunningSchedulesPageContent = (props) => {
    var _a, _b, _c;
    const hasPermissions = usePermissions();
    const data = usePreloadedQuery(runningSchedulesPageQuery, props.queryRef);
    const intl = useIntl();
    const useDefaultSearch = JSON.parse(localStorage.getItem('useDefaultSearch'));
    const defaultSearch = JSON.parse(localStorage.getItem('defaultSearch'));
    const defaultSearchDates = JSON.parse(localStorage.getItem('defaultSearchDates'));
    const [context, setContext] = useState(Object.assign(Object.assign(Object.assign(Object.assign({}, defaultScheduleTemplateDetailsPagevalues), getDefaultRunningSchedulesFilterContextFromUrl()), { companyId: data.currentCompany.id, defaultSearch: Object.assign(Object.assign({}, defaultScheduleTemplateDetailsPagevalues.defaultSearch), { defaultSearchSaved: localStorage.getItem('defaultSearchSaved'), defaultSearch: defaultSearch, defaultSearchDates: defaultSearchDates, useDefaultSearch: !!useDefaultSearch }) }), (!!useDefaultSearch
        ? {
            filters: defaultSearch || defaultScheduleTemplateDetailsPagevalues.filters,
            toDate: (defaultSearchDates === null || defaultSearchDates === void 0 ? void 0 : defaultSearchDates.toDate) || defaultScheduleTemplateDetailsPagevalues.toDate,
            fromDate: (defaultSearchDates === null || defaultSearchDates === void 0 ? void 0 : defaultSearchDates.fromDate) || defaultScheduleTemplateDetailsPagevalues.fromDate,
        }
        : {})));
    if (useDefaultSearch) {
        setRunningSchedulesFilterContextToUrl(context);
    }
    const [refetchGrid, setRefetchGrid] = useState();
    const updateContextFilters = (context) => {
        setRunningSchedulesFilterContextToUrl(context);
        setContext(context);
    };
    const { size: pageSize } = useViewport();
    const { addToast } = useToasts();
    const onDateChange = (date) => {
        localStorage.setItem('useDefaultSearch', `${false}`);
        updateContextFilters(Object.assign(Object.assign({}, context), { fromDate: formatISO(date.fromDate, { representation: 'date' }), toDate: formatISO(date.toDate, { representation: 'date' }), highlightedShifts: {}, highlightedSchedulableEntities: [], defaultSearch: Object.assign(Object.assign({}, context.defaultSearch), { useDefaultSearch: false }) }));
    };
    const onToggleInformationDrawer = () => {
        var _a;
        setContext(Object.assign(Object.assign({}, context), { informationDrawer: Object.assign(Object.assign({}, context.informationDrawer), { show: !((_a = context.informationDrawer) === null || _a === void 0 ? void 0 : _a.show) }) }));
    };
    const updateFilters = (filters, name) => {
        localStorage.setItem('useDefaultSearch', `${false}`);
        updateContextFilters(Object.assign(Object.assign({}, context), { filters: Object.assign(Object.assign({}, context.filters), { [name]: filters }), defaultSearch: Object.assign(Object.assign({}, context.defaultSearch), { useDefaultSearch: false }) }));
    };
    const canEditSchedules = useMemo(() => hasPermissions(['EDIT_SCHEDULES']), []);
    const changeRequestVariables = useMemo(() => {
        const { assignmentTypeIds, onlyVacant, resourceIds, scheduleIds } = context.filters;
        return {
            assignmentTypesFilter: assignmentTypeIds,
            entitiesFilter: {
                assignedResources: resourceIds,
                vacant: onlyVacant,
            },
            fromDate: context.fromDate,
            schedulesFilter: scheduleIds,
            toDate: context.toDate,
            whereChangeRequest: canEditSchedules
                ? undefined
                : {
                    requestedByUserId: { in: [data.currentUser.me.id] },
                },
        };
    }, [context, data]);
    const [scheduleShiftVacancyDrawerQueryRef, loadScheduleShiftVacancyDrawerQuery, disposeScheduleShiftVacancyDrawerQuery,] = useQueryLoader(scheduleShiftVacancyDrawerQuery);
    const [isLoadingScheduleShiftVacancyDrawerQuery, startScheduleShiftVacancyDrawerQueryTransition] = useTransition();
    const resourceFilters = useMemo(() => (<>
        <SchedulableResourceFilter updateFilters={updateFilters} title={intl.formatMessage({
            id: 'general.objects.asset.name.plural',
            defaultMessage: 'Enheter',
        })} type="ASSET" withColorSelect selectedResourceIds={context.filters.resourceIds}/>
        <SchedulableResourceFilter updateFilters={updateFilters} title={intl.formatMessage({
            id: 'general.objects.user.name.plural',
            defaultMessage: 'Användare',
        })} type="USER" withColorSelect selectedResourceIds={context.filters.resourceIds}/>
        <RunningSchedulesScheduleFilter updateFilters={updateFilters}/>
      </>), [context.filters.resourceIds]);
    return (<>
      <RunningSchedulesPageContextProvider value={context}>
        <ScheduleLayout>
          <ScheduleLayoutHeader>
            {pageSize === 'small' ? (<RunningSchedulesHeaderMobile headerElements={<>{resourceFilters}</>} onDateChange={onDateChange} userRef={data.currentUser.me}/>) : (<RunningSchedulesHeader headerElements={<>
                    <Suspense fallback={<IconButton color="danger" disabled icon={faUserClock}/>}>
                      <ChangeRequestDropdown userId={hasPermissions(['EDIT_SCHEDULES']) ? undefined : data.currentUser.me.id} variables={changeRequestVariables}/>
                    </Suspense>
                    {resourceFilters}
                    <Suspense fallback={null}>
                      <TagsFilter selectedTagIds={context.filters.tagIds} title="Taggar" updateFilters={updateFilters}/>
                    </Suspense>
                    <RunningSchedulesSaveSearch saveDefaultSearch={(useDefaultSearch) => {
                    const date = new Date();
                    localStorage.setItem('defaultSearchSaved', date.toString());
                    localStorage.setItem('defaultSearch', JSON.stringify(context.filters));
                    localStorage.setItem('defaultSearchDates', JSON.stringify({
                        toDate: context.toDate,
                        fromDate: context.fromDate,
                    }));
                    setContext(Object.assign(Object.assign({}, context), { defaultSearch: Object.assign(Object.assign({}, context.defaultSearch), { useDefaultSearch, defaultSearchSaved: date.toString(), defaultSearch: Object.assign({}, context.filters), defaultSearchDates: {
                                toDate: context.toDate,
                                fromDate: context.fromDate,
                            } }) }));
                    addToast(intl.formatMessage({
                        defaultMessage: 'Sökning sparad',
                        id: 'RunningSchedulesPage.save_search',
                    }), {
                        color: 'success',
                    });
                }} displayDefaultSearch={(display) => {
                    var _a, _b, _c, _d, _e;
                    localStorage.setItem('useDefaultSearch', `${display}`);
                    if (display) {
                        updateContextFilters(Object.assign(Object.assign({}, context), { defaultSearch: Object.assign(Object.assign({}, context.defaultSearch), { useDefaultSearch: display }), filters: (_a = context === null || context === void 0 ? void 0 : context.defaultSearch) === null || _a === void 0 ? void 0 : _a.defaultSearch, toDate: ((_c = (_b = context === null || context === void 0 ? void 0 : context.defaultSearch) === null || _b === void 0 ? void 0 : _b.defaultSearchDates) === null || _c === void 0 ? void 0 : _c.toDate) || context.toDate, fromDate: ((_e = (_d = context === null || context === void 0 ? void 0 : context.defaultSearch) === null || _d === void 0 ? void 0 : _d.defaultSearchDates) === null || _e === void 0 ? void 0 : _e.fromDate) || context.fromDate }));
                    }
                    else {
                        updateContextFilters(Object.assign(Object.assign({}, context), { defaultSearch: Object.assign(Object.assign({}, context.defaultSearch), { useDefaultSearch: display }), filters: defaultScheduleTemplateDetailsPagevalues.filters, toDate: defaultScheduleTemplateDetailsPagevalues.toDate, fromDate: defaultScheduleTemplateDetailsPagevalues.fromDate }));
                    }
                }}/>
                    <RunningSchedulePrintSchedule />
                    {canEditSchedules && (<>
                        <ErrorBoundary onError={(error) => {
                        return (<Tooltip content="Kunde inte hämta dubbelbokningar, prova att ladda om sidan">
                                <IconButton icon={faTimes} color="danger"/>
                              </Tooltip>);
                    }}>
                          <ScheduleShiftClashDrawerAction fromDate={toDateFn(context.fromDate)} toDate={toDateFn(context.toDate)}/>
                        </ErrorBoundary>
                        <ErrorBoundary onError={(error) => {
                        return (<Tooltip content="Kunde inte hämta vakanta pass, prova att ladda om sidan">
                                <IconButton icon={faTimes} color="danger"/>
                              </Tooltip>);
                    }}>
                          <ScheduleShiftVacancyDrawerAction fromDate={toDateFn(context.fromDate)} toDate={toDateFn(context.toDate)}/>
                        </ErrorBoundary>
                      </>)}
                    {hasPermissions(['EDIT_COMPANY']) && (<>
                        <Tooltip content={intl.formatMessage({
                        defaultMessage: 'Schemainformation',
                        id: 'general.misc.schedule_information.name.singular',
                    })}>
                          <IconButton appearance={((_a = context.informationDrawer) === null || _a === void 0 ? void 0 : _a.show) ? 'filled' : 'outlined'} icon={((_b = context.informationDrawer) === null || _b === void 0 ? void 0 : _b.show) ? faInfoSolid : faInfo} onClick={() => {
                        onToggleInformationDrawer();
                    }}/>
                        </Tooltip>
                      </>)}
                  </>} queryRef={data} onDateChange={onDateChange} setRefetchGrid={() => {
                setRefetchGrid(Date.now());
            }}/>)}
          </ScheduleLayoutHeader>
          <ScheduleLayoutMain>
            <Suspense fallback={<Skeleton type="table" height="100%" style={{ minWidth: '375px' }}/>}>
              <RunningSchedulesEditContainer setRefetchGrid={() => {
            setRefetchGrid(Date.now());
        }} setHighlightedSchedulableEntities={(highlighted) => {
            setContext(Object.assign(Object.assign({}, context), { highlightedSchedulableEntities: highlighted }));
        }} unsetAllHighlighted={() => {
            setContext(Object.assign(Object.assign({}, context), { highlightedSchedulableEntities: [], highlightedShifts: {} }));
        }}/>
            </Suspense>
            <ScheduleLayoutContent border={true} padding={false}>
              <Suspense fallback={<Skeleton height="100%" type="page"/>}>
                <RunningSchedulesGrid refetch={refetchGrid} queryRef={data} setSelectedShifts={(highlightedShifts) => setContext(Object.assign(Object.assign({}, context), { highlightedShifts }))} resetFilters={() => {
            updateContextFilters(Object.assign(Object.assign({}, context), { filters: Object.assign({}, defaultScheduleTemplateDetailsPagevalues.filters) }));
        }} onToggleEditMode={(open) => {
            setContext(Object.assign(Object.assign({}, context), { editMode: open }));
        }} unsetAllHighlighted={() => {
            setContext(Object.assign(Object.assign({}, context), { highlightedSchedulableEntities: [], highlightedShifts: {} }));
        }}/>
              </Suspense>
            </ScheduleLayoutContent>
          </ScheduleLayoutMain>
        </ScheduleLayout>
        <Suspense fallback={<></>}>
          {scheduleShiftVacancyDrawerQueryRef && (<ScheduleShiftVacancyDrawer onClose={() => {
                disposeScheduleShiftVacancyDrawerQuery();
            }} preloadedQuery={scheduleShiftVacancyDrawerQueryRef}/>)}
        </Suspense>
        {hasPermissions(['EDIT_COMPANY']) && ((_c = context === null || context === void 0 ? void 0 : context.informationDrawer) === null || _c === void 0 ? void 0 : _c.show) && (<RunningSchedulesInformationDrawer queryRef={data} onClose={() => {
                setContext(Object.assign(Object.assign({}, context), { informationDrawer: Object.assign(Object.assign({}, context.informationDrawer), { show: false }) }));
            }} onTabChange={(value) => {
                setContext(Object.assign(Object.assign({}, context), { informationDrawer: Object.assign(Object.assign({}, context.informationDrawer), { tab: value }) }));
            }} onTypeChange={(value) => {
                setContext(Object.assign(Object.assign({}, context), { informationDrawer: Object.assign(Object.assign({}, context.informationDrawer), { type: value }) }));
            }}/>)}
      </RunningSchedulesPageContextProvider>
    </>);
};
