import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import AssetProficiencyValidationIcon from './AssetProficiencyValidationIcon';
const ScheduleShiftAssetValidations = (props) => {
    const { parent, iconSize = '1x' } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftAssetValidations_scheduleShift on ScheduleShift {
        assetValidations {
          ...AssetProficiencyValidationIcon_scheduleShiftAssetValidation
          schedulableAsset {
            asset {
              validateProficiency
            }
          }
          assetProficiency {
            isValid
          }
        }
      }
    `, parent);
    return (<>
      {data.assetValidations
            .filter((x) => x.schedulableAsset.asset.validateProficiency)
            .filter((x) => !x.assetProficiency.isValid)
            .map((x, index) => (<React.Fragment key={`proficiency-${index}`}>
            <AssetProficiencyValidationIcon iconSize={iconSize} parent={x}/>
          </React.Fragment>))}
    </>);
};
export default ScheduleShiftAssetValidations;
