/**
 * @generated SignedSource<<4031b4f029f9a72fe157e90862c76ffd>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "fragment": {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "RunningSchedulesChangeResourceModalQuery",
        "selections": [
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RunningSchedulesChangeResourceForm_query"
            }
        ],
        "type": "Query",
        "abstractKey": null
    },
    "kind": "Request",
    "operation": {
        "argumentDefinitions": [],
        "kind": "Operation",
        "name": "RunningSchedulesChangeResourceModalQuery",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "filterModel",
                        "value": {
                            "withCurrentUserPermissions": [
                                "EDIT_RUNNING"
                            ]
                        }
                    }
                ],
                "concreteType": "ScheduleConnection",
                "kind": "LinkedField",
                "name": "schedules",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Schedule",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "id",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "schedules(filterModel:{\"withCurrentUserPermissions\":[\"EDIT_RUNNING\"]})"
            }
        ]
    },
    "params": {
        "cacheID": "281cd47bb24372da15d5fceaaef7215f",
        "id": null,
        "metadata": {},
        "name": "RunningSchedulesChangeResourceModalQuery",
        "operationKind": "query",
        "text": "query RunningSchedulesChangeResourceModalQuery {\n  ...RunningSchedulesChangeResourceForm_query\n}\n\nfragment RunningSchedulesChangeResourceForm_query on Query {\n  ...RunningSchedulesSelectScheduleForm_query\n  schedules(filterModel: {withCurrentUserPermissions: [EDIT_RUNNING]}) {\n    nodes {\n      id\n    }\n  }\n}\n\nfragment RunningSchedulesSelectScheduleForm_query on Query {\n  schedules(filterModel: {withCurrentUserPermissions: [EDIT_RUNNING]}) {\n    nodes {\n      id\n      name\n    }\n  }\n}\n"
    }
};
node.hash = "72b96dcfac297bdf1e55ba7eca0643b3";
export default node;
