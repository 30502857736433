import React from 'react';
import { FormFooter, ModalToSheet, ModalToSheetContent, ModalToSheetHeader } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql } from 'react-relay';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import PlannedAbsenceForm from './PlannedAbsenceForm';
import enums from '~/enums.json';
import { formatISO } from 'date-fns';
const timeSpanUnitMinute = enums.TimeSpanUnit.MINUTE.key;
export const addPlannedAbsenceModalQuery = graphql `
  query AddPlannedAbsenceModalQuery($includeEntity: Boolean!, $entityId: ID) {
    schedulableEntity(id: $entityId) @include(if: $includeEntity) {
      ... on ISchedulableEntity {
        id
        displayName
        ... on SchedulableUser {
          user {
            assignmentTypes {
              nodes {
                title
                id
              }
            }
          }
        }
        ... on SchedulableAsset {
          asset {
            assignmentTypes {
              nodes {
                title
                id
              }
            }
          }
        }
      }
    }
  }
`;
const AddPlannedAbsenceModal = (props) => {
    var _a, _b, _c, _d;
    const intl = useIntl();
    const { schedulableEntity } = useLazyLoadQuery(addPlannedAbsenceModalQuery, {
        includeEntity: !!props.defaultAssignedEntityId,
        entityId: props.defaultAssignedEntityId,
    });
    const [createPlannedAbsence, createPlannedAbsenceLoading] = useMutation(graphql `
      mutation AddPlannedAbsenceModalMutation($model: CreatePlannedAbsenceRequestInput!) {
        createPlannedAbsence(model: $model) {
          plannedAbsenceEdge {
            node {
              assignedEntity {
                ... on ISchedulableEntity {
                  id
                }
              }
              assignmentType {
                title
                id
              }
              assignmentTypeId
              description
              end
              id
              reasonEvent {
                id
                name
              }
              start
              times {
                weekday
                period {
                  start(unit: MINUTE) {
                    value
                    unit
                  }
                  end(unit: MINUTE) {
                    value
                    unit
                  }
                }
              }
            }
          }
        }
      }
    `);
    const assignmentTypes = (_d = ((_b = (_a = schedulableEntity === null || schedulableEntity === void 0 ? void 0 : schedulableEntity.asset) === null || _a === void 0 ? void 0 : _a.assignmentTypes) !== null && _b !== void 0 ? _b : (_c = schedulableEntity === null || schedulableEntity === void 0 ? void 0 : schedulableEntity.user) === null || _c === void 0 ? void 0 : _c.assignmentTypes)) === null || _d === void 0 ? void 0 : _d.nodes.map((x) => x);
    const assignmentTypeIds = assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.map((x) => x.id);
    const uniqueAssignmentTypeIds = [...new Set(assignmentTypeIds)];
    const uniqueAssignmentTypes = uniqueAssignmentTypeIds.map((x) => {
        const assignmentType = assignmentTypes.find((y) => y.id == x);
        return { id: assignmentType.id, title: assignmentType.title };
    });
    const assignedEntity = schedulableEntity
        ? {
            id: schedulableEntity.id,
            displayName: schedulableEntity.displayName,
            assignmentTypes: uniqueAssignmentTypes,
        }
        : null;
    return (<ModalToSheet size="medium" onClose={props.onClose}>
      <ModalToSheetHeader onClose={props.onClose} closeButtonLabel="Avbryt">
        <FormattedMessage id="AddPlannedAbsenceModal.title" defaultMessage="Lägg till frånvaro"/>
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <PlannedAbsenceForm onSubmit={(data) => {
            var _a;
            createPlannedAbsence({
                variables: {
                    model: {
                        assignedEntityId: data.assignedEntityId,
                        assignmentTypeId: data.assignmentTypeId,
                        reasonEventId: data.reasonEventId,
                        start: formatISO(data.start),
                        end: formatISO(data.end),
                        description: data.description,
                        times: (_a = data.times) === null || _a === void 0 ? void 0 : _a.map((x) => {
                            return {
                                weekday: x.weekday,
                                period: {
                                    start: {
                                        value: x.startMinute,
                                        unit: timeSpanUnitMinute,
                                    },
                                    end: {
                                        value: x.endMinute,
                                        unit: timeSpanUnitMinute,
                                    },
                                },
                            };
                        }),
                    },
                },
                configs: [
                    {
                        connectionInfo: [
                            {
                                key: 'useGetPlannedAbsences_plannedAbsences',
                                rangeBehavior: 'prepend',
                            },
                        ],
                        parentID: data.assignedEntityId,
                        edgeName: 'plannedAbsenceEdge',
                        type: 'RANGE_ADD',
                    },
                ],
                onCompleted: () => {
                    props.onClose();
                },
            });
        }} defaultValues={{
            assignedEntity: assignedEntity,
            assignmentTypeId: null,
            description: null,
            reasonEventId: null,
            start: props.defaultDate,
            end: null,
            times: [],
        }} formFooter={<FormFooter disabled={createPlannedAbsenceLoading} onCancel={props.onClose} submitText={intl.formatMessage({
                id: 'general.actions.create',
                defaultMessage: 'Lägg till',
            })}/>}></PlannedAbsenceForm>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default AddPlannedAbsenceModal;
