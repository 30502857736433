import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { FormattedMessage } from 'react-intl';
import { ShiftHistoryEvent } from './ScheduleShiftHistoryEvent';
import User from '../User';
import SchedulableEntity from '../ScheduleableEntity/SchedulableEntity';
import enums from '~/enums.json';
export const ScheduleShiftHistoryVacancyInterest = (props) => {
    var _a, _b;
    const data = useFragment(graphql `
      fragment ScheduleShiftHistoryVacancyInterest_history on ScheduleShiftHistoryVacancyInterest {
        id
        byUser {
          ...User_user
        }
        description
        schedulableEntity {
          ...SchedulableEntity_schedulableEntity
        }
        status
        timestamp
      }
    `, props.history);
    const vacancyInterestStatusEnum = enums.VacancyInterestStatus;
    const { byUser, description, schedulableEntity, status, timestamp } = data;
    return (<ShiftHistoryEvent timestamp={timestamp.toString()}>
      <p>
        {!!description ? (<FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryVacancyInterest.with_message" defaultMessage="{user} satte intresse till {status} för {interestedUser} med meddelande: {message}" values={{
                user: <User user={byUser}/>,
                status: (<strong style={{ fontWeight: 'bold' }}>{((_a = vacancyInterestStatusEnum[status]) === null || _a === void 0 ? void 0 : _a.description) || ''}</strong>),
                interestedUser: <SchedulableEntity schedulableEntity={schedulableEntity} isVacant={false}/>,
                message: description,
            }}/>) : (<FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryVacancyInterest.without_message" defaultMessage="{user} satte intresse till {status} för {interestedUser}" values={{
                user: <User user={byUser}/>,
                status: (<strong style={{ fontWeight: 'bold' }}>{((_b = vacancyInterestStatusEnum[status]) === null || _b === void 0 ? void 0 : _b.description) || ''}</strong>),
                interestedUser: <SchedulableEntity schedulableEntity={schedulableEntity} isVacant={false}/>,
            }}/>)}
      </p>
    </ShiftHistoryEvent>);
};
