import { useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
export const useScheduleShiftTags = (scheduleShiftRef) => {
    const data = useFragment(graphql `
      fragment useScheduleShiftTags_scheduleShift on ScheduleShift {
        id
        tags(first: null) @connection(key: "useScheduleShiftTags_tags") {
          edges {
            node {
              id
              tagId
              tag {
                name
              }
            }
          }
        }
      }
    `, scheduleShiftRef);
    const [id, setId] = useState(data === null || data === void 0 ? void 0 : data.id);
    const [setTagsOnScheduleShift, setTagsLoading] = useMutation(graphql `
      mutation useScheduleShiftTags_setTagMutation($model: SetTagsOnScheduleShiftRequestInput!) {
        setTagsOnScheduleShift(model: $model) {
          scheduleShiftEdge {
            node {
              ...useScheduleShiftTags_scheduleShift
            }
          }
        }
      }
    `);
    const setScheduleShiftTags = ({ tagIds }) => {
        const promise = new Promise((resolve, reject) => {
            setTagsOnScheduleShift({
                onCompleted: (response) => {
                    resolve(response);
                },
                onError: (error) => {
                    reject(error);
                },
                variables: {
                    model: {
                        tagIds,
                        id,
                    },
                },
            });
        });
        return promise;
    };
    return { data, setId, setTagsLoading, setScheduleShiftTags };
};
export default useScheduleShiftTags;
