import React, { useMemo, useTransition } from 'react';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { DrawerSkeleton, IconButton, Tooltip } from '@sonika-se/ui-kit/components';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { formatISO } from 'date-fns';
import { useQueryLoader } from 'react-relay/hooks';
import ScheduleShiftClashCountBadge from '~/badges/ScheduleShiftClashCountBadge';
import ScheduleShiftClashDrawer, { scheduleShiftClashDrawerQuery } from '~/drawers/ScheduleShiftClashDrawer';
export const ScheduleShiftClashDrawerAction = (props) => {
    const { fromDate, toDate } = props;
    const hasPermissions = usePermissions();
    const canEditSchedules = useMemo(() => hasPermissions(['EDIT_SCHEDULES']), []);
    const [isTransitioning, startTransition] = useTransition();
    const [scheduleShiftClashDrawerQueryRef, loadScheduleShiftClashDrawerQuery, disposeScheduleShiftClashDrawerQuery] = useQueryLoader(scheduleShiftClashDrawerQuery);
    if (!canEditSchedules) {
        return null;
    }
    return (<>
      <Tooltip content="Dubbelbokningar">
        <ScheduleShiftClashCountBadge fromDate={fromDate} toDate={toDate}>
          <IconButton appearance={scheduleShiftClashDrawerQueryRef ? 'filled' : 'outlined'} disabled={isTransitioning} icon={faExclamationTriangle} onClick={() => {
            if (scheduleShiftClashDrawerQueryRef) {
                disposeScheduleShiftClashDrawerQuery();
            }
            else {
                startTransition(() => {
                    loadScheduleShiftClashDrawerQuery({
                        scheduleShiftClashGroupListModel: {
                            fromDate: formatISO(fromDate),
                            toDate: formatISO(toDate),
                        },
                    }, {
                        fetchPolicy: 'network-only',
                    });
                });
            }
        }}/>
        </ScheduleShiftClashCountBadge>
      </Tooltip>
      {isTransitioning && (<DrawerSkeleton onClose={() => {
                disposeScheduleShiftClashDrawerQuery();
            }} type="loader"/>)}
      {scheduleShiftClashDrawerQueryRef && (<ScheduleShiftClashDrawer onClose={() => {
                disposeScheduleShiftClashDrawerQuery();
            }} preloadedQuery={scheduleShiftClashDrawerQueryRef}/>)}
    </>);
};
export default ScheduleShiftClashDrawerAction;
