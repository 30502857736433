import React, { useMemo, useState } from 'react';
import { graphql, useMutation, usePaginationFragment } from 'react-relay/hooks';
import { Button, Chip, Confirm, DataTable, Flexbox, Placeholder, Text, Tooltip } from '@sonika-se/ui-kit/components';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import Avatar from '~/shared/Avatar';
import { ConnectionHandler } from 'relay-runtime';
import { Link } from 'react-router-dom';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
export const ScheduleTemplateTable = (props) => {
    const { scheduleRef } = props;
    const { data: schedule, hasNext, isLoadingNext, loadNext, refetch, } = usePaginationFragment(graphql `
      fragment ScheduleTemplateTable_schedule on Schedule
      @argumentDefinitions(after: { type: "String" }, first: { type: "Int", defaultValue: 50 })
      @refetchable(queryName: "ScheduleTemplateTable_scheduleQuery") {
        currentUserPermissions
        id
        templates(after: $after, first: $first)
          @connection(key: "ScheduleTemplateTable_schedule_templates", filters: []) {
          edges {
            node {
              canEdit
              id
              name
              publications(order: { timestampId: DESC }) {
                nodes {
                  publishedByUser {
                    ...Avatar_iHaveAvatar
                    firstname
                    lastname
                  }
                  timestampId
                  startDate
                  endDate
                }
              }
            }
          }
        }
      }
    `, scheduleRef);
    const scheduleTemplates = useMemo(() => schedule.templates.edges.map(({ node: scheduleTemplate }) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return {
            name: scheduleTemplate,
            status: scheduleTemplate.canEdit ? 'DRAFT' : 'PUBLISHED',
            lastPublishedAt: {
                date: toDate((_b = (_a = scheduleTemplate.publications) === null || _a === void 0 ? void 0 : _a.nodes[0]) === null || _b === void 0 ? void 0 : _b.timestampId),
                startDate: toDate((_d = (_c = scheduleTemplate.publications) === null || _c === void 0 ? void 0 : _c.nodes[0]) === null || _d === void 0 ? void 0 : _d.startDate),
                endDate: toDate((_f = (_e = scheduleTemplate.publications) === null || _e === void 0 ? void 0 : _e.nodes[0]) === null || _f === void 0 ? void 0 : _f.endDate),
            },
            lastPublishedBy: (_h = (_g = scheduleTemplate.publications) === null || _g === void 0 ? void 0 : _g.nodes[0]) === null || _h === void 0 ? void 0 : _h.publishedByUser,
            actions: scheduleTemplate,
        };
    }), [schedule]);
    const [deleteScheduleTemplate, deleteScheduleTemplateLoading] = useMutation(graphql `
      mutation ScheduleTemplateTable_deleteScheduleTemplateMutation($model: DeleteScheduleTemplateRequestInput!) {
        deleteScheduleTemplate(model: $model) {
          templateEdge {
            node {
              id
            }
          }
        }
      }
    `);
    const [cloneScheduleTemplate, cloneScheduleTemplateLoading] = useMutation(graphql `
      mutation ScheduleTemplateTable_cloneScheduleTemplateMutation(
        $connectionsToPrependEdge: [ID!]!
        $model: CloneScheduleTemplateInput!
      ) {
        cloneScheduleTemplate(model: $model) {
          scheduleTemplateEdge @prependEdge(connections: $connectionsToPrependEdge) {
            node {
              canEdit
              id
              name
            }
          }
        }
      }
    `);
    const [scheduleTemplateToDelete, setScheduleTemplateToDelete] = useState();
    const canEditSchedules = usePermissions()(['EDIT_SCHEDULES']);
    const canEditTemplates = useMemo(() => canEditSchedules || schedule.currentUserPermissions.includes('EDIT_TEMPLATES'), [canEditSchedules, schedule]);
    return scheduleTemplates.length === 0 ? (<Placeholder text="Inga mallar."/>) : (<>
      <DataTable columns={{
            name: {
                render: (scheduleTemplate) => (<Link to={`/schedules/${schedule.id}/templates/${scheduleTemplate.id}`}>
                <Text bold>{scheduleTemplate.name}</Text>
              </Link>),
                text: 'Namn',
            },
            status: {
                text: 'Status',
                render: (status) => (<Chip color={status === 'PUBLISHED' ? sonikaTheme.colors.system.go : sonikaTheme.colors.system.wait} size="xsmall" text={status === 'PUBLISHED' ? 'Tillämpad' : 'Utkast'}/>),
            },
            lastPublishedAt: {
                render: (lastPublishedAt) => lastPublishedAt.date && (<Tooltip placement="top" content={`${formatDate(lastPublishedAt.startDate, 'date')} - ${formatDate(lastPublishedAt.endDate, 'date')}`}>
                  {formatDate(lastPublishedAt.date, 'date')}
                </Tooltip>),
                text: 'Tillämpad',
            },
            lastPublishedBy: {
                render: (lastPublishedBy) => lastPublishedBy && (<Flexbox align="center" direction="row" gap="xsmall">
                  <Avatar iHaveAvatarRef={lastPublishedBy} size="xsmall"/>
                  <span>{`${lastPublishedBy.firstname} ${lastPublishedBy.lastname}`}</span>
                </Flexbox>),
                text: 'Av',
            },
            actions: canEditTemplates
                ? {
                    render: (scheduleTemplate) => (<Flexbox direction="row" gap="xsmall" justify="end">
                    {scheduleTemplate.canEdit && (<Button color="danger" disabled={cloneScheduleTemplateLoading || deleteScheduleTemplateLoading} onClick={() => {
                                setScheduleTemplateToDelete(scheduleTemplate.id);
                            }} size="xsmall">
                        Ta bort
                      </Button>)}
                    <Button color="primary" disabled={cloneScheduleTemplateLoading || deleteScheduleTemplateLoading} onClick={() => {
                            cloneScheduleTemplate({
                                variables: {
                                    connectionsToPrependEdge: [
                                        ConnectionHandler.getConnectionID(schedule.id, 'ScheduleTemplateTable_schedule_templates', []),
                                    ],
                                    model: {
                                        scheduleTemplateId: scheduleTemplate.id,
                                        newName: `${scheduleTemplate.name} - kopia`,
                                    },
                                },
                            });
                        }} size="xsmall">
                      Duplicera
                    </Button>
                  </Flexbox>),
                    text: '',
                }
                : undefined,
        }} loading={isLoadingNext} onPaginate={() => {
            loadNext(50);
        }} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={scheduleTemplates}/>
      {scheduleTemplateToDelete && (<Confirm appearance="danger" confirmButton={{
                onConfirm: () => {
                    deleteScheduleTemplate({
                        onCompleted: () => {
                            refetch({}, { fetchPolicy: 'network-only' });
                            setScheduleTemplateToDelete(undefined);
                        },
                        variables: {
                            model: {
                                id: scheduleTemplateToDelete,
                            },
                        },
                    });
                },
                text: 'Ta bort',
            }} disabled={deleteScheduleTemplateLoading} headerText="Är du säker på att du vill ta bort mallen?" onCancel={() => {
                setScheduleTemplateToDelete(undefined);
            }}/>)}
    </>);
};
export default ScheduleTemplateTable;
