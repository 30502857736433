import React, { useState, Suspense, useContext, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { faCalendarTimes, faCalendarPlus, faRandom, faTimes, faUndo, faUserSlash, faExclamationTriangle, faMinusSquare, faClock, } from '@fortawesome/pro-light-svg-icons';
import { useMutation, graphql, useQueryLoader } from 'react-relay/hooks';
import { IconButton, Flexbox, ModalToSheetSkeleton, Tooltip, Divider, Confirm } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import RunningSchedulesEditUpdateTimesModal, { runningSchedulesEditUpdateTimesModalQuery, } from './components/RunningSchedulesEditUpdateTimesModal';
import RunningSchedulesDeleteAssignedResourceModal from './components/RunningSchedulesDeleteAssignedResourceModal';
import RunningSchedulesDeleteShiftsModal, { runningSchedulesDeleteShiftsModalQuery, } from '../RunningSchedulesDeleteShiftsModal/RunningSchedulesDeleteShiftsModal';
import RunningSchedulesChangeResourceModal, { runningSchedulesChangeResourceModalQuery, } from '../RunningSchedulesChangeResourceModal/RunningSchedulesChangeResourceModal';
import RunningSchedulesPageContext from '../../RunningSchedulesPageContext';
import { updateScheduleShiftAssigneesFormQuery } from '../UpdateScheduleShiftAssigneesForm';
const useStyles = createUseStyles(() => ({
    buttonColum: {
        padding: '1rem .5rem',
        borderRight: '1px solid #D5D6D8',
    },
}));
const RunningSchedulesEditNavbar = (props) => {
    const { onAddScheduleShift, refetch, unsetAllHighlighted } = props;
    const intl = useIntl();
    const [highlightedEventConfirm, setHighlightedEventConfirm] = useState(undefined);
    const { highlightedShifts } = useContext(RunningSchedulesPageContext);
    const [runningSchedulesDeleteShiftsModalQueryRef, loadRunningSchedulesDeleteShiftsModalQuery, disposeRunningSchedulesDeleteShiftsModalQuery,] = useQueryLoader(runningSchedulesDeleteShiftsModalQuery);
    const [updateScheduleShiftAssigneesFormQueryRef, loadUpdateScheduleShiftAssigneesFormQuery, disposeUpdateScheduleShiftAssigneesFormQuery,] = useQueryLoader(updateScheduleShiftAssigneesFormQuery);
    const [runningSchedulesEditUpdateTimesModalQueryRef, loadRunningSchedulesEditUpdateTimesModalQuery, disposeRunningSchedulesEditUpdateTimesModalQuery,] = useQueryLoader(runningSchedulesEditUpdateTimesModalQuery);
    const [runningSchedulesChangeResourceModalQueryRef, loadRunningSchedulesChangeResourceModalQuery, disposeRunningSchedulesChangeResourceModalQuery,] = useQueryLoader(runningSchedulesChangeResourceModalQuery);
    useEffect(() => {
        switch (highlightedEventConfirm) {
            case 'DELETE-ASSIGNEE':
                loadUpdateScheduleShiftAssigneesFormQuery({
                    where: { id: { in: Object.keys(highlightedShifts) } },
                    potentialValidationsModel: { newAssignedEntityId: null },
                });
                break;
            case 'UPDATE-TIME':
                loadRunningSchedulesEditUpdateTimesModalQuery({ where: { id: { in: Object.keys(highlightedShifts) } } });
                break;
        }
    }, [highlightedEventConfirm, highlightedShifts]);
    const [deleteScheduleShifts] = useMutation(graphql `
      mutation RunningSchedulesEditNavbar_deleteScheduleShiftsMutation($model: [DeleteScheduleShiftRequestInput!]!) {
        deleteScheduleShifts(model: $model) {
          shiftEdge {
            node {
              id
              assignedEntityId
            }
          }
        }
      }
    `);
    const [resetScheduleShifts] = useMutation(graphql `
      mutation RunningSchedulesEditNavbar_resetScheduleShiftsMutation($model: [ResetScheduleShiftRequestInput!]!) {
        resetScheduleShifts(model: $model) {
          shiftEdge {
            node {
              ...ScheduleShift_shift
            }
          }
        }
      }
    `);
    const classes = useStyles();
    const getConfirmModal = (event) => {
        switch (event) {
            case 'DELETE':
                return (<Confirm appearance="danger" headerText={`Vill du ta bort ${Object.keys(highlightedShifts).length} pass?`} text="Passen kommer försvinna och du kan inte ångra raderingen i efterhand" onCancel={() => setHighlightedEventConfirm(undefined)} icon={faExclamationTriangle} confirmButton={{
                        text: 'Ta bort',
                        onConfirm: () => {
                            deleteScheduleShifts({
                                variables: {
                                    model: Object.keys(highlightedShifts).map((shiftId) => ({ shiftId })),
                                },
                                onCompleted: () => {
                                    setHighlightedEventConfirm(undefined);
                                    unsetAllHighlighted();
                                    refetch();
                                },
                            });
                        },
                    }}/>);
            case 'RESET':
                return (<Confirm appearance="danger" headerText={`Vill du återställa ${Object.keys(highlightedShifts).length} pass?`} text="Alla ändringar och historiken för passen kommer att nollställas" onCancel={() => setHighlightedEventConfirm(undefined)} icon={faExclamationTriangle} confirmButton={{
                        text: 'Återställ',
                        onConfirm: () => {
                            resetScheduleShifts({
                                variables: {
                                    model: Object.keys(highlightedShifts).map((shiftId) => ({ shiftId })),
                                },
                                onCompleted: () => {
                                    setHighlightedEventConfirm(undefined);
                                    unsetAllHighlighted();
                                },
                            });
                        },
                    }}/>);
            case 'DELETE-ASSIGNEE':
                return (<Suspense fallback={<ModalToSheetSkeleton />}>
            {updateScheduleShiftAssigneesFormQueryRef && (<RunningSchedulesDeleteAssignedResourceModal onCancel={() => {
                            disposeUpdateScheduleShiftAssigneesFormQuery();
                            setHighlightedEventConfirm(undefined);
                        }} preloadedQuery={updateScheduleShiftAssigneesFormQueryRef}/>)}
          </Suspense>);
            case 'UPDATE-TIME':
                return (<Suspense fallback={<ModalToSheetSkeleton />}>
            {runningSchedulesEditUpdateTimesModalQueryRef && (<RunningSchedulesEditUpdateTimesModal onCancel={() => {
                            disposeRunningSchedulesEditUpdateTimesModalQuery();
                            setHighlightedEventConfirm(undefined);
                        }} preloadedQuery={runningSchedulesEditUpdateTimesModalQueryRef}/>)}
          </Suspense>);
            default:
                return <div />;
        }
    };
    return (<>
      <Flexbox direction="column" gap="xsmall" className={classes.buttonColum}>
        <div>
          <Tooltip content={intl.formatMessage({
            id: 'general.misc.schedule_shift.actions.create',
            defaultMessage: 'Lägg till pass',
        })} placement="right">
            <IconButton icon={faCalendarPlus} onClick={() => {
            onAddScheduleShift();
        }}/>
          </Tooltip>
        </div>
        <div>
          <Tooltip content="Byt anställd eller enhet" placement="right">
            <IconButton icon={faRandom} onClick={() => {
            loadRunningSchedulesChangeResourceModalQuery({});
        }}/>
          </Tooltip>
        </div>
        <div>
          <Tooltip content="Ta bort flera pass" placement="right">
            <IconButton icon={faCalendarTimes} onClick={() => {
            loadRunningSchedulesDeleteShiftsModalQuery({});
        }}/>
          </Tooltip>
        </div>
        {!!Object.keys(highlightedShifts).length && (<>
            <Divider />
            <div>
              <Tooltip content="Avmarkera alla valda pass" placement="right">
                <IconButton icon={faMinusSquare} onClick={() => {
                unsetAllHighlighted();
            }}/>
              </Tooltip>
            </div>
            <div>
              <Tooltip content="Uppdatera tid för markerade pass" placement="right">
                <IconButton icon={faClock} onClick={() => {
                setHighlightedEventConfirm('UPDATE-TIME');
            }}/>
              </Tooltip>
            </div>
            <div>
              <Tooltip content="Gör markerade pass vakanta" placement="right">
                <IconButton icon={faUserSlash} onClick={() => {
                setHighlightedEventConfirm('DELETE-ASSIGNEE');
            }}/>
              </Tooltip>
            </div>
            <div>
              <Tooltip content="Återställ markerade pass" placement="right">
                <IconButton icon={faUndo} onClick={() => {
                setHighlightedEventConfirm('RESET');
            }}/>
              </Tooltip>
            </div>
            <div>
              <Tooltip content="Ta bort markerade pass" placement="right">
                <IconButton icon={faTimes} onClick={() => {
                setHighlightedEventConfirm('DELETE');
            }}/>
              </Tooltip>
            </div>
          </>)}
      </Flexbox>
      {highlightedEventConfirm && getConfirmModal(highlightedEventConfirm)}
      {runningSchedulesChangeResourceModalQueryRef && (<RunningSchedulesChangeResourceModal onClose={() => {
                disposeRunningSchedulesChangeResourceModalQuery();
            }} preloadedQuery={runningSchedulesChangeResourceModalQueryRef}/>)}
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {runningSchedulesDeleteShiftsModalQueryRef && (<RunningSchedulesDeleteShiftsModal preloadedQuery={runningSchedulesDeleteShiftsModalQueryRef} onClose={() => disposeRunningSchedulesDeleteShiftsModalQuery()}/>)}
      </Suspense>
    </>);
};
export default RunningSchedulesEditNavbar;
