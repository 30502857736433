import React, { useCallback } from 'react';
import { Field, Form, IntervalInput } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
export const ScheduleShiftClashQueryForm = (props) => {
    const { defaultValues, onSubmit } = props;
    const { control, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues,
    });
    const submitForm = useCallback(() => {
        handleSubmit((form) => {
            onSubmit(form);
        })();
    }, [onSubmit]);
    return (<Form>
      <Field label="Vald period">
        <Controller name="interval" control={control} render={({ field }) => (<IntervalInput onChange={(value) => {
                field.onChange(value);
                submitForm();
            }} value={field.value}/>)}/>
      </Field>
    </Form>);
};
export default ScheduleShiftClashQueryForm;
