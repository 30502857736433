import React from 'react';
import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            backgroundColor: '#FFFFFF',
            gridColumn: 1,
            left: 0,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            position: 'sticky',
            textAlign: 'center',
            fontSize: pxToRem(20),
            fontWeight: 600,
        },
    };
});
export const MyScheduleGridWeek = (props) => {
    const { week, gridRow } = props;
    const classes = useStyles();
    return (<div className={classes.root} style={{
            gridRow,
        }}>
      {week}
    </div>);
};
