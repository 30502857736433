import { useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
export const useScheduleShiftNotes = (scheduleShiftRef) => {
    const data = useFragment(graphql `
      fragment useScheduleShiftNotes_scheduleShift on ScheduleShift {
        id
        notes(first: null) @connection(key: "useScheduleShiftNotes_notes") {
          edges {
            node {
              id
              byUser {
                id
                firstname
                lastname
              }
              createdAt
              text
            }
          }
        }
      }
    `, scheduleShiftRef);
    const [id, setId] = useState(data === null || data === void 0 ? void 0 : data.id);
    const [createScheduleShiftNote, addNoteInProgress] = useMutation(graphql `
      mutation useScheduleShiftNotes_createNoteMutation($model: CreateScheduleShiftNoteRequestInput!) {
        createScheduleShiftNote(model: $model) {
          noteEdge {
            node {
              id
              byUser {
                id
                firstname
                lastname
              }
              createdAt
              text
            }
          }
        }
      }
    `);
    const [deleteScheduleShiftNotes] = useMutation(graphql `
      mutation useScheduleShiftNotes_deleteNoteMutation($model: DeleteScheduleShiftNoteRequestInput!) {
        deleteScheduleShiftNote(model: $model) {
          noteEdge {
            node {
              id
            }
          }
        }
      }
    `);
    const notes = {
        addNote: ({ text }) => {
            const promise = new Promise((resolve, reject) => {
                createScheduleShiftNote({
                    configs: [
                        {
                            connectionInfo: [
                                {
                                    key: 'useScheduleShiftNotes_notes',
                                    rangeBehavior: 'append',
                                },
                            ],
                            edgeName: 'noteEdge',
                            parentID: id,
                            type: 'RANGE_ADD',
                        },
                    ],
                    onCompleted: (response) => {
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: {
                            text,
                            iHaveNotesId: id,
                        },
                    },
                });
            });
            return promise;
        },
        removeNote: ({ noteId }) => {
            const promise = new Promise((resolve, reject) => {
                deleteScheduleShiftNotes({
                    configs: [
                        {
                            connectionKeys: [
                                {
                                    key: 'useScheduleShiftNotes_notes',
                                },
                            ],
                            parentID: data.id,
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['noteEdge', 'node'],
                            pathToConnection: ['scheduleShift', 'notes'],
                        },
                    ],
                    onCompleted: (response) => {
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: {
                            noteId,
                            shiftId: id,
                        },
                    },
                });
            });
            return promise;
        },
    };
    return Object.assign(Object.assign({}, notes), { data, setId, addNoteInProgress });
};
export default useScheduleShiftNotes;
