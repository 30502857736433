/**
 * @generated SignedSource<<9ed3c86d54664623aa0c0b6fe4e28bfa>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 30
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleListPageQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ScheduleListPage_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ScheduleListPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "ScheduleConnection",
                    "kind": "LinkedField",
                    "name": "schedules",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "currentUserPermissions",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "archived",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "inactivatedFrom",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleTemplateConnection",
                                            "kind": "LinkedField",
                                            "name": "templates",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleTemplateEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleTemplate",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v1 /*: any*/),
                                                                (v2 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "schedules(first:30)"
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "SchedulesTable_schedules",
                    "kind": "LinkedHandle",
                    "name": "schedules"
                }
            ]
        },
        "params": {
            "cacheID": "9803f5776e038268138b0fa7a7295d49",
            "id": null,
            "metadata": {},
            "name": "ScheduleListPageQuery",
            "operationKind": "query",
            "text": "query ScheduleListPageQuery {\n  ...ScheduleListPage_query\n}\n\nfragment ScheduleListPage_query on Query {\n  ...SchedulesTable_query\n}\n\nfragment ScheduleListRow_schedule on Schedule {\n  id\n  currentUserPermissions\n  name\n  archived\n  inactivatedFrom\n  templates {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment SchedulesTable_query on Query {\n  schedules(first: 30) {\n    edges {\n      node {\n        id\n        name\n        ...ScheduleListRow_schedule\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "d44178709bf842a7dfdc8fe9464a02ac";
export default node;
