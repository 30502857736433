/**
 * @generated SignedSource<<08fae5f5b07664f63d5ec55c28a13fc7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        },
        {
            "defaultValue": 50,
            "kind": "LocalArgument",
            "name": "first"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v2 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
        },
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "Avatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "queryTransform",
                        "value": "width=300&height=300&mode=crop&scale=both"
                    }
                ],
                "kind": "ScalarField",
                "name": "url",
                "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
            },
            (v4 /*: any*/)
        ],
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v8 = [
        (v7 /*: any*/)
    ], v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleAssignmentTypeResourceTable_scheduleAssignmentTypeQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v2 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "ScheduleAssignmentTypeResourceTable_scheduleAssignmentType"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleAssignmentTypeResourceTable_scheduleAssignmentTypeQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                    "kind": "LinkedField",
                                    "name": "resources",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeResource",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "schedulableEntity",
                                                            "plural": false,
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Asset",
                                                                            "kind": "LinkedField",
                                                                            "name": "asset",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v5 /*: any*/),
                                                                                (v4 /*: any*/),
                                                                                {
                                                                                    "kind": "InlineFragment",
                                                                                    "selections": [
                                                                                        (v6 /*: any*/),
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": (v8 /*: any*/),
                                                                                            "type": "Asset",
                                                                                            "abstractKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v7 /*: any*/),
                                                                                                (v9 /*: any*/),
                                                                                                (v10 /*: any*/)
                                                                                            ],
                                                                                            "type": "User",
                                                                                            "abstractKey": null
                                                                                        }
                                                                                    ],
                                                                                    "type": "IHaveAvatar",
                                                                                    "abstractKey": "__isIHaveAvatar"
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "SchedulableAsset",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "User",
                                                                            "kind": "LinkedField",
                                                                            "name": "user",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v9 /*: any*/),
                                                                                (v10 /*: any*/),
                                                                                (v4 /*: any*/),
                                                                                {
                                                                                    "kind": "InlineFragment",
                                                                                    "selections": [
                                                                                        (v6 /*: any*/),
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v7 /*: any*/),
                                                                                                (v5 /*: any*/)
                                                                                            ],
                                                                                            "type": "Asset",
                                                                                            "abstractKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v5 /*: any*/)
                                                                                            ],
                                                                                            "type": "Company",
                                                                                            "abstractKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": (v8 /*: any*/),
                                                                                            "type": "User",
                                                                                            "abstractKey": null
                                                                                        }
                                                                                    ],
                                                                                    "type": "IHaveAvatar",
                                                                                    "abstractKey": "__isIHaveAvatar"
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "SchedulableUser",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v4 /*: any*/)
                                                                    ],
                                                                    "type": "Node",
                                                                    "abstractKey": "__isNode"
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v4 /*: any*/),
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "filters": [],
                                    "handle": "connection",
                                    "key": "ScheduleAssignmentTypeResourceTable_scheduleAssignmentType_resources",
                                    "kind": "LinkedHandle",
                                    "name": "resources"
                                }
                            ],
                            "type": "ScheduleAssignmentType",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "12c30f7cf7841cc5077c6fb82af95142",
            "id": null,
            "metadata": {},
            "name": "ScheduleAssignmentTypeResourceTable_scheduleAssignmentTypeQuery",
            "operationKind": "query",
            "text": "query ScheduleAssignmentTypeResourceTable_scheduleAssignmentTypeQuery(\n  $after: String\n  $first: Int = 50\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ScheduleAssignmentTypeResourceTable_scheduleAssignmentType_2HEEH6\n    id\n  }\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n\nfragment ScheduleAssignmentTypeResourceTable_scheduleAssignmentType_2HEEH6 on ScheduleAssignmentType {\n  resources(after: $after, first: $first) {\n    edges {\n      node {\n        schedulableEntity {\n          __typename\n          ... on SchedulableAsset {\n            asset {\n              ...Avatar_iHaveAvatar\n              name\n              id\n            }\n          }\n          ... on SchedulableUser {\n            user {\n              ...Avatar_iHaveAvatar\n              firstname\n              lastname\n              id\n            }\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
        }
    };
})();
node.hash = "2439b8dfbd24af90e38f7ba6233a3766";
export default node;
