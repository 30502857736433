/**
 * @generated SignedSource<<4035fb7323e2b0ba4a6c789604b1b634>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "CreateScheduleShiftPayload",
            "kind": "LinkedField",
            "name": "createScheduleShifts",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfScheduleShift",
                    "kind": "LinkedField",
                    "name": "shiftEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShift",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AddScheduleShiftModal_createScheduleShiftsMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AddScheduleShiftModal_createScheduleShiftsMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "8a7b2652df4fdc08e51dad7447bc3e42",
            "id": null,
            "metadata": {},
            "name": "AddScheduleShiftModal_createScheduleShiftsMutation",
            "operationKind": "mutation",
            "text": "mutation AddScheduleShiftModal_createScheduleShiftsMutation(\n  $model: [CreateScheduleShiftRequestInput!]!\n) {\n  createScheduleShifts(model: $model) {\n    shiftEdge {\n      node {\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "3911929ea184d066d6e3acf647e05683";
export default node;
