import React, { Suspense } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { graphql } from 'react-relay';
import { useMutation, useFragment } from 'react-relay/hooks';
import { Button, Field, Form, PopoverToSheetContent, Skeleton, PopoverToSheetFooter, } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import EventField from '~/components/EventField';
const defaultValues = {
    reason: '',
};
const validationSchema = object({
    reason: string().required(),
});
export const RemoveAssignedEntity = (props) => {
    var _a;
    const { onSubmit } = props;
    const intl = useIntl();
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    const scheduleShift = useFragment(graphql `
      fragment RemoveAssignedEntity on ScheduleShift {
        id
        assignmentType {
          title
          id
        }
      }
    `, props.scheduleShift);
    const [removeAssignedEntity, mutationLoading] = useMutation(graphql `
      mutation RemoveAssignedEntityNewMutation($model: UpdateScheduleShiftAssigneeRequestInput!) {
        updateScheduleShiftAssignee(model: $model) {
          scheduleShiftHistoryAssigneeChangeEdge {
            node {
              id
              scheduleShift {
                id
                assignedEntity {
                  ... on ISchedulableEntity {
                    id
                  }
                }
                ...ScheduleShiftContent_ScheduleShift
                ...ScheduleShiftHistory_scheduleShift
              }
              ...ScheduleShiftHistoryAssigneeChange_history
            }
          }
        }
      }
    `);
    return (<>
      <Suspense fallback={<Skeleton type="form" height="10vh"/>}>
        <PopoverToSheetContent>
          <Form id="removeAssigneeForm" onSubmit={handleSubmit((data) => {
            removeAssignedEntity({
                onCompleted: () => {
                    onSubmit();
                },
                variables: {
                    model: {
                        newAssignedEntityId: null,
                        reasonEventId: data.reason,
                        shiftId: scheduleShift.id,
                    },
                },
            });
        })}>
            <Field label={intl.formatMessage({
            defaultMessage: 'Anledning',
            id: 'RemoveAssignedEntity.fields.reason.label',
        })}>
              <Controller control={control} name="reason" render={({ field }) => {
            var _a, _b, _c;
            return (<EventField assignmentTypeId={(_a = scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.assignmentType) === null || _a === void 0 ? void 0 : _a.id} where={{ isAssigneeReplacementReason: { eq: true } }} disabled={mutationLoading} error={(_c = (_b = errors === null || errors === void 0 ? void 0 : errors.reason) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.toString()} defaultValue={field.value} onChange={(value) => field.onChange(value ? value[0] : undefined)} placeholder={intl.formatMessage({
                    defaultMessage: 'Välj anledning',
                    id: 'RemoveAssignedEntity.fields.reason.placeholder',
                })}/>);
        }}/>
            </Field>
          </Form>
        </PopoverToSheetContent>
        <PopoverToSheetFooter style={{
            display: 'flex',
            justifyContent: 'flex-end',
        }}>
          <Button disabled={mutationLoading} form="removeAssigneeForm" type="submit">
            <FormattedMessage id="RemoveAssignedEntity.button_submit" defaultMessage="Spara" values={{
            assignmentTypeTitle: ((_a = scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.assignmentType) === null || _a === void 0 ? void 0 : _a.title) || '',
        }}/>
          </Button>
        </PopoverToSheetFooter>
      </Suspense>
    </>);
};
export default RemoveAssignedEntity;
