import React, { Suspense } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { Button, ModalToSheetSkeleton, Text } from '@sonika-se/ui-kit/components';
import { formatDateSpan, toDate } from '@sonika-se/ui-kit/utils/time';
import ScheduleShiftModal, { scheduleShiftModalQuery, } from '~/pages/SchedulePages/components/ScheduleShiftModal/ScheduleShiftModal';
export const ScheduleShiftClashListItem = (props) => {
    var _a;
    const { iCanBeUsedToCalculateScheduleTimesRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftClashListItem_iCanBeUsedToCalculateScheduleTimesConnection on ICanBeUsedToCalculateScheduleTimes {
        __typename
        ... on PlannedAbsence {
          id
          assignmentType {
            title
          }
          end
          start
        }
        ... on ScheduleShift {
          id
          assignmentType {
            title
          }
          end
          schedule {
            name
          }
          start
        }
        ... on ScheduleShiftAssigneeReplacement {
          id
          scheduleShift {
            assignmentType {
              title
            }
            end
            schedule {
              name
            }
            start
          }
        }
      }
    `, iCanBeUsedToCalculateScheduleTimesRef);
    const scheduleShift = data.__typename === 'ScheduleShift'
        ? data
        : data.__typename === 'ScheduleShiftAssigneeReplacement'
            ? data.scheduleShift
            : data.__typename === 'PlannedAbsence'
                ? data
                : null;
    const start = toDate(scheduleShift.start);
    const end = toDate(scheduleShift.end);
    const [scheduleShiftModalQueryRef, loadScheduleShiftModalQuery, disposeScheduleShiftModalQuery] = useQueryLoader(scheduleShiftModalQuery);
    return (<>
      <div>
        {data.__typename === 'ScheduleShift' || data.__typename === 'ScheduleShiftAssigneeReplacement' ? (<Button appearance="text" color="primary" onClick={() => {
                loadScheduleShiftModalQuery({ shiftId: data.id });
            }} padding={false}>
            {formatDateSpan(start, end, 'medium')}
          </Button>) : (<Text bold>{formatDateSpan(start, end, 'medium')}</Text>)}
        <Text appearance="light">
          {(_a = scheduleShift.assignmentType) === null || _a === void 0 ? void 0 : _a.title}
          {data.__typename === 'ScheduleShift'
            ? `, ${data.schedule.name}`
            : data.__typename === 'ScheduleShiftAssigneeReplacement'
                ? `, ${data.scheduleShift.schedule.name}`
                : null}
          {data.__typename === 'PlannedAbsence' && <> (frånvaro)</>}
        </Text>
      </div>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh" onClose={() => disposeScheduleShiftModalQuery()} type="loader"/>}>
        {scheduleShiftModalQueryRef && (<ScheduleShiftModal onClose={() => disposeScheduleShiftModalQuery()} preloadedQuery={scheduleShiftModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleShiftClashListItem;
