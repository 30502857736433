import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { faUsers, faCube as assetIcon } from '@fortawesome/pro-regular-svg-icons';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
import { Checkbox, Label, Flexbox, Divider, Button, Placeholder } from '@sonika-se/ui-kit/components';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
import ScheduleTemplateDetailsPageContext from '../../ScheduleTemplateDetailsPageContext';
import { FormattedMessage, useIntl } from 'react-intl';
const useStyles = createUseStyles((theme) => ({
    container: {
        padding: '10px 16px',
        margin: 0,
        borderBottom: '1px solid #ccc',
    },
    smallHeader: {
        color: theme.colors.foreground.tertiary,
    },
    resourceCheckbox: {
        marginRight: 10,
        cursor: 'pointer',
    },
    icon: {
        color: theme.colors.primary,
    },
}));
const ScheduleTemplateResourceFilterList = (props) => {
    const { updateFilters, scheduleTemplate, type = 'all', setNumberOfChecked } = props;
    const { resourceIds } = useContext(ScheduleTemplateDetailsPageContext).filters;
    const classes = useStyles();
    const intl = useIntl();
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment ScheduleTemplateResourceFilterList_resources on Schedule
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 100 }
        after: { type: "String" }
        where: { type: "SchedulableEntityFilterInput" }
        checkedWhere: { type: "SchedulableEntityFilterInput" }
      )
      @refetchable(queryName: "ScheduleTemplateResourceFilterList_resourcesQuery") {
        id
        checked: schedulableEntities(after: $after, first: $first, where: $checkedWhere) {
          edges {
            node {
              ... on SchedulableUser {
                id
                user {
                  id
                  firstname
                  lastname
                }
              }
              ... on SchedulableAsset {
                id
                asset {
                  id
                  name
                }
              }
            }
          }
        }
        schedulableEntities(after: $after, first: $first, where: $where) {
          edges {
            node {
              ... on SchedulableUser {
                id
                user {
                  id
                  firstname
                  lastname
                }
              }
              ... on SchedulableAsset {
                id
                asset {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `, scheduleTemplate);
    const [checked, setChecked] = useState([]);
    const [unchecked, setUnchecked] = useState([]);
    useEffect(() => {
        refetch({
            model: { nameContains: undefined },
            where: { type: { eq: type === 'asset' ? 'ASSET' : 'USER' }, id: { nin: resourceIds } },
            checkedWhere: { type: { eq: type === 'asset' ? 'ASSET' : 'USER' }, id: { in: resourceIds } },
        }, { fetchPolicy: 'network-only' });
    }, []);
    useEffect(() => {
        setNumberOfChecked(checked === null || checked === void 0 ? void 0 : checked.length);
    }, [checked]);
    useDidMountEffect(() => {
        setChecked(data.checked.edges);
        setUnchecked(data.schedulableEntities.edges);
    }, [data]);
    return (<>
      <Flexbox direction="column" gap="medium">
        {!!(checked === null || checked === void 0 ? void 0 : checked.length) && (<Flexbox justify="space" align="center">
            <Label text={intl.formatMessage({ id: 'general.actions.selected', defaultMessage: 'Valda' })} className={classes.smallHeader}/>
            <Button appearance="text" size="small" style={{ padding: 0 }} onClick={() => {
                setChecked([]);
                setUnchecked([...checked, ...unchecked]);
                updateFilters(resourceIds.filter((checkedValue) => !(checked === null || checked === void 0 ? void 0 : checked.find(({ node }) => {
                    return checkedValue === node.id;
                }))), 'resourceIds');
            }}>
              <FormattedMessage id="general.actions.clear" defaultMessage="Rensa"/>
            </Button>
          </Flexbox>)}
        {checked === null || checked === void 0 ? void 0 : checked.map(({ node }) => {
            const { user, id, asset } = node;
            const name = asset ? asset.name : `${user.firstname} ${user.lastname}`;
            return (<Flexbox key={`assignmenttype_checkbox_${id}`} gap="xsmall" align="center">
              <Checkbox id={`checkedAssignmentType_${id}`} checked={!!resourceIds.find((checkedId) => checkedId === id)} className={classes.resourceCheckbox} onChange={() => {
                    setChecked(checked.filter(({ node }) => node.id !== id));
                    setUnchecked([{ node }, ...unchecked]);
                    updateFilters(resourceIds.filter((checkedValue) => checkedValue !== id), 'resourceIds');
                }}/>
              <Label style={{ fontSize: '1rem' }} text={name} htmlFor={`checkedAssignmentType_${id}`}/>
            </Flexbox>);
        })}
        {!!(checked === null || checked === void 0 ? void 0 : checked.length) && <Divider />}
        {!!(unchecked === null || unchecked === void 0 ? void 0 : unchecked.length) && (<Label text={intl.formatMessage({ defaultMessage: 'Visa endast', id: 'general.actions.only_show' })} className={classes.smallHeader}/>)}
        {unchecked === null || unchecked === void 0 ? void 0 : unchecked.map(({ node }) => {
            const { user, id, asset } = node;
            const name = asset ? asset.name : `${user.firstname} ${user.lastname}`;
            return (<Flexbox key={`assignmenttype_checkbox_${id}`} gap="xsmall" align="center">
              <Checkbox id={`checkedAssignmentType_${id}`} checked={!!resourceIds.find((checkedId) => checkedId === id)} className={classes.resourceCheckbox} onChange={(event) => {
                    setChecked([...checked, { node }]);
                    setUnchecked(unchecked.filter(({ node }) => node.id !== id));
                    updateFilters([...resourceIds, id], 'resourceIds');
                }}/>
              <Label style={{ fontSize: '1rem' }} text={name} htmlFor={`checkedAssignmentType_${id}`}/>
            </Flexbox>);
        })}
        {!(unchecked === null || unchecked === void 0 ? void 0 : unchecked.length) && (<Placeholder icon={type === 'user' ? faUsers : assetIcon} text={intl.formatMessage({
                id: 'ScheduleTemplateDetailsPage.ScheduleTemplateResourceFilterList.no_data',
                defaultMessage: 'Det finns inga {type} i schemamallen',
            }, {
                type: type === 'asset'
                    ? intl.formatMessage({ id: 'general.objects.asset.name.plural', defaultMessage: 'Enheter' })
                    : intl.formatMessage({ id: 'general.objects.user.name.plural', defaultMessage: 'Användare' }),
            })}/>)}
      </Flexbox>
    </>);
};
export default ScheduleTemplateResourceFilterList;
