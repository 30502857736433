import { useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import useAttachments from './useAttachments';
export const useScheduleShiftAttachments = (scheduleShiftRef, options) => {
    const data = useFragment(graphql `
      fragment useScheduleShiftAttachments_scheduleShift on ScheduleShift {
        id
        attachments(first: null) @connection(key: "useScheduleShiftAttachments_attachments") {
          edges {
            node {
              contentType
              directory
              id
              name
              size
              storageId
              url
            }
          }
        }
      }
    `, scheduleShiftRef);
    const [id, setId] = useState(data === null || data === void 0 ? void 0 : data.id);
    const [createScheduleShiftAttachment] = useMutation(graphql `
      mutation useScheduleShiftAttachments_createFileMutation($model: CreateScheduleShiftAttachmentRequestInput!) {
        createScheduleShiftAttachment(model: $model) {
          scheduleShiftEdge {
            node {
              ...useScheduleShiftAttachments_scheduleShift
            }
          }
        }
      }
    `);
    const [deleteScheduleShiftAttachment] = useMutation(graphql `
      mutation useScheduleShiftAttachments_deleteFileMutation($model: DeleteScheduleShiftAttachmentRequestInput!) {
        deleteScheduleShiftAttachment(model: $model) {
          scheduleShiftAttachmentEdge {
            node {
              id
            }
          }
        }
      }
    `);
    const attachments = useAttachments(Object.assign(Object.assign({}, options), { createAttachment: (uploadedAttachment) => {
            const promise = new Promise((resolve, reject) => {
                createScheduleShiftAttachment({
                    onCompleted: (response) => {
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: Object.assign(Object.assign({}, uploadedAttachment), { scheduleShiftId: id }),
                    },
                });
            });
            return promise;
        }, deleteAttachment: ({ id }) => {
            const promise = new Promise((resolve, reject) => {
                deleteScheduleShiftAttachment({
                    configs: [
                        {
                            connectionKeys: [
                                {
                                    key: 'useScheduleShiftAttachments_attachments',
                                },
                            ],
                            parentID: data.id,
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['scheduleShiftAttachmentEdge', 'node'],
                            pathToConnection: ['scheduleShift', 'attachments'],
                        },
                    ],
                    onCompleted: (response) => {
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: {
                            id,
                        },
                    },
                });
            });
            return promise;
        }, uploadEndpoint: 'schedules/v1/ScheduleShiftAttachment/upload' }));
    return Object.assign(Object.assign({}, attachments), { data, setId });
};
export default useScheduleShiftAttachments;
