import { graphql, useFragment } from 'react-relay/hooks';
export const useGetPlannedAbsences = (queryRef) => {
    const data = useFragment(graphql `
      fragment useGetPlannedAbsences_plannedAbsences on SchedulableEntity
      @argumentDefinitions(
        where: { type: "PlannedAbsenceFilterInput" }
        fromDate: { type: "DateTime!" }
        first: { type: "Int" }
        toDate: { type: "DateTime!" }
      ) {
        ... on ISchedulableEntity {
          id
          displayName
        }
        ... on SchedulableUser {
          plannedAbsences(model: { fromDate: $fromDate, toDate: $toDate }, where: $where, first: $first)
            @connection(key: "useGetPlannedAbsences_plannedAbsences", filters: ["where"]) {
            edges {
              node {
                assignedEntity {
                  ... on ISchedulableEntity {
                    id
                  }
                }
                assignmentTypeId
                assignmentType {
                  title
                  id
                }
                description
                end
                id
                reasonEvent {
                  name
                  id
                  color
                }
                start
              }
            }
          }
        }
        ... on SchedulableAsset {
          plannedAbsences(model: { fromDate: $fromDate, toDate: $toDate }, first: $first, where: $where)
            @connection(key: "useGetPlannedAbsences_plannedAbsences", filters: ["where"]) {
            edges {
              node {
                assignedEntity {
                  ... on ISchedulableEntity {
                    id
                  }
                }
                assignmentTypeId
                assignmentType {
                  title
                  id
                }
                description
                end
                id
                reasonEvent {
                  name
                  id
                }
                start
              }
            }
          }
        }
      }
    `, queryRef);
    return data;
};
