import React from 'react';
import { Flexbox, Text } from '@sonika-se/ui-kit/components';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
import { graphql, useFragment } from 'react-relay/hooks';
import Avatar from '../Avatar';
export const UserSnippet = (props) => {
    const { avatarSize = 'small', avatarVisible = true, children, className, linkDisabled = false, nameVisible = true, style, userRef, } = props;
    const user = useFragment(graphql `
      fragment UserSnippet_user on User {
        ...Avatar_iHaveAvatar
        firstname
        id
        lastname
      }
    `, userRef);
    const { frontUrls } = useEnvironment();
    return (<Flexbox align="center" className={className} justify="start" gap="small" style={style}>
      {avatarVisible && <Avatar iHaveAvatarRef={user} size={avatarSize}/>}
      {nameVisible && (<Flexbox direction="column" gap="none">
          {linkDisabled ? (<Text bold>{`${user.firstname} ${user.lastname}`}</Text>) : (<a href={`${frontUrls['CORE']}users/${user.id}`}>
              <Text bold>{`${user.firstname} ${user.lastname}`}</Text>
            </a>)}
          {children}
        </Flexbox>)}
    </Flexbox>);
};
export default UserSnippet;
