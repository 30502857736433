import React, { useEffect, useRef } from 'react';
import { Flexbox, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
import ScheduleShiftClashCard from '../cards/ScheduleShiftClashCard';
export const ScheduleShiftClashGroupList = (props) => {
    const { model, queryRef } = props;
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment ScheduleShiftClashGroupList_query on Query
      @argumentDefinitions(model: { type: "GetRunningScheduleRequestInput!" })
      @refetchable(queryName: "ScheduleShiftClashGroupList_queryQuery") {
        currentScheduleShiftClashes(model: $model) {
          ...ScheduleShiftClashCard_clashGroup
        }
      }
    `, queryRef);
    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            return;
        }
        refetch({
            model,
        }, {
            fetchPolicy: 'network-only',
        });
    }, [model]);
    return data.currentScheduleShiftClashes.length === 0 ? (<Placeholder text="Inga dubbelbokningar."/>) : (<Flexbox direction="column" gap="medium">
      {data.currentScheduleShiftClashes.map((clashGroup, index) => (<ScheduleShiftClashCard clashGroupRef={clashGroup} key={index}/>))}
    </Flexbox>);
};
export default ScheduleShiftClashGroupList;
