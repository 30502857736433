import React, { useRef, useState } from 'react';
import { format, subMonths, addMonths } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { Flexbox, IconButton, Button, MonthPicker, Sheet, SheetHeader } from '@sonika-se/ui-kit/components';
export const MonthSelect = (props) => {
    const { date, onDateChange } = props;
    const datePickerRef = useRef();
    const [showMenuList, setShowMenuList] = useState(false);
    return (<>
      <div ref={datePickerRef}>
        <Flexbox justify="space">
          <IconButton appearance="text" size="medium" icon={faAngleLeft} onClick={() => onDateChange(subMonths(date, 1))}/>
          <Button appearance="text" size="medium" style={{
            textTransform: 'capitalize',
        }} onClick={() => {
            setShowMenuList(true);
        }}>
            {format(date, 'yyyy MMMM', { locale })}
          </Button>
          <IconButton appearance="text" size="medium" icon={faAngleRight} onClick={() => onDateChange(addMonths(date, 1))}/>
        </Flexbox>
      </div>
      {showMenuList && (<Sheet onClose={() => setShowMenuList(false)}>
          <SheetHeader onClose={() => setShowMenuList(false)} closeButtonLabel="Avbry">
            Välj månad
          </SheetHeader>
          <MonthPicker onChange={(newDate) => {
                onDateChange(newDate);
                setShowMenuList(false);
            }} selectedDate={date}/>
        </Sheet>)}
    </>);
};
export default MonthSelect;
