/**
 * @generated SignedSource<<41cc009d07bbcf29cab539aaedb29fbe>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "DeleteScheduleShiftNotePayload",
            "kind": "LinkedField",
            "name": "deleteScheduleShiftNote",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfScheduleShiftNote",
                    "kind": "LinkedField",
                    "name": "noteEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShiftNote",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useScheduleShiftNotes_deleteNoteMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useScheduleShiftNotes_deleteNoteMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "8c701b6ceebe71c5ab00db88fec6cc1b",
            "id": null,
            "metadata": {},
            "name": "useScheduleShiftNotes_deleteNoteMutation",
            "operationKind": "mutation",
            "text": "mutation useScheduleShiftNotes_deleteNoteMutation(\n  $model: DeleteScheduleShiftNoteRequestInput!\n) {\n  deleteScheduleShiftNote(model: $model) {\n    noteEdge {\n      node {\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "519732ad51746ca883ee661c85f0171e";
export default node;
