/**
 * @generated SignedSource<<128188d6b772877bcee6ccab0430887c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleShiftHistoryVacancyInterestList_scheduleShiftHistoryVacancyInterestConnection",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleShiftHistoryVacancyInterest",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftHistoryVacancyInterestListItem_scheduleShiftHistoryVacancyInterest"
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
        }
    ],
    "type": "ScheduleShiftHistoryVacancyInterestConnection",
    "abstractKey": null
};
node.hash = "860e2d23faa34c4e3adf2d1d1f2b6f1b";
export default node;
