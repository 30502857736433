import React, { Suspense, useCallback, useState } from 'react';
import { faUserClock } from '@fortawesome/pro-solid-svg-icons';
import { faUserClock as faUserClockLight } from '@fortawesome/pro-regular-svg-icons';
import { Chip, Divider, Flexbox, IconButton, Label, ModalToSheetSkeleton, Popover, PopoverContent, PopoverHeader, Text, Tooltip, } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery, useQueryLoader } from 'react-relay/hooks';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { createUseStyles } from 'react-jss';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
import ScheduleShiftModal, { scheduleShiftModalQuery } from '../ScheduleShiftModal/ScheduleShiftModal';
import User from '~/components/User/User';
import { mapChangeRequestStatusWithAppearance } from '~/utils';
import enums from '~/enums.json';
const useStyles = createUseStyles((theme) => {
    return {
        requestChip: {
            padding: '.5rem',
            '&:hover': {
                cursor: 'pointer',
                background: theme.colors.background.primary,
                '& $newDate': {
                    color: theme.colors.primary,
                },
            },
        },
        oldDate: {
            textDecoration: 'line-through',
            color: theme.colors.foreground.placeholder,
        },
        newDate: {},
    };
});
export const ChangeRequestDropdownList = (props) => {
    const { header, items, onClick, userId } = props;
    const classes = useStyles();
    const intl = useIntl();
    return (<Flexbox direction="column" gap="small">
      <Label text={header}/>
      <Divider />
      {Object.keys(items).map((key, index) => {
            const requests = items[key];
            return (<Flexbox direction="column" key={key}>
            {!userId && (<Flexbox gap="small" align="center">
                <User parent={requests[0].requestedBy} size="xsmall"/>
                <Text bold>
                  {requests[0].requestedBy.firstname} {requests[0].requestedBy.lastname}
                </Text>
              </Flexbox>)}
            {requests.map((request) => {
                    var _a;
                    return (<Flexbox direction="column" gap="xxsmall" className={classes.requestChip} key={request.id} onClick={() => {
                            onClick(request.scheduleShift.id);
                        }}>
                <Flexbox justify="space" align="center" gap="medium">
                  <Label text={`${request.scheduleShift.assignmentType.title}, ${request.scheduleShift.schedule.name}`}/>
                  <Chip appearance={mapChangeRequestStatusWithAppearance(request.status)} text={intl.formatMessage({
                            id: `ScheduleShiftChangeRequestStatus_${request.status}`,
                            defaultMessage: (_a = enums.ScheduleShiftChangeRequestStatus[request.status]) === null || _a === void 0 ? void 0 : _a.description,
                        })} size="xsmall"/>
                </Flexbox>
                <Text className={classes.newDate}>
                  {format(new Date(request.start), 'd/M HH:mm', { locale })}
                  {format(new Date(request.end), new Date(request.end).getDay() !== new Date(request.start).getDay()
                            ? ' - d/M HH:mm'
                            : ' - HH:mm', { locale })}
                </Text>
                <Text size="small" className={classes.oldDate}>
                  {format(new Date(request.scheduleShift.start), 'd/M HH:mm', { locale })}
                  {format(new Date(request.scheduleShift.end), new Date(request.scheduleShift.end).getDay() !==
                            new Date(request.scheduleShift.start).getDay()
                            ? ' - d/M HH:mm'
                            : ' - HH:mm', { locale })}
                </Text>
              </Flexbox>);
                })}
          </Flexbox>);
        })}
    </Flexbox>);
};
export const ChangeRequestDropdown = (props) => {
    var _a, _b, _c;
    const { userId, iconButtonProps, variables } = props;
    const intl = useIntl();
    const [fetchKey, setFetchKey] = useState(0);
    const refetch = useCallback(() => {
        setFetchKey((prev) => {
            const result = (prev !== null && prev !== void 0 ? prev : 0) + 1;
            return result;
        });
    }, []);
    const data = useLazyLoadQuery(graphql `
      query ChangeRequestDropdownQuery(
        $assignmentTypesFilter: [ID!]
        $entitiesFilter: ScheduleTemplateShiftFilterRequestInput
        $fromDate: DateTime!
        $schedulesFilter: [ID!]
        $toDate: DateTime!
        $whereChangeRequest: ScheduleShiftChangeRequestFilterInput
      ) {
        scheduleShiftChangeRequests(
          model: { fromDate: $fromDate, toDate: $toDate }
          where: $whereChangeRequest
          schedulesFilter: $schedulesFilter
          entitiesFilter: $entitiesFilter
          assignmentTypesFilter: $assignmentTypesFilter
        ) {
          edges {
            node {
              id
              status
              start
              end
              requestedBy {
                id
                ...User
                firstname
                lastname
              }
              scheduleShift {
                start
                id
                end
                assignmentType {
                  title
                }
                schedule {
                  name
                }
              }
            }
          }
        }
      }
    `, variables, { fetchKey: fetchKey, fetchPolicy: 'network-only' });
    const [scheduleShiftModalQueryRef, loadScheduleShiftModalQuery, disposeScheduleShiftModalQuery] = useQueryLoader(scheduleShiftModalQuery);
    const [anchorEl, setAnchorEl] = useState(null);
    useDidMountEffect(() => {
        refetch();
    }, [variables]);
    if (!data || ((_b = (_a = data.scheduleShiftChangeRequests) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length) === 0) {
        return null;
    }
    const { groupedChangeRequests, groupedChangeRequestsApproved, groupedChangeRequestsDenied } = (_c = data.scheduleShiftChangeRequests) === null || _c === void 0 ? void 0 : _c.edges.reduce((prevItem, item) => {
        if (item.node.status === 'APPROVED') {
            return Object.assign(Object.assign({}, prevItem), { groupedChangeRequestsApproved: Object.assign(Object.assign({}, prevItem.groupedChangeRequestsApproved), { [item.node.requestedBy.id]: [
                        ...(prevItem.groupedChangeRequestsApproved[item.node.requestedBy.id] || []),
                        item.node,
                    ] }) });
        }
        else if (item.node.status === 'DENIED') {
            return Object.assign(Object.assign({}, prevItem), { groupedChangeRequestsDenied: Object.assign(Object.assign({}, prevItem.groupedChangeRequestsDenied), { [item.node.requestedBy.id]: [
                        ...(prevItem.groupedChangeRequestsDenied[item.node.requestedBy.id] || []),
                        item.node,
                    ] }) });
        }
        return Object.assign(Object.assign({}, prevItem), { groupedChangeRequests: Object.assign(Object.assign({}, prevItem.groupedChangeRequests), { [item.node.requestedBy.id]: [
                    ...(prevItem.groupedChangeRequests[item.node.requestedBy.id] || []),
                    item.node,
                ] }) });
    }, { groupedChangeRequests: {}, groupedChangeRequestsApproved: {}, groupedChangeRequestsDenied: {} });
    const hasNoPendingRequests = Object.keys(groupedChangeRequests).length === 0;
    const defaultButtonProps = {
        appearance: hasNoPendingRequests ? 'outlined' : 'filled',
        color: hasNoPendingRequests ? 'primary' : 'danger',
        icon: hasNoPendingRequests ? faUserClockLight : faUserClock,
    };
    return (<Suspense fallback={<IconButton color="danger" disabled icon={faUserClock}/>}>
      <Tooltip content={intl.formatMessage({
            defaultMessage: 'Begärda förändringar',
            id: 'general.misc.schedule_shift_change_request.name.plural',
        })}>
        <IconButton onClick={(event) => {
            setAnchorEl(!anchorEl ? event.currentTarget : null);
        }} {...(iconButtonProps || defaultButtonProps)}/>
      </Tooltip>
      <Popover anchorEl={anchorEl} onClose={() => setAnchorEl(null)} size="auto" style={{ minWidth: '20rem' }}>
        <PopoverHeader>
          <FormattedMessage id="general.misc.schedule_shift_change_request.name.plural" defaultMessage="Begärda förändringar"/>
        </PopoverHeader>
        <PopoverContent>
          <Flexbox direction="column" gap="large">
            {Object.keys(groupedChangeRequests).length > 0 && (<ChangeRequestDropdownList header={intl.formatMessage({
                defaultMessage: 'Öppna ärenden',
                id: 'general.misc.schedule_shift_change_request.open_requests',
            })} userId={userId} items={groupedChangeRequests} onClick={(id) => {
                setAnchorEl(null);
                loadScheduleShiftModalQuery({ shiftId: id });
            }}/>)}

            {Object.keys(groupedChangeRequestsApproved).length > 0 && (<ChangeRequestDropdownList header={intl.formatMessage({
                defaultMessage: 'Godkända förändingar',
                id: 'general.misc.schedule_shift_change_request.accepted_requests',
            })} userId={userId} items={groupedChangeRequestsApproved} onClick={(id) => {
                setAnchorEl(null);
                loadScheduleShiftModalQuery({ shiftId: id });
            }}/>)}

            {Object.keys(groupedChangeRequestsDenied).length > 0 && (<ChangeRequestDropdownList header={intl.formatMessage({
                defaultMessage: 'Avslagna förändingar',
                id: 'general.misc.schedule_shift_change_request.rejected_requests',
            })} userId={userId} items={groupedChangeRequestsDenied} onClick={(id) => {
                setAnchorEl(null);
                loadScheduleShiftModalQuery({ shiftId: id });
            }}/>)}
          </Flexbox>
        </PopoverContent>
      </Popover>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh" onClose={() => disposeScheduleShiftModalQuery()} type="loader"/>}>
        {scheduleShiftModalQueryRef && (<ScheduleShiftModal onClose={() => disposeScheduleShiftModalQuery()} preloadedQuery={scheduleShiftModalQueryRef} defaultTab="request_change"/>)}
      </Suspense>
    </Suspense>);
};
export default ChangeRequestDropdown;
