/**
 * @generated SignedSource<<346dd55f1ab3758e70ad3700cbfb49c1>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = {
        "kind": "Variable",
        "name": "model",
        "variableName": "model"
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftVacancyCountBadge_queryQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftVacancyCountBadge_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleShiftVacancyCountBadge_queryQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        (v1 /*: any*/),
                        {
                            "kind": "Literal",
                            "name": "where",
                            "value": {
                                "assignedEntityId": {
                                    "eq": null
                                }
                            }
                        }
                    ],
                    "concreteType": "ScheduleShiftConnection",
                    "kind": "LinkedField",
                    "name": "scheduleShifts",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalCount",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "49313150cb594848bae1e1387d26aabd",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftVacancyCountBadge_queryQuery",
            "operationKind": "query",
            "text": "query ScheduleShiftVacancyCountBadge_queryQuery(\n  $model: GetRunningScheduleRequestInput!\n) {\n  ...ScheduleShiftVacancyCountBadge_query_47b4ea\n}\n\nfragment ScheduleShiftVacancyCountBadge_query_47b4ea on Query {\n  scheduleShifts(model: $model, where: {assignedEntityId: {eq: null}}) {\n    totalCount\n  }\n}\n"
        }
    };
})();
node.hash = "e2ad8273c18b69a579c13d8354953312";
export default node;
