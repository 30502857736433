import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { useMutation, graphql, usePreloadedQuery } from 'react-relay/hooks';
import { Button, Field, Form, Input, ModalToSheet, ModalToSheetContent, ModalToSheetFooter, ModalToSheetHeader, DateInput, Select, Flexbox, } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { date } from 'yup';
import { formatISO } from 'date-fns';
export const newScheduleTemplateModalQuery = graphql `
  query NewScheduleTemplateModalQuery {
    schedules {
      nodes {
        id
        name
      }
    }
  }
`;
const validationSchema = (hideScheduleSelect) => object({
    name: string().required('Du måste ange ett namn'),
    preliminaryStartDate: date().required('Du måste ange ett startdatum'),
    scheduleId: hideScheduleSelect ? string().optional() : string().required('Du måste välja ett schema för mallen'),
});
export const NewScheduleTemplateModal = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { hideScheduleSelect, onCancel, onSubmit, preloadedQuery, defaultScheduleId } = props;
    const intl = useIntl();
    const data = usePreloadedQuery(newScheduleTemplateModalQuery, preloadedQuery);
    const defaultSelected = (_b = (_a = data === null || data === void 0 ? void 0 : data.schedules) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.find(({ id }) => id === defaultScheduleId);
    const [selectedItems, setSelectedItems] = useState([{ label: defaultSelected === null || defaultSelected === void 0 ? void 0 : defaultSelected.name, value: defaultSelected === null || defaultSelected === void 0 ? void 0 : defaultSelected.id }]);
    const { control, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: {
            scheduleId: defaultScheduleId,
            preliminaryStartDate: new Date(),
        },
        resolver: yupResolver(validationSchema(hideScheduleSelect)),
    });
    const [createScheduleTemplate, isInFlight] = useMutation(graphql `
    mutation NewScheduleTemplateModalCreateMutation($model: CreateTemplateRequestInput!) {
      createScheduleTemplate(model: $model) {
        templateEdge {
          node {
            id
            name
            schedule {
              id
              templates {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
            schedule2: schedule {
              ...ScheduleTemplateTable_schedule
            }
          }
        }
      }
    }
  `);
    return (<ModalToSheet onClose={onCancel}>
      <ModalToSheetHeader onClose={onCancel} closeButtonLabel="Avbryt">
        Ny mall
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Form id="newScheduleTemplateForm" onSubmit={handleSubmit((data) => {
            createScheduleTemplate({
                variables: {
                    model: {
                        name: data.name,
                        periodLengthInDays: 7,
                        preliminaryStartDate: formatISO(data.preliminaryStartDate),
                        scheduleId: hideScheduleSelect ? defaultScheduleId : data.scheduleId,
                        startsOnWeekday: 'MONDAY',
                    },
                },
                onCompleted: (data) => {
                    var _a, _b, _c;
                    onSubmit((_c = (_b = (_a = data.createScheduleTemplate) === null || _a === void 0 ? void 0 : _a.templateEdge) === null || _b === void 0 ? void 0 : _b.node) === null || _c === void 0 ? void 0 : _c.id);
                },
                onError: (error) => console.log({ error }),
            });
        })}>
          <Field error={(_d = (_c = errors === null || errors === void 0 ? void 0 : errors.name) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString()} label={intl.formatMessage({
            id: 'general.objects.schedule_template.fields.name.label',
            defaultMessage: 'Namn',
        })}>
            <Controller control={control} name="name" render={({ field }) => {
            var _a, _b;
            return (<Input inputName="name" disabled={isInFlight} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={field.onChange}/>);
        }}/>
          </Field>
          {!hideScheduleSelect && (<Field error={(_f = (_e = errors === null || errors === void 0 ? void 0 : errors.scheduleId) === null || _e === void 0 ? void 0 : _e.message) === null || _f === void 0 ? void 0 : _f.toString()} label={intl.formatMessage({
                id: 'general.objects.schedule_template.fields.schedule_id.label',
                defaultMessage: 'För schema',
            })}>
              <Controller control={control} name="scheduleId" render={({ field }) => {
                var _a, _b;
                return (<Select error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.scheduleId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} disabled={isInFlight} selectedItems={selectedItems} onChange={(values) => {
                        field.onChange(values);
                        setSelectedItems(values);
                    }} placeholder="Välj schema" options={data.schedules.nodes.map((schedule) => ({
                        label: schedule.name,
                        value: schedule.id,
                    }))}/>);
            }}/>
            </Field>)}
          <Field error={(_h = (_g = errors === null || errors === void 0 ? void 0 : errors.preliminaryStartDate) === null || _g === void 0 ? void 0 : _g.message) === null || _h === void 0 ? void 0 : _h.toString()} label={intl.formatMessage({
            id: 'general.objects.schedule_template.fields.premilinary_start_date.label',
            defaultMessage: 'Preliminärt startdatum',
        })}>
            <Controller control={control} name="preliminaryStartDate" render={({ field }) => {
            var _a, _b;
            return (<DateInput error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.preliminaryStartDate) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} disabled={isInFlight} onChange={field.onChange} value={field.value}/>);
        }}/>
          </Field>
        </Form>
      </ModalToSheetContent>
      <ModalToSheetFooter>
        <Flexbox gap="medium">
          <Button disabled={isInFlight} onClick={() => onCancel()} appearance="outlined">
            <FormattedMessage id="general.actions.cancel" defaultMessage="Avbryt"/>
          </Button>
          <Button disabled={isInFlight} form="newScheduleTemplateForm" type="submit">
            <FormattedMessage id="general.actions.create" defaultMessage="Lägg till"/>
          </Button>
        </Flexbox>
      </ModalToSheetFooter>
    </ModalToSheet>);
};
