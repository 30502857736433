var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Suspense, useMemo } from 'react';
import { Chip, Flexbox, ModalToSheetSkeleton, Text } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import ScheduleShiftModal, { scheduleShiftModalQuery, } from '~/pages/SchedulePages/components/ScheduleShiftModal/ScheduleShiftModal';
import dateRangeInText from '~/utils/dateRangeInText';
export const ScheduleShiftCard = (props) => {
    var _a, _b, _c, _d;
    const { className, date, onClick, scheduleShiftRef, type } = props, rest = __rest(props, ["className", "date", "onClick", "scheduleShiftRef", "type"]);
    const data = useFragment(graphql `
      fragment ScheduleShiftCard_scheduleShift on ScheduleShift {
        id
        assignedEntity {
          ... on SchedulableAsset {
            asset {
              color
            }
          }
          ... on SchedulableUser {
            user {
              color
            }
          }
        }
        assignmentType {
          title
        }
        schedule {
          name
        }
        end
        simultaneousScheduleShift {
          edges {
            node {
              id
              assignedEntity {
                ... on SchedulableAsset {
                  id
                  asset {
                    name
                    color
                  }
                }
              }
            }
          }
        }
        start
        status
      }
    `, scheduleShiftRef);
    const [scheduleShiftModalQueryRef, loadScheduleShiftModalQuery, disposeScheduleShiftModalQuery] = useQueryLoader(scheduleShiftModalQuery);
    const title = useMemo(() => {
        const getBaseTitle = () => {
            var _a;
            if (type === 'ASSET') {
                return `${data.assignmentType.title} (${data.schedule.name})`;
            }
            const simultaneousAssets = (_a = data.simultaneousScheduleShift) === null || _a === void 0 ? void 0 : _a.edges.filter((asset) => { var _a, _b; return (_b = (_a = asset === null || asset === void 0 ? void 0 : asset.node) === null || _a === void 0 ? void 0 : _a.assignedEntity) === null || _b === void 0 ? void 0 : _b.asset; });
            return `${data.assignmentType.title}${(simultaneousAssets === null || simultaneousAssets === void 0 ? void 0 : simultaneousAssets.length)
                ? ` (${simultaneousAssets.map((asset) => asset.node.assignedEntity.asset.name).join(', ')})`
                : ''}`;
        };
        const baseTitle = getBaseTitle();
        if (data.status == 'WITHDRAWN') {
            return `Inställt - ${baseTitle}`;
        }
        return baseTitle;
    }, [data]);
    return (<>
      <div className={className} onClick={(event) => {
            loadScheduleShiftModalQuery({
                shiftId: data.id,
            });
            if (onClick) {
                onClick(event);
            }
        }} {...rest}>
        <Flexbox direction="column" gap="xsmall">
          <Text size="small">
            {dateRangeInText(date, new Date(data === null || data === void 0 ? void 0 : data.start.toString()), new Date(data === null || data === void 0 ? void 0 : data.end.toString()))}
          </Text>
          <Chip color={data.assignedEntity ? ((_b = (_a = data.assignedEntity) === null || _a === void 0 ? void 0 : _a.asset) === null || _b === void 0 ? void 0 : _b.color) || ((_d = (_c = data.assignedEntity) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.color) : '#F44336'} style={{
            cursor: 'pointer',
        }} text={title} specialColor={data.status == 'WITHDRAWN' ? 'gray-striped' : undefined}/>
        </Flexbox>
      </div>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh" onClose={() => disposeScheduleShiftModalQuery()} type="loader"/>}>
        {scheduleShiftModalQueryRef && (<ScheduleShiftModal onClose={() => disposeScheduleShiftModalQuery()} preloadedQuery={scheduleShiftModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleShiftCard;
