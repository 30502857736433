import React from 'react';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router';
import { ScheduleAssignmentTypeResourceTablePanel, } from '~/panels/ScheduleAssignmentTypeResourceTablePanel';
export const ScheduleResourcesPage = () => {
    const { scheduleId } = useParams();
    const { schedule } = useLazyLoadQuery(graphql `
      query ScheduleResourcesPageQuery($scheduleId: ID!) {
        schedule(id: $scheduleId) {
          assignmentTypes {
            nodes {
              ...ScheduleAssignmentTypeResourceTablePanel_scheduleAssignmentType
              id
            }
          }
        }
      }
    `, {
        scheduleId,
    });
    return (<Flexbox direction="column" gap="medium">
      {schedule.assignmentTypes.nodes.map((assignmentType) => (<ScheduleAssignmentTypeResourceTablePanel key={assignmentType.id} scheduleAssignmentTypeRef={assignmentType}/>))}
    </Flexbox>);
};
export default ScheduleResourcesPage;
