import React from 'react';
import { ErrorBox } from '@sonika-se/ui-kit/components';
export const ErrorBoundary = (props) => {
    const { children, onError = () => <ErrorBox style={{ flex: 1 }} type="page"/> } = props;
    // TODO: Enable AppInsightsErrorBoundary when this issue is resolved https://github.com/microsoft/ApplicationInsights-JS/issues/1804
    return <FallbackErrorBoundary fallback={onError}>{children}</FallbackErrorBoundary>;
    /*return (
      <AppInsightsErrorBoundary appInsights={reactPlugin} onError={onError}>
        {children}
      </AppInsightsErrorBoundary>
    )*/
    // return <>{children}</>
};
class FallbackErrorBoundary extends React.Component {
    static getDerivedStateFromError(error) {
        return { error: error };
    }
    render() {
        var _a;
        if ((_a = this.state) === null || _a === void 0 ? void 0 : _a.error) {
            console.log('error!!!');
            // You can render any custom fallback UI
            return this.props.fallback(this.state.error);
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
