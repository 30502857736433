import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Flexbox, FormModal, Icon, Notification, Text } from '@sonika-se/ui-kit/components';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { formatDate, formatDateSpan, toDate } from '@sonika-se/ui-kit/utils/time';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
import ScheduleShiftVacancyNotifyModalForm from '~/forms/ScheduleShiftVacancyNotifyModalForm';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
export const scheduleShiftVacancyNotifyModalQuery = graphql `
  query ScheduleShiftVacancyNotifyModalQuery($scheduleShiftId: ID!, $where: UserFilterInput) {
    ...ScheduleShiftVacancyNotifyModalForm_query @arguments(where: $where)
    scheduleShift(id: $scheduleShiftId) {
      assignmentType {
        title
      }
      end
      schedule {
        name
      }
      start
    }
    users(where: $where) {
      nodes {
        id
      }
    }
  }
`;
export const ScheduleShiftVacancyNotifyModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const formRef = useRef(null);
    const data = usePreloadedQuery(scheduleShiftVacancyNotifyModalQuery, preloadedQuery);
    const [dispatchId, setDispatchId] = useState();
    const [createDispatchContent, createDispatchContentLoading] = useMutation(graphql `
        mutation ScheduleShiftVacancyNotifyModal_createDispatchContentMutation($model: CreateDispatchContentInput!) {
          createDispatchContent(model: $model) {
            dispatchContent {
              id
            }
          }
        }
      `);
    const [createDispatch, createDispatchLoading] = useMutation(graphql `
      mutation ScheduleShiftVacancyNotifyModal_createDispatchMutation($model: CreateDispatchInput!) {
        createDispatch(model: $model) {
          dispatch {
            id
          }
        }
      }
    `);
    const [submitDispatch, submitDispatchLoading] = useMutation(graphql `
      mutation ScheduleShiftVacancyNotifyModal_submitDispatchMutation($model: SubmitDispatchInput!) {
        submitDispatch(model: $model) {
          dispatch {
            id
          }
        }
      }
    `);
    const submitForm = useCallback(() => {
        const dispatchName = `Vakant pass ${formatDate(toDate(data.scheduleShift.start), 'short')} - ${data.scheduleShift.schedule.name}`;
        formRef.current.submit((form) => {
            createDispatchContent({
                onCompleted: ({ createDispatchContent }) => {
                    createDispatch({
                        onCompleted: ({ createDispatch }) => {
                            submitDispatch({
                                onCompleted: ({ submitDispatch }) => {
                                    setDispatchId(submitDispatch.dispatch.id);
                                },
                                variables: {
                                    model: {
                                        dispatchId: createDispatch.dispatch.id,
                                    },
                                },
                            });
                        },
                        variables: {
                            model: {
                                contentId: createDispatchContent.dispatchContent.id,
                                name: dispatchName,
                                recipientsType: 'USERS',
                                sendAtType: 'IMMEDIATELY',
                                toUserIds: form.recipientUserIds,
                            },
                        },
                    });
                },
                variables: {
                    model: {
                        name: dispatchName,
                        smsContent: {
                            text: form.content,
                        },
                        sendSmsContent: true,
                    },
                },
            });
        });
    }, [formRef]);
    const loading = useMemo(() => createDispatchContentLoading || createDispatchLoading || submitDispatchLoading, [createDispatchContentLoading || createDispatchLoading || submitDispatchLoading]);
    const { frontUrls } = useEnvironment();
    return (<FormModal disabled={loading || dispatchId !== undefined} onClose={onClose} size="medium" onSubmit={submitForm} submitButtonLabel="Skicka" title="Meddela tillgängliga">
      {!loading && !dispatchId && (<ScheduleShiftVacancyNotifyModalForm defaultValues={{
                content: `Vakant pass som ${data.scheduleShift.assignmentType.title} i schema ${data.scheduleShift.schedule.name} ${formatDateSpan(toDate(data.scheduleShift.start), toDate(data.scheduleShift.end), 'short')}. Intresserad?`,
                recipientUserIds: data.users.nodes.map((user) => user.id),
            }} onSubmit={submitForm} queryRef={data} ref={formRef}/>)}
      {loading && (<Flexbox align="center" direction="column" gap="small">
          <Icon color={sonikaTheme.colors.primary} icon={faSpinner} size="2x" spin/>
          <Text>Ditt utskick skapas</Text>
        </Flexbox>)}
      {dispatchId && (<Notification shadow={false} title={`Meddelande skickat genom Sonika Utskick.`} actions={[
                {
                    title: 'Visa utskick',
                    onClick: () => {
                        window.location.href = `${frontUrls.NOTIFY}dispatches/${dispatchId}`;
                    },
                },
            ]}/>)}
    </FormModal>);
};
export default ScheduleShiftVacancyNotifyModal;
