import React, { Suspense, useMemo, useState } from 'react';
import { usePreloadedQuery, graphql } from 'react-relay/hooks';
import { ModalToSheet, ModalToSheetHeader, ModalToSheetContent, Flexbox, Text, H3, ModalToSheetSkeleton, } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { useNavigate } from 'react-router';
import ScheduleShiftContent from '../../ScheduleShiftPage/components/ScheduleShiftContent';
import DeleteScheduleShiftConfirm from '../../ScheduleShiftPage/components/ScheduleShiftInformationTab/components/DeleteScheduleShiftConfirm';
import displayScheduleShiftStartEndDates from '~/utils/displayScheduleShiftStartEndDates';
import ChangeShiftTemplateTimeModal from '~/modals/ChangeShiftTemplateTimeModal';
export const scheduleShiftModalQuery = graphql `
  query ScheduleShiftModalQuery($shiftId: ID!) {
    scheduleShift(id: $shiftId) {
      id
      start
      end
      schedule {
        currentUserPermissions
        name
        id
      }
      assignmentTypeId
      assignmentType {
        title
      }
      assignedEntityId
      assignedEntity {
        ... on SchedulableUser {
          user {
            id
          }
        }
      }
      ...ScheduleShiftContent_ScheduleShift
      isVacant
      status
      originatingScheduleTemplateShift {
        id
      }
    }
    currentUser {
      me {
        id
        schedulableEntity {
          id
          resourceInScheduleAssignmentTypes {
            edges {
              node {
                scheduleAssignmentType {
                  id
                  assignmentType {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const ScheduleShiftModal = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { onClose, preloadedQuery, absenceReason, defaultTab } = props;
    const intl = useIntl();
    const [selectedTab, setSelectedTab] = useState(defaultTab || 'information');
    const navigate = useNavigate();
    const { currentUser, scheduleShift } = usePreloadedQuery(scheduleShiftModalQuery, preloadedQuery);
    const [showDeleteShiftConfirm, setShowDeleteShiftConfirm] = useState(false);
    const [showChangeShiftTemplateTime, setShowChangeShiftTemplateTime] = useState(false);
    const hasPermissions = usePermissions();
    const canRequestShift = !scheduleShift.assignedEntityId &&
        currentUser.me.schedulableEntity &&
        !!((_d = (_c = (_b = (_a = currentUser.me) === null || _a === void 0 ? void 0 : _a.schedulableEntity) === null || _b === void 0 ? void 0 : _b.resourceInScheduleAssignmentTypes) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.find(({ node: item }) => (item === null || item === void 0 ? void 0 : item.scheduleAssignmentType.assignmentType.id) === (scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.assignmentTypeId)));
    const currentUserPermissions = useMemo(() => scheduleShift.schedule.currentUserPermissions, []);
    const hasScheduleEditRunningPermission = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || currentUserPermissions.includes('EDIT_RUNNING'), [currentUserPermissions, hasPermissions]);
    const actions = [
        {
            label: intl.formatMessage({
                id: 'ScheduleShiftModal.go_to_page',
                defaultMessage: 'Gå till passida',
            }),
            onClick: () => {
                navigate(`/running-schedules/${scheduleShift.id}`);
            },
        },
    ];
    if (hasScheduleEditRunningPermission) {
        if (scheduleShift.originatingScheduleTemplateShift != null) {
            actions.push({
                label: 'Ändra malltid',
                onClick: () => {
                    setShowChangeShiftTemplateTime(true);
                },
            });
        }
        actions.push({
            label: 'Ta bort',
            onClick: () => {
                setShowDeleteShiftConfirm(true);
            },
        });
    }
    return (<>
      <ModalToSheet onClose={onClose} style={{ minHeight: '20vh' }} size="large">
        <ModalToSheetHeader onClose={onClose} closeButtonLabel="Stäng" actions={actions} tabs={{
            initialValue: defaultTab || 'information',
            onChange: (value) => setSelectedTab(value),
            tabs: [
                {
                    key: 'information',
                    label: intl.formatMessage({ id: 'ScheduleShiftModal.Tab.information', defaultMessage: 'Information' }),
                    value: 'information',
                },
                (hasScheduleEditRunningPermission || ((_f = (_e = scheduleShift.assignedEntity) === null || _e === void 0 ? void 0 : _e.user) === null || _f === void 0 ? void 0 : _f.id) === currentUser.me.id) && {
                    key: 'request_change',
                    value: 'request_change',
                    label: intl.formatMessage({
                        id: 'ScheduleShiftModal.Tab.request_change',
                        defaultMessage: 'Begär förändring',
                    }),
                },
                hasScheduleEditRunningPermission && {
                    key: 'notes',
                    label: intl.formatMessage({ id: 'ScheduleShiftModal.Tab.notes', defaultMessage: 'Anteckningar' }),
                    value: 'notes',
                },
                hasScheduleEditRunningPermission && {
                    key: 'history',
                    label: intl.formatMessage({ id: 'ScheduleShiftModal.Tab.history', defaultMessage: 'Historik' }),
                    value: 'history',
                },
            ],
        }}>
          <div>
            <Flexbox align="center" gap="medium">
              <Text size="large">
                {scheduleShift.schedule.name} - {(_g = scheduleShift.assignmentType) === null || _g === void 0 ? void 0 : _g.title}
              </Text>{' '}
            </Flexbox>
            <H3>{displayScheduleShiftStartEndDates(scheduleShift.start, scheduleShift.end)}</H3>
          </div>
        </ModalToSheetHeader>
        <ModalToSheetContent>
          <ScheduleShiftContent schedulableEntityId={(_j = (_h = currentUser.me) === null || _h === void 0 ? void 0 : _h.schedulableEntity) === null || _j === void 0 ? void 0 : _j.id} selectedTab={selectedTab} scheduleShift={scheduleShift} canRequestShift={canRequestShift}/>
        </ModalToSheetContent>
      </ModalToSheet>
      <DeleteScheduleShiftConfirm scheduleShiftId={scheduleShift.id} scheduleId={scheduleShift.schedule.id} open={showDeleteShiftConfirm} onClose={(deleted) => {
            setShowDeleteShiftConfirm(false);
            if (!!deleted) {
                onClose();
            }
        }}/>
      {showChangeShiftTemplateTime && (<Suspense fallback={<ModalToSheetSkeleton type="loader"/>}>
          <ChangeShiftTemplateTimeModal onClose={() => {
                console.log('tid uppdaterad');
                setShowChangeShiftTemplateTime(false);
            }} onDismiss={() => {
                setShowChangeShiftTemplateTime(false);
            }} shiftTemplateId={(_k = scheduleShift.originatingScheduleTemplateShift) === null || _k === void 0 ? void 0 : _k.id}/>
        </Suspense>)}
    </>);
};
export default ScheduleShiftModal;
