/**
 * @generated SignedSource<<fd9ca053f8294d88da3109b02e6748fb>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "assignmentTypeId"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "endTime"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "schedulableUserId"
    }, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "startTime"
    }, v4 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "schedulableUserId"
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v6 = [
        {
            "kind": "Variable",
            "name": "assignmentTypeId",
            "variableName": "assignmentTypeId"
        },
        {
            "kind": "Variable",
            "name": "dateTime",
            "variableName": "startTime"
        }
    ], v7 = {
        "kind": "Literal",
        "name": "unit",
        "value": "HOUR"
    }, v8 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
        }
    ], v9 = {
        "alias": null,
        "args": [
            {
                "kind": "Variable",
                "name": "fromDate",
                "variableName": "startTime"
            },
            {
                "kind": "Variable",
                "name": "toDate",
                "variableName": "endTime"
            },
            (v7 /*: any*/)
        ],
        "concreteType": "TimeSpanUnitValue",
        "kind": "LinkedField",
        "name": "expectedWorkTime",
        "plural": false,
        "selections": (v8 /*: any*/),
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": [
            {
                "kind": "Variable",
                "name": "currentTime",
                "variableName": "startTime"
            }
        ],
        "concreteType": "WorkTimePeriod",
        "kind": "LinkedField",
        "name": "currentPeriod",
        "plural": false,
        "selections": [
            (v5 /*: any*/),
            {
                "alias": null,
                "args": [
                    (v7 /*: any*/)
                ],
                "concreteType": "TimeSpanUnitValue",
                "kind": "LinkedField",
                "name": "ordinaryWorkTimePerDay",
                "plural": false,
                "selections": (v8 /*: any*/),
                "storageKey": "ordinaryWorkTimePerDay(unit:\"HOUR\")"
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "PlannedAbsenceFormEmploymentQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "schedulableEntity",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v6 /*: any*/),
                                            "concreteType": "Employment",
                                            "kind": "LinkedField",
                                            "name": "activeEmployment",
                                            "plural": false,
                                            "selections": [
                                                (v5 /*: any*/),
                                                (v9 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "EmploymentSetting",
                                                    "kind": "LinkedField",
                                                    "name": "employmentSetting",
                                                    "plural": false,
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        (v10 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "WorkTimeSetting",
                                                            "kind": "LinkedField",
                                                            "name": "workTimeSetting",
                                                            "plural": false,
                                                            "selections": [
                                                                (v11 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "SchedulableUser",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v2 /*: any*/),
                (v0 /*: any*/),
                (v3 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "PlannedAbsenceFormEmploymentQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "schedulableEntity",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v6 /*: any*/),
                                            "concreteType": "Employment",
                                            "kind": "LinkedField",
                                            "name": "activeEmployment",
                                            "plural": false,
                                            "selections": [
                                                (v5 /*: any*/),
                                                (v9 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "EmploymentSetting",
                                                    "kind": "LinkedField",
                                                    "name": "employmentSetting",
                                                    "plural": false,
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        (v10 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "WorkTimeSetting",
                                                            "kind": "LinkedField",
                                                            "name": "workTimeSetting",
                                                            "plural": false,
                                                            "selections": [
                                                                (v11 /*: any*/),
                                                                (v5 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "SchedulableUser",
                            "abstractKey": null
                        },
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v5 /*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e7a57e06fbc501b12a8d88818380cf9c",
            "id": null,
            "metadata": {},
            "name": "PlannedAbsenceFormEmploymentQuery",
            "operationKind": "query",
            "text": "query PlannedAbsenceFormEmploymentQuery(\n  $schedulableUserId: ID!\n  $assignmentTypeId: ID!\n  $startTime: DateTime!\n  $endTime: DateTime!\n) {\n  schedulableEntity(id: $schedulableUserId) {\n    __typename\n    ... on SchedulableUser {\n      id\n      user {\n        id\n        activeEmployment(assignmentTypeId: $assignmentTypeId, dateTime: $startTime) {\n          id\n          expectedWorkTime(fromDate: $startTime, toDate: $endTime, unit: HOUR) {\n            value\n            unit\n          }\n          employmentSetting {\n            id\n            title\n            workTimeSetting {\n              currentPeriod(currentTime: $startTime) {\n                id\n                ordinaryWorkTimePerDay(unit: HOUR) {\n                  value\n                  unit\n                }\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "35e29938db6fb43362113471327ac633";
export default node;
