import React, { useState } from 'react';
import { Button, Flexbox, H6 } from '@sonika-se/ui-kit/components';
import { graphql, useMutation } from 'react-relay/hooks';
import ScheduleShiftBreakForm from './ScheduleShiftBreakForm';
import { formatISO } from 'date-fns';
const AddScheduleShiftBreak = (props) => {
    const { defaultOpen = false, sameDay, shiftId, breaks = [], shiftStart } = props;
    const [formOpen, setFormOpen] = useState(defaultOpen);
    const [addScheduleShiftBreak, addScheduleShiftBreakLoading] = useMutation(graphql `
    mutation AddScheduleShiftBreakMutation($model: UpdateScheduleShiftTimeRequestInput!) {
      updateScheduleShiftTime(model: $model) {
        shiftEdge {
          node {
            id
            breaks {
              end
              start
              type
            }
          }
        }
        scheduleShiftHistoryTimeChangeEdge {
          node {
            id
            scheduleShift {
              ...ScheduleShift_shift
              ...ScheduleShiftHistory_scheduleShift
            }
            ...ScheduleShiftHistoryTimeChange_history
          }
        }
      }
    }
  `);
    if (!formOpen) {
        return (<Button size="large" appearance="text" display="block" onClick={() => setFormOpen(true)}>
        Lägg till rast
      </Button>);
    }
    const mergeDateAndTime = (date, time) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
    };
    return (<Flexbox direction="column" gap="small" style={{ padding: '.75rem 1rem' }}>
      <H6>Lägg till rast</H6>
      <ScheduleShiftBreakForm sameDay={sameDay} isLoading={addScheduleShiftBreakLoading} onClose={() => setFormOpen(false)} onSubmit={(values) => {
            const breakStart = sameDay ? mergeDateAndTime(shiftStart, values.start) : values.start;
            const breakEnd = sameDay ? mergeDateAndTime(shiftStart, values.end) : values.end;
            addScheduleShiftBreak({
                variables: {
                    model: {
                        shiftId,
                        breaks: [
                            ...breaks,
                            {
                                start: formatISO(breakStart),
                                end: formatISO(breakEnd),
                                type: values.type,
                            },
                        ],
                    },
                },
                onCompleted: () => setFormOpen(false),
            });
        }}/>
    </Flexbox>);
};
export default AddScheduleShiftBreak;
