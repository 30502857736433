/**
 * @generated SignedSource<<4dc0641bf25a0f5e75e50360a5c928ef>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000
                },
                {
                    "kind": "Literal",
                    "name": "order",
                    "value": {
                        "sortOrder": "ASC"
                    }
                }
            ],
            "concreteType": "ScheduleConnection",
            "kind": "LinkedField",
            "name": "schedules",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "archived",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "inactivatedFrom",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "schedules(first:10000,order:{\"sortOrder\":\"ASC\"})"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleSortModalQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ScheduleSortModalQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "197604faf7284c448bc2e17239a4c2dd",
            "id": null,
            "metadata": {},
            "name": "ScheduleSortModalQuery",
            "operationKind": "query",
            "text": "query ScheduleSortModalQuery {\n  schedules(first: 10000, order: {sortOrder: ASC}) {\n    nodes {\n      archived\n      inactivatedFrom\n      id\n      name\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "af85ab28f9b74facac794ab86d5ddc26";
export default node;
