/**
 * @generated SignedSource<<ce7b0c2c219e6371d4885ae4f435fea4>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AssignedEntity_ScheduleShift",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignedEntity",
                "plural": false,
                "selections": [
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "color",
                                "storageKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "user",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "phone",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "type": "SchedulableUser",
                                "abstractKey": null
                            }
                        ],
                        "type": "ISchedulableEntity",
                        "abstractKey": "__isISchedulableEntity"
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SchedulableEntityWithText_iScheduleableEntity"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleAssignmentType",
                "kind": "LinkedField",
                "name": "scheduleAssignmentType",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "where",
                                "value": {
                                    "schedulableEntity": {
                                        "type": {
                                            "eq": "USER"
                                        }
                                    }
                                }
                            }
                        ],
                        "concreteType": "ScheduleAssignmentTypeResourceConnection",
                        "kind": "LinkedField",
                        "name": "resources",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleAssignmentTypeResource",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "schedulableEntity",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "User",
                                                        "kind": "LinkedField",
                                                        "name": "user",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "type": "SchedulableUser",
                                                "abstractKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": "resources(where:{\"schedulableEntity\":{\"type\":{\"eq\":\"USER\"}}})"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentUserPermissions",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RemoveAssignedEntity"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateAssignedEntityOnShift_scheduleShift"
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isVacant",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ScheduleShiftAssetValidations_scheduleShift"
            }
        ],
        "type": "ScheduleShift",
        "abstractKey": null
    };
})();
node.hash = "7237181a49e003ce8288c3261b5c81f8";
export default node;
