/**
 * @generated SignedSource<<e7375c4cf8c9e445b6846bb305d178ec>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "Company",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            },
            {
                "alias": "avatarImage",
                "args": null,
                "concreteType": "Avatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "storageId",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "queryTransform",
                                "value": "?width=68&height=68"
                            }
                        ],
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": "url(queryTransform:\"?width=68&height=68\")"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Company",
        "abstractKey": null
    };
})();
node.hash = "ca9805c8c450756cabdbd304d4718bfd";
export default node;
