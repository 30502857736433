import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { faEye as faEyeSolid } from '@fortawesome/pro-solid-svg-icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { Text, Skeleton, Divider, Popover, PopoverHeader, PopoverContent, IconButton, Flexbox, Checkbox, Label, Tooltip, Badge, } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import MySchedulePageContext from '../../MySchedulePageContext';
import PlaceholderText from '~/components/PlaceholderText';
const useStyles = createUseStyles(() => ({
    container: {
        padding: '10px 16px',
        margin: 0,
        borderBottom: '1px solid #ccc',
    },
    resourceRow: {
        fontSize: 16,
        paddingTop: 5,
        paddingBottom: 5,
    },
    resourceCheckbox: {
        marginRight: 10,
        cursor: 'pointer',
    },
    scheduleContainer: {
        overflow: 'auto',
        maxHeight: '50vh',
    },
}));
const MyScheduleFilter = (props) => {
    var _a, _b, _c, _d, _e;
    const { queryRef, updateFilters } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { scheduleIds: checked, assignmentTypeIds } = useContext(MySchedulePageContext).filters;
    const open = Boolean(anchorEl);
    const intl = useIntl();
    const classes = useStyles();
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const { size: pageSize } = useViewport();
    const data = useFragment(graphql `
      fragment MyScheduleFilter_schedulableUser on SchedulableUser {
        resourceInScheduleAssignmentTypes {
          edges {
            node {
              id
              scheduleAssignmentType {
                id
                assignmentType {
                  id
                  title
                }
                schedule {
                  id
                }
              }
            }
          }
        }
        resourceInSchedules {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, queryRef);
    const assignmentTypes = ((_b = (_a = data === null || data === void 0 ? void 0 : data.resourceInScheduleAssignmentTypes) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.reduce((prev, cur) => {
        var _a, _b, _c, _d, _e, _f;
        return Object.assign(Object.assign({}, prev), { [(_c = (_b = (_a = cur === null || cur === void 0 ? void 0 : cur.node) === null || _a === void 0 ? void 0 : _a.scheduleAssignmentType) === null || _b === void 0 ? void 0 : _b.assignmentType) === null || _c === void 0 ? void 0 : _c.id]: (_f = (_e = (_d = cur === null || cur === void 0 ? void 0 : cur.node) === null || _d === void 0 ? void 0 : _d.scheduleAssignmentType) === null || _e === void 0 ? void 0 : _e.assignmentType) === null || _f === void 0 ? void 0 : _f.title });
    }, {})) || {};
    const handleClose = () => {
        setAnchorEl(null);
    };
    const nrOfFilters = (checked === null || checked === void 0 ? void 0 : checked.length) + (assignmentTypeIds === null || assignmentTypeIds === void 0 ? void 0 : assignmentTypeIds.length);
    return (<>
      <Tooltip content={intl.formatMessage({ id: 'MyScheduleFilter.header', defaultMessage: 'Visningsalternativ' })}>
        <Badge text={`${nrOfFilters}`} appearance={nrOfFilters ? 'danger' : 'none'}>
          <IconButton appearance={open ? 'filled' : 'outlined'} onClick={handleClick} icon={open ? faEyeSolid : faEye} size={pageSize === 'small' ? 'small' : 'medium'}/>
        </Badge>
      </Tooltip>
      <Popover size="small" anchorEl={open ? anchorEl : undefined} onClose={handleClose}>
        <>
          <PopoverHeader>
            <FormattedMessage id="MyScheduleFilter.header" defaultMessage="Visningsalternativ"/>
          </PopoverHeader>
          <PopoverContent>
            <Skeleton loading={!data}>
              <Flexbox direction="column" gap="xsmall">
                <Text size="small" style={{ marginBottom: '.25rem' }}>
                  <FormattedMessage id="MyScheduleFilter.only_show_schedules" defaultMessage="Visa endast schema"/>
                </Text>
                {(_d = (_c = data === null || data === void 0 ? void 0 : data.resourceInSchedules) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(({ node: schedule }) => {
            return (<Flexbox gap="xsmall" align="center" key={`schedule_checkbox_${schedule.id}`}>
                      <Checkbox id={`checkedAsset_${schedule.id}`} checked={!!checked.find((checkedValue) => {
                    return checkedValue === schedule.id;
                })} className={classes.resourceCheckbox} onChange={(event) => {
                    if (event.target.checked) {
                        updateFilters([...checked, schedule.id], 'scheduleIds');
                    }
                    else {
                        updateFilters(checked.filter((checkedValue) => checkedValue !== schedule.id), 'scheduleIds');
                    }
                }}/>
                      <Label style={{ fontSize: '1rem' }} text={schedule.name} htmlFor={`checkedAsset_${schedule.id}`}/>
                    </Flexbox>);
        })}
                {((_e = data === null || data === void 0 ? void 0 : data.resourceInSchedules) === null || _e === void 0 ? void 0 : _e.edges.length) === 0 && (<PlaceholderText>
                    <FormattedMessage id="MyScheduleFilter.no_schedules" defaultMessage="Det finns inga scheman att filtrera på"/>
                  </PlaceholderText>)}
              </Flexbox>
            </Skeleton>
            <Divider style={{ margin: '1rem 0 ' }}/>
            <Skeleton loading={!data}>
              <Flexbox direction="column" gap="xsmall">
                <Text size="small" style={{ marginBottom: '.25rem' }}>
                  <FormattedMessage id="MyScheduleFilter.only_show_assignment_types" defaultMessage="Visa endast roller"/>
                </Text>
                {Object.keys(assignmentTypes).map((key) => {
            return (<Flexbox gap="xsmall" align="center" key={`schedule_checkbox_${key}`}>
                      <Checkbox id={`checkedAsset_${key}`} checked={!!assignmentTypeIds.find((checkedValue) => {
                    return checkedValue === key;
                })} className={classes.resourceCheckbox} onChange={(event) => {
                    if (event.target.checked) {
                        updateFilters([...assignmentTypeIds, key], 'assignmentTypeIds');
                    }
                    else {
                        updateFilters(assignmentTypeIds.filter((checkedValue) => checkedValue !== key), 'assignmentTypeIds');
                    }
                }}/>
                      <Label style={{ fontSize: '1rem' }} text={assignmentTypes[key]} htmlFor={`checkedAsset_${key}`}/>
                    </Flexbox>);
        })}
                {Object.keys(assignmentTypes).length === 0 && (<PlaceholderText>
                    <FormattedMessage id="MyScheduleFilter.no_assignment_types" defaultMessage="Det finns inga roller att filtrera på"/>
                  </PlaceholderText>)}
              </Flexbox>
            </Skeleton>
          </PopoverContent>
        </>
      </Popover>
    </>);
};
export default MyScheduleFilter;
