import React, { Suspense, useState } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { Button, Icon, ModalToSheetSkeleton, Placeholder, Text } from '@sonika-se/ui-kit/components';
import { faCalendarWeek, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import ScheduleTemplateHeader from './components/ScheduleTemplateHeader/ScheduleTemplateHeader';
import ScheduleTemplatePeriod from './components/ScheduleTemplatePeriod/ScheduleTemplatePeriod';
import ScheduleTemplateSidebar from './components/ScheduleTemplateSidebar/ScheduleTemplateSidebar';
import ScheduleTemplateEditNavbar from './components/ScheduleTemplateEditNavbar/ScheduleTemplateEditNavbar';
import { ScheduleLayout, ScheduleLayoutHeader, ScheduleLayoutMain, ScheduleLayoutSidebar, ScheduleLayoutContent, ScheduleLayoutGrid, } from '../SchedulePages/components/ScheduleLayout/ScheduleLayout';
import { ScheduleTemplateDetailsPageContextProvider, defaultScheduleTemplateDetailsPagevalues, } from './ScheduleTemplateDetailsPageContext';
import ScheduleTemplateInformationDrawer from './components/ScheduleTemplateInformationDrawer/ScheduleTemplateInformationDrawer';
import NewScheduleTemplatePeriodModal from './components/NewScheduleTemplatePeriodModal/NewScheduleTemplatePeriodModal';
export const useStyles = createUseStyles(() => {
    return {
        scheduleContent: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'auto',
        },
        hover: {
            '&> *': {
                pointerEvents: 'none',
            },
        },
        hoverElement: {
            position: 'sticky',
            height: 0,
            top: '40%',
            left: 'calc(50% - 125px)',
            width: '250px',
            zIndex: 1,
            textAlign: 'center',
            background: 'white',
        },
    };
});
export const scheduleTemplateDetailsQuery = graphql `
  query ScheduleTemplateDetailsPageQuery($scheduleTemplateId: ID!) {
    currentCompany {
      id
    }
    scheduleTemplate(id: $scheduleTemplateId) {
      id
      ...ScheduleTemplateHeader_scheduleTemplate
      ...ScheduleTemplateSidebar_scheduleTemplate
      ...ScheduleTemplateEditNavbar_scheduleTemplate
      ...ScheduleTemplateInformationDrawer
      ...ScheduleTemplateEditTimeModal_shifts
      periodDuration(unit: DAY) {
        value
      }
      periods(first: null, order: { order: ASC }) @connection(key: "ScheduleTemplate_periods") {
        edges {
          node {
            id
            ...ScheduleTemplatePeriod_period
          }
        }
      }
    }
  }
`;
export const ScheduleTemplateDetailsPage = () => {
    var _a, _b, _c, _d, _e, _f;
    const { scheduleTemplateId } = useParams();
    const [createPeriodModalOpen, setCreatePeriodModalOpen] = useState(false);
    const data = useLazyLoadQuery(scheduleTemplateDetailsQuery, {
        scheduleTemplateId,
    }, { fetchPolicy: 'network-only' });
    const [context, setContext] = useState(Object.assign(Object.assign({}, defaultScheduleTemplateDetailsPagevalues), { companyId: data.currentCompany.id }));
    const { scheduleTemplate } = data;
    const [hover, setHover] = useState(null);
    const classes = useStyles();
    const [updateScheduleTemplateShift, updateScheduleTemplateShiftLoading] = useMutation(graphql `
      mutation ScheduleTemplateDetailsPageMutation($model: [UpdateScheduleTemplateShiftRequestInput!]!) {
        updateScheduleTemplateShifts(model: $model) {
          shiftEdge {
            node {
              ...ScheduleTemplateShift_shift
              assignmentTypeId
              description
              day {
                dayInPeriod
                id
              }
              endTimeOfDay
              period {
                ...ScheduleTemplatePeriod_period
                id
                scheduleTemplateId
              }
              startTimeOfDay
            }
          }
        }
      }
    `);
    const allowDrop = (event, editable) => {
        event.preventDefault();
        if (!editable) {
            event.dataTransfer.dropEffect = 'none';
        }
        else {
            event.dataTransfer.dropEffect = 'move';
        }
    };
    return (<>
      <ScheduleTemplateDetailsPageContextProvider value={context}>
        <ScheduleLayout>
          <ScheduleLayoutHeader>
            <ScheduleTemplateHeader onToggleInformationDrawer={() => {
            setContext(Object.assign(Object.assign({}, context), { showInformationDrawer: !context.showInformationDrawer }));
        }} scheduleTemplate={scheduleTemplate} updateFilters={(filters, name) => {
            setContext(Object.assign(Object.assign({}, context), { filters: Object.assign(Object.assign({}, context.filters), { [name]: filters }) }));
        }}/>
          </ScheduleLayoutHeader>
          <ScheduleLayoutMain>
            <ScheduleLayoutSidebar>
              <ScheduleTemplateSidebar scheduleTemplate={scheduleTemplate} updateHighlightedResources={(highlighted, highlightedShifts) => {
            setContext(Object.assign(Object.assign({}, context), { highlightedSidebar: highlighted, highlightedShifts }));
        }}/>
            </ScheduleLayoutSidebar>
            <ScheduleTemplateEditNavbar scheduleTemplate={scheduleTemplate} scheduleTemplateRef={scheduleTemplate} resetHighlightedResources={() => {
            setContext(Object.assign(Object.assign({}, context), { highlightedSidebar: [], highlightedShifts: [] }));
        }}/>
            <div id="schedule-content" style={{ overflow: 'auto' }} onDragOver={(event) => event.preventDefault()} onDragEnter={(event) => {
            var _a;
            if ((_a = context === null || context === void 0 ? void 0 : context.highlightedShifts) === null || _a === void 0 ? void 0 : _a.length) {
                event.preventDefault();
                setHover(event.target);
                return allowDrop(event, true);
            }
        }} onDragLeave={(event) => {
            if (event.target === hover) {
                return setHover(null);
            }
        }} onDrop={(event) => {
            var _a, _b;
            if (!((_a = context === null || context === void 0 ? void 0 : context.highlightedShifts) === null || _a === void 0 ? void 0 : _a.length))
                return setHover(null);
            if (updateScheduleTemplateShiftLoading)
                return;
            // const type = event.dataTransfer.getData('type')
            const assignedEntityId = event.dataTransfer.getData('entityId') === 'null' ? null : event.dataTransfer.getData('entityId');
            updateScheduleTemplateShift({
                variables: {
                    model: (_b = context.highlightedShifts) === null || _b === void 0 ? void 0 : _b.map((shift) => ({
                        shiftId: shift,
                        assignedEntityId,
                    })),
                },
                onCompleted: () => {
                    setContext(Object.assign(Object.assign({}, context), { highlightedShifts: [] }));
                    context.dispatchScheduleTemplateUpdatedEvent();
                },
            });
            setHover(false);
            return setHover(null);
        }} className={[hover ? classes.hover : '', classes.scheduleContent].join(' ')}>
              {hover && (<div className={classes.hoverElement}>
                  <Icon icon={faUserPlus} style={{
                fontSize: '4rem',
                color: '#0C2AF3',
                marginBottom: '.5rem',
            }}/>
                  <Text>
                    <FormattedMessage id="ScheduleTemplateDetailsPage.add_on_highlighted" values={{ number: (_a = context === null || context === void 0 ? void 0 : context.highlightedShifts) === null || _a === void 0 ? void 0 : _a.length }} defaultMessage="Lägg till på {number} markerade pass"/>
                  </Text>
                </div>)}
              {!((_b = scheduleTemplate.periods.edges) === null || _b === void 0 ? void 0 : _b.length) && (<Placeholder icon={faCalendarWeek} height="full" title="Det finns inga perioder" text="Lägg till en period för att sätta upp din schemamall">
                  <Button onClick={() => setCreatePeriodModalOpen(true)}>Lägg till period</Button>
                </Placeholder>)}
              {!!((_c = scheduleTemplate.periods.edges) === null || _c === void 0 ? void 0 : _c.length) && (<div style={{ opacity: hover ? 0.3 : 1 }}>
                  <ScheduleLayoutContent color="white">
                    <ScheduleLayoutGrid numColumns={scheduleTemplate.periodDuration.value}>
                      {scheduleTemplate.periods.edges.map(({ node: period }) => (<ScheduleTemplatePeriod key={period.id} period={period} updateHighlightedResources={(highlightedShifts) => {
                    setContext(Object.assign(Object.assign({}, context), { highlightedShifts }));
                }}/>))}
                    </ScheduleLayoutGrid>
                  </ScheduleLayoutContent>
                </div>)}
            </div>
          </ScheduleLayoutMain>
        </ScheduleLayout>
      </ScheduleTemplateDetailsPageContextProvider>
      <ScheduleTemplateInformationDrawer onClose={() => {
            setContext(Object.assign(Object.assign({}, context), { showInformationDrawer: false }));
        }} open={context.showInformationDrawer} scheduleTemplate={scheduleTemplate} selectedPeriodId={(_f = (_e = (_d = data.scheduleTemplate.periods) === null || _d === void 0 ? void 0 : _d.edges[0]) === null || _e === void 0 ? void 0 : _e.node) === null || _f === void 0 ? void 0 : _f.id}/>
      {createPeriodModalOpen && (<Suspense fallback={<ModalToSheetSkeleton />}>
          <NewScheduleTemplatePeriodModal onCancel={() => setCreatePeriodModalOpen(false)} onSubmit={() => setCreatePeriodModalOpen(false)} scheduleTemplateId={scheduleTemplate.id}/>
        </Suspense>)}
    </>);
};
export default ScheduleTemplateDetailsPage;
