import React from 'react';
import { Chip, Flexbox, Text } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import { graphql } from 'react-relay';
import { useFragment } from 'react-relay/hooks';
import dateRangeInText from '~/utils/dateRangeInText';
export const PlannedAbsenceCard = (props) => {
    var _a;
    const { date, plannedAbsenceRef } = props;
    const data = useFragment(graphql `
      fragment PlannedAbsenceCard_plannedAbsence on PlannedAbsence {
        end
        reasonEvent {
          name
          id
          color
        }
        start
      }
    `, plannedAbsenceRef);
    const intl = useIntl();
    return (<Flexbox direction="column" gap="xsmall">
      <Text size="small">{dateRangeInText(date, new Date(data.start.toString()), new Date(data.end.toString()))}</Text>
      <Chip appearance="danger" text={data.reasonEvent.name} color={(_a = data.reasonEvent) === null || _a === void 0 ? void 0 : _a.color}/>
    </Flexbox>);
};
export default PlannedAbsenceCard;
