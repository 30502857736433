import React, { useMemo } from 'react';
import { Label } from '@sonika-se/ui-kit/components';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { createUseStyles } from 'react-jss';
import { graphql, useFragment } from 'react-relay/hooks';
import EditScheduleShiftDescription from '~/components/InlineEditFields/ScheduleShift/EditScheduleShiftDescription';
import EditScheduleShiftEvent from '~/components/InlineEditFields/ScheduleShift/EditScheduleShiftEvent';
import EditScheduleShiftTimeEnd from '~/components/InlineEditFields/ScheduleShift/EditScheduleShiftTimeEnd';
import EditScheduleShiftTimeStart from '~/components/InlineEditFields/ScheduleShift/EditScheduleShiftTimeStart';
import Tags from '~/components/InlineEditFields/Tags/Tags';
import useScheduleShiftTags from '~/hooks/tags/useScheduleShiftTags';
import ScheduleShiftEditBreaks from './ScheduleShiftEditBreaks/ScheduleShiftEditBreaks';
const useStyles = createUseStyles((theme) => ({
    shiftInformation: {
        display: 'grid',
        gridGap: '1rem',
        gridTemplateColumns: '1fr 1fr',
        [`@media (max-width: ${theme.breakPoints.low})`]: {
            gridTemplateColumns: ({ isAdmin }) => (isAdmin ? '1fr' : '1fr 1fr'),
        },
    },
}));
const ShiftInformation = (props) => {
    const data = useFragment(graphql `
      fragment ShiftInformation_ScheduleShift on ScheduleShift {
        ...EditScheduleShiftTimeStart
        ...EditScheduleShiftTimeEnd
        ...EditScheduleShiftEvent
        ...EditScheduleShiftDescription
        ...useScheduleShiftTags_scheduleShift
        ...ScheduleShiftEditBreaks_scheduleShift
        assignmentTypeId
        schedule {
          currentUserPermissions
        }
      }
    `, props.scheduleShiftRef);
    const hasPermissions = usePermissions();
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || data.schedule.currentUserPermissions.includes('EDIT_RUNNING'), [data, hasPermissions]);
    const { data: scheduleShiftTags, setTagsLoading, setScheduleShiftTags } = useScheduleShiftTags(data);
    const classes = useStyles({
        isAdmin: canEditRunning,
    });
    return (<div className={classes.shiftInformation}>
      <div>
        <Label text="Börjar"/>
        <EditScheduleShiftTimeStart scheduleShift={data}/>
      </div>
      <div>
        <Label text="Slutar"/>
        <EditScheduleShiftTimeEnd scheduleShift={data}/>
      </div>
      <div>
        <Label text="Raster"/>
        <ScheduleShiftEditBreaks scheduleShift={data}/>
      </div>
      <div>
        <Label text="Händelse"/>
        <EditScheduleShiftEvent scheduleShift={data} assignmentTypeId={data.assignmentTypeId}/>
      </div>
      <div>
        <Label text="Taggar"/>
        <Tags tags={scheduleShiftTags} setTagsLoading={setTagsLoading} setTags={setScheduleShiftTags} editable={canEditRunning}/>
      </div>
      <div>
        <Label text="Beskrivning"/>
        <EditScheduleShiftDescription scheduleShift={data} placeholder="Ingen beskrivning"/>
      </div>
    </div>);
};
export default ShiftInformation;
