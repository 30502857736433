import React, { useState } from 'react';
import { Flexbox, H6, Icon, IntervalInput, ModalToSheetContent, ModalToSheetHeader, ModalToSheet, Text, } from '@sonika-se/ui-kit/components';
import { graphql } from 'react-relay';
import { usePreloadedQuery, useRefetchableFragment } from 'react-relay/hooks';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { isSameDay, format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import User from '../User/User';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
import NoDataPlaceholder from '../NoDataPlaceholder';
export const userAbsenceModalQuery = graphql `
  query UserAbsenceModalQuery($id: ID!, $myScheduleModel: GetRunningScheduleRequestInput!) {
    user(id: $id) {
      id
      firstname
      lastname
      ...User
      schedulableEntity {
        id
        ... on SchedulableUser {
          ...UserAbsenceModal_scheduleAbsences @arguments(myScheduleModel: $myScheduleModel)
        }
      }
    }
  }
`;
export const UserAbsenceModal = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { onClose, preloadedQuery, fromDate, toDate } = props;
    const [date, setDate] = useState({ fromDate, toDate });
    const [refetchAbsences, setRefetchAbsences] = useState(new Date());
    const { user } = usePreloadedQuery(userAbsenceModalQuery, preloadedQuery);
    const [scheduleAbsences, refetch] = useRefetchableFragment(graphql `
      fragment UserAbsenceModal_scheduleAbsences on SchedulableUser
      @argumentDefinitions(myScheduleModel: { type: "GetRunningScheduleRequestInput!" })
      @refetchable(queryName: "UserAbsenceModal_scheduleAbsencesQuery") {
        scheduleAbsences(model: $myScheduleModel) {
          edges {
            node {
              id
              reasonEvent {
                id
                name
              }
              start
              end
              assignmentType {
                title
              }
              schedule {
                name
              }
            }
          }
        }
        plannedAbsences(model: $myScheduleModel) {
          edges {
            node {
              id
              reasonEvent {
                id
                name
              }
              start
              end
              assignmentType {
                title
              }
            }
          }
        }
      }
    `, user.schedulableEntity);
    useDidMountEffect(() => {
        refetch({
            myScheduleModel: {
                fromDate: date.fromDate,
                toDate: date.toDate,
            },
        }, { fetchPolicy: 'network-only' });
    }, [date, refetchAbsences]);
    return (<>
      <ModalToSheet onClose={onClose}>
        <ModalToSheetHeader onClose={onClose} closeButtonLabel="Stäng">
          Visa frånvaro
        </ModalToSheetHeader>
        <ModalToSheetContent>
          <Flexbox direction="column" gap="xlarge">
            <Flexbox align="center" gap="xsmall">
              <User parent={user} showAvatarLightBox/>
              <Flexbox direction="column">
                <H6>{`${user.firstname} ${user.lastname}`}</H6>
              </Flexbox>
            </Flexbox>
            <IntervalInput onUpdate={() => {
            setRefetchAbsences(new Date());
        }} value={date} onChange={(value) => {
            setDate(value);
        }}/>
            {((_a = scheduleAbsences === null || scheduleAbsences === void 0 ? void 0 : scheduleAbsences.scheduleAbsences) === null || _a === void 0 ? void 0 : _a.edges.length) === 0 &&
            ((_c = (_b = scheduleAbsences === null || scheduleAbsences === void 0 ? void 0 : scheduleAbsences.plannedAbsences) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.length) === 0 && (<NoDataPlaceholder icon={faCalendarAlt} appearance="gray">
                  <Text>Inga frånvaropass i vald period</Text>
                </NoDataPlaceholder>)}
            {(_e = (_d = scheduleAbsences === null || scheduleAbsences === void 0 ? void 0 : scheduleAbsences.scheduleAbsences) === null || _d === void 0 ? void 0 : _d.edges) === null || _e === void 0 ? void 0 : _e.map(({ node }) => {
            var _a, _b;
            const sameDay = isSameDay(new Date(node.start), new Date(node.end));
            return (<Flexbox direction="column" gap="small" key={node.id}>
                  <Text size="small">{`${format(new Date(node.start), 'dd/MM HH:mm', {
                    locale,
                })} - ${format(new Date(node.end), sameDay ? 'HH:mm' : 'dd/MM HH:mm', { locale })}`}</Text>
                  <Flexbox gap="small">
                    <Icon icon={faCircle} color={'#FD544F'}/>
                    <Flexbox direction="column" gap="xsmall">
                      <Text>{node.reasonEvent.name}</Text>
                      <Text size="small">{`${(_a = node === null || node === void 0 ? void 0 : node.assignmentType) === null || _a === void 0 ? void 0 : _a.title}, ${(_b = node === null || node === void 0 ? void 0 : node.schedule) === null || _b === void 0 ? void 0 : _b.name}`}</Text>
                    </Flexbox>
                  </Flexbox>
                </Flexbox>);
        })}
            {(_g = (_f = scheduleAbsences === null || scheduleAbsences === void 0 ? void 0 : scheduleAbsences.plannedAbsences) === null || _f === void 0 ? void 0 : _f.edges) === null || _g === void 0 ? void 0 : _g.map(({ node }) => {
            var _a;
            const sameDay = isSameDay(new Date(node.start), new Date(node.end));
            return (<Flexbox direction="column" gap="small" key={node.id}>
                  <Text size="small">{`${format(new Date(node.start), 'dd/MM HH:mm', {
                    locale,
                })} - ${format(new Date(node.end), sameDay ? 'HH:mm' : 'dd/MM HH:mm', { locale })}`}</Text>
                  <Flexbox gap="small">
                    <Icon icon={faCircle} color={'#FD544F'}/>
                    <Flexbox direction="column" gap="xsmall">
                      <Text>{node.reasonEvent.name}</Text>
                      <Text size="small">{`${(_a = node === null || node === void 0 ? void 0 : node.assignmentType) === null || _a === void 0 ? void 0 : _a.title}`}</Text>
                    </Flexbox>
                  </Flexbox>
                </Flexbox>);
        })}
          </Flexbox>
        </ModalToSheetContent>
      </ModalToSheet>
    </>);
};
export default UserAbsenceModal;
