import React, { Suspense } from 'react';
import { Flexbox, ModalSkeleton, Panel, PanelButton, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import AssignmentTypeChip from '~/chips/AssignmentTypeChip';
import ScheduleAssignmentTypesUpdateModal, { scheduleAssignmentTypesUpdateModalQuery, } from '~/modals/ScheduleAssignmentTypesUpdateModal';
export const ScheduleAssignmentTypesPanel = (props) => {
    const { scheduleRef } = props;
    const schedule = useFragment(graphql `
      fragment ScheduleAssignmentTypesPanel_schedule on Schedule {
        assignmentTypes {
          nodes {
            id
            assignmentType {
              ...AssignmentTypeChip_assignmentType
            }
          }
        }
        id
      }
    `, scheduleRef);
    const hasPermissions = usePermissions();
    const [scheduleAssignmentTypesUpdateModalQueryRef, loadScheduleAssignmentTypesUpdateModalQuery, disposeScheduleAssignmentTypesUpdateModalQuery,] = useQueryLoader(scheduleAssignmentTypesUpdateModalQuery);
    return (<>
      <Panel title="Roller" actions={hasPermissions(['EDIT_SCHEDULES']) && (<PanelButton onClick={() => loadScheduleAssignmentTypesUpdateModalQuery({ scheduleId: schedule.id })}>
              Ändra
            </PanelButton>)}>
        {schedule.assignmentTypes.nodes.length > 0 ? (<Flexbox gap="xsmall" wrap="wrap">
            {schedule.assignmentTypes.nodes.map((scheduleAssignmentType) => (<AssignmentTypeChip assignmentTypeRef={scheduleAssignmentType.assignmentType} key={scheduleAssignmentType.id} type="view"/>))}
          </Flexbox>) : (<Placeholder text="Inga roller."/>)}
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {scheduleAssignmentTypesUpdateModalQueryRef && (<ScheduleAssignmentTypesUpdateModal onClose={() => {
                disposeScheduleAssignmentTypesUpdateModalQuery();
            }} preloadedQuery={scheduleAssignmentTypesUpdateModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleAssignmentTypesPanel;
