import React, { useState } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { string, object, array, mixed } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Field, FieldList, Form, FormFooter, FormSection, Checkbox, NewDateTimeInput, MultiSelect, Select2, } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import { toDate } from '@sonika-se/ui-kit/utils/time';
import { date } from 'yup';
import enums from '~/enums.json';
import EventField from '~/components/EventField';
const validationSchema = (intl) => object({
    assignees: array()
        .of(object({
        assignedEntityId: string().nullable(),
        assignmentTypeId: string().required(),
        eventId: string().required(),
    }))
        .required(),
    breaks: array()
        .of(object({
        end: date().required(intl.formatMessage({
            id: 'AddScheduleShiftForm.fields.breaks.end.error.required',
            defaultMessage: 'Du måste ange en sluttid för rasten',
        })),
        start: date().required(intl.formatMessage({
            id: 'AddScheduleShiftForm.fields.breaks.start.error.required',
            defaultMessage: 'Du måste ange en starttid för rasten',
        })),
        type: mixed().oneOf(Object.keys(enums.BreakEnum)),
    }))
        .notRequired(),
    dates: array()
        .of(object({
        start: date().required(intl.formatMessage({
            id: 'AddScheduleShiftForm.fields.start.error.required',
            defaultMessage: 'Du måste ange ett startdatum för passet',
        })),
        end: date().required(intl.formatMessage({
            id: 'AddScheduleShiftForm.fields.start.error.required',
            defaultMessage: 'Du måste ange ett slutdatum för passet',
        })),
    }))
        .required(),
    scheduleId: string().required(),
});
export const AddScheduleShiftForm = (props) => {
    const { disabled, onCancel, onSubmit, query } = props;
    const intl = useIntl();
    const scheduleShiftBreakType = enums.BreakEnum;
    const defaultValues = {
        assignees: [
            {
                assignedEntityId: '',
                assignmentTypeId: '',
                eventId: '',
            },
        ],
        breaks: [],
        dates: [{ start: null, end: null }],
        scheduleId: '',
    };
    const { control, formState: { errors }, watch, handleSubmit, setValue, } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema(intl)),
    });
    const data = useFragment(graphql `
      fragment AddScheduleShiftForm_query on Query {
        schedules(filterModel: { withCurrentUserPermissions: [EDIT_RUNNING] }) {
          edges {
            node {
              id
              assignmentTypes {
                edges {
                  node {
                    id
                    assignmentType {
                      id
                      title
                    }
                    resources {
                      edges {
                        node {
                          id
                          schedulableEntity {
                            ... on ISchedulableEntity {
                              id
                              displayName
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              name
            }
          }
        }
      }
    `, query);
    const [showBreaks, setShowBreaks] = useState(false);
    const [selectedScheduleId, setSelectedScheduleId] = useState();
    const schedules = data.schedules.edges.reduce((prev, { node: schedule }) => {
        const assignmentTypes = schedule.assignmentTypes.edges.reduce((prev, { node: item }) => {
            return Object.assign(Object.assign({}, prev), { [item.assignmentType.id]: item === null || item === void 0 ? void 0 : item.resources.edges.map(({ node }) => ({
                    value: node.schedulableEntity.id,
                    label: node.schedulableEntity.displayName,
                })) });
        }, {});
        return Object.assign(Object.assign({}, prev), { [schedule.id]: {
                assignmentTypes,
                assignmentTypeOptions: schedule.assignmentTypes.edges.map(({ node: item }) => ({
                    value: item.assignmentType.id,
                    label: item.assignmentType.title,
                })),
            } });
    }, {});
    return (<Form onSubmit={handleSubmit((form) => {
            onSubmit(form);
        })}>
      <FormSection columns="1fr 1fr">
        <Field label={intl.formatMessage({
            defaultMessage: 'Schema',
            id: 'general.misc.schedule_shift.fields.schedule_id.label',
        })}>
          <Controller control={control} name="scheduleId" render={({ field }) => {
            var _a, _b;
            return (<MultiSelect disabled={disabled} error={(_b = (_a = errors.scheduleId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} defaultValue={selectedScheduleId} placeholder={intl.formatMessage({
                    defaultMessage: 'Välj schema',
                    id: 'general.misc.schedule_shift.fields.schedule_id.placeholder',
                })} onChange={(value) => {
                    const scheduleId = value ? value[0] : undefined;
                    field.onChange(scheduleId);
                    setSelectedScheduleId(scheduleId);
                    setValue('assignees', [
                        {
                            assignedEntityId: '',
                            assignmentTypeId: '',
                            eventId: '',
                        },
                    ]);
                }} options={data.schedules.edges.map(({ node: schedule }) => ({
                    label: schedule.name,
                    value: schedule.id,
                }))}/>);
        }}/>
        </Field>
      </FormSection>
      <FieldList compact control={control} appendText={intl.formatMessage({
            defaultMessage: 'Lägg till datum',
            id: 'AddScheduleShiftForm.dates.append_text',
        })} disabled={disabled} name="dates" label="" render={(item, index) => (<FormSection columns="1fr 1fr" key={item.id}>
            <Field label={index === 0
                ? intl.formatMessage({
                    defaultMessage: 'Starttid',
                    id: 'general.misc.schedule_shift.fields.start.label',
                })
                : undefined}>
              <Controller control={control} name={`dates.${index}.start`} render={({ field }) => {
                var _a, _b;
                return (<NewDateTimeInput disabled={disabled} error={errors.dates && ((_b = (_a = errors.dates[index]) === null || _a === void 0 ? void 0 : _a.start.message) === null || _b === void 0 ? void 0 : _b.toString())} header="Välj starttid" onChange={(date) => {
                        field.onChange(date);
                    }} placeholder="Välj datum och tid" value={toDate(field.value)}/>);
            }}/>
            </Field>
            <Field label={index === 0
                ? intl.formatMessage({
                    defaultMessage: 'Sluttid',
                    id: 'general.misc.schedule_shift.fields.end.label',
                })
                : undefined}>
              <Controller control={control} name={`dates.${index}.end`} render={({ field }) => {
                var _a, _b;
                return (<NewDateTimeInput disabled={disabled} error={errors.dates && ((_b = (_a = errors.dates[index]) === null || _a === void 0 ? void 0 : _a.end.message) === null || _b === void 0 ? void 0 : _b.toString())} header="Välj sluttid" onChange={(date) => field.onChange(date)} placeholder="Välj datum och tid" value={toDate(field.value)}/>);
            }}/>
            </Field>
          </FormSection>)}/>
      <FormSection>
        <Field direction="row-reverse" label={intl.formatMessage({
            defaultMessage: 'Passet innehåller raster',
            id: 'general.misc.schedule_shift.fields.contains_breaks.placeholder',
        })}>
          <Checkbox disabled={disabled} onChange={(event) => {
            setShowBreaks(event.target.checked);
        }}/>
        </Field>
      </FormSection>
      {showBreaks && (<FormSection>
          <FieldList appendText={intl.formatMessage({
                id: 'general.misc.breaks.actions.create',
                defaultMessage: 'Lägg till rast',
            })} compact control={control} disabled={disabled} name="breaks" label={intl.formatMessage({
                id: 'general.misc.breaks.name.plural',
                defaultMessage: 'Raster',
            })} render={(field, index) => (<FormSection columns="2fr 2fr 1fr" key={field.id}>
                <Field>
                  <Controller control={control} name={`breaks.${index}.start`} render={({ field }) => {
                    var _a, _b;
                    return (<NewDateTimeInput disabled={disabled} error={errors.dates && ((_b = (_a = errors.breaks[index]) === null || _a === void 0 ? void 0 : _a.start.message) === null || _b === void 0 ? void 0 : _b.toString())} header="Välj starttid" onChange={(date) => field.onChange(date)} placeholder="Välj datum och tid" value={toDate(field.value)}/>);
                }}/>
                </Field>
                <Field>
                  <Controller control={control} name={`breaks.${index}.end`} render={({ field }) => {
                    var _a, _b;
                    return (<NewDateTimeInput disabled={disabled} error={errors.dates && ((_b = (_a = errors.breaks[index]) === null || _a === void 0 ? void 0 : _a.end.message) === null || _b === void 0 ? void 0 : _b.toString())} header="Välj sluttid" onChange={(date) => field.onChange(date)} placeholder="Välj datum och tid" value={toDate(field.value)}/>);
                }}/>
                </Field>
                <Field>
                  <Controller control={control} name={`breaks.${index}.type`} render={({ field }) => {
                    var _a, _b, _c;
                    return (<MultiSelect error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_b = (_a = errors.breaks[index]) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.toString())} disabled={disabled} options={(_c = Object.keys(scheduleShiftBreakType)) === null || _c === void 0 ? void 0 : _c.map((key) => {
                            var _a;
                            return ({
                                label: intl.formatMessage({
                                    id: `BreakEnum_${key}`,
                                    defaultMessage: (_a = scheduleShiftBreakType[key]) === null || _a === void 0 ? void 0 : _a.description,
                                }),
                                value: key,
                            });
                        })} placeholder={intl.formatMessage({
                            id: 'general.misc.breaks.fields.type.placeholder',
                            defaultMessage: 'Välj typ',
                        })} onChange={(value) => (value ? field.onChange(value[0]) : undefined)} defaultValue={field.value}/>);
                }}/>
                </Field>
              </FormSection>)}/>
        </FormSection>)}
      <FormSection>
        <FieldList appendText={intl.formatMessage({
            defaultMessage: 'Lägg till anställd eller enhet',
            id: 'AddScheduleShiftForm.assignees.append_text',
        })} compact control={control} disabled={disabled} label={intl.formatMessage({
            defaultMessage: 'Välj anställda och enheter',
            id: 'AddScheduleShiftForm.assignees.label',
        })} name="assignees" render={(field, index) => {
            const values = watch('assignees')[index];
            const { assignmentTypeOptions = [], assignmentTypes = {} } = schedules[selectedScheduleId] || {};
            return (<FormSection columns="1fr 1fr 1fr" key={field.id}>
                <Field columnSpan={1}>
                  <Controller control={control} name={`assignees.${index}.assignmentTypeId`} render={({ field }) => {
                    var _a, _b, _c;
                    return (<MultiSelect error={(errors === null || errors === void 0 ? void 0 : errors.assignees) && ((_c = (_b = (_a = errors.assignees[index]) === null || _a === void 0 ? void 0 : _a.assignmentTypeId) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.toString())} disabled={disabled || !selectedScheduleId} onBlur={field.onBlur} onChange={(value) => field.onChange(value ? value[0] : undefined)} options={assignmentTypeOptions} placeholder={intl.formatMessage({
                            id: 'general.misc.schedule_shift.fields.assignment_type_id.label',
                            defaultMessage: 'Välj roll',
                        })} defaultValue={field.value}/>);
                }}/>
                </Field>
                <Field columnSpan={1}>
                  <Controller control={control} name={`assignees.${index}.eventId`} render={({ field }) => {
                    var _a, _b, _c;
                    return (<EventField error={(errors === null || errors === void 0 ? void 0 : errors.assignees) && ((_c = (_b = (_a = errors.assignees[index]) === null || _a === void 0 ? void 0 : _a.eventId) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.toString())} disabled={disabled || !values.assignmentTypeId} onBlur={field.onBlur} onChange={(value) => field.onChange(value ? value[0] : undefined)} placeholder={intl.formatMessage({
                            defaultMessage: 'Välj händelse',
                            id: 'general.misc.schedule_shift.fields.event_id.placeholder',
                        })} where={{ isWork: { eq: true } }} assignmentTypeId={values.assignmentTypeId} defaultValue={field.value}/>);
                }}/>
                </Field>
                <Field columnSpan={1}>
                  <Controller control={control} name={`assignees.${index}.assignedEntityId`} render={({ field }) => {
                    var _a, _b, _c;
                    return (<Select2 error={(errors === null || errors === void 0 ? void 0 : errors.assignees) && ((_c = (_b = (_a = errors.assignees[index]) === null || _a === void 0 ? void 0 : _a.assignedEntityId) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.toString())} disabled={disabled || !values.assignmentTypeId} onBlur={field.onBlur} onChange={({ single }) => field.onChange(single)} options={[
                            {
                                label: intl.formatMessage({
                                    defaultMessage: 'Vakant',
                                    id: 'general.misc.schedule_shift.vacant.singular',
                                }),
                                value: null,
                            },
                            ...(assignmentTypes[values.assignmentTypeId] || []),
                        ]} placeholder={intl.formatMessage({
                            id: 'general.misc.schedule_shift.fields.assigned_entity_id.label',
                            defaultMessage: 'Välj anställd/Enhet',
                        })} defaultValue={field.value}/>);
                }}/>
                </Field>
              </FormSection>);
        }}/>
      </FormSection>
      <FormFooter disabled={disabled} onCancel={() => {
            onCancel();
        }} submitText={intl.formatMessage({
            id: 'general.actions.create',
            defaultMessage: 'Lägg till',
        })}/>
    </Form>);
};
