import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { useFragment, graphql } from 'react-relay/hooks';
import { Text, Flexbox, Divider } from '@sonika-se/ui-kit/components';
import { RunningSchedulesInformationTableRow } from './RunningSchedulesInformationTableRow';
const useStyles = createUseStyles((theme) => ({
    tableHeader: {
        marginBottom: '.5rem',
        color: theme.colors.foreground.tertiary,
    },
    bold: {
        fontWeight: 'bold',
    },
    tableCell: {
        width: '5rem',
        textAlign: 'center',
        '&:first-child': {
            textAlign: 'left',
        },
        '&:last-child': {
            textAlign: 'right',
            paddingRight: '.5rem',
        },
    },
    tableRow: {
        '&:not(:last-child)': {
            borderBottom: `${theme.helpers.pxToRem(1)} solid ${theme.colors.divider}`,
        },
    },
    tableRowContainer: {
        '&:empty': {
            padding: theme.helpers.pxToRem(20),
            background: theme.colors.background.tertiary,
            textAlign: 'center',
        },
        '&:empty:before': {
            content: '"Inget data matchade sökningen"',
        },
    },
}));
export const RunningSchedulesInformationTable = (props) => {
    const { assignmentTypeId, selectedAbsenceFilters, date, update } = props;
    const scheduleTimes = useFragment(graphql `
      fragment RunningSchedulesInformationTable_scheduleTimes on ScheduleTimesPayload {
        timesByEntityAndAssignmentType {
          assignmentType {
            id
            title
          }
          schedulableEntity {
            ... on ISchedulableEntity {
              id
            }
          }
          ...RunningSchedulesInformationTableRow_scheduleTime
        }
      }
    `, props.scheduleTimes);
    const classes = useStyles();
    const filteredScheduleTimes = scheduleTimes.timesByEntityAndAssignmentType.filter((entity) => entity.assignmentType.id === assignmentTypeId);
    return (<div style={{ padding: '0 1.5rem 1rem' }}>
      <Flexbox direction="column">
        <Flexbox justify="space">
          <Text size="small" className={[classes.tableCell, classes.tableHeader].join(' ')}>
            <FormattedMessage defaultMessage="Name" id="ScheduleInformation.name"/>
          </Text>
          <Flexbox>
            <Text className={[classes.tableCell, classes.tableHeader].join(' ')} size="small">
              <FormattedMessage defaultMessage="Totalt" id="ScheduleInformation.total"/>
            </Text>
          </Flexbox>
        </Flexbox>
        <Divider />
        <div className={classes.tableRowContainer}>
          {filteredScheduleTimes.map((entity, index) => {
            var _a;
            return (<RunningSchedulesInformationTableRow date={date} scheduleTime={entity} key={`${(_a = entity.schedulableEntity) === null || _a === void 0 ? void 0 : _a.id}-${index}`} className={classes.tableRow} selectedAbsenceFilters={selectedAbsenceFilters} update={update}/>);
        })}
        </div>
      </Flexbox>
    </div>);
};
