import React, { useEffect } from 'react';
import { graphql } from 'react-relay';
import { usePreloadedQuery, useRefetchableFragment } from 'react-relay/hooks';
import { Divider, Placeholder } from '@sonika-se/ui-kit/components';
import { faCalendarEdit } from '@fortawesome/pro-regular-svg-icons';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import { ScheduleTemplateInformationTable } from './ScheduleTemplateInformationResourceTable';
export const scheduleTemplateInformationResourceQuery = graphql `
  query ScheduleTemplateInformationResourceQuery(
    $startDate: DateTime!
    $endDate: DateTime!
    $startPeriodId: ID!
    $id: ID!
    $typeFilter: [SchedulableEntityType!]
  ) {
    ...ScheduleTemplateInformationResource_scheduleTimes
      @arguments(
        startDate: $startDate
        endDate: $endDate
        typeFilter: $typeFilter
        id: $id
        startPeriodId: $startPeriodId
      )
  }
`;
const useStyles = createUseStyles((theme) => ({
    root: {
        overflowY: 'auto',
        background: '#F8F9FA',
        height: '100%',
    },
    noDataContainer: {
        height: '100%',
        '& > *': {
            margin: 'auto',
            textAlign: 'center',
            '& > svg': {
                color: theme.colors.primary,
                fontSize: '4rem',
                marginBottom: '2rem',
            },
        },
    },
}));
export const ScheduleTemplateInformationResource = (props) => {
    var _a, _b;
    const { date, type, preloadedQuery, selectedPeriodId, id, open, update } = props;
    const classes = useStyles();
    const intl = useIntl();
    const scheduleTimes = usePreloadedQuery(scheduleTemplateInformationResourceQuery, preloadedQuery);
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment ScheduleTemplateInformationResource_scheduleTimes on Query
      @argumentDefinitions(
        startDate: { type: "DateTime!" }
        endDate: { type: "DateTime!" }
        id: { type: "ID!" }
        startPeriodId: { type: "ID!" }
        typeFilter: { type: "[SchedulableEntityType!]" }
      )
      @refetchable(queryName: "ScheduleTemplateInformationResource_scheduleTimesQuery") {
        scheduleTemplateTimes(
          model: { startDate: $startDate, endDate: $endDate, dryRun: false, id: $id, startPeriodId: $startPeriodId }
          typeFilter: { types: $typeFilter }
        ) {
          timesByEntityAndAssignmentType {
            assignedDurationWithoutUnpaidBreaks(unit: HOUR) {
              unit
              value
            }
            assignmentType {
              id
              title
            }
            schedulableEntity {
              ... on ISchedulableEntity {
                id
                color
                displayName
              }
            }
          }
        }
      }
    `, scheduleTimes);
    useEffect(() => {
        if (!selectedPeriodId || !open)
            return;
        refetch({
            selectedPeriodId,
            endDate: date.endDate,
            startDate: date.startDate,
            typeFilter: [type],
            id,
        }, { fetchPolicy: 'network-only' });
    }, [date, selectedPeriodId, update, type]);
    const mappedAssigmentTypes = {};
    (_b = (_a = data === null || data === void 0 ? void 0 : data.scheduleTemplateTimes) === null || _a === void 0 ? void 0 : _a.timesByEntityAndAssignmentType) === null || _b === void 0 ? void 0 : _b.forEach((scheduleTime) => {
        var _a;
        if (!(scheduleTime === null || scheduleTime === void 0 ? void 0 : scheduleTime.assignmentType))
            return;
        mappedAssigmentTypes[scheduleTime.assignmentType.id] = Object.assign(Object.assign(Object.assign({}, (mappedAssigmentTypes[scheduleTime.assignmentType.id] || {})), scheduleTime.assignmentType), { entities: [
                ...(((_a = mappedAssigmentTypes[scheduleTime.assignmentType.id]) === null || _a === void 0 ? void 0 : _a.entities) || []),
                Object.assign(Object.assign({}, scheduleTime.schedulableEntity), { assignedDurationWithoutUnpaidBreaks: scheduleTime.assignedDurationWithoutUnpaidBreaks }),
            ] });
    });
    return (<div className={classes.root}>
      {Object.keys(mappedAssigmentTypes).map((key) => {
            const assignmentType = mappedAssigmentTypes[key];
            return (<div key={`schedule_time_information_${assignmentType.id}_${date.startDate}_${date.endDate}`}>
            <ScheduleTemplateInformationTable assignmentType={assignmentType} date={date} selectedPeriodId={selectedPeriodId} templateId={id} type={type}/>
            <Divider />
          </div>);
        })}
      {Object.keys(mappedAssigmentTypes).length === 0 && (<Placeholder height="full" icon={faCalendarEdit} text={intl.formatMessage({
                defaultMessage: 'Det finns inga pass på det angivna datumet',
                id: 'ScheduleTemplateDetailsPage.ScheduleTemplateInformationResource.no_data_on_date',
            })}/>)}
    </div>);
};
