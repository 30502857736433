/**
 * @generated SignedSource<<3fcc7437eddba77f08bf054a34028bc5>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "MINUTE"
        }
    ], v1 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "fromDate"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "id"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "toDate"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [],
                "operation": require('./ChangeShiftTemplateTimeModal_ScheduleTemplateQuery.graphql')
            }
        },
        "name": "ChangeShiftTemplateTimeModal_ScheduleTemplate",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "id"
                    }
                ],
                "concreteType": "ScheduleTemplateShift",
                "kind": "LinkedField",
                "name": "scheduleTemplateShift",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTimeOfDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endTimeOfDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleTemplateShiftBreak",
                        "kind": "LinkedField",
                        "name": "breaks",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": (v0 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "offsetStart",
                                "plural": false,
                                "selections": (v1 /*: any*/),
                                "storageKey": "offsetStart(unit:\"MINUTE\")"
                            },
                            {
                                "alias": null,
                                "args": (v0 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "offsetEnd",
                                "plural": false,
                                "selections": (v1 /*: any*/),
                                "storageKey": "offsetEnd(unit:\"MINUTE\")"
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "first",
                                "value": 100
                            },
                            {
                                "kind": "Variable",
                                "name": "fromDate",
                                "variableName": "fromDate"
                            },
                            {
                                "kind": "Literal",
                                "name": "order",
                                "value": [
                                    {
                                        "start": "ASC"
                                    }
                                ]
                            },
                            {
                                "kind": "Variable",
                                "name": "toDate",
                                "variableName": "toDate"
                            }
                        ],
                        "concreteType": "ScheduleShiftConnection",
                        "kind": "LinkedField",
                        "name": "generatedScheduleShifts",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalCount",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleShift",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                    (v2 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "start",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "end",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "assignedEntity",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    (v2 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "displayName",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "type": "ISchedulableEntity",
                                                "abstractKey": "__isISchedulableEntity"
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "8c8e01ab818a80ad35e5fc76484de1c8";
export default node;
