import React, { useCallback } from 'react';
import { graphql, usePreloadedQuery, useQueryLoader, useMutation } from 'react-relay/hooks';
import { Button, Flexbox } from '@sonika-se/ui-kit/components';
import SchedulePermissionList from '../../../../components/SchedulePermissionList';
import UserPickerModal, { userPickerModalQuery } from '../../../../components/UserPickerModal/UserPickerModal';
import RolePickerModal, { rolePickerModalQuery } from '../../../../components/RolePickerModal/RolePickerModal';
export const editScheduleModalPermissionsTabQuery = graphql `
  query EditScheduleModalPermissionsTabQuery($id: ID!) {
    schedule(id: $id) {
      id
      ...SchedulePermissionList_schedule
    }
  }
`;
export const EditScheduleModalPermissionsTab = (props) => {
    const { preloadedQuery } = props;
    const { schedule } = usePreloadedQuery(editScheduleModalPermissionsTabQuery, preloadedQuery);
    const [userPickerModalQueryRef, loadUserPickerModalQuery, disposeUserPickerModalQuery] = useQueryLoader(userPickerModalQuery);
    const [rolePickerModalQueryRef, loadRolePickerModalQuery, disposeRolePickerModalQuery] = useQueryLoader(rolePickerModalQuery);
    const [createSchedulePermissionMutation, createSchedulePermissionMutationLoading] = useMutation(graphql `
        mutation EditScheduleModalPermissionsTab_createSchedulePermissionMutation(
          $model: CreateSchedulePermissionInput!
        ) {
          createSchedulePermission(model: $model) {
            schedulePermissionEdge {
              node {
                ...SchedulePermissionListItem_schedulePermission
              }
            }
          }
        }
      `);
    const createSchedulePermission = useCallback(({ roleId, userId }) => {
        createSchedulePermissionMutation({
            configs: [
                {
                    connectionInfo: [
                        {
                            key: 'SchedulePermissionList_schedule_permissions',
                            rangeBehavior: 'prepend',
                        },
                    ],
                    parentID: schedule.id,
                    edgeName: 'schedulePermissionEdge',
                    type: 'RANGE_ADD',
                },
            ],
            variables: {
                model: {
                    permissions: [],
                    roleId,
                    userId,
                    scheduleId: schedule.id,
                },
            },
        });
    }, [createSchedulePermissionMutation, schedule]);
    return (<>
      <Flexbox direction="column" gap="large">
        <Flexbox gap="medium">
          <Button appearance="outlined" onClick={() => {
            loadUserPickerModalQuery({});
        }} size="small">
            Lägg till användare
          </Button>
          <Button appearance="outlined" onClick={() => {
            loadRolePickerModalQuery({});
        }} size="small">
            Lägg till grupp
          </Button>
        </Flexbox>
        <SchedulePermissionList scheduleRef={schedule}/>
      </Flexbox>
      {userPickerModalQueryRef && (<UserPickerModal onClose={() => {
                disposeUserPickerModalQuery();
            }} onSelect={(userId) => {
                disposeUserPickerModalQuery();
                createSchedulePermission({ userId });
            }} preloadedQuery={userPickerModalQueryRef}/>)}
      {rolePickerModalQueryRef && (<RolePickerModal onClose={() => {
                disposeRolePickerModalQuery();
            }} onSelect={(roleId) => {
                disposeRolePickerModalQuery();
                createSchedulePermission({ roleId });
            }} preloadedQuery={rolePickerModalQueryRef}/>)}
    </>);
};
export default EditScheduleModalPermissionsTab;
