import React, { useContext } from 'react';
import { Flexbox, IntervalInput } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import { Chip } from '@sonika-se/ui-kit/components';
import { format } from 'date-fns';
import RunningSchedulesPageContext from '../../RunningSchedulesPageContext';
export const RunningSchedulesHeader = (props) => {
    var _a, _b;
    const { onDateChange, queryRef, setRefetchGrid } = props;
    const { toDate, fromDate } = useContext(RunningSchedulesPageContext);
    const data = useFragment(graphql `
      fragment RunningSchedulesHeader_query on Query {
        currentCompany {
          scheduleSettings {
            id
            viewAccessUntil
          }
        }
      }
    `, queryRef);
    return (<Flexbox justify="space">
      <Flexbox align="center" gap="small">
        <IntervalInput value={{ fromDate: new Date(fromDate), toDate: new Date(toDate) }} onChange={(date) => {
            if (!date.fromDate && !date.toDate)
                return;
            onDateChange(date);
        }} onUpdate={() => {
            setRefetchGrid();
        }}/>
        {((_b = (_a = data.currentCompany) === null || _a === void 0 ? void 0 : _a.scheduleSettings) === null || _b === void 0 ? void 0 : _b.viewAccessUntil) && (<Chip appearance="secondary" size="xsmall" text={`Pass är synliga t.o.m. ${format(new Date(data.currentCompany.scheduleSettings.viewAccessUntil), 'd LLL yyyy').toLocaleLowerCase()}`}/>)}
      </Flexbox>
      <Flexbox gap="xsmall">{props.headerElements}</Flexbox>
    </Flexbox>);
};
