/**
 * @generated SignedSource<<4108d484a396c6015d9e05f5e2c63219>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = [
        (v3 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleAssignmentType_scheduleAssignmentTypeQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ScheduleAssignmentType_scheduleAssignmentType"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleAssignmentType_scheduleAssignmentTypeQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "assignmentType",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                    "kind": "LinkedField",
                                    "name": "resources",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeResource",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "schedulableEntity",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v3 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "color",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "displayName",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "Asset",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "asset",
                                                                                    "plural": false,
                                                                                    "selections": (v4 /*: any*/),
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "type": "SchedulableAsset",
                                                                            "abstractKey": null
                                                                        }
                                                                    ],
                                                                    "type": "ISchedulableEntity",
                                                                    "abstractKey": "__isISchedulableEntity"
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": (v4 /*: any*/),
                                                                    "type": "Node",
                                                                    "abstractKey": "__isNode"
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "schedule",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "currentUserPermissions",
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scheduleId",
                                    "storageKey": null
                                }
                            ],
                            "type": "ScheduleAssignmentType",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "89651ab4970fca3b92336b4d0a88c721",
            "id": null,
            "metadata": {},
            "name": "ScheduleAssignmentType_scheduleAssignmentTypeQuery",
            "operationKind": "query",
            "text": "query ScheduleAssignmentType_scheduleAssignmentTypeQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ScheduleAssignmentType_scheduleAssignmentType\n    id\n  }\n}\n\nfragment ScheduleAssignmentType_scheduleAssignmentType on ScheduleAssignmentType {\n  id\n  assignmentType {\n    id\n    title\n  }\n  resources {\n    edges {\n      node {\n        id\n        schedulableEntity {\n          __typename\n          ... on ISchedulableEntity {\n            __isISchedulableEntity: __typename\n            id\n            color\n            displayName\n            ... on SchedulableAsset {\n              asset {\n                id\n              }\n            }\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n  }\n  schedule {\n    currentUserPermissions\n    id\n  }\n  scheduleId\n}\n"
        }
    };
})();
node.hash = "742f724b676eebe95940cbbc8cb81222";
export default node;
