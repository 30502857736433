import React, { Suspense } from 'react';
import { graphql } from 'react-relay';
import { usePreloadedQuery } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { Skeleton } from '@sonika-se/ui-kit/components';
import ScheduleTimesPayload from '~/pages/SchedulePages/MySchedule/components/MyScheduleInformationDrawer/ScheduleTimesPayload';
export const scheduleTemplateInformationPeriodScheduleTimesQuery = graphql `
  query ScheduleTemplateInformationPeriodScheduleTimesQuery(
    $entitiesFilter: ScheduleTemplateShiftFilterRequestInput
    $scheduleTemplateId: ID!
  ) {
    scheduleTemplateTimesPerPeriod(entitiesFilter: $entitiesFilter, scheduleTemplateId: $scheduleTemplateId) {
      periods {
        period {
          id
        }
        ...ScheduleTimesPayload_iHaveScheduleTimes
      }
    }
  }
`;
const useStyles = createUseStyles((theme) => ({
    root: {
        overflowY: 'auto',
        height: '100%',
    },
}));
export const ScheduleTemplateInformationPeriodScheduleTimes = (props) => {
    const { periodId, preloadedQuery } = props;
    const classes = useStyles();
    const data = usePreloadedQuery(scheduleTemplateInformationPeriodScheduleTimesQuery, preloadedQuery);
    return (<div className={classes.root}>
      <Suspense fallback={<Skeleton type="table"/>}>
        {data.scheduleTemplateTimesPerPeriod.periods.map((group) => group.period.id === periodId && <ScheduleTimesPayload iHaveScheduleTimesRef={group}/>)}
      </Suspense>
    </div>);
};
export default ScheduleTemplateInformationPeriodScheduleTimes;
