/**
 * @generated SignedSource<<74c3f58a85b7daaf51c7808d9fbade5d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filter"
        },
        {
            "defaultValue": 50,
            "kind": "LocalArgument",
            "name": "first"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "runningScheduleModel"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ], v1 = {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
    }, v2 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    }, v3 = {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
    }, v4 = [
        (v1 /*: any*/),
        {
            "kind": "Variable",
            "name": "filterModel",
            "variableName": "filter"
        },
        (v2 /*: any*/),
        {
            "kind": "Literal",
            "name": "order",
            "value": {
                "sortOrder": "ASC"
            }
        },
        (v3 /*: any*/)
    ], v5 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
        }
    ], v6 = {
        "kind": "Variable",
        "name": "model",
        "variableName": "runningScheduleModel"
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleGrid_queryQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/),
                        {
                            "kind": "Variable",
                            "name": "filter",
                            "variableName": "filter"
                        },
                        (v2 /*: any*/),
                        {
                            "kind": "Variable",
                            "name": "runningScheduleModel",
                            "variableName": "runningScheduleModel"
                        },
                        (v3 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "ScheduleGrid_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleGrid_queryQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "ScheduleConnection",
                    "kind": "LinkedField",
                    "name": "schedules",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "archived",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "inactivatedFrom",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "assignmentTypes",
                                            "plural": false,
                                            "selections": (v5 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": [
                                                (v6 /*: any*/),
                                                {
                                                    "kind": "Literal",
                                                    "name": "where",
                                                    "value": {
                                                        "assignedEntityId": {
                                                            "neq": null
                                                        }
                                                    }
                                                }
                                            ],
                                            "concreteType": "ScheduleShiftConnection",
                                            "kind": "LinkedField",
                                            "name": "runningSchedule",
                                            "plural": false,
                                            "selections": (v5 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": [
                                                (v6 /*: any*/)
                                            ],
                                            "concreteType": "ScheduleShiftChangeRequestConnection",
                                            "kind": "LinkedField",
                                            "name": "shiftChangeRequests",
                                            "plural": false,
                                            "selections": (v5 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": "vacantShifts",
                                            "args": [
                                                (v6 /*: any*/),
                                                {
                                                    "kind": "Literal",
                                                    "name": "where",
                                                    "value": {
                                                        "assignedEntityId": {
                                                            "eq": null
                                                        }
                                                    }
                                                }
                                            ],
                                            "concreteType": "ScheduleShiftConnection",
                                            "kind": "LinkedField",
                                            "name": "runningSchedule",
                                            "plural": false,
                                            "selections": (v5 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": "schedulableAssets",
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "where",
                                                    "value": {
                                                        "type": {
                                                            "eq": "ASSET"
                                                        }
                                                    }
                                                }
                                            ],
                                            "concreteType": "SchedulableEntityConnection",
                                            "kind": "LinkedField",
                                            "name": "schedulableEntities",
                                            "plural": false,
                                            "selections": (v5 /*: any*/),
                                            "storageKey": "schedulableEntities(where:{\"type\":{\"eq\":\"ASSET\"}})"
                                        },
                                        {
                                            "alias": "schedulableUser",
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "where",
                                                    "value": {
                                                        "type": {
                                                            "eq": "USER"
                                                        }
                                                    }
                                                }
                                            ],
                                            "concreteType": "SchedulableEntityConnection",
                                            "kind": "LinkedField",
                                            "name": "schedulableEntities",
                                            "plural": false,
                                            "selections": (v5 /*: any*/),
                                            "storageKey": "schedulableEntities(where:{\"type\":{\"eq\":\"USER\"}})"
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "ScheduleGrid_query_schedules",
                    "kind": "LinkedHandle",
                    "name": "schedules"
                }
            ]
        },
        "params": {
            "cacheID": "cc01c2b27f8fdf7248ea6dc30a6b0c2a",
            "id": null,
            "metadata": {},
            "name": "ScheduleGrid_queryQuery",
            "operationKind": "query",
            "text": "query ScheduleGrid_queryQuery(\n  $after: String\n  $filter: ScheduleFilterRequestInput\n  $first: Int = 50\n  $runningScheduleModel: GetRunningScheduleRequestInput!\n  $where: ScheduleFilterInput\n) {\n  ...ScheduleGrid_query_31VQIF\n}\n\nfragment ScheduleCard_schedule_1gb8Rr on Schedule {\n  archived\n  inactivatedFrom\n  assignmentTypes {\n    totalCount\n  }\n  id\n  name\n  runningSchedule(model: $runningScheduleModel, where: {assignedEntityId: {neq: null}}) {\n    totalCount\n  }\n  shiftChangeRequests(model: $runningScheduleModel) {\n    totalCount\n  }\n  vacantShifts: runningSchedule(model: $runningScheduleModel, where: {assignedEntityId: {eq: null}}) {\n    totalCount\n  }\n  schedulableAssets: schedulableEntities(where: {type: {eq: ASSET}}) {\n    totalCount\n  }\n  schedulableUser: schedulableEntities(where: {type: {eq: USER}}) {\n    totalCount\n  }\n}\n\nfragment ScheduleGrid_query_31VQIF on Query {\n  schedules(after: $after, first: $first, order: {sortOrder: ASC}, where: $where, filterModel: $filter) {\n    edges {\n      node {\n        ...ScheduleCard_schedule_1gb8Rr\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "efee24d308f8b7e816b160751996cf95";
export default node;
