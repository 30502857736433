/**
 * @generated SignedSource<<33cbe03ff64cb7b968018cad273546a3>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ScheduleShift_shift",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignedEntity",
                "plural": false,
                "selections": [
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "SchedulableAsset_entity"
                            }
                        ],
                        "type": "SchedulableAsset",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "SchedulableUser_entity"
                            }
                        ],
                        "type": "SchedulableUser",
                        "abstractKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assignedEntityId",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentType",
                "kind": "LinkedField",
                "name": "assignmentType",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "end",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "start",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isVacant",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
            }
        ],
        "type": "ScheduleShift",
        "abstractKey": null
    };
})();
node.hash = "df74323a82be813591677fd333b9813f";
export default node;
