import React, { useMemo } from 'react';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay/hooks';
import Notes from '~/components/Notes/Notes';
import useScheduleShiftNotes from '~/hooks/notes/useScheduleShiftNotes';
const ScheduleShiftNotesTab = (props) => {
    var _a;
    const { scheduleShiftRef } = props;
    const intl = useIntl();
    const data = useFragment(graphql `
      fragment ScheduleShiftNotesTab_scheduleShift on ScheduleShift {
        ...useScheduleShiftNotes_scheduleShift
        schedule {
          currentUserPermissions
        }
      }
    `, scheduleShiftRef);
    const { addNote, data: noteData, removeNote, addNoteInProgress } = useScheduleShiftNotes(data);
    const hasPermissions = usePermissions();
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || data.schedule.currentUserPermissions.includes('EDIT_RUNNING'), [data, hasPermissions]);
    return (<Notes addNoteInProgress={addNoteInProgress} addNote={addNote} canEditRunning={canEditRunning} notes={(_a = noteData === null || noteData === void 0 ? void 0 : noteData.notes) === null || _a === void 0 ? void 0 : _a.edges.map(({ node }) => node)} removeNote={removeNote} placeholder={intl.formatMessage({
            id: 'ScheduleShiftModal.Notes.add_placeholder',
            defaultMessage: 'Skriv en anteckning',
        })} showHidden/>);
};
export default ScheduleShiftNotesTab;
