import { addMonths, endOfDay, formatISO, startOfDay } from 'date-fns';
export const createRunningSchedulesFilter = (model) => {
    var _a, _b, _c;
    const assignmentTypeWhere = model.assignmentTypeIds.length
        ? { assignmentTypeId: { in: model.assignmentTypeIds } }
        : undefined;
    // const resourceIdsWhere = model.resourceIds.length ? { assignedEntityId: { in: model.resourceIds } } : undefined
    // const onlyVacantWhere = model.onlyVacant ? { assignedEntityId: { eq: null } } : undefined
    // const where =
    //   assignmentTypeWhere || resourceIdsWhere || onlyVacantWhere
    //     ? { ...(assignmentTypeWhere || {}), ...(resourceIdsWhere || {}), ...(onlyVacantWhere || {}) }
    //     : undefined
    const schedulesFilter = {
        scheduleIdsIn: model.scheduleIds,
    };
    const now = new Date();
    const calcFromDate = model.fromDate || now;
    const calcToDate = model.toDate || addMonths(now, 1);
    const shiftsFilter = {
        assignedEntityIdsIn: ((_a = model.resourceIds) === null || _a === void 0 ? void 0 : _a.length) > 0 ? model.resourceIds : null,
        assignmentTypeIdsIn: ((_b = model.assignmentTypeIds) === null || _b === void 0 ? void 0 : _b.length) > 0 ? model.assignmentTypeIds : null,
        vacant: model.onlyVacant ? true : null,
        tagIdsIn: ((_c = model.tagIds) === null || _c === void 0 ? void 0 : _c.length) > 0 ? model.tagIds : null,
    };
    const result = {
        fromDate: startOfDay(new Date(calcFromDate)),
        toDate: endOfDay(new Date(calcToDate)),
        // where,
        filterModel: schedulesFilter,
        whereAssignmentTypes: assignmentTypeWhere,
        shiftsFilter,
    };
    return result;
};
export const createRunningSchedulesFilterFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const useDefaultSearch = JSON.parse(localStorage.getItem('useDefaultSearch'));
    const defaultSearch = JSON.parse(localStorage.getItem('defaultSearch'));
    const defaultSearchDates = JSON.parse(localStorage.getItem('defaultSearchDates'));
    const defaultTags = JSON.parse(localStorage.getItem('defaultTags'));
    return createRunningSchedulesFilter({
        assignmentTypeIds: urlParams.getAll('assignmentTypeIds') || (useDefaultSearch && (defaultSearch === null || defaultSearch === void 0 ? void 0 : defaultSearch.assignmentTypeIds)) || [],
        resourceIds: urlParams.getAll('resourceIds') || (useDefaultSearch && (defaultSearch === null || defaultSearch === void 0 ? void 0 : defaultSearch.resourceIds)) || [],
        onlyVacant: urlParams.get('onlyVacant') || (useDefaultSearch && (defaultSearch === null || defaultSearch === void 0 ? void 0 : defaultSearch.onlyVacant)) || false,
        scheduleIds: urlParams.getAll('scheduleIds') || (useDefaultSearch && (defaultSearch === null || defaultSearch === void 0 ? void 0 : defaultSearch.scheduleIds)) || [],
        fromDate: urlParams.get('fromDate') || (useDefaultSearch ? defaultSearchDates === null || defaultSearchDates === void 0 ? void 0 : defaultSearchDates.fromDate : undefined),
        toDate: urlParams.get('toDate') || (useDefaultSearch ? defaultSearchDates === null || defaultSearchDates === void 0 ? void 0 : defaultSearchDates.toDate : undefined),
        tagIds: urlParams.getAll('tagIds') || (useDefaultSearch ? defaultTags !== null && defaultTags !== void 0 ? defaultTags : undefined : undefined),
    });
};
export const getDefaultRunningSchedulesFilterContextFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const now = new Date();
    return {
        fromDate: urlParams.get('fromDate') || formatISO(now, { representation: 'date' }),
        toDate: urlParams.get('toDate') || formatISO(addMonths(now, 1), { representation: 'date' }),
        filters: {
            resourceIds: urlParams.getAll('resourceIds') || [],
            assignmentTypeIds: urlParams.getAll('assignmentTypeIds') || [],
            scheduleIds: urlParams.getAll('scheduleIds') || [],
            onlyVacant: !!urlParams.get('onlyVacant') || undefined,
            hideEmptySchedules: !!urlParams.get('hideEmptySchedules') || undefined,
            tagIds: urlParams.getAll('tagIds') || [],
        },
    };
};
export const setRunningSchedulesFilterContextToUrl = (context) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const urlParams = new URLSearchParams();
    urlParams.set('fromDate', context.fromDate);
    urlParams.set('toDate', context.toDate);
    if ((_b = (_a = context === null || context === void 0 ? void 0 : context.filters) === null || _a === void 0 ? void 0 : _a.assignmentTypeIds) === null || _b === void 0 ? void 0 : _b.length) {
        (_c = context === null || context === void 0 ? void 0 : context.filters) === null || _c === void 0 ? void 0 : _c.assignmentTypeIds.forEach((filter) => {
            urlParams.append('assignmentTypeIds', filter);
        });
    }
    if ((_e = (_d = context === null || context === void 0 ? void 0 : context.filters) === null || _d === void 0 ? void 0 : _d.resourceIds) === null || _e === void 0 ? void 0 : _e.length) {
        (_f = context === null || context === void 0 ? void 0 : context.filters) === null || _f === void 0 ? void 0 : _f.resourceIds.forEach((filter) => {
            urlParams.append('resourceIds', filter);
        });
    }
    if ((_h = (_g = context === null || context === void 0 ? void 0 : context.filters) === null || _g === void 0 ? void 0 : _g.scheduleIds) === null || _h === void 0 ? void 0 : _h.length) {
        (_j = context === null || context === void 0 ? void 0 : context.filters) === null || _j === void 0 ? void 0 : _j.scheduleIds.forEach((filter) => {
            urlParams.append('scheduleIds', filter);
        });
    }
    if ((_l = (_k = context === null || context === void 0 ? void 0 : context.filters) === null || _k === void 0 ? void 0 : _k.tagIds) === null || _l === void 0 ? void 0 : _l.length) {
        (_m = context === null || context === void 0 ? void 0 : context.filters) === null || _m === void 0 ? void 0 : _m.tagIds.forEach((filter) => {
            urlParams.append('tagIds', filter);
        });
    }
    if ((_o = context === null || context === void 0 ? void 0 : context.filters) === null || _o === void 0 ? void 0 : _o.onlyVacant) {
        urlParams.set('onlyVacant', (_p = context === null || context === void 0 ? void 0 : context.filters) === null || _p === void 0 ? void 0 : _p.onlyVacant);
    }
    if ((_q = context === null || context === void 0 ? void 0 : context.filters) === null || _q === void 0 ? void 0 : _q.hideEmptySchedules) {
        urlParams.set('hideEmptySchedules', (_r = context === null || context === void 0 ? void 0 : context.filters) === null || _r === void 0 ? void 0 : _r.hideEmptySchedules);
    }
    window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
};
export const createChangeRequestFilters = ({ assignmentTypeIds, resourceIds, onlyVacant, scheduleIds, fromDate, toDate, userId, }) => {
    const now = new Date();
    const calcFromDate = fromDate || now;
    const calcToDate = toDate || addMonths(now, 1);
    return {
        assignmentTypesFilter: assignmentTypeIds || [],
        entitiesFilter: (resourceIds === null || resourceIds === void 0 ? void 0 : resourceIds.length) || onlyVacant
            ? {
                assignedResources: resourceIds || [],
                vacant: !!onlyVacant,
            }
            : undefined,
        schedulesFilter: scheduleIds || null,
        whereChangeRequest: userId ? { requestedByUserId: { in: [userId] } } : null,
        fromDate: startOfDay(new Date(calcFromDate)),
        toDate: endOfDay(new Date(calcToDate)),
    };
};
export const createChangeRequestFiltersFromUrl = (userId) => {
    const urlParams = new URLSearchParams(window.location.search);
    return createChangeRequestFilters({
        userId,
        resourceIds: urlParams.getAll('resourceIds'),
        assignmentTypeIds: urlParams.getAll('assignmentTypeIds'),
        scheduleIds: urlParams.getAll('scheduleIds'),
        fromDate: urlParams.get('fromDate'),
        toDate: urlParams.get('toDate'),
        onlyVacant: urlParams.get('onlyVacant'),
    });
};
