import React, { useEffect } from 'react';
import { SelectDropdown } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay/hooks';
export const SchedulableEntitiesSearch = (props) => {
    const { allScheduleableEntityIds, onSelect, query, selectedScheduleableEntityIds } = props;
    const data = useLazyLoadQuery(graphql `
      query SchedulableEntitiesSearchQuery($where: SchedulableEntityFilterInput) {
        ...SchedulableEntitiesSearch_query @arguments(where: $where)
      }
    `, {
        where: {
            id: {
                in: allScheduleableEntityIds,
                nin: selectedScheduleableEntityIds,
            },
            name: { contains: query },
        },
    }, {
        fetchPolicy: 'network-only',
    });
    const { data: { schedulableEntities }, hasNext, isLoadingNext, loadNext, refetch, } = usePaginationFragment(graphql `
      fragment SchedulableEntitiesSearch_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 10 }
        where: { type: "SchedulableEntityFilterInput" }
      )
      @refetchable(queryName: "SchedulableEntitiesSearchRefetchQuery") {
        schedulableEntities(after: $after, first: $first, model: { onlyActive: true }, where: $where)
          @connection(key: "SchedulableEntitiesSearch_query_schedulableEntities") {
          edges {
            node {
              ... on ISchedulableEntity {
                id
                displayName
              }
            }
          }
        }
      }
    `, data);
    useEffect(() => {
        if (query !== '') {
            refetch({
                where: {
                    id: {
                        in: allScheduleableEntityIds,
                        nin: selectedScheduleableEntityIds,
                    },
                    name: { contains: query },
                },
            }, {
                fetchPolicy: 'network-only',
            });
        }
    }, [query]);
    return (<SelectDropdown onLastItemVisible={hasNext && !isLoadingNext
            ? () => {
                loadNext(10);
            }
            : undefined} options={schedulableEntities.edges.map(({ node: scheduledEntity }) => ({
            label: scheduledEntity.displayName,
            value: scheduledEntity.id,
        }))} searchString={query} selectedItems={[]} setSelected={({ value }) => {
            onSelect(value);
        }}/>);
};
export default SchedulableEntitiesSearch;
