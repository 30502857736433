/**
 * @generated SignedSource<<a8eee9710740c76be46ce6b52e9a70b6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "event",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unit",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ScheduleTimesPayload_iHaveScheduleTimes",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                "kind": "LinkedField",
                "name": "timesByEntityAndAssignmentType",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CalculateTimePartsPayload",
                        "kind": "LinkedField",
                        "name": "calculatedTimes",
                        "plural": false,
                        "selections": [
                            {
                                "alias": "employmentSummaries",
                                "args": null,
                                "concreteType": "CalculateTimePartsSummaryByEmployment",
                                "kind": "LinkedField",
                                "name": "summaryByEmployment",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Employment",
                                        "kind": "LinkedField",
                                        "name": "employment",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "EmploymentSetting",
                                                "kind": "LinkedField",
                                                "name": "employmentSetting",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "title",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": "eventSummaries",
                                        "args": null,
                                        "concreteType": "TimePartsSummary",
                                        "kind": "LinkedField",
                                        "name": "calculatedSummary",
                                        "plural": true,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "isWork",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "TimeSpecificationPartSummary",
                                                "kind": "LinkedField",
                                                "name": "nonRegularPartsSummary",
                                                "plural": true,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "unit",
                                                                "value": "SECOND"
                                                            }
                                                        ],
                                                        "concreteType": "TimeSpanUnitValue",
                                                        "kind": "LinkedField",
                                                        "name": "duration",
                                                        "plural": false,
                                                        "selections": [
                                                            (v1 /*: any*/),
                                                            (v2 /*: any*/)
                                                        ],
                                                        "storageKey": "duration(unit:\"SECOND\")"
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            (v2 /*: any*/),
                                            (v1 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "IHaveScheduleTimes",
        "abstractKey": "__isIHaveScheduleTimes"
    };
})();
node.hash = "67329d6bee87298c96cf4e738ffb84e0";
export default node;
