import React, { useState } from 'react';
import { string, object } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { H6, Form, Field, MultiSelect } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay/hooks';
const validationPublishScheduleWizardPeriod = object({
    startPeriodId: string().required(),
});
export const PublishScheduleWizardPeriod = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { id, state, onSubmit, scheduleTemplate } = props;
    const intl = useIntl();
    const formData = useFragment(graphql `
      fragment PublishScheduleWizardPeriod_periods on ScheduleTemplate {
        periods(order: { order: ASC }) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, scheduleTemplate);
    const [data, setData] = useState({
        startPeriodId: (_c = (_b = (_a = formData === null || formData === void 0 ? void 0 : formData.periods) === null || _a === void 0 ? void 0 : _a.edges[0]) === null || _b === void 0 ? void 0 : _b.node) === null || _c === void 0 ? void 0 : _c.id,
    });
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: { startPeriodId: data.startPeriodId },
        resolver: yupResolver(validationPublishScheduleWizardPeriod),
    });
    if (state === 'READ') {
        return <H6>{`${(_g = (_f = (_e = (_d = formData === null || formData === void 0 ? void 0 : formData.periods) === null || _d === void 0 ? void 0 : _d.edges) === null || _e === void 0 ? void 0 : _e.find(({ node }) => node.id === data.startPeriodId)) === null || _f === void 0 ? void 0 : _f.node) === null || _g === void 0 ? void 0 : _g.name}`}</H6>;
    }
    return (<div>
      <H6 style={{ marginTop: '1rem' }}>
        <FormattedMessage defaultMessage="Välj vilken period som schemat ska starta i" id="ScheduleTemplateDetailsPage.PublishScheduleWizardPeriod.header"/>
      </H6>
      <Form id={id} columns="1fr 1fr" style={{ marginTop: '1rem' }} onSubmit={handleSubmit((formData) => {
            setData(formData);
            onSubmit(formData);
        })}>
        <Field label={intl.formatMessage({
            id: 'general.objects.schedule.fields.start_period.label',
            defaultMessage: 'Startperiod',
        })} error={(_j = (_h = errors === null || errors === void 0 ? void 0 : errors.startPeriodId) === null || _h === void 0 ? void 0 : _h.message) === null || _j === void 0 ? void 0 : _j.toString()} columnSpan={3}>
          <Controller control={control} name="startPeriodId" render={({ field }) => {
            var _a, _b;
            return (<MultiSelect placeholder={intl.formatMessage({
                    id: 'general.objects.schedule.fields.start_period.placeholder',
                    defaultMessage: 'Välj startperiod',
                })} stayOpenOnSelect={false} options={(_b = (_a = formData === null || formData === void 0 ? void 0 : formData.periods) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(({ node: entity }) => ({
                    label: entity === null || entity === void 0 ? void 0 : entity.name,
                    value: entity === null || entity === void 0 ? void 0 : entity.id,
                }))} onChange={(value) => {
                    field.onChange(value ? value[0] : undefined);
                    setData({ startPeriodId: value ? value[0] : undefined });
                }} defaultValue={field.value}/>);
        }}/>
        </Field>
      </Form>
    </div>);
};
export default PublishScheduleWizardPeriod;
