/**
 * @generated SignedSource<<6ba2d2be45ad1c9e64e26464af6d29de>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        "resources"
    ], v1 = {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Avatar_iHaveAvatar"
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "after"
            },
            {
                "defaultValue": 50,
                "kind": "LocalArgument",
                "name": "first"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "first",
                    "cursor": "after",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "first",
                        "cursor": "after"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./ScheduleAssignmentTypeResourceTable_scheduleAssignmentTypeQuery.graphql'),
                "identifierField": "id"
            }
        },
        "name": "ScheduleAssignmentTypeResourceTable_scheduleAssignmentType",
        "selections": [
            {
                "alias": "resources",
                "args": null,
                "concreteType": "ScheduleAssignmentTypeResourceConnection",
                "kind": "LinkedField",
                "name": "__ScheduleAssignmentTypeResourceTable_scheduleAssignmentType_resources_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleAssignmentTypeResourceEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleAssignmentTypeResource",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "schedulableEntity",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Asset",
                                                        "kind": "LinkedField",
                                                        "name": "asset",
                                                        "plural": false,
                                                        "selections": [
                                                            (v1 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "name",
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "type": "SchedulableAsset",
                                                "abstractKey": null
                                            },
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "User",
                                                        "kind": "LinkedField",
                                                        "name": "user",
                                                        "plural": false,
                                                        "selections": [
                                                            (v1 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "firstname",
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "lastname",
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "type": "SchedulableUser",
                                                "abstractKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            }
        ],
        "type": "ScheduleAssignmentType",
        "abstractKey": null
    };
})();
node.hash = "2439b8dfbd24af90e38f7ba6233a3766";
export default node;
