import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { faEye as faEyeSolid } from '@fortawesome/pro-solid-svg-icons';
import { useFragment, graphql } from 'react-relay/hooks';
import { Switch, Popover, PopoverHeader, PopoverContent, PopoverFooter, Flexbox, Checkbox, Label, IconButton, Tooltip, Badge, Placeholder, } from '@sonika-se/ui-kit/components';
import ScheduleTemplateDetailsPageContext from '../../ScheduleTemplateDetailsPageContext';
import { FormattedMessage, useIntl } from 'react-intl';
const useStyles = createUseStyles((theme) => ({
    smallHeader: {
        color: theme.colors.foreground.tertiary,
    },
    assignmentTypeCheckbox: {
        marginRight: 10,
        cursor: 'pointer',
    },
}));
const ScheduleTemplateAssignmentTypeFilter = (props) => {
    const { updateFilters, scheduleTemplate } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { assignmentTypeIds: checked, onlyVacant } = useContext(ScheduleTemplateDetailsPageContext).filters;
    const open = Boolean(anchorEl);
    const classes = useStyles();
    const intl = useIntl();
    const data = useFragment(graphql `
      fragment ScheduleTemplateAssignmentTypeFilter_assignmentTypes on Schedule {
        id
        assignmentTypes {
          edges {
            node {
              id
              assignmentType {
                id
                title
              }
            }
          }
        }
      }
    `, scheduleTemplate);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const nrOfFilters = (checked === null || checked === void 0 ? void 0 : checked.length) + (onlyVacant ? 1 : 0);
    return (<>
      <div>
        <Tooltip content={intl.formatMessage({
            id: 'ScheduleTemplateAssignmentTypeFilter.header',
            defaultMessage: 'Visningsalternativ',
        })}>
          <Badge text={`${nrOfFilters}`} appearance={nrOfFilters ? 'danger' : 'none'}>
            <IconButton onClick={handleClick} icon={open ? faEyeSolid : faEye} appearance={open ? 'filled' : 'outlined'}/>
          </Badge>
        </Tooltip>
      </div>
      <Popover size="auto" anchorEl={open ? anchorEl : undefined} onClose={handleClose}>
        <PopoverHeader>
          <FormattedMessage defaultMessage="Visningsalternativ" id="ScheduleTemplateAssignmentTypeFilter.header"/>
        </PopoverHeader>
        <PopoverContent>
          <Flexbox direction="column" gap="medium">
            <Label text={intl.formatMessage({ defaultMessage: 'Visa {name}', id: 'general.actions.show' }, {
            name: intl.formatMessage({
                id: 'general.objects.assignment_type.name.plural',
                defaultMessage: 'Roller',
            }),
        })} className={classes.smallHeader}/>
            {data.assignmentTypes.edges.map(({ node }) => (<Flexbox key={`assignmenttype_checkbox_${node.assignmentType.id}`} gap="xsmall" align="center">
                <Checkbox id={`checkedAssignmentType_${node.assignmentType.id}`} checked={!!checked.find((checkedId) => checkedId === node.assignmentType.id)} className={classes.assignmentTypeCheckbox} onChange={(event) => {
                if (event.target.checked) {
                    updateFilters([...checked, node.assignmentType.id], 'assignmentTypeIds');
                }
                else {
                    updateFilters(checked.filter((checkedValue) => checkedValue !== node.assignmentType.id), 'assignmentTypeIds');
                }
            }}/>
                <Label style={{ fontSize: '1rem' }} text={node.assignmentType.title} htmlFor={`checkedAssignmentType_${node.assignmentType.id}`}/>
              </Flexbox>))}
            {data.assignmentTypes.edges.length === 0 && (<Placeholder text={intl.formatMessage({
                defaultMessage: 'Det finns inga roller att filtrera på',
                id: 'ScheduleTemplateAssignmentTypeFilter.no_data',
            })}/>)}
          </Flexbox>
        </PopoverContent>
        <PopoverFooter>
          <Switch label={intl.formatMessage({
            id: 'ScheduleTemplateAssignmentTypeFilter.display_only_vacant',
            defaultMessage: 'Visa endast vakanta pass',
        })} onChange={(event) => updateFilters(event.target.checked || undefined, 'onlyVacant')} defaultChecked={onlyVacant}/>
        </PopoverFooter>
      </Popover>
    </>);
};
export default ScheduleTemplateAssignmentTypeFilter;
