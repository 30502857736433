import React from 'react';
import { Button, Flexbox } from '@sonika-se/ui-kit/components';
export const EditableList = (props) => {
    const { children, hasNext, isLoadingNext, loadNext } = props;
    return (<Flexbox direction="column" gap="medium">
      {children}
      <div>
        {hasNext && (<Button color="default" disabled={isLoadingNext} display="block" onClick={() => loadNext()}>
            Ladda fler
          </Button>)}
      </div>
    </Flexbox>);
};
export default EditableList;
