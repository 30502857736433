/**
 * @generated SignedSource<<39016f445e30c9e5e251a2a3cb89409d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignmentTypeChip_assignmentType",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
        }
    ],
    "type": "AssignmentType",
    "abstractKey": null
};
node.hash = "3e8b34f5c705468ced0e6e1117b272d7";
export default node;
