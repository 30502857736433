/**
 * @generated SignedSource<<1643f4e90fe2c768d9fac50877025293>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleConnection",
            "kind": "LinkedField",
            "name": "schedules",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "NewScheduleTemplateModalQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "NewScheduleTemplateModalQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "7de0c566d97323a372fb4276e4ff2d3d",
            "id": null,
            "metadata": {},
            "name": "NewScheduleTemplateModalQuery",
            "operationKind": "query",
            "text": "query NewScheduleTemplateModalQuery {\n  schedules {\n    nodes {\n      id\n      name\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "98b126cd1a2cdde2e5b94ab310aabb74";
export default node;
