var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery, useMutation, useRefetchableFragment } from 'react-relay/hooks';
import { Controller, useForm } from 'react-hook-form';
import { Accordion, AccordionItem, Button, Confirm, DateInput, Field, FieldList, Flexbox, FormSection, Input, ModalToSheet, ModalToSheetContent, ModalToSheetFooter, ModalToSheetHeader, Select2, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from '@sonika-se/ui-kit/components';
import { array, date, mixed, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToasts } from '@sonika-se/ui-kit/hooks';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
import { formatISO } from 'date-fns';
import { calcOffsetInMinutes } from '~/pages/ScheduleTemplateDetailsPage/components/ScheduleTemplateShiftForm';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { calculateBreakTime } from '~/pages/ScheduleTemplateDetailsPage/components/EditScheduleTemplateShiftModal/EditScheduleTemplateShiftModal';
export const ChangeShiftTemplateTimeModal = (props) => {
    var _a;
    const { onClose, shiftTemplateId, onDismiss } = props;
    const data = useLazyLoadQuery(graphql `
      query ChangeShiftTemplateTimeModalQuery($id: ID!) {
        breakTypes: enumValues(name: "BreakEnum") {
          key
          name
          description
        }
        ...ChangeShiftTemplateTimeModal_ScheduleTemplate @arguments(id: $id)
      }
    `, { id: shiftTemplateId });
    const { breakTypes } = data;
    const [showConfirmAfterSave, setShowConfirmAfterSave] = useState(false);
    const [{ scheduleTemplateShift }, refetch] = useRefetchableFragment(graphql `
      fragment ChangeShiftTemplateTimeModal_ScheduleTemplate on Query
      @argumentDefinitions(id: { type: "ID!" }, fromDate: { type: "DateTime" }, toDate: { type: "DateTime" })
      @refetchable(queryName: "ChangeShiftTemplateTimeModal_ScheduleTemplateQuery") {
        scheduleTemplateShift(id: $id) {
          startTimeOfDay
          endTimeOfDay
          breaks {
            offsetStart(unit: MINUTE) {
              value
              unit
            }
            offsetEnd(unit: MINUTE) {
              value
              unit
            }
            type
          }
          generatedScheduleShifts(first: 100, order: [{ start: ASC }], fromDate: $fromDate, toDate: $toDate) {
            totalCount
            nodes {
              id
              start
              end
              assignedEntity {
                ... on ISchedulableEntity {
                  id
                  displayName
                }
              }
            }
          }
        }
      }
    `, data);
    const allBreakTypes = breakTypes.map((x) => (Object.assign(Object.assign({}, x), { key: x.key })));
    const selectableBreakTypes = allBreakTypes;
    const defaultValues = {
        newStartTime: scheduleTemplateShift.startTimeOfDay,
        newEndTime: scheduleTemplateShift.endTimeOfDay,
        breaks: (_a = scheduleTemplateShift.breaks) === null || _a === void 0 ? void 0 : _a.map((x) => ({
            type: x.type,
            offsetStart: calculateBreakTime(scheduleTemplateShift.startTimeOfDay, x.offsetStart.value),
            offsetEnd: calculateBreakTime(scheduleTemplateShift.startTimeOfDay, x.offsetEnd.value),
        })),
    };
    const validationSchema = object().shape({
        validFrom: date().notRequired().nullable(),
        validTo: date().notRequired().nullable(),
        newStartTime: string().required(),
        newEndTime: string().required(),
        breaks: array().of(mixed()).notRequired().nullable(),
    });
    const { control, watch, formState: { errors, isValid }, handleSubmit, setValue, } = useForm({
        defaultValues: defaultValues,
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    });
    const { addToast } = useToasts();
    const selectedFromDate = watch('validFrom');
    const selectedToDate = watch('validTo');
    useEffect(() => {
        refetch({
            id: shiftTemplateId,
            fromDate: selectedFromDate == null ? null : formatISO(selectedFromDate),
            toDate: selectedToDate == null ? null : formatISO(selectedToDate),
        });
    }, [selectedFromDate, selectedToDate]);
    const [updateShifts, updateShiftsLoading] = useMutation(graphql `
      mutation ChangeShiftTemplateTimeModal_updateMutation($model: UpdateScheduleTemplateShiftTimesInput!) {
        updateScheduleTemplateShiftTimes(model: $model) {
          shift {
            id
          }
        }
      }
    `);
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        updateShifts({
            variables: {
                model: {
                    templateShiftId: shiftTemplateId,
                    validFrom: data.validFrom == null ? null : formatISO(data.validFrom),
                    validTo: data.validTo == null ? null : formatISO(data.validTo),
                    startOffsetFromMidnight: {
                        unit: 'MINUTE',
                        value: calcOffsetInMinutes(0, data.newStartTime),
                    },
                    endOffsetFromMidnight: {
                        unit: 'MINUTE',
                        value: calcOffsetInMinutes(0, data.newEndTime),
                    },
                    breaks: (_b = data.breaks) === null || _b === void 0 ? void 0 : _b.map((x) => ({
                        type: x.type,
                        startOffsetFromMidnight: {
                            unit: 'MINUTE',
                            value: calcOffsetInMinutes(0, x.offsetStart),
                        },
                        endOffsetFromMidnight: {
                            unit: 'MINUTE',
                            value: calcOffsetInMinutes(0, x.offsetEnd),
                        },
                    })),
                },
            },
            onCompleted: (response) => {
                addToast(`Tider uppdaterade på ${response.updateScheduleTemplateShiftTimes.length} pass`);
                setShowConfirmAfterSave(true);
            },
        });
    });
    const onInvalidForm = (errors) => __awaiter(void 0, void 0, void 0, function* () {
        console.log(errors);
    });
    return (<>
      <ModalToSheet onClose={() => onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss()} size={'large'}>
        <form onSubmit={handleSubmit(onSubmit, onInvalidForm)} style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          <ModalToSheetHeader onClose={() => onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss()}>Uppdatera malltider</ModalToSheetHeader>
          <ModalToSheetContent>
            <FormSection columns="1fr 1fr">
              <Field label="Från datum">
                <Controller control={control} name="validFrom" render={({ field }) => {
            var _a;
            return (<>
                      <DateInput error={(_a = errors === null || errors === void 0 ? void 0 : errors.validFrom) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>
                    </>);
        }}/>
              </Field>
              <Field label="Till och med datum">
                <Controller control={control} name="validTo" render={({ field }) => {
            var _a;
            return (<>
                      <DateInput error={(_a = errors === null || errors === void 0 ? void 0 : errors.validTo) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>
                    </>);
        }}/>
              </Field>
              <Field columnSpan={2} style={{ backgroundColor: sonikaTheme.colors.background.tertiary }}>
                {/* <Placeholder> */}
                <Flexbox>
                  <Accordion>
                    <AccordionItem size="medium" title={`Påverkar ${scheduleTemplateShift.generatedScheduleShifts.totalCount} pass`}>
                      <Table className="small-table">
                        <TableHead>
                          <TableRow>
                            <TableHeader>
                              <strong>Start</strong>
                            </TableHeader>
                            <TableHeader>
                              <strong>Slut</strong>
                            </TableHeader>
                            <TableHeader>
                              <strong>Tillsatt</strong>
                            </TableHeader>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scheduleTemplateShift.generatedScheduleShifts.nodes.map((x) => {
            var _a, _b;
            return (<TableRow key={`ChangeShiftTemplateTimeModal-${x.id}`}>
                              <TableCell>{formatDate(toDate(x.start), 'dateTime')}</TableCell>
                              <TableCell>{formatDate(toDate(x.end), 'dateTime')}</TableCell>
                              <TableCell>{(_b = (_a = x.assignedEntity) === null || _a === void 0 ? void 0 : _a.displayName) !== null && _b !== void 0 ? _b : 'Vakant'}</TableCell>
                            </TableRow>);
        })}
                        </TableBody>
                      </Table>
                    </AccordionItem>
                  </Accordion>
                </Flexbox>
                {/* </Placeholder> */}
              </Field>
              <Field label="Ny starttid">
                <Controller control={control} name="newStartTime" render={({ field }) => {
            var _a;
            return (<Input error={(_a = errors === null || errors === void 0 ? void 0 : errors.newStartTime) === null || _a === void 0 ? void 0 : _a.message} onChange={field.onChange} type="time" value={field.value}/>);
        }}/>
              </Field>
              <Field label="Ny sluttid">
                <Controller control={control} name="newEndTime" render={({ field }) => {
            var _a;
            return (<Input error={(_a = errors === null || errors === void 0 ? void 0 : errors.newEndTime) === null || _a === void 0 ? void 0 : _a.message} onChange={field.onChange} type="time" value={field.value}/>);
        }}/>
              </Field>
              <FormSection columnSpan={2}>
                <FieldList appendText="Lägg till rast" compact control={control} name="breaks" label="Raster" render={(field, index) => {
            var _a, _b, _c, _d, _e, _f, _g;
            return (<FormSection columns="1fr 1fr 1fr" key={field.id}>
                      <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_c = (_b = (_a = errors.breaks[index]) === null || _a === void 0 ? void 0 : _a.offsetStart) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.toString())}>
                        <Controller control={control} name={`breaks.${index}.offsetStart`} render={({ field }) => (<Input defaultValue={field.value} onChange={field.onChange} type="time" value={field.value}/>)}/>
                      </Field>
                      <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_f = (_e = (_d = errors.breaks[index]) === null || _d === void 0 ? void 0 : _d.offsetEnd) === null || _e === void 0 ? void 0 : _e.message) === null || _f === void 0 ? void 0 : _f.toString())}>
                        <Controller control={control} name={`breaks.${index}.offsetEnd`} render={({ field }) => (<Input defaultValue={field.value} onChange={field.onChange} type="time" value={field.value}/>)}/>
                      </Field>
                      <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_g = errors.breaks[index]) === null || _g === void 0 ? void 0 : _g.type.toString())}>
                        <Controller control={control} name={`breaks.${index}.type`} render={({ field }) => (<Select2 options={selectableBreakTypes.map((x) => ({
                        label: x.name,
                        value: x.key,
                    }))} onChange={(value) => {
                        field.onChange(value === null || value === void 0 ? void 0 : value.single);
                    }} placeholder="Välj typ" defaultValue={field.value}/>)}/>
                      </Field>
                    </FormSection>);
        }}/>
              </FormSection>
            </FormSection>
          </ModalToSheetContent>
          <ModalToSheetFooter>
            <Flexbox direction="row" justify="space" style={{ flex: 1 }}>
              <Button type="button" color="default" onClick={() => onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss()}>
                Stäng
              </Button>
              <Button type="submit" disabled={updateShiftsLoading}>
                Spara
              </Button>
            </Flexbox>
          </ModalToSheetFooter>
        </form>
      </ModalToSheet>
      {showConfirmAfterSave && (<Confirm headerText="Ladda om sidan för att visa uppdateringarna" confirmButton={{
                onConfirm: () => {
                    setShowConfirmAfterSave(false);
                    onClose();
                },
                text: 'Ok',
            }}/>)}
    </>);
};
export default ChangeShiftTemplateTimeModal;
