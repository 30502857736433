import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Button, Flexbox, Text } from '@sonika-se/ui-kit/components';
export const UserPickerListItem = (props) => {
    const { onSelect, userRef } = props;
    const { firstname, id, lastname } = useFragment(graphql `
      fragment UserPickerListItem_user on User {
        id
        firstname
        lastname
      }
    `, userRef);
    return (<Flexbox justify="space">
      <Text>{`${firstname} ${lastname}`}</Text>
      <Button onClick={() => onSelect(id)} size="small">
        Välj
      </Button>
    </Flexbox>);
};
export default UserPickerListItem;
