import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { ShiftHistoryEvent } from './ScheduleShiftHistoryEvent';
import User from '../User';
import { FormattedMessage } from 'react-intl';
function comparer(otherArray) {
    return function (current) {
        return (otherArray.filter(function (other) {
            return other.start == current.start && other.end == current.end;
        }).length == 0);
    };
}
const getBreaksEventText = (originalBreaksItems, newBreaksItems, byUser) => {
    const newBreaks = newBreaksItems || [];
    const originalBreaks = originalBreaksItems || [];
    if ((originalBreaks === null || originalBreaks === void 0 ? void 0 : originalBreaks.length) > (newBreaks === null || newBreaks === void 0 ? void 0 : newBreaks.length)) {
        const changedBreaks = originalBreaks.filter(comparer(newBreaks));
        const mappedBreaks = changedBreaks === null || changedBreaks === void 0 ? void 0 : changedBreaks.map((changedBreak) => `${format(new Date(changedBreak === null || changedBreak === void 0 ? void 0 : changedBreak.start), 'HH:mm', { locale })}-${format(new Date(changedBreak === null || changedBreak === void 0 ? void 0 : changedBreak.end), 'HH:mm', {
            locale,
        })}`);
        return (<FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryTimeChange.removed_breaks" defaultMessage="{user} har tagit bort följande raster: {breaks}" values={{
                user: <User user={byUser}/>,
                breaks: <strong style={{ fontWeight: 'bold' }}>{mappedBreaks === null || mappedBreaks === void 0 ? void 0 : mappedBreaks.join(', ')}</strong>,
            }}/>);
    }
    else if ((originalBreaks === null || originalBreaks === void 0 ? void 0 : originalBreaks.length) < (newBreaks === null || newBreaks === void 0 ? void 0 : newBreaks.length)) {
        const changedBreaks = newBreaks.filter(comparer(originalBreaks));
        const mappedBreaks = changedBreaks === null || changedBreaks === void 0 ? void 0 : changedBreaks.map((changedBreak) => `${format(new Date(changedBreak === null || changedBreak === void 0 ? void 0 : changedBreak.start), 'HH:mm', { locale })}-${format(new Date(changedBreak === null || changedBreak === void 0 ? void 0 : changedBreak.end), 'HH:mm', {
            locale,
        })}`);
        return (<FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryTimeChange.added_breaks" defaultMessage="{user} har lagt till  följande raster: {breaks}" values={{
                user: <User user={byUser}/>,
                breaks: <strong style={{ fontWeight: 'bold' }}>{mappedBreaks === null || mappedBreaks === void 0 ? void 0 : mappedBreaks.join(', ')}</strong>,
            }}/>);
    }
    else {
        const changedBreaks = newBreaks.filter(comparer(originalBreaks));
        if ((changedBreaks === null || changedBreaks === void 0 ? void 0 : changedBreaks.length) === 0) {
            return (<FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryTimeChange.changed_break_type" defaultMessage="{user} har ändrat typ på en av rasterna" values={{
                    user: <User user={byUser}/>,
                }}/>);
        }
        const mappedBreaks = changedBreaks === null || changedBreaks === void 0 ? void 0 : changedBreaks.map((changedBreak) => `${format(new Date(changedBreak === null || changedBreak === void 0 ? void 0 : changedBreak.start), 'HH:mm', { locale })}-${format(new Date(changedBreak === null || changedBreak === void 0 ? void 0 : changedBreak.end), 'HH:mm', {
            locale,
        })}`);
        return (<FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryTimeChange.changed_break_time" defaultMessage="{user} har ändrat tid på följande raster till: {breaks}" values={{
                user: <User user={byUser}/>,
                breaks: <strong style={{ fontWeight: 'bold' }}>{mappedBreaks === null || mappedBreaks === void 0 ? void 0 : mappedBreaks.join(', ')}</strong>,
            }}/>);
    }
};
export const ScheduleShiftHistoryTimeChange = (props) => {
    const { byUser, newEnd, newStart, originalEnd, originalStart, timestamp, originalBreaks, newBreaks } = useFragment(graphql `
      fragment ScheduleShiftHistoryTimeChange_history on ScheduleShiftHistoryTimeChange {
        id
        byUser {
          ...User_user
        }
        newEnd
        newStart
        originalBreaks {
          start
          end
          type
        }
        newBreaks {
          start
          end
          type
        }
        originalEnd
        originalStart
        timestamp
      }
    `, props.history);
    if (originalStart.toString() === newStart.toString() && newEnd.toString() === originalEnd.toString()) {
        // Raster har ändrats
        return (<ShiftHistoryEvent timestamp={timestamp.toString()}>
        <p>{getBreaksEventText(originalBreaks, newBreaks, byUser)}</p>
      </ShiftHistoryEvent>);
    }
    return (<ShiftHistoryEvent timestamp={timestamp.toString()}>
      <p>
        <FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryTimeChange.without_breaks" defaultMessage="{user} har ändrat tiden från {fromDate} till {toDate}." values={{
            user: <User user={byUser}/>,
            fromDate: (<strong style={{ fontWeight: 'bold' }}>{`${format(new Date(originalStart.toString()), 'HH:mm', {
                    locale,
                })}-${format(new Date(originalEnd.toString()), 'HH:mm', { locale })}`}</strong>),
            toDate: (<strong style={{ fontWeight: 'bold' }}>{`${format(new Date(newStart.toString()), 'HH:mm', {
                    locale,
                })}-${format(new Date(newEnd.toString()), 'HH:mm', { locale })}`}</strong>),
        }}/>
      </p>
    </ShiftHistoryEvent>);
};
export default ScheduleShiftHistoryTimeChange;
