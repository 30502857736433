import React from 'react';
import { Divider, Flexbox, H6, ModalToSheetContent, ModalToSheet, ModalToSheetHeader, Text, } from '@sonika-se/ui-kit/components';
import { graphql } from 'react-relay';
import { usePreloadedQuery } from 'react-relay/hooks';
import Asset from './Asset';
import PlaceholderText from './PlaceholderText';
import { MySchedulePaginationContentAsset } from '~/pages/SchedulePages/MySchedule/components/MySchedulePaginationContent/MySchedulePaginationContentAsset';
import Address from './AssetAddress';
export const assetModalQuery = graphql `
  query AssetModalQuery($id: ID!, $myScheduleModel: GetRunningScheduleRequestInput!) {
    asset(id: $id) {
      id
      ...AssetAddress
      color
      description
      ...Asset
      name
      schedulableEntity {
        id
        ... on SchedulableAsset {
          ...MySchedulePaginationContentAsset_shifts @arguments(model: $myScheduleModel)
        }
      }
    }
  }
`;
export const AssetModal = (props) => {
    const { onClose, preloadedQuery, toDate, fromDate } = props;
    const { asset } = usePreloadedQuery(assetModalQuery, preloadedQuery);
    return (<ModalToSheet onClose={onClose}>
      <ModalToSheetHeader onClose={onClose} closeButtonLabel="Stäng">
        Visa enheter
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Flexbox direction="column" gap="large">
          <Flexbox align="center" gap="xsmall">
            <Asset parent={asset}/>
            <Flexbox direction="column">
              <Text>{asset.name}</Text>
            </Flexbox>
          </Flexbox>
          <Flexbox gap="xsmall" direction="column">
            <Text size="small" style={{ color: '#6A6A6A' }}>
              Adress / Kajplats
            </Text>
            <Address asset={asset}/>
          </Flexbox>
          <Flexbox gap="xsmall" direction="column">
            <Text size="small" style={{ color: '#6A6A6A' }}>
              Beskrivning
            </Text>
            {asset.description ? (<Text>{asset.description}</Text>) : (<PlaceholderText>Ingen beskrivning</PlaceholderText>)}
          </Flexbox>
          <Flexbox direction="column" gap="xsmall">
            <H6>Kommmande pass</H6>
            <Divider />
            <div>
              <MySchedulePaginationContentAsset fromDate={fromDate} toDate={toDate} scheduleShifts={asset === null || asset === void 0 ? void 0 : asset.schedulableEntity}/>
            </div>
          </Flexbox>
        </Flexbox>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default AssetModal;
