import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import ScheduleGeneralInfoForm from '~/forms/ScheduleGeneralInfoForm';
import { toDate } from '@sonika-se/ui-kit/utils/time';
export const scheduleScheduleUpdateModalQuery = graphql `
  query ScheduleGeneralInfoUpdateModalQuery($scheduleId: ID!) {
    schedule(id: $scheduleId) {
      archived
      inactivatedFrom
      id
      name
    }
  }
`;
export const ScheduleGeneralInfoUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { schedule } = usePreloadedQuery(scheduleScheduleUpdateModalQuery, preloadedQuery);
    const [updateSchedule, updateScheduleLoading] = useMutation(graphql `
      mutation ScheduleGeneralInfoUpdateModal_updateScheduleMutation($model: UpdateScheduleRequestInput!) {
        updateSchedule(model: $model) {
          scheduleEdge {
            node {
              archived
              inactivatedFrom
              name
            }
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        updateSchedule({
            onCompleted: () => {
                onClose();
            },
            variables: {
                model: {
                    name: form.name,
                    scheduleId: schedule.id,
                    archived: form.archived,
                    inactivatedFrom: form.inactivatedFrom,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateScheduleLoading} title="Ändra allmän info">
      <ScheduleGeneralInfoForm defaultValues={{
            archived: schedule.archived,
            inactivatedFrom: !schedule.inactivatedFrom ? null : toDate(schedule.inactivatedFrom),
            name: schedule.name,
        }} disabled={updateScheduleLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default ScheduleGeneralInfoUpdateModal;
