import React from 'react';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import ScheduleTemplateShiftClash from './ScheduleTemplateShiftClash';
export const ScheduleTemplateShiftClashGroup = (props) => {
    const { scheduleTemplateShiftClashGroupRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleTemplateShiftClashGroup_scheduleTemplateShiftClashGroup on ScheduleTemplateShiftClashGroup {
        clashes {
          id
          assignedEntity {
            ... on SchedulableAsset {
              id
              asset {
                name
              }
            }
            ... on SchedulableUser {
              id
              user {
                ...User
                firstname
                lastname
              }
            }
          }
          ...ScheduleTemplateShiftClash_scheduleTemplateShift
        }
      }
    `, scheduleTemplateShiftClashGroupRef);
    return (<Flexbox direction="column" gap="xsmall">
      {data.clashes.map((clash) => (<ScheduleTemplateShiftClash key={clash.id} onClick={() => {
                // ?
            }} scheduleTemplateShiftRef={clash}/>))}
    </Flexbox>);
};
export default ScheduleTemplateShiftClashGroup;
