/**
 * @generated SignedSource<<504ece0c860aa79d5184a64936909e5e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleShiftId"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "where"
        }
    ], v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "scheduleShiftId"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftVacancyNotifyModalQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftVacancyNotifyModalForm_query"
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "ScheduleShift",
                    "kind": "LinkedField",
                    "name": "scheduleShift",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentType",
                            "kind": "LinkedField",
                            "name": "assignmentType",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Schedule",
                            "kind": "LinkedField",
                            "name": "schedule",
                            "plural": false,
                            "selections": [
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleShiftVacancyNotifyModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "firstname",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastname",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "ScheduleShift",
                    "kind": "LinkedField",
                    "name": "scheduleShift",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentType",
                            "kind": "LinkedField",
                            "name": "assignmentType",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Schedule",
                            "kind": "LinkedField",
                            "name": "schedule",
                            "plural": false,
                            "selections": [
                                (v5 /*: any*/),
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v6 /*: any*/),
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "046db64ca260043f77508ee73526577c",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftVacancyNotifyModalQuery",
            "operationKind": "query",
            "text": "query ScheduleShiftVacancyNotifyModalQuery(\n  $scheduleShiftId: ID!\n  $where: UserFilterInput\n) {\n  ...ScheduleShiftVacancyNotifyModalForm_query_3FC4Qo\n  scheduleShift(id: $scheduleShiftId) {\n    assignmentType {\n      title\n      id\n    }\n    end\n    schedule {\n      name\n      id\n    }\n    start\n    id\n  }\n  users(where: $where) {\n    nodes {\n      id\n    }\n  }\n}\n\nfragment ScheduleShiftVacancyNotifyModalForm_query_3FC4Qo on Query {\n  users(where: $where) {\n    nodes {\n      id\n      firstname\n      lastname\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "cafe02603544f1667e027de06c0f3f24";
export default node;
