import React from 'react';
import { InlineDateInput } from '@sonika-se/ui-kit/components';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
export const EditCompanyScheduleVisibility = (props) => {
    const { placeholder, company } = props;
    const { id, scheduleSettings } = useFragment(graphql `
      fragment EditCompanyScheduleVisibility on Company {
        id
        scheduleSettings {
          id
          viewAccessUntil
        }
      }
    `, company);
    const [updateCompany, updateCompanyLoading] = useMutation(graphql `
    mutation EditCompanyScheduleVisibilityMutation($model: UpdateCompanyScheduleSettingsRequestInput!) {
      updateCompanyScheduleSettings(model: $model) {
        scheduleSettingsEdge {
          node {
            id
            viewAccessUntil
          }
        }
      }
    }
  `);
    const [createCompany, createCompanyLoading] = useMutation(graphql `
    mutation EditCompanyScheduleVisibilityCreateMutation($model: CreateCompanyScheduleSettingsRequestInput!) {
      createCompanyScheduleSettings(model: $model) {
        scheduleSettingsEdge {
          node {
            id
            viewAccessUntil
          }
        }
      }
    }
  `);
    return (<InlineDateInput disabled={updateCompanyLoading} placeholder={placeholder} defaultValue={(scheduleSettings === null || scheduleSettings === void 0 ? void 0 : scheduleSettings.viewAccessUntil) ? new Date(scheduleSettings === null || scheduleSettings === void 0 ? void 0 : scheduleSettings.viewAccessUntil) : undefined} onChange={(date) => {
            if (!scheduleSettings) {
                createCompany({
                    variables: { model: { viewAccessUntil: date ? new Date(date) : null } },
                    optimisticResponse: {
                        createCompanyScheduleSettings: {
                            scheduleSettingsEdge: {
                                node: {
                                    id,
                                    viewAccessUntil: date ? new Date(date) : null,
                                },
                            },
                        },
                    },
                });
            }
            else {
                updateCompany({
                    variables: { model: { viewAccessUntil: date ? new Date(date) : null } },
                    optimisticResponse: {
                        updateCompanyScheduleSettings: {
                            scheduleSettingsEdge: {
                                node: {
                                    id: scheduleSettings === null || scheduleSettings === void 0 ? void 0 : scheduleSettings.id,
                                    viewAccessUntil: date ? new Date(date) : null,
                                },
                            },
                        },
                    },
                });
            }
        }}/>);
};
export default EditCompanyScheduleVisibility;
