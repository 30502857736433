/**
 * @generated SignedSource<<300c25f96ae3b786ae69b4736ff1241b>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SchedulesTableUpdateScheduleMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateSchedulePayload",
                    "kind": "LinkedField",
                    "name": "updateSchedule",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfSchedule",
                            "kind": "LinkedField",
                            "name": "scheduleEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "ScheduleListRow_schedule"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SchedulesTableUpdateScheduleMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateSchedulePayload",
                    "kind": "LinkedField",
                    "name": "updateSchedule",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfSchedule",
                            "kind": "LinkedField",
                            "name": "scheduleEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "currentUserPermissions",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "archived",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "inactivatedFrom",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleTemplateConnection",
                                            "kind": "LinkedField",
                                            "name": "templates",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleTemplateEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleTemplate",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "adbc04c29a4cb9edf484191e7dbe9c66",
            "id": null,
            "metadata": {},
            "name": "SchedulesTableUpdateScheduleMutation",
            "operationKind": "mutation",
            "text": "mutation SchedulesTableUpdateScheduleMutation(\n  $model: UpdateScheduleRequestInput!\n) {\n  updateSchedule(model: $model) {\n    scheduleEdge {\n      node {\n        id\n        name\n        ...ScheduleListRow_schedule\n      }\n    }\n  }\n}\n\nfragment ScheduleListRow_schedule on Schedule {\n  id\n  currentUserPermissions\n  name\n  archived\n  inactivatedFrom\n  templates {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "81982533f44245d0b5676acd82400a5a";
export default node;
