/**
 * @generated SignedSource<<3c1a83fdfe8dad7efdc1a2ff9d8f3310>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./ScheduleTemplateShiftClashesRefetchQuery.graphql'),
                "identifierField": "id"
            }
        },
        "name": "ScheduleTemplateShiftClashes_scheduleTemplate",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTemplateShiftClashGroup",
                "kind": "LinkedField",
                "name": "shiftClashes",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleTemplateShift",
                        "kind": "LinkedField",
                        "name": "clashes",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "assignedEntity",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Asset",
                                                "kind": "LinkedField",
                                                "name": "asset",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "name",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "SchedulableAsset",
                                        "abstractKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "User",
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "args": null,
                                                        "kind": "FragmentSpread",
                                                        "name": "User"
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "firstname",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "lastname",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "SchedulableUser",
                                        "abstractKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ScheduleTemplateShiftClashGroup_scheduleTemplateShiftClashGroup"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ScheduleTemplate",
        "abstractKey": null
    };
})();
node.hash = "47724d0a239ed142b4bd04989f5869e2";
export default node;
