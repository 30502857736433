/**
 * @generated SignedSource<<fa81b25f25a3ab9c822696afe40b585e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ScheduleAssignmentTypesPanel_schedule",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleAssignmentTypeConnection",
                "kind": "LinkedField",
                "name": "assignmentTypes",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleAssignmentType",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "AssignmentType",
                                "kind": "LinkedField",
                                "name": "assignmentType",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "AssignmentTypeChip_assignmentType"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v0 /*: any*/)
        ],
        "type": "Schedule",
        "abstractKey": null
    };
})();
node.hash = "e1e3d2fc843363d8bdf082583335fffb";
export default node;
