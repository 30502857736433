/**
 * @generated SignedSource<<61dee3f9cb929f6dc391ab8ffc383207>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentTypeConnection",
        "kind": "LinkedField",
        "name": "assignmentTypes",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentType",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    },
                    (v2 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "QuerySchedulableEntitySelectDataQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "QuerySchedulableEntitySelectData_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "QuerySchedulableEntitySelectDataQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "SchedulableEntityConnection",
                    "kind": "LinkedField",
                    "name": "schedulableEntities",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableEntityEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Asset",
                                                    "kind": "LinkedField",
                                                    "name": "asset",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        },
                                                        (v1 /*: any*/),
                                                        (v3 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "SchedulableAsset",
                                            "abstractKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "firstname",
                                                            "storageKey": null
                                                        },
                                                        (v1 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Avatar",
                                                            "kind": "LinkedField",
                                                            "name": "avatar",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "storageId",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": [
                                                                        {
                                                                            "kind": "Literal",
                                                                            "name": "queryTransform",
                                                                            "value": "width=68&height=68&mode=crop&scale=both"
                                                                        }
                                                                    ],
                                                                    "kind": "ScalarField",
                                                                    "name": "url",
                                                                    "storageKey": "url(queryTransform:\"width=68&height=68&mode=crop&scale=both\")"
                                                                },
                                                                {
                                                                    "alias": "imageUrl",
                                                                    "args": [
                                                                        {
                                                                            "kind": "Literal",
                                                                            "name": "queryTransform",
                                                                            "value": "width=400&height=400&mode=crop&scale=both"
                                                                        }
                                                                    ],
                                                                    "kind": "ScalarField",
                                                                    "name": "url",
                                                                    "storageKey": "url(queryTransform:\"width=400&height=400&mode=crop&scale=both\")"
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v3 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "SchedulableUser",
                                            "abstractKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "displayName",
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "ISchedulableEntity",
                                            "abstractKey": "__isISchedulableEntity"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v2 /*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "schedulableEntities(first:10)"
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "filters": [
                        "where",
                        "model"
                    ],
                    "handle": "connection",
                    "key": "QuerySchedulableEntitySelectData_query_schedulableEntities",
                    "kind": "LinkedHandle",
                    "name": "schedulableEntities"
                }
            ]
        },
        "params": {
            "cacheID": "79f42fb5c2d8e6fcca17e8d7daeee1fe",
            "id": null,
            "metadata": {},
            "name": "QuerySchedulableEntitySelectDataQuery",
            "operationKind": "query",
            "text": "query QuerySchedulableEntitySelectDataQuery {\n  ...QuerySchedulableEntitySelectData_query\n}\n\nfragment Asset on Asset {\n  name\n  color\n}\n\nfragment QuerySchedulableEntitySelectData_query on Query {\n  schedulableEntities(first: 10) {\n    edges {\n      node {\n        __typename\n        ... on SchedulableAsset {\n          asset {\n            ...Asset\n            assignmentTypes {\n              nodes {\n                title\n                id\n              }\n            }\n            id\n          }\n        }\n        ... on SchedulableUser {\n          user {\n            ...User\n            assignmentTypes {\n              nodes {\n                title\n                id\n              }\n            }\n            id\n          }\n        }\n        ... on ISchedulableEntity {\n          __isISchedulableEntity: __typename\n          id\n          displayName\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment User on User {\n  firstname\n  color\n  avatar {\n    id\n    storageId\n    url(queryTransform: \"width=68&height=68&mode=crop&scale=both\")\n    ...UserImageLightBox\n  }\n}\n\nfragment UserImageLightBox on Avatar {\n  imageUrl: url(queryTransform: \"width=400&height=400&mode=crop&scale=both\")\n}\n"
        }
    };
})();
node.hash = "ec3ee11adb76fa51dc96c2a40d7870dc";
export default node;
