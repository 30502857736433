import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FileList, Flexbox, H4 } from '@sonika-se/ui-kit/components';
import { useApi, usePermissions } from '@sonika-se/ui-kit/hooks';
import { graphql, useFragment } from 'react-relay/hooks';
import useScheduleShiftAttachments from '~/hooks/attachments/useScheduleShiftAttachments';
const ScheduleShiftFiles = (props) => {
    var _a;
    const { scheduleShiftRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftFiles_scheduleShift on ScheduleShift {
        ...useScheduleShiftAttachments_scheduleShift
        schedule {
          currentUserPermissions
        }
      }
    `, scheduleShiftRef);
    const hasPermissions = usePermissions();
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || data.schedule.currentUserPermissions.includes('EDIT_RUNNING'), [data, hasPermissions]);
    const intl = useIntl();
    const { addAttachments, data: attachmentsData, removeAttachment } = useScheduleShiftAttachments(data);
    const { downloadFile } = useApi();
    if (!((_a = attachmentsData.attachments.edges) === null || _a === void 0 ? void 0 : _a.length) && !canEditRunning) {
        return null; // <Placeholder title="Det finns inga filer på det här passet" icon={faFileAlt} />
    }
    return (<Flexbox direction="column" gap="xsmall">
      <H4>Filer</H4>
      <FileList headerText={intl.formatMessage({
            defaultMessage: 'Släpp filer här eller klicka för att välja',
            id: 'ScheduleShiftModal.FileList.header_text',
        })} appearance="compact" defaultFiles={attachmentsData.attachments.edges.map(({ node }) => node)} editable={canEditRunning} onChange={(files) => {
            addAttachments(Array.from(files));
        }} onDelete={(file) => {
            removeAttachment(file);
        }} onDownload={(url, name) => {
            downloadFile(url, name);
        }}/>
    </Flexbox>);
};
export default ScheduleShiftFiles;
