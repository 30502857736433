import React, { Suspense, useContext, useState } from 'react';
import { Flexbox, ModalToSheetSkeleton, MonthInput, H5, Divider, IconButton, ToggleButtonGroup, } from '@sonika-se/ui-kit/components';
import { toDate as toDateFn } from '@sonika-se/ui-kit/utils/time';
import { isSameDay } from 'date-fns';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useIntl } from 'react-intl';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import MonthSelect from '~/pages/SchedulePages/components/MonthSelect/MonthSelect';
import AbsenceCalendarFilter from './AbsenceCalendarFilter';
import AbsenceCalendarPageContext from '../AbsenceCalendarPageContext';
import AddPlannedAbsenceModal from './AddPlannedAbsenceModal';
import SchedulableResourceFilter from '~/components/SchedulableResourceFilter/SchedulableResourceFilter';
import { useQueryLoader } from 'react-relay/hooks';
import { scheduleShiftClashDrawerQuery } from '~/drawers/ScheduleShiftClashDrawer';
import ScheduleShiftClashDrawerAction from '~/actions/ScheduleShiftClashDrawerAction';
export const AbsenceCalendarHeader = (props) => {
    const { onDateChange, onFilterChange, queryRef } = props;
    const { size: pageSize } = useViewport();
    const hasPermissions = usePermissions();
    const { fromDate, toDate, filters } = useContext(AbsenceCalendarPageContext);
    const intl = useIntl();
    const [showAddModal, setShowAddModal] = useState(false);
    const [scheduleShiftClashDrawerQueryRef, loadScheduleShiftClashDrawerQuery, disposeScheduleShiftClashDrawerQuery] = useQueryLoader(scheduleShiftClashDrawerQuery);
    if (pageSize === 'small') {
        return (<Flexbox direction="column">
        <Flexbox style={{ padding: '1rem' }} gap="small" direction="column">
          <H5>Frånvaro</H5>
          <Flexbox justify="space">
            <ToggleButtonGroup type="radio" size="small" defaultValue="USER" options={[
                {
                    label: intl.formatMessage({ defaultMessage: 'Användare', id: 'general.objects.user.name.plural' }),
                    value: 'USER',
                },
                {
                    label: intl.formatMessage({ defaultMessage: 'Enheter', id: 'general.objects.asset.name.plural' }),
                    value: 'ASSET',
                },
            ]} onChange={(event) => {
                var _a;
                onFilterChange({
                    type: (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value,
                    resourceIds: [],
                });
            }}/>
            <Flexbox gap="xsmall">
              <SchedulableResourceFilter updateFilters={(values, key) => onFilterChange({ [key]: values })} title={filters.type === 'USER'
                ? intl.formatMessage({ id: 'general.objects.user.name.plural', defaultMessage: 'Användare' })
                : intl.formatMessage({ id: 'general.objects.asset.name.plural', defaultMessage: 'Enheter' })} type={filters.type} selectedResourceIds={filters.resourceIds}/>
              <AbsenceCalendarFilter queryRef={queryRef} updateFilters={onFilterChange}/>
              <IconButton size="small" appearance="filled" icon={faPlus} onClick={() => setShowAddModal(true)}/>
            </Flexbox>
          </Flexbox>
        </Flexbox>
        <Divider />
        <MonthSelect date={fromDate} onDateChange={(date) => {
                if (!isSameDay(date, fromDate)) {
                    onDateChange(date);
                }
            }}/>
        <Suspense fallback={<ModalToSheetSkeleton />}>
          {showAddModal && <AddPlannedAbsenceModal onClose={() => setShowAddModal(false)}/>}
        </Suspense>
      </Flexbox>);
    }
    return (<Flexbox justify="space">
      <Flexbox gap="medium">
        <ToggleButtonGroup type="radio" defaultValue="USER" options={[
            {
                label: intl.formatMessage({ defaultMessage: 'Användare', id: 'general.objects.user.name.plural' }),
                value: 'USER',
            },
            {
                label: intl.formatMessage({ defaultMessage: 'Enheter', id: 'general.objects.asset.name.plural' }),
                value: 'ASSET',
            },
        ]} onChange={(event) => {
            var _a;
            onFilterChange({
                type: (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value,
                resourceIds: [],
            });
        }}/>
        <MonthInput onUpdate={() => {
            onDateChange(fromDate);
        }} defaultValue={new Date(fromDate)} onChange={(date) => {
            if (!isSameDay(date, fromDate)) {
                onDateChange(date);
            }
        }}/>
      </Flexbox>
      <Flexbox gap="xsmall">
        <ScheduleShiftClashDrawerAction fromDate={toDateFn(fromDate)} toDate={toDateFn(toDate)}/>
        <SchedulableResourceFilter updateFilters={(values, key) => onFilterChange({ [key]: values })} title={filters.type === 'USER'
            ? intl.formatMessage({ id: 'general.objects.user.name.plural', defaultMessage: 'Användare' })
            : intl.formatMessage({ id: 'general.objects.asset.name.plural', defaultMessage: 'Enheter' })} type={filters.type} selectedResourceIds={filters.resourceIds}/>
        <AbsenceCalendarFilter queryRef={queryRef} updateFilters={onFilterChange}/>
        {/* <Button onClick={() => setShowAddModal(true)}>Lägg till frånvaro</Button> */}
      </Flexbox>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {showAddModal && <AddPlannedAbsenceModal onClose={() => setShowAddModal(false)}/>}
      </Suspense>
    </Flexbox>);
};
export default AbsenceCalendarHeader;
