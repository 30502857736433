import React from 'react';
const scheduleTemplateUpdatedEventName = 'scheduleTemplateUpdated';
const scheduleTemplateUpdatedEvent = new Event(scheduleTemplateUpdatedEventName);
export const defaultScheduleTemplateDetailsPagevalues = {
    companyId: null,
    filters: {
        resourceIds: [],
        assignmentTypeIds: [],
        onlyVacant: undefined,
    },
    highlightedShifts: [],
    highlightedSidebar: [],
    showInformationDrawer: false,
    dispatchScheduleTemplateUpdatedEvent: () => window.dispatchEvent(scheduleTemplateUpdatedEvent),
    subscribeToScheduleTemplateUpdatedEvent: (fun) => window.addEventListener(scheduleTemplateUpdatedEventName, fun, false),
    unSubscribeToScheduleTemplateUpdatedEvent: (fun) => window.removeEventListener(scheduleTemplateUpdatedEventName, fun, false),
};
export const ScheduleTemplateDetailsPageContext = React.createContext(defaultScheduleTemplateDetailsPagevalues);
export const ScheduleTemplateDetailsPageContextProvider = ScheduleTemplateDetailsPageContext.Provider;
export default ScheduleTemplateDetailsPageContext;
