import React, { Suspense, useState } from 'react';
import { useToasts } from '@sonika-se/ui-kit/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useMutation, usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { ModalToSheetContent, ModalToSheet, Wizard, Flexbox, Button, ModalToSheetHeader, } from '@sonika-se/ui-kit/components';
import PublishScheduleWizardDate from './components/PublishScheduleWizardDate';
import PublishScheduleWizardPeriod from './components/PublishScheduleWizardPeriod';
import PublishScheduleWizardSummation from './components/PublishScheduleWizardSummation';
import QueuedJobStatus, { queuedJobStatusQuery } from '~/components/QueuedJobStatus';
import PublishScheduleWizardOther from './components/PublishScheduleWizardOther';
import { formatISO } from 'date-fns';
export const publishScheduleModalQuery = graphql `
  query PublishScheduleModalQuery($scheduleTemplateId: ID!) {
    scheduleTemplate(id: $scheduleTemplateId) {
      id
      name
      periods(order: { order: ASC }) {
        edges {
          node {
            id
          }
        }
      }
      ...PublishScheduleWizardPeriod_periods
    }
  }
`;
export const PublishScheduleModal = (props) => {
    var _a, _b, _c, _d, _e;
    const intl = useIntl();
    const { onCancel, preloadedQuery } = props;
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [fetchingDryRunDate, setFetchingDryRunData] = useState(false);
    const { scheduleTemplate } = usePreloadedQuery(publishScheduleModalQuery, preloadedQuery);
    const [data, setData] = useState({
        startDate: undefined,
        endDate: undefined,
        scheduleTemplateId: scheduleTemplate.id,
        startPeriodId: ((_b = (_a = scheduleTemplate === null || scheduleTemplate === void 0 ? void 0 : scheduleTemplate.periods) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length) === 1 ? (_e = (_d = (_c = scheduleTemplate === null || scheduleTemplate === void 0 ? void 0 : scheduleTemplate.periods) === null || _c === void 0 ? void 0 : _c.edges[0]) === null || _d === void 0 ? void 0 : _d.node) === null || _e === void 0 ? void 0 : _e.id : undefined,
        existingShiftsOption: undefined,
    });
    const onSubmit = (formData) => {
        setData(Object.assign(Object.assign({}, (data || {})), formData));
        setCurrentStepIndex(currentStepIndex + 1);
    };
    const { addToast } = useToasts();
    const [enqueuePublishScheduleTemplateJob, isInFlight] = useMutation(graphql `
    mutation PublishScheduleModalEnqueueJobMutation($model: EnqueuePublishScheduleTemplateJobInput!) {
      enqueuePublishScheduleTemplateJob(model: $model) {
        queuedJobMessageId
      }
    }
  `);
    const [queuedJobStatusQueryRef, loadQueuedJobStatusQuery, disposeQueuedJobStatusQuery] = useQueryLoader(queuedJobStatusQuery);
    const [showJobRunning, setShowJobRunning] = useState(false);
    const steps = [
        {
            title: intl.formatMessage({ id: 'general.objects.schedule.fields.date.label', defaultMessage: 'Datum' }),
            render: (props) => (<PublishScheduleWizardDate {...props} onSubmit={(formData) => {
                    onSubmit(formData);
                }}/>),
        },
        {
            title: intl.formatMessage({
                id: 'general.objects.schedule.fields.start_period.label',
                defaultMessage: 'Startperiod',
            }),
            render: (props) => (<PublishScheduleWizardPeriod {...props} scheduleTemplate={scheduleTemplate} onSubmit={(formData) => {
                    onSubmit(formData);
                }}/>),
        },
        {
            title: 'Övrigt',
            render: (props) => (<PublishScheduleWizardOther {...props} onSubmit={(formData) => {
                    onSubmit(formData);
                }}/>),
        },
        {
            title: intl.formatMessage({ id: 'general.misc.summation.name.singular', defaultMessage: 'Summering' }),
            render: (props) => (<PublishScheduleWizardSummation {...props} formData={{
                    startDate: formatISO(data.startDate),
                    endDate: formatISO(data.endDate),
                    scheduleTemplateId: data.scheduleTemplateId,
                    startPeriodId: data.startPeriodId,
                    existingShiftsOption: data.existingShiftsOption,
                }} setLoading={(loading) => setFetchingDryRunData(loading)}/>),
        },
    ];
    return (<ModalToSheet onClose={() => onCancel()}>
      <ModalToSheetHeader onClose={() => onCancel()} closeButtonLabel="Avbryt">
        Tillämpa mall i schema
      </ModalToSheetHeader>
      <Wizard steps={steps} currentStep={currentStepIndex} onStepChange={setCurrentStepIndex} loading={isInFlight}/>

      <ModalToSheetContent>
        {showJobRunning && (<Suspense fallback={null}>
            {queuedJobStatusQueryRef && (<QueuedJobStatus completeMessage="Mallen är tillämpad" preloadedQuery={queuedJobStatusQueryRef}/>)}
          </Suspense>)}
        {!showJobRunning && (<Flexbox gap="xsmall" justify="end">
            <Button disabled={currentStepIndex + 1 !== steps.length || isInFlight || fetchingDryRunDate} onClick={() => {
                enqueuePublishScheduleTemplateJob({
                    onCompleted: ({ enqueuePublishScheduleTemplateJob: { queuedJobMessageId } }) => {
                        loadQueuedJobStatusQuery({
                            messageId: queuedJobMessageId,
                        });
                        setShowJobRunning(true);
                    },
                    onError: () => {
                        addToast(intl.formatMessage({
                            defaultMessage: 'Något gick fel',
                            id: 'ScheduleTemplateDetailsPage.PublishScheduleModal.toast.error',
                        }), { color: 'error' });
                    },
                    variables: {
                        model: {
                            scheduleTemplateId: scheduleTemplate.id,
                            startDate: formatISO(data.startDate),
                            endDate: formatISO(data.endDate),
                            startPeriodId: data.startPeriodId,
                            existingShiftsOption: data.existingShiftsOption,
                        },
                    },
                });
            }}>
              <FormattedMessage id="general.actions.publish" defaultMessage="Tillämpa"/>
            </Button>
          </Flexbox>)}
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default PublishScheduleModal;
