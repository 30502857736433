import React, { useEffect, useState } from 'react';
import { Flexbox, Icon, Text, Tooltip } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useMutation } from 'react-relay/hooks';
import { faExclamationCircle, faExclamationTriangle, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
export const PublishScheduleWizardSummation = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { setLoading, formData } = props;
    const intl = useIntl();
    const [dryRunData, setDryRunData] = useState();
    const [publishScheduleTemplate, isInFlight] = useMutation(graphql `
    mutation PublishScheduleWizardSummationDryRunMutation($model: DryRunPublishScheduleTemplateInput!) {
      dryRunPublishScheduleTemplate(model: $model) {
        validationErrors
        validationWarnings
        numberOfShiftsToCreate
        numberOfShiftsToDelete
        numberOfShiftsWithChangesToDelete
        numberOfRemainingShifts
        numberOfResultingClashes
      }
    }
  `);
    useEffect(() => {
        setLoading(true);
        publishScheduleTemplate({
            variables: {
                model: formData,
            },
            onCompleted: (data) => {
                setDryRunData(data);
                setLoading(false);
            },
        });
    }, []);
    return isInFlight ? (<Icon style={{
            width: '100%',
        }} icon={faSpinner} spin/>) : (<Flexbox direction="column" gap="medium" style={{ paddingTop: '1rem', width: '100%' }}>
      {(_a = dryRunData === null || dryRunData === void 0 ? void 0 : dryRunData.dryRunPublishScheduleTemplate) === null || _a === void 0 ? void 0 : _a.validationErrors.map((message, index) => (<Text key={`validationMessage_${index}`}>
          <Icon fixedWidth={true} color={sonikaTheme.colors.system.stop} icon={faExclamationTriangle}/> {message}
        </Text>))}
      {(_b = dryRunData === null || dryRunData === void 0 ? void 0 : dryRunData.dryRunPublishScheduleTemplate) === null || _b === void 0 ? void 0 : _b.validationWarnings.map((message, index) => (<Text key={`validationMessage_${index}`}>
          <Icon fixedWidth={true} color={sonikaTheme.colors.system.wait} icon={faExclamationCircle}/> {message}
        </Text>))}
      {((_c = dryRunData === null || dryRunData === void 0 ? void 0 : dryRunData.dryRunPublishScheduleTemplate) === null || _c === void 0 ? void 0 : _c.validationErrors.length) === 0 && (<>
          <Text>
            <FormattedMessage id="ScheduleTemplateDetailsPage.PublishScheduleWizardSummation.added_shifts" defaultMessage="Antal pass som läggs till: {number}" values={{ number: (_d = dryRunData === null || dryRunData === void 0 ? void 0 : dryRunData.dryRunPublishScheduleTemplate) === null || _d === void 0 ? void 0 : _d.numberOfShiftsToCreate }}/>
          </Text>
          <Text>
            <FormattedMessage id="ScheduleTemplateDetailsPage.PublishScheduleWizardSummation.removed_shifts" defaultMessage="Antal pass som tas bort: {number}" values={{ number: (_e = dryRunData === null || dryRunData === void 0 ? void 0 : dryRunData.dryRunPublishScheduleTemplate) === null || _e === void 0 ? void 0 : _e.numberOfShiftsToDelete }}/>
          </Text>
          <Text>
            <FormattedMessage id="ScheduleTemplateDetailsPage.PublishScheduleWizardSummation.removedChanged_shifts" defaultMessage="Antal förändrade pass som tas bort: {number}" values={{ number: (_f = dryRunData === null || dryRunData === void 0 ? void 0 : dryRunData.dryRunPublishScheduleTemplate) === null || _f === void 0 ? void 0 : _f.numberOfShiftsWithChangesToDelete }}/>
          </Text>
          <Text>
            <FormattedMessage id="ScheduleTemplateDetailsPage.PublishScheduleWizardSummation.remaining_shifts" defaultMessage="Antal pass som behålls: {number}" values={{ number: (_g = dryRunData === null || dryRunData === void 0 ? void 0 : dryRunData.dryRunPublishScheduleTemplate) === null || _g === void 0 ? void 0 : _g.numberOfRemainingShifts }}/>
          </Text>
          <Text>
            <Tooltip content="Tar ej hänsyn till vakanta pass">
              <FormattedMessage id="ScheduleTemplateDetailsPage.PublishScheduleWizardSummation.resulting_clashes" defaultMessage="Antal dubbelbokningar när mallen är tillämpad: {number}" values={{ number: (_h = dryRunData === null || dryRunData === void 0 ? void 0 : dryRunData.dryRunPublishScheduleTemplate) === null || _h === void 0 ? void 0 : _h.numberOfResultingClashes }}/>
            </Tooltip>
          </Text>
        </>)}
    </Flexbox>);
};
export default PublishScheduleWizardSummation;
