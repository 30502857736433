/**
 * @generated SignedSource<<9b1e2dca3ff68acd58dd405f66a2fb00>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        },
        {
            "defaultValue": 50,
            "kind": "LocalArgument",
            "name": "first"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v2 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
        },
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleTemplateTable_scheduleQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v2 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "ScheduleTemplateTable_schedule"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleTemplateTable_scheduleQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "currentUserPermissions",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "concreteType": "ScheduleTemplateConnection",
                                    "kind": "LinkedField",
                                    "name": "templates",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleTemplateEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleTemplate",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "canEdit",
                                                            "storageKey": null
                                                        },
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": [
                                                                {
                                                                    "kind": "Literal",
                                                                    "name": "order",
                                                                    "value": {
                                                                        "timestampId": "DESC"
                                                                    }
                                                                }
                                                            ],
                                                            "concreteType": "ScheduleTemplatePublicationConnection",
                                                            "kind": "LinkedField",
                                                            "name": "publications",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ScheduleTemplatePublication",
                                                                    "kind": "LinkedField",
                                                                    "name": "nodes",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "User",
                                                                            "kind": "LinkedField",
                                                                            "name": "publishedByUser",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "firstname",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "lastname",
                                                                                    "storageKey": null
                                                                                },
                                                                                (v4 /*: any*/),
                                                                                {
                                                                                    "kind": "InlineFragment",
                                                                                    "selections": [
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "concreteType": "Avatar",
                                                                                            "kind": "LinkedField",
                                                                                            "name": "avatar",
                                                                                            "plural": false,
                                                                                            "selections": [
                                                                                                {
                                                                                                    "alias": null,
                                                                                                    "args": [
                                                                                                        {
                                                                                                            "kind": "Literal",
                                                                                                            "name": "queryTransform",
                                                                                                            "value": "width=300&height=300&mode=crop&scale=both"
                                                                                                        }
                                                                                                    ],
                                                                                                    "kind": "ScalarField",
                                                                                                    "name": "url",
                                                                                                    "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
                                                                                                },
                                                                                                (v4 /*: any*/)
                                                                                            ],
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v6 /*: any*/),
                                                                                                (v5 /*: any*/)
                                                                                            ],
                                                                                            "type": "Asset",
                                                                                            "abstractKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v5 /*: any*/)
                                                                                            ],
                                                                                            "type": "Company",
                                                                                            "abstractKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v6 /*: any*/)
                                                                                            ],
                                                                                            "type": "User",
                                                                                            "abstractKey": null
                                                                                        }
                                                                                    ],
                                                                                    "type": "IHaveAvatar",
                                                                                    "abstractKey": "__isIHaveAvatar"
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "timestampId",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "startDate",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "endDate",
                                                                            "storageKey": null
                                                                        },
                                                                        (v4 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": "publications(order:{\"timestampId\":\"DESC\"})"
                                                        },
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "filters": [],
                                    "handle": "connection",
                                    "key": "ScheduleTemplateTable_schedule_templates",
                                    "kind": "LinkedHandle",
                                    "name": "templates"
                                }
                            ],
                            "type": "Schedule",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "01c04febcea48935a07696ab2484cf29",
            "id": null,
            "metadata": {},
            "name": "ScheduleTemplateTable_scheduleQuery",
            "operationKind": "query",
            "text": "query ScheduleTemplateTable_scheduleQuery(\n  $after: String\n  $first: Int = 50\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ScheduleTemplateTable_schedule_2HEEH6\n    id\n  }\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n\nfragment ScheduleTemplateTable_schedule_2HEEH6 on Schedule {\n  currentUserPermissions\n  id\n  templates(after: $after, first: $first) {\n    edges {\n      node {\n        canEdit\n        id\n        name\n        publications(order: {timestampId: DESC}) {\n          nodes {\n            publishedByUser {\n              ...Avatar_iHaveAvatar\n              firstname\n              lastname\n              id\n            }\n            timestampId\n            startDate\n            endDate\n            id\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "c942960da01eba196cd128a74b04a369";
export default node;
