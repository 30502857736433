import React, { Fragment, Suspense } from 'react';
import { PopoverLink, Notification, ModalToSheetSkeleton } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
import { addDays, differenceInHours, eachDayOfInterval, endOfDay, format, isAfter, isBefore, isSameDay } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { useQueryLoader } from 'react-relay/hooks';
import { max, min, startOfDay } from 'date-fns';
import EditPlannedAbsenceModal, { editPlannedAbsenceModalQuery } from '../EditPlannedAbsenceModal';
import { useGetPlannedAbsences } from '~/hooks/useGetPlannedAbsences';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        gridCell: {
            padding: pxToRem(8),
            textTransform: 'capitalize',
        },
        lightCell: {
            background: 'white',
        },
        darkCell: {
            background: theme.colors.background.primary,
        },
        errorBackground: {
            background: theme.colors.system.stop,
        },
        gridCellItem: {
            borderRadius: pxToRem(4),
            height: pxToRem(14),
            lineHeight: pxToRem(20),
            cursor: 'pointer',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            padding: pxToRem(8),
            margin: `${pxToRem(5)} 0`,
            '&:focus': {
                boxShadow: theme.helpers.shadow(1),
            },
        },
    };
});
export const AbsenceCalendarGridRow = (props) => {
    var _a;
    const { days, onCellClick, queryRef, rowIndex, cellWidth, toDate, fromDate, classes } = props;
    const data = useGetPlannedAbsences(queryRef);
    const [editPlannedAbsenceModalQueryRef, loadEditPlannedAbsenceModalQuery, disposeEditPlannedAbsenceModalQuery] = useQueryLoader(editPlannedAbsenceModalQuery);
    const connectedAbsences = ((_a = data === null || data === void 0 ? void 0 : data.plannedAbsences) === null || _a === void 0 ? void 0 : _a.edges) || [];
    let prevAbsences = [];
    return (<>
      {days.map((day, dayIndex) => {
            const absencesStartingToday = connectedAbsences.filter(({ node: absence }) => isSameDay(max([new Date(absence.start), fromDate]), day));
            const absencesStartingTodayIndexes = absencesStartingToday.map(({ node: absence }) => absence.id);
            let absencesStartingTodayLength = absencesStartingToday === null || absencesStartingToday === void 0 ? void 0 : absencesStartingToday.length;
            const continuedAbsences = prevAbsences.map((absence) => {
                var _a;
                if (absence &&
                    (isSameDay(max([new Date(absence.start), fromDate]), day) ||
                        isSameDay(min([new Date(absence.end), toDate]), day) ||
                        (isBefore(day, min([new Date(absence.end), toDate])) &&
                            isAfter(day, max([new Date(absence.start), fromDate]))))) {
                    return absence;
                }
                else {
                    if (absencesStartingTodayLength > 0) {
                        absencesStartingTodayLength -= 1;
                        return (_a = absencesStartingToday[absencesStartingTodayLength]) === null || _a === void 0 ? void 0 : _a.node;
                    }
                    return null;
                }
            });
            if (absencesStartingTodayLength > 0) {
                // Push the values that didn't fit in the current prevAbsences array
                Array(absencesStartingTodayLength)
                    .fill(null)
                    .forEach((item, index) => {
                    var _a;
                    continuedAbsences.push((_a = absencesStartingToday[index]) === null || _a === void 0 ? void 0 : _a.node);
                });
            }
            prevAbsences = continuedAbsences;
            return (<Fragment key={`${data.id}_${day}`}>
            <div className={[classes.gridCell, dayIndex % 2 === 0 ? classes.darkCell : classes.lightCell].join(' ')} style={{
                    gridRow: rowIndex + 1,
                    gridColumn: dayIndex + 2,
                    cursor: 'pointer',
                }} onClick={() => {
                    onCellClick(data.id, day);
                }}/>
            {continuedAbsences.map((absence, continuedAbsenceIndex) => {
                    var _a, _b, _c;
                    if (!absence || absencesStartingTodayIndexes.indexOf(absence.id) < 0)
                        return null;
                    const start = max([new Date(absence.start), startOfDay(fromDate)]);
                    const end = min([new Date(absence.end), endOfDay(toDate)]);
                    const nrOfCells = (_a = eachDayOfInterval({
                        start,
                        end,
                    })) === null || _a === void 0 ? void 0 : _a.length;
                    return (<div key={`${data.id}_${day}_${absence.id}`} style={{
                            zIndex: 2,
                            height: '2.5rem',
                            gridRow: rowIndex + 1,
                            gridColumnStart: dayIndex + 2,
                            marginTop: `${continuedAbsenceIndex * 2.1875}rem`,
                            gridColumnEnd: dayIndex + 2 + nrOfCells,
                            marginLeft: `${differenceInHours(start, startOfDay(start)) * (cellWidth / 16 / 24)}rem`,
                            marginRight: `${differenceInHours(startOfDay(addDays(end, 1)), end) * (cellWidth / 16 / 24)}rem`,
                        }}>
                  <PopoverLink placement="bottom-start" popover={<Notification title={data.displayName} text={`${absence.reasonEvent.name}, ${format(new Date(absence.start), 'd MMM HH:mm', {
                                locale,
                            }).replace('.', '')} - ${format(new Date(absence.end), 'd MMM HH:mm', {
                                locale,
                            }).replace('.', '')}`} color={((_b = absence.reasonEvent) === null || _b === void 0 ? void 0 : _b.color) || 'danger'}/>}>
                    <div role="button" tabIndex={0} style={{
                            backgroundColor: (_c = absence.reasonEvent) === null || _c === void 0 ? void 0 : _c.color,
                        }} className={[classes.gridCellItem, classes.errorBackground].join(' ')} onClick={() => {
                            loadEditPlannedAbsenceModalQuery({ id: absence.id });
                        }}>
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{absence.reasonEvent.name}</span>
                    </div>
                  </PopoverLink>
                </div>);
                })}
          </Fragment>);
        })}
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {!!editPlannedAbsenceModalQueryRef && (<EditPlannedAbsenceModal preloadedQuery={editPlannedAbsenceModalQueryRef} onClose={() => {
                disposeEditPlannedAbsenceModalQuery();
            }}/>)}
      </Suspense>
    </>);
};
export default AbsenceCalendarGridRow;
