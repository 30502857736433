import React, { useState, Suspense, useContext } from 'react';
import { useFragment, graphql, useMutation, useQueryLoader } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { Chip, ModalToSheetSkeleton } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import SchedulableUser from '../../../SchedulePages/components/SchedulableUser/SchedulableUser';
import SchedulableAsset from '../../../SchedulePages/components/SchedulableAsset/SchedulableAsset';
import EditScheduleTemplateShiftModal, { editScheduleTemplateShiftModalQuery, } from '../EditScheduleTemplateShiftModal/EditScheduleTemplateShiftModal';
import ScheduleTemplateDetailsPageContext from '../../ScheduleTemplateDetailsPageContext';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {},
        scheduledEntity: {
            marginTop: pxToRem(4),
        },
    };
});
export const ScheduleTemplateShift = (props) => {
    var _a, _b;
    const { className, style, updateHighlightedResources } = props;
    const [hover, setHover] = useState(false);
    const intl = useIntl();
    const classes = useStyles();
    const context = useContext(ScheduleTemplateDetailsPageContext);
    const { highlightedShifts } = context;
    const shift = useFragment(graphql `
      fragment ScheduleTemplateShift_shift on ScheduleTemplateShift {
        id
        assignedEntity {
          __typename
          ...SchedulableAsset_entity
          ...SchedulableUser_entity
        }
        assignmentType {
          id
          title
        }
      }
    `, props.shift);
    const allowDrop = (event, editable) => {
        event.preventDefault();
        if (!editable) {
            event.dataTransfer.dropEffect = 'none';
        }
        else {
            event.dataTransfer.dropEffect = 'move';
        }
    };
    const [updateScheduleTemplateShift, updateScheduleTemplateShiftLoading] = useMutation(graphql `
      mutation ScheduleTemplateShiftUpdateScheduleTemplateShiftMutation(
        $model: UpdateScheduleTemplateShiftRequestInput!
      ) {
        updateScheduleTemplateShift(model: $model) {
          shiftEdge {
            node {
              period {
                ...ScheduleTemplatePeriod_period
                id
                scheduleTemplateId
              }
              startTimeOfDay
            }
          }
        }
      }
    `);
    const [editScheduleTemplateShiftModalQueryRef, loadEditScheduleTemplateShiftModalQuery, disposeEditScheduleTemplateShiftModalQuery,] = useQueryLoader(editScheduleTemplateShiftModalQuery);
    return (<>
      <div className={[classes.root, className].join(' ')} onClick={() => {
            if (updateScheduleTemplateShiftLoading)
                return;
            loadEditScheduleTemplateShiftModalQuery({ scheduleTemplateShiftId: shift.id }, { fetchPolicy: 'network-only' });
        }} onDragOver={(event) => {
            setHover(true);
            return allowDrop(event, true);
        }} onDrop={(event) => {
            if (updateScheduleTemplateShiftLoading)
                return;
            // const type = event.dataTransfer.getData('type')
            const assignedEntityId = event.dataTransfer.getData('entityId') === 'null' ? null : event.dataTransfer.getData('entityId');
            const assignmentTypeId = event.dataTransfer.getData('assignmentTypeId');
            if (assignedEntityId && assignmentTypeId !== shift.assignmentType.id) {
                setHover(false);
                return;
            }
            updateScheduleTemplateShift({
                variables: {
                    model: {
                        assignedEntityId,
                        shiftId: shift.id,
                    },
                },
                onCompleted: () => {
                    context.dispatchScheduleTemplateUpdatedEvent();
                },
            });
            setHover(false);
        }} onDragLeave={() => setHover(false)} style={style}>
        <div className={classes.scheduledEntity}>
          {!shift.assignedEntity && (<Chip color={hover ? '#00A15A' : '#D32F2F'} text={intl.formatMessage({
                defaultMessage: 'Vakant',
                id: 'general.misc.schedule_shift.vacant.singular',
            })} specialColor="red-striped" fullWidth checkboxProps={{
                checked: !!(highlightedShifts === null || highlightedShifts === void 0 ? void 0 : highlightedShifts.find((id) => id === shift.id)),
                onChange: (checked) => {
                    if (checked) {
                        updateHighlightedResources([...highlightedShifts, shift.id]);
                    }
                    else {
                        updateHighlightedResources(highlightedShifts.filter((id) => id !== shift.id));
                    }
                },
            }}/>)}
          {((_a = shift.assignedEntity) === null || _a === void 0 ? void 0 : _a.__typename) === 'SchedulableAsset' && (<SchedulableAsset color={hover ? '#00A15A' : null} entity={shift.assignedEntity} checkboxProps={{
                checked: !!(highlightedShifts === null || highlightedShifts === void 0 ? void 0 : highlightedShifts.find((id) => id === shift.id)),
                onChange: (checked) => {
                    if (checked) {
                        updateHighlightedResources([...highlightedShifts, shift.id]);
                    }
                    else {
                        updateHighlightedResources(highlightedShifts.filter((id) => id !== shift.id));
                    }
                },
            }}/>)}
          {((_b = shift.assignedEntity) === null || _b === void 0 ? void 0 : _b.__typename) === 'SchedulableUser' && (<>
              <SchedulableUser color={hover ? '#00A15A' : null} entity={shift.assignedEntity} checkboxProps={{
                checked: !!(highlightedShifts === null || highlightedShifts === void 0 ? void 0 : highlightedShifts.find((id) => id === shift.id)),
                onChange: (checked) => {
                    if (checked) {
                        updateHighlightedResources([...highlightedShifts, shift.id]);
                    }
                    else {
                        updateHighlightedResources(highlightedShifts.filter((id) => id !== shift.id));
                    }
                },
            }}/>
            </>)}
        </div>
      </div>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
        {editScheduleTemplateShiftModalQueryRef && (<EditScheduleTemplateShiftModal onCancel={() => disposeEditScheduleTemplateShiftModalQuery()} onSubmit={() => {
                disposeEditScheduleTemplateShiftModalQuery();
                context.dispatchScheduleTemplateUpdatedEvent();
            }} preloadedQuery={editScheduleTemplateShiftModalQueryRef}/>)}
      </Suspense>
    </>);
};
