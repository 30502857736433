import { useEffect } from 'react';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
import { graphql, useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks';
export const schedulableResourceCheckedNumberQuery = graphql `
  query SchedulableResourceCheckedNumberCheckedNumberQuery($checkedWhere: SchedulableEntityFilterInput) {
    ...SchedulableResourceCheckedNumber_resourcesChecked @arguments(checkedWhere: $checkedWhere)
  }
`;
const SchedulableResourceCheckedNumber = (props) => {
    const { setNumberOfChecked, selectedResourceIds, type } = props;
    const data = useLazyLoadQuery(schedulableResourceCheckedNumberQuery, {
        checkedWhere: { type: { eq: type }, id: { in: selectedResourceIds } },
    });
    const [checkedData, refetchCheckedData] = useRefetchableFragment(graphql `
      fragment SchedulableResourceCheckedNumber_resourcesChecked on Query
      @argumentDefinitions(checkedWhere: { type: "SchedulableEntityFilterInput" })
      @refetchable(queryName: "SchedulableResourceCheckedNumber_resourcesCheckedQuery") {
        checkedNumber: schedulableEntities(where: $checkedWhere) {
          totalCount
        }
      }
    `, data);
    useDidMountEffect(() => {
        refetchCheckedData({
            checkedWhere: { type: { eq: type }, id: { in: selectedResourceIds } },
        }, { fetchPolicy: 'network-only' });
    }, [selectedResourceIds]);
    useEffect(() => {
        var _a;
        setNumberOfChecked((_a = checkedData === null || checkedData === void 0 ? void 0 : checkedData.checkedNumber) === null || _a === void 0 ? void 0 : _a.totalCount);
    }, [checkedData]);
    return null;
};
export default SchedulableResourceCheckedNumber;
