import React, { Fragment } from 'react';
import { Divider } from '@sonika-se/ui-kit/components';
import { startOfDay } from 'date-fns';
import { toDate } from '@sonika-se/ui-kit/utils/time';
import { Flexbox } from '@sonika-se/ui-kit/components';
import Weekday from '~/components/Weekday';
import ScheduleShiftCard from '~/components/ScheduleShiftCard';
import ScheduleShiftAbsenceCard from '~/components/ScheduleShiftAbsenceCard';
import PlannedAbsenceCard from '~/components/PlannedAbsenceCard';
export const MyScheduleList = (props) => {
    const { scheduleItems, skipEmptyDays = false, dates, style, type } = props;
    return (<div style={Object.assign({ display: 'grid', gap: '1rem', gridTemplateColumns: 'auto 1fr', minWidth: 'fit-content', width: '100%', margin: '1rem 0' }, style)}>
      {dates === null || dates === void 0 ? void 0 : dates.map((date, index) => {
            var _a, _b;
            const scheduleShiftsOnDate = (_a = scheduleItems === null || scheduleItems === void 0 ? void 0 : scheduleItems.edges) === null || _a === void 0 ? void 0 : _a.filter(({ node }) => {
                var _a, _b, _c;
                return date >= startOfDay(toDate((node === null || node === void 0 ? void 0 : node.start) || ((_a = node === null || node === void 0 ? void 0 : node.scheduleShift) === null || _a === void 0 ? void 0 : _a.start))) &&
                    date < toDate((node === null || node === void 0 ? void 0 : node.end) || ((_b = node === null || node === void 0 ? void 0 : node.scheduleShift) === null || _b === void 0 ? void 0 : _b.end) || (node === null || node === void 0 ? void 0 : node.start) || ((_c = node === null || node === void 0 ? void 0 : node.scheduleShift) === null || _c === void 0 ? void 0 : _c.start));
            });
            if (skipEmptyDays && scheduleShiftsOnDate.length === 0)
                return <></>;
            return (<React.Fragment key={`date_in_schedule_${index}`}>
            <Weekday date={date}/>
            <div style={{ gridColumn: 2 }}>
              <Flexbox direction="column" gap="xsmall">
                {(_b = scheduleShiftsOnDate === null || scheduleShiftsOnDate === void 0 ? void 0 : scheduleShiftsOnDate.sort((shiftOne, shiftTwo) => new Date(shiftOne.node.start).getTime() -
                    new Date(shiftTwo.node.start).getTime())) === null || _b === void 0 ? void 0 : _b.map((scheduleShift) => {
                    var _a;
                    return (<Fragment key={(_a = scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node) === null || _a === void 0 ? void 0 : _a.id}>
                      {(scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node.__typename) === 'PlannedAbsence' && (<PlannedAbsenceCard date={date} plannedAbsenceRef={scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node}/>)}
                      {(scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node.__typename) === 'ScheduleShift' && (<ScheduleShiftCard date={date} scheduleShiftRef={scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node} type={type}/>)}
                      {(scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node.__typename) === 'ScheduleShiftAssigneeReplacement' && (<ScheduleShiftAbsenceCard date={date} scheduleShiftAssigneeReplacementRef={scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node}/>)}
                    </Fragment>);
                })}
              </Flexbox>
            </div>
            <Divider style={{ gridColumn: 'span 2' }}/>
          </React.Fragment>);
        })}
    </div>);
};
