import React, { Suspense, useState } from 'react';
import { BasicPage, BasicPageHeader, Box, Placeholder, Skeleton, } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import SimultaneousShiftsFilterForm from './components/SimultaneousShiftsFilterForm';
import SimultaneousShiftsResultList from './components/SimultaneousShiftsResultList';
import DetailedPageContent from '~/components/BasicPage/DetailedPageContent';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
export const SimultaneousShiftsPage = () => {
    const intl = useIntl();
    const [filterFormResult, setFilterFormResult] = useState();
    const [isLoading, setIsLoading] = useState(false);
    return (<BasicPage>
      <BasicPageHeader title="Samtidiga pass"/>
      <DetailedPageContent leftColumnPercentageWidth={20} leftColumn={<Box background="lm-background-secondary" padding="medium">
            <SimultaneousShiftsFilterForm onSubmit={(data) => {
                setFilterFormResult(data);
            }} disabled={isLoading} isLoading={isLoading}/>
          </Box>}>
        {!filterFormResult && (<Placeholder icon={faInfoCircle} title="Hitta användare som har pass samtidigt som enheter inom samma schema" text="Denna funktion kan t.ex. användas för att se schemalagda sjödagar"/>)}
        {filterFormResult && (<Suspense fallback={<Box>
                <Skeleton type="loader" height={100}/>
              </Box>}>
            <SimultaneousShiftsResultList onLoading={() => setIsLoading(true)} onResult={() => setIsLoading(false)} queryInput={filterFormResult}/>
          </Suspense>)}
      </DetailedPageContent>
    </BasicPage>);
};
export default SimultaneousShiftsPage;
