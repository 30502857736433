/**
 * @generated SignedSource<<217376b2d7db8873af1c80d920c7bd18>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "schedulesFilter"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "refetch": {
            "connection": null,
            "fragmentPathInResult": [
                "node"
            ],
            "operation": require('./MyScheduleInformationDrawerContent_shiftsQuery.graphql'),
            "identifierField": "id"
        }
    },
    "name": "MyScheduleInformationDrawerContent_shifts",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                },
                {
                    "kind": "Variable",
                    "name": "schedulesFilter",
                    "variableName": "schedulesFilter"
                }
            ],
            "concreteType": "ScheduleTimesPayload",
            "kind": "LinkedField",
            "name": "scheduleTimes",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ScheduleTimesPayload_iHaveScheduleTimes"
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "SchedulableUser",
    "abstractKey": null
};
node.hash = "62229b3c7f3eac9f2e932996d18311d5";
export default node;
