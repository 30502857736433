/**
 * @generated SignedSource<<6ab6fb59e90e05d65c3c876eec3d1e34>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "refetch": {
            "connection": null,
            "fragmentPathInResult": [],
            "operation": require('./ScheduleShiftClashCountBadge_queryQuery.graphql')
        }
    },
    "name": "ScheduleShiftClashCountBadge_query",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "ClashGroup",
            "kind": "LinkedField",
            "name": "currentScheduleShiftClashes",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "clashes",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "72eeb3379bcd0b1515c7aa3d0cf2f7bb";
export default node;
