import React, { useContext, useState } from 'react';
import { Button, Flexbox, ModalToSheet, ModalToSheetContent, ModalToSheetFooter, ModalToSheetHeader, SortableList, } from '@sonika-se/ui-kit/components';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import ScheduleTemplateDetailsPageContext from '~/pages/ScheduleTemplateDetailsPage/ScheduleTemplateDetailsPageContext';
const SortPeriodsModal = (props) => {
    const { onCancel, periodsRef, scheduleTemplateId } = props;
    const periods = useFragment(graphql `
      fragment SortPeriodsModal_scheduleTemplatePeriodConnection on ScheduleTemplatePeriodConnection {
        edges {
          node {
            id
            name
            order
          }
        }
      }
    `, periodsRef);
    const [updatePeriods, updatePeriodsLoading] = useMutation(graphql `
      mutation SortPeriodsModal_updatePeriodsMutation($model: ReorderScheduleTemplatePeriodsRequestInput!) {
        reorderScheduleTemplatePeriods(model: $model) {
          templateEdge {
            node {
              id
              name
              periods(first: null, order: { order: ASC }) @connection(key: "ScheduleTemplate_periods") {
                edges {
                  node {
                    id
                    ...ScheduleTemplatePeriod_period
                  }
                }
              }
            }
          }
        }
      }
    `);
    const [sortedPeriods, setSortedPeriods] = useState();
    const context = useContext(ScheduleTemplateDetailsPageContext);
    return (<ModalToSheet onClose={() => onCancel()}>
      <ModalToSheetHeader closeButtonLabel="Avbryt" onClose={() => onCancel()}>
        <FormattedMessage defaultMessage="Sortera perioder" id="SortPeriodsModal.title"/>
      </ModalToSheetHeader>
      <ModalToSheetContent style={{ background: '#F8F9FA' }}>
        <SortableList items={periods.edges.map(({ node }) => ({
            key: node.id,
            text: node.name,
        }))} onChange={(items) => {
            setSortedPeriods(items);
        }}/>
      </ModalToSheetContent>
      <ModalToSheetFooter>
        <Flexbox align="end" gap="small">
          <Button appearance="outlined" onClick={() => onCancel()}>
            <FormattedMessage id="SortPeriodsModal.cancelButtonText" defaultMessage="Avbryt"/>
          </Button>
          <Button disabled={!sortedPeriods || updatePeriodsLoading} onClick={() => {
            updatePeriods({
                onCompleted: () => {
                    context.dispatchScheduleTemplateUpdatedEvent();
                    onCancel();
                },
                variables: {
                    model: {
                        scheduleTemplateId,
                        newOrder: sortedPeriods.map((item, index) => ({
                            order: index,
                            id: item.key,
                        })),
                    },
                },
            });
        }}>
            <FormattedMessage id="SortPeriodsModal.saveButtonText" defaultMessage="Spara"/>
          </Button>
        </Flexbox>
      </ModalToSheetFooter>
    </ModalToSheet>);
};
export default SortPeriodsModal;
