import React, { Suspense, useContext, useRef, useState } from 'react';
import { graphql, useFragment, useMutation, useQueryLoader } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Confirm, Divider, MenuList, MenuListItem, Popover, Skeleton, ModalToSheetSkeleton, Flexbox, Icon, } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import EditScheduleTemplatePeriodModal, { editScheduleTemplatePeriodModalQuery, } from '../EditScheduleTemplatePeriodModal';
import ScheduleTemplatePeriodDay from '../ScheduleTemplatePeriodDay/ScheduleTemplatePeriodDay';
import ScheduleTemplateDetailsPageContext from '../../ScheduleTemplateDetailsPageContext';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            gridColumn: '1 / -1',
            position: 'relative',
            '&:not(:first-child)': {
                borderTop: `${pxToRem(1)} solid #DADADA`,
                paddingTop: pxToRem(8),
                marginTop: pxToRem(8),
            },
        },
        periodName: {
            cursor: 'pointer',
            left: 0,
            position: 'sticky',
            display: 'inline-block',
            '&:hover': {
                color: theme.colors.primary,
            },
        },
    };
});
export const ScheduleTemplatePeriod = (props) => {
    const classes = useStyles();
    const period = useFragment(graphql `
      fragment ScheduleTemplatePeriod_period on ScheduleTemplatePeriod {
        id
        days {
          edges {
            node {
              id
              weekday
              ...ScheduleTemplatePeriodDay_day
            }
          }
        }
        name
        scheduleTemplateId
      }
    `, props.period);
    const [copyScheduleTemplatePeriodMutation] = useMutation(graphql `
      mutation ScheduleTemplatePeriod_cloneScheduleTemplatePeriodMutation(
        $model: CloneScheduleTemplatePeriodRequestInput!
      ) {
        cloneScheduleTemplatePeriod(model: $model) {
          periodEdge {
            node {
              ...ScheduleTemplatePeriod_period
              scheduleTemplate {
                id
                periods(first: null, order: { order: ASC }) @connection(key: "ScheduleTemplate_periods") {
                  edges {
                    node {
                      id
                      ...ScheduleTemplatePeriod_period
                    }
                  }
                  totalCount
                }
              }
            }
          }
        }
      }
    `);
    const [deleteScheduleTemplatePeriod] = useMutation(graphql `
      mutation ScheduleTemplatePeriodModalDeleteMutation($model: DeleteScheduleTemplatePeriodRequestInput!) {
        deleteScheduleTemplatePeriod(model: $model) {
          periodEdge {
            node {
              id
              scheduleTemplate {
                id
                periods(first: null, order: { order: ASC }) @connection(key: "ScheduleTemplate_periods") {
                  edges {
                    node {
                      id
                      ...ScheduleTemplatePeriod_period
                    }
                  }
                  totalCount
                }
              }
            }
          }
        }
      }
    `);
    const intl = useIntl();
    const [editScheduleTemplatePeriodModalQueryRef, loadEditScheduleTemplatePeriodModalQuery, disposeEditScheduleTemplatePeriodModalQuery,] = useQueryLoader(editScheduleTemplatePeriodModalQuery);
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const context = useContext(ScheduleTemplateDetailsPageContext);
    const actionsPopoverAnchorRef = useRef();
    return (<>
      <div className={classes.root}>
        <div className={classes.periodName} onClick={() => {
            setContextMenuVisible(!contextMenuVisible);
        }} ref={actionsPopoverAnchorRef}>
          <Flexbox gap="xsmall">
            <span>{period.name}</span>
            <Icon icon={faChevronDown}/>
          </Flexbox>
          <Popover anchorEl={contextMenuVisible ? actionsPopoverAnchorRef.current : undefined} placement="bottom-start" onClose={() => {
            setContextMenuVisible(false);
        }}>
            <MenuList>
              <MenuListItem label={intl.formatMessage({
            defaultMessage: 'Byt namn på period',
            id: 'general.misc.period.actions.update_name',
        })} onClick={() => {
            loadEditScheduleTemplatePeriodModalQuery({
                scheduleTemplatePeriod: period.id,
            });
            setContextMenuVisible(false);
        }}/>
              <Divider />
              <MenuListItem label={intl.formatMessage({
            defaultMessage: 'Duplicera period',
            id: 'general.misc.period.actions.duplicate',
        })} onClick={() => {
            copyScheduleTemplatePeriodMutation({
                configs: [
                    {
                        connectionInfo: [
                            {
                                key: 'ScheduleTemplate_periods',
                                rangeBehavior: 'append',
                            },
                        ],
                        edgeName: 'periodEdge',
                        parentID: period.scheduleTemplateId,
                        type: 'RANGE_ADD',
                    },
                ],
                onCompleted: (response) => {
                    context.dispatchScheduleTemplateUpdatedEvent();
                },
                onError: (error) => {
                    console.log({ error });
                },
                variables: {
                    model: {
                        id: period.id,
                        name: intl.formatMessage({
                            defaultMessage: 'Kopia av {name}',
                            id: 'general.misc.period.fields.name_copy.label',
                        }, { name: period.name }),
                    },
                },
            });
            setContextMenuVisible(false);
        }}/>
              <Divider />
              <MenuListItem label={intl.formatMessage({
            defaultMessage: 'Ta bort period',
            id: 'general.misc.period.actions.delete',
        })} onClick={() => {
            setConfirmDeleteVisible(true);
            setContextMenuVisible(false);
        }}/>
            </MenuList>
          </Popover>
        </div>
      </div>
      {period.days.edges.map(({ node: day }) => (<Suspense fallback={<Skeleton height={200}/>} key={day.id}>
          <ScheduleTemplatePeriodDay day={day} updateHighlightedResources={props.updateHighlightedResources}/>
        </Suspense>))}
      <Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
        {editScheduleTemplatePeriodModalQueryRef && (<EditScheduleTemplatePeriodModal preloadedQuery={editScheduleTemplatePeriodModalQueryRef} onClose={() => disposeEditScheduleTemplatePeriodModalQuery()} onSubmit={() => disposeEditScheduleTemplatePeriodModalQuery()}/>)}
      </Suspense>
      {confirmDeleteVisible && (<Confirm appearance="danger" headerText={intl.formatMessage({
                defaultMessage: 'Är du säker på att du vill ta bort perioden?',
                id: 'general.misc.period.actions.delete.confirm_message',
            })} onCancel={() => setConfirmDeleteVisible(false)} confirmButton={{
                onConfirm: () => {
                    deleteScheduleTemplatePeriod({
                        configs: [
                            {
                                connectionKeys: [
                                    {
                                        key: 'ScheduleTemplate_periods',
                                    },
                                ],
                                parentID: period.scheduleTemplateId,
                                type: 'RANGE_DELETE',
                                deletedIDFieldName: ['periodEdge', 'node'],
                                pathToConnection: ['node', 'periods'],
                            },
                        ],
                        onCompleted: (response) => {
                            context.dispatchScheduleTemplateUpdatedEvent();
                        },
                        onError: (error) => {
                            console.log({ error });
                        },
                        variables: {
                            model: {
                                id: period.id,
                            },
                        },
                    });
                },
                text: intl.formatMessage({
                    defaultMessage: 'Ta bort period',
                    id: 'general.misc.period.actions.delete',
                }),
            }}/>)}
    </>);
};
export default ScheduleTemplatePeriod;
