import React, { Suspense, useContext, useState } from 'react';
import { Divider, Drawer, DrawerContent, DrawerHeader, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, usePreloadedQuery } from 'react-relay/hooks';
import { toDate } from '@sonika-se/ui-kit/utils/time';
import { formatISO } from 'date-fns';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import ScheduleShiftClashQueryForm from '~/forms/ScheduleShiftClashQueryForm';
import ScheduleShiftClashGroupList from '~/lists/ScheduleShiftClashGroupList';
import RunningSchedulesPageContext from '~/pages/SchedulePages/RunningSchedulesPage/RunningSchedulesPageContext';
export const scheduleShiftClashDrawerQuery = graphql `
  query ScheduleShiftClashDrawerQuery($scheduleShiftClashGroupListModel: GetRunningScheduleRequestInput!) {
    ...ScheduleShiftClashGroupList_query @arguments(model: $scheduleShiftClashGroupListModel)
  }
`;
export const ScheduleShiftClashDrawer = (props) => {
    const { onClose, preloadedQuery } = props;
    const data = usePreloadedQuery(scheduleShiftClashDrawerQuery, preloadedQuery);
    const context = useContext(RunningSchedulesPageContext);
    const [scheduleShiftClashGroupListProps, setScheduleShiftClashGroupListProps] = useState();
    return (<Drawer onClose={onClose} open>
      <DrawerHeader title="Dubbelbokningar"/>
      <div style={{ padding: '1rem 1.5rem' }}>
        <ScheduleShiftClashQueryForm defaultValues={{
            interval: {
                fromDate: toDate(context.fromDate),
                toDate: toDate(context.toDate),
            },
        }} onSubmit={(form) => {
            setScheduleShiftClashGroupListProps({
                model: {
                    fromDate: formatISO(form.interval.fromDate),
                    toDate: formatISO(form.interval.toDate),
                },
            });
        }}/>
      </div>
      <Divider />
      <DrawerContent style={{ backgroundColor: sonikaTheme.colors.background.primary, flex: 1 }}>
        <Suspense fallback={<Skeleton height="100%" type="table"/>}>
          <ScheduleShiftClashGroupList {...scheduleShiftClashGroupListProps} queryRef={data}/>
        </Suspense>
      </DrawerContent>
    </Drawer>);
};
export default ScheduleShiftClashDrawer;
