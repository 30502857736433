/**
 * @generated SignedSource<<cb91be8b06c45772ad1a7c908b3134bd>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleShiftClashList_clashGroup",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "clashes",
            "plural": true,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftClashListItem_iCanBeUsedToCalculateScheduleTimesConnection"
                },
                {
                    "kind": "InlineFragment",
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "ClashGroup",
    "abstractKey": null
};
node.hash = "57876a1da7ace8ac366a4cff275d1b3c";
export default node;
