/**
 * @generated SignedSource<<5fc1fe33d5acdb72ed4a86d25f4e3d44>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentTypeConnection",
        "kind": "LinkedField",
        "name": "assignmentTypes",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentType",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    },
                    (v2 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v5 = [
        (v4 /*: any*/)
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assignmentTypeId",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "reasonEvent",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v11 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "MINUTE"
        }
    ], v12 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
        }
    ], v13 = {
        "alias": null,
        "args": null,
        "concreteType": "PlannedAbsenceTime",
        "kind": "LinkedField",
        "name": "times",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weekday",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTimeSpanPeriod",
                "kind": "LinkedField",
                "name": "period",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": (v11 /*: any*/),
                        "concreteType": "TimeSpanUnitValue",
                        "kind": "LinkedField",
                        "name": "start",
                        "plural": false,
                        "selections": (v12 /*: any*/),
                        "storageKey": "start(unit:\"MINUTE\")"
                    },
                    {
                        "alias": null,
                        "args": (v11 /*: any*/),
                        "concreteType": "TimeSpanUnitValue",
                        "kind": "LinkedField",
                        "name": "end",
                        "plural": false,
                        "selections": (v12 /*: any*/),
                        "storageKey": "end(unit:\"MINUTE\")"
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v15 = [
        (v4 /*: any*/),
        (v2 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditPlannedAbsenceModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "assignedEntity",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": (v5 /*: any*/),
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "SchedulableUser",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Asset",
                                                            "kind": "LinkedField",
                                                            "name": "asset",
                                                            "plural": false,
                                                            "selections": (v5 /*: any*/),
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "SchedulableAsset",
                                                    "abstractKey": null
                                                }
                                            ],
                                            "type": "ISchedulableEntity",
                                            "abstractKey": "__isISchedulableEntity"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v13 /*: any*/)
                            ],
                            "type": "PlannedAbsence",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditPlannedAbsenceModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v14 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "assignedEntity",
                                    "plural": false,
                                    "selections": [
                                        (v14 /*: any*/),
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": (v15 /*: any*/),
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "SchedulableUser",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Asset",
                                                            "kind": "LinkedField",
                                                            "name": "asset",
                                                            "plural": false,
                                                            "selections": (v15 /*: any*/),
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "SchedulableAsset",
                                                    "abstractKey": null
                                                }
                                            ],
                                            "type": "ISchedulableEntity",
                                            "abstractKey": "__isISchedulableEntity"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v2 /*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v13 /*: any*/)
                            ],
                            "type": "PlannedAbsence",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "9596125db3f9cc0cf4de694ef3ec0521",
            "id": null,
            "metadata": {},
            "name": "EditPlannedAbsenceModalQuery",
            "operationKind": "query",
            "text": "query EditPlannedAbsenceModalQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on PlannedAbsence {\n      id\n      assignedEntity {\n        __typename\n        ... on ISchedulableEntity {\n          __isISchedulableEntity: __typename\n          id\n          displayName\n          ... on SchedulableUser {\n            user {\n              assignmentTypes {\n                nodes {\n                  title\n                  id\n                }\n              }\n              id\n            }\n          }\n          ... on SchedulableAsset {\n            asset {\n              assignmentTypes {\n                nodes {\n                  title\n                  id\n                }\n              }\n              id\n            }\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      assignmentTypeId\n      description\n      end\n      reasonEvent {\n        id\n        name\n      }\n      start\n      times {\n        weekday\n        period {\n          start(unit: MINUTE) {\n            value\n            unit\n          }\n          end(unit: MINUTE) {\n            value\n            unit\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "d22fcb2f57d25086274866d9acc34990";
export default node;
