import React, { Suspense } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { graphql } from 'react-relay';
import { useMutation, useFragment, usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { Button, Field, Form, PopoverToSheetContent, PopoverToSheetFooter, MultiSelect, Notification, Skeleton, } from '@sonika-se/ui-kit/components';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { useIntl } from 'react-intl';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
import EventField from '~/components/EventField';
import AssetProficiencyValidationNotification from '~/components/AssetProficiencyValidationNotification';
export const potentionalClashesQuery = graphql `
  query UpdateAssignedEntityOnShiftPotentionalClashesQuery(
    $model: PotentialScheduleShiftClashRequestInput!
    $assetValidationModel: PotentialAssetValidationsInput!
  ) {
    potentialScheduleShiftClash(model: $model) {
      ... on ClashGroup {
        clashes {
          end
          start
        }
      }
    }
    potentialAssetValidations(model: $assetValidationModel) {
      ...AssetProficiencyValidationNotification_scheduleShiftAssetValidation
      schedulableAsset {
        asset {
          validateProficiency
        }
      }
      assetProficiency {
        isValid
      }
    }
  }
`;
const defaultValues = {
    newResourceId: '',
    reason: '',
};
const validationSchema = object({
    newResourceId: string().required(),
    reason: string().notRequired(),
});
const PotentialScheduleShiftClashes = (props) => {
    var _a, _b;
    const { preloadedQuery } = props;
    const { potentialScheduleShiftClash, potentialAssetValidations } = usePreloadedQuery(potentionalClashesQuery, preloadedQuery);
    const intl = useIntl();
    //if (!potentialScheduleShiftClash[0] || !potentialScheduleShiftClash[0]?.clashes?.length) return null
    return (<>
      {potentialScheduleShiftClash != null &&
            potentialScheduleShiftClash[0] != null &&
            ((_b = (_a = potentialScheduleShiftClash[0]) === null || _a === void 0 ? void 0 : _a.clashes) === null || _b === void 0 ? void 0 : _b.length) > 0 && (<Notification color="error" shadow={false} icon={faExclamationTriangle} title={intl.formatMessage({
                id: 'PotentialScheduleShiftClashes.title',
                defaultMessage: 'Dubbelbokning',
            })} text={intl.formatMessage({
                id: 'PotentialScheduleShiftClashes.text',
                defaultMessage: 'Ersättaren är schemalagd den här tiden',
            })}/>)}
      {potentialAssetValidations
            .filter((x) => x.schedulableAsset.asset.validateProficiency)
            .filter((x) => !x.assetProficiency.isValid)
            .map((x, index) => (<React.Fragment key={`proficiency-${index}`}>
            <AssetProficiencyValidationNotification parent={x}/>
          </React.Fragment>))}
    </>);
};
export const UpdateAssignedEntityOnShift = (props) => {
    var _a, _b, _c, _d;
    const { onSubmit } = props;
    const intl = useIntl();
    const scheduleShift = useFragment(graphql `
      fragment UpdateAssignedEntityOnShift_scheduleShift on ScheduleShift {
        id
        assignedEntityId
        start
        end
        assignmentTypeId
        assignmentType {
          title
        }
        schedule {
          assignmentTypes {
            edges {
              node {
                id
                assignmentTypeId
                resources {
                  edges {
                    node {
                      schedulableEntity {
                        ... on ISchedulableEntity {
                          id
                          displayName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `, props.scheduleShift);
    const [updateScheduleShiftAssignee, mutationLoading] = useMutation(graphql `
      mutation UpdateAssignedEntityOnShiftMutation($model: UpdateScheduleShiftAssigneeRequestInput!) {
        updateScheduleShiftAssignee(model: $model) {
          scheduleShiftHistoryAssigneeChangeEdge {
            node {
              id
              scheduleShift {
                ...ScheduleShift_shift
                ...ScheduleShiftInformationTab_ScheduleShift
                ...ScheduleShiftHistory_scheduleShift
              }
              ...ScheduleShiftHistoryAssigneeChange_history
            }
          }
        }
      }
    `);
    const { control, formState: { errors }, handleSubmit, watch, } = useForm({
        defaultValues: Object.assign({}, defaultValues),
        resolver: yupResolver(validationSchema),
    });
    const assignedEntityId = watch('newResourceId');
    const [potentionalClashesQueryRef, loadPotentionalClashesQuery] = useQueryLoader(potentionalClashesQuery);
    useDidMountEffect(() => {
        if (assignedEntityId) {
            loadPotentionalClashesQuery({
                model: {
                    start: scheduleShift.start,
                    end: scheduleShift.end,
                    assignedEntityId: assignedEntityId,
                },
                assetValidationModel: {
                    shiftId: scheduleShift.id,
                    newAssignedEntityId: assignedEntityId,
                },
            });
        }
    }, [assignedEntityId]);
    const schedulableEntities = scheduleShift.schedule.assignmentTypes.edges
        .find(({ node: assignmentType }) => assignmentType.assignmentTypeId === scheduleShift.assignmentTypeId)
        .node.resources.edges.map(({ node }) => node.schedulableEntity);
    return (<>
      <Suspense fallback={<Skeleton type="form" height="20vh"/>}>
        <PopoverToSheetContent maxHeight="unset">
          <Form id="removeAssigneeForm" onSubmit={handleSubmit((data) => {
            console.log(data);
            updateScheduleShiftAssignee({
                onCompleted: () => {
                    onSubmit();
                },
                variables: {
                    model: {
                        reasonEventId: data.reason === '' ? undefined : data.reason,
                        shiftId: scheduleShift.id,
                        newAssignedEntityId: data.newResourceId,
                    },
                },
            });
        })}>
            {(scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.assignedEntityId) && (<Field error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.reason) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} label={intl.formatMessage({
                defaultMessage: 'Anledning',
                id: 'UpdateAssignedEntityOnShift.fields.reason.label',
            })}>
                <Controller control={control} name="reason" render={({ field }) => {
                var _a, _b;
                return (<EventField error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.reason) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} disabled={mutationLoading} onChange={(value) => field.onChange(value ? value[0] : undefined)} placeholder={intl.formatMessage({
                        defaultMessage: 'Välj anledning',
                        id: 'UpdateAssignedEntityOnShift.fields.reason.placeholder',
                    })} where={{ isAssigneeReplacementReason: { eq: true } }} assignmentTypeId={scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.assignmentTypeId} defaultValue={field.value}/>);
            }}/>
              </Field>)}
            <Field label={intl.formatMessage({
            defaultMessage: 'Ersättare',
            id: 'UpdateAssignedEntityOnShift.fields.new_resource_id.label',
        })} error={(_d = (_c = errors === null || errors === void 0 ? void 0 : errors.newResourceId) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString()}>
              <Controller control={control} name="newResourceId" render={({ field }) => {
            var _a, _b;
            return (<MultiSelect error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.newResourceId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={(value) => field.onChange(value ? value[0] : undefined)} options={schedulableEntities.map((entity) => ({
                    label: entity.displayName,
                    value: entity.id,
                }))} placeholder={intl.formatMessage({
                    defaultMessage: 'Välj ersättare',
                    id: 'UpdateAssignedEntityOnShift.fields.new_resource_id.placeholder',
                })} defaultValue={field.value}/>);
        }}/>
            </Field>
            <Suspense fallback={null}>
              {potentionalClashesQueryRef && (<PotentialScheduleShiftClashes preloadedQuery={potentionalClashesQueryRef}/>)}
            </Suspense>
          </Form>
        </PopoverToSheetContent>
        <PopoverToSheetFooter style={{
            display: 'flex',
            justifyContent: 'flex-end',
        }}>
          <Button disabled={mutationLoading} form="removeAssigneeForm" type="submit">
            Spara
          </Button>
        </PopoverToSheetFooter>
      </Suspense>
    </>);
};
