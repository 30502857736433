/**
 * @generated SignedSource<<922104794391c879dd20c99a631c7611>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "kind": "InlineFragment",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
            }
        ],
        "type": "ISchedulableEntity",
        "abstractKey": "__isISchedulableEntity"
    }, v2 = {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "assignedEntity",
        "plural": false,
        "selections": [
            (v1 /*: any*/),
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Asset",
                        "kind": "LinkedField",
                        "name": "asset",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "assetNumber",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "type": "SchedulableAsset",
                "abstractKey": null
            }
        ],
        "storageKey": null
    }, v3 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "HOUR"
        }
    ], v4 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        }
    ], v5 = [
        (v0 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        }
    ], v6 = [
        (v0 /*: any*/),
        (v2 /*: any*/),
        {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": (v5 /*: any*/),
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "schedule",
            "plural": false,
            "selections": (v5 /*: any*/),
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
        }
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "model"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [],
                "operation": require('./SimultaneousShiftsResultListRefetchQuery.graphql')
            }
        },
        "name": "SimultaneousShiftsResultListFragment",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "model",
                        "variableName": "model"
                    }
                ],
                "concreteType": "FindSimultaneousSheduleShiftsPayload",
                "kind": "LinkedField",
                "name": "findSimultaneousSheduleShifts",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "schedulableEntity",
                        "plural": false,
                        "selections": [
                            {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "DisplaySchedulableEntity"
                            },
                            (v1 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignedEntityWithAssignedSimultaneousDuration",
                        "kind": "LinkedField",
                        "name": "simultaneousAssignedEntities",
                        "plural": true,
                        "selections": [
                            (v2 /*: any*/),
                            {
                                "alias": null,
                                "args": (v3 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "simultaneousAssignedDurationWithoutUnpaidBreaks",
                                "plural": false,
                                "selections": (v4 /*: any*/),
                                "storageKey": "simultaneousAssignedDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
                            },
                            {
                                "alias": null,
                                "args": (v3 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "simultaneousAssignedDurationIncludingBreaks",
                                "plural": false,
                                "selections": (v4 /*: any*/),
                                "storageKey": "simultaneousAssignedDurationIncludingBreaks(unit:\"HOUR\")"
                            },
                            {
                                "alias": null,
                                "args": (v3 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "assignedEntityShiftsDurationIncludingBreaks",
                                "plural": false,
                                "selections": (v4 /*: any*/),
                                "storageKey": "assignedEntityShiftsDurationIncludingBreaks(unit:\"HOUR\")"
                            },
                            {
                                "alias": null,
                                "args": (v3 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "assignedEntityShiftsDurationWithoutUnpaidBreaks",
                                "plural": false,
                                "selections": (v4 /*: any*/),
                                "storageKey": "assignedEntityShiftsDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
                            },
                            {
                                "alias": null,
                                "args": (v3 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "assignedSourceShiftDurationIncludingBreaks",
                                "plural": false,
                                "selections": (v4 /*: any*/),
                                "storageKey": "assignedSourceShiftDurationIncludingBreaks(unit:\"HOUR\")"
                            },
                            {
                                "alias": null,
                                "args": (v3 /*: any*/),
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "assignedSourceShiftDurationWithoutUnpaidBreaks",
                                "plural": false,
                                "selections": (v4 /*: any*/),
                                "storageKey": "assignedSourceShiftDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "simultaneousAssignedDaysCount",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "simultaneousAssignedDates",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "assignedSourceDates",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "assignedSourceDays",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "assignedEntityDates",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "assignedEntityDays",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleShiftAndAssignedEntityWithSimultaneousScheduleShifts",
                                "kind": "LinkedField",
                                "name": "simultaneousShifts",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ScheduleShift",
                                        "kind": "LinkedField",
                                        "name": "simultaneousShifts",
                                        "plural": true,
                                        "selections": (v6 /*: any*/),
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ScheduleShift",
                                        "kind": "LinkedField",
                                        "name": "shift",
                                        "plural": false,
                                        "selections": (v6 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "24d5addd6fa3a01058ee82955c6b668b";
export default node;
