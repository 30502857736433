/**
 * @generated SignedSource<<a9605971dcb22cf547da4320ead1dacc>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "scheduleId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assignmentTypeId",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleAssignmentTypesUpdateModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleAssignmentTypeConnection",
                            "kind": "LinkedField",
                            "name": "assignmentTypes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleAssignmentType",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleAssignmentTypesUpdateModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleAssignmentTypeConnection",
                            "kind": "LinkedField",
                            "name": "assignmentTypes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleAssignmentType",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "25b1775fad2c3cb5a1b4715ba06113e1",
            "id": null,
            "metadata": {},
            "name": "ScheduleAssignmentTypesUpdateModalQuery",
            "operationKind": "query",
            "text": "query ScheduleAssignmentTypesUpdateModalQuery(\n  $scheduleId: ID!\n) {\n  schedule(id: $scheduleId) {\n    id\n    assignmentTypes {\n      nodes {\n        assignmentTypeId\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "2f0573c02cc8d3a859f09cf9e7f5e842";
export default node;
