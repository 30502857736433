import React, { Suspense, useState } from 'react';
import { graphql, useLazyLoadQuery, useQueryLoader, useRefetchableFragment } from 'react-relay/hooks';
import { Skeleton, Button, Flexbox, ModalToSheetSkeleton, BasicPage, BasicPageHeader, } from '@sonika-se/ui-kit/components';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import SchedulesTable from './SchedulesTable';
import { NewScheduleTemplateModal, newScheduleTemplateModalQuery } from './NewScheduleTemplateModal';
import ScheduleSortModal, { scheduleSortModalQuery } from '../../../modals/ScheduleSortModal';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
export const scheduleListQuery = graphql `
  query ScheduleListPageQuery {
    ...ScheduleListPage_query
  }
`;
const useStyles = createUseStyles((theme) => ({
    headerContainer: {
        justifyContent: 'space-between',
    },
}));
export const ScheduleListPage = () => {
    const intl = useIntl();
    const classes = useStyles();
    const [showScheduleCreateModal, setShowScheduleCreateModal] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState('');
    const [scheduleSortModalQueryRef, loadScheduleSortModalQuery, disposeScheduleSortModalQuery] = useQueryLoader(scheduleSortModalQuery);
    const [newScheduleTemplateModalQueryRef, loadNewScheduleTemplateModalQuery, disposeNewScheduleTemplateModalQuery] = useQueryLoader(newScheduleTemplateModalQuery);
    const data = useLazyLoadQuery(scheduleListQuery, {});
    const [fragment, refetch] = useRefetchableFragment(graphql `
      fragment ScheduleListPage_query on Query @refetchable(queryName: "ScheduleListPage_queryQuery") {
        ...SchedulesTable_query
      }
    `, data);
    const navigate = useNavigate();
    const hasPermissions = usePermissions();
    return (<>
      <BasicPage width="large">
        <BasicPageHeader className={classes.headerContainer} action={hasPermissions(['EDIT_SCHEDULES']) && (<Flexbox gap="xsmall">
                <Button appearance="outlined" onClick={() => {
                loadScheduleSortModalQuery({}, {
                    fetchPolicy: 'network-only',
                });
            }}>
                  <FormattedMessage id="ScheduleListPage.sortButtonText" defaultMessage="Sortera"/>
                </Button>
                <Button onClick={() => setShowScheduleCreateModal(true)}>
                  <FormattedMessage id="general.objects.schedule.actions.create" defaultMessage="Nytt schema"/>
                </Button>
              </Flexbox>)} title={intl.formatMessage({
            defaultMessage: 'Schemamallar',
            id: 'ScheduleListPage.header',
        })}/>
        <div>
          <Suspense fallback={<Skeleton height="20vh" type="table"/>}>
            <SchedulesTable queryRef={fragment} setShowNewScheduleModal={setShowScheduleCreateModal} loadNewTemplateModal={(id) => {
            loadNewScheduleTemplateModalQuery({});
            setSelectedSchedule(id);
        }}/>
          </Suspense>
        </div>
      </BasicPage>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh" type="form"/>}>
        {showScheduleCreateModal && <></>}
        {newScheduleTemplateModalQueryRef && (<NewScheduleTemplateModal defaultScheduleId={selectedSchedule} onCancel={() => {
                disposeNewScheduleTemplateModalQuery();
                setSelectedSchedule('');
            }} onSubmit={(id) => {
                disposeNewScheduleTemplateModalQuery();
                setSelectedSchedule('');
                if (id)
                    navigate(id);
            }} preloadedQuery={newScheduleTemplateModalQueryRef}/>)}
        {scheduleSortModalQueryRef && (<ScheduleSortModal onClose={(change) => {
                disposeScheduleSortModalQuery();
                if (change) {
                    refetch({}, { fetchPolicy: 'network-only' });
                }
            }} preloadedQuery={scheduleSortModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleListPage;
