import React, { useContext, useMemo, useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { faAngleRight, faAngleUp, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { sv as locale } from 'date-fns/locale';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { usePermissions, useToasts } from '@sonika-se/ui-kit/hooks';
import { AppContext, Button, Collapse, Flexbox, Input, Label, Notification, ReadViewContainer, ToggleButtonGroup, } from '@sonika-se/ui-kit/components';
import enums from '~/enums.json';
import PlaceholderText from './PlaceholderText';
export const ScheduleShiftChangeRequest = (props) => {
    const data = useFragment(graphql `
      fragment ScheduleShiftChangeRequest_shift on ScheduleShiftChangeRequest {
        comment
        createdAt
        end
        id
        requestedBy {
          id
          firstname
          lastname
        }
        respondedAt
        responseComment
        respondedBy {
          id
          firstname
          lastname
        }
        scheduleShift {
          schedule {
            currentUserPermissions
          }
        }
        scheduleShiftId
        start
        status
        responseComment
      }
    `, props.scheduleShiftChangeRequest);
    const [informationIsOpen, setInformationIsOpen] = useState(props.alwaysHideAtRender ? false : data.status === 'REQUESTED');
    const intl = useIntl();
    const [status, setStatus] = useState();
    const [comment, setComment] = useState('');
    const { currentUser: user } = useContext(AppContext);
    const hasPermissions = usePermissions();
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || data.scheduleShift.schedule.currentUserPermissions.includes('EDIT_RUNNING'), [data, hasPermissions]);
    const { addToast } = useToasts();
    const scheduleShiftChangeRequestStatus = enums.ScheduleShiftChangeRequestStatus;
    const [deleteChangeRequest, deleteChangeRequestLoading] = useMutation(graphql `
      mutation ScheduleShiftChangeRequestDeleteChangeMutation($model: DeleteScheduleShiftChangeRequestRequestInput!) {
        deleteScheduleShiftChangeRequest(model: $model) {
          changeRequestEdge {
            node {
              id
            }
          }
        }
      }
    `);
    const [updateChangeRequest, updateChangeRequestLoading] = useMutation(graphql `
      mutation ScheduleShiftChangeRequestUpdateChangeMutation($model: UpdateScheduleShiftChangeRequestRequestInput!) {
        updateScheduleShiftChangeRequest(model: $model) {
          changeRequestEdge {
            node {
              id
              ...ScheduleShiftChangeRequest_shift
            }
          }
        }
      }
    `);
    const [updateScheduleShiftTime, updateScheduleShiftTimeLoading] = useMutation(graphql `
      mutation ScheduleShiftChangeRequestUpdateShiftMutation($model: UpdateScheduleShiftTimeRequestInput!) {
        updateScheduleShiftTime(model: $model) {
          shiftEdge {
            node {
              id
              end
              start
            }
          }
          scheduleShiftHistoryTimeChangeEdge {
            node {
              id
              scheduleShift {
                ...ScheduleShift_shift
                ...ScheduleShiftHistory_scheduleShift
              }
              ...ScheduleShiftHistoryTimeChange_history
            }
          }
        }
      }
    `);
    const yourCard = data.requestedBy.id === user.id;
    return (<Notification title={`${yourCard ? 'Du' : `${data.requestedBy.firstname} ${data.requestedBy.lastname}`} skickade en förändringsförfrågan ${format(new Date(data.createdAt), 'yyyy-MM-dd HH:mm', {
            locale,
        })}`} shadow={false} color={data.status === 'DENIED' ? 'error' : data.status === 'APPROVED' ? 'success' : 'primary'} text={data.status === 'DENIED'
            ? `${data.respondedBy.firstname} ${data.respondedBy.lastname} har nekat förändringen`
            : data.status === 'APPROVED'
                ? `${data.respondedBy.firstname} ${data.respondedBy.lastname} har godkänt förändringen`
                : 'Administratör har inte tagit ställning till förändringen'}>
      <Flexbox justify="space">
        <Button style={{ paddingLeft: '0' }} appearance="text" size="small" icon={informationIsOpen ? faAngleUp : faAngleRight} onClick={() => setInformationIsOpen(!informationIsOpen)}>
          {informationIsOpen ? 'Dölj information' : 'Mer information'}
        </Button>
        {yourCard && data.status === 'REQUESTED' && (<Button icon={faTimes} appearance="text" color="danger" size="small" onClick={() => {
                deleteChangeRequest({
                    variables: {
                        model: {
                            id: data.id,
                        },
                    },
                    configs: [
                        {
                            connectionKeys: [
                                {
                                    key: 'ScheduleShiftChangeTab_changeRequests',
                                },
                            ],
                            parentID: data.scheduleShiftId,
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['changeRequestEdge', 'node'],
                            pathToConnection: [data.scheduleShiftId, 'changeRequests'],
                        },
                    ],
                });
            }}>
            Ta bort
          </Button>)}
      </Flexbox>
      <Collapse open={informationIsOpen}>
        <div style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            gap: '0 1rem',
        }}>
          <Label htmlFor="tags" text={intl.formatMessage({
            defaultMessage: 'Började',
            id: 'ScheduleShiftModal.start.label',
        })}/>
          <ReadViewContainer disabled>
            {format(new Date(data.start), 'yyyy-MM-dd HH:mm', { locale })}
          </ReadViewContainer>
          <Label htmlFor="tags" text={intl.formatMessage({
            defaultMessage: 'Slutade',
            id: 'ScheduleShiftModal.end.label',
        })}/>
          <ReadViewContainer disabled>
            {format(new Date(data.end), 'yyyy-MM-dd HH:mm', { locale })}
          </ReadViewContainer>
          <Label htmlFor="tags" text={intl.formatMessage({
            defaultMessage: 'Kommentar',
            id: 'ScheduleShiftModal.comment.label',
        })}/>
          <ReadViewContainer disabled>
            {!!data.comment ? data.comment : <PlaceholderText>Ingen kommentar</PlaceholderText>}
          </ReadViewContainer>
          {data.responseComment && (<>
              <Label htmlFor="tags" text={intl.formatMessage({
                defaultMessage: 'Kommentar administratör:',
                id: 'ScheduleShiftModal.responseComment.label',
            })}/>
              <ReadViewContainer disabled>{data.responseComment}</ReadViewContainer>
            </>)}
        </div>
      </Collapse>
      {data.status === 'REQUESTED' && canEditRunning && (<Flexbox direction="column" style={{
                marginTop: '1rem',
            }} gap="medium">
          <Flexbox gap="medium">
            <ToggleButtonGroup width="full" style={{
                flex: 1,
            }} inputName={`status_${data.id}`} onChange={(event) => {
                setStatus(event.target.value);
            }} defaultValue={status} type="radio" value={status} options={Object.keys(scheduleShiftChangeRequestStatus)
                .filter((key) => key !== 'REQUESTED')
                .map((key) => {
                var _a;
                return ({
                    label: intl.formatMessage({
                        defaultMessage: (_a = scheduleShiftChangeRequestStatus[key]) === null || _a === void 0 ? void 0 : _a.description,
                        id: `ScheduleShiftChangeRequestStatus_${key}`,
                    }),
                    value: key,
                });
            })}/>

            <Input style={{
                flex: 2,
            }} inputName="responseComment" placeholder="Eventuell kommentar" onChange={(value) => setComment(value)} value={comment}/>
          </Flexbox>
          <Button disabled={!status} onClick={() => {
                updateChangeRequest({
                    variables: {
                        model: {
                            id: data.id,
                            responseComment: comment,
                            status: status,
                        },
                    },
                    onError: (error) => {
                        addToast(intl.formatMessage({
                            defaultMessage: 'Du måste välja att Godkänna / Avslå förändringen',
                            id: 'ScheduleShiftChangeRequest.toast.error',
                        }), {
                            color: 'error',
                        });
                    },
                    onCompleted: () => {
                        if (status === 'APPROVED') {
                            updateScheduleShiftTime({
                                variables: {
                                    model: {
                                        shiftId: data.scheduleShiftId,
                                        end: data.end,
                                        start: data.start,
                                    },
                                },
                                onError: (error) => {
                                    addToast(intl.formatMessage({
                                        defaultMessage: 'Passet kunde inte uppdateras',
                                        id: 'ScheduleShiftChangeRequest.toast.shift_update.error',
                                    }), {
                                        color: 'error',
                                    });
                                },
                            });
                        }
                    },
                });
            }}>
            Spara
          </Button>
        </Flexbox>)}
    </Notification>);
};
export default ScheduleShiftChangeRequest;
