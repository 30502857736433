import React, { Suspense } from 'react';
import { Text, Flexbox, Avatar, IconButton, Skeleton } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import { formatTimeSpan } from '@sonika-se/ui-kit/utils/time';
import { useQueryLoader } from 'react-relay/hooks';
import { faAngleRight, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import ScheduleTemplateInformationPeriodScheduleTimes, { scheduleTemplateInformationPeriodScheduleTimesQuery, } from './ScheduleTemplateInformationPeriodScheduleTimes';
const useStyles = createUseStyles((theme) => ({
    tableRow: {
        height: theme.helpers.pxToRem(40),
    },
    bold: {
        fontWeight: 'bold',
    },
    tableHeader: {
        color: theme.colors.foreground.tertiary,
    },
    tableCell: {
        width: '5rem',
        textAlign: 'center',
        '&:first-child': {
            textAlign: 'left',
        },
        '&:last-child': {
            textAlign: 'right',
            paddingRight: '.5rem',
        },
    },
    tableRowSmall: {
        paddingLeft: '2rem',
        height: theme.helpers.pxToRem(24),
    },
}));
export const ScheduleTemplateInformationPeriodTableRow = (props) => {
    const { entity, selectedPeriodId, templateId } = props;
    const intl = useIntl();
    const classes = useStyles();
    const name = (entity === null || entity === void 0 ? void 0 : entity.displayName) ||
        intl.formatMessage({
            defaultMessage: 'Vakant',
            id: 'general.misc.schedule_shift.vacant.singular',
        });
    const [scheduleTemplateInformationPeriodScheduleTimesQueryRef, loadScheduleTemplateInformationPeriodScheduleTimesQuery, disposeScheduleTemplateInformationPeriodScheduleTimesQuery,] = useQueryLoader(scheduleTemplateInformationPeriodScheduleTimesQuery);
    return (<div key={entity.id}>
      <Flexbox justify="space" className={classes.tableRow} align="center">
        <Flexbox gap="xsmall" align="center">
          <IconButton icon={scheduleTemplateInformationPeriodScheduleTimesQueryRef ? faAngleUp : faAngleRight} appearance="text" color="default" size="small" onClick={() => {
            if (!scheduleTemplateInformationPeriodScheduleTimesQueryRef) {
                loadScheduleTemplateInformationPeriodScheduleTimesQuery({
                    entitiesFilter: {
                        assignedResources: [entity.id],
                    },
                    scheduleTemplateId: templateId,
                }, {
                    fetchPolicy: 'network-only',
                });
            }
            else {
                disposeScheduleTemplateInformationPeriodScheduleTimesQuery();
            }
        }}/>
          <Avatar size="xsmall" text={name} color={(entity === null || entity === void 0 ? void 0 : entity.color) || '#fd544f'}/>
          <span>{name}</span>
        </Flexbox>
        <Text className={classes.tableCell}>{formatTimeSpan(entity.assignedDurationWithoutUnpaidBreaks)}</Text>
      </Flexbox>
      <Suspense fallback={<div style={{ paddingBottom: '0.5rem', paddingLeft: '3rem', paddingRight: '0.5rem' }}>
            <Skeleton type="table"/>
          </div>}>
        {scheduleTemplateInformationPeriodScheduleTimesQueryRef && (<div style={{ paddingBottom: '0.5rem', paddingLeft: '3rem', paddingRight: '0.5rem' }}>
            <ScheduleTemplateInformationPeriodScheduleTimes periodId={selectedPeriodId} preloadedQuery={scheduleTemplateInformationPeriodScheduleTimesQueryRef}/>
          </div>)}
      </Suspense>
    </div>);
};
