import React from 'react';
import { startOfMonth } from 'date-fns';
import { endOfMonth } from 'date-fns/esm';
const now = new Date();
const fromDate = startOfMonth(now);
const toDate = endOfMonth(now);
export const defaultAbsenceCalendarPageValues = {
    toDate,
    fromDate,
    filters: {
        schedulable: true,
        resourceIds: [],
        absenceReasons: [],
        assignmentTypes: [],
        type: 'USER',
        hideEmptyResources: false,
    },
};
export const AbsenceCalendarPageContext = React.createContext(defaultAbsenceCalendarPageValues);
export const AbsenceCalendarPageContextProvider = AbsenceCalendarPageContext.Provider;
export default AbsenceCalendarPageContext;
