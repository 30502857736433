import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Flexbox, Text } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import enums from '~/enums.json';
const useStyles = createUseStyles((theme) => ({
    assignmentType: {
        color: '#484848',
        marginTop: '0.125rem',
    },
    dates: {
        color: theme.colors.primary,
        fontWeight: 500,
    },
    root: {
        cursor: 'pointer',
    },
}));
export const ScheduleTemplateShiftClash = (props) => {
    var _a;
    const { onClick, scheduleTemplateShiftRef } = props;
    const classes = useStyles();
    const weekday = enums.Weekday;
    const data = useFragment(graphql `
      fragment ScheduleTemplateShiftClash_scheduleTemplateShift on ScheduleTemplateShift {
        id
        assignmentType {
          title
        }
        day {
          weekday
        }
        endTimeOfDay
        period {
          name
        }
        startTimeOfDay
      }
    `, scheduleTemplateShiftRef);
    return (<Flexbox className={classes.root} direction="column" gap="xsmall" onClick={() => onClick()}>
      <Text className={classes.dates} size="small">
        <FormattedMessage defaultMessage={(_a = weekday[data.day.weekday]) === null || _a === void 0 ? void 0 : _a.description} id={`Weekday_${data.day.weekday}`}/>
        {` ${data.startTimeOfDay} - ${data.endTimeOfDay}`}
      </Text>
      <Text className={classes.assignmentType} size="small">
        {data.assignmentType.title}, {data.period.name}
      </Text>
    </Flexbox>);
};
export default ScheduleTemplateShiftClash;
