import React, { Suspense } from 'react';
import { graphql } from 'react-relay';
import { usePreloadedQuery } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { Skeleton } from '@sonika-se/ui-kit/components';
import ScheduleTimesPayload from '~/pages/SchedulePages/MySchedule/components/MyScheduleInformationDrawer/ScheduleTimesPayload';
export const scheduleTemplateInformationResourceScheduleTimesQuery = graphql `
  query ScheduleTemplateInformationResourceScheduleTimesQuery(
    $entitiesFilter: ScheduleTemplateShiftFilterRequestInput
    $model: PublishScheduleTemplateRequestInput!
  ) {
    scheduleTemplateTimes(entitiesFilter: $entitiesFilter, model: $model) {
      ...ScheduleTimesPayload_iHaveScheduleTimes
    }
  }
`;
const useStyles = createUseStyles((theme) => ({
    root: {
        overflowY: 'auto',
        height: '100%',
    },
}));
export const ScheduleTemplateInformationResourceScheduleTimes = (props) => {
    const { preloadedQuery } = props;
    const classes = useStyles();
    const data = usePreloadedQuery(scheduleTemplateInformationResourceScheduleTimesQuery, preloadedQuery);
    return (<div className={classes.root}>
      <Suspense fallback={<Skeleton type="table"/>}>
        <ScheduleTimesPayload iHaveScheduleTimesRef={data.scheduleTemplateTimes}/>
      </Suspense>
    </div>);
};
export default ScheduleTemplateInformationResourceScheduleTimes;
