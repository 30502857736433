import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router';
import SchedulePermissionsPanel from '~/panels/SchedulePermissionsPanel';
export const SchedulePermissionsPage = () => {
    const { scheduleId } = useParams();
    const { schedule } = useLazyLoadQuery(graphql `
      query SchedulePermissionsPageQuery($scheduleId: ID!) {
        schedule(id: $scheduleId) {
          ...SchedulePermissionsPanel_schedule
        }
      }
    `, {
        scheduleId,
    });
    return <SchedulePermissionsPanel scheduleRef={schedule}/>;
};
export default SchedulePermissionsPage;
