/**
 * @generated SignedSource<<f6addbaf48322a796ccadc0471021453>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "myScheduleModel"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    }, v5 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "myScheduleModel"
        }
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "reasonEvent",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            (v6 /*: any*/)
        ],
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            (v2 /*: any*/)
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserAbsenceModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "User"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableUser",
                            "kind": "LinkedField",
                            "name": "schedulableEntity",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "myScheduleModel",
                                            "variableName": "myScheduleModel"
                                        }
                                    ],
                                    "kind": "FragmentSpread",
                                    "name": "UserAbsenceModal_scheduleAbsences"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserAbsenceModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Avatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "storageId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "queryTransform",
                                            "value": "width=68&height=68&mode=crop&scale=both"
                                        }
                                    ],
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": "url(queryTransform:\"width=68&height=68&mode=crop&scale=both\")"
                                },
                                {
                                    "alias": "imageUrl",
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "queryTransform",
                                            "value": "width=400&height=400&mode=crop&scale=both"
                                        }
                                    ],
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": "url(queryTransform:\"width=400&height=400&mode=crop&scale=both\")"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableUser",
                            "kind": "LinkedField",
                            "name": "schedulableEntity",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "concreteType": "ScheduleShiftAssigneeReplacementConnection",
                                    "kind": "LinkedField",
                                    "name": "scheduleAbsences",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleShiftAssigneeReplacementEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleShiftAssigneeReplacement",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v8 /*: any*/),
                                                        (v9 /*: any*/),
                                                        (v10 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Schedule",
                                                            "kind": "LinkedField",
                                                            "name": "schedule",
                                                            "plural": false,
                                                            "selections": [
                                                                (v6 /*: any*/),
                                                                (v2 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "concreteType": "PlannedAbsenceConnection",
                                    "kind": "LinkedField",
                                    "name": "plannedAbsences",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PlannedAbsenceEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PlannedAbsence",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v8 /*: any*/),
                                                        (v9 /*: any*/),
                                                        (v10 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "127acebe0ee374e20228b211787b1af7",
            "id": null,
            "metadata": {},
            "name": "UserAbsenceModalQuery",
            "operationKind": "query",
            "text": "query UserAbsenceModalQuery(\n  $id: ID!\n  $myScheduleModel: GetRunningScheduleRequestInput!\n) {\n  user(id: $id) {\n    id\n    firstname\n    lastname\n    ...User\n    schedulableEntity {\n      id\n      ...UserAbsenceModal_scheduleAbsences_4dGZpP\n    }\n  }\n}\n\nfragment User on User {\n  firstname\n  color\n  avatar {\n    id\n    storageId\n    url(queryTransform: \"width=68&height=68&mode=crop&scale=both\")\n    ...UserImageLightBox\n  }\n}\n\nfragment UserAbsenceModal_scheduleAbsences_4dGZpP on SchedulableUser {\n  scheduleAbsences(model: $myScheduleModel) {\n    edges {\n      node {\n        id\n        reasonEvent {\n          id\n          name\n        }\n        start\n        end\n        assignmentType {\n          title\n          id\n        }\n        schedule {\n          name\n          id\n        }\n      }\n    }\n  }\n  plannedAbsences(model: $myScheduleModel) {\n    edges {\n      node {\n        id\n        reasonEvent {\n          id\n          name\n        }\n        start\n        end\n        assignmentType {\n          title\n          id\n        }\n      }\n    }\n  }\n  id\n}\n\nfragment UserImageLightBox on Avatar {\n  imageUrl: url(queryTransform: \"width=400&height=400&mode=crop&scale=both\")\n}\n"
        }
    };
})();
node.hash = "22e16dc817b713f11908e2fe3f0872f4";
export default node;
