/**
 * @generated SignedSource<<334d9076ab4890e5e97e8fec77a98281>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "id"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "typeFilter"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [],
                "operation": require('./ScheduleTemplateInformationperiods_periodsQuery.graphql')
            }
        },
        "name": "ScheduleTemplateInformationPeriods_periods",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "scheduleTemplateId",
                        "variableName": "id"
                    },
                    {
                        "fields": [
                            {
                                "kind": "Variable",
                                "name": "types",
                                "variableName": "typeFilter"
                            }
                        ],
                        "kind": "ObjectValue",
                        "name": "typeFilter"
                    }
                ],
                "concreteType": "ScheduleTimesByTemplatePeriodPayload",
                "kind": "LinkedField",
                "name": "scheduleTemplateTimesPerPeriod",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleTemplatePeriodTimes",
                        "kind": "LinkedField",
                        "name": "periods",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleTemplatePeriod",
                                "kind": "LinkedField",
                                "name": "period",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                                "kind": "LinkedField",
                                "name": "timesByEntityAndAssignmentType",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "unit",
                                                "value": "HOUR"
                                            }
                                        ],
                                        "concreteType": "TimeSpanUnitValue",
                                        "kind": "LinkedField",
                                        "name": "assignedDurationWithoutUnpaidBreaks",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "unit",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "value",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": "assignedDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AssignmentType",
                                        "kind": "LinkedField",
                                        "name": "assignmentType",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "title",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "schedulableEntity",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "color",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "displayName",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "type": "ISchedulableEntity",
                                                "abstractKey": "__isISchedulableEntity"
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "a44003ecab42c4e68fbb919c93a31373";
export default node;
