/**
 * @generated SignedSource<<32f770f56db605173fa251f6c8f89a50>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
        }
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ScheduleShiftVacancyCard_scheduleShift",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentType",
                "kind": "LinkedField",
                "name": "assignmentType",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assignedEntityId",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "end",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "start",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleShiftHistoryVacancyInterestConnection",
                "kind": "LinkedField",
                "name": "vacancyInterests",
                "plural": false,
                "selections": [
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ScheduleShiftHistoryVacancyInterestList_scheduleShiftHistoryVacancyInterestConnection"
                    }
                ],
                "storageKey": null
            },
            {
                "alias": "numInterested",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                            "status": {
                                "eq": "INTERESTED"
                            }
                        }
                    }
                ],
                "concreteType": "ScheduleShiftHistoryVacancyInterestConnection",
                "kind": "LinkedField",
                "name": "vacancyInterests",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": "vacancyInterests(where:{\"status\":{\"eq\":\"INTERESTED\"}})"
            },
            {
                "alias": "numNotInterested",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                            "status": {
                                "eq": "NOT_INTERESTED"
                            }
                        }
                    }
                ],
                "concreteType": "ScheduleShiftHistoryVacancyInterestConnection",
                "kind": "LinkedField",
                "name": "vacancyInterests",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": "vacancyInterests(where:{\"status\":{\"eq\":\"NOT_INTERESTED\"}})"
            }
        ],
        "type": "ScheduleShift",
        "abstractKey": null
    };
})();
node.hash = "a6934f7b8d116f75119402daa899e550";
export default node;
