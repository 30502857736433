import * as React from 'react';
import { useLazyLoadQuery, graphql, useRefetchableFragment } from 'react-relay/hooks';
const SystemTexts = (props) => {
    const { onChange } = props;
    const data = useLazyLoadQuery(graphql `
      query SystemTextsGetTextsQuery($locale: Locale!) {
        ...SystemTexts_texts @arguments(locale: $locale)
      }
    `, {
        locale: 'SV_SE',
    });
    const [texts, refetch] = useRefetchableFragment(graphql `
      fragment SystemTexts_texts on Query
      @argumentDefinitions(locale: { type: "Locale!" })
      @refetchable(queryName: "SystemTexts_textsQuery") {
        texts(locale: $locale) {
          edges {
            node {
              key
              value
              locale
              scope
              id
            }
          }
        }
      }
    `, data);
    /*
    const configUpdate = React.useMemo(() => {
      return {
        onCompleted: () => {
          console.log('completed')
        },
        onError: (error) => {
          console.log({ error })
        },
        onNext: (next) => {
          console.log({ next })
          refetch(
            {
              locale: 'SV_SE',
            },
            { fetchPolicy: 'network-only' },
          )
        },
        subscription: subscriptionUpdate,
      } as GraphQLSubscriptionConfig<SystemTextsUpdateSubscription>
    }, [])
  
    const configDelete = React.useMemo(() => {
      return {
        onCompleted: () => {
          console.log('completed')
        },
        onError: (error) => {
          console.log({ error })
        },
        onNext: (next) => {
          console.log({ next })
          refetch(
            {
              locale: 'SV_SE',
            },
            { fetchPolicy: 'network-only' },
          )
        },
        subscription: subscriptionDelete,
      } as GraphQLSubscriptionConfig<SystemTextsDeleteSubscription>
    }, [])
  
    const configCreate = React.useMemo(() => {
      return {
        onCompleted: () => {
          console.log('completed')
        },
        onError: (error) => {
          console.log({ error })
        },
        onNext: (next) => {
          console.log({ next })
          refetch(
            {
              locale: 'SV_SE',
            },
            { fetchPolicy: 'network-only' },
          )
        },
        subscription: subscriptionCreate,
      } as GraphQLSubscriptionConfig<SystemTextsCreateSubscription>
    }, [])
  
    useSubscription(configUpdate)
    useSubscription(configDelete)
    useSubscription(configCreate)
    */
    React.useEffect(() => {
        var _a;
        onChange((_a = texts === null || texts === void 0 ? void 0 : texts.texts) === null || _a === void 0 ? void 0 : _a.edges);
    }, [texts]);
    return <div />;
};
// declare let module: object
export default SystemTexts;
