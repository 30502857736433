import React, { Suspense, useState } from 'react';
import { Flexbox, Text, ModalToSheetSkeleton } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
import User from '~/components/User/User';
import AddPlannedAbsenceModal from '../AddPlannedAbsenceModal';
import Asset from '~/components/Asset';
import AbsenceCalendarGridRow from './AbsenceCalendarGridRow';
export const useStylesPlannedCalendar = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        grid: ({ numColumns, cellWidth }) => ({
            display: 'grid',
            gridGap: pxToRem(1),
            height: 'fit-content',
            gridTemplateColumns: `${pxToRem(280)} repeat(${numColumns}, minmax(${pxToRem(cellWidth)}, ${pxToRem(cellWidth)}))`,
            minWidth: '100%',
            width: 'fit-content',
        }),
        headerGrid: ({ numColumns, cellWidth }) => ({
            display: 'grid',
            gridGap: pxToRem(1),
            marginBottom: '-1px',
            height: 'fit-content',
            position: 'sticky',
            top: 0,
            zIndex: 4,
            gridTemplateColumns: `${pxToRem(280)} repeat(${numColumns}, minmax(${pxToRem(cellWidth)}, ${pxToRem(cellWidth)}))`,
            minWidth: '100%',
            width: 'fit-content',
        }),
        gridCell: {
            padding: pxToRem(8),
            textTransform: 'capitalize',
        },
        topLeftCorner: {
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 4,
            borderRight: `${pxToRem(1)} solid ${theme.colors.foreground.separation}`,
            borderBottom: `${pxToRem(1)} solid ${theme.colors.foreground.separation}`,
            marginLeft: pxToRem(-1),
            marginTop: pxToRem(-1),
            background: 'white',
            '& > *': {
                margin: 'auto 0',
            },
        },
        gridCellTop: {
            borderBottom: `${pxToRem(1)} solid ${theme.colors.foreground.separation}`,
            marginTop: pxToRem(-1),
            position: 'sticky',
            background: 'white',
            zIndex: 3,
            top: 0,
        },
        gridCellLeft: {
            marginLeft: pxToRem(-1),
            borderRight: `${pxToRem(1)} solid ${theme.colors.foreground.separation}`,
            position: 'sticky',
            zIndex: 3,
            left: 0,
            cursor: 'pointer',
            '&:hover': {
                color: theme.colors.primary,
            },
        },
        lightCell: {
            background: 'white',
        },
        darkCell: {
            background: theme.colors.background.primary,
        },
        gridRow: {
            display: 'contents',
            '&:hover': {
                '& $lightCell': {
                    background: '#e5f0f9 !important',
                },
                '& $darkCell': {
                    background: '#DEEFFF !important',
                },
            },
        },
        errorBackground: {
            background: theme.colors.system.stop,
        },
        gridCellItem: {
            borderRadius: pxToRem(4),
            height: pxToRem(14),
            lineHeight: pxToRem(20),
            cursor: 'pointer',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            padding: pxToRem(8),
            margin: `${pxToRem(5)} 0`,
            '&:focus': {
                boxShadow: theme.helpers.shadow(1),
            },
        },
    };
});
export const AbsenceCalendarGridContent = (props) => {
    var _a, _b;
    const { days, data, toDate, fromDate } = props;
    const calcCellWidth = (window.innerWidth - 281 - (days === null || days === void 0 ? void 0 : days.length)) / (days === null || days === void 0 ? void 0 : days.length);
    const cellWidth = calcCellWidth > 56 ? calcCellWidth : 56;
    const classes = useStylesPlannedCalendar({ numColumns: days === null || days === void 0 ? void 0 : days.length, cellWidth });
    const [selectedUser, setSlectedUser] = useState();
    const [selectedDate, setSlectedDate] = useState();
    const [showAddModal, setShowAddModal] = useState(false);
    let userIndex = 0;
    return (<div className={classes.grid}>
      {(_b = (_a = data === null || data === void 0 ? void 0 : data.schedulableEntities) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(({ node }) => {
            userIndex += 1;
            return (<div className={classes.gridRow} key={node.id}>
            <div style={{ gridRow: userIndex + 1, gridColumn: 1 }} onClick={() => {
                    setSlectedUser(node.id);
                }} role="button" aria-label={node.displayName} tabIndex={0} className={[classes.gridCell, classes.gridCellLeft, classes.lightCell].join(' ')}>
              <Flexbox gap="small" align="center">
                {!!node.user ? <User parent={node.user} size="xsmall"/> : <Asset parent={node.asset} size="xsmall"/>}
                <Text>{node.displayName}</Text>
              </Flexbox>
            </div>
            <AbsenceCalendarGridRow days={days} toDate={toDate} fromDate={fromDate} classes={classes} cellWidth={cellWidth} rowIndex={userIndex} onCellClick={(userId, date) => {
                    setSlectedDate(date);
                    setSlectedUser(userId);
                    setShowAddModal(true);
                }} queryRef={node}/>
          </div>);
        })}
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {showAddModal && (<AddPlannedAbsenceModal defaultAssignedEntityId={selectedUser} defaultDate={selectedDate} onClose={() => {
                setShowAddModal(false);
                setSlectedDate(undefined);
                setSlectedUser(undefined);
            }}/>)}
      </Suspense>
    </div>);
};
export default AbsenceCalendarGridContent;
