/**
 * @generated SignedSource<<661e7a62a5a05ebaa85cc189b722d67f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        },
        {
            "defaultValue": 10,
            "kind": "LocalArgument",
            "name": "first"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v2 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
        },
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = [
        (v4 /*: any*/)
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scheduleId",
        "storageKey": null
    }, v7 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
        },
        (v4 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleAssignmentTypeResourcesUpdateModalRefetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v2 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "ScheduleAssignmentTypeResourcesUpdateModal_scheduleAssignmentType"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleAssignmentTypeResourcesUpdateModalRefetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "assignmentType",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AssetConnection",
                                            "kind": "LinkedField",
                                            "name": "assets",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AssetEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Asset",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "SchedulableAsset",
                                                                    "kind": "LinkedField",
                                                                    "name": "schedulableEntity",
                                                                    "plural": false,
                                                                    "selections": (v5 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                (v4 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserConnection",
                                            "kind": "LinkedField",
                                            "name": "users",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "UserEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "SchedulableUser",
                                                                    "kind": "LinkedField",
                                                                    "name": "schedulableEntity",
                                                                    "plural": false,
                                                                    "selections": (v5 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                (v4 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                    "kind": "LinkedField",
                                    "name": "resources",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeResource",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "schedulableEntityId",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleAssignmentType",
                                                            "kind": "LinkedField",
                                                            "name": "scheduleAssignmentType",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "assignmentTypeId",
                                                                    "storageKey": null
                                                                },
                                                                (v6 /*: any*/),
                                                                (v4 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "schedulableEntity",
                                                            "plural": false,
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": (v7 /*: any*/),
                                                                    "type": "SchedulableAsset",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": (v7 /*: any*/),
                                                                    "type": "SchedulableUser",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": (v5 /*: any*/),
                                                                    "type": "Node",
                                                                    "abstractKey": "__isNode"
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "ScheduleAssignmentTypeResourcesUpdateModal_scheduleAssignmentType_resources",
                                    "kind": "LinkedHandle",
                                    "name": "resources"
                                },
                                (v6 /*: any*/)
                            ],
                            "type": "ScheduleAssignmentType",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "46c7e1a697cdaaa40e729d7f1988d313",
            "id": null,
            "metadata": {},
            "name": "ScheduleAssignmentTypeResourcesUpdateModalRefetchQuery",
            "operationKind": "query",
            "text": "query ScheduleAssignmentTypeResourcesUpdateModalRefetchQuery(\n  $after: String\n  $first: Int = 10\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ScheduleAssignmentTypeResourcesUpdateModal_scheduleAssignmentType_2HEEH6\n    id\n  }\n}\n\nfragment SchedulableEntityListItem_scheduleAssignmentTypeResource on ScheduleAssignmentTypeResource {\n  id\n  scheduleAssignmentType {\n    assignmentTypeId\n    scheduleId\n    id\n  }\n  schedulableEntity {\n    __typename\n    ... on SchedulableAsset {\n      color\n      displayName\n      id\n    }\n    ... on SchedulableUser {\n      color\n      displayName\n      id\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment ScheduleAssignmentTypeResourcesUpdateModal_scheduleAssignmentType_2HEEH6 on ScheduleAssignmentType {\n  id\n  assignmentType {\n    id\n    assets {\n      edges {\n        node {\n          schedulableEntity {\n            id\n          }\n          id\n        }\n      }\n    }\n    title\n    users {\n      edges {\n        node {\n          schedulableEntity {\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n  resources(after: $after, first: $first) {\n    edges {\n      node {\n        id\n        schedulableEntityId\n        ...SchedulableEntityListItem_scheduleAssignmentTypeResource\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  scheduleId\n}\n"
        }
    };
})();
node.hash = "421ea9f9e31e924cdd81c422d17cbb83";
export default node;
