/**
 * @generated SignedSource<<846228af3c8775ca494710bac58471c8>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "currentScheduleShiftClashesModel"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "currentScheduleShiftClashesModel"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftClashCountBadgeQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftClashCountBadge_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleShiftClashCountBadgeQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ClashGroup",
                    "kind": "LinkedField",
                    "name": "currentScheduleShiftClashes",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "clashes",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "8b249d99e044f88d3bef622d11d13ba0",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftClashCountBadgeQuery",
            "operationKind": "query",
            "text": "query ScheduleShiftClashCountBadgeQuery(\n  $currentScheduleShiftClashesModel: GetRunningScheduleRequestInput!\n) {\n  ...ScheduleShiftClashCountBadge_query_1YXmH\n}\n\nfragment ScheduleShiftClashCountBadge_query_1YXmH on Query {\n  currentScheduleShiftClashes(model: $currentScheduleShiftClashesModel) {\n    clashes {\n      __typename\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "396da937c5ce72055d25cfb71bbb312c";
export default node;
