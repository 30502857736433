import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Chip } from '@sonika-se/ui-kit/components';
export const SchedulableUser = (props) => {
    const { color, entity, checkboxProps } = props;
    const data = useFragment(graphql `
      fragment SchedulableUser_entity on SchedulableUser {
        id
        entityDisabled
        schedulable
        user {
          id
          color
          firstname
          lastname
        }
      }
    `, entity);
    return (<Chip style={{
            cursor: 'pointer',
        }} color={!data.schedulable || data.entityDisabled ? '#FD544F' : color !== null && color !== void 0 ? color : data.user.color} fullWidth text={`${data.user.firstname} ${data.user.lastname}`} checkboxProps={checkboxProps}/>);
};
export default SchedulableUser;
