import React, { Suspense, useContext, useEffect } from 'react';
import { graphql, usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { faEye as faEyeSolid } from '@fortawesome/pro-solid-svg-icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { Text, Skeleton, Divider, Popover, PopoverHeader, PopoverContent, PopoverFooter, Switch, IconButton, Flexbox, Label, Checkbox, Tooltip, Badge, } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import RunningSchedulesPageContext from '../../RunningSchedulesPageContext';
export const runningSchedulesScheduleFilterQuery = graphql `
  query RunningSchedulesScheduleFilterQuery {
    schedules {
      edges {
        node {
          assignmentTypes {
            nodes {
              assignmentType {
                id
                title
              }
            }
          }
          id
          name
        }
      }
    }
  }
`;
const useStyles = createUseStyles(() => ({
    container: {
        padding: '10px 16px',
        margin: 0,
        borderBottom: '1px solid #ccc',
    },
    resourceRow: {
        fontSize: 16,
        paddingTop: 5,
        paddingBottom: 5,
    },
    resourceCheckbox: {
        marginRight: 10,
        cursor: 'pointer',
    },
    scheduleContainer: {
        overflow: 'auto',
        maxHeight: '50vh',
    },
}));
const RunningSchedulesScheduleFilterList = (props) => {
    var _a, _b, _c, _d, _e;
    const { updateFilters, queryRef } = props;
    const data = usePreloadedQuery(runningSchedulesScheduleFilterQuery, queryRef);
    const intl = useIntl();
    const classes = useStyles();
    const { filters } = useContext(RunningSchedulesPageContext);
    const { scheduleIds: checked, assignmentTypeIds } = filters;
    const assignmentTypes = data.schedules.edges.reduce((prev, cur) => {
        const scheduleAssignmentTypes = cur.node.assignmentTypes.nodes.reduce((prev, cur) => {
            return Object.assign(Object.assign({}, prev), { [cur.assignmentType.id]: cur.assignmentType.title });
        }, {});
        return Object.assign(Object.assign({}, prev), scheduleAssignmentTypes);
    }, {});
    return (<>
      <Flexbox direction="column" gap="xsmall">
        <Text size="small" style={{ marginBottom: '.25rem' }}>
          <FormattedMessage id="RunningSchedulesScheduleFilter.only_show_assignment_types" defaultMessage="Visa endast {assignmentTypes}" values={{
            assignmentTypes: intl.formatMessage({
                id: 'general.objects.assignment_type.name.plural',
                defaultMessage: 'Roller',
            }),
        }}/>
        </Text>
        {Object.keys(assignmentTypes).map((key) => {
            return (<Flexbox gap="xsmall" align="center" key={key}>
              <Checkbox id={`checkedAsset_${key}`} checked={!!assignmentTypeIds.find((checkedValue) => {
                    return checkedValue === key;
                })} className={classes.resourceCheckbox} onChange={(event) => {
                    if (event.target.checked) {
                        updateFilters([...assignmentTypeIds, key], 'assignmentTypeIds');
                    }
                    else {
                        updateFilters(assignmentTypeIds.filter((checkedValue) => checkedValue !== key), 'assignmentTypeIds');
                    }
                }}/>
              <Label style={{ fontSize: '1rem' }} text={assignmentTypes[key]} htmlFor={`checkedAsset_${key}`}/>
            </Flexbox>);
        })}
      </Flexbox>

      {((_a = Object.keys(assignmentTypes)) === null || _a === void 0 ? void 0 : _a.length) === 0 &&
            intl.formatMessage({
                id: 'RunningSchedulesScheduleFilter.no_assignmentTypes',
                defaultMessage: 'Det finns inga roller att filtrera på',
            })}
      <Divider style={{ margin: '1rem 0 ' }}/>
      <Skeleton loading={!data}>
        <Flexbox direction="column" gap="xsmall">
          <Text size="small" style={{ marginBottom: '.25rem' }}>
            <FormattedMessage id="RunningSchedulesScheduleFilter.only_show_schedules" defaultMessage="Visa endast {schedule}" values={{
            schedule: intl.formatMessage({
                id: 'general.objects.schedule.name.singular',
                defaultMessage: 'Schema',
            }),
        }}/>
          </Text>
          {(_c = (_b = data === null || data === void 0 ? void 0 : data.schedules) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.map(({ node: schedule }) => (<Flexbox gap="xsmall" align="center" key={schedule.id}>
              <Checkbox id={`checkedAsset_${schedule.id}`} checked={!!checked.find((checkedValue) => {
                return checkedValue === schedule.id;
            })} className={classes.resourceCheckbox} onChange={(event) => {
                if (event.target.checked) {
                    updateFilters([...checked, schedule.id], 'scheduleIds');
                }
                else {
                    updateFilters(checked.filter((checkedValue) => checkedValue !== schedule.id), 'scheduleIds');
                }
            }}/>
              <Label style={{ fontSize: '1rem' }} text={schedule.name} htmlFor={`checkedAsset_${schedule.id}`}/>
            </Flexbox>))}
        </Flexbox>
        {((_e = (_d = data === null || data === void 0 ? void 0 : data.schedules) === null || _d === void 0 ? void 0 : _d.edges) === null || _e === void 0 ? void 0 : _e.length) === 0 &&
            intl.formatMessage({
                id: 'RunningSchedulesScheduleFilter.no_schedules',
                defaultMessage: 'Det finns inga scheman att filtrera på',
            })}
      </Skeleton>
    </>);
};
const RunningSchedulesScheduleFilter = (props) => {
    const { updateFilters } = props;
    const { filters, toDate, fromDate } = useContext(RunningSchedulesPageContext);
    const { onlyVacant, hideEmptySchedules, scheduleIds, assignmentTypeIds } = filters;
    const [queryReference, loadQuery, disposeQuery] = useQueryLoader(runningSchedulesScheduleFilterQuery);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const intl = useIntl();
    useEffect(() => {
        return () => disposeQuery();
    }, []);
    const handleClick = (event) => {
        loadQuery({ fromDate, toDate });
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const { size: pageSize } = useViewport();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const getTotalAmountOfFilters = () => {
        let total = 0;
        if (onlyVacant)
            total += 1;
        if (hideEmptySchedules)
            total += 1;
        return total + (scheduleIds === null || scheduleIds === void 0 ? void 0 : scheduleIds.length) + assignmentTypeIds.length;
    };
    return (<>
      <Tooltip content={intl.formatMessage({
            defaultMessage: 'Visningsalternativ',
            id: 'RunningSchedulesScheduleFilter.header',
        })}>
        <Badge appearance={getTotalAmountOfFilters() ? 'danger' : 'none'} text={`${getTotalAmountOfFilters()}`}>
          <IconButton appearance={open ? 'filled' : 'outlined'} onClick={handleClick} icon={open ? faEyeSolid : faEye} size={pageSize === 'small' ? 'small' : 'medium'}/>
        </Badge>
      </Tooltip>
      <Popover size="auto" style={{ minWidth: '20.5rem' }} anchorEl={open ? anchorEl : undefined} onClose={handleClose}>
        <>
          <PopoverHeader>
            <FormattedMessage id="RunningSchedulesScheduleFilter.header" defaultMessage="Visningsalternativ"/>
          </PopoverHeader>
          <PopoverContent>
            <Suspense fallback={<Skeleton type="table" height="150px"/>}>
              <RunningSchedulesScheduleFilterList queryRef={queryReference} updateFilters={updateFilters}/>
            </Suspense>
          </PopoverContent>
          <PopoverFooter>
            <Flexbox direction="column">
              <div style={{ marginBottom: '.25rem' }}>
                <Switch label={intl.formatMessage({
            id: 'RunningSchedulesScheduleFilter.hide_empty_schedules',
            defaultMessage: 'Dölj tomma scheman',
        })} defaultChecked={hideEmptySchedules} onChange={(event) => updateFilters(event.target.checked || undefined, 'hideEmptySchedules')}/>
              </div>
              <div>
                <Switch label={intl.formatMessage({
            id: 'RunningSchedulesScheduleFilter.display_only_vacant',
            defaultMessage: 'Visa endast vakanta pass',
        })} defaultChecked={onlyVacant} onChange={(event) => updateFilters(event.target.checked || undefined, 'onlyVacant')}/>
              </div>
            </Flexbox>
          </PopoverFooter>
        </>
      </Popover>
    </>);
};
export default RunningSchedulesScheduleFilter;
