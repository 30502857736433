import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Chip } from '@sonika-se/ui-kit/components';
export const SchedulableAsset = (props) => {
    var _a;
    const { color, entity, checkboxProps } = props;
    const data = useFragment(graphql `
      fragment SchedulableAsset_entity on SchedulableAsset {
        id
        asset {
          id
          color
          name
        }
        entityDisabled
        schedulable
      }
    `, entity);
    return (<Chip color={!data.schedulable || data.entityDisabled ? '#FD544F' : color !== null && color !== void 0 ? color : (_a = data === null || data === void 0 ? void 0 : data.asset) === null || _a === void 0 ? void 0 : _a.color} fullWidth text={`${data.asset.name}`} checkboxProps={checkboxProps} style={{
            cursor: 'pointer',
        }}/>);
};
export default SchedulableAsset;
