/**
 * @generated SignedSource<<12ef80025efb86a5b4ffe6992e9561f0>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "CreateScheduleShiftNotePayload",
            "kind": "LinkedField",
            "name": "createScheduleShiftNote",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfScheduleShiftNote",
                    "kind": "LinkedField",
                    "name": "noteEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShiftNote",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "byUser",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "firstname",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastname",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "text",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useScheduleShiftNotes_createNoteMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useScheduleShiftNotes_createNoteMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "d8091c740e3351295fcc8aa85b7f55d7",
            "id": null,
            "metadata": {},
            "name": "useScheduleShiftNotes_createNoteMutation",
            "operationKind": "mutation",
            "text": "mutation useScheduleShiftNotes_createNoteMutation(\n  $model: CreateScheduleShiftNoteRequestInput!\n) {\n  createScheduleShiftNote(model: $model) {\n    noteEdge {\n      node {\n        id\n        byUser {\n          id\n          firstname\n          lastname\n        }\n        createdAt\n        text\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "55b4f37e0617a8904d26410167e42775";
export default node;
