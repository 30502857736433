import React from 'react';
import { date, object, ref } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format, formatISO } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { Form, Field, Text, Flexbox, DateInput } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
export const validationSchema = (intl) => object({
    fromDate: date()
        .typeError(intl.formatMessage({
        id: 'RunningSchedulesDeleteShiftsWizardStep3Form.fields.from_date.required',
        defaultMessage: 'Du måste ange ett startdatum',
    }))
        .required(),
    toDate: date()
        .typeError(intl.formatMessage({
        id: 'RunningSchedulesDeleteShiftsWizardStep3Form.fields.to_date.required',
        defaultMessage: 'Du måste ange ett slutdatum',
    }))
        .min(ref('fromDate'), ({ min }) => `Datum måste vara efter ${formatISO(min, { representation: 'date' })}`)
        .required(),
});
export const RunningSchedulesDeleteShiftsWizardStep3 = (props) => {
    const { data, id, onSubmit, state } = props;
    const intl = useIntl();
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: {
            fromDate: new Date(),
            toDate: new Date(),
        },
        resolver: yupResolver(validationSchema(intl)),
    });
    return state === 'READ' ? (<Text>
      {format(data.fromDate, 'yyyy-MM-dd', { locale })} - {format(data.toDate, 'yyyy-MM-dd', { locale })}
    </Text>) : (<Form id={id} onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
      <div>
        <Text>För roll och anställd eller enhet</Text>
        <Text size="small">Endast de som är kopplade till en rollen går att välja</Text>
      </div>
      <Flexbox gap="medium">
        <Field label="Från datum">
          <Controller control={control} name="fromDate" render={({ field }) => {
            var _a, _b;
            return (<DateInput error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.fromDate) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} value={field.value} onChange={field.onChange} placeholder="Välj datum"/>);
        }}/>
        </Field>
        <Field label="Till datum">
          <Controller control={control} name="toDate" render={({ field }) => {
            var _a, _b;
            return (<DateInput error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.toDate) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} value={field.value} onChange={field.onChange} placeholder="Välj datum"/>);
        }}/>
        </Field>
      </Flexbox>
    </Form>);
};
export default RunningSchedulesDeleteShiftsWizardStep3;
