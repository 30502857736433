/**
 * @generated SignedSource<<0e780d1a3216a2200a0a501866c0e0e6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
        }
    ], v1 = {
        "kind": "Variable",
        "name": "model",
        "variableName": "runningScheduleModel"
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "runningScheduleModel"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "ScheduleCard_schedule",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archived",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inactivatedFrom",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleAssignmentTypeConnection",
                "kind": "LinkedField",
                "name": "assignmentTypes",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    (v1 /*: any*/),
                    {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                            "assignedEntityId": {
                                "neq": null
                            }
                        }
                    }
                ],
                "concreteType": "ScheduleShiftConnection",
                "kind": "LinkedField",
                "name": "runningSchedule",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    (v1 /*: any*/)
                ],
                "concreteType": "ScheduleShiftChangeRequestConnection",
                "kind": "LinkedField",
                "name": "shiftChangeRequests",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            },
            {
                "alias": "vacantShifts",
                "args": [
                    (v1 /*: any*/),
                    {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                            "assignedEntityId": {
                                "eq": null
                            }
                        }
                    }
                ],
                "concreteType": "ScheduleShiftConnection",
                "kind": "LinkedField",
                "name": "runningSchedule",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            },
            {
                "alias": "schedulableAssets",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                            "type": {
                                "eq": "ASSET"
                            }
                        }
                    }
                ],
                "concreteType": "SchedulableEntityConnection",
                "kind": "LinkedField",
                "name": "schedulableEntities",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": "schedulableEntities(where:{\"type\":{\"eq\":\"ASSET\"}})"
            },
            {
                "alias": "schedulableUser",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                            "type": {
                                "eq": "USER"
                            }
                        }
                    }
                ],
                "concreteType": "SchedulableEntityConnection",
                "kind": "LinkedField",
                "name": "schedulableEntities",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": "schedulableEntities(where:{\"type\":{\"eq\":\"USER\"}})"
            }
        ],
        "type": "Schedule",
        "abstractKey": null
    };
})();
node.hash = "8dabc3c0f3fc114a03ffdc31bde676fe";
export default node;
