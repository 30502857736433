import React from 'react';
import { graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { Flexbox, Label, FormSection, Divider, BasicPage, BasicPageHeader } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import EditCompanyScheduleVisibility from '~/components/InlineEditFields/Company/EditCompanyScheduleVisibility';
export const generalSettingsPageQuery = graphql `
  query SystemSettingsPageQuery {
    currentCompany {
      id
      ...EditCompanyScheduleVisibility
    }
  }
`;
export const SystemSettingsPage = () => {
    const data = useLazyLoadQuery(generalSettingsPageQuery, {});
    const intl = useIntl();
    return (<BasicPage width="large">
      <BasicPageHeader title={intl.formatMessage({
            id: 'general.objects.settings.name.plural',
            defaultMessage: 'Inställningar',
        })}/>
      <div>
        <Flexbox direction="column" gap="xlarge">
          <Flexbox direction="column" gap="medium">
            <Divider />
            <FormSection columns="1fr 3fr">
              <Label htmlFor="viewAccessUntil" text={intl.formatMessage({
            defaultMessage: 'Pass synliga t.o.m.',
            id: 'general.objects.company.fields.view_access_until.label',
        })}/>
              <EditCompanyScheduleVisibility company={data === null || data === void 0 ? void 0 : data.currentCompany} placeholder={intl.formatMessage({
            defaultMessage: 'Lägg till datum',
            id: 'general.objects.company.fields.view_access_until.placeholder',
        })}/>
            </FormSection>
            <Divider />
          </Flexbox>
        </Flexbox>
      </div>
    </BasicPage>);
};
export default SystemSettingsPage;
