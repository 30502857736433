/**
 * @generated SignedSource<<0701031a62401a44e7d1df83f6421050>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditScheduleShiftDescription",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "schedule",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentUserPermissions",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "ScheduleShift",
    "abstractKey": null
};
node.hash = "5092a6bc4947a90192d83bebedea2f7e";
export default node;
