/**
 * @generated SignedSource<<dd7eabe060b8aeb1fe89fec5c56b3a88>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "CreateDispatchContentPayload",
            "kind": "LinkedField",
            "name": "createDispatchContent",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DispatchContent",
                    "kind": "LinkedField",
                    "name": "dispatchContent",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftVacancyNotifyModal_createDispatchContentMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleShiftVacancyNotifyModal_createDispatchContentMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "503f46c087752556a87743c185e8c8f1",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftVacancyNotifyModal_createDispatchContentMutation",
            "operationKind": "mutation",
            "text": "mutation ScheduleShiftVacancyNotifyModal_createDispatchContentMutation(\n  $model: CreateDispatchContentInput!\n) {\n  createDispatchContent(model: $model) {\n    dispatchContent {\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "28d32974da1d4d1a126d5c176ee434cc";
export default node;
