/**
 * @generated SignedSource<<4325867a856357c1b85fb7e53e7e3d6a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "DeleteSchedulePayload",
            "kind": "LinkedField",
            "name": "deleteSchedule",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfSchedule",
                    "kind": "LinkedField",
                    "name": "scheduleEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Schedule",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SchedulesTableDeleteScheduleMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SchedulesTableDeleteScheduleMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "430a0619a57ab25d88f5e9bc00914f40",
            "id": null,
            "metadata": {},
            "name": "SchedulesTableDeleteScheduleMutation",
            "operationKind": "mutation",
            "text": "mutation SchedulesTableDeleteScheduleMutation(\n  $model: DeleteScheduleRequestInput!\n) {\n  deleteSchedule(model: $model) {\n    scheduleEdge {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "676e18c5b79ba9b8e830f50b16a69b95";
export default node;
