import { faCheck, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Tooltip } from '@sonika-se/ui-kit/components';
import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
const AssetProficiencyValidationIcon = (props) => {
    const { parent, iconSize = '1x' } = props;
    const data = useFragment(graphql `
      fragment AssetProficiencyValidationIcon_scheduleShiftAssetValidation on ScheduleShiftAssetValidation {
        schedulableAsset {
          asset {
            id
            name
            assetNumber
            validateProficiency
          }
        }
        schedulableUser {
          user {
            fullName
          }
        }
        userAssignmentType {
          title
        }
        assetProficiency {
          isValid
        }
      }
    `, parent);
    return (<>
      {data.assetProficiency.isValid ? (<Tooltip placement="top" content={`${data.schedulableUser.user.fullName} har behörighet ${data.userAssignmentType.title}/${data.schedulableAsset.asset.name}`}>
          <Icon icon={faCheck} color={sonikaTheme.colors.system.go}/>
        </Tooltip>) : (<Tooltip placement="top" content={`${data.schedulableUser.user.fullName} saknar behörighet ${data.userAssignmentType.title} för ${data.schedulableAsset.asset.name}`}>
          <Icon icon={faExclamationTriangle} size={iconSize} color={sonikaTheme.colors.system.stop}/>
        </Tooltip>)}
    </>);
};
export default AssetProficiencyValidationIcon;
