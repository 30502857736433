import React, { useMemo } from 'react';
import { Flexbox, H2, H4, H6 } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import { useFragment, graphql } from 'react-relay/hooks';
import Asset from '../Asset';
import User from '../User/User';
export const DisplaySchedulableEntity = (props) => {
    const { schedulableEntity, size = 'medium' } = props;
    const data = useFragment(graphql `
      fragment DisplaySchedulableEntity on SchedulableEntity {
        ... on ISchedulableEntity {
          id
          displayName
        }
        ... on SchedulableUser {
          user {
            ...User
          }
        }
        ... on SchedulableAsset {
          asset {
            ...Asset
          }
        }
      }
    `, schedulableEntity);
    const intl = useIntl();
    if (!data) {
        return null;
    }
    const { avatarSize, textComponent } = useMemo(() => {
        switch (size) {
            case 'small':
                return { avatarSize: 'xsmall', textComponent: <H6>{data.displayName}</H6> };
            case 'medium':
                return { avatarSize: 'small', textComponent: <H4>{data.displayName}</H4> };
            case 'large':
                return { avatarSize: 'medium', textComponent: <H2>{data.displayName}</H2> };
        }
    }, [size, data]);
    return (<Flexbox direction="row" gap="xsmall" align="center">
      {data.user ? (<User parent={data.user} size={avatarSize}/>) : data.asset ? (<Asset parent={data.asset} size={avatarSize}/>) : null}
      {textComponent}
    </Flexbox>);
};
export default DisplaySchedulableEntity;
