import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useNavigate, Link } from 'react-router-dom';
import { graphql, useMutation, useFragment } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons';
import { ContextMenu, ContextMenuItem, Divider, Flexbox, Placeholder, Button, Confirm, Card, Icon, CardContent, Modal, H5, DataTable, Text, AccordionItem, Heading, Tooltip, } from '@sonika-se/ui-kit/components';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { faArchive } from '@fortawesome/pro-regular-svg-icons';
const useStyles = createUseStyles((theme) => ({
    ScheduleListRowRoot: {
        padding: `${theme.helpers.pxToRem(16)} ${theme.helpers.pxToRem(16)} ${theme.helpers.pxToRem(16)} 0`,
        color: theme.colors.primary,
        '&:not(:first-child)': {
            borderTop: `${theme.helpers.pxToRem(1)} solid ${theme.colors.divider}`,
        },
    },
    tableContainer: {
        paddingLeft: theme.helpers.pxToRem(40),
        paddingRight: theme.helpers.pxToRem(16),
        paddingBottom: theme.helpers.pxToRem(24),
    },
    newTemplateButton: {
        fontWeight: 400,
        marginTop: '1rem',
    },
}));
const ScheduleListRow = (props) => {
    const { archiveSchedule, deleteSchedule, setResult, disabled, loadNewTemplateModal } = props;
    const classes = useStyles();
    const intl = useIntl();
    const schedule = useFragment(graphql `
      fragment ScheduleListRow_schedule on Schedule {
        id
        currentUserPermissions
        name
        archived
        inactivatedFrom
        templates {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, props.schedule);
    const [deleteScheduleTemplate, deleteScheduleTemplateLoading] = useMutation(graphql `
    mutation ScheduleListRowDeleteMutation($model: DeleteScheduleTemplateRequestInput!) {
      deleteScheduleTemplate(model: $model) {
        templateEdge {
          node {
            id
            name
            schedule {
              id
              templates {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
    const [cloneScheduleTemplate, cloneScheduleTemplateLoading] = useMutation(graphql `
      mutation ScheduleListRowCloneScheduleTemplateMutation($model: CloneScheduleTemplateInput!) {
        cloneScheduleTemplate(model: $model) {
          scheduleTemplateEdge {
            node {
              id
              name
              schedule {
                id
                templates {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);
    const [confirmDeleteTemplate, setConfirmDeleteTemplate] = useState('');
    const [confirmDeleteSchedule, setConfirmDeleteSchedule] = useState(null);
    const [confirmArchiveSchedule, setConfirmArchiveSchedule] = useState(null);
    const navigate = useNavigate();
    const hasPermissions = usePermissions();
    const canEditInformation = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || schedule.currentUserPermissions.includes('EDIT_INFORMATION'), [hasPermissions, schedule]);
    const canEditPermissions = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || schedule.currentUserPermissions.includes('EDIT_PERMISSIONS'), [hasPermissions, schedule]);
    const canEditTemplates = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || schedule.currentUserPermissions.includes('EDIT_TEMPLATES'), [hasPermissions, schedule]);
    return (<>
      <AccordionItem actions={(canEditInformation || canEditPermissions) && (<ContextMenu>
              {(canEditInformation || canEditPermissions) && (<ContextMenuItem disabled={disabled || deleteScheduleTemplateLoading} label={intl.formatMessage({
                    id: 'general.actions.update',
                    defaultMessage: 'Redigera',
                })} onClick={() => {
                    setResult(schedule.id);
                }}/>)}
              {hasPermissions(['EDIT_SCHEDULES']) && (<>
                  {(canEditInformation || canEditPermissions) && <Divider />}

                  {!schedule.archived && (<ContextMenuItem disabled={disabled || deleteScheduleTemplateLoading} label={intl.formatMessage({
                        id: 'general.actions.archive',
                        defaultMessage: 'Dölj',
                    })} onClick={() => {
                        setConfirmArchiveSchedule(schedule);
                    }}/>)}

                  {schedule.archived && (<>
                      <ContextMenuItem disabled={disabled || deleteScheduleTemplateLoading} label={intl.formatMessage({
                        id: 'general.actions.delete',
                        defaultMessage: 'Visa',
                    })} onClick={() => {
                        archiveSchedule(schedule.id, null);
                    }}/>
                      <Divider />
                      <ContextMenuItem disabled={disabled || deleteScheduleTemplateLoading} label={intl.formatMessage({
                        id: 'general.actions.delete',
                        defaultMessage: 'Ta bort',
                    })} onClick={() => {
                        setConfirmDeleteSchedule(schedule);
                    }}/>
                    </>)}
                </>)}
            </ContextMenu>)} defaultOpen={!!schedule.templates.edges.length} size="large" title={<Heading appearance="h3">
            <Flexbox gap="small">
              <span>{schedule.name}</span>
              {schedule.archived && (<Tooltip content="Dolt">
                  <Icon icon={faArchive}/>
                </Tooltip>)}
            </Flexbox>
          </Heading>}>
        {canEditTemplates && (<Card>
            <CardContent padding="large">
              {!schedule.templates.edges.length ? (<div className={classes.tableContainer}>
                  <Placeholder icon={faCalendarWeek} title={intl.formatMessage({
                    id: 'ScheduleListPage.ScheduleListRow.no_data',
                    defaultMessage: 'Lägg till en mall till schemat',
                })}>
                    <Button onClick={() => loadNewTemplateModal(schedule.id)}>
                      <FormattedMessage id="general.objects.schedule_template.actions.create" defaultMessage="Ny mall"/>
                    </Button>
                  </Placeholder>
                </div>) : (<>
                  <DataTable columns={{
                    name: {
                        text: 'Mall',
                        render: (scheduleTemplate) => (<Link to={scheduleTemplate.id}>
                            <Text bold>{scheduleTemplate.name}</Text>
                          </Link>),
                    },
                    actions: {
                        align: 'right',
                        render: (scheduleTemplate) => (<ContextMenu>
                            <ContextMenuItem disabled={disabled || deleteScheduleTemplateLoading} label={intl.formatMessage({
                                id: 'general.actions.update',
                                defaultMessage: 'Redigera',
                            })} onClick={() => {
                                navigate(scheduleTemplate.id);
                            }}/>
                            <ContextMenuItem disabled={disabled || deleteScheduleTemplateLoading || cloneScheduleTemplateLoading} label={intl.formatMessage({
                                id: 'general.actions.duplicate',
                                defaultMessage: 'Duplicera',
                            })} onClick={() => {
                                cloneScheduleTemplate({
                                    variables: {
                                        model: {
                                            scheduleTemplateId: scheduleTemplate.id,
                                            newName: `${scheduleTemplate.name} kopia`,
                                        },
                                    },
                                });
                            }}/>
                            <Divider />
                            <ContextMenuItem disabled={disabled || deleteScheduleTemplateLoading} label={intl.formatMessage({
                                id: 'general.actions.delete',
                                defaultMessage: 'Ta bort',
                            })} onClick={() => {
                                setConfirmDeleteTemplate(scheduleTemplate.id);
                            }}/>
                          </ContextMenu>),
                    },
                }} rows={schedule.templates.edges.map(({ node: scheduleTemplate }) => ({
                    name: scheduleTemplate,
                    actions: scheduleTemplate,
                }))}/>
                  <Divider />
                  <Button appearance="text" icon={faPlus} padding={false} className={classes.newTemplateButton} onClick={() => loadNewTemplateModal(schedule === null || schedule === void 0 ? void 0 : schedule.id)}>
                    <FormattedMessage id="general.objects.schedule_template.actions.create" defaultMessage="Ny mall"/>
                  </Button>
                </>)}
            </CardContent>
          </Card>)}
      </AccordionItem>
      {!!confirmDeleteTemplate && (<Confirm appearance="danger" headerText={intl.formatMessage({
                defaultMessage: 'Är du säker på att du vill ta bort den här schemamallen?',
                id: 'ScheduleListPage.ScheduleListRow.Confirm.delete.schedule_template.header_text',
            })} confirmButton={{
                text: intl.formatMessage({ id: 'general.actions.delete', defaultMessage: 'Ta bort' }),
                onConfirm: () => {
                    deleteScheduleTemplate({
                        variables: {
                            model: {
                                id: confirmDeleteTemplate,
                            },
                        },
                        onCompleted: () => {
                            setConfirmDeleteTemplate(undefined);
                        },
                    });
                },
            }} onCancel={() => setConfirmDeleteTemplate(undefined)}/>)}
      {!!confirmArchiveSchedule && (<Confirm appearance="warning" headerText={`Vill du dölja ${confirmArchiveSchedule.name}?`} confirmButton={{
                text: intl.formatMessage({ id: 'general.actions.archive', defaultMessage: 'Dölj' }),
                onConfirm: () => {
                    archiveSchedule(confirmArchiveSchedule.id, true);
                    setConfirmArchiveSchedule(null);
                },
            }} onCancel={() => setConfirmArchiveSchedule(null)}/>)}
      {!!confirmDeleteSchedule && (<Confirm appearance="danger" headerText={`Är du säker på att du vill ta bort ${confirmDeleteSchedule.name}?`} text={'Alla pass i detta schema kommer tas bort. Du kan inte ångra detta.'} confirmButton={{
                text: intl.formatMessage({ id: 'general.actions.delete', defaultMessage: 'Ta bort' }),
                onConfirm: () => {
                    deleteSchedule(confirmDeleteSchedule.id);
                    setConfirmDeleteSchedule(undefined);
                },
            }} onCancel={() => setConfirmDeleteSchedule(undefined)}/>)}
      {cloneScheduleTemplateLoading && (<Modal>
          <Card background="gray">
            <CardContent padding="large">
              <Flexbox direction="column" align="center" justify="center" gap="medium">
                <Icon icon={faSpinner} spin/>
                <H5>Duplicerar mall</H5>
              </Flexbox>
            </CardContent>
          </Card>
        </Modal>)}
    </>);
};
export default ScheduleListRow;
