/**
 * @generated SignedSource<<d9c2ac9bd8d0c9054cb4d9cd3b72dca7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "CreateDispatchPayload",
            "kind": "LinkedField",
            "name": "createDispatch",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dispatch",
                    "kind": "LinkedField",
                    "name": "dispatch",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftVacancyNotifyModal_createDispatchMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleShiftVacancyNotifyModal_createDispatchMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "85a3bad02f43a3e881fe4e66dfd4fe20",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftVacancyNotifyModal_createDispatchMutation",
            "operationKind": "mutation",
            "text": "mutation ScheduleShiftVacancyNotifyModal_createDispatchMutation(\n  $model: CreateDispatchInput!\n) {\n  createDispatch(model: $model) {\n    dispatch {\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "617fd31e3f7ec5024aed6323312e1382";
export default node;
