/**
 * @generated SignedSource<<9eaecae7edf32233b3798abf1d5d93fb>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "typeFilter"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleTemplateInformationPeriodsQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "id",
                            "variableName": "id"
                        },
                        {
                            "kind": "Variable",
                            "name": "typeFilter",
                            "variableName": "typeFilter"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "ScheduleTemplateInformationPeriods_periods"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleTemplateInformationPeriodsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "scheduleTemplateId",
                            "variableName": "id"
                        },
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "types",
                                    "variableName": "typeFilter"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "typeFilter"
                        }
                    ],
                    "concreteType": "ScheduleTimesByTemplatePeriodPayload",
                    "kind": "LinkedField",
                    "name": "scheduleTemplateTimesPerPeriod",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleTemplatePeriodTimes",
                            "kind": "LinkedField",
                            "name": "periods",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleTemplatePeriod",
                                    "kind": "LinkedField",
                                    "name": "period",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                                    "kind": "LinkedField",
                                    "name": "timesByEntityAndAssignmentType",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "unit",
                                                    "value": "HOUR"
                                                }
                                            ],
                                            "concreteType": "TimeSpanUnitValue",
                                            "kind": "LinkedField",
                                            "name": "assignedDurationWithoutUnpaidBreaks",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "unit",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "value",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": "assignedDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AssignmentType",
                                            "kind": "LinkedField",
                                            "name": "assignmentType",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "title",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "schedulableEntity",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "color",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "displayName",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "ISchedulableEntity",
                                                    "abstractKey": "__isISchedulableEntity"
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v1 /*: any*/)
                                                    ],
                                                    "type": "Node",
                                                    "abstractKey": "__isNode"
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f392bb18646e883ccfebd937bf0b8b24",
            "id": null,
            "metadata": {},
            "name": "ScheduleTemplateInformationPeriodsQuery",
            "operationKind": "query",
            "text": "query ScheduleTemplateInformationPeriodsQuery(\n  $id: ID!\n  $typeFilter: [SchedulableEntityType!]\n) {\n  ...ScheduleTemplateInformationPeriods_periods_2jU29C\n}\n\nfragment ScheduleTemplateInformationPeriods_periods_2jU29C on Query {\n  scheduleTemplateTimesPerPeriod(scheduleTemplateId: $id, typeFilter: {types: $typeFilter}) {\n    periods {\n      period {\n        id\n        name\n      }\n      timesByEntityAndAssignmentType {\n        assignedDurationWithoutUnpaidBreaks(unit: HOUR) {\n          unit\n          value\n        }\n        assignmentType {\n          id\n          title\n        }\n        schedulableEntity {\n          __typename\n          ... on ISchedulableEntity {\n            __isISchedulableEntity: __typename\n            id\n            color\n            displayName\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "cb0afb02621d69dc2fc7bf999eaec9d9";
export default node;
