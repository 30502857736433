/**
 * @generated SignedSource<<7742862e302cd2f621ad81a9046f43bb>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "currentScheduleShiftVacancyesModel"
        }
    ], v1 = {
        "kind": "Variable",
        "name": "model",
        "variableName": "currentScheduleShiftVacancyesModel"
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftVacancyCountBadgeQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftVacancyCountBadge_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleShiftVacancyCountBadgeQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        (v1 /*: any*/),
                        {
                            "kind": "Literal",
                            "name": "where",
                            "value": {
                                "assignedEntityId": {
                                    "eq": null
                                }
                            }
                        }
                    ],
                    "concreteType": "ScheduleShiftConnection",
                    "kind": "LinkedField",
                    "name": "scheduleShifts",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalCount",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "cf970a9b077cbede65ba3d974ec6b4fe",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftVacancyCountBadgeQuery",
            "operationKind": "query",
            "text": "query ScheduleShiftVacancyCountBadgeQuery(\n  $currentScheduleShiftVacancyesModel: GetRunningScheduleRequestInput!\n) {\n  ...ScheduleShiftVacancyCountBadge_query_31u7SZ\n}\n\nfragment ScheduleShiftVacancyCountBadge_query_31u7SZ on Query {\n  scheduleShifts(model: $currentScheduleShiftVacancyesModel, where: {assignedEntityId: {eq: null}}) {\n    totalCount\n  }\n}\n"
        }
    };
})();
node.hash = "d8cc7f81186c0483c91dcda4a221cc8b";
export default node;
