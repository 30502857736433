import React from 'react';
import { useRoutes } from 'react-router-dom';
import AbsenceCalendarPage from './AbsenceCalendarPage/AbsenceCalendarPage';
export const AbsencesPages = () => {
    const routes = useRoutes([
        {
            path: '/',
            element: <AbsenceCalendarPage />,
        },
    ]);
    return routes;
};
export default AbsencesPages;
