/**
 * @generated SignedSource<<fd9149d35583061d6c5e02f1a55f2d85>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "MINUTE"
        }
    ], v2 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        }
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ScheduleTemplateEditTimeModal_shifts",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTemplateShiftConnection",
                "kind": "LinkedField",
                "name": "shifts",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleTemplateShiftEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleTemplateShift",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ScheduleTemplatePeriodDay",
                                        "kind": "LinkedField",
                                        "name": "day",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "dayInPeriod",
                                                "storageKey": null
                                            },
                                            (v0 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "endTimeOfDay",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "startTimeOfDay",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ScheduleTemplatePeriod",
                                        "kind": "LinkedField",
                                        "name": "period",
                                        "plural": false,
                                        "selections": [
                                            (v0 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ScheduleTemplateShiftBreak",
                                        "kind": "LinkedField",
                                        "name": "breaks",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": (v1 /*: any*/),
                                                "concreteType": "TimeSpanUnitValue",
                                                "kind": "LinkedField",
                                                "name": "offsetEnd",
                                                "plural": false,
                                                "selections": (v2 /*: any*/),
                                                "storageKey": "offsetEnd(unit:\"MINUTE\")"
                                            },
                                            {
                                                "alias": null,
                                                "args": (v1 /*: any*/),
                                                "concreteType": "TimeSpanUnitValue",
                                                "kind": "LinkedField",
                                                "name": "offsetStart",
                                                "plural": false,
                                                "selections": (v2 /*: any*/),
                                                "storageKey": "offsetStart(unit:\"MINUTE\")"
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "type",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ScheduleTemplate",
        "abstractKey": null
    };
})();
node.hash = "d9e2e49857a9fa87bca376fa1b50d439";
export default node;
