import React, { Suspense, useEffect, useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { matchPath, Outlet, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { App, Box, NoAccessPanel, PrivateRoute, Skeleton } from '@sonika-se/ui-kit/components';
import { useEnvironment, usePermissions, useViewport } from '@sonika-se/ui-kit/hooks';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { serviceIcons, serviceNames } from '@sonika-se/ui-kit';
import NotFound from '~/pages/NotFound';
import SchedulePages from '~/pages/SchedulePages/SchedulePages';
import { MySchedulePages } from '~/pages/SchedulePages/MySchedulePages';
import SettingsPages from '~/pages/SettingsPages/SettingsPages';
import PrintPages from '~/pages/PrintPages/PrintPages';
import AbsencesPages from './pages/AbsencePages/AbsencesPages';
import ScheduleListPage from './pages/SchedulePages/ScheduleListPage/ScheduleListPage';
import ScheduleTemplateDetailsPage from './pages/ScheduleTemplateDetailsPage/ScheduleTemplateDetailsPage';
import ScheduleRoutes from './routes/ScheduleRoutes';
const Redirect = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(props.href, { replace: true });
    }, [props.href]);
    return null;
};
export const Routes = () => {
    var _a, _b, _c, _d, _e, _f;
    const { size } = useViewport();
    const hasPermissions = usePermissions();
    const data = useLazyLoadQuery(graphql `
      query RoutesQuery {
        currentUser(ensureAuthorized: true) {
          me {
            avatar {
              id
              url(queryTransform: "width=68&height=68&mode=crop&scale=both")
            }
            color
            firstname
            lastname
            schedulableEntity {
              id
              schedulable
            }
          }
          services
          permissions
        }
        schedules {
          nodes {
            currentUserPermissions
            id
            name
          }
        }
      }
    `, {});
    const schedulePermissions = useMemo(() => data.schedules.nodes.map((schedule) => schedule.currentUserPermissions).flat(), [data]);
    const canEditSchedules = useMemo(() => hasPermissions(['EDIT_SCHEDULES']), []);
    const canEditTemplates = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) ||
        schedulePermissions.includes('EDIT_INFORMATION') ||
        schedulePermissions.includes('EDIT_TEMPLATES') ||
        schedulePermissions.includes('EDIT_PERMISSIONS') ||
        schedulePermissions.includes('PUBLISH_TEMPLATES'), [schedulePermissions]);
    const { currentUser } = data;
    const hasAccess = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.services) === null || _a === void 0 ? void 0 : _a.includes('SCHEDULE');
    const homeUrl = ((_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.currentUser) === null || _b === void 0 ? void 0 : _b.me) === null || _c === void 0 ? void 0 : _c.schedulableEntity) === null || _d === void 0 ? void 0 : _d.schedulable) ? '/my-schedule' : '/running-schedules';
    const location = useLocation();
    const navigate = useNavigate();
    const { frontUrls } = useEnvironment();
    const menuItems = [
        {
            path: '/running-schedules',
            text: 'Översikt',
        },
        {
            path: '/absences',
            permission: 'EDIT_SCHEDULES',
            text: 'Frånvaro',
        },
        {
            path: '/schedules',
            permission: 'EDIT_SCHEDULES',
            text: 'Scheman',
        },
        {
            path: size === 'small' ? '/settings' : '/settings/system',
            permission: 'EDIT_SCHEDULES',
            text: 'Inställningar',
        },
    ];
    if ((_f = (_e = currentUser === null || currentUser === void 0 ? void 0 : currentUser.me) === null || _e === void 0 ? void 0 : _e.schedulableEntity) === null || _f === void 0 ? void 0 : _f.schedulable) {
        menuItems.splice(1, 0, {
            path: '/my-schedule',
            text: 'Mitt schema',
        });
    }
    const appProps = useMemo(() => {
        var _a;
        return ({
            apps: currentUser
                ? ['CORE', ...currentUser.services].map((service) => ({
                    icon: serviceIcons[service],
                    name: serviceNames[service],
                    url: frontUrls[service],
                }))
                : null,
            homeUrl: homeUrl,
            icon: faCalendarAlt,
            menu: {
                items: menuItems
                    .filter((route) => route.permission === undefined || (currentUser === null || currentUser === void 0 ? void 0 : currentUser.permissions.includes(route.permission)))
                    .map((route) => ({
                    selected: matchPath({
                        path: route.path,
                        end: route.path === '/',
                    }, location.pathname) !== null,
                    text: route.text,
                    url: route.path,
                })),
                onNavigate: (url) => {
                    navigate(url);
                },
            },
            name: serviceNames.SCHEDULE,
            user: currentUser
                ? {
                    color: currentUser === null || currentUser === void 0 ? void 0 : currentUser.me.color,
                    firstname: currentUser === null || currentUser === void 0 ? void 0 : currentUser.me.firstname,
                    lastname: currentUser === null || currentUser === void 0 ? void 0 : currentUser.me.lastname,
                    imageUrl: (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.me.avatar) === null || _a === void 0 ? void 0 : _a.url,
                }
                : undefined,
        });
    }, [currentUser, location]);
    const noAppAccessContent = useMemo(() => (<App {...appProps} menu={null}>
        <Box display="flex" height="100%" padding="large">
          <NoAccessPanel />
        </Box>
      </App>), []);
    const noPageAccessContent = useMemo(() => (<Box display="flex" height="100%" padding="large">
        <NoAccessPanel />
      </Box>), []);
    const routes = useRoutes([
        {
            path: '/print/*',
            element: hasAccess ? <PrintPages /> : noAppAccessContent,
        },
        {
            path: '*',
            children: [
                {
                    path: '',
                    element: <Redirect href={homeUrl}/>,
                },
                {
                    path: 'absences/*',
                    element: (<PrivateRoute fallback={noPageAccessContent} permissions={['EDIT_SCHEDULES']}>
              <AbsencesPages />
            </PrivateRoute>),
                },
                {
                    path: 'schedule-templates',
                    element: <ScheduleListPage />,
                },
                {
                    path: 'schedule-templates/:scheduleTemplateId',
                    element: <ScheduleTemplateDetailsPage />,
                },
                {
                    path: 'my-schedule/*',
                    element: <MySchedulePages />,
                },
                {
                    path: 'running-schedules/*',
                    element: <SchedulePages />,
                },
                {
                    path: 'schedules/*',
                    element: <ScheduleRoutes />,
                },
                {
                    path: 'settings/*',
                    element: (<PrivateRoute fallback={noPageAccessContent} permissions={['EDIT_COMPANY']}>
              <SettingsPages />
            </PrivateRoute>),
                },
            ],
            element: hasAccess ? (<App {...appProps}>
          <Suspense fallback={<Skeleton height="100%" type="page"/>}>
            <Outlet />
          </Suspense>
        </App>) : (noAppAccessContent),
        },
        {
            path: '/*',
            element: <NotFound />,
        },
    ]);
    return routes;
};
export default Routes;
