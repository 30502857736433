/**
 * @generated SignedSource<<4fab9e76bd6892323b25ca3e7f5fbfd9>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleShiftAssetValidations_scheduleShift",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleShiftAssetValidation",
            "kind": "LinkedField",
            "name": "assetValidations",
            "plural": true,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AssetProficiencyValidationIcon_scheduleShiftAssetValidation"
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchedulableAsset",
                    "kind": "LinkedField",
                    "name": "schedulableAsset",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Asset",
                            "kind": "LinkedField",
                            "name": "asset",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "validateProficiency",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetProficiencyValidation",
                    "kind": "LinkedField",
                    "name": "assetProficiency",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isValid",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "ScheduleShift",
    "abstractKey": null
};
node.hash = "a77b9b0a361a9ed592dda8b32182b0dd";
export default node;
