import React, { useEffect } from 'react';
import { faSadTear } from '@fortawesome/pro-light-svg-icons';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { Accordion, AccordionItem, Box, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Placeholder, Card, Icon, Tooltip, } from '@sonika-se/ui-kit/components';
import { formatDate, formatDuration, toDate } from '@sonika-se/ui-kit/utils/time';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks';
import { Link } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import DisplaySchedulableEntity from '~/components/DisplaySchedulableEntity/DisplaySchedulableEntity';
const getRequestModel = (form) => {
    return {
        fromDate: form.startDate.toISOString(),
        toDate: form.endDate.toISOString(),
        scheduleIds: [],
        schedulableEntityId: form.schedulableEntityId,
        assignmentTypeIds: form.assignmentTypeIds,
        eventIds: form.eventsIds,
        withSchedulableEntityIds: form.withSchedulableEntityIds,
        withSchedulableEntitiesOfTypes: form.withSchedulableEntityTypes,
    };
};
export const SimultaneousShiftsResultList = (props) => {
    const { queryInput, onResult, onLoading } = props;
    //  useEffect(() => {
    //   if (onLoading) {
    //     onLoading()
    //   }
    //  }, [queryInput])
    const dataQuery = useLazyLoadQuery(graphql `
      query SimultaneousShiftsResultListQuery($model: FindSimultaneousSheduleShiftsInput!) {
        ...SimultaneousShiftsResultListFragment @arguments(model: $model)
      }
    `, {
        model: getRequestModel(queryInput),
    }, {
        fetchPolicy: 'network-only',
    });
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment SimultaneousShiftsResultListFragment on Query
      @argumentDefinitions(model: { type: "FindSimultaneousSheduleShiftsInput!" })
      @refetchable(queryName: "SimultaneousShiftsResultListRefetchQuery") {
        findSimultaneousSheduleShifts(model: $model) {
          schedulableEntity {
            ...DisplaySchedulableEntity
            ... on ISchedulableEntity {
              id
              displayName
            }
          }
          simultaneousAssignedEntities {
            assignedEntity {
              ... on ISchedulableEntity {
                id
                displayName
              }
              ... on SchedulableAsset {
                asset {
                  assetNumber
                }
              }
            }
            simultaneousAssignedDurationWithoutUnpaidBreaks(unit: HOUR) {
              unit
              value
            }
            simultaneousAssignedDurationIncludingBreaks(unit: HOUR) {
              unit
              value
            }
            assignedEntityShiftsDurationIncludingBreaks(unit: HOUR) {
              unit
              value
            }
            assignedEntityShiftsDurationWithoutUnpaidBreaks(unit: HOUR) {
              unit
              value
            }
            assignedSourceShiftDurationIncludingBreaks(unit: HOUR) {
              unit
              value
            }
            assignedSourceShiftDurationWithoutUnpaidBreaks(unit: HOUR) {
              unit
              value
            }
            simultaneousAssignedDaysCount
            simultaneousAssignedDates
            assignedSourceDates
            assignedSourceDays
            assignedEntityDates
            assignedEntityDays
            simultaneousShifts {
              simultaneousShifts {
                id
                assignedEntity {
                  ... on ISchedulableEntity {
                    id
                    displayName
                  }
                  ... on SchedulableAsset {
                    asset {
                      assetNumber
                    }
                  }
                }
                event {
                  id
                  name
                }
                schedule {
                  id
                  name
                }
                start
                end
              }
              shift {
                id
                assignedEntity {
                  ... on ISchedulableEntity {
                    id
                    displayName
                  }
                  ... on SchedulableAsset {
                    asset {
                      assetNumber
                    }
                  }
                }
                event {
                  id
                  name
                }
                schedule {
                  id
                  name
                }
                start
                end
              }
            }
          }
        }
      }
    `, dataQuery);
    useEffect(() => {
        onResult();
    }, [data]);
    if (!data) {
        return <span>Någonting stämmer inte...</span>;
    }
    const sourceEntity = data.findSimultaneousSheduleShifts.schedulableEntity;
    return (<>
      <DisplaySchedulableEntity size="large" schedulableEntity={sourceEntity}/>

      <Card shadow="small" style={{ marginTop: '20px' }}>
        <Box>
          {data.findSimultaneousSheduleShifts.simultaneousAssignedEntities.length === 0 && (<Placeholder height="auto" title="Inga samtidiga pass" icon={faSadTear}/>)}
          <Accordion>
            {data.findSimultaneousSheduleShifts.simultaneousAssignedEntities.map((schedulableEntity) => {
            var _a, _b;
            return (<AccordionItem padding="small" size="large" key={schedulableEntity.assignedEntity.id} title={`${schedulableEntity.assignedEntity.displayName} ${!!((_a = schedulableEntity.assignedEntity.asset) === null || _a === void 0 ? void 0 : _a.assetNumber)
                    ? '(' + ((_b = schedulableEntity.assignedEntity.asset) === null || _b === void 0 ? void 0 : _b.assetNumber) + ')'
                    : ''}`}>
                <Card shadow="small" background="gray">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeader>Namn</TableHeader>
                        <TableHeader>Pass</TableHeader>
                        <TableHeader>
                          <Tooltip content="Antal dagar som pass startar på">Startdagar</Tooltip>
                        </TableHeader>
                        <TableHeader>
                          <Tooltip content="Antal datum som passen sträcker sig över">Datum</Tooltip>
                        </TableHeader>
                        <TableHeader>
                          <Tooltip content="Total tid utan obetalda raster">Utan raster</Tooltip>
                        </TableHeader>
                        <TableHeader>
                          <Tooltip content="Total tid inklusive raster">Total tid</Tooltip>
                        </TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{schedulableEntity.assignedEntity.displayName}</TableCell>
                        <TableCell>
                          {schedulableEntity.simultaneousShifts.flatMap((x) => x.simultaneousShifts).length}
                        </TableCell>
                        <TableCell>{schedulableEntity.assignedEntityDays.length}</TableCell>
                        <TableCell>{schedulableEntity.assignedEntityDates.length}</TableCell>
                        <TableCell>
                          {formatDuration({
                    hours: schedulableEntity.assignedEntityShiftsDurationWithoutUnpaidBreaks.value,
                })}
                        </TableCell>
                        <TableCell>
                          {formatDuration({
                    hours: schedulableEntity.assignedEntityShiftsDurationIncludingBreaks.value,
                })}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{sourceEntity.displayName}</TableCell>
                        <TableCell>{schedulableEntity.simultaneousShifts.length}</TableCell>
                        <TableCell>{schedulableEntity.assignedSourceDays.length}</TableCell>
                        <TableCell>{schedulableEntity.assignedSourceDates.length}</TableCell>
                        <TableCell>
                          {formatDuration({
                    hours: schedulableEntity.assignedSourceShiftDurationWithoutUnpaidBreaks.value,
                })}
                        </TableCell>
                        <TableCell>
                          {formatDuration({
                    hours: schedulableEntity.assignedSourceShiftDurationIncludingBreaks.value,
                })}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Gemensamt</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>
                          <Tooltip content={`Antal startdagar som ${sourceEntity.displayName} och ${schedulableEntity.assignedEntity.displayName} har gemensamt`}>
                            {schedulableEntity.simultaneousAssignedDaysCount}
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip content={`Antal datum som ${sourceEntity.displayName} och ${schedulableEntity.assignedEntity.displayName} är schemalagda samtidigt`}>
                            {schedulableEntity.simultaneousAssignedDates.length}
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {formatDuration({
                    hours: schedulableEntity.simultaneousAssignedDurationWithoutUnpaidBreaks.value,
                })}
                        </TableCell>
                        <TableCell>
                          {formatDuration({
                    hours: schedulableEntity.simultaneousAssignedDurationIncludingBreaks.value,
                })}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Accordion>
                    <AccordionItem size="medium" title="Pass">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableHeader>Namn</TableHeader>
                            <TableHeader>Starttid</TableHeader>
                            <TableHeader>Sluttid</TableHeader>
                            <TableHeader>Händelse</TableHeader>
                            <TableHeader></TableHeader>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {schedulableEntity.simultaneousShifts.map((simultaneousShiftContainer) => (<>
                              <TableRow key={simultaneousShiftContainer.shift.id}>
                                <TableCell>{simultaneousShiftContainer.shift.assignedEntity.displayName}</TableCell>
                                <TableCell>
                                  {formatDate(toDate(simultaneousShiftContainer.shift.start), 'dateTime')}
                                </TableCell>
                                <TableCell>
                                  {formatDate(toDate(simultaneousShiftContainer.shift.end), 'dateTime')}
                                </TableCell>
                                <TableCell>{simultaneousShiftContainer.shift.event.name}</TableCell>
                                <TableCell>
                                  <Link target={'_blank'} to={`/running-schedules/${simultaneousShiftContainer.shift.id}`}>
                                    <Tooltip content="Gå till passida">
                                      <Icon icon={faExternalLink}/>
                                    </Tooltip>
                                  </Link>
                                </TableCell>
                              </TableRow>
                              {simultaneousShiftContainer.simultaneousShifts.map((shift) => (<TableRow key={shift.id}>
                                  <TableCell>&nbsp;-&nbsp;{shift.assignedEntity.displayName}</TableCell>
                                  {/* <TableCell></TableCell> */}
                                  <TableCell>{formatDate(toDate(shift.start), 'dateTime')}</TableCell>
                                  <TableCell>{formatDate(toDate(shift.end), 'dateTime')}</TableCell>
                                  <TableCell>{shift.event.name}</TableCell>
                                  <TableCell>
                                    <Link target={'_blank'} to={`/running-schedules/${shift.id}`}>
                                      <Tooltip content="Gå till passida">
                                        <Icon icon={faExternalLink}/>
                                      </Tooltip>
                                    </Link>
                                  </TableCell>
                                </TableRow>))}
                            </>))}
                        </TableBody>
                      </Table>
                    </AccordionItem>
                  </Accordion>
                </Card>
              </AccordionItem>);
        })}
          </Accordion>
        </Box>
      </Card>

      {/* <pre>
          {JSON.stringify(data, null, 2)}
        </pre> */}
    </>);
};
export default SimultaneousShiftsResultList;
