/**
 * @generated SignedSource<<07c0c8b76c962bb2d4b2ada884b8b333>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "HOUR"
        }
    ], v1 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        }
    ], v2 = {
        "alias": null,
        "args": (v0 /*: any*/),
        "concreteType": "TimeSpanUnitValue",
        "kind": "LinkedField",
        "name": "absenceDurationWithoutUnpaidBreaks",
        "plural": false,
        "selections": (v1 /*: any*/),
        "storageKey": "absenceDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "RunningSchedulesInformationTableRow_scheduleTime",
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTimeAbsenceByReason",
                "kind": "LinkedField",
                "name": "absencesByReason",
                "plural": true,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Event",
                        "kind": "LinkedField",
                        "name": "reasonEvent",
                        "plural": false,
                        "selections": [
                            (v3 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v0 /*: any*/),
                "concreteType": "TimeSpanUnitValue",
                "kind": "LinkedField",
                "name": "assignedDurationWithoutUnpaidBreaks",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": "assignedDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
            },
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "schedulableEntity",
                "plural": false,
                "selections": [
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v3 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "color",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                            }
                        ],
                        "type": "ISchedulableEntity",
                        "abstractKey": "__isISchedulableEntity"
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "userId",
                                "storageKey": null
                            }
                        ],
                        "type": "SchedulableUser",
                        "abstractKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ScheduleTimeByEntityAndAssignmentType",
        "abstractKey": null
    };
})();
node.hash = "28737f1bf54e8791db72d2b1098268d4";
export default node;
