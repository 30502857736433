/**
 * @generated SignedSource<<6358b5f76e13817c8123ed05f534ba66>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SchedulePermissionsPanel_createSchedulePermissionMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateSchedulePermissionPayload",
                    "kind": "LinkedField",
                    "name": "createSchedulePermission",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfSchedulePermission",
                            "kind": "LinkedField",
                            "name": "schedulePermissionEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SchedulePermission",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "SchedulePermissionListItem_schedulePermission"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SchedulePermissionsPanel_createSchedulePermissionMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateSchedulePermissionPayload",
                    "kind": "LinkedField",
                    "name": "createSchedulePermission",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfSchedulePermission",
                            "kind": "LinkedField",
                            "name": "schedulePermissionEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SchedulePermission",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "permissions",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Role",
                                            "kind": "LinkedField",
                                            "name": "role",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Schedule",
                                            "kind": "LinkedField",
                                            "name": "schedule",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "currentUserPermissions",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "scheduleId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "firstname",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "lastname",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "350f743f371a316b67d56491f6ae7746",
            "id": null,
            "metadata": {},
            "name": "SchedulePermissionsPanel_createSchedulePermissionMutation",
            "operationKind": "mutation",
            "text": "mutation SchedulePermissionsPanel_createSchedulePermissionMutation(\n  $model: CreateSchedulePermissionInput!\n) {\n  createSchedulePermission(model: $model) {\n    schedulePermissionEdge {\n      node {\n        ...SchedulePermissionListItem_schedulePermission\n        id\n      }\n    }\n  }\n}\n\nfragment SchedulePermissionListItem_schedulePermission on SchedulePermission {\n  id\n  permissions\n  role {\n    id\n    name\n  }\n  schedule {\n    currentUserPermissions\n    id\n  }\n  scheduleId\n  user {\n    id\n    firstname\n    lastname\n  }\n}\n"
        }
    };
})();
node.hash = "1a9910adcc9451ce2921ab8624b35c6c";
export default node;
