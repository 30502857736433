/**
 * @generated SignedSource<<bd623d6b899db8ecf779eae432c9b269>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleTemplateShiftClash_scheduleTemplateShift",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentType",
            "kind": "LinkedField",
            "name": "assignmentType",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleTemplatePeriodDay",
            "kind": "LinkedField",
            "name": "day",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weekday",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTimeOfDay",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleTemplatePeriod",
            "kind": "LinkedField",
            "name": "period",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTimeOfDay",
            "storageKey": null
        }
    ],
    "type": "ScheduleTemplateShift",
    "abstractKey": null
};
node.hash = "527e00a0182e677e9099624ee334b8de";
export default node;
