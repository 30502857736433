/**
 * @generated SignedSource<<d128938b14c91b3e08e90d0464a51f15>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SchedulePermissionsPanel_schedule",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SchedulePermissionList_schedule"
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentUserPermissions",
            "storageKey": null
        }
    ],
    "type": "Schedule",
    "abstractKey": null
};
node.hash = "146c51fe8ad4266527bc7c51d76d9336";
export default node;
