/**
 * @generated SignedSource<<cf6cda217dc4e6d1ed6b7f3bbd8b1780>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RunningSchedulesSelectScheduleForm_query",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "filterModel",
                    "value": {
                        "withCurrentUserPermissions": [
                            "EDIT_RUNNING"
                        ]
                    }
                }
            ],
            "concreteType": "ScheduleConnection",
            "kind": "LinkedField",
            "name": "schedules",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "schedules(filterModel:{\"withCurrentUserPermissions\":[\"EDIT_RUNNING\"]})"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "99e14d01ec211f0252198fc355f721f1";
export default node;
