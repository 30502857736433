import React from 'react';
import { Flexbox, H2, Icon, Card, CardContent } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles((theme) => ({
    container: {
        padding: '.5rem',
        height: '100%',
        textAlign: 'center',
        '&> *': {
            margin: 'auto',
        },
        display: 'flex',
        color: ({ appearance }) => appearance === 'primary' ? theme.colors.foreground.primary : theme.colors.foreground.tertiary,
    },
    icon: {
        color: ({ appearance }) => (appearance === 'primary' ? theme.colors.primary : theme.colors.foreground.placeholder),
    },
}));
export const NoDataPlaceholder = (props) => {
    const { icon, children, appearance = 'primary' } = props;
    const classes = useStyles({ appearance });
    return (<Card shadow="none" background={appearance === 'gray' ? 'gray' : 'white'} className={classes.container}>
      <CardContent padding="large">
        <Flexbox align="center" justify="center" direction="column" gap="xsmall">
          <H2>
            <Icon icon={icon} className={classes.icon}/>
          </H2>
          {children}
        </Flexbox>
      </CardContent>
    </Card>);
};
export default NoDataPlaceholder;
