import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
export const User = (props) => {
    const user = useFragment(graphql `
      fragment User_user on User {
        id
        firstname
        lastname
      }
    `, props.user);
    return <strong style={{ fontWeight: 'bold' }}>{`${user.firstname} ${user.lastname}`}</strong>;
};
export default User;
