import React from 'react';
import { useIntl } from 'react-intl';
import { useFragment, graphql } from 'react-relay/hooks';
export const SchedulableEntity = (props) => {
    const data = useFragment(graphql `
      fragment SchedulableEntity_schedulableEntity on SchedulableEntity {
        ... on ISchedulableEntity {
          id
          displayName
        }
      }
    `, props.schedulableEntity);
    const intl = useIntl();
    return (<span>
      {(data === null || data === void 0 ? void 0 : data.displayName) ||
            intl.formatMessage({ id: 'general.misc.schedule_shift.vacant.singular', defaultMessage: 'Vakant' })}
    </span>);
};
export default SchedulableEntity;
