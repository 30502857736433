import React, { useState } from 'react';
import { faPrint, faSync } from '@fortawesome/pro-regular-svg-icons';
import { IconButton, Tooltip } from '@sonika-se/ui-kit/components';
// import { useApi, useEnvironment } from '@sonika-se/ui-kit/hooks'
import buildFrontendUrl from '~/utils/buildFrontendUrl';
import { useIntl } from 'react-intl';
const RunningSchedulePrintSchedule = () => {
    //const { restApiUrl } = useEnvironment()
    //const { downloadFile } = useApi()
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    return (<>
      <Tooltip content={loading
            ? intl.formatMessage({ defaultMessage: 'Genererar schema', id: 'RunningSchedulesHeader.creating' })
            : intl.formatMessage({ defaultMessage: 'Ladda ned schema', id: 'RunningSchedulesHeader.download' })}>
        <IconButton appearance="outlined" disabled={loading} icon={loading ? faSync : faPrint} onClick={() => {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 3000);
            const query = new URLSearchParams(window.location.search);
            const printUrl = buildFrontendUrl(`/print/running-schedules${query.toString() ? `?${query.toString()}` : ''}`);
            window.open(printUrl);
            /*
              const query = new URLSearchParams(window.location.search)
              const printUrl = buildFrontendUrl(`/print/running-schedules${query.toString() ? `?${query.toString()}` : ''}`)
              downloadFile(
                `${restApiUrl}/screenshot/capture?url=${encodeURIComponent(printUrl)}`,
                'schedules.pdf',
              ).finally(() => {
                setLoading(false)
              })
            */
        }} spin={loading}/>
      </Tooltip>
    </>);
};
export default RunningSchedulePrintSchedule;
