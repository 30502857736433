import React, { Suspense } from 'react';
import { Panel, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import ScheduleShiftTable from '~/tables/ScheduleShiftTable';
export const ScheduleShiftTablePanel = (props) => {
    const { scheduleRef } = props;
    const schedule = useFragment(graphql `
      fragment ScheduleShiftTablePanel_schedule on Schedule
      @argumentDefinitions(runningScheduleModel: { type: "GetRunningScheduleRequestInput!" }) {
        ...ScheduleShiftTable_schedule @arguments(runningScheduleModel: $runningScheduleModel)
      }
    `, scheduleRef);
    return (<Panel title="Kommande pass">
      <Suspense fallback={<Skeleton type="table"/>}>
        <ScheduleShiftTable scheduleRef={schedule}/>
      </Suspense>
    </Panel>);
};
export default ScheduleShiftTablePanel;
