/**
 * @generated SignedSource<<762d7883ba27b2380710c34f24445027>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "after"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "checkedWhere"
    }, v2 = {
        "defaultValue": 100,
        "kind": "LocalArgument",
        "name": "first"
    }, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    }, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "where"
    }, v5 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v6 = {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
    }, v7 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    }, v8 = {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v11 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "SchedulableEntityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v9 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v10 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                        (v10 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "firstname",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastname",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "SchedulableUser",
                            "abstractKey": null
                        },
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v10 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Asset",
                                    "kind": "LinkedField",
                                    "name": "asset",
                                    "plural": false,
                                    "selections": [
                                        (v10 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "SchedulableAsset",
                            "abstractKey": null
                        },
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v10 /*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleTemplateResourceFilterList_resourcesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                (v6 /*: any*/),
                                {
                                    "kind": "Variable",
                                    "name": "checkedWhere",
                                    "variableName": "checkedWhere"
                                },
                                (v7 /*: any*/),
                                (v8 /*: any*/)
                            ],
                            "kind": "FragmentSpread",
                            "name": "ScheduleTemplateResourceFilterList_resources"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v4 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ScheduleTemplateResourceFilterList_resourcesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": "checked",
                                    "args": [
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "kind": "Variable",
                                            "name": "where",
                                            "variableName": "checkedWhere"
                                        }
                                    ],
                                    "concreteType": "SchedulableEntityConnection",
                                    "kind": "LinkedField",
                                    "name": "schedulableEntities",
                                    "plural": false,
                                    "selections": (v11 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/)
                                    ],
                                    "concreteType": "SchedulableEntityConnection",
                                    "kind": "LinkedField",
                                    "name": "schedulableEntities",
                                    "plural": false,
                                    "selections": (v11 /*: any*/),
                                    "storageKey": null
                                }
                            ],
                            "type": "Schedule",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "486fdb2dd77ca7fb4584ec567f50e281",
            "id": null,
            "metadata": {},
            "name": "ScheduleTemplateResourceFilterList_resourcesQuery",
            "operationKind": "query",
            "text": "query ScheduleTemplateResourceFilterList_resourcesQuery(\n  $after: String\n  $checkedWhere: SchedulableEntityFilterInput\n  $first: Int = 100\n  $where: SchedulableEntityFilterInput\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ScheduleTemplateResourceFilterList_resources_221h22\n    id\n  }\n}\n\nfragment ScheduleTemplateResourceFilterList_resources_221h22 on Schedule {\n  id\n  checked: schedulableEntities(after: $after, first: $first, where: $checkedWhere) {\n    edges {\n      node {\n        __typename\n        ... on SchedulableUser {\n          id\n          user {\n            id\n            firstname\n            lastname\n          }\n        }\n        ... on SchedulableAsset {\n          id\n          asset {\n            id\n            name\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n  schedulableEntities(after: $after, first: $first, where: $where) {\n    edges {\n      node {\n        __typename\n        ... on SchedulableUser {\n          id\n          user {\n            id\n            firstname\n            lastname\n          }\n        }\n        ... on SchedulableAsset {\n          id\n          asset {\n            id\n            name\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "b27613eedbae2a6a18974b94f8680deb";
export default node;
