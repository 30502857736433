/**
 * @generated SignedSource<<cbb979e5abe158d368b154bd91a94481>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Asset",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        }
    ],
    "type": "Asset",
    "abstractKey": null
};
node.hash = "62afd984ba20e77d3fae936c1922a9af";
export default node;
