import React from 'react';
import { Accordion, Card, CardContent, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import SchedulePermissionListItem from '../pages/SchedulePages/components/EditScheduleModal/SchedulePermissionListItem';
export const SchedulePermissionList = (props) => {
    const { scheduleRef } = props;
    const { permissions } = useFragment(graphql `
      fragment SchedulePermissionList_schedule on Schedule
      @argumentDefinitions(after: { type: "String" }, first: { type: "Int", defaultValue: 50 }) {
        permissions(after: $after, first: $first)
          @connection(key: "SchedulePermissionList_schedule_permissions", filters: []) {
          edges {
            node {
              id
              ...SchedulePermissionListItem_schedulePermission
              role {
                name
              }
              user {
                firstname
                lastname
              }
            }
          }
        }
      }
    `, scheduleRef);
    return permissions.edges.length === 0 ? (<Placeholder text="Rättigheter saknas"/>) : (<Card background="gray" shadow="none">
      <CardContent>
        <Accordion>
          {permissions.edges
            .map((x) => x)
            .sort((a, b) => {
            const getValue = (item) => {
                if (a.node.user) {
                    return `_${a.node.user.firstname} ${a.node.user.lastname}`;
                }
                if (a.node.role) {
                    return `__${a.node.role.name}`;
                }
                return 'xxxxxx';
            };
            const aValue = getValue(a);
            const bValue = getValue(b);
            return aValue.localeCompare(bValue);
        })
            .map(({ node: permission }) => (<SchedulePermissionListItem key={permission.id} schedulePermissionRef={permission}/>))}
        </Accordion>
      </CardContent>
    </Card>);
};
export default SchedulePermissionList;
