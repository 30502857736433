/**
 * @generated SignedSource<<f2b1ae34035003c0276c83689787c51c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "endDate"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "startDate"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "startPeriodId"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "typeFilter"
        }
    ], v1 = {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
    }, v2 = {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
    }, v3 = {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
    }, v4 = {
        "kind": "Variable",
        "name": "startPeriodId",
        "variableName": "startPeriodId"
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleTemplateInformationResource_scheduleTimesQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "kind": "Variable",
                            "name": "typeFilter",
                            "variableName": "typeFilter"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "ScheduleTemplateInformationResource_scheduleTimes"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleTemplateInformationResource_scheduleTimesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "fields": [
                                {
                                    "kind": "Literal",
                                    "name": "dryRun",
                                    "value": false
                                },
                                (v1 /*: any*/),
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "kind": "ObjectValue",
                            "name": "model"
                        },
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "types",
                                    "variableName": "typeFilter"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "typeFilter"
                        }
                    ],
                    "concreteType": "ScheduleTimesPayload",
                    "kind": "LinkedField",
                    "name": "scheduleTemplateTimes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                            "kind": "LinkedField",
                            "name": "timesByEntityAndAssignmentType",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "unit",
                                            "value": "HOUR"
                                        }
                                    ],
                                    "concreteType": "TimeSpanUnitValue",
                                    "kind": "LinkedField",
                                    "name": "assignedDurationWithoutUnpaidBreaks",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "unit",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "value",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "assignedDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "assignmentType",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "schedulableEntity",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "color",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "displayName",
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "ISchedulableEntity",
                                            "abstractKey": "__isISchedulableEntity"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v5 /*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "1a297130af51e344fbdbb17276b46302",
            "id": null,
            "metadata": {},
            "name": "ScheduleTemplateInformationResource_scheduleTimesQuery",
            "operationKind": "query",
            "text": "query ScheduleTemplateInformationResource_scheduleTimesQuery(\n  $endDate: DateTime!\n  $id: ID!\n  $startDate: DateTime!\n  $startPeriodId: ID!\n  $typeFilter: [SchedulableEntityType!]\n) {\n  ...ScheduleTemplateInformationResource_scheduleTimes_3N107r\n}\n\nfragment ScheduleTemplateInformationResource_scheduleTimes_3N107r on Query {\n  scheduleTemplateTimes(model: {startDate: $startDate, endDate: $endDate, dryRun: false, id: $id, startPeriodId: $startPeriodId}, typeFilter: {types: $typeFilter}) {\n    timesByEntityAndAssignmentType {\n      assignedDurationWithoutUnpaidBreaks(unit: HOUR) {\n        unit\n        value\n      }\n      assignmentType {\n        id\n        title\n      }\n      schedulableEntity {\n        __typename\n        ... on ISchedulableEntity {\n          __isISchedulableEntity: __typename\n          id\n          color\n          displayName\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "e39f5e1bd720637b91e2ea7822c5756d";
export default node;
