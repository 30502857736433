import React from 'react';
import { Chip } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
export const AssignmentTypeChip = (props) => {
    const { assignmentTypeRef, type } = props;
    const assignmentType = useFragment(graphql `
      fragment AssignmentTypeChip_assignmentType on AssignmentType {
        id
        title
      }
    `, assignmentTypeRef);
    return <Chip appearance="primary" text={assignmentType.title} type={type}/>;
};
export default AssignmentTypeChip;
