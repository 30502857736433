import React, { useState, Suspense, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import { endOfDay } from 'date-fns';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { Drawer, DrawerHeader, Field, IntervalInput, Skeleton, Flexbox, Input, ToggleButtonGroup, Select, MonthInput, IconButton, } from '@sonika-se/ui-kit/components';
import { RunningSchedulesInformation } from './components/RunningSchedulesInformation';
import RunningSchedulesPageContext from '../../RunningSchedulesPageContext';
import { graphql, useFragment } from 'react-relay/hooks';
import { useDebounce } from '@sonika-se/ui-kit/hooks';
import { RunningSchedulesInformationByMonth } from './components/RunningSchedulesInformationByMonth';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        top: {
            borderBottom: `${theme.helpers.pxToRem(1)} solid ${theme.colors.divider}`,
            padding: `${pxToRem(24)} ${pxToRem(24)}`,
        },
    };
});
export const RunningSchedulesInformationDrawer = (props) => {
    var _a;
    const { onClose, onTabChange, onTypeChange, queryRef } = props;
    const { informationDrawer, toDate, fromDate } = useContext(RunningSchedulesPageContext);
    const { show, tab, type } = informationDrawer;
    const { events } = useFragment(graphql `
      fragment RunningSchedulesInformationDrawer on Query {
        events(where: { isAbsence: { eq: true } }) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, queryRef);
    const [date, setDate] = useState({ fromDate: new Date(fromDate), toDate: endOfDay(new Date(toDate)) });
    const [selectedAbsenceFilters, setSelectedAbscenceFilters] = useState();
    const [filter, setFilter] = useState('');
    const delayedfilter = useDebounce(filter, 300);
    const [update, setUpdate] = useState();
    const classes = useStyles();
    const intl = useIntl();
    const [tabType, setTabType] = useState(type);
    const [month, setMonth] = useState(date.fromDate);
    const [searchText, setSearchText] = useState();
    const [refreshCount, setRefreshCount] = useState(0);
    return (<Drawer open={show} onClose={() => {
            onClose();
        }}>
      <DrawerHeader tabs={{
            divider: false,
            initialValue: type,
            onChange: (tab) => {
                setTabType(tab);
                if (tab == 'USER' || tab == 'ASSET') {
                    setFilter('');
                    onTypeChange(tab);
                }
            },
            tabs: [
                {
                    key: 'USER',
                    label: intl.formatMessage({
                        id: 'general.objects.user.name.plural',
                        defaultMessage: 'Användare',
                    }),
                    value: 'USER',
                },
                {
                    key: 'ASSET',
                    label: intl.formatMessage({
                        id: 'general.objects.asset.name.plural',
                        defaultMessage: 'Enheter',
                    }),
                    value: 'ASSET',
                },
                {
                    key: 'BYMONTH',
                    label: 'Tider per månad',
                    value: 'BYMONTH',
                },
            ],
        }} title={intl.formatMessage({
            defaultMessage: 'Schemainformation',
            id: 'general.misc.schedule_information.name.singular',
        })}/>
      <>
        {(tabType == 'USER' || tabType == 'ASSET') && (<>
            <Flexbox direction="column" gap="medium" className={classes.top}>
              <Flexbox align="end" gap="medium">
                <Field label={intl.formatMessage({
                id: 'general.misc.period.selected_period',
                defaultMessage: 'Vald period',
            })} style={{ flex: 1 }}>
                  <IntervalInput value={date} onChange={(value) => {
                setDate(value);
                setUpdate(Date.now());
            }} onUpdate={() => {
                setUpdate(Date.now());
            }}/>
                </Field>
                <Field label={intl.formatMessage({ id: 'general.actions.search', defaultMessage: 'Sök' })} style={{ flex: 1 }}>
                  <Input placeholder={intl.formatMessage({ id: 'general.actions.search', defaultMessage: 'Sök' })} value={filter} onChange={(value) => setFilter(value)}/>
                </Field>
              </Flexbox>
              <ToggleButtonGroup width="full" type="radio" inputName="information-tabs" onChange={(event) => {
                onTabChange(event.target.value);
            }} defaultValue={tab} options={[
                {
                    label: intl.formatMessage({
                        id: 'RunningSchedulesInformationDrawer.all_times',
                        defaultMessage: 'Alla tider',
                    }),
                    value: 'all',
                },
                {
                    label: type === 'USER'
                        ? intl.formatMessage({ id: 'ScheduleInformation.absence', defaultMessage: 'Frånvaro' })
                        : intl.formatMessage({ id: 'ScheduleInformation.changes', defaultMessage: 'Avvikelser' }),
                    value: 'absences',
                },
            ]}/>
              {tab === 'absences' && (<Select stayOpenOnSelect multiple selectedItems={selectedAbsenceFilters} placeholder={intl.formatMessage({
                    id: 'RunningSchedulesInformationDrawer.select_absences',
                    defaultMessage: 'Välj frånvarotyper',
                })} onChange={(values) => {
                    setSelectedAbscenceFilters(values);
                }} options={(_a = events === null || events === void 0 ? void 0 : events.edges) === null || _a === void 0 ? void 0 : _a.map(({ node }) => {
                    return {
                        value: node.id,
                        label: node.name,
                    };
                })}/>)}
            </Flexbox>
            <Suspense fallback={<Skeleton type="table" height="100%"/>}>
              <RunningSchedulesInformation date={date} filter={delayedfilter} selectedAbsenceFilters={selectedAbsenceFilters} update={update}/>
            </Suspense>
          </>)}
        <>
          {tabType == 'BYMONTH' && (<>
              <div style={{ padding: '1rem 1.5rem', overflow: 'auto', flex: 'auto' }}>
                <Flexbox align="end" gap="medium">
                  <Field label="Månad" style={{ flex: 1 }}>
                    <MonthInput defaultValue={month} onChange={(value) => {
                if (value) {
                    setMonth(value);
                }
            }}/>
                  </Field>
                  <Field label="Användare" style={{ flex: 1 }}>
                    <Input placeholder="Sök" delay={500} onChange={(value) => {
                setSearchText(value);
            }}/>
                  </Field>
                  <IconButton icon={faSync} onClick={() => setRefreshCount(refreshCount + 1)}/>
                </Flexbox>
                <Suspense fallback={<Skeleton type="loader" height="100%"/>}>
                  <RunningSchedulesInformationByMonth refreshCount={refreshCount} month={month} searchFilter={searchText}/>
                </Suspense>
              </div>
            </>)}
        </>
      </>
    </Drawer>);
};
export default RunningSchedulesInformationDrawer;
