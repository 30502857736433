import React, { useMemo } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { DataTable, Flexbox, Placeholder } from '@sonika-se/ui-kit/components';
import Avatar from '~/shared/Avatar';
export const ScheduleAssignmentTypeResourceTable = (props) => {
    const { scheduleAssignmentTypeRef } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment ScheduleAssignmentTypeResourceTable_scheduleAssignmentType on ScheduleAssignmentType
      @argumentDefinitions(after: { type: "String" }, first: { type: "Int", defaultValue: 50 })
      @refetchable(queryName: "ScheduleAssignmentTypeResourceTable_scheduleAssignmentTypeQuery") {
        resources(after: $after, first: $first)
          @connection(key: "ScheduleAssignmentTypeResourceTable_scheduleAssignmentType_resources", filters: []) {
          edges {
            node {
              schedulableEntity {
                ... on SchedulableAsset {
                  asset {
                    ...Avatar_iHaveAvatar
                    name
                  }
                }
                ... on SchedulableUser {
                  user {
                    ...Avatar_iHaveAvatar
                    firstname
                    lastname
                  }
                }
              }
            }
          }
        }
      }
    `, scheduleAssignmentTypeRef);
    const resources = useMemo(() => data.resources.edges.map(({ node: resource }) => ({
        name: resource.schedulableEntity,
    })), [data]);
    return resources.length === 0 ? (<Placeholder text="Inga användare/enheter."/>) : (<DataTable columns={{
            name: {
                render: (schedulableEntity) => (<Flexbox align="center" direction="row" gap="xsmall">
              <Avatar iHaveAvatarRef={schedulableEntity.asset || schedulableEntity.user} size="xsmall"/>
              <span>
                {schedulableEntity.asset
                        ? `${schedulableEntity.asset.name}`
                        : `${schedulableEntity.user.firstname} ${schedulableEntity.user.lastname}`}
              </span>
            </Flexbox>),
                text: 'Namn',
            },
        }} loading={isLoadingNext} onPaginate={() => {
            loadNext(50);
        }} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={resources}/>);
};
export default ScheduleAssignmentTypeResourceTable;
