import React, { useState } from 'react';
import { graphql, useFragment, usePreloadedQuery } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { H6, Divider, Flexbox, IconButton, Collapse } from '@sonika-se/ui-kit/components';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { schedulableResourceFilterQueryColorListQuery } from '../SchedulableResourceFilter';
import SchedulableEntityColorRow from '../../../pages/SchedulePages/components/SchedulableEntityColorRow/SchedulableEntityColorRow';
const useStyles = createUseStyles(() => ({
    assignmentTypeColumnRoot: {
        '&:not(:first-child)': {
            marginTop: '1rem',
        },
    },
}));
const AssignmentTypeColumn = (props) => {
    const { assignmentType, resources } = props;
    const [open, setOpen] = useState(true);
    const [updatedColor, setUpdatedColor] = useState({});
    const classes = useStyles();
    return (<>
      <Flexbox align="center" justify="space" className={classes.assignmentTypeColumnRoot}>
        <H6>{assignmentType.title}</H6>
        <IconButton icon={open ? faAngleUp : faAngleDown} appearance="text" color="dark" onClick={() => setOpen(!open)}/>
      </Flexbox>
      <Divider />
      <Collapse open={open}>
        {resources.map((resource) => (<SchedulableEntityColorRow color={updatedColor[resource.id] || (resource === null || resource === void 0 ? void 0 : resource.color)} type={(resource === null || resource === void 0 ? void 0 : resource.name) ? 'asset' : 'user'} id={resource === null || resource === void 0 ? void 0 : resource.id} name={(resource === null || resource === void 0 ? void 0 : resource.name) ? resource === null || resource === void 0 ? void 0 : resource.name : `${resource === null || resource === void 0 ? void 0 : resource.firstname} ${resource === null || resource === void 0 ? void 0 : resource.lastname}`} key={resource.id} onChange={(colorObject) => setUpdatedColor(Object.assign(Object.assign({}, updatedColor), { [resource.id]: colorObject.hex }))}/>))}
      </Collapse>
    </>);
};
export const SchedulableResourceEditColor = (props) => {
    const { type = 'all', queryRef } = props;
    const preloadedData = usePreloadedQuery(schedulableResourceFilterQueryColorListQuery, queryRef);
    const { schedulableEntities } = useFragment(graphql `
      fragment SchedulableResourceEditColor_resources on Query
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        schedulableEntities(after: $after, first: $first) {
          edges {
            node {
              ... on ISchedulableEntity {
                resourceInScheduleAssignmentTypes {
                  edges {
                    node {
                      id
                      scheduleAssignmentTypeId
                      scheduleAssignmentType {
                        assignmentType {
                          id
                          title
                        }
                      }
                    }
                  }
                }
              }
              ... on SchedulableUser {
                id
                user {
                  id
                  firstname
                  lastname
                  color
                }
              }
              ... on SchedulableAsset {
                id
                asset {
                  id
                  name
                  color
                }
              }
            }
          }
        }
      }
    `, preloadedData);
    const filterTypes = (schedulableEntities) => {
        switch (type) {
            case 'USER': {
                return schedulableEntities.filter(({ node: entity }) => entity.user);
            }
            case 'ASSET': {
                return schedulableEntities.filter(({ node: entity }) => entity.asset);
            }
            default: {
                return schedulableEntities;
            }
        }
    };
    const assignmentTypesWithFilteredResources = filterTypes(schedulableEntities.edges).reduce((prev, cur) => {
        const assignmentTypes = prev;
        cur.node.resourceInScheduleAssignmentTypes.edges
            .filter((assignmentType, index, array) => array.indexOf(array.find((item) => item.node.scheduleAssignmentType.assignmentType.id ===
            assignmentType.node.scheduleAssignmentType.assignmentType.id)) === index)
            .forEach((assignmentType) => {
            var _a, _b;
            const id = assignmentType.node.scheduleAssignmentType.assignmentType.id;
            const prevAssignmentType = prev[id] || {
                resources: [],
                assignmentType: assignmentType.node.scheduleAssignmentType.assignmentType,
            };
            assignmentTypes[id] = Object.assign(Object.assign({}, prevAssignmentType), { resources: [...prevAssignmentType.resources, ((_a = cur.node) === null || _a === void 0 ? void 0 : _a.user) || ((_b = cur.node) === null || _b === void 0 ? void 0 : _b.asset)] });
        });
        return assignmentTypes;
    }, {});
    return (<div>
      {Object.keys(assignmentTypesWithFilteredResources).map((key) => {
            const element = assignmentTypesWithFilteredResources[key];
            return (<AssignmentTypeColumn assignmentType={element.assignmentType} key={`color_row_${element.assignmentType.id}`} resources={element.resources}/>);
        })}
    </div>);
};
export default SchedulableResourceEditColor;
