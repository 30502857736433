import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { string, object } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Field, Text, MultiSelect } from '@sonika-se/ui-kit/components';
export const validationSchema = object({
    scheduleId: string().required(),
});
export const RunningSchedulesDeleteShiftsWizardStep1 = (props) => {
    var _a, _b;
    const { data, id, onSubmit, query, state } = props;
    const { schedules } = useFragment(graphql `
      fragment RunningSchedulesDeleteShiftsWizardStep1_query on Query {
        schedules(filterModel: { withCurrentUserPermissions: [EDIT_RUNNING] }) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, query);
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: data,
        resolver: yupResolver(validationSchema),
    });
    return state === 'READ' ? (<Text>{(_b = (_a = schedules.edges.find(({ node: schedule }) => schedule.id === data.scheduleId)) === null || _a === void 0 ? void 0 : _a.node) === null || _b === void 0 ? void 0 : _b.name}</Text>) : (<Form id={id} onSubmit={handleSubmit((newData) => {
            onSubmit(newData);
        })}>
      <Text>Välj schema för bytet</Text>
      <Field label="Schema att ta bort pass inom">
        <Controller control={control} name="scheduleId" render={({ field }) => {
            var _a, _b;
            return (<MultiSelect error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.scheduleId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} defaultValue={field.value} placeholder="Välj schema" onChange={(value) => (value ? field.onChange(value[0]) : undefined)} options={schedules.edges.map(({ node: schedule }) => ({
                    label: schedule.name,
                    value: schedule.id,
                }))} value={field.value}/>);
        }}/>
      </Field>
    </Form>);
};
export default RunningSchedulesDeleteShiftsWizardStep1;
