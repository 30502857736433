import React from 'react';
import { useRoutes } from 'react-router';
import MySchedulePage from './MySchedule/MySchedulePage';
export const MySchedulePages = () => {
    const routes = useRoutes([
        {
            path: '/',
            element: <MySchedulePage />,
        },
    ]);
    return routes;
};
