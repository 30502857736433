/**
 * @generated SignedSource<<2b1ebd9e9233bf39205e295600e562df>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = [
        (v2 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftChangeRequestUpdateChangeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateScheduleShiftChangeRequestPayload",
                    "kind": "LinkedField",
                    "name": "updateScheduleShiftChangeRequest",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfScheduleShiftChangeRequest",
                            "kind": "LinkedField",
                            "name": "changeRequestEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShiftChangeRequest",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "ScheduleShiftChangeRequest_shift"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleShiftChangeRequestUpdateChangeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateScheduleShiftChangeRequestPayload",
                    "kind": "LinkedField",
                    "name": "updateScheduleShiftChangeRequest",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfScheduleShiftChangeRequest",
                            "kind": "LinkedField",
                            "name": "changeRequestEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShiftChangeRequest",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "comment",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "end",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "requestedBy",
                                            "plural": false,
                                            "selections": (v3 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "respondedAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "responseComment",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "respondedBy",
                                            "plural": false,
                                            "selections": (v3 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleShift",
                                            "kind": "LinkedField",
                                            "name": "scheduleShift",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Schedule",
                                                    "kind": "LinkedField",
                                                    "name": "schedule",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "currentUserPermissions",
                                                            "storageKey": null
                                                        },
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "scheduleShiftId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "start",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "ab6e050fd73cfdb5a542cc025d6c18e0",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftChangeRequestUpdateChangeMutation",
            "operationKind": "mutation",
            "text": "mutation ScheduleShiftChangeRequestUpdateChangeMutation(\n  $model: UpdateScheduleShiftChangeRequestRequestInput!\n) {\n  updateScheduleShiftChangeRequest(model: $model) {\n    changeRequestEdge {\n      node {\n        id\n        ...ScheduleShiftChangeRequest_shift\n      }\n    }\n  }\n}\n\nfragment ScheduleShiftChangeRequest_shift on ScheduleShiftChangeRequest {\n  comment\n  createdAt\n  end\n  id\n  requestedBy {\n    id\n    firstname\n    lastname\n  }\n  respondedAt\n  responseComment\n  respondedBy {\n    id\n    firstname\n    lastname\n  }\n  scheduleShift {\n    schedule {\n      currentUserPermissions\n      id\n    }\n    id\n  }\n  scheduleShiftId\n  start\n  status\n}\n"
        }
    };
})();
node.hash = "7a1fda4590c0ab04b7a205c7ae40f845";
export default node;
