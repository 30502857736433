import React, { useLayoutEffect } from 'react';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        content: ({ color, padding }) => ({
            backgroundColor: color === 'white' ? '#fff' : theme.colors.foreground.separation,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'auto',
            position: 'relative',
            padding: padding ? pxToRem(16) : 0,
        }),
        grid: ({ numColumns }) => ({
            display: 'grid',
            gridGap: '0.5rem',
            gridTemplateColumns: `repeat(${numColumns}, auto)`,
        }),
        header: {
            backgroundColor: '#FFFFFF',
            borderBottom: `${pxToRem(1)} solid #D5D6D8`,
            padding: pxToRem(16),
            [`@media (max-width: ${theme.breakPoints.low})`]: {
                padding: 0,
            },
        },
        main: {
            background: '#FFFFFF',
            display: 'flex',
            flex: 1,
            overflowY: 'auto',
        },
        sidebar: {
            background: '#F2F4F6',
            borderRight: `${pxToRem(1)} solid #DADADA`,
            display: 'flex',
            width: '20rem',
        },
    };
});
export const ScheduleLayout = (props) => {
    const { children } = props;
    const classes = useStyles({});
    return <div className={classes.root}>{children}</div>;
};
export const ScheduleLayoutContent = (props) => {
    const { border = false, children, padding = true, color = 'gray' } = props;
    const classes = useStyles({ padding, color });
    useLayoutEffect(() => {
        // Click and drag funktionality from: https://codepen.io/toddwebdev/pen/yExKoj
        const slider = document.getElementById('schedule-layout-content');
        let isDown = false;
        let startX;
        let scrollLeft;
        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown)
                return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }, []);
    return (<div className={classes.content} id="schedule-layout-content">
      {children}
    </div>);
};
export const ScheduleLayoutGrid = (props) => {
    const { children, numColumns } = props;
    const classes = useStyles({ numColumns });
    return <div className={classes.grid}>{children}</div>;
};
export const ScheduleLayoutHeader = (props) => {
    const { children } = props;
    const classes = useStyles({});
    return <div className={classes.header}>{children}</div>;
};
export const ScheduleLayoutMain = (props) => {
    const { children } = props;
    const classes = useStyles({});
    return <div className={classes.main}>{children}</div>;
};
export const ScheduleLayoutSidebar = (props) => {
    const { children } = props;
    const classes = useStyles({});
    return <div className={classes.sidebar}>{children}</div>;
};
