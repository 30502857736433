import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { useMutation, graphql } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import { Field, Form, FormFooter, Input, ModalToSheet, ModalToSheetHeader, ModalToSheetContent, } from '@sonika-se/ui-kit/components';
const defaultValues = {
    name: '',
};
const validationSchema = object({
    name: string().required(),
});
export const NewScheduleTemplatePeriodModal = (props) => {
    var _a, _b;
    const { onCancel, onSubmit, periodId, scheduleTemplateId } = props;
    const intl = useIntl();
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    const [createScheduleTemplatePeriod, isInFlight] = useMutation(graphql `
    mutation NewScheduleTemplatePeriodModalCreateMutation($model: CreateScheduleTemplatePeriodRequestInput!) {
      createScheduleTemplatePeriod(model: $model) {
        periodEdge {
          node {
            scheduleTemplate {
              id
              periods(first: null, order: { order: ASC }) @connection(key: "ScheduleTemplate_periods") {
                edges {
                  node {
                    id
                    ...ScheduleTemplatePeriod_period
                  }
                }
                totalCount
              }
            }
          }
        }
      }
    }
  `);
    return (<ModalToSheet onClose={() => onCancel()}>
      <ModalToSheetHeader onClose={() => onCancel()} closeButtonLabel="Avbryt">
        {!periodId
            ? intl.formatMessage({
                id: 'general.misc.period.actions.create',
                defaultMessage: 'Lägg till period',
            })
            : intl.formatMessage({ id: 'general.misc.period.actions.change', defaultMessage: 'Ändra period' })}
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Form onSubmit={handleSubmit((formData) => {
            createScheduleTemplatePeriod({
                variables: {
                    model: {
                        scheduleTemplateId,
                        name: formData.name,
                    },
                },
                configs: [
                    {
                        connectionInfo: [
                            {
                                key: 'ScheduleTemplate_periods',
                                rangeBehavior: 'append',
                            },
                        ],
                        edgeName: 'periodEdge',
                        parentID: scheduleTemplateId,
                        type: 'RANGE_ADD',
                    },
                ],
                onCompleted: () => onSubmit(),
            });
        })}>
          <Field label={intl.formatMessage({ id: 'general.misc.period.fields.name.label', defaultMessage: 'Namn' })} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()}>
            <Controller control={control} name="name" render={({ field }) => {
            var _a;
            return (<Input error={(_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message.toString()} disabled={isInFlight} onChange={field.onChange} value={field.value}/>);
        }}/>
          </Field>
          <FormFooter disabled={isInFlight} onCancel={() => onCancel()} submitText={!periodId
            ? intl.formatMessage({ id: 'general.actions.create', defaultMessage: 'Lägg till' })
            : intl.formatMessage({ id: 'general.actions.update', defaultMessage: 'Redigera' })}/>
        </Form>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default NewScheduleTemplatePeriodModal;
