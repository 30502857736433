import React, { useState, useRef } from 'react';
import { Button, Popover, Icon, MenuList, MenuListItem } from '@sonika-se/ui-kit/components';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay/hooks';
export const ScheduleSwitch = (props) => {
    var _a;
    const { label, userRef } = props;
    const [showSwitchPage, setShowSwitchPage] = useState(false);
    const navigate = useNavigate();
    const switchPageRef = useRef();
    const intl = useIntl();
    const data = useFragment(graphql `
      fragment ScheduleSwitch_user on User {
        schedulableEntity {
          id
          schedulable
        }
      }
    `, userRef);
    const schedulable = (_a = data.schedulableEntity) === null || _a === void 0 ? void 0 : _a.schedulable;
    if (!schedulable) {
        return (<div ref={switchPageRef}>
        <Button appearance="text" color="dark" size="medium" disabled style={{ padding: 0, textAlign: 'left' }}>
          {label}
        </Button>
      </div>);
    }
    return (<>
      <div ref={switchPageRef}>
        <Button appearance="text" color="dark" size="medium" style={{ padding: 0, textAlign: 'left' }} onClick={() => {
            setShowSwitchPage(!showSwitchPage);
        }}>
          <Icon icon={faCaretDown} color="#848484" style={{ marginLeft: '.5rem', fontSize: '12px' }}/>
          {label}
        </Button>
      </div>
      <Popover anchorEl={showSwitchPage ? switchPageRef.current : undefined} onClose={() => setShowSwitchPage(false)} placement="bottom">
        <MenuList>
          <MenuListItem label={intl.formatMessage({
            defaultMessage: 'Alla scheman',
            id: 'general.objects.schedule.fields.all_schedules',
        })} onClick={() => navigate('/running-schedules')}/>
          <MenuListItem label={intl.formatMessage({
            defaultMessage: 'Mitt schema',
            id: 'general.objects.my_schedule.name.singular',
        })} onClick={() => navigate('/my-schedule')}/>
        </MenuList>
      </Popover>
    </>);
};
export default ScheduleSwitch;
