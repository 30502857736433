/**
 * @generated SignedSource<<0c5fd6143926eec219de296e67f0d5f2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "DeleteScheduleTemplatePayload",
            "kind": "LinkedField",
            "name": "deleteScheduleTemplate",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfScheduleTemplate",
                    "kind": "LinkedField",
                    "name": "templateEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleTemplate",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleTemplateTable_deleteScheduleTemplateMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleTemplateTable_deleteScheduleTemplateMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "25c6b83338545326cfdbdadb4dfefd86",
            "id": null,
            "metadata": {},
            "name": "ScheduleTemplateTable_deleteScheduleTemplateMutation",
            "operationKind": "mutation",
            "text": "mutation ScheduleTemplateTable_deleteScheduleTemplateMutation(\n  $model: DeleteScheduleTemplateRequestInput!\n) {\n  deleteScheduleTemplate(model: $model) {\n    templateEdge {\n      node {\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "60aa7a2e7224c7db0c4a3f820310ecb1";
export default node;
