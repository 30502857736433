import React, { useEffect, useMemo, useRef } from 'react';
import { faCheck, faSpinner, faSync, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Card, CardContent, Flexbox, Icon, Text } from '@sonika-se/ui-kit/components';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { graphql, usePreloadedQuery, useRefetchableFragment } from 'react-relay/hooks';
export const queuedJobStatusQuery = graphql `
  query QueuedJobStatusQuery($messageId: String!) {
    ...QueuedJobStatus_query @arguments(messageId: $messageId)
  }
`;
export const QueuedJobStatus = (props) => {
    const { preloadedQuery, onComplete, completeMessage = 'Färdig' } = props;
    const data = usePreloadedQuery(queuedJobStatusQuery, preloadedQuery);
    const [fragment, refetch] = useRefetchableFragment(graphql `
      fragment QueuedJobStatus_query on Query
      @argumentDefinitions(messageId: { type: "String!" })
      @refetchable(queryName: "QueuedJobStatus_queuedJobQuery") {
        queuedJobByMessageId(messageId: $messageId) {
          id
          status
          startTime
          endTime
          attachments {
            name
            url
          }
        }
      }
    `, data);
    const intervalRef = useRef(null);
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            refetch(null, { fetchPolicy: 'store-and-network' });
        }, 1000);
    }, [refetch]);
    const status = useMemo(() => { var _a; return (_a = fragment === null || fragment === void 0 ? void 0 : fragment.queuedJobByMessageId) === null || _a === void 0 ? void 0 : _a.status; }, [fragment]);
    useEffect(() => {
        if (status === 'CANCELLED' || status === 'COMPLETED' || status === 'FAILED') {
            clearInterval(intervalRef.current);
            onComplete && onComplete(fragment);
        }
    }, [intervalRef, status]);
    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);
    return (<Flexbox direction="column" gap="medium">
      <Card background="gray" shadow="none">
        <CardContent style={{ padding: '2rem' }}>
          <Flexbox align="center" direction="column" gap="small" justify="center">
            {!status && (<>
                <Icon icon={faSpinner} spin/>
                <Text size="small">Initierar</Text>
              </>)}
            {status === 'RUNNING' && (<>
                <Icon color={sonikaTheme.colors.primary} icon={faSync} spin/>
                <Text size="small">Jobbar...</Text>
              </>)}
            {status === 'CANCELLED' && (<>
                <Icon color={sonikaTheme.colors.system.stop} icon={faTimes}/>
                <Text size="small">Avbruten</Text>
              </>)}
            {status === 'FAILED' && (<>
                <Icon color={sonikaTheme.colors.system.stop} icon={faTimes}/>
                <Text size="small">Misslyckades</Text>
              </>)}
            {status === 'COMPLETED' && (<>
                <Icon color={sonikaTheme.colors.system.go} icon={faCheck}/>
                <Text size="small">{completeMessage}</Text>
              </>)}
          </Flexbox>
        </CardContent>
      </Card>
    </Flexbox>);
};
export default QueuedJobStatus;
