import React from 'react';
import { ModalToSheet, ModalToSheetContent, ModalToSheetHeader } from '@sonika-se/ui-kit/components';
import UpdateScheduleShiftAssigneesForm from '../../UpdateScheduleShiftAssigneesForm';
const RunningSchedulesDeleteAssignedResourceModal = (props) => {
    const { onCancel, preloadedQuery } = props;
    return (<ModalToSheet onClose={onCancel}>
      <ModalToSheetHeader onClose={onCancel} closeButtonLabel="Stäng">
        Gör pass vakanta
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <UpdateScheduleShiftAssigneesForm preloadedQuery={preloadedQuery} onCancel={onCancel}/>
      </ModalToSheetContent>
    </ModalToSheet>);
};
RunningSchedulesDeleteAssignedResourceModal.propTypes = {};
export default RunningSchedulesDeleteAssignedResourceModal;
