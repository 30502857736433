import React, { Fragment, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { faSpinner, faTags } from '@fortawesome/pro-regular-svg-icons';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { Checkbox, Label, Flexbox, Button, Icon, VisibleContainer, Divider, Placeholder, } from '@sonika-se/ui-kit/components';
import { FormattedMessage } from 'react-intl';
const useStyles = createUseStyles((theme) => ({
    container: {
        [`@media (max-width: ${theme.breakPoints.low})`]: {
            marginTop: '3rem',
        },
    },
    smallHeader: {
        color: theme.colors.foreground.tertiary,
    },
    resourceCheckbox: {
        marginRight: 10,
        cursor: 'pointer',
    },
    icon: {
        color: theme.colors.primary,
    },
}));
const TagsFilterList = (props) => {
    const { updateFilters, queryRef, selectedTags } = props;
    const classes = useStyles();
    const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment(graphql `
      fragment TagsFilterListFragment_query on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 20 }
        after: { type: "String" }
        where: { type: "TagFilterInput" }
      )
      @refetchable(queryName: "TagsFilterListFragment_refetchQuery") {
        tags(where: $where, order: { name: ASC }, first: $first, after: $after)
          @connection(key: "TagsFilterList_tags") {
          totalCount
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, queryRef);
    const [currentTags, setCurrentTags] = useState(selectedTags);
    const selectedTagIds = useMemo(() => {
        return currentTags.map((x) => x.id);
    }, [currentTags]);
    const unchecked = useMemo(() => data.tags.edges.filter((x) => !selectedTagIds.find((y) => y == x.node.id)), [selectedTagIds, data]);
    return (<>
      <Flexbox direction="column" gap="medium" className={classes.container}>
        {currentTags.length > 0 && (<Flexbox justify="space" align="center">
            <Label text="Valda" className={classes.smallHeader}/>
            <Button appearance="text" size="small" style={{ padding: 0 }} onClick={() => {
                setCurrentTags([]);
                updateFilters([], 'tagIds');
            }}>
              <FormattedMessage id="general.actions.clear" defaultMessage="Rensa"/>
            </Button>
          </Flexbox>)}
        {currentTags === null || currentTags === void 0 ? void 0 : currentTags.map((node) => {
            const { id, name } = node;
            return (<Flexbox key={`checkedTagId_${id}`} gap="xsmall" align="center">
              <Checkbox id={`checkedTagId_${id}`} checked={true} className={classes.resourceCheckbox} onChange={() => {
                    setCurrentTags(currentTags.filter((x) => x.id != id));
                    updateFilters(selectedTagIds.filter((x) => x != id), 'tagIds');
                }}/>
              <Label style={{ fontSize: '1rem' }} text={name} htmlFor={`checkedTagId_${id}`}/>
            </Flexbox>);
        })}
        {selectedTagIds.length > 0 && <Divider />}
        {unchecked === null || unchecked === void 0 ? void 0 : unchecked.map(({ node }, index) => {
            const { name, id } = node;
            return (<Fragment key={`tag_checkbox_${id}`}>
              <Flexbox gap="xsmall" align="center">
                <Checkbox id={`checkedTag_${id}`} checked={!!selectedTagIds.find((x) => x === id)} className={classes.resourceCheckbox} onChange={(event) => {
                    setCurrentTags([...currentTags, node]);
                    updateFilters([...selectedTagIds, id], 'tagIds');
                }}/>
                <Label style={{ fontSize: '1rem' }} text={name} htmlFor={`checkedTag_${id}`}/>
              </Flexbox>
              {hasNext && index + 1 === (unchecked === null || unchecked === void 0 ? void 0 : unchecked.length) && (<VisibleContainer callback={() => loadNext(20)}>
                  {isLoadingNext && (<Flexbox align="center" justify="center">
                      <Icon icon={faSpinner} spin/>
                    </Flexbox>)}
                </VisibleContainer>)}
            </Fragment>);
        })}
        {data.tags.totalCount === 0 && <Placeholder icon={faTags} text="Hittade inga taggar"/>}
      </Flexbox>
    </>);
};
export default TagsFilterList;
