/**
 * @generated SignedSource<<5ccfabbd3ca3ec4dfd0afa7ba45294e3>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditAssignmentTypesOnScheduleModal_schedule",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleAssignmentTypeConnection",
            "kind": "LinkedField",
            "name": "assignmentTypes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleAssignmentType",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "assignmentTypeId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleAssignmentTypeResourceConnection",
                            "kind": "LinkedField",
                            "name": "resources",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "totalCount",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Schedule",
    "abstractKey": null
};
node.hash = "d0fb05eabd37825f6078e4cb4abca6af";
export default node;
