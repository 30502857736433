import React, { Suspense, useRef, useState } from 'react';
import { faTags } from '@fortawesome/pro-regular-svg-icons';
import { faTags as faTagsSolid } from '@fortawesome/pro-solid-svg-icons';
import { IconButton, Flexbox, Input, Skeleton, Tooltip, PopoverToSheet, PopoverToSheetHeader, PopoverToSheetContent, Badge, } from '@sonika-se/ui-kit/components';
import { useDebounce } from '@sonika-se/ui-kit/hooks';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import TagsFilterList from './TagsFilterList';
const TagsFilter = (props) => {
    const { updateFilters, title, selectedTagIds } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filter, setFilter] = useState('');
    const delayedfilter = useDebounce(filter, 300);
    const open = Boolean(anchorEl);
    const defaultSelectedTagIds = useRef(selectedTagIds !== null && selectedTagIds !== void 0 ? selectedTagIds : []);
    const data = useLazyLoadQuery(graphql `
      query TagsFilterQuery($selectedTagIds: [ID]!, $where: TagFilterInput) {
        ...TagsFilterListFragment_query @arguments(where: $where)
        selectedTags: tags(where: { id: { in: $selectedTagIds } }) {
          nodes {
            id
            name
          }
        }
      }
    `, {
        selectedTagIds: defaultSelectedTagIds.current,
        where: delayedfilter ? { name: { contains: delayedfilter } } : undefined,
    }, {
        fetchPolicy: 'store-and-network',
    });
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const solidIcon = faTagsSolid;
    const lightIcon = faTags;
    return (<>
      <Tooltip content={title}>
        <Badge appearance={(selectedTagIds === null || selectedTagIds === void 0 ? void 0 : selectedTagIds.length) > 0 ? 'danger' : 'none'} text={`${selectedTagIds === null || selectedTagIds === void 0 ? void 0 : selectedTagIds.length}`}>
          <IconButton appearance={open ? 'filled' : 'outlined'} onClick={handleClick} icon={open ? solidIcon : lightIcon} size={'medium'}/>
        </Badge>
      </Tooltip>
      <PopoverToSheet size="auto" height="70vh" style={{ minWidth: '20.5rem' }} anchorEl={open ? anchorEl : null} onClose={handleClose}>
        <PopoverToSheetHeader onClose={handleClose}>
          <Flexbox direction="column" gap="xsmall">
            <div>{title}</div>
            <Input placeholder="Sök" value={filter} onChange={(value) => setFilter(value)}/>
          </Flexbox>
        </PopoverToSheetHeader>
        <PopoverToSheetContent>
          <Suspense fallback={<Skeleton type="table" height="10rem"/>}>
            <TagsFilterList selectedTags={data.selectedTags.nodes} queryRef={data} updateFilters={updateFilters}/>
          </Suspense>
        </PopoverToSheetContent>
      </PopoverToSheet>
    </>);
};
export default TagsFilter;
