/**
 * @generated SignedSource<<064fd544da5c4cee5ef0edfe409669e4>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RunningSchedulesDeleteShiftsWizard_query",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "filterModel",
                    "value": {
                        "withCurrentUserPermissions": [
                            "EDIT_RUNNING"
                        ]
                    }
                }
            ],
            "concreteType": "ScheduleConnection",
            "kind": "LinkedField",
            "name": "schedules",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Schedule",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "RunningSchedulesDeleteShiftsWizardStep2_schedule"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "schedules(filterModel:{\"withCurrentUserPermissions\":[\"EDIT_RUNNING\"]})"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RunningSchedulesDeleteShiftsWizardStep1_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "28ab51a7bb836056854d07deb13d81c6";
export default node;
