/**
 * @generated SignedSource<<6e80bb422391481d0912e57c011778a9>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "entitiesFilter"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "schedulesFilter"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "typeFilter"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "refetch": {
            "connection": null,
            "fragmentPathInResult": [],
            "operation": require('./RunningSchedulesInformation_scheduleTimesQuery.graphql')
        }
    },
    "name": "RunningSchedulesInformation_scheduleTimes",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "entitiesFilter",
                    "variableName": "entitiesFilter"
                },
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                },
                {
                    "kind": "Variable",
                    "name": "schedulesFilter",
                    "variableName": "schedulesFilter"
                },
                {
                    "kind": "Variable",
                    "name": "typeFilter",
                    "variableName": "typeFilter"
                }
            ],
            "concreteType": "ScheduleTimesPayload",
            "kind": "LinkedField",
            "name": "scheduleTimes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                    "kind": "LinkedField",
                    "name": "timesByEntityAndAssignmentType",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentType",
                            "kind": "LinkedField",
                            "name": "assignmentType",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RunningSchedulesInformationTable_scheduleTimes"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "20432087f50cfd99ea3927ddc2c5a99e";
export default node;
