/**
 * @generated SignedSource<<2e21048ef2a5f347b8012727048f7485>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "RunningSchedulesInformationTable_scheduleTimes",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                "kind": "LinkedField",
                "name": "timesByEntityAndAssignmentType",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentType",
                        "kind": "LinkedField",
                        "name": "assignmentType",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "schedulableEntity",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    (v0 /*: any*/)
                                ],
                                "type": "ISchedulableEntity",
                                "abstractKey": "__isISchedulableEntity"
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RunningSchedulesInformationTableRow_scheduleTime"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ScheduleTimesPayload",
        "abstractKey": null
    };
})();
node.hash = "a51e791a3563619b500f822bd6801b04";
export default node;
