/**
 * @generated SignedSource<<bdcf6a13a54567ec1b7bb84db5ffbd36>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PublishScheduleWizardPeriod_periods",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "order",
                    "value": {
                        "order": "ASC"
                    }
                }
            ],
            "concreteType": "ScheduleTemplatePeriodConnection",
            "kind": "LinkedField",
            "name": "periods",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleTemplatePeriodEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleTemplatePeriod",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "periods(order:{\"order\":\"ASC\"})"
        }
    ],
    "type": "ScheduleTemplate",
    "abstractKey": null
};
node.hash = "ba630f6d716e75aadab9de7820d81f00";
export default node;
