var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { Avatar } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import UserImageLightBox from './UserImageLightBox';
import { blobToDataURL } from '@sonika-se/ui-kit/utils/misc';
import { useApi } from '@sonika-se/ui-kit/hooks';
const User = (props) => {
    const { parent, onClick, showAvatarLightBox = false } = props, rest = __rest(props, ["parent", "onClick", "showAvatarLightBox"]);
    const [image, setImage] = useState('');
    const [showlightBox, setShowLightBox] = useState(false);
    const { firstname, avatar, color } = useFragment(graphql `
      fragment User on User {
        firstname
        color
        avatar {
          id
          storageId
          url(queryTransform: "width=68&height=68&mode=crop&scale=both")
          ...UserImageLightBox
        }
      }
    `, parent);
    const { fetch } = useApi();
    useEffect(() => {
        let isMounted = true;
        if (avatar === null || avatar === void 0 ? void 0 : avatar.url) {
            fetch(avatar.url)
                .then((response) => response && response.blob())
                .then((blob) => blob && blobToDataURL(blob))
                .then((dataUrl) => {
                if (isMounted) {
                    setImage(dataUrl);
                }
            });
        }
        else {
            setImage(undefined);
        }
        return () => {
            isMounted = false;
        };
    }, [avatar]);
    return (<>
      <Avatar {...rest} text={firstname} color={(avatar === null || avatar === void 0 ? void 0 : avatar.url) ? 'white' : color || '#0C2AF3'} image={image} onClick={showAvatarLightBox
            ? () => {
                setShowLightBox(true);
            }
            : onClick}/>
      {showlightBox && !!image && <UserImageLightBox onClose={() => setShowLightBox(false)} parent={avatar}/>}
    </>);
};
export default User;
