/**
 * @generated SignedSource<<295c8350300ee94167ae2d7f1cac00a3>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "RemoveAssignedEntity",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentType",
                "kind": "LinkedField",
                "name": "assignmentType",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    },
                    (v0 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "type": "ScheduleShift",
        "abstractKey": null
    };
})();
node.hash = "5d4e8f61d28bebdca1c5cd006cfde5bb";
export default node;
