/**
 * @generated SignedSource<<71b0434613b56a69b80b2bfa707141af>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RunningSchedulesHeaderMobile_user",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleSwitch_user"
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "f99f045068172b12e29b1708f0386247";
export default node;
