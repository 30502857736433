/**
 * @generated SignedSource<<2a8bb74ea70f444728694505b2c3c924>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "assetValidationModel"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "model"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v5 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "assetValidationModel"
        }
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validateProficiency",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "AssetProficiencyValidation",
        "kind": "LinkedField",
        "name": "assetProficiency",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isValid",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateAssignedEntityOnShiftPotentionalClashesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "ClashGroup",
                    "kind": "LinkedField",
                    "name": "potentialScheduleShiftClash",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "clashes",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "ScheduleShiftAssetValidation",
                    "kind": "LinkedField",
                    "name": "potentialAssetValidations",
                    "plural": true,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AssetProficiencyValidationNotification_scheduleShiftAssetValidation"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableAsset",
                            "kind": "LinkedField",
                            "name": "schedulableAsset",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Asset",
                                    "kind": "LinkedField",
                                    "name": "asset",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "UpdateAssignedEntityOnShiftPotentionalClashesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "ClashGroup",
                    "kind": "LinkedField",
                    "name": "potentialScheduleShiftClash",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "clashes",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v8 /*: any*/)
                                    ],
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "ScheduleShiftAssetValidation",
                    "kind": "LinkedField",
                    "name": "potentialAssetValidations",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableAsset",
                            "kind": "LinkedField",
                            "name": "schedulableAsset",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Asset",
                                    "kind": "LinkedField",
                                    "name": "asset",
                                    "plural": false,
                                    "selections": [
                                        (v8 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "assetNumber",
                                            "storageKey": null
                                        },
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v8 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableUser",
                            "kind": "LinkedField",
                            "name": "schedulableUser",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "fullName",
                                            "storageKey": null
                                        },
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v8 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentType",
                            "kind": "LinkedField",
                            "name": "userAssignmentType",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                },
                                (v8 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "36fd4ff2e825898e8d75ecadf54343cd",
            "id": null,
            "metadata": {},
            "name": "UpdateAssignedEntityOnShiftPotentionalClashesQuery",
            "operationKind": "query",
            "text": "query UpdateAssignedEntityOnShiftPotentionalClashesQuery(\n  $model: PotentialScheduleShiftClashRequestInput!\n  $assetValidationModel: PotentialAssetValidationsInput!\n) {\n  potentialScheduleShiftClash(model: $model) {\n    clashes {\n      __typename\n      end\n      start\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n  potentialAssetValidations(model: $assetValidationModel) {\n    ...AssetProficiencyValidationNotification_scheduleShiftAssetValidation\n    schedulableAsset {\n      asset {\n        validateProficiency\n        id\n      }\n      id\n    }\n    assetProficiency {\n      isValid\n    }\n  }\n}\n\nfragment AssetProficiencyValidationNotification_scheduleShiftAssetValidation on ScheduleShiftAssetValidation {\n  schedulableAsset {\n    asset {\n      id\n      name\n      assetNumber\n      validateProficiency\n    }\n    id\n  }\n  schedulableUser {\n    user {\n      fullName\n      id\n    }\n    id\n  }\n  userAssignmentType {\n    title\n    id\n  }\n  assetProficiency {\n    isValid\n  }\n}\n"
        }
    };
})();
node.hash = "1b8b1422d8796cedc2a90d7d0b17b81f";
export default node;
