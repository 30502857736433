/**
 * @generated SignedSource<<5ca18b65fab14a1d4fd36774e720d3b4>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RolePicker_query",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RolePickerList_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "e2f6c9f85bf2dd7e3771ddeab31e92bc";
export default node;
