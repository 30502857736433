/**
 * @generated SignedSource<<3b2a1ed7b501398c865b6035b15b17d7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "SystemSettingsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "currentCompany",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditCompanyScheduleVisibility"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "SystemSettingsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "currentCompany",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CompanyScheduleSettings",
                            "kind": "LinkedField",
                            "name": "scheduleSettings",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "viewAccessUntil",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "0f3506e521c52e917f8341201f2c5b8e",
            "id": null,
            "metadata": {},
            "name": "SystemSettingsPageQuery",
            "operationKind": "query",
            "text": "query SystemSettingsPageQuery {\n  currentCompany {\n    id\n    ...EditCompanyScheduleVisibility\n  }\n}\n\nfragment EditCompanyScheduleVisibility on Company {\n  id\n  scheduleSettings {\n    id\n    viewAccessUntil\n  }\n}\n"
        }
    };
})();
node.hash = "1a9266f30689bf0f4666cb83c1ac7fe3";
export default node;
