/**
 * @generated SignedSource<<4ab1001bb8d22952b627239823a760a7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "scheduleId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 50
        }
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "Avatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "queryTransform",
                        "value": "width=300&height=300&mode=crop&scale=both"
                    }
                ],
                "kind": "ScalarField",
                "name": "url",
                "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
            },
            (v2 /*: any*/)
        ],
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v8 = [
        (v7 /*: any*/)
    ], v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleResourcesPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleAssignmentTypeConnection",
                            "kind": "LinkedField",
                            "name": "assignmentTypes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleAssignmentType",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "ScheduleAssignmentTypeResourceTablePanel_scheduleAssignmentType"
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleResourcesPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleAssignmentTypeConnection",
                            "kind": "LinkedField",
                            "name": "assignmentTypes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleAssignmentType",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": (v3 /*: any*/),
                                            "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                            "kind": "LinkedField",
                                            "name": "resources",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleAssignmentTypeResource",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": null,
                                                                    "kind": "LinkedField",
                                                                    "name": "schedulableEntity",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v4 /*: any*/),
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "Asset",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "asset",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        (v5 /*: any*/),
                                                                                        (v2 /*: any*/),
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v6 /*: any*/),
                                                                                                {
                                                                                                    "kind": "InlineFragment",
                                                                                                    "selections": (v8 /*: any*/),
                                                                                                    "type": "Asset",
                                                                                                    "abstractKey": null
                                                                                                },
                                                                                                {
                                                                                                    "kind": "InlineFragment",
                                                                                                    "selections": [
                                                                                                        (v7 /*: any*/),
                                                                                                        (v9 /*: any*/),
                                                                                                        (v10 /*: any*/)
                                                                                                    ],
                                                                                                    "type": "User",
                                                                                                    "abstractKey": null
                                                                                                }
                                                                                            ],
                                                                                            "type": "IHaveAvatar",
                                                                                            "abstractKey": "__isIHaveAvatar"
                                                                                        }
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "type": "SchedulableAsset",
                                                                            "abstractKey": null
                                                                        },
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "User",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "user",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        (v9 /*: any*/),
                                                                                        (v10 /*: any*/),
                                                                                        (v2 /*: any*/),
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v6 /*: any*/),
                                                                                                {
                                                                                                    "kind": "InlineFragment",
                                                                                                    "selections": [
                                                                                                        (v7 /*: any*/),
                                                                                                        (v5 /*: any*/)
                                                                                                    ],
                                                                                                    "type": "Asset",
                                                                                                    "abstractKey": null
                                                                                                },
                                                                                                {
                                                                                                    "kind": "InlineFragment",
                                                                                                    "selections": [
                                                                                                        (v5 /*: any*/)
                                                                                                    ],
                                                                                                    "type": "Company",
                                                                                                    "abstractKey": null
                                                                                                },
                                                                                                {
                                                                                                    "kind": "InlineFragment",
                                                                                                    "selections": (v8 /*: any*/),
                                                                                                    "type": "User",
                                                                                                    "abstractKey": null
                                                                                                }
                                                                                            ],
                                                                                            "type": "IHaveAvatar",
                                                                                            "abstractKey": "__isIHaveAvatar"
                                                                                        }
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "type": "SchedulableUser",
                                                                            "abstractKey": null
                                                                        },
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                (v2 /*: any*/)
                                                                            ],
                                                                            "type": "Node",
                                                                            "abstractKey": "__isNode"
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v2 /*: any*/),
                                                                (v4 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "cursor",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PageInfo",
                                                    "kind": "LinkedField",
                                                    "name": "pageInfo",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "endCursor",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "hasNextPage",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": "resources(first:50)"
                                        },
                                        {
                                            "alias": null,
                                            "args": (v3 /*: any*/),
                                            "filters": [],
                                            "handle": "connection",
                                            "key": "ScheduleAssignmentTypeResourceTable_scheduleAssignmentType_resources",
                                            "kind": "LinkedHandle",
                                            "name": "resources"
                                        },
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AssignmentType",
                                            "kind": "LinkedField",
                                            "name": "assignmentType",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "title",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Schedule",
                                            "kind": "LinkedField",
                                            "name": "schedule",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "currentUserPermissions",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a0b4de02e971215502f82012edd5a308",
            "id": null,
            "metadata": {},
            "name": "ScheduleResourcesPageQuery",
            "operationKind": "query",
            "text": "query ScheduleResourcesPageQuery(\n  $scheduleId: ID!\n) {\n  schedule(id: $scheduleId) {\n    assignmentTypes {\n      nodes {\n        ...ScheduleAssignmentTypeResourceTablePanel_scheduleAssignmentType\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n\nfragment ScheduleAssignmentTypeResourceTablePanel_scheduleAssignmentType on ScheduleAssignmentType {\n  ...ScheduleAssignmentTypeResourceTable_scheduleAssignmentType\n  assignmentType {\n    title\n    id\n  }\n  id\n  schedule {\n    currentUserPermissions\n    id\n  }\n}\n\nfragment ScheduleAssignmentTypeResourceTable_scheduleAssignmentType on ScheduleAssignmentType {\n  resources(first: 50) {\n    edges {\n      node {\n        schedulableEntity {\n          __typename\n          ... on SchedulableAsset {\n            asset {\n              ...Avatar_iHaveAvatar\n              name\n              id\n            }\n          }\n          ... on SchedulableUser {\n            user {\n              ...Avatar_iHaveAvatar\n              firstname\n              lastname\n              id\n            }\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
        }
    };
})();
node.hash = "82a35e4f22204d715cb4da7169b07ef2";
export default node;
