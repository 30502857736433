import React, { Suspense, useContext, useState } from 'react';
import { Divider, Drawer, DrawerContent, DrawerHeader, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, usePreloadedQuery } from 'react-relay/hooks';
import { toDate } from '@sonika-se/ui-kit/utils/time';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { formatISO } from 'date-fns';
import ScheduleShiftHistoryVacancyInterestQueryForm from '~/forms/ScheduleShiftHistoryVacancyInterestQueryForm';
import ScheduleShiftVacancyList from '~/lists/ScheduleShiftVacancyList';
import RunningSchedulesPageContext from '~/pages/SchedulePages/RunningSchedulesPage/RunningSchedulesPageContext';
export const scheduleShiftVacancyDrawerQuery = graphql `
  query ScheduleShiftVacancyDrawerQuery($scheduleShiftVacancyListModel: GetRunningScheduleRequestInput) {
    ...ScheduleShiftHistoryVacancyInterestQueryForm_query
    ...ScheduleShiftVacancyList_query @arguments(model: $scheduleShiftVacancyListModel)
  }
`;
export const ScheduleShiftVacancyDrawer = (props) => {
    const { onClose, preloadedQuery } = props;
    const data = usePreloadedQuery(scheduleShiftVacancyDrawerQuery, preloadedQuery);
    const context = useContext(RunningSchedulesPageContext);
    const [scheduleShiftVacancyListProps, setScheduleShiftVacancyListProps] = useState();
    return (<Drawer onClose={onClose} open>
      <DrawerHeader title="Vakanta pass"/>
      <div style={{ padding: '1rem 1.5rem' }}>
        <ScheduleShiftHistoryVacancyInterestQueryForm defaultValues={{
            interval: {
                fromDate: toDate(context.fromDate),
                toDate: toDate(context.toDate),
            },
            scheduleId: null,
        }} onSubmit={(form) => {
            setScheduleShiftVacancyListProps({
                model: {
                    fromDate: formatISO(form.interval.fromDate),
                    toDate: formatISO(form.interval.toDate),
                },
                where: form.scheduleId
                    ? {
                        scheduleId: {
                            eq: form.scheduleId,
                        },
                    }
                    : undefined,
            });
        }} queryRef={data}/>
      </div>
      <Divider />
      <DrawerContent style={{ backgroundColor: sonikaTheme.colors.background.primary, flex: 1 }}>
        <Suspense fallback={<Skeleton height="100%" type="table"/>}>
          <ScheduleShiftVacancyList {...scheduleShiftVacancyListProps} queryRef={data}/>
        </Suspense>
      </DrawerContent>
    </Drawer>);
};
export default ScheduleShiftVacancyDrawer;
