/**
 * @generated SignedSource<<055a3a03742a1e800d9e4ec89c3d2673>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": {
        "refetch": {
            "connection": null,
            "fragmentPathInResult": [],
            "operation": require('./ScheduleListPage_queryQuery.graphql')
        }
    },
    "name": "ScheduleListPage_query",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SchedulesTable_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "03094c8241e1390b305d3fb54c526430";
export default node;
