import React from 'react';
import { ModalToSheet, ModalToSheetContent, ModalToSheetHeader } from '@sonika-se/ui-kit/components';
import { graphql, usePreloadedQuery } from 'react-relay/hooks';
import RolePicker from './RolePicker';
export const rolePickerModalQuery = graphql `
  query RolePickerModalQuery {
    ...RolePicker_query
  }
`;
export const RolePickerModal = (props) => {
    const { onClose, onSelect, preloadedQuery } = props;
    const data = usePreloadedQuery(rolePickerModalQuery, preloadedQuery);
    return (<ModalToSheet onClose={() => onClose()}>
      <ModalToSheetHeader onClose={() => onClose()}>Välj grupp</ModalToSheetHeader>
      <ModalToSheetContent>
        <RolePicker onSelect={onSelect} queryRef={data}/>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default RolePickerModal;
