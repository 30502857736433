/**
 * @generated SignedSource<<260410c5e15b88dba9a10363a64c753c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
    }, v2 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    }, v3 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "SchedulableEntityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "firstname",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastname",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "SchedulableUser",
                            "abstractKey": null
                        },
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Asset",
                                    "kind": "LinkedField",
                                    "name": "asset",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "SchedulableAsset",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "after"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "checkedWhere"
            },
            {
                "defaultValue": 100,
                "kind": "LocalArgument",
                "name": "first"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "where"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./ScheduleTemplateResourceFilterList_resourcesQuery.graphql'),
                "identifierField": "id"
            }
        },
        "name": "ScheduleTemplateResourceFilterList_resources",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": "checked",
                "args": [
                    (v1 /*: any*/),
                    (v2 /*: any*/),
                    {
                        "kind": "Variable",
                        "name": "where",
                        "variableName": "checkedWhere"
                    }
                ],
                "concreteType": "SchedulableEntityConnection",
                "kind": "LinkedField",
                "name": "schedulableEntities",
                "plural": false,
                "selections": (v3 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    (v1 /*: any*/),
                    (v2 /*: any*/),
                    {
                        "kind": "Variable",
                        "name": "where",
                        "variableName": "where"
                    }
                ],
                "concreteType": "SchedulableEntityConnection",
                "kind": "LinkedField",
                "name": "schedulableEntities",
                "plural": false,
                "selections": (v3 /*: any*/),
                "storageKey": null
            }
        ],
        "type": "Schedule",
        "abstractKey": null
    };
})();
node.hash = "b27613eedbae2a6a18974b94f8680deb";
export default node;
