import React, { useMemo } from 'react';
import { NewInlineDateTimeInput } from '@sonika-se/ui-kit/components';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { formatISO } from 'date-fns';
export const EditScheduleShiftTimeStart = (props) => {
    const { scheduleShift, placeholder } = props;
    const { id, schedule, start } = useFragment(graphql `
      fragment EditScheduleShiftTimeStart on ScheduleShift {
        id
        schedule {
          currentUserPermissions
        }
        start
      }
    `, scheduleShift);
    const [updateScheduleShiftTime, updateScheduleShiftTimeLoading] = useMutation(graphql `
      mutation EditScheduleShiftTimeStartMutation($model: UpdateScheduleShiftTimeRequestInput!) {
        updateScheduleShiftTime(model: $model) {
          shiftEdge {
            node {
              id
              start
            }
          }
          scheduleShiftHistoryTimeChangeEdge {
            node {
              id
              scheduleShift {
                ...ScheduleShift_shift
                ...ScheduleShiftHistory_scheduleShift
              }
              ...ScheduleShiftHistoryTimeChange_history
            }
          }
        }
      }
    `);
    const hasPermissions = usePermissions();
    const hasScheduleEditRunningPermission = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || schedule.currentUserPermissions.includes('EDIT_RUNNING'), [hasPermissions, schedule]);
    return (<NewInlineDateTimeInput disabled={!hasScheduleEditRunningPermission || updateScheduleShiftTimeLoading} defaultValue={start ? new Date(start) : null} placeholder={placeholder} displayActionButtons header="Välj starttid" onChange={(date) => {
            if (!date)
                return;
            updateScheduleShiftTime({
                variables: {
                    model: {
                        shiftId: id,
                        start: date ? formatISO(date) : null,
                    },
                },
                optimisticResponse: {
                    updateScheduleShiftTime: {
                        shiftEdge: {
                            node: {
                                id,
                                start: date ? formatISO(date) : null,
                            },
                        },
                        scheduleShiftHistoryTimeChangeEdge: {
                            node: {
                                id: '2',
                                scheduleShift: null,
                                byUser: null,
                                newEnd: start,
                                newStart: date,
                                originalBreaks: null,
                                newBreaks: null,
                                originalEnd: start,
                                originalStart: start,
                                timestamp: start,
                            },
                        },
                    },
                },
            });
        }}/>);
};
export default EditScheduleShiftTimeStart;
