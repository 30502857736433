import React, { useState } from 'react';
import { Text, Flexbox, IconButton, Divider } from '@sonika-se/ui-kit/components';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { ScheduleTemplateInformationPeriodTableRow } from './ScheduleTemplateInformationPeriodTableRow';
const useStyles = createUseStyles((theme) => ({
    tableRow: {
        height: theme.helpers.pxToRem(40),
    },
    tableHeader: {
        marginBottom: '.5rem',
        color: theme.colors.foreground.tertiary,
    },
    bold: {
        fontWeight: 'bold',
    },
    tableCell: {
        width: '5rem',
        textAlign: 'center',
        '&:first-child': {
            textAlign: 'left',
        },
        '&:last-child': {
            textAlign: 'right',
            paddingRight: '.5rem',
        },
    },
    angleIcon: {
        fontSize: '1.5rem',
        padding: '0 .75rem',
        color: theme.colors.foreground.tertiary,
    },
}));
export const ScheduleTemplateInformationPeriodTable = (props) => {
    const { period, templateId, type } = props;
    const [open, setOpen] = useState(true);
    const classes = useStyles();
    return (<div>
      <Flexbox align="center">
        <IconButton icon={open ? faAngleUp : faAngleDown} appearance="text" color="default" size="large" onClick={() => setOpen(!open)}/>
        <Text>{period.period.name}</Text>
      </Flexbox>
      {open && (<div style={{ padding: '0 1.5rem 1rem' }}>
          <Flexbox direction="column">
            <Flexbox justify="space">
              <Text size="small" className={[classes.tableCell, classes.tableHeader].join(' ')}>
                <FormattedMessage defaultMessage="Namn" id="general.misc.schedule_information.fields.name.label"/>
              </Text>
              <Text className={[classes.tableCell, classes.tableHeader].join(' ')} size="small">
                <FormattedMessage defaultMessage="Totalt" id="general.misc.schedule_information.fields.total.label"/>
              </Text>
            </Flexbox>
            <Divider />
            {period.timesByEntityAndAssignmentType.map((entity, index) => (<div key={`timesByEntityAndAssignmentType_${index}`}>
                <ScheduleTemplateInformationPeriodTableRow selectedPeriodId={period.period.id} entity={Object.assign(Object.assign({}, entity), entity.schedulableEntity)} templateId={templateId} type={type}/>
                {index + 1 !== period.timesByEntityAndAssignmentType.length && <Divider />}
              </div>))}
          </Flexbox>
        </div>)}
    </div>);
};
