import React, { Suspense, useState } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Card, CardContent, CardHeader, Input, Skeleton } from '@sonika-se/ui-kit/components';
import { useDebounce } from '@sonika-se/ui-kit/hooks';
import RolePickerList from './RolePickerList';
export const RolePicker = (props) => {
    const { onSelect, queryRef } = props;
    const data = useFragment(graphql `
      fragment RolePicker_query on Query {
        ...RolePickerList_query
      }
    `, queryRef);
    const [search, setSearch] = useState();
    const delayedSearch = useDebounce(search, 100);
    return (<Card borders shadow="none">
      <CardHeader>
        <Input display="block" onChange={(value) => setSearch(value)} placeholder="Sök grupp"/>
      </CardHeader>
      <CardContent style={{ height: '40vh', overflowY: 'auto' }}>
        <Suspense fallback={<Skeleton height="100%" type="table"/>}>
          <RolePickerList onSelect={onSelect} queryRef={data} search={delayedSearch}/>
        </Suspense>
      </CardContent>
    </Card>);
};
export default RolePicker;
