import React, { useEffect, useState, useContext, Suspense, useLayoutEffect, useMemo } from 'react';
import { graphql, useRefetchableFragment, useMutation, useQueryLoader } from 'react-relay/hooks';
import { eachDayOfInterval, formatISO, getWeek } from 'date-fns';
import { createUseStyles } from 'react-jss';
import { faCalendarPlus, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { Flexbox, IconButton, ModalToSheetSkeleton, Placeholder, Button, Icon, Text, Tooltip, } from '@sonika-se/ui-kit/components';
import { useDidMountEffect, usePermissions, useViewport } from '@sonika-se/ui-kit/hooks';
import { Link } from 'react-router-dom';
import { eachWeekOfInterval, endOfWeek } from 'date-fns';
import { faPencilAlt as faPencilAltSolid } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import RunningSchedulesChangeAssigneeReasonModal from '../RunningSchedulesChangeAssigneReasonModal/RunningSchedulesChangeAssigneeReasonModal';
import ScheduleShiftModal, { scheduleShiftModalQuery } from '../../../components/ScheduleShiftModal/ScheduleShiftModal';
import RunningSchedulesPageContext from '../../RunningSchedulesPageContext';
import RunningSchedulesGridHeader from './components/RunningSchedulesGridHeader';
import RunningSchedulesGridWeek from './components/RunningSchedulesGridWeek';
import { createRunningSchedulesFilter } from '../../../utils/filters';
import { updateScheduleShiftAssigneesFormQuery } from '../UpdateScheduleShiftAssigneesForm';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        grid: ({ numColumns }) => ({
            display: 'grid',
            gridGap: pxToRem(1),
            height: 'fit-content',
            gridTemplateColumns: `auto repeat(${numColumns}, minmax(${pxToRem(200)}, 1fr))`,
            minWidth: '100%',
            width: 'fit-content',
        }),
        topLeftCorner: {
            background: '#FFFFFF',
            gridColumn: 1,
            gridRow: `1 / span 2`,
            position: 'sticky',
            left: 0,
            gridArea: '1 / 1 / 3 / 3',
            minWidth: pxToRem(60),
            minHeight: pxToRem(60),
            top: 0,
            zIndex: '1',
            borderRight: `${pxToRem(1)} solid ${theme.colors.foreground.separation}`,
            margin: `${pxToRem(0)} ${pxToRem(-1)}`,
        },
        hover: {
            '&> *': {
                pointerEvents: 'none',
            },
        },
        hoverElement: {
            position: 'sticky',
            height: 0,
            top: '40%',
            left: 'calc(50% - 125px)',
            width: '250px',
            zIndex: 1,
            textAlign: 'center',
        },
    };
});
export const RunningSchedulesGrid = (props) => {
    var _a, _b;
    const { refetch: refetchProp, queryRef, setSelectedShifts, unsetAllHighlighted, onToggleEditMode, resetFilters, } = props;
    const { filters, toDate, fromDate, highlightedShifts, editMode } = useContext(RunningSchedulesPageContext);
    const { resourceIds, scheduleIds, assignmentTypeIds, onlyVacant, hideEmptySchedules, tagIds } = filters;
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment RunningSchedulesGrid_schedules on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int" }
        fromDate: { type: "DateTime!" }
        toDate: { type: "DateTime!" }
        where: { type: "ScheduleShiftFilterInput" }
        whereSchedules: { type: "ScheduleFilterInput" }
        shiftsFilter: { type: "ScheduleShiftsFilterInput" }
        filterModel: { type: "ScheduleFilterRequestInput" }
      )
      @refetchable(queryName: "RunningSchedulesGrid_schedulesQuery") {
        gridSchedules: schedules(where: $whereSchedules, filterModel: $filterModel) {
          edges {
            node {
              id
              currentUserPermissions
              ...RunningSchedulesGridSchedule_schedule
                @arguments(
                  after: $after
                  first: $first
                  fromDate: $fromDate
                  toDate: $toDate
                  where: $where
                  shiftsFilter: $shiftsFilter
                )
              ...RunningSchedulesGridHeader_schedule
                @arguments(
                  after: $after
                  first: $first
                  fromDate: $fromDate
                  toDate: $toDate
                  where: $where
                  shiftsFilter: $shiftsFilter
                )
              runningSchedule(
                after: $after
                first: $first
                model: { fromDate: $fromDate, toDate: $toDate }
                where: $where
                filter: $shiftsFilter
              ) {
                edges {
                  node {
                    id
                    assignmentType {
                      id
                      title
                    }
                  }
                }
              }
              name
            }
          }
        }
      }
    `, queryRef);
    const currentUserSchedulePermissions = useMemo(() => data.gridSchedules.edges.map(({ node: schedule }) => schedule.currentUserPermissions).flat(), [data]);
    const hasPermissions = usePermissions();
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || currentUserSchedulePermissions.includes('EDIT_RUNNING'), [hasPermissions]);
    const [updateScheduleTemplateShift, updateScheduleTemplateShiftLoading] = useMutation(graphql `
        mutation RunningSchedulesGridChangeAssigneMutation($model: [UpdateScheduleShiftAssigneeRequestInput!]!) {
          updateScheduleShiftAssignees(model: $model) {
            scheduleShiftHistoryAssigneeChangeEdge {
              node {
                id
                scheduleShift {
                  ...ScheduleShift_shift
                  ...ScheduleShiftHistory_scheduleShift
                }
                ...ScheduleShiftHistoryAssigneeChange_history
              }
            }
          }
        }
      `);
    const numColumns = data.gridSchedules.edges.reduce((prev, cur) => {
        const scheduleAssignmentTypes = cur.node.runningSchedule.edges.reduce((prev, cur) => {
            return Object.assign(Object.assign({}, prev), { [cur.node.assignmentType.id]: cur.node.assignmentType.title });
        }, {});
        return prev + (Object.keys(scheduleAssignmentTypes).length || (hideEmptySchedules ? 0 : 1));
    }, 0);
    const classes = useStyles({
        numColumns,
    });
    const { size: pageSize } = useViewport();
    const [weeks, setWeeks] = useState(null);
    const [hover, setHover] = useState(null);
    const [updateResource, setUpdateResource] = useState({});
    const [isRefetching, setIsRefecthing] = useState(false);
    const [updateScheduleShiftAssigneesFormQueryRef, loadUpdateScheduleShiftAssigneesFormQuery, disposeUpdateScheduleShiftAssigneesFormQuery,] = useQueryLoader(updateScheduleShiftAssigneesFormQuery);
    useEffect(() => {
        if ((updateResource === null || updateResource === void 0 ? void 0 : updateResource.assigneeId) !== undefined) {
            loadUpdateScheduleShiftAssigneesFormQuery({
                where: { id: { in: Object.keys(highlightedShifts) } },
                potentialValidationsModel: {
                    newAssignedEntityId: updateResource === null || updateResource === void 0 ? void 0 : updateResource.assigneeId,
                },
            });
        }
    }, [updateResource]);
    useDidMountEffect(() => {
        const filters = createRunningSchedulesFilter({
            assignmentTypeIds,
            resourceIds,
            onlyVacant,
            scheduleIds,
            fromDate,
            toDate,
            tagIds,
        });
        refetch(filters, { fetchPolicy: 'network-only' });
    }, [resourceIds, scheduleIds, assignmentTypeIds, onlyVacant, refetchProp, tagIds]);
    useEffect(() => {
        const newWeeks = eachWeekOfInterval({ start: new Date(fromDate), end: new Date(toDate) }, { weekStartsOn: 1 });
        const mappedWeeks = newWeeks.reduce((prev, week, index) => {
            const weekNumber = getWeek(week, { weekStartsOn: 1, firstWeekContainsDate: 4 });
            const start = index === 0 ? fromDate : week;
            const end = index + 1 === newWeeks.length ? toDate : endOfWeek(week, { weekStartsOn: 1 });
            return Object.assign(Object.assign({}, prev), { [formatISO(week, { representation: 'date' })]: {
                    dates: eachDayOfInterval({ start: new Date(start), end: new Date(end) }),
                    weekNumber,
                } });
        }, {});
        if (!weeks) {
            setWeeks(mappedWeeks);
        }
        else if (JSON.stringify(weeks) !== JSON.stringify(mappedWeeks)) {
            setWeeks(mappedWeeks);
            const filters = createRunningSchedulesFilter({
                assignmentTypeIds,
                resourceIds,
                onlyVacant,
                scheduleIds,
                fromDate,
                toDate,
                tagIds,
            });
            setIsRefecthing(true);
            refetch(filters, {
                fetchPolicy: 'store-and-network',
                onComplete: () => {
                    setIsRefecthing(false);
                },
            });
        }
    }, [fromDate, toDate]);
    const [scheduleShiftModalQueryRef, loadScheduleShiftModalQuery, disposeScheduleShiftModalQuery] = useQueryLoader(scheduleShiftModalQuery);
    const allowDrop = (event, editable) => {
        event.preventDefault();
        if (!editable) {
            event.dataTransfer.dropEffect = 'none';
        }
        else {
            event.dataTransfer.dropEffect = 'move';
        }
    };
    useLayoutEffect(() => {
        // Click and drag funktionality from: https://codepen.io/toddwebdev/pen/yExKoj
        const slider = document.getElementById('schedule-content');
        let isDown = false;
        let startX;
        let scrollLeft;
        if (!slider)
            return;
        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown)
                return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }, []);
    const header = useMemo(() => data.gridSchedules.edges.map(({ node: schedule }, scheduleIndex) => (<RunningSchedulesGridHeader schedule={schedule} key={`runningschedules_header_${schedule.id}_${scheduleIndex}`}/>)), [data, weeks]);
    const grid = useMemo(() => {
        var _a;
        return (_a = Object.keys(weeks || {})) === null || _a === void 0 ? void 0 : _a.map((weekDate, index) => {
            const week = weeks[weekDate];
            return (<RunningSchedulesGridWeek weekNumber={week.weekNumber} key={`runningschedules_week_${week.weekNumber}_${index}`} schedules={data} defaultOpen={index === 0 || index === 1} dates={week === null || week === void 0 ? void 0 : week.dates} numColumns={numColumns} setSelectedShifts={setSelectedShifts} onShiftClick={(shiftId) => {
                    loadScheduleShiftModalQuery({ shiftId }, { fetchPolicy: 'network-only' });
                }}/>);
        });
    }, [data, weeks, setSelectedShifts]);
    const intl = useIntl();
    if (numColumns === 0) {
        if ((_b = (_a = data === null || data === void 0 ? void 0 : data.gridSchedules) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length) {
            return (<Placeholder icon={faCalendarPlus} title="Det finns inga scheman som matchar dina filtreringar" height="full">
          <Flexbox gap="medium">
            {hasPermissions(['EDIT_SCHEDULES']) && (<Button onClick={() => onToggleEditMode(true)}>
                <FormattedMessage defaultMessage="Redigera {schedules}" id="RunningSchedulesGrid.edit_schedules" values={{
                        schedules: intl.formatMessage({
                            defaultMessage: 'Scheman',
                            id: 'general.objects.schedule.name.plural',
                        }),
                    }}/>
              </Button>)}
            <Button onClick={() => resetFilters()}>
              <FormattedMessage defaultMessage="Rensa filter" id="RunningSchedulesGrid.clear_filters"/>
            </Button>
          </Flexbox>
        </Placeholder>);
        }
        return hasPermissions(['EDIT_SCHEDULES']) ? (<Placeholder icon={faCalendarPlus} title="Skapa scheman för att komma igång" height="full">
        <Link to="/schedules">
          <Button type="button">Gå till Scheman för att skapa ett första schema</Button>
        </Link>
      </Placeholder>) : (<Placeholder icon={faCalendarPlus} title="Inga scheman tillgängliga" height="full"></Placeholder>);
    }
    return (<>
      {isRefetching && (<div id="schedule-content" style={{ overflow: 'auto', height: '100%' }}>
          <Placeholder height="full" text="Laddar..."></Placeholder>
        </div>)}
      {!isRefetching && (<div id="schedule-content" style={{ overflow: 'auto' }} onDragOver={(event) => event.preventDefault()} onDragEnter={(event) => {
                if (Object.keys(highlightedShifts).length) {
                    event.preventDefault();
                    setHover(event.currentTarget);
                    return allowDrop(event, true);
                }
            }} onDragLeave={(event) => {
                if (event.target === hover) {
                    return setHover(null);
                }
            }} onDrop={(event) => {
                var _a;
                if (!Object.keys(highlightedShifts).length)
                    return setHover(null);
                const assigneeId = (!event.dataTransfer.getData('entityId') || event.dataTransfer.getData('entityId')) === 'null'
                    ? null
                    : event.dataTransfer.getData('entityId');
                const assignmentTypeId = (!event.dataTransfer.getData('assignmentTypeId') || event.dataTransfer.getData('assignmentTypeId')) ===
                    'null'
                    ? null
                    : event.dataTransfer.getData('assignmentTypeId');
                const type = event.dataTransfer.getData('type') === 'null'
                    ? null
                    : event.dataTransfer.getData('type');
                const hasEntity = Object.keys(highlightedShifts).some((key) => {
                    var _a;
                    return (_a = highlightedShifts[key]) === null || _a === void 0 ? void 0 : _a.hasEntity;
                });
                const entities = Object.keys(highlightedShifts).filter((key) => {
                    var _a;
                    return !!((_a = highlightedShifts[key]) === null || _a === void 0 ? void 0 : _a.hasEntity);
                });
                if (hasEntity) {
                    setUpdateResource({
                        assigneeId: assigneeId || null,
                        type,
                        optionsId: assignmentTypeId || ((_a = highlightedShifts[entities[0]]) === null || _a === void 0 ? void 0 : _a.assignmentTypeId),
                    });
                }
                else if (assigneeId) {
                    updateScheduleTemplateShift({
                        variables: {
                            model: Object.keys(highlightedShifts).map((shiftId) => ({
                                shiftId,
                                newAssignedEntityId: assigneeId,
                                reasonEventId: null,
                            })),
                        },
                    });
                }
                return setHover(null);
            }} className={[hover ? classes.hover : ''].join(' ')}>
          {hover && (<div className={classes.hoverElement}>
              <Icon icon={faUserPlus} style={{
                    fontSize: '4rem',
                    color: '#0C2AF3',
                    marginBottom: '.5rem',
                }}/>
              <Text>
                <FormattedMessage defaultMessage="Lägg till på {number} markerade pass" id="RunningSchedulesGrid.add_to_highlighted" values={{
                    number: Object.keys(highlightedShifts).length,
                }}/>
              </Text>
            </div>)}

          <div className={classes.grid} style={{ opacity: hover ? 0.3 : 1 }}>
            {header}
            {grid}
            <Flexbox align="center" justify="center" className={classes.topLeftCorner}>
              {canEditRunning && pageSize !== 'small' && (<Tooltip content={intl.formatMessage({
                    defaultMessage: 'Redigera {schedule}',
                    id: 'RunningSchedulesGrid.edit_schedule',
                }, {
                    schedule: intl.formatMessage({
                        defaultMessage: 'Schema',
                        id: 'general.objects.schedule.name.singular',
                    }),
                })}>
                  <IconButton appearance={editMode ? 'filled' : 'outlined'} size="medium" onClick={() => onToggleEditMode(!editMode)} icon={faPencilAltSolid}/>
                </Tooltip>)}
            </Flexbox>
          </div>
          <Suspense fallback={<ModalToSheetSkeleton height="20vh" onClose={() => disposeScheduleShiftModalQuery()} type="loader"/>}>
            {scheduleShiftModalQueryRef && (<ScheduleShiftModal onClose={() => disposeScheduleShiftModalQuery()} preloadedQuery={scheduleShiftModalQueryRef}/>)}
          </Suspense>
        </div>)}
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {(updateResource === null || updateResource === void 0 ? void 0 : updateResource.assigneeId) !== undefined && updateScheduleShiftAssigneesFormQueryRef && (<RunningSchedulesChangeAssigneeReasonModal preloadedQuery={updateScheduleShiftAssigneesFormQueryRef} newEntityId={updateResource.assigneeId} assignmentTypeId={updateResource.optionsId} assigneeType={updateResource.type} onCancel={() => {
                setUpdateResource({});
                disposeUpdateScheduleShiftAssigneesFormQuery();
                unsetAllHighlighted();
            }}/>)}
      </Suspense>
    </>);
};
export default RunningSchedulesGrid;
