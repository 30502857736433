import React, { useState, Suspense } from 'react';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useIntl } from 'react-intl';
import { Drawer, DrawerHeader, Field, IntervalInput, Skeleton, DrawerContent, Flexbox, } from '@sonika-se/ui-kit/components';
import MyScheduleInformationDrawerContent from './MyScheduleInformationDrawerContent';
export const MyScheduleInformationDrawer = (props) => {
    const { defaultToDate = endOfMonth(new Date()), defaultFromDate = startOfMonth(new Date()), onClose, open, meId, } = props;
    const [date, setDate] = useState({
        fromDate: defaultFromDate,
        toDate: defaultToDate,
    });
    const [update, setUpdate] = useState();
    const intl = useIntl();
    return (<Drawer open={open} onClose={() => {
            onClose();
        }}>
      <DrawerHeader title={intl.formatMessage({
            defaultMessage: 'Schemainformation',
            id: 'general.misc.schedule_information.name.singular',
        })}/>
      <DrawerContent style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Flexbox direction="column" gap="medium">
          <Field label={intl.formatMessage({ id: 'general.misc.period.selected_period', defaultMessage: 'Vald period' })}>
            <IntervalInput value={date} onChange={(value) => {
            setDate(value);
            setUpdate(Date.now());
        }} onUpdate={() => {
            setUpdate(Date.now());
        }}/>
          </Field>
          <Suspense fallback={<Skeleton />}>
            <MyScheduleInformationDrawerContent date={date} open={open} update={update} meId={meId}/>
          </Suspense>
        </Flexbox>
      </DrawerContent>
    </Drawer>);
};
export default MyScheduleInformationDrawer;
