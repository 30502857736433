/**
 * @generated SignedSource<<87d58d7f34d5cf631a6e930cd8b9a513>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = [
        (v2 /*: any*/)
    ], v4 = {
        "kind": "InlineFragment",
        "selections": (v3 /*: any*/),
        "type": "ISchedulableEntity",
        "abstractKey": "__isISchedulableEntity"
    }, v5 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            (v2 /*: any*/)
        ],
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assignmentTypeId",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reasonEventId",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "reasonEvent",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            },
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v12 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "MINUTE"
        }
    ], v13 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
        }
    ], v14 = {
        "alias": null,
        "args": null,
        "concreteType": "PlannedAbsenceTime",
        "kind": "LinkedField",
        "name": "times",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weekday",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTimeSpanPeriod",
                "kind": "LinkedField",
                "name": "period",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": (v12 /*: any*/),
                        "concreteType": "TimeSpanUnitValue",
                        "kind": "LinkedField",
                        "name": "start",
                        "plural": false,
                        "selections": (v13 /*: any*/),
                        "storageKey": "start(unit:\"MINUTE\")"
                    },
                    {
                        "alias": null,
                        "args": (v12 /*: any*/),
                        "concreteType": "TimeSpanUnitValue",
                        "kind": "LinkedField",
                        "name": "end",
                        "plural": false,
                        "selections": (v13 /*: any*/),
                        "storageKey": "end(unit:\"MINUTE\")"
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditPlannedAbsenceModalMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdatePlannedAbsencePayload",
                    "kind": "LinkedField",
                    "name": "updatePlannedAbsence",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfPlannedAbsence",
                            "kind": "LinkedField",
                            "name": "plannedAbsenceEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PlannedAbsence",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "assignedEntity",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v2 /*: any*/),
                                        (v9 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v14 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditPlannedAbsenceModalMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdatePlannedAbsencePayload",
                    "kind": "LinkedField",
                    "name": "updatePlannedAbsence",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfPlannedAbsence",
                            "kind": "LinkedField",
                            "name": "plannedAbsenceEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PlannedAbsence",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "assignedEntity",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                (v4 /*: any*/),
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": (v3 /*: any*/),
                                                    "type": "Node",
                                                    "abstractKey": "__isNode"
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v2 /*: any*/),
                                        (v9 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v14 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "6f56d003962ddfe7ebf9deed896e7a78",
            "id": null,
            "metadata": {},
            "name": "EditPlannedAbsenceModalMutation",
            "operationKind": "mutation",
            "text": "mutation EditPlannedAbsenceModalMutation(\n  $model: UpdatePlannedAbsenceRequestInput!\n) {\n  updatePlannedAbsence(model: $model) {\n    plannedAbsenceEdge {\n      node {\n        assignedEntity {\n          __typename\n          ... on ISchedulableEntity {\n            __isISchedulableEntity: __typename\n            id\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n        assignmentType {\n          title\n          id\n        }\n        assignmentTypeId\n        description\n        end\n        id\n        reasonEventId\n        reasonEvent {\n          name\n          id\n          color\n        }\n        start\n        times {\n          weekday\n          period {\n            start(unit: MINUTE) {\n              value\n              unit\n            }\n            end(unit: MINUTE) {\n              value\n              unit\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "4085c3ca27ef62429c4f1f340f091a24";
export default node;
