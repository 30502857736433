/**
 * @generated SignedSource<<0879f68b7a3729e0bbb6b45626c11695>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "month"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v3 = [
        {
            "kind": "Variable",
            "name": "month",
            "variableName": "month"
        }
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "HOUR"
        }
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unit",
        "storageKey": null
    }, v9 = [
        (v7 /*: any*/),
        (v8 /*: any*/)
    ], v10 = [
        (v4 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
        }
    ], v11 = [
        (v4 /*: any*/),
        (v5 /*: any*/)
    ], v12 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "event",
        "plural": false,
        "selections": (v11 /*: any*/),
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesInformationByMonthRow_fragmentQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v3 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "RunningSchedulesInformationByMonthRow_fragment"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "RunningSchedulesInformationByMonthRow_fragmentQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v4 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "color",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v3 /*: any*/),
                                    "concreteType": "ScheduleTimesByMonthPayload",
                                    "kind": "LinkedField",
                                    "name": "scheduleTimesByMonth",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TimeReport",
                                            "kind": "LinkedField",
                                            "name": "timeReport",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleTimesByMonthSummary",
                                            "kind": "LinkedField",
                                            "name": "summary",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": (v6 /*: any*/),
                                                    "concreteType": "TimeSpanUnitValue",
                                                    "kind": "LinkedField",
                                                    "name": "totalTime",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": "totalTime(unit:\"HOUR\")"
                                                },
                                                {
                                                    "alias": null,
                                                    "args": (v6 /*: any*/),
                                                    "concreteType": "TimeSpanUnitValue",
                                                    "kind": "LinkedField",
                                                    "name": "workedTime",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": "workedTime(unit:\"HOUR\")"
                                                },
                                                {
                                                    "alias": null,
                                                    "args": (v6 /*: any*/),
                                                    "concreteType": "TimeSpanUnitValue",
                                                    "kind": "LinkedField",
                                                    "name": "absenceTime",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": "absenceTime(unit:\"HOUR\")"
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TimeSummaryByEmployment",
                                            "kind": "LinkedField",
                                            "name": "employmentSummaries",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Employment",
                                                    "kind": "LinkedField",
                                                    "name": "employment",
                                                    "plural": false,
                                                    "selections": (v10 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "TimeReportSummaryByEmployment",
                                                    "kind": "LinkedField",
                                                    "name": "timeReportSummary",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": (v6 /*: any*/),
                                                            "concreteType": "TimeSpanUnitValue",
                                                            "kind": "LinkedField",
                                                            "name": "expectedWorkTime",
                                                            "plural": false,
                                                            "selections": (v9 /*: any*/),
                                                            "storageKey": "expectedWorkTime(unit:\"HOUR\")"
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "TimeReportSummaryByEmploymentAndEvent",
                                                            "kind": "LinkedField",
                                                            "name": "eventSummaries",
                                                            "plural": true,
                                                            "selections": [
                                                                (v12 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "isWork",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "TimeReportSummaryByEmploymentAndEventPartsSummary",
                                                                    "kind": "LinkedField",
                                                                    "name": "nonRegularPartsSummary",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        (v12 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": [
                                                                                {
                                                                                    "kind": "Literal",
                                                                                    "name": "unit",
                                                                                    "value": "SECOND"
                                                                                }
                                                                            ],
                                                                            "concreteType": "TimeSpanUnitValue",
                                                                            "kind": "LinkedField",
                                                                            "name": "duration",
                                                                            "plural": false,
                                                                            "selections": (v9 /*: any*/),
                                                                            "storageKey": "duration(unit:\"SECOND\")"
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v8 /*: any*/),
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CompensatoryTimesByTimeStore",
                                            "kind": "LinkedField",
                                            "name": "compensatoryTimes",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "EmploymentSetting",
                                                    "kind": "LinkedField",
                                                    "name": "employmentSetting",
                                                    "plural": false,
                                                    "selections": (v10 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CompensatoryTimeSetting",
                                                    "kind": "LinkedField",
                                                    "name": "timeStore",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Event",
                                                            "kind": "LinkedField",
                                                            "name": "timeStoreEvent",
                                                            "plural": false,
                                                            "selections": (v11 /*: any*/),
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CompensatoryTime",
                                                    "kind": "LinkedField",
                                                    "name": "times",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "description",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": (v6 /*: any*/),
                                                            "concreteType": "TimeSpanUnitValue",
                                                            "kind": "LinkedField",
                                                            "name": "time",
                                                            "plural": false,
                                                            "selections": (v9 /*: any*/),
                                                            "storageKey": "time(unit:\"HOUR\")"
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "TimeSpanUnitValue",
                                                    "kind": "LinkedField",
                                                    "name": "totalTime",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "ISchedulableEntity",
                            "abstractKey": "__isISchedulableEntity"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "9546bab613338952866251da0494c92d",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesInformationByMonthRow_fragmentQuery",
            "operationKind": "query",
            "text": "query RunningSchedulesInformationByMonthRow_fragmentQuery(\n  $month: DateTime!\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...RunningSchedulesInformationByMonthRow_fragment_3vjFD7\n    id\n  }\n}\n\nfragment RunningSchedulesInformationByMonthRow_fragment_3vjFD7 on ISchedulableEntity {\n  __isISchedulableEntity: __typename\n  id\n  name\n  color\n  scheduleTimesByMonth(month: $month) {\n    timeReport {\n      id\n    }\n    summary {\n      totalTime(unit: HOUR) {\n        value\n        unit\n      }\n      workedTime(unit: HOUR) {\n        value\n        unit\n      }\n      absenceTime(unit: HOUR) {\n        value\n        unit\n      }\n    }\n    employmentSummaries {\n      employment {\n        id\n        title\n      }\n      timeReportSummary {\n        expectedWorkTime(unit: HOUR) {\n          value\n          unit\n        }\n        eventSummaries {\n          event {\n            id\n            name\n          }\n          isWork\n          nonRegularPartsSummary {\n            event {\n              id\n              name\n            }\n            duration(unit: SECOND) {\n              value\n              unit\n            }\n          }\n          unit\n          value\n        }\n      }\n    }\n    compensatoryTimes {\n      employmentSetting {\n        id\n        title\n      }\n      timeStore {\n        id\n        name\n        timeStoreEvent {\n          id\n          name\n        }\n      }\n      times {\n        description\n        time(unit: HOUR) {\n          value\n          unit\n        }\n      }\n      totalTime {\n        value\n        unit\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "661f4a1ed5a45bd9311f1ade2bd3d03d";
export default node;
