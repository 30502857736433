var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { MultiSelect } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
export const eventFieldQuery = graphql `
  query EventFieldQuery($where: EventFilterInput) {
    events(where: $where) {
      edges {
        node {
          id
          name
          assignmentTypes {
            id
          }
        }
      }
    }
  }
`;
const EventField = (props) => {
    var _a, _b;
    const { where, assignmentTypeId } = props, rest = __rest(props, ["where", "assignmentTypeId"]);
    const { events } = useLazyLoadQuery(eventFieldQuery, { where });
    return (<MultiSelect {...rest} options={(_b = (_a = events === null || events === void 0 ? void 0 : events.edges) === null || _a === void 0 ? void 0 : _a.filter(({ node }) => { var _a; return !!((_a = node === null || node === void 0 ? void 0 : node.assignmentTypes) === null || _a === void 0 ? void 0 : _a.find((assignmentType) => (assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.id) === assignmentTypeId)); })) === null || _b === void 0 ? void 0 : _b.map(({ node: item }) => ({
            value: item.id,
            label: item.name,
        }))}/>);
};
export default EventField;
