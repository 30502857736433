import React from 'react';
import { graphql, useMutation } from 'react-relay/hooks';
import { Confirm } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
export const ResetScheduleShiftConfirm = (props) => {
    const { onClose, open, scheduleShiftId } = props;
    const intl = useIntl();
    const [resetScheduleShift, resetScheduleShiftLoading] = useMutation(graphql `
        mutation ResetScheduleShiftConfirmResetScheduleShiftMutation($model: ResetScheduleShiftRequestInput!) {
          resetScheduleShift(model: $model) {
            shiftEdge {
              node {
                ...ScheduleShiftInformationTab_ScheduleShift
                ...useScheduleShiftAttachments_scheduleShift
                ...useScheduleShiftNotes_scheduleShift
                ...ScheduleShiftHistory_scheduleShift
                ...ScheduleShiftChangeTab_shift
              }
            }
          }
        }
      `);
    if (!open)
        return null;
    return (<Confirm headerText={intl.formatMessage({
            id: 'ResetScheduleShiftConfirm.Confirm.reset_shift.header_text',
            defaultMessage: 'Säkert att du vill återställa pass?',
        })} text={intl.formatMessage({
            id: 'ResetScheduleShiftConfirm.Confirm.reset_shift.text',
            defaultMessage: 'Allt som hänt med passet innan kommer att försvinna och det går inte att ändra.',
        })} appearance="danger" onCancel={() => {
            onClose();
        }} disabled={resetScheduleShiftLoading} confirmButton={{
            onConfirm: () => {
                resetScheduleShift({
                    variables: {
                        model: {
                            shiftId: scheduleShiftId,
                        },
                    },
                    onCompleted: () => {
                        onClose();
                    },
                });
            },
            text: intl.formatMessage({ id: 'general.actions.reset', defaultMessage: 'Återställ' }),
        }}/>);
};
export default ResetScheduleShiftConfirm;
