/**
 * @generated SignedSource<<202753b384cc7a9cebb1556b2d23d424>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetAddress",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "street",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postalCode",
            "storageKey": null
        }
    ],
    "type": "Asset",
    "abstractKey": null
};
node.hash = "7a0359ca23d79107b879deb0b3ac7796";
export default node;
