import React, { Suspense, useEffect, useState } from 'react';
import { faUsers, faCube as assetIcon } from '@fortawesome/pro-regular-svg-icons';
import { faUsers as faUsersSolid, faCube as assetIconSolid } from '@fortawesome/pro-solid-svg-icons';
import { IconButton, Flexbox, Input, Skeleton, Tooltip, Button, DrawerContent, Drawer, DrawerHeader, PopoverToSheet, PopoverToSheetHeader, PopoverToSheetContent, PopoverToSheetFooter, Badge, } from '@sonika-se/ui-kit/components';
import { useViewport, usePermissions, useDebounce } from '@sonika-se/ui-kit/hooks';
import SchedulableResourceFilterList from './components/SchedulableResourceFilterList';
import { graphql, useQueryLoader } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import SchedulableResourceEditColor from './components/SchedulableResourceEditColor';
import SchedulableResourceCheckedNumber from './components/SchedulableResourceCheckedNumber';
export const schedulableResourceFilterQuery = graphql `
  query SchedulableResourceFilterQuery(
    $where: SchedulableEntityFilterInput
    $model: SchedulableEntitiesQueryRequestInput
    $checkedWhere: SchedulableEntityFilterInput
  ) {
    ...SchedulableResourceFilterList_resources @arguments(where: $where, model: $model)
    ...SchedulableResourceFilterList_resourcesChecked @arguments(checkedWhere: $checkedWhere)
  }
`;
export const schedulableResourceFilterCheckedNumberQuery = graphql `
  query SchedulableResourceFilterCheckedNumberQuery($checkedWhere: SchedulableEntityFilterInput) {
    checkedNumber: schedulableEntities(where: $checkedWhere) {
      totalCount
    }
  }
`;
export const schedulableResourceFilterQueryColorListQuery = graphql `
  query SchedulableResourceFilterQueryColorListQuery {
    ...SchedulableResourceEditColor_resources
  }
`;
const SchedulableResourceFilter = (props) => {
    const { updateFilters, title, type = 'USER', selectedResourceIds, withColorSelect = false } = props;
    const intl = useIntl();
    const hasPermission = usePermissions();
    const [queryReference, loadQuery, disposeQuery] = useQueryLoader(schedulableResourceFilterQuery);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filter, setFilter] = React.useState('');
    const delayedfilter = useDebounce(filter, 300);
    const [numberOfChecked, setNumberOfChecked] = useState(0);
    const open = Boolean(anchorEl);
    const { size: pageSize } = useViewport();
    const [queryReferenceColor, loadQueryColor, disposeQueryColor] = useQueryLoader(schedulableResourceFilterQueryColorListQuery);
    useEffect(() => {
        return () => disposeQuery();
    }, []);
    const handleClick = (event) => {
        loadQuery({
            model: { nameContains: filter || undefined },
            where: { type: { eq: type }, id: { nin: selectedResourceIds } },
            checkedWhere: { type: { eq: type }, id: { in: selectedResourceIds } },
        });
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const solidIcon = type === 'USER' ? faUsersSolid : assetIconSolid;
    const lightIcon = type === 'USER' ? faUsers : assetIcon;
    return (<>
      <Suspense fallback={null}>
        <SchedulableResourceCheckedNumber type={type} selectedResourceIds={selectedResourceIds} setNumberOfChecked={(number) => setNumberOfChecked(number)}/>
      </Suspense>
      <Tooltip content={title}>
        <Badge appearance={!!numberOfChecked ? 'danger' : 'none'} text={`${numberOfChecked}`}>
          <IconButton appearance={open ? 'filled' : 'outlined'} onClick={handleClick} icon={open ? solidIcon : lightIcon} size={pageSize === 'small' ? 'small' : 'medium'}/>
        </Badge>
      </Tooltip>
      <PopoverToSheet size="auto" height="70vh" style={{ minWidth: '20.5rem' }} anchorEl={open ? anchorEl : null} onClose={handleClose}>
        <PopoverToSheetHeader onClose={handleClose} closeButtonLabel={intl.formatMessage({ id: 'general.actions.close', defaultMessage: 'Stäng' })}>
          {pageSize === 'small' ? (title) : (<Flexbox direction="column" gap="xsmall">
              <div>{title}</div>
              <Input placeholder={intl.formatMessage({ defaultMessage: 'Sök', id: 'general.actions.search' })} value={filter} onChange={(value) => setFilter(value)}/>
            </Flexbox>)}
        </PopoverToSheetHeader>
        <PopoverToSheetContent>
          {pageSize === 'small' && (<div style={{
                padding: '.5rem 1rem',
                position: 'absolute',
                top: '3.5rem',
                left: 0,
                right: 0,
                background: 'white',
                zIndex: 4,
            }}>
              <Input placeholder={intl.formatMessage({ defaultMessage: 'Sök', id: 'general.actions.search' })} value={filter} onChange={(value) => setFilter(value)}/>
            </div>)}
          <Suspense fallback={<Skeleton type="table" height={pageSize === 'small' ? '50vh' : '10rem'}/>}>
            <SchedulableResourceFilterList queryRef={queryReference} selectedResourceIds={selectedResourceIds} type={type} setNumberOfChecked={(number) => {
            setNumberOfChecked(number);
        }} updateFilters={updateFilters} filter={delayedfilter}/>
          </Suspense>
        </PopoverToSheetContent>
        {hasPermission(['EDIT_SCHEDULES']) && withColorSelect && (<PopoverToSheetFooter>
            <Button appearance="text" size="small" display="block" onClick={() => {
                handleClose();
                loadQueryColor({});
            }}>
              <FormattedMessage defaultMessage="Välj färg för {title}" values={{ title }} id="general.misc.color.actions.select_for"/>
            </Button>
          </PopoverToSheetFooter>)}
      </PopoverToSheet>
      {withColorSelect && hasPermission(['EDIT_SCHEDULES']) && (<Drawer open={!!queryReferenceColor} onClose={() => disposeQueryColor()} size="small">
          <DrawerHeader title={intl.formatMessage({
                defaultMessage: 'Välj färg för {title}',
                id: 'general.misc.color.actions.select_for',
            }, {
                title: type === 'USER'
                    ? intl.formatMessage({ id: 'general.objects.user.name.plural', defaultMessage: 'Användare' })
                    : type === 'ASSET'
                        ? intl.formatMessage({ id: 'general.objects.asset.name.plural', defaultMessage: 'Enheter' })
                        : '',
            })}/>
          {!!queryReferenceColor && (<Suspense fallback={<Skeleton type="table" height="calc(var(--vh, 1vh) * 100)"/>}>
              <DrawerContent>
                <SchedulableResourceEditColor queryRef={queryReferenceColor} type={type}/>
              </DrawerContent>
            </Suspense>)}
        </Drawer>)}
    </>);
};
export default SchedulableResourceFilter;
