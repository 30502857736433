import React, { Suspense, useContext, useEffect, useMemo } from 'react';
import { graphql, useRefetchableFragment, useQueryLoader } from 'react-relay/hooks';
import { Flexbox, Chip, IconButton, Placeholder } from '@sonika-se/ui-kit/components';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { createUseStyles } from 'react-jss';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import RunningSchedulesPageContext from '../../../RunningSchedulesPageContext';
import { ScheduleAssignmentTypeResourcesUpdateModal, scheduleAssignmentTypeResourcesUpdateModalQuery, } from '../../../../../../modals/ScheduleAssignmentTypeResourcesUpdateModal';
import { dragSchedulableEntity } from '../../../../utils/dragSchedulableEntity';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            padding: `${pxToRem(8)} ${pxToRem(16)} ${pxToRem(16)}`,
        },
    };
});
export const RunningScheduleAssignmentType = (props) => {
    var _a, _b, _c;
    const { scheduleAssignmentType, setHighlightedSchedulableEntities, scheduleId, filter } = props;
    const [assignmentType, refetch] = useRefetchableFragment(graphql `
      fragment RunningScheduleAssignmentType_scheduleAssignmentType on ScheduleAssignmentType
      @argumentDefinitions(nameContains: { type: "String" })
      @refetchable(queryName: "RunningScheduleAssignmentType_scheduleAssignmentTypeQuery") {
        id
        assignmentType {
          id
          title
        }
        filteredResources: resources(nameContains: $nameContains) {
          edges {
            node {
              id
              schedulableEntity {
                ... on ISchedulableEntity {
                  id
                  color
                  displayName
                  ... on SchedulableAsset {
                    asset {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        schedule {
          currentUserPermissions
        }
      }
    `, scheduleAssignmentType);
    const classes = useStyles();
    const { highlightedSchedulableEntities } = useContext(RunningSchedulesPageContext);
    useEffect(() => {
        refetch({
            nameContains: filter,
        }, { fetchPolicy: 'store-and-network' });
    }, [filter]);
    const [scheduleAssignmentTypeResourcesUpdateModalQueryRef, loadScheduleAssignmentTypeResourcesUpdateModalQuery, disposeScheduleAssignmentTypeResourcesUpdateModalQuery,] = useQueryLoader(scheduleAssignmentTypeResourcesUpdateModalQuery);
    const hasPermission = usePermissions();
    const canEditAssignmentTypes = useMemo(() => hasPermission(['EDIT_SCHEDULES']) ||
        assignmentType.schedule.currentUserPermissions.includes('EDIT_ASSIGNMENT_TYPES'), [assignmentType, hasPermission]);
    return (<div>
      <div className={classes.root}>
        <Flexbox align="center" justify="space">
          <span>{assignmentType.assignmentType.title}</span>
          {canEditAssignmentTypes && (<IconButton appearance="text" color="default" icon={faCog} onClick={() => {
                loadScheduleAssignmentTypeResourcesUpdateModalQuery({
                    scheduleAssignmentTypeId: assignmentType.id,
                }, { fetchPolicy: 'network-only' });
            }} size="small"/>)}
        </Flexbox>
        <Flexbox direction="column" gap="xsmall" style={{ marginTop: '0.5rem' }}>
          {((_b = (_a = assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.filteredResources) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length) === 0 && (<Placeholder text="Inga anställda/enheter valda."/>)}
          {(_c = assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.filteredResources) === null || _c === void 0 ? void 0 : _c.edges.map(({ node: resource }) => {
            const { asset, color, displayName } = resource.schedulableEntity;
            const type = asset ? 'asset' : 'user';
            return (<div key={resource.id}>
                <div draggable="true" onDragStart={(event) => {
                    dragSchedulableEntity(event, type, resource.schedulableEntity.id, assignmentType.assignmentType.id);
                }}>
                  <Chip color={color} fullWidth={true} text={displayName} checkboxProps={{
                    checked: !!(highlightedSchedulableEntities === null || highlightedSchedulableEntities === void 0 ? void 0 : highlightedSchedulableEntities.find((entity) => entity.schedulableEntityId === resource.schedulableEntity.id &&
                        entity.assignmentTypeId === assignmentType.assignmentType.id)),
                    onChange: (checked) => {
                        if (checked) {
                            setHighlightedSchedulableEntities([
                                ...highlightedSchedulableEntities,
                                {
                                    schedulableEntityId: resource.schedulableEntity.id,
                                    assignmentTypeId: assignmentType.assignmentType.id,
                                    scheduleId,
                                },
                            ]);
                        }
                        else {
                            setHighlightedSchedulableEntities(highlightedSchedulableEntities.filter((entity) => !(entity.schedulableEntityId === resource.schedulableEntity.id &&
                                entity.assignmentTypeId === assignmentType.assignmentType.id &&
                                entity.scheduleId === scheduleId)));
                        }
                    },
                }}/>
                </div>
              </div>);
        })}
        </Flexbox>
      </div>
      <Suspense fallback={<></>}>
        {scheduleAssignmentTypeResourcesUpdateModalQueryRef && (<ScheduleAssignmentTypeResourcesUpdateModal filter={filter} onClose={() => {
                refetch({ nameContains: filter }, { fetchPolicy: 'network-only' });
                disposeScheduleAssignmentTypeResourcesUpdateModalQuery();
            }} preloadedQuery={scheduleAssignmentTypeResourcesUpdateModalQueryRef}/>)}
      </Suspense>
    </div>);
};
export default RunningScheduleAssignmentType;
