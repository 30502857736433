/**
 * @generated SignedSource<<e57de68317c2b896167671bfe7d40d60>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AddScheduleShiftForm_query",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "filterModel",
                        "value": {
                            "withCurrentUserPermissions": [
                                "EDIT_RUNNING"
                            ]
                        }
                    }
                ],
                "concreteType": "ScheduleConnection",
                "kind": "LinkedField",
                "name": "schedules",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Schedule",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ScheduleAssignmentTypeConnection",
                                        "kind": "LinkedField",
                                        "name": "assignmentTypes",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ScheduleAssignmentTypeEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ScheduleAssignmentType",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "AssignmentType",
                                                                "kind": "LinkedField",
                                                                "name": "assignmentType",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v0 /*: any*/),
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "title",
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                                "kind": "LinkedField",
                                                                "name": "resources",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                                                        "kind": "LinkedField",
                                                                        "name": "edges",
                                                                        "plural": true,
                                                                        "selections": [
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "concreteType": "ScheduleAssignmentTypeResource",
                                                                                "kind": "LinkedField",
                                                                                "name": "node",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    (v0 /*: any*/),
                                                                                    {
                                                                                        "alias": null,
                                                                                        "args": null,
                                                                                        "concreteType": null,
                                                                                        "kind": "LinkedField",
                                                                                        "name": "schedulableEntity",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            {
                                                                                                "kind": "InlineFragment",
                                                                                                "selections": [
                                                                                                    (v0 /*: any*/),
                                                                                                    {
                                                                                                        "alias": null,
                                                                                                        "args": null,
                                                                                                        "kind": "ScalarField",
                                                                                                        "name": "displayName",
                                                                                                        "storageKey": null
                                                                                                    }
                                                                                                ],
                                                                                                "type": "ISchedulableEntity",
                                                                                                "abstractKey": "__isISchedulableEntity"
                                                                                            }
                                                                                        ],
                                                                                        "storageKey": null
                                                                                    }
                                                                                ],
                                                                                "storageKey": null
                                                                            }
                                                                        ],
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "schedules(filterModel:{\"withCurrentUserPermissions\":[\"EDIT_RUNNING\"]})"
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "cd5f3e787e3653b87d7d15f108071292";
export default node;
