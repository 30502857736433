/**
 * @generated SignedSource<<044b88387aefb81ad78fa1ed10c70452>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesDeleteShiftsModalQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RunningSchedulesDeleteShiftsWizard_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "RunningSchedulesDeleteShiftsModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "filterModel",
                            "value": {
                                "withCurrentUserPermissions": [
                                    "EDIT_RUNNING"
                                ]
                            }
                        }
                    ],
                    "concreteType": "ScheduleConnection",
                    "kind": "LinkedField",
                    "name": "schedules",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "assignmentTypes",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleAssignmentType",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v0 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "AssignmentType",
                                                                    "kind": "LinkedField",
                                                                    "name": "assignmentType",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v0 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "title",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SchedulableEntityConnection",
                                            "kind": "LinkedField",
                                            "name": "schedulableEntities",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SchedulableEntityEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "__typename",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v0 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                                            "kind": "LinkedField",
                                                                            "name": "resourceInScheduleAssignmentTypes",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "edges",
                                                                                    "plural": true,
                                                                                    "selections": [
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "concreteType": "ScheduleAssignmentTypeResource",
                                                                                            "kind": "LinkedField",
                                                                                            "name": "node",
                                                                                            "plural": false,
                                                                                            "selections": [
                                                                                                (v0 /*: any*/),
                                                                                                {
                                                                                                    "alias": null,
                                                                                                    "args": null,
                                                                                                    "concreteType": "ScheduleAssignmentType",
                                                                                                    "kind": "LinkedField",
                                                                                                    "name": "scheduleAssignmentType",
                                                                                                    "plural": false,
                                                                                                    "selections": [
                                                                                                        (v0 /*: any*/),
                                                                                                        {
                                                                                                            "alias": null,
                                                                                                            "args": null,
                                                                                                            "kind": "ScalarField",
                                                                                                            "name": "assignmentTypeId",
                                                                                                            "storageKey": null
                                                                                                        }
                                                                                                    ],
                                                                                                    "storageKey": null
                                                                                                }
                                                                                            ],
                                                                                            "storageKey": null
                                                                                        }
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "ISchedulableEntity",
                                                                    "abstractKey": "__isISchedulableEntity"
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Asset",
                                                                            "kind": "LinkedField",
                                                                            "name": "asset",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v0 /*: any*/),
                                                                                (v1 /*: any*/)
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "SchedulableAsset",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "User",
                                                                            "kind": "LinkedField",
                                                                            "name": "user",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v0 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "firstname",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "lastname",
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "SchedulableUser",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v0 /*: any*/)
                                                                    ],
                                                                    "type": "Node",
                                                                    "abstractKey": "__isNode"
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "schedules(filterModel:{\"withCurrentUserPermissions\":[\"EDIT_RUNNING\"]})"
                }
            ]
        },
        "params": {
            "cacheID": "d56114126ba344ad67ada8828f6d8c3f",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesDeleteShiftsModalQuery",
            "operationKind": "query",
            "text": "query RunningSchedulesDeleteShiftsModalQuery {\n  ...RunningSchedulesDeleteShiftsWizard_query\n}\n\nfragment RunningSchedulesDeleteShiftsWizardStep1_query on Query {\n  schedules(filterModel: {withCurrentUserPermissions: [EDIT_RUNNING]}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment RunningSchedulesDeleteShiftsWizardStep2_schedule on Schedule {\n  assignmentTypes {\n    edges {\n      node {\n        id\n        assignmentType {\n          id\n          title\n        }\n      }\n    }\n  }\n  schedulableEntities {\n    edges {\n      node {\n        __typename\n        ... on ISchedulableEntity {\n          __isISchedulableEntity: __typename\n          id\n          resourceInScheduleAssignmentTypes {\n            edges {\n              node {\n                id\n                scheduleAssignmentType {\n                  id\n                  assignmentTypeId\n                }\n              }\n            }\n          }\n        }\n        ... on SchedulableAsset {\n          asset {\n            id\n            name\n          }\n        }\n        ... on SchedulableUser {\n          user {\n            id\n            firstname\n            lastname\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment RunningSchedulesDeleteShiftsWizard_query on Query {\n  schedules(filterModel: {withCurrentUserPermissions: [EDIT_RUNNING]}) {\n    edges {\n      node {\n        id\n        ...RunningSchedulesDeleteShiftsWizardStep2_schedule\n      }\n    }\n  }\n  ...RunningSchedulesDeleteShiftsWizardStep1_query\n}\n"
        }
    };
})();
node.hash = "e608d4d8dbe4813266f6d20a0cd55372";
export default node;
