import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, array, mixed } from 'yup';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import { Field, FieldList, Checkbox, Input, Form, FormFooter, FormSection, Fieldset, ModalToSheet, ModalToSheetContent, VisuallyHidden, ToggleButtonGroup, ModalToSheetHeader, Select2, Textarea, } from '@sonika-se/ui-kit/components';
import { durationInMinutes } from '@sonika-se/ui-kit/utils/time';
import enums from '~/enums.json';
import EventField from '~/components/EventField';
export const calcOffsetInMinutes = (dayIndex, time) => {
    const [hours, minutes] = time.split(':').map((value) => +value);
    return Number(dayIndex) * 24 * 60 + hours * 60 + minutes;
};
const validationSchema = object({
    assignees: array()
        .of(object({
        assignedEntityId: string().nullable(),
        assignmentTypeId: string().required(),
        eventId: string().required('Du måste ange en händelse'),
    }))
        .required(),
    dayInPeriods: array().of(string()).compact().min(1, 'Du måste välja minst en dag').required(),
    endTime: string().required('Du måste ange sluttid'),
    periodIds: array().of(string()).compact().min(1, 'Du måste välja minst en period').required(),
    description: string(),
    startTime: string().required('Du måste ange starttid'),
    breaks: array()
        .of(object({
        offsetEnd: string().matches(/[0-9][0-9]:[0-9][0-9]/),
        offsetStart: string().matches(/[0-9][0-9]:[0-9][0-9]/),
        type: mixed().oneOf(Object.keys(enums.BreakEnum)),
    }))
        .notRequired(),
});
export const NewScheduleTemplateShiftModal = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { initialPeriodId, initialDayInPeriod, onCancel, onSubmit, scheduleTemplateId } = props;
    const intl = useIntl();
    const scheduleShiftBreakType = enums.BreakEnum;
    const { control, formState: { errors }, handleSubmit, setValue, watch, } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            assignees: [
                {
                    assignedEntityId: '',
                    assignmentTypeId: '',
                },
            ],
            endTime: '',
            startTime: '',
            description: '',
        },
    });
    const [hasBreak, setHasBreak] = useState(false);
    const { scheduleTemplate } = useLazyLoadQuery(graphql `
      query NewScheduleTemplateShiftModalQuery($scheduleTemplateId: ID!) {
        scheduleTemplate(id: $scheduleTemplateId) {
          id
          periodLength
          periods(order: { order: ASC }) {
            nodes {
              id
              name
            }
          }
          schedule {
            assignmentTypes {
              edges {
                node {
                  id
                  assignmentType {
                    id
                    title
                  }
                  resources {
                    edges {
                      node {
                        schedulableEntity {
                          ... on ISchedulableEntity {
                            id
                            displayName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          startsOnWeekday
        }
      }
    `, {
        scheduleTemplateId,
    }, { fetchPolicy: 'network-only' });
    const assignmentTypes = scheduleTemplate.schedule.assignmentTypes.edges.map(({ node: assignmentType }) => ({
        label: assignmentType.assignmentType.title,
        value: assignmentType.assignmentType.id,
    }));
    const weekday = enums.Weekday;
    const [createPeriodShifts, isInFlight] = useMutation(graphql `
    mutation NewScheduleTemplateShiftModalCreateMutation($model: [CreateScheduleTemplateShiftRequestInput!]!) {
      createScheduleTemplateShifts(model: $model) {
        shiftEdge {
          node {
            period {
              ...ScheduleTemplatePeriod_period
            }
          }
        }
      }
    }
  `);
    const resources = scheduleTemplate.schedule.assignmentTypes.edges.reduce((prev, { node: item }) => {
        return Object.assign(Object.assign({}, prev), { [item.assignmentType.id]: item === null || item === void 0 ? void 0 : item.resources.edges.map(({ node }) => ({
                value: node.schedulableEntity.id,
                label: node.schedulableEntity.displayName,
            })) });
    }, {});
    const periods = scheduleTemplate.periods.nodes;
    useEffect(() => {
        if (initialPeriodId !== undefined) {
            setValue('periodIds', periods.map((period) => (period.id === initialPeriodId ? period.id : null)));
        }
        else if (periods.length === 1) {
            setValue('periodIds', periods.map((period) => period.id));
        }
        if (initialDayInPeriod !== undefined) {
            setValue('dayInPeriods', [initialDayInPeriod.toString()]);
        }
        console.log({ initialPeriodId, initialDayInPeriod, periods });
    }, []);
    return (<ModalToSheet onClose={() => onCancel()} size="large">
      <ModalToSheetHeader onClose={() => onCancel()} closeButtonLabel="Avbryt">
        Lägg till pass
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Form onSubmit={handleSubmit((formData) => {
            function onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }
            const models = [];
            formData.dayInPeriods.map((dayIndex) => formData.periodIds.filter(onlyUnique).map((periodId) => {
                formData.assignees.map((assignee) => {
                    models.push({
                        eventId: assignee.eventId,
                        breaks: hasBreak
                            ? formData.breaks.map((item) => (Object.assign(Object.assign({}, item), { offsetStart: {
                                    value: durationInMinutes(formData.startTime, item.offsetStart),
                                    unit: 'MINUTE',
                                }, offsetEnd: {
                                    value: durationInMinutes(formData.startTime, item.offsetStart) +
                                        durationInMinutes(item.offsetStart, item.offsetEnd),
                                    unit: 'MINUTE',
                                } })))
                            : null,
                        assignedEntityId: assignee.assignedEntityId !== '' ? assignee.assignedEntityId : null,
                        assignmentTypeId: assignee.assignmentTypeId,
                        description: formData.description,
                        startTimeOffset: calcOffsetInMinutes(dayIndex, formData.startTime),
                        endTimeOffset: calcOffsetInMinutes(formData.startTime > formData.endTime ? (+dayIndex + 1).toString() : dayIndex, formData.endTime),
                        periodId,
                    });
                });
            }));
            createPeriodShifts({
                variables: {
                    model: models,
                },
                onCompleted: () => onSubmit(),
            });
        })}>
          <FormSection columns="1fr 1fr" title="">
            <VisuallyHidden visible={!initialPeriodId && !initialDayInPeriod}>
              <Fieldset columnSpan={2} direction="row" title={intl.formatMessage({
            defaultMessage: 'Perioder',
            id: 'general.misc.period.name.plural',
        })} error={(_a = errors === null || errors === void 0 ? void 0 : errors.periodIds) === null || _a === void 0 ? void 0 : _a.toString()}>
                <Controller control={control} name="periodIds" render={({ field }) => (<ToggleButtonGroup type="checkbox" width="full" disabled={isInFlight} onChange={(event) => {
                if (event.target.checked) {
                    field.onChange([...(field.value || []), event.target.value]);
                }
                else {
                    field.onChange([...(field.value || [])].filter((id) => id !== event.target.value));
                }
            }} options={periods.map((period) => ({ label: period.name, value: period.id }))} value={field.value}/>)}/>
              </Fieldset>
              <Fieldset columnSpan={2} direction="row" title={intl.formatMessage({
            defaultMessage: 'Dagar',
            id: 'general.misc.day.name.plural',
        })} error={(_b = errors === null || errors === void 0 ? void 0 : errors.dayInPeriods) === null || _b === void 0 ? void 0 : _b.toString()}>
                <Controller control={control} name="dayInPeriods" render={({ field }) => (<ToggleButtonGroup type="checkbox" disabled={isInFlight} width="full" onChange={(event) => {
                if (event.target.checked) {
                    field.onChange([...(field.value || []), event.target.value]);
                }
                else {
                    field.onChange([...(field.value || [])].filter((id) => id !== event.target.value));
                }
            }} options={Object.keys(weekday).map((day, dayIndex) => {
                var _a;
                return ({
                    label: `${(_a = weekday[day]) === null || _a === void 0 ? void 0 : _a.description}`.substring(0, 3),
                    value: dayIndex,
                });
            })} value={field.value}/>)}/>
              </Fieldset>
            </VisuallyHidden>
            <Field error={(_d = (_c = errors === null || errors === void 0 ? void 0 : errors.startTime) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString()} label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.start.label',
            defaultMessage: 'Starttid',
        })}>
              <Controller control={control} name="startTime" render={({ field }) => (<Input onChange={field.onChange} placeholder="00:00" type="time" disabled={isInFlight} value={field.value}/>)}/>
            </Field>
            <Field error={(_f = (_e = errors === null || errors === void 0 ? void 0 : errors.endTime) === null || _e === void 0 ? void 0 : _e.message) === null || _f === void 0 ? void 0 : _f.toString()} label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.end.label',
            defaultMessage: 'Sluttid',
        })}>
              <Controller control={control} name="endTime" render={({ field }) => (<Input onChange={field.onChange} placeholder="00:00" type="time" disabled={isInFlight} value={field.value}/>)}/>
            </Field>
            <Field direction="row-reverse" columnSpan={2} label={intl.formatMessage({
            defaultMessage: 'Passet innehåller raster',
            id: 'general.misc.schedule_shift.fields.contains_breaks.placeholder',
        })}>
              <Checkbox inputName="hasBreak" disabled={isInFlight} onChange={(e) => setHasBreak(e.target.checked)}/>
            </Field>
          </FormSection>
          {hasBreak && (<FormSection>
              <FieldList appendText={intl.formatMessage({
                defaultMessage: 'Lägg till rast',
                id: 'general.misc.breaks.actions.create',
            })} compact control={control} disabled={isInFlight} name="breaks" label={intl.formatMessage({
                defaultMessage: 'Raster',
                id: 'general.misc.breaks.name.plural',
            })} render={(field, index) => {
                var _a, _b, _c, _d, _e, _f, _g;
                return (<FormSection columns="1fr 1fr 1fr" key={field.id}>
                    <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_c = (_b = (_a = errors.breaks[index]) === null || _a === void 0 ? void 0 : _a.offsetStart) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.toString())}>
                      <Controller control={control} name={`breaks.${index}.offsetStart`} render={({ field }) => (<Input defaultValue={field.value} onChange={field.onChange} type="time" value={field.value}/>)}/>
                    </Field>
                    <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_f = (_e = (_d = errors.breaks[index]) === null || _d === void 0 ? void 0 : _d.offsetEnd) === null || _e === void 0 ? void 0 : _e.message) === null || _f === void 0 ? void 0 : _f.toString())}>
                      <Controller control={control} name={`breaks.${index}.offsetEnd`} render={({ field }) => (<Input defaultValue={field.value} onChange={field.onChange} type="time" value={field.value}/>)}/>
                    </Field>
                    <Field error={(errors === null || errors === void 0 ? void 0 : errors.breaks) && ((_g = errors.breaks[index]) === null || _g === void 0 ? void 0 : _g.type.toString())}>
                      <Controller control={control} name={`breaks.${index}.type`} render={({ field }) => {
                        var _a;
                        return (<Select2 disabled={isInFlight} options={(_a = Object.keys(scheduleShiftBreakType)) === null || _a === void 0 ? void 0 : _a.map((key) => {
                                var _a;
                                return ({
                                    label: intl.formatMessage({
                                        id: `BreakEnum_${key}`,
                                        defaultMessage: (_a = scheduleShiftBreakType[key]) === null || _a === void 0 ? void 0 : _a.description,
                                    }),
                                    value: key,
                                });
                            })} onChange={(value) => {
                                field.onChange(value === null || value === void 0 ? void 0 : value.single);
                            }} placeholder={intl.formatMessage({
                                defaultMessage: 'Välj typ',
                                id: 'general.misc.breaks.fields.type.placeholder',
                            })} defaultValue={field.value}/>);
                    }}/>
                    </Field>
                  </FormSection>);
            }}/>
            </FormSection>)}
          <FormSection columns="1fr" title="">
            <Field error={(_h = (_g = errors === null || errors === void 0 ? void 0 : errors.description) === null || _g === void 0 ? void 0 : _g.message) === null || _h === void 0 ? void 0 : _h.toString()} label="Beskrivning" columnSpan={2}>
              <Controller control={control} name="description" render={({ field }) => (<Textarea onChange={(event) => {
                field.onChange(event.target.value);
            }} placeholder="Beskrivning av passet" value={field.value}/>)}/>
            </Field>
            <FieldList appendText={intl.formatMessage({
            defaultMessage: 'Lägg till anställd eller enhet',
            id: 'AddScheduleShiftForm.assignees.append_text',
        })} compact control={control} disabled={isInFlight} label={intl.formatMessage({
            defaultMessage: 'Välj anställda och enheter',
            id: 'AddScheduleShiftForm.assignees.label',
        })} name="assignees" error={(errors === null || errors === void 0 ? void 0 : errors.assignees) && ((_l = (_k = (_j = errors.assignees[0]) === null || _j === void 0 ? void 0 : _j.assignmentTypeId) === null || _k === void 0 ? void 0 : _k.message) === null || _l === void 0 ? void 0 : _l.toString())} render={(field, index) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            const values = watch('assignees')[index];
            return (<FormSection columns="1fr 1fr 1fr" key={field.id}>
                    <Field error={(errors === null || errors === void 0 ? void 0 : errors.assignees) && ((_c = (_b = (_a = errors.assignees[index]) === null || _a === void 0 ? void 0 : _a.assignmentTypeId) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.toString())}>
                      <Controller control={control} name={`assignees.${index}.assignmentTypeId`} render={({ field }) => (<Select2 disabled={isInFlight || !scheduleTemplate.schedule.assignmentTypes.edges.length} onChange={({ single }) => field.onChange(single)} options={assignmentTypes} placeholder="Välj roll" defaultValue={field.value}/>)}/>
                    </Field>
                    <Field columnSpan={1} error={(errors === null || errors === void 0 ? void 0 : errors.assignees) && ((_f = (_e = (_d = errors.assignees[index]) === null || _d === void 0 ? void 0 : _d.eventId) === null || _e === void 0 ? void 0 : _e.message) === null || _f === void 0 ? void 0 : _f.toString())}>
                      <Controller control={control} name={`assignees.${index}.eventId`} render={({ field }) => {
                    var _a, _b, _c;
                    return (<EventField assignmentTypeId={values.assignmentTypeId} where={{ isWork: { eq: true } }} error={(errors === null || errors === void 0 ? void 0 : errors.assignees) && ((_c = (_b = (_a = errors.assignees[index]) === null || _a === void 0 ? void 0 : _a.eventId) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.toString())} inputName={`assignees[${index}].eventId`} id={`assignees[${index}].eventId`} disabled={isInFlight || !values.assignmentTypeId} onChange={(value) => field.onChange(value ? value[0] : undefined)} placeholder={intl.formatMessage({
                            defaultMessage: 'Välj händelse',
                            id: 'general.misc.schedule_shift.fields.event_id.placeholder',
                        })} defaultValue={field.value}/>);
                }}/>
                    </Field>
                    <Field error={(errors === null || errors === void 0 ? void 0 : errors.assignees) && ((_j = (_h = (_g = errors.assignees[index]) === null || _g === void 0 ? void 0 : _g.assignedEntityId) === null || _h === void 0 ? void 0 : _h.message) === null || _j === void 0 ? void 0 : _j.toString())}>
                      <Controller control={control} name={`assignees.${index}.assignedEntityId`} render={({ field }) => (<Select2 disabled={isInFlight || !values.assignmentTypeId} onChange={({ single }) => field.onChange(single)} options={[
                        {
                            label: intl.formatMessage({
                                defaultMessage: 'Vakant',
                                id: 'general.misc.schedule_shift.vacant.singular',
                            }),
                            value: null,
                        },
                        ...(resources[values.assignmentTypeId] || []),
                    ]} placeholder="Välj anställd/enhet" defaultValue={field.value}/>)}/>
                    </Field>
                  </FormSection>);
        }}/>
          </FormSection>
          <FormFooter disabled={isInFlight} onCancel={() => onCancel()} submitText={intl.formatMessage({ id: 'general.actions.create', defaultMessage: 'Lägg till' })}></FormFooter>
        </Form>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default NewScheduleTemplateShiftModal;
