import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShiftHistoryEvent } from './ScheduleShiftHistoryEvent';
import User from '../User';
import SchedulableEntity from '../ScheduleableEntity/SchedulableEntity';
export const ScheduleShiftHistoryAssigneeChange = (props) => {
    const data = useFragment(graphql `
      fragment ScheduleShiftHistoryAssigneeChange_history on ScheduleShiftHistoryAssigneeChange {
        id
        reasonEvent {
          name
          id
        }
        byUser {
          ...User_user
        }
        newAssignedEntity {
          ...SchedulableEntity_schedulableEntity
        }
        previouslyAssignedEntity {
          ...SchedulableEntity_schedulableEntity
        }
        timestamp
      }
    `, props.history);
    const intl = useIntl();
    const { reasonEvent, byUser, newAssignedEntity, previouslyAssignedEntity, timestamp } = data;
    return (<ShiftHistoryEvent timestamp={timestamp.toString()}>
      <p>
        {newAssignedEntity ? (<FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryAssigneeChange.replaced" defaultMessage="{user} ersatte {originalUser} med {newUser} pga {reason}." values={{
                user: <User user={byUser}/>,
                originalUser: (<strong style={{ fontWeight: 'bold' }}>
                  <SchedulableEntity schedulableEntity={previouslyAssignedEntity} isVacant={previouslyAssignedEntity == null}/>
                </strong>),
                newUser: (<strong style={{ fontWeight: 'bold' }}>
                  <SchedulableEntity schedulableEntity={newAssignedEntity} isVacant={newAssignedEntity == null}/>
                </strong>),
                reason: (<strong style={{ fontWeight: 'bold' }}>
                  {(reasonEvent === null || reasonEvent === void 0 ? void 0 : reasonEvent.name) ||
                        intl.formatMessage({ id: 'assigneeeChangeReason_No_REASON', defaultMessage: 'Ingen anledning' })}
                </strong>),
            }}/>) : (<FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryAssigneeChange.deleted" defaultMessage="{user} tog bort {originalUser} från passet pga {reason}." values={{
                user: <User user={byUser}/>,
                originalUser: (<strong style={{ fontWeight: 'bold' }}>
                  <SchedulableEntity schedulableEntity={previouslyAssignedEntity} isVacant={previouslyAssignedEntity == null}/>
                </strong>),
                reason: <strong style={{ fontWeight: 'bold' }}>{reasonEvent === null || reasonEvent === void 0 ? void 0 : reasonEvent.name}</strong>,
            }}/>)}
      </p>
    </ShiftHistoryEvent>);
};
