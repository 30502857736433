/**
 * @generated SignedSource<<1318f78f568c55c14fb6f316830d1c98>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleTemplateTablePanel_schedule",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleTemplateTable_schedule"
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentUserPermissions",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Schedule",
    "abstractKey": null
};
node.hash = "bd3057212f1cdcd4bf6c021d98451479";
export default node;
