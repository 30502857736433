import React, { useState } from 'react';
import { faUserSlash } from '@fortawesome/pro-light-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flexbox, Form, Icon, Input, Notification, Text, ToggleButtonGroup } from '@sonika-se/ui-kit/components';
import { useDidMountEffect, useViewport } from '@sonika-se/ui-kit/hooks';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import { object, string } from 'yup';
import enums from '~/enums.json';
const useStyles = createUseStyles((theme) => ({
    ScheduleShiftInterestForm: {
        marginTop: '-1rem',
        paddingLeft: theme.helpers.pxToRem(4),
    },
}));
export const validationSchema = object({
    status: string().required(),
    description: string(),
});
const ScheduleShiftInterestForm = (props) => {
    var _a, _b;
    const classes = useStyles();
    const intl = useIntl();
    const data = useFragment(graphql `
      fragment ScheduleShiftInterestForm_scheduleShift on ScheduleShift {
        id
        currentVacancyInterestForEntity {
          description
          status
        }
      }
    `, props.scheduleShiftRef);
    const vacancyInterestStatus = enums.VacancyInterestStatus;
    const { control, formState: { errors }, handleSubmit, reset, watch, } = useForm({
        defaultValues: {
            status: (_a = data === null || data === void 0 ? void 0 : data.currentVacancyInterestForEntity) === null || _a === void 0 ? void 0 : _a.status,
            description: (_b = data === null || data === void 0 ? void 0 : data.currentVacancyInterestForEntity) === null || _b === void 0 ? void 0 : _b.description,
        },
        resolver: yupResolver(validationSchema),
    });
    const [editMode, setEditMode] = useState(false);
    const [createScheduleShiftVacancyInterest, createScheduleShiftVacancyInterestLoading] = useMutation(graphql `
        mutation ScheduleShiftInterestForm_createScheduleShiftVacancyInterestMutation(
          $model: CreateScheduleShiftVacancyInterestRequestInput!
        ) {
          createScheduleShiftVacancyInterest(model: $model) {
            shiftEdge {
              node {
                ...ScheduleShiftVacancyCard_scheduleShift
                ...ScheduleShiftHistory_scheduleShift
                currentVacancyInterestForEntity {
                  id
                  status
                }
                vacancyInterests {
                  ...ScheduleShiftHistoryVacancyInterestList_scheduleShiftHistoryVacancyInterestConnection
                }
              }
            }
          }
        }
      `);
    const selectedStatus = watch('status');
    const selectedDescription = watch('description');
    useDidMountEffect(() => {
        var _a, _b;
        if (selectedStatus !== (((_a = data === null || data === void 0 ? void 0 : data.currentVacancyInterestForEntity) === null || _a === void 0 ? void 0 : _a.status) || '') ||
            selectedDescription !== (((_b = data === null || data === void 0 ? void 0 : data.currentVacancyInterestForEntity) === null || _b === void 0 ? void 0 : _b.description) || '')) {
            setEditMode(true);
        }
        else {
            setEditMode(false);
        }
    }, [selectedDescription, selectedStatus]);
    const { size } = useViewport();
    return (<Notification shadow={false} color="error">
      <Form onSubmit={handleSubmit((values) => {
            createScheduleShiftVacancyInterest({
                onError: (error) => {
                    console.log({ error });
                },
                onCompleted: () => {
                    setEditMode(false);
                },
                variables: {
                    model: {
                        schedulableEntityId: props.schedulableEntityId,
                        shiftId: data === null || data === void 0 ? void 0 : data.id,
                        description: values === null || values === void 0 ? void 0 : values.description,
                        status: values.status,
                    },
                },
            });
        })}>
        <Flexbox direction="column" gap="small" className={classes.ScheduleShiftInterestForm}>
          <Flexbox justify="space" align={size === 'small' ? 'start' : 'center'} direction={size === 'small' ? 'column' : 'row'} gap="small">
            <Flexbox gap="small">
              <Icon icon={faUserSlash} color="#FD544F" size="lg"/>
              <Text>Är du intresserad av passet?</Text>
            </Flexbox>
            <Controller control={control} name="status" render={({ field }) => {
            var _a;
            return (<ToggleButtonGroup width={size === 'small' ? 'full' : 'auto'} error={(_a = errors === null || errors === void 0 ? void 0 : errors.status) === null || _a === void 0 ? void 0 : _a.message} onChange={field.onChange} value={field.value} options={Object.keys(vacancyInterestStatus).map((key) => {
                    var _a;
                    return ({
                        label: intl.formatMessage({
                            defaultMessage: (_a = vacancyInterestStatus[key]) === null || _a === void 0 ? void 0 : _a.description,
                            id: `VacancyInterestStatus_${key}`,
                        }),
                        value: key,
                    });
                })} appearance="default" type="radio"/>);
        }}/>
          </Flexbox>

          <Flexbox direction="column" gap="xsmall">
            <Controller control={control} name="description" render={({ field }) => {
            var _a;
            return (<Input type="text" placeholder="Kommentar" error={(_a = errors === null || errors === void 0 ? void 0 : errors.description) === null || _a === void 0 ? void 0 : _a.message} onChange={field.onChange} value={field.value}/>);
        }}/>
            {!!editMode && (<Flexbox gap="xsmall" justify="end">
                <Button appearance="outlined" onClick={() => {
                var _a, _b;
                reset({
                    status: ((_a = data === null || data === void 0 ? void 0 : data.currentVacancyInterestForEntity) === null || _a === void 0 ? void 0 : _a.status) || '',
                    description: ((_b = data === null || data === void 0 ? void 0 : data.currentVacancyInterestForEntity) === null || _b === void 0 ? void 0 : _b.description) || '',
                });
            }}>
                  Avbryt
                </Button>
                <Button type="submit">Spara</Button>
              </Flexbox>)}
          </Flexbox>
        </Flexbox>
      </Form>
    </Notification>);
};
export default ScheduleShiftInterestForm;
