/**
 * @generated SignedSource<<490b722569e0d208abf14a7870a08b66>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        (v3 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleTemplateShiftClashesRefetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ScheduleTemplateShiftClashes_scheduleTemplate"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleTemplateShiftClashesRefetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleTemplateShiftClashGroup",
                                    "kind": "LinkedField",
                                    "name": "shiftClashes",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleTemplateShift",
                                            "kind": "LinkedField",
                                            "name": "clashes",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": null,
                                                    "kind": "LinkedField",
                                                    "name": "assignedEntity",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Asset",
                                                                    "kind": "LinkedField",
                                                                    "name": "asset",
                                                                    "plural": false,
                                                                    "selections": (v4 /*: any*/),
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "type": "SchedulableAsset",
                                                            "abstractKey": null
                                                        },
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "User",
                                                                    "kind": "LinkedField",
                                                                    "name": "user",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "firstname",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "color",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Avatar",
                                                                            "kind": "LinkedField",
                                                                            "name": "avatar",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v3 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "kind": "ScalarField",
                                                                                    "name": "storageId",
                                                                                    "storageKey": null
                                                                                },
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": [
                                                                                        {
                                                                                            "kind": "Literal",
                                                                                            "name": "queryTransform",
                                                                                            "value": "width=68&height=68&mode=crop&scale=both"
                                                                                        }
                                                                                    ],
                                                                                    "kind": "ScalarField",
                                                                                    "name": "url",
                                                                                    "storageKey": "url(queryTransform:\"width=68&height=68&mode=crop&scale=both\")"
                                                                                },
                                                                                {
                                                                                    "alias": "imageUrl",
                                                                                    "args": [
                                                                                        {
                                                                                            "kind": "Literal",
                                                                                            "name": "queryTransform",
                                                                                            "value": "width=400&height=400&mode=crop&scale=both"
                                                                                        }
                                                                                    ],
                                                                                    "kind": "ScalarField",
                                                                                    "name": "url",
                                                                                    "storageKey": "url(queryTransform:\"width=400&height=400&mode=crop&scale=both\")"
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "lastname",
                                                                            "storageKey": null
                                                                        },
                                                                        (v3 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "type": "SchedulableUser",
                                                            "abstractKey": null
                                                        },
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                (v3 /*: any*/)
                                                            ],
                                                            "type": "Node",
                                                            "abstractKey": "__isNode"
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AssignmentType",
                                                    "kind": "LinkedField",
                                                    "name": "assignmentType",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "title",
                                                            "storageKey": null
                                                        },
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleTemplatePeriodDay",
                                                    "kind": "LinkedField",
                                                    "name": "day",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "weekday",
                                                            "storageKey": null
                                                        },
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endTimeOfDay",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleTemplatePeriod",
                                                    "kind": "LinkedField",
                                                    "name": "period",
                                                    "plural": false,
                                                    "selections": (v4 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "startTimeOfDay",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "ScheduleTemplate",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e7ec5e5e37d2d75620eae291aaafec8a",
            "id": null,
            "metadata": {},
            "name": "ScheduleTemplateShiftClashesRefetchQuery",
            "operationKind": "query",
            "text": "query ScheduleTemplateShiftClashesRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ScheduleTemplateShiftClashes_scheduleTemplate\n    id\n  }\n}\n\nfragment ScheduleTemplateShiftClashGroup_scheduleTemplateShiftClashGroup on ScheduleTemplateShiftClashGroup {\n  clashes {\n    id\n    assignedEntity {\n      __typename\n      ... on SchedulableAsset {\n        id\n        asset {\n          name\n          id\n        }\n      }\n      ... on SchedulableUser {\n        id\n        user {\n          ...User\n          firstname\n          lastname\n          id\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    ...ScheduleTemplateShiftClash_scheduleTemplateShift\n  }\n}\n\nfragment ScheduleTemplateShiftClash_scheduleTemplateShift on ScheduleTemplateShift {\n  id\n  assignmentType {\n    title\n    id\n  }\n  day {\n    weekday\n    id\n  }\n  endTimeOfDay\n  period {\n    name\n    id\n  }\n  startTimeOfDay\n}\n\nfragment ScheduleTemplateShiftClashes_scheduleTemplate on ScheduleTemplate {\n  id\n  shiftClashes {\n    clashes {\n      assignedEntity {\n        __typename\n        ... on SchedulableAsset {\n          id\n          asset {\n            name\n            id\n          }\n        }\n        ... on SchedulableUser {\n          id\n          user {\n            ...User\n            firstname\n            lastname\n            id\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      id\n    }\n    ...ScheduleTemplateShiftClashGroup_scheduleTemplateShiftClashGroup\n  }\n}\n\nfragment User on User {\n  firstname\n  color\n  avatar {\n    id\n    storageId\n    url(queryTransform: \"width=68&height=68&mode=crop&scale=both\")\n    ...UserImageLightBox\n  }\n}\n\nfragment UserImageLightBox on Avatar {\n  imageUrl: url(queryTransform: \"width=400&height=400&mode=crop&scale=both\")\n}\n"
        }
    };
})();
node.hash = "47724d0a239ed142b4bd04989f5869e2";
export default node;
