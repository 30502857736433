import React, { useState } from 'react';
import { Chip, Flexbox, Text, H6, Button } from '@sonika-se/ui-kit/components';
import { formatDateSpan, toDate } from '@sonika-se/ui-kit/utils/time';
import { useIntl } from 'react-intl';
import enums from '~/enums.json';
import ScheduleShiftBreakForm from './ScheduleShiftBreakForm';
const ScheduleShiftBreakCard = (props) => {
    var _a;
    const { breakItem, editable = false, sameDay, onUpdate, isLoading = false, onDelete } = props;
    const [formOpen, setFormOpen] = useState(false);
    const dateFormat = sameDay ? 'HH:mm' : 'MM/dd HH:mm';
    const intl = useIntl();
    const scheduleShiftBreakType = enums.BreakEnum;
    if (formOpen) {
        return (<Flexbox direction="column" gap="small">
        <H6>Uppdatera rast</H6>
        <ScheduleShiftBreakForm sameDay={sameDay} isLoading={isLoading} onClose={() => setFormOpen(false)} onSubmit={(values) => {
                if (!!onUpdate)
                    onUpdate(values);
            }} {...breakItem}>
          {!!onDelete && (<Button size="small" appearance="outlined" color="danger" disabled={isLoading} onClick={onDelete}>
              Ta bort
            </Button>)}
        </ScheduleShiftBreakForm>
      </Flexbox>);
    }
    return (<Flexbox gap="xsmall" align="center" onClick={editable ? () => setFormOpen(true) : undefined}>
      <Text bold>{formatDateSpan(toDate(breakItem.start), toDate(breakItem.end), 'shortTime')}</Text>
      <Chip type="view" size="xsmall" appearance="secondary" text={intl.formatMessage({
            defaultMessage: (_a = scheduleShiftBreakType[breakItem.type]) === null || _a === void 0 ? void 0 : _a.description,
            id: `BreakEnum_${breakItem.type}`,
        })}/>
    </Flexbox>);
};
export default ScheduleShiftBreakCard;
