/**
 * @generated SignedSource<<56c43e8ba2a1dc2736f082ad41c4fc37>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "refetch": {
            "connection": null,
            "fragmentPathInResult": [],
            "operation": require('./ScheduleShiftVacancyCountBadge_queryQuery.graphql')
        }
    },
    "name": "ScheduleShiftVacancyCountBadge_query",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                },
                {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                        "assignedEntityId": {
                            "eq": null
                        }
                    }
                }
            ],
            "concreteType": "ScheduleShiftConnection",
            "kind": "LinkedField",
            "name": "scheduleShifts",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "e2ad8273c18b69a579c13d8354953312";
export default node;
