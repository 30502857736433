import React, { useEffect, useTransition } from 'react';
import { graphql, useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks';
import { Badge } from '@sonika-se/ui-kit/components';
import { formatISO } from 'date-fns';
const REFETCH_INTERVAL_IN_MS = 30 * 1000;
export const ScheduleShiftVacancyCountBadge = (props) => {
    const { children, fromDate, toDate } = props;
    const data = useLazyLoadQuery(graphql `
      query ScheduleShiftVacancyCountBadgeQuery($currentScheduleShiftVacancyesModel: GetRunningScheduleRequestInput!) {
        ...ScheduleShiftVacancyCountBadge_query @arguments(model: $currentScheduleShiftVacancyesModel)
      }
    `, {
        currentScheduleShiftVacancyesModel: {
            fromDate: formatISO(fromDate),
            toDate: formatISO(toDate),
        },
    });
    const [{ scheduleShifts }, refetch] = useRefetchableFragment(graphql `
      fragment ScheduleShiftVacancyCountBadge_query on Query
      @argumentDefinitions(model: { type: "GetRunningScheduleRequestInput!" })
      @refetchable(queryName: "ScheduleShiftVacancyCountBadge_queryQuery") {
        scheduleShifts(model: $model, where: { assignedEntityId: { eq: null } }) {
          totalCount
        }
      }
    `, data);
    const [, startTransition] = useTransition();
    useEffect(() => {
        const intervalId = setInterval(() => {
            startTransition(() => {
                refetch({}, { fetchPolicy: 'network-only' });
            });
        }, REFETCH_INTERVAL_IN_MS);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    return (<Badge appearance="danger" text={scheduleShifts.totalCount.toString()}>
      {children}
    </Badge>);
};
export default ScheduleShiftVacancyCountBadge;
