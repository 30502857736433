import React from 'react';
import { ModalToSheet, ModalToSheetContent, ModalToSheetHeader } from '@sonika-se/ui-kit/components';
import UpdateScheduleShiftAssigneesForm from '../UpdateScheduleShiftAssigneesForm';
const RunningSchedulesChangeAssigneeReasonModal = (props) => {
    const { onCancel, preloadedQuery, newEntityId } = props;
    return (<ModalToSheet onClose={onCancel}>
      <ModalToSheetHeader onClose={onCancel} closeButtonLabel="Avbryt">
        Ersätt
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <UpdateScheduleShiftAssigneesForm preloadedQuery={preloadedQuery} onCancel={onCancel} newEntityId={newEntityId}/>
      </ModalToSheetContent>
    </ModalToSheet>);
};
RunningSchedulesChangeAssigneeReasonModal.propTypes = {};
export default RunningSchedulesChangeAssigneeReasonModal;
