import React from 'react';
import { ModalToSheet, ModalToSheetContent, ModalToSheetHeader } from '@sonika-se/ui-kit/components';
import { graphql, usePreloadedQuery } from 'react-relay/hooks';
import UserPicker from './UserPicker';
export const userPickerModalQuery = graphql `
  query UserPickerModalQuery {
    ...UserPicker_query
  }
`;
export const UserPickerModal = (props) => {
    const { onClose, onSelect, preloadedQuery } = props;
    const data = usePreloadedQuery(userPickerModalQuery, preloadedQuery);
    return (<ModalToSheet onClose={() => onClose()}>
      <ModalToSheetHeader onClose={() => onClose()}>Välj användare</ModalToSheetHeader>
      <ModalToSheetContent>
        <UserPicker onSelect={onSelect} queryRef={data}/>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default UserPickerModal;
