/**
 * @generated SignedSource<<14b394e7caae2fe023efd0cb7caf89b1>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
    }, v2 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    }, v3 = {
        "kind": "Variable",
        "name": "fromDate",
        "variableName": "fromDate"
    }, v4 = {
        "kind": "Variable",
        "name": "toDate",
        "variableName": "toDate"
    }, v5 = {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
    }, v6 = [
        (v1 /*: any*/),
        (v2 /*: any*/),
        (v3 /*: any*/),
        {
            "kind": "Variable",
            "name": "shiftsFilter",
            "variableName": "shiftsFilter"
        },
        (v4 /*: any*/),
        (v5 /*: any*/)
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "after"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "filterModel"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "first"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "fromDate"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "shiftsFilter"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "toDate"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "where"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "whereSchedules"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [],
                "operation": require('./RunningSchedulesGrid_schedulesQuery.graphql')
            }
        },
        "name": "RunningSchedulesGrid_schedules",
        "selections": [
            {
                "alias": "gridSchedules",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "filterModel",
                        "variableName": "filterModel"
                    },
                    {
                        "kind": "Variable",
                        "name": "where",
                        "variableName": "whereSchedules"
                    }
                ],
                "concreteType": "ScheduleConnection",
                "kind": "LinkedField",
                "name": "schedules",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Schedule",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "currentUserPermissions",
                                        "storageKey": null
                                    },
                                    {
                                        "args": (v6 /*: any*/),
                                        "kind": "FragmentSpread",
                                        "name": "RunningSchedulesGridSchedule_schedule"
                                    },
                                    {
                                        "args": (v6 /*: any*/),
                                        "kind": "FragmentSpread",
                                        "name": "RunningSchedulesGridHeader_schedule"
                                    },
                                    {
                                        "alias": null,
                                        "args": [
                                            (v1 /*: any*/),
                                            {
                                                "kind": "Variable",
                                                "name": "filter",
                                                "variableName": "shiftsFilter"
                                            },
                                            (v2 /*: any*/),
                                            {
                                                "fields": [
                                                    (v3 /*: any*/),
                                                    (v4 /*: any*/)
                                                ],
                                                "kind": "ObjectValue",
                                                "name": "model"
                                            },
                                            (v5 /*: any*/)
                                        ],
                                        "concreteType": "ScheduleShiftConnection",
                                        "kind": "LinkedField",
                                        "name": "runningSchedule",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ScheduleShiftEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ScheduleShift",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "AssignmentType",
                                                                "kind": "LinkedField",
                                                                "name": "assignmentType",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v0 /*: any*/),
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "title",
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "40bfb78aff1a9d010ff4b3e92e55e026";
export default node;
