import React, { useContext, useState, Suspense, useEffect, useMemo } from 'react';
import { useFragment, graphql, useMutation, useQueryLoader } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { useToasts } from '@sonika-se/ui-kit/hooks';
import { Chip, ModalToSheetSkeleton } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import SchedulableUser from '../../../components/SchedulableUser/SchedulableUser';
import SchedulableAsset from '../../../components/SchedulableAsset/SchedulableAsset';
import RunningSchedulesPageContext from '../../RunningSchedulesPageContext';
import RunningSchedulesChangeAssigneeReasonModal from '../RunningSchedulesChangeAssigneReasonModal/RunningSchedulesChangeAssigneeReasonModal';
import { updateScheduleShiftAssigneesFormQuery } from '../UpdateScheduleShiftAssigneesForm';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        scheduledEntity: {
            marginTop: pxToRem(4),
            marginBottom: pxToRem(8),
        },
    };
});
export const ScheduleShift = (props) => {
    const classes = useStyles();
    const [hover, setHover] = useState(false);
    const [updateResource, setUpdateResource] = useState({});
    const intl = useIntl();
    const { className = '', onClick, checked = false, addHighlightedShift, deleteHighlightedShift, currentUserSchedulePermissions, } = props;
    const { editMode } = useContext(RunningSchedulesPageContext);
    const { addToast } = useToasts();
    const [updateScheduleShiftAssigneesFormQueryRef, loadRunningUpdateScheduleShiftAssigneesFormQuery, disposeRunningUpdateScheduleShiftAssigneesFormQuery,] = useQueryLoader(updateScheduleShiftAssigneesFormQuery);
    const shift = useFragment(graphql `
      fragment ScheduleShift_shift on ScheduleShift {
        id
        assignedEntity {
          ... on SchedulableAsset {
            id
            ...SchedulableAsset_entity
          }
          ... on SchedulableUser {
            id
            ...SchedulableUser_entity
          }
          __typename
        }
        assignedEntityId
        assignmentType {
          id
          title
        }
        end
        start
        isVacant
        status
      }
    `, props.shift);
    const canEditRunning = useMemo(() => currentUserSchedulePermissions.includes('EDIT_RUNNING'), [currentUserSchedulePermissions]);
    useEffect(() => {
        if ((updateResource === null || updateResource === void 0 ? void 0 : updateResource.assignedEntityId) !== undefined) {
            loadRunningUpdateScheduleShiftAssigneesFormQuery({
                where: { id: { in: [shift.id] } },
                potentialValidationsModel: {
                    newAssignedEntityId: updateResource === null || updateResource === void 0 ? void 0 : updateResource.assignedEntityId,
                },
            });
        }
    }, [updateResource]);
    const allowDrop = (event, editable) => {
        event.preventDefault();
        if (!editable) {
            event.dataTransfer.dropEffect = 'none';
        }
        else {
            event.dataTransfer.dropEffect = 'move';
        }
    };
    const [updateScheduleShift, updateScheduleShiftLoading] = useMutation(graphql `
      mutation ScheduleShiftChangeAssigneeReasonModalMutation($model: UpdateScheduleShiftAssigneeRequestInput!) {
        updateScheduleShiftAssignee(model: $model) {
          scheduleShiftHistoryAssigneeChangeEdge {
            node {
              id
              scheduleShift {
                ...ScheduleShift_shift
                ...ScheduleShiftHistory_scheduleShift
              }
              ...ScheduleShiftHistoryAssigneeChange_history
            }
          }
        }
      }
    `);
    const getShiftChip = () => {
        var _a, _b;
        if (shift.status == 'WITHDRAWN') {
            return (<Chip fullWidth style={{
                    cursor: 'pointer',
                }} color={hover ? '#00A15A' : '#D32F2F'} text="Inställt" specialColor="gray-striped" checkboxProps={editMode && canEditRunning
                    ? {
                        checked,
                        onChange: (checked) => {
                            if (updateScheduleShiftLoading) {
                                return;
                            }
                            if (checked) {
                                addHighlightedShift(shift.id, '', shift.assignmentType.id);
                            }
                            else {
                                deleteHighlightedShift(shift.id);
                            }
                        },
                    }
                    : undefined}/>);
        }
        if (shift.isVacant) {
            return (<Chip fullWidth style={{
                    cursor: 'pointer',
                }} color={hover ? '#00A15A' : '#D32F2F'} text={intl.formatMessage({
                    defaultMessage: 'Vakant',
                    id: 'general.misc.schedule_shift.vacant.singular',
                })} specialColor="red-striped" checkboxProps={editMode && canEditRunning
                    ? {
                        checked,
                        onChange: (checked) => {
                            if (updateScheduleShiftLoading) {
                                return;
                            }
                            if (checked) {
                                addHighlightedShift(shift.id, '', shift.assignmentType.id);
                            }
                            else {
                                deleteHighlightedShift(shift.id);
                            }
                        },
                    }
                    : undefined}/>);
        }
        if (((_a = shift.assignedEntity) === null || _a === void 0 ? void 0 : _a.__typename) === 'SchedulableAsset') {
            return (<SchedulableAsset color={hover ? '#00A15A' : null} entity={shift.assignedEntity} checkboxProps={editMode && canEditRunning
                    ? {
                        checked,
                        onChange: (checked) => {
                            if (updateScheduleShiftLoading) {
                                return;
                            }
                            if (checked) {
                                addHighlightedShift(shift.id, shift.assignedEntityId, shift.assignmentType.id);
                            }
                            else {
                                deleteHighlightedShift(shift.id);
                            }
                        },
                    }
                    : undefined}/>);
        }
        if (((_b = shift.assignedEntity) === null || _b === void 0 ? void 0 : _b.__typename) === 'SchedulableUser') {
            return (<SchedulableUser color={hover ? '#00A15A' : null} entity={shift.assignedEntity} checkboxProps={editMode && canEditRunning
                    ? {
                        checked,
                        onChange: (checked) => {
                            if (updateScheduleShiftLoading) {
                                return;
                            }
                            if (checked) {
                                addHighlightedShift(shift.id, shift.assignedEntityId, shift.assignmentType.id);
                            }
                            else {
                                deleteHighlightedShift(shift.id);
                            }
                        },
                    }
                    : undefined}/>);
        }
        return (<Chip fullWidth style={{
                cursor: 'pointer',
            }} color="#000" text="Okänt pass"/>);
    };
    //const shiftChip = useMemo(() => getShiftChip(), [shift, editMode, checked, hover, canEditRunning])
    const shiftChip = getShiftChip();
    return (<div className={className} onClick={onClick} onDragOver={(event) => {
            setHover(true);
            return allowDrop(event, true);
        }} onDragLeave={() => setHover(false)} onDrop={(event) => {
            var _a;
            event.preventDefault();
            event.stopPropagation();
            if (updateScheduleShiftLoading)
                return;
            const assignedEntityId = event.dataTransfer.getData('entityId') === 'null' ? null : event.dataTransfer.getData('entityId');
            const assignmentTypeId = event.dataTransfer.getData('assignmentTypeId') === 'null'
                ? null
                : event.dataTransfer.getData('assignmentTypeId');
            const type = event.dataTransfer.getData('type') === 'null'
                ? null
                : event.dataTransfer.getData('type') === 'asset'
                    ? 'asset'
                    : 'user';
            const { id = '' } = Object.assign({}, shift === null || shift === void 0 ? void 0 : shift.assignedEntity);
            if (assignedEntityId === id) {
                setHover(false);
                return;
            }
            if (assignmentTypeId && assignmentTypeId !== ((_a = shift === null || shift === void 0 ? void 0 : shift.assignmentType) === null || _a === void 0 ? void 0 : _a.id)) {
                addToast(intl.formatMessage({
                    id: 'ScheduleShift.toast.error.wrong_permissions',
                    defaultMessage: 'Du kan inte lägga till den här enheten på det här passet. Enheten har inte rätt behörighet',
                }), {
                    color: 'error',
                });
                setHover(false);
                return;
            }
            if (!shift.assignedEntity) {
                updateScheduleShift({
                    variables: {
                        model: {
                            reasonEventId: null,
                            shiftId: shift.id,
                            newAssignedEntityId: assignedEntityId,
                        },
                    },
                    onCompleted: () => {
                        setHover(false);
                    },
                    onError: () => {
                        setHover(false);
                    },
                });
            }
            else {
                setUpdateResource({ assignedEntityId: assignedEntityId || null, type });
                setHover(false);
            }
        }}>
      <div className={classes.scheduledEntity}>{shiftChip}</div>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {(updateResource === null || updateResource === void 0 ? void 0 : updateResource.assignedEntityId) !== undefined && updateScheduleShiftAssigneesFormQueryRef && (<RunningSchedulesChangeAssigneeReasonModal preloadedQuery={updateScheduleShiftAssigneesFormQueryRef} newEntityId={updateResource.assignedEntityId} assigneeType={updateResource.type} assignmentTypeId={shift.assignmentType.id} onCancel={() => {
                setUpdateResource({});
                disposeRunningUpdateScheduleShiftAssigneesFormQuery();
            }}/>)}
      </Suspense>
    </div>);
};
