import React, { useState } from 'react';
import { string, object } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { H6, Text, Form, Field, MultiSelect } from '@sonika-se/ui-kit/components';
import { graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
const validationRunningSchedulesSelectFromUserForm = object({
    currentlyAssignedEntityId: string().required(),
    assignmentTypeId: string().required(),
});
export const RunningSchedulesSelectFromUserForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const { id, state, onSubmit, scheduleId } = props;
    const [data, setData] = useState({
        currentlyAssignedEntityId: undefined,
        assignmentTypeId: '',
    });
    const { control, formState: { errors }, handleSubmit, } = useForm({
        resolver: yupResolver(validationRunningSchedulesSelectFromUserForm),
    });
    const formData = useLazyLoadQuery(graphql `
      query RunningSchedulesSelectFromUserFormQuery($scheduleId: ID!) {
        schedule(id: $scheduleId) {
          assignmentTypes {
            edges {
              node {
                id
                assignmentType {
                  id
                  title
                }
              }
            }
          }
          schedulableEntities {
            edges {
              node {
                ... on ISchedulableEntity {
                  resourceInScheduleAssignmentTypes {
                    edges {
                      node {
                        id
                        scheduleAssignmentTypeId
                        scheduleAssignmentType {
                          assignmentType {
                            id
                            title
                          }
                        }
                      }
                    }
                  }
                }
                ... on SchedulableUser {
                  id
                  user {
                    id
                    firstname
                    lastname
                  }
                }
                ... on SchedulableAsset {
                  id
                  asset {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    `, {
        scheduleId,
    });
    const selectedEntity = (_b = (_a = formData.schedule.schedulableEntities) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.find(({ node: entity }) => (entity === null || entity === void 0 ? void 0 : entity.id) === data.currentlyAssignedEntityId);
    const selectedAssignmentType = (_d = (_c = formData.schedule.assignmentTypes) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.find(({ node }) => node.assignmentType.id === data.assignmentTypeId);
    const uniqueAssignmentTypes = (_g = (_f = (_e = formData.schedule.assignmentTypes) === null || _e === void 0 ? void 0 : _e.edges) === null || _f === void 0 ? void 0 : _f.map(({ node }) => {
        var _a, _b, _c;
        return (_c = (_b = (_a = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.node) === null || _a === void 0 ? void 0 : _a.resourceInScheduleAssignmentTypes) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.find(({ node: item }) => item.scheduleAssignmentTypeId === node.id);
    })) === null || _g === void 0 ? void 0 : _g.filter((item) => item);
    if (state === 'READ') {
        if (data.currentlyAssignedEntityId === 'vakant')
            return (<H6>
          <FormattedMessage defaultMessage="Vakant" id="general.misc.schedule_shift.vacant.singular"/>,{' '}
          {(_j = (_h = selectedAssignmentType === null || selectedAssignmentType === void 0 ? void 0 : selectedAssignmentType.node) === null || _h === void 0 ? void 0 : _h.assignmentType) === null || _j === void 0 ? void 0 : _j.title}
        </H6>);
        return (<H6>
        {((_l = (_k = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.node) === null || _k === void 0 ? void 0 : _k.asset) === null || _l === void 0 ? void 0 : _l.name) ||
                `${(_o = (_m = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.node) === null || _m === void 0 ? void 0 : _m.user) === null || _o === void 0 ? void 0 : _o.firstname} ${(_q = (_p = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.node) === null || _p === void 0 ? void 0 : _p.user) === null || _q === void 0 ? void 0 : _q.lastname}`}
        , {(_s = (_r = selectedAssignmentType === null || selectedAssignmentType === void 0 ? void 0 : selectedAssignmentType.node) === null || _r === void 0 ? void 0 : _r.assignmentType) === null || _s === void 0 ? void 0 : _s.title}
      </H6>);
    }
    const intl = useIntl();
    return (<>
      <H6 style={{ marginTop: '1rem' }}>Vilken anställd eller enhet vill du byta från?</H6>
      <Text style={{ marginTop: '1rem' }}>Endast de som är kopplade till en roll i schemat går att välja</Text>
      <Form id={id} style={{ marginTop: '1rem' }} columns="1fr 1fr 1fr 1fr 1fr" onSubmit={handleSubmit((formData) => {
            setData(formData);
            onSubmit(formData);
        })}>
        <Field label="Anställd eller enhet" columnSpan={3}>
          <Controller control={control} name="currentlyAssignedEntityId" render={({ field }) => {
            var _a, _b, _c, _d;
            return (<MultiSelect error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.currentlyAssignedEntityId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} inputName="currentlyAssignedEntityId" placeholder="Välj anställd eller enhet" stayOpenOnSelect={false} options={[
                    {
                        label: intl.formatMessage({
                            defaultMessage: 'Vakant',
                            id: 'general.misc.schedule_shift.vacant.singular',
                        }),
                        value: 'vakant',
                    },
                    ...(_d = (_c = formData.schedule.schedulableEntities) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(({ node: entity }) => {
                        var _a, _b, _c;
                        return ({
                            label: ((_a = entity === null || entity === void 0 ? void 0 : entity.asset) === null || _a === void 0 ? void 0 : _a.name) || `${(_b = entity === null || entity === void 0 ? void 0 : entity.user) === null || _b === void 0 ? void 0 : _b.firstname} ${(_c = entity === null || entity === void 0 ? void 0 : entity.user) === null || _c === void 0 ? void 0 : _c.lastname}`,
                            value: entity === null || entity === void 0 ? void 0 : entity.id,
                        });
                    }),
                ]} onChange={(value) => {
                    field.onChange(value ? value[0] : undefined);
                    setData({ currentlyAssignedEntityId: value[0], assignmentTypeId: '' });
                }} defaultValue={field.value}/>);
        }}/>
        </Field>
        <Field columnSpan={2} label={intl.formatMessage({
            id: 'general.objects.assignment_type.name.singular',
            defaultMessage: 'Roll',
        })}>
          <Controller control={control} name="assignmentTypeId" render={({ field }) => {
            var _a, _b, _c, _d;
            return (<MultiSelect error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.assignmentTypeId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} inputName="assignmentTypeId" disabled={data.currentlyAssignedEntityId === undefined} stayOpenOnSelect={false} options={data.currentlyAssignedEntityId !== 'vakant'
                    ? uniqueAssignmentTypes === null || uniqueAssignmentTypes === void 0 ? void 0 : uniqueAssignmentTypes.map(({ node: assignmentType }) => {
                        var _a, _b, _c;
                        return ({
                            label: (_a = assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.scheduleAssignmentType) === null || _a === void 0 ? void 0 : _a.assignmentType.title,
                            value: (_c = (_b = assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.scheduleAssignmentType) === null || _b === void 0 ? void 0 : _b.assignmentType) === null || _c === void 0 ? void 0 : _c.id,
                        });
                    })
                    : (_d = (_c = formData.schedule.assignmentTypes) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(({ node: assignmentType }) => ({
                        label: assignmentType.assignmentType.title,
                        value: assignmentType.assignmentType.id,
                    }))} onChange={(value) => {
                    field.onChange(value ? value[0] : undefined);
                    setData(Object.assign(Object.assign({}, data), { assignmentTypeId: value[0] }));
                }} defaultValue={field.value}/>);
        }}/>
        </Field>
      </Form>
    </>);
};
export default RunningSchedulesSelectFromUserForm;
