/**
 * @generated SignedSource<<f8f81e325593c9e14249cf01ea0bfcb9>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "User",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Avatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "storageId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "queryTransform",
                            "value": "width=68&height=68&mode=crop&scale=both"
                        }
                    ],
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": "url(queryTransform:\"width=68&height=68&mode=crop&scale=both\")"
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserImageLightBox"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "7b5d8c9d025abff230c9dd2ad355fadb";
export default node;
