/**
 * @generated SignedSource<<4c1635a8b44b0bcaf31845aff8162040>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        "schedulableEntities"
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "after"
            },
            {
                "defaultValue": 30,
                "kind": "LocalArgument",
                "name": "first"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "model"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "where"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "first",
                    "cursor": "after",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "first",
                        "cursor": "after"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [],
                "operation": require('./SchedulableResourceFilterList_resourcesQuery.graphql')
            }
        },
        "name": "SchedulableResourceFilterList_resources",
        "selections": [
            {
                "alias": "schedulableEntities",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "model",
                        "variableName": "model"
                    },
                    {
                        "kind": "Variable",
                        "name": "where",
                        "variableName": "where"
                    }
                ],
                "concreteType": "SchedulableEntityConnection",
                "kind": "LinkedField",
                "name": "__SchedulableResourceFilterList_schedulableEntities_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SchedulableEntityEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "User",
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                    (v1 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "firstname",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "lastname",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "SchedulableUser",
                                        "abstractKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Asset",
                                                "kind": "LinkedField",
                                                "name": "asset",
                                                "plural": false,
                                                "selections": [
                                                    (v1 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "name",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "SchedulableAsset",
                                        "abstractKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "a7456ba5356ee8333aed83d4ea17da22";
export default node;
