/**
 * @generated SignedSource<<76ba17ce30813ec409ea818282af48e2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "endDate"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "id"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "startDate"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "startPeriodId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "typeFilter"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [],
                "operation": require('./ScheduleTemplateInformationResource_scheduleTimesQuery.graphql')
            }
        },
        "name": "ScheduleTemplateInformationResource_scheduleTimes",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "fields": [
                            {
                                "kind": "Literal",
                                "name": "dryRun",
                                "value": false
                            },
                            {
                                "kind": "Variable",
                                "name": "endDate",
                                "variableName": "endDate"
                            },
                            {
                                "kind": "Variable",
                                "name": "id",
                                "variableName": "id"
                            },
                            {
                                "kind": "Variable",
                                "name": "startDate",
                                "variableName": "startDate"
                            },
                            {
                                "kind": "Variable",
                                "name": "startPeriodId",
                                "variableName": "startPeriodId"
                            }
                        ],
                        "kind": "ObjectValue",
                        "name": "model"
                    },
                    {
                        "fields": [
                            {
                                "kind": "Variable",
                                "name": "types",
                                "variableName": "typeFilter"
                            }
                        ],
                        "kind": "ObjectValue",
                        "name": "typeFilter"
                    }
                ],
                "concreteType": "ScheduleTimesPayload",
                "kind": "LinkedField",
                "name": "scheduleTemplateTimes",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                        "kind": "LinkedField",
                        "name": "timesByEntityAndAssignmentType",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": [
                                    {
                                        "kind": "Literal",
                                        "name": "unit",
                                        "value": "HOUR"
                                    }
                                ],
                                "concreteType": "TimeSpanUnitValue",
                                "kind": "LinkedField",
                                "name": "assignedDurationWithoutUnpaidBreaks",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "unit",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "value",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": "assignedDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "AssignmentType",
                                "kind": "LinkedField",
                                "name": "assignmentType",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "schedulableEntity",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "color",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "displayName",
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "ISchedulableEntity",
                                        "abstractKey": "__isISchedulableEntity"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "e39f5e1bd720637b91e2ea7822c5756d";
export default node;
