/**
 * @generated SignedSource<<cbcab435e9e8be9638bba0020134fc4f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleShiftNotesTab_scheduleShift",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useScheduleShiftNotes_scheduleShift"
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "schedule",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentUserPermissions",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "ScheduleShift",
    "abstractKey": null
};
node.hash = "9f7a1f149318f5537457fb8352a90281";
export default node;
