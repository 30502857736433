/**
 * @generated SignedSource<<4aa55a67cb33e1a78c6acb3237989447>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "checkedWhere"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SchedulableResourceCheckedNumber_resourcesCheckedQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "checkedWhere",
                            "variableName": "checkedWhere"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "SchedulableResourceCheckedNumber_resourcesChecked"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SchedulableResourceCheckedNumber_resourcesCheckedQuery",
            "selections": [
                {
                    "alias": "checkedNumber",
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "where",
                            "variableName": "checkedWhere"
                        }
                    ],
                    "concreteType": "SchedulableEntityConnection",
                    "kind": "LinkedField",
                    "name": "schedulableEntities",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalCount",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "cd0885ba9a8cf88d1b062317b568f825",
            "id": null,
            "metadata": {},
            "name": "SchedulableResourceCheckedNumber_resourcesCheckedQuery",
            "operationKind": "query",
            "text": "query SchedulableResourceCheckedNumber_resourcesCheckedQuery(\n  $checkedWhere: SchedulableEntityFilterInput\n) {\n  ...SchedulableResourceCheckedNumber_resourcesChecked_309nRW\n}\n\nfragment SchedulableResourceCheckedNumber_resourcesChecked_309nRW on Query {\n  checkedNumber: schedulableEntities(where: $checkedWhere) {\n    totalCount\n  }\n}\n"
        }
    };
})();
node.hash = "e3026e57453c9a76a4b1c5b62444463d";
export default node;
