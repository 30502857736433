import React, { useMemo } from 'react';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { formatISO, startOfDay } from 'date-fns';
import { addYears } from 'date-fns/esm';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router';
import ScheduleAssignmentTypesPanel from '~/panels/ScheduleAssignmentTypesPanel';
import ScheduleGeneralInfoPanel from '~/panels/ScheduleGeneralInfoPanel';
import ScheduleShiftTablePanel from '~/panels/ScheduleShiftTablePanel';
export const ScheduleDetailsPage = () => {
    const { scheduleId } = useParams();
    const fromDate = useMemo(() => startOfDay(Date.now()), []);
    const toDate = useMemo(() => addYears(fromDate, 1), [fromDate]);
    const { schedule } = useLazyLoadQuery(graphql `
      query ScheduleDetailsPageQuery($scheduleId: ID!, $runningScheduleModel: GetRunningScheduleRequestInput!) {
        schedule(id: $scheduleId) {
          ...ScheduleAssignmentTypesPanel_schedule
          ...ScheduleGeneralInfoPanel_schedule
          ...ScheduleShiftTablePanel_schedule @arguments(runningScheduleModel: $runningScheduleModel)
        }
      }
    `, {
        scheduleId,
        runningScheduleModel: {
            fromDate: formatISO(fromDate),
            toDate: formatISO(toDate),
        },
    });
    return (<Flexbox direction="row" gap="large">
      <div style={{ flex: 3 }}>
        <ScheduleShiftTablePanel scheduleRef={schedule}/>
      </div>
      <div style={{ flex: 1 }}>
        <Flexbox direction="column" gap="large">
          <ScheduleGeneralInfoPanel scheduleRef={schedule}/>
          <ScheduleAssignmentTypesPanel scheduleRef={schedule}/>
        </Flexbox>
      </div>
    </Flexbox>);
};
export default ScheduleDetailsPage;
