import React, { useMemo } from 'react';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { graphql, useFragment } from 'react-relay/hooks';
import AssignedEntity from './components/AssignedEntity';
import ScheduleShiftFiles from './components/ScheduleShiftFiles';
import ScheduleShiftInterestForm from './components/ScheduleShiftInterestForm';
import ShiftInformation from './components/ShiftInformation';
import SimultaneousShifts from './components/SimultaneousShifts';
import ScheduleShiftHistoryVacancyInterestList from '~/lists/ScheduleShiftHistoryVacancyInterestList';
const ScheduleShiftInformationTab = (props) => {
    const data = useFragment(graphql `
      fragment ScheduleShiftInformationTab_ScheduleShift on ScheduleShift {
        id
        schedule {
          currentUserPermissions
        }
        ...AssignedEntity_ScheduleShift
        ...ShiftInformation_ScheduleShift
        ...SimultaneousShifts_scheduleShift
        ...ScheduleShiftFiles_scheduleShift
        ...ScheduleShiftInterestForm_scheduleShift
        isVacant
        status
        vacancyInterests {
          ...ScheduleShiftHistoryVacancyInterestList_scheduleShiftHistoryVacancyInterestConnection
        }
      }
    `, props.scheduleShift);
    const hasPermissions = usePermissions();
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || data.schedule.currentUserPermissions.includes('EDIT_RUNNING'), [hasPermissions, data]);
    return (<Flexbox direction="column" gap="large">
      <AssignedEntity assignedEntityRef={data}/>
      {data.isVacant && data.status == 'ACTIVE' && props.canRequestShift && (<ScheduleShiftInterestForm schedulableEntityId={props.schedulableEntityId} scheduleShiftRef={data}/>)}
      {data.isVacant && data.status == 'ACTIVE' && canEditRunning && (<ScheduleShiftHistoryVacancyInterestList scheduleShiftHistoryVacancyInterestConnectionRef={data.vacancyInterests}/>)}
      <ShiftInformation scheduleShiftRef={data}/>
      <ScheduleShiftFiles scheduleShiftRef={data}/>
      <SimultaneousShifts scheduleShiftRef={data}/>
    </Flexbox>);
};
export default ScheduleShiftInformationTab;
