import React, { useState } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { string, object } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Field, Text, Flexbox, MultiSelect } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
export const validationSchema = object({
    assignmentTypeId: string().required(),
    currentlyAssignedEntityId: string().required(),
});
export const RunningSchedulesDeleteShiftsWizardStep2 = (props) => {
    var _a, _b, _c, _d, _e;
    const { data, id, onSubmit, schedule, state } = props;
    const { assignmentTypes, schedulableEntities } = useFragment(graphql `
      fragment RunningSchedulesDeleteShiftsWizardStep2_schedule on Schedule {
        assignmentTypes {
          edges {
            node {
              id
              assignmentType {
                id
                title
              }
            }
          }
        }
        schedulableEntities {
          edges {
            node {
              ... on ISchedulableEntity {
                id
                resourceInScheduleAssignmentTypes {
                  edges {
                    node {
                      id
                      scheduleAssignmentType {
                        id
                        assignmentTypeId
                      }
                    }
                  }
                }
              }
              ... on SchedulableAsset {
                asset {
                  id
                  name
                }
              }
              ... on SchedulableUser {
                user {
                  id
                  firstname
                  lastname
                }
              }
            }
          }
        }
      }
    `, schedule);
    const intl = useIntl();
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: data,
        resolver: yupResolver(validationSchema),
    });
    const [selectedAssignmentTypeId, setSelectedAssignmentTypeId] = useState(data.assignmentTypeId);
    const [selectedEntity, setSelectedEntity] = useState(data.currentlyAssignedEntityId);
    const selectableSchedulableEntities = selectedAssignmentTypeId
        ? schedulableEntities.edges.filter(({ node: schedulableEntity }) => schedulableEntity.resourceInScheduleAssignmentTypes.edges.findIndex(({ node: x }) => x.scheduleAssignmentType.assignmentTypeId === selectedAssignmentTypeId) > -1)
        : [];
    const selectedAssignmentType = (_b = (_a = assignmentTypes.edges.find(({ node: assignmentType }) => assignmentType.assignmentType.id === data.assignmentTypeId)) === null || _a === void 0 ? void 0 : _a.node) === null || _b === void 0 ? void 0 : _b.assignmentType;
    const selectedSchedulableEntity = (_c = selectableSchedulableEntities.find(({ node: schedulableEntity }) => schedulableEntity.id === data.currentlyAssignedEntityId)) === null || _c === void 0 ? void 0 : _c.node;
    return state === 'READ' ? (<Text>
      {selectedAssignmentType === null || selectedAssignmentType === void 0 ? void 0 : selectedAssignmentType.title},
      {data.currentlyAssignedEntityId === 'vakant'
            ? intl.formatMessage({
                defaultMessage: 'Vakant',
                id: 'general.misc.schedule_shift.vacant.singular',
            })
            : selectedSchedulableEntity.asset
                ? selectedSchedulableEntity.asset.name
                : `${(_d = selectedSchedulableEntity.user) === null || _d === void 0 ? void 0 : _d.firstname} ${(_e = selectedSchedulableEntity.user) === null || _e === void 0 ? void 0 : _e.lastname}`}
    </Text>) : (<Form id={id} onSubmit={handleSubmit((newData) => {
            onSubmit(newData);
        })}>
      <div>
        <Text>För roll och anställd eller enhet</Text>
        <Text size="small">Endast de som är kopplade till en rollen går att välja</Text>
      </div>
      <Flexbox gap="medium">
        <Field label="Roll">
          <Controller control={control} name="assignmentTypeId" render={({ field }) => {
            var _a, _b;
            return (<MultiSelect error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.assignmentTypeId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} defaultValue={field.value} inputName="assignmentTypeId" placeholder="Välj roll" onChange={(value) => {
                    field.onChange(value ? value[0] : undefined);
                    setSelectedAssignmentTypeId(value ? value[0] : undefined);
                    setSelectedEntity(undefined);
                }} options={assignmentTypes.edges.map(({ node: assignmentType }) => ({
                    label: assignmentType.assignmentType.title,
                    value: assignmentType.assignmentType.id,
                }))}/>);
        }}/>
        </Field>
        <Field label="Anställd eller enhet">
          <Controller control={control} name="currentlyAssignedEntityId" render={({ field }) => {
            var _a, _b;
            return (<MultiSelect error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.currentlyAssignedEntityId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} defaultValue={field.value} placeholder="Välj resurs" onChange={(value) => {
                    field.onChange(value ? value[0] : undefined);
                    setSelectedEntity(value ? value[0] : undefined);
                }} options={[
                    {
                        label: intl.formatMessage({
                            defaultMessage: 'Vakant',
                            id: 'general.misc.schedule_shift.vacant.singular',
                        }),
                        value: 'vakant',
                    },
                    ...selectableSchedulableEntities.map(({ node: schedulableEntity }) => {
                        var _a, _b;
                        return ({
                            label: schedulableEntity.asset
                                ? schedulableEntity.asset.name
                                : `${(_a = schedulableEntity.user) === null || _a === void 0 ? void 0 : _a.firstname} ${(_b = schedulableEntity.user) === null || _b === void 0 ? void 0 : _b.lastname}`,
                            value: schedulableEntity.id,
                        });
                    }),
                ]}/>);
        }}/>
        </Field>
      </Flexbox>
    </Form>);
};
export default RunningSchedulesDeleteShiftsWizardStep2;
