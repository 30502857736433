import React from 'react';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles((theme) => ({
    placeholderText: {
        fontStyle: 'italic',
        color: theme.colors.foreground.placeholder,
        fontWeight: 'normal',
    },
}));
export const PlaceholderText = (props) => {
    const { children } = props;
    const classes = useStyles();
    return <div className={classes.placeholderText}>{children}</div>;
};
export default PlaceholderText;
