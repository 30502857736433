/**
 * @generated SignedSource<<d5706ef43da0dfa7035bc84a17b4e3bc>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "nameContains"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "nameContains",
            "variableName": "nameContains"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserPermissions",
        "storageKey": null
    }, v4 = [
        (v2 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesEditSidebar_schedulesQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "RunningSchedulesEditSidebar_schedules"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RunningSchedulesEditSidebar_schedulesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "filterModel",
                            "value": {
                                "withCurrentUserPermissions": [
                                    "EDIT_RUNNING"
                                ]
                            }
                        }
                    ],
                    "concreteType": "ScheduleConnection",
                    "kind": "LinkedField",
                    "name": "schedules",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "order",
                                                    "value": {
                                                        "timestampId": "ASC"
                                                    }
                                                }
                                            ],
                                            "concreteType": "ScheduleAssignmentTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "assignmentTypes",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleAssignmentType",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                                    "kind": "LinkedField",
                                                                    "name": "resources",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "totalCount",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v2 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "AssignmentType",
                                                                    "kind": "LinkedField",
                                                                    "name": "assignmentType",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v2 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "title",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": "filteredResources",
                                                                    "args": (v1 /*: any*/),
                                                                    "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                                    "kind": "LinkedField",
                                                                    "name": "resources",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                                                            "kind": "LinkedField",
                                                                            "name": "edges",
                                                                            "plural": true,
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "ScheduleAssignmentTypeResource",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "node",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        (v2 /*: any*/),
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "concreteType": null,
                                                                                            "kind": "LinkedField",
                                                                                            "name": "schedulableEntity",
                                                                                            "plural": false,
                                                                                            "selections": [
                                                                                                {
                                                                                                    "alias": null,
                                                                                                    "args": null,
                                                                                                    "kind": "ScalarField",
                                                                                                    "name": "__typename",
                                                                                                    "storageKey": null
                                                                                                },
                                                                                                {
                                                                                                    "kind": "InlineFragment",
                                                                                                    "selections": [
                                                                                                        (v2 /*: any*/),
                                                                                                        {
                                                                                                            "alias": null,
                                                                                                            "args": null,
                                                                                                            "kind": "ScalarField",
                                                                                                            "name": "color",
                                                                                                            "storageKey": null
                                                                                                        },
                                                                                                        {
                                                                                                            "alias": null,
                                                                                                            "args": null,
                                                                                                            "kind": "ScalarField",
                                                                                                            "name": "displayName",
                                                                                                            "storageKey": null
                                                                                                        },
                                                                                                        {
                                                                                                            "kind": "InlineFragment",
                                                                                                            "selections": [
                                                                                                                {
                                                                                                                    "alias": null,
                                                                                                                    "args": null,
                                                                                                                    "concreteType": "Asset",
                                                                                                                    "kind": "LinkedField",
                                                                                                                    "name": "asset",
                                                                                                                    "plural": false,
                                                                                                                    "selections": (v4 /*: any*/),
                                                                                                                    "storageKey": null
                                                                                                                }
                                                                                                            ],
                                                                                                            "type": "SchedulableAsset",
                                                                                                            "abstractKey": null
                                                                                                        }
                                                                                                    ],
                                                                                                    "type": "ISchedulableEntity",
                                                                                                    "abstractKey": "__isISchedulableEntity"
                                                                                                },
                                                                                                {
                                                                                                    "kind": "InlineFragment",
                                                                                                    "selections": (v4 /*: any*/),
                                                                                                    "type": "Node",
                                                                                                    "abstractKey": "__isNode"
                                                                                                }
                                                                                            ],
                                                                                            "storageKey": null
                                                                                        }
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Schedule",
                                                                    "kind": "LinkedField",
                                                                    "name": "schedule",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v3 /*: any*/),
                                                                        (v2 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": "assignmentTypes(order:{\"timestampId\":\"ASC\"})"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "schedules(filterModel:{\"withCurrentUserPermissions\":[\"EDIT_RUNNING\"]})"
                }
            ]
        },
        "params": {
            "cacheID": "4b2439f037db18ee9453ac2d311fbe11",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesEditSidebar_schedulesQuery",
            "operationKind": "query",
            "text": "query RunningSchedulesEditSidebar_schedulesQuery(\n  $nameContains: String\n) {\n  ...RunningSchedulesEditSidebar_schedules_1WForM\n}\n\nfragment RunningScheduleAssignmentType_scheduleAssignmentType_1WForM on ScheduleAssignmentType {\n  id\n  assignmentType {\n    id\n    title\n  }\n  filteredResources: resources(nameContains: $nameContains) {\n    edges {\n      node {\n        id\n        schedulableEntity {\n          __typename\n          ... on ISchedulableEntity {\n            __isISchedulableEntity: __typename\n            id\n            color\n            displayName\n            ... on SchedulableAsset {\n              asset {\n                id\n              }\n            }\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n  }\n  schedule {\n    currentUserPermissions\n    id\n  }\n}\n\nfragment RunningSchedulesEditSidebar_schedules_1WForM on Query {\n  schedules(filterModel: {withCurrentUserPermissions: [EDIT_RUNNING]}) {\n    edges {\n      node {\n        id\n        currentUserPermissions\n        name\n        assignmentTypes(order: {timestampId: ASC}) {\n          edges {\n            node {\n              resources {\n                totalCount\n              }\n              id\n              assignmentType {\n                id\n                title\n              }\n              ...RunningScheduleAssignmentType_scheduleAssignmentType_1WForM\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "2c4cc202537c4ad21561b228d9c6204b";
export default node;
