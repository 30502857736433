export const mapChangeRequestStatusWithAppearance = (status) => {
    switch (status) {
        case 'REQUESTED':
            return 'primary';
        case 'APPROVED':
            return 'success';
        case 'DENIED':
            return 'danger';
        default:
            return undefined;
    }
};
