/**
 * @generated SignedSource<<5d1c3e8a79e80c39cf7a94bae12244e7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "ensureAuthorized",
            "value": true
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "concreteType": "Avatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
            (v1 /*: any*/),
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "queryTransform",
                        "value": "width=68&height=68&mode=crop&scale=both"
                    }
                ],
                "kind": "ScalarField",
                "name": "url",
                "storageKey": "url(queryTransform:\"width=68&height=68&mode=crop&scale=both\")"
            }
        ],
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "SchedulableUser",
        "kind": "LinkedField",
        "name": "schedulableEntity",
        "plural": false,
        "selections": [
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulable",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "services",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissions",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleConnection",
        "kind": "LinkedField",
        "name": "schedules",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentUserPermissions",
                        "storageKey": null
                    },
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "RoutesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "me",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/),
                        (v8 /*: any*/)
                    ],
                    "storageKey": "currentUser(ensureAuthorized:true)"
                },
                (v9 /*: any*/)
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "RoutesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "me",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v1 /*: any*/)
                    ],
                    "storageKey": "currentUser(ensureAuthorized:true)"
                },
                (v9 /*: any*/)
            ]
        },
        "params": {
            "cacheID": "f6bed1f9b48fe73dfb44ede4d3b12ef6",
            "id": null,
            "metadata": {},
            "name": "RoutesQuery",
            "operationKind": "query",
            "text": "query RoutesQuery {\n  currentUser(ensureAuthorized: true) {\n    me {\n      avatar {\n        id\n        url(queryTransform: \"width=68&height=68&mode=crop&scale=both\")\n      }\n      color\n      firstname\n      lastname\n      schedulableEntity {\n        id\n        schedulable\n      }\n      id\n    }\n    services\n    permissions\n    id\n  }\n  schedules {\n    nodes {\n      currentUserPermissions\n      id\n      name\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "f9d4d010dccef3b5bc7334457f7a83dd";
export default node;
