import React, { useMemo, useState } from 'react';
import { Button, Flexbox, ModalToSheet, ModalToSheetContent, ModalToSheetFooter, ModalToSheetHeader, SortableList, } from '@sonika-se/ui-kit/components';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
export const scheduleSortModalQuery = graphql `
  query ScheduleSortModalQuery {
    schedules(first: 10000, order: { sortOrder: ASC }) {
      nodes {
        archived
        inactivatedFrom
        id
        name
      }
    }
  }
`;
export const ScheduleSortModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { schedules } = usePreloadedQuery(scheduleSortModalQuery, preloadedQuery);
    const [updateSchedules, updateSchedulesLoading] = useMutation(graphql `
      mutation ScheduleSortModal_updateSchedulesMutation($model: [UpdateScheduleRequestInput!]!) {
        updateSchedules(model: $model) {
          scheduleEdge {
            node {
              id
              name
            }
          }
        }
      }
    `);
    const activeSchedules = useMemo(() => schedules.nodes.filter((schedule) => !schedule.archived), [schedules]);
    const archivedSchedules = useMemo(() => schedules.nodes.filter((schedule) => schedule.archived), [schedules]);
    const [sortedSchedules, setSortedSchedules] = useState();
    return (<ModalToSheet onClose={() => onClose(false)}>
      <ModalToSheetHeader closeButtonLabel="Avbryt" onClose={() => onClose(false)}>
        Sortera scheman
      </ModalToSheetHeader>
      <ModalToSheetContent style={{ background: '#F8F9FA' }}>
        <SortableList items={activeSchedules.map((schedule) => ({
            key: schedule.id,
            text: schedule.name,
        }))} onChange={(items) => {
            setSortedSchedules(items);
        }}/>
      </ModalToSheetContent>
      <ModalToSheetFooter>
        <Flexbox align="end" gap="small">
          <Button disabled={!sortedSchedules || updateSchedulesLoading} onClick={() => {
            updateSchedules({
                onCompleted: () => {
                    onClose(true);
                },
                variables: {
                    model: [
                        ...sortedSchedules.map((item, index) => ({
                            scheduleId: item.key,
                            sortOrder: index,
                        })),
                        ...archivedSchedules.map((schedule, index) => ({
                            scheduleId: schedule.id,
                            sortOrder: sortedSchedules.length + index,
                        })),
                    ],
                },
            });
        }}>
            Spara
          </Button>
        </Flexbox>
      </ModalToSheetFooter>
    </ModalToSheet>);
};
export default ScheduleSortModal;
