import React, { Suspense, useMemo, useRef, useState } from 'react';
import { BasicPage, BasicPageHeader, Button, Flexbox, ModalSkeleton, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery, useQueryLoader } from 'react-relay/hooks';
import { addWeeks, formatISO, startOfDay } from 'date-fns';
import { ConnectionHandler } from 'relay-runtime';
import ScheduleGrid from '~/grids/ScheduleGrid';
import ScheduleQueryForm from '~/forms/ScheduleQueryForm';
import { ScheduleCreateModal } from '~/modals/ScheduleCreateModal';
import ScheduleSortModal, { scheduleSortModalQuery } from '~/modals/ScheduleSortModal';
export const ScheduleIndexPage = () => {
    const runningScheduleModel = useMemo(() => {
        const fromDate = startOfDay(new Date());
        const toDate = addWeeks(fromDate, 1);
        return {
            fromDate: formatISO(fromDate),
            toDate: formatISO(toDate),
        };
    }, []);
    const data = useLazyLoadQuery(graphql `
      query ScheduleIndexPageQuery($runningScheduleModel: GetRunningScheduleRequestInput!) {
        ...ScheduleGrid_query @arguments(runningScheduleModel: $runningScheduleModel)
      }
    `, {
        runningScheduleModel,
    }, {
        fetchPolicy: 'network-only',
    });
    const [scheduleCreateModalVisible, setScheduleCreateModalVisible] = useState(false);
    const [scheduleSortModalQueryRef, loadScheduleSortModalQuery, disposeScheduleSortModalQuery] = useQueryLoader(scheduleSortModalQuery);
    const [scheduleGridProps, setScheduleGridProps] = useState(null);
    const scheduleGridRef = useRef(null);
    return (<>
      <BasicPage width="large">
        <BasicPageHeader title="Scheman" action={<Flexbox direction="row" gap="small">
              <Button appearance="outlined" color="primary" onClick={() => {
                loadScheduleSortModalQuery({}, { fetchPolicy: 'network-only' });
            }}>
                Sortera
              </Button>
              <Button onClick={() => {
                setScheduleCreateModalVisible(true);
            }}>
                Lägg till schema
              </Button>
            </Flexbox>}/>
        <Flexbox direction="column" gap="medium">
          <ScheduleQueryForm defaultValues={{
            status: 'ACTIVE',
            archived: 'NOTARCHIVED',
        }} onSubmit={(form) => {
            const now = new Date();
            setScheduleGridProps({
                filter: {
                    currentlyInactivated: form.status == null ? null : form.status === 'DISABLED' ? true : false,
                    archived: form.archived == null || form.archived == 'ALL'
                        ? null
                        : form.archived === 'ARCHIVED'
                            ? true
                            : false,
                    search: form.search,
                },
            });
        }}/>
          <Suspense fallback={<Skeleton height={200} type="table"/>}>
            <ScheduleGrid queryRef={data} ref={scheduleGridRef} {...scheduleGridProps}/>
          </Suspense>
        </Flexbox>
      </BasicPage>
      <Suspense fallback={<ModalSkeleton />}>
        {scheduleCreateModalVisible && (<ScheduleCreateModal connectionsToPrependEdge={[ConnectionHandler.getConnectionID('root', 'ScheduleGrid_query_schedules')]} onClose={() => setScheduleCreateModalVisible(false)} runningScheduleModel={runningScheduleModel}/>)}
        {scheduleSortModalQueryRef && (<ScheduleSortModal onClose={(change) => {
                disposeScheduleSortModalQuery();
                if (change) {
                    scheduleGridRef.current.refetch(scheduleGridProps);
                }
            }} preloadedQuery={scheduleSortModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleIndexPage;
