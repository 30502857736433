import React, { Fragment } from 'react';
import { createUseStyles } from 'react-jss';
import { format, getMonth, isWeekend } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { Flexbox } from '@sonika-se/ui-kit/components';
import ScheduleShiftCard from '~/components/ScheduleShiftCard';
import ScheduleShiftAbsenceCard from '~/components/ScheduleShiftAbsenceCard';
import PlannedAbsenceCard from '~/components/PlannedAbsenceCard';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            backgroundColor: ({ currentMonth, isWeekend }) => isWeekend ? 'rgba(241, 62, 19, 0.06)' : currentMonth ? 'white' : '#F8F9FA',
            padding: pxToRem(12),
            minHeight: '180px',
            height: '88%',
        },
        date: {
            fontSize: pxToRem(14),
            color: ({ isWeekend }) => (isWeekend ? theme.colors.system.stop : theme.colors.foreground.secondary),
            fontWeight: 500,
            paddingBottom: '.25rem',
            textAlign: 'right',
        },
    };
});
export const MyScheduleGridDate = (props) => {
    var _a;
    const { date, fromDate, scheduleShifts } = props;
    const classes = useStyles({ currentMonth: getMonth(date) === getMonth(fromDate), isWeekend: isWeekend(date) });
    return (<div className={classes.root}>
      <div className={classes.date}>{format(date, 'd', { locale })}</div>
      <Flexbox direction="column" gap="xsmall">
        {(_a = scheduleShifts === null || scheduleShifts === void 0 ? void 0 : scheduleShifts.sort((shiftOne, shiftTwo) => shiftOne.node.start - shiftTwo.node.start)) === null || _a === void 0 ? void 0 : _a.map((scheduleShift) => {
            var _a;
            return (<Fragment key={(_a = scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node) === null || _a === void 0 ? void 0 : _a.id}>
              {(scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node.__typename) === 'PlannedAbsence' && (<PlannedAbsenceCard date={date} plannedAbsenceRef={scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node}/>)}
              {(scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node.__typename) === 'ScheduleShift' && (<ScheduleShiftCard date={date} scheduleShiftRef={scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node}/>)}
              {(scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node.__typename) === 'ScheduleShiftAssigneeReplacement' && (<ScheduleShiftAbsenceCard date={date} scheduleShiftAssigneeReplacementRef={scheduleShift === null || scheduleShift === void 0 ? void 0 : scheduleShift.node}/>)}
            </Fragment>);
        })}
      </Flexbox>
    </div>);
};
export default MyScheduleGridDate;
