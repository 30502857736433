/**
 * @generated SignedSource<<cac37b41283b930a12e3cc42e10c3893>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleAssignmentTypeResourceTablePanel_scheduleAssignmentType",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleAssignmentTypeResourceTable_scheduleAssignmentType"
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentType",
            "kind": "LinkedField",
            "name": "assignmentType",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "schedule",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentUserPermissions",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "ScheduleAssignmentType",
    "abstractKey": null
};
node.hash = "51f176303ad21b93057643f64a03cdd1";
export default node;
