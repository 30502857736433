import React, { useMemo } from 'react';
import { Chip } from '@sonika-se/ui-kit/components';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import enums from '~/enums.json';
const scheduleShiftStatusEnumValues = enums.ScheduleShiftStatusEnum;
export const ShiftStatusChip = (props) => {
    const { status } = props;
    const values = useMemo(() => {
        var _a, _b, _c, _d;
        switch (status) {
            case 'ACTIVE':
                return {
                    text: (_b = (_a = scheduleShiftStatusEnumValues[status]) === null || _a === void 0 ? void 0 : _a.description) !== null && _b !== void 0 ? _b : status,
                    color: sonikaTheme.colors.system.go,
                };
            case 'WITHDRAWN':
                return {
                    text: (_d = (_c = scheduleShiftStatusEnumValues[status]) === null || _c === void 0 ? void 0 : _c.description) !== null && _d !== void 0 ? _d : status,
                    specialColor: 'gray-striped',
                };
        }
    }, [status]);
    return <Chip text={values.text} size="xsmall" specialColor={values.specialColor} color={values.color}/>;
};
export default ShiftStatusChip;
