import React, { useState } from 'react';
import { string, object } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { H6, Text, Form, Field, MultiSelect } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
const validationRunningSchedulesSelectToUserForm = object({
    newAssignedEntityId: string().required(),
});
export const RunningSchedulesSelectToUserForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { id, state, onSubmit, scheduleId, assignmentTypeId } = props;
    const [data, setData] = useState({ newAssignedEntityId: '' });
    const { control, formState: { errors }, handleSubmit, } = useForm({
        resolver: yupResolver(validationRunningSchedulesSelectToUserForm),
    });
    const intl = useIntl();
    const formData = useLazyLoadQuery(graphql `
      query RunningSchedulesSelectToUserFormQuery($scheduleId: ID!) {
        schedule(id: $scheduleId) {
          assignmentTypes {
            edges {
              node {
                id
                assignmentType {
                  id
                  title
                }
              }
            }
          }
          schedulableEntities {
            edges {
              node {
                ... on ISchedulableEntity {
                  resourceInScheduleAssignmentTypes {
                    edges {
                      node {
                        id
                        scheduleAssignmentTypeId
                        scheduleAssignmentType {
                          assignmentType {
                            id
                            title
                          }
                        }
                      }
                    }
                  }
                }
                ... on SchedulableUser {
                  id
                  user {
                    id
                    firstname
                    lastname
                  }
                }
                ... on SchedulableAsset {
                  id
                  asset {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    `, {
        scheduleId,
    });
    const selectedAssignmentType = (_b = (_a = formData.schedule.assignmentTypes) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.find(({ node }) => node.assignmentType.id === assignmentTypeId);
    const selectedEntity = (_d = (_c = formData.schedule.schedulableEntities) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.find(({ node: entity }) => (entity === null || entity === void 0 ? void 0 : entity.id) === data.newAssignedEntityId);
    if (state === 'READ') {
        if (data.newAssignedEntityId === 'vakant')
            return (<H6>
          <FormattedMessage defaultMessage="Vakant" id="general.misc.schedule_shift.vacant.singular"/>
        </H6>);
        return (<H6>
        {((_f = (_e = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.node) === null || _e === void 0 ? void 0 : _e.asset) === null || _f === void 0 ? void 0 : _f.name) ||
                `${(_h = (_g = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.node) === null || _g === void 0 ? void 0 : _g.user) === null || _h === void 0 ? void 0 : _h.firstname} ${(_k = (_j = selectedEntity === null || selectedEntity === void 0 ? void 0 : selectedEntity.node) === null || _j === void 0 ? void 0 : _j.user) === null || _k === void 0 ? void 0 : _k.lastname}`}
      </H6>);
    }
    return (<Form id={id} style={{ marginTop: '1rem' }} onSubmit={handleSubmit((formData) => {
            setData(formData);
            onSubmit(formData);
        })}>
      <H6>{`${(_m = (_l = selectedAssignmentType === null || selectedAssignmentType === void 0 ? void 0 : selectedAssignmentType.node) === null || _l === void 0 ? void 0 : _l.assignmentType) === null || _m === void 0 ? void 0 : _m.title} som ersätter`}</H6>
      <Text>Endast de som är kopplade till samma roll i schemat går att välja</Text>
      <Field label={`Välj ${(_p = (_o = selectedAssignmentType === null || selectedAssignmentType === void 0 ? void 0 : selectedAssignmentType.node) === null || _o === void 0 ? void 0 : _o.assignmentType) === null || _p === void 0 ? void 0 : _p.title}`}>
        <Controller control={control} name="newAssignedEntityId" render={({ field }) => {
            var _a, _b, _c, _d, _e;
            return (<MultiSelect error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.newAssignedEntityId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} inputName="newAssignedEntityId" placeholder="Välj anställd eller enhet" stayOpenOnSelect={false} options={[
                    {
                        label: intl.formatMessage({
                            defaultMessage: 'Vakant',
                            id: 'general.misc.schedule_shift.vacant.singular',
                        }),
                        value: 'vakant',
                    },
                    ...(_e = (_d = (_c = formData.schedule.schedulableEntities) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.filter(({ node: entity }) => {
                        var _a, _b;
                        return (_b = (_a = entity === null || entity === void 0 ? void 0 : entity.resourceInScheduleAssignmentTypes) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.find(({ node }) => (selectedAssignmentType === null || selectedAssignmentType === void 0 ? void 0 : selectedAssignmentType.node.id) === (node === null || node === void 0 ? void 0 : node.scheduleAssignmentTypeId));
                    })) === null || _e === void 0 ? void 0 : _e.map(({ node: entity }) => {
                        var _a, _b, _c;
                        return ({
                            label: ((_a = entity === null || entity === void 0 ? void 0 : entity.asset) === null || _a === void 0 ? void 0 : _a.name) || `${(_b = entity === null || entity === void 0 ? void 0 : entity.user) === null || _b === void 0 ? void 0 : _b.firstname} ${(_c = entity === null || entity === void 0 ? void 0 : entity.user) === null || _c === void 0 ? void 0 : _c.lastname}`,
                            value: entity === null || entity === void 0 ? void 0 : entity.id,
                        });
                    }),
                ]} onChange={(value) => {
                    field.onChange(value ? value[0] : undefined);
                    setData({ newAssignedEntityId: value ? value[0] : undefined });
                }} defaultValue={field.value}/>);
        }}/>
      </Field>
    </Form>);
};
export default RunningSchedulesSelectToUserForm;
