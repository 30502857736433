import React from 'react';
import { format, addDays, startOfISOWeek, startOfDay } from 'date-fns';
import { createUseStyles } from 'react-jss';
import { sv as locale } from 'date-fns/locale';
import { FormattedMessage } from 'react-intl';
import { toDate } from '@sonika-se/ui-kit/utils/time';
import { MyScheduleGridWeek } from './components/MyScheduleGridWeek';
import { MyScheduleGridDate } from './components/MyScheduleGridDate';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        grid: {
            display: 'grid',
            gridGap: `${pxToRem(1)} ${pxToRem(1)}`,
            height: 'fit-content',
            gridTemplateColumns: `auto repeat(7, minmax(${pxToRem(200)}, 1fr))`,
            minWidth: 'fit-content',
            width: '100%',
            backgroundColor: '#D5D6D8',
        },
        topLeftCorner: {
            backgroundColor: '#F8F9FA',
            gridColumn: 1,
            gridRow: `1`,
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: '1',
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            fontWeight: 'bold',
            fontSize: pxToRem(14),
            color: theme.colors.foreground.secondary,
        },
        weekDayName: {
            textTransform: 'capitalize',
            gridRow: 1,
            fontWeight: 'bold',
            position: 'sticky',
            backgroundColor: '#F8F9FA',
            top: 0,
            fontSize: pxToRem(14),
            color: theme.colors.foreground.secondary,
            padding: '0.625rem 1rem',
        },
    };
});
export const MyScheduleGrid = (props) => {
    const { fromDate, scheduleItems, weeks, dates } = props;
    const classes = useStyles();
    const weekDayArray = new Array(7).fill(null);
    return (<>
      <div className={classes.grid}>
        {weeks === null || weeks === void 0 ? void 0 : weeks.map((week, weekIndex) => (<MyScheduleGridWeek week={week} key={`week_schedule_number_${week}`} gridRow={2 + weekIndex}/>))}
        {weekDayArray.map((_, index) => {
            const weekDay = format(addDays(startOfISOWeek(new Date()), index), 'EEEE', { locale });
            return (<div className={classes.weekDayName} key={`weekday-header-${weekDay}`}>
              {weekDay.replace('.', '')}
            </div>);
        })}
        {dates === null || dates === void 0 ? void 0 : dates.map((date, index) => {
            var _a;
            const scheduleShiftsOnDate = (_a = scheduleItems === null || scheduleItems === void 0 ? void 0 : scheduleItems.edges) === null || _a === void 0 ? void 0 : _a.filter(({ node }) => {
                var _a, _b, _c;
                return date >= startOfDay(toDate((node === null || node === void 0 ? void 0 : node.start) || ((_a = node === null || node === void 0 ? void 0 : node.scheduleShift) === null || _a === void 0 ? void 0 : _a.start))) &&
                    date < toDate((node === null || node === void 0 ? void 0 : node.end) || ((_b = node === null || node === void 0 ? void 0 : node.scheduleShift) === null || _b === void 0 ? void 0 : _b.end) || (node === null || node === void 0 ? void 0 : node.start) || ((_c = node === null || node === void 0 ? void 0 : node.scheduleShift) === null || _c === void 0 ? void 0 : _c.start));
            });
            return (<div key={`date_in_schedule_${index}`} style={{ background: 'white' }}>
              <MyScheduleGridDate date={date} fromDate={fromDate} scheduleShifts={scheduleShiftsOnDate}/>
            </div>);
        })}
        <div className={classes.topLeftCorner}>
          <FormattedMessage defaultMessage="V." id="general.misc.week.name.short"/>
        </div>
        <div style={{ borderBottom: '1px solid #D5D6D8', width: '100%' }}/>
      </div>
    </>);
};
