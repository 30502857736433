import React, { useContext, useState } from 'react';
import { sv as locale } from 'date-fns/locale';
import { Flexbox, Input, Text } from '@sonika-se/ui-kit/components';
import { eachDayOfInterval, format } from 'date-fns';
import { useDebounce, useViewport } from '@sonika-se/ui-kit/hooks';
import { useIntl } from 'react-intl';
import AbsenceCalendarPageContext from '../../AbsenceCalendarPageContext';
import { useStylesPlannedCalendar } from './AbsenceCalendarGridContent';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
export const AbsenceCalendarGridDates = (props) => {
    const { onFilterChange } = props;
    const { size: pageSize } = useViewport();
    const [search, setSearch] = useState('');
    const delayedSearch = useDebounce(search, 300);
    useDidMountEffect(() => {
        onFilterChange({ search: delayedSearch });
    }, [delayedSearch]);
    const { fromDate, filters, toDate } = useContext(AbsenceCalendarPageContext);
    const [days, setDays] = useState(eachDayOfInterval({ start: fromDate, end: toDate }));
    useDidMountEffect(() => {
        setDays(eachDayOfInterval({ start: fromDate, end: toDate }));
    }, [toDate, fromDate]);
    const calcCellWidth = (window.innerWidth - 281 - (days === null || days === void 0 ? void 0 : days.length)) / (days === null || days === void 0 ? void 0 : days.length);
    const cellWidth = calcCellWidth > 56 ? calcCellWidth : 56;
    const classes = useStylesPlannedCalendar({ numColumns: days === null || days === void 0 ? void 0 : days.length, cellWidth });
    const intl = useIntl();
    if (pageSize === 'small') {
        return (<div style={{ background: 'white', padding: '1rem' }}>
        <Input onChange={(value) => {
                setSearch(value);
            }} placeholder={(filters === null || filters === void 0 ? void 0 : filters.type) === 'USER'
                ? intl.formatMessage({ id: 'general.objects.user.actions.search', defaultMessage: 'Sök användare' })
                : intl.formatMessage({ id: 'general.objects.asset.actions.search', defaultMessage: 'Sök enhet' })}/>
      </div>);
    }
    return (<div className={classes.headerGrid}>
      <div className={[classes.gridCell, classes.topLeftCorner].join(' ')}>
        <Input onChange={(value) => {
            setSearch(value);
        }} style={{ height: '2rem ' }} placeholder={(filters === null || filters === void 0 ? void 0 : filters.type) === 'USER'
            ? intl.formatMessage({ id: 'general.objects.user.actions.search', defaultMessage: 'Sök användare' })
            : intl.formatMessage({ id: 'general.objects.asset.actions.search', defaultMessage: 'Sök enhet' })}/>
      </div>
      {days.map((day) => (<Flexbox key={`absence_day_${day}`} className={[classes.gridCell, classes.gridCellTop].join(' ')} direction="column" gap="xxsmall">
          <Text size="small" bold>
            {format(day, 'd', { locale })}
          </Text>
          <Text size="small">{format(day, 'eee', { locale })}</Text>
        </Flexbox>))}
    </div>);
};
export default AbsenceCalendarGridDates;
