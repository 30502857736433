import React, { useContext, useEffect, useState } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { format, isToday, isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { ScheduleShift } from '../../ScheduleShift/ScheduleShift';
import RunningSchedulesPageContext from '../../../RunningSchedulesPageContext';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        scheduleShiftFirst: {
            marginLeft: 0,
            paddingLeft: pxToRem(8),
        },
        scheduleShiftToday: {
            backgroundColor: '#EEF7FF !important',
        },
        scheduleShiftTime: {
            color: '#484848',
            display: 'block',
            fontSize: pxToRem(10),
            fontWeight: 600,
            marginBottom: pxToRem(6),
        },
        onHoverEmptyCell: {
            '& .show-on-hover': {
                display: 'none',
            },
            '&:hover .show-on-hover': {
                display: 'contents',
            },
        },
    };
});
const findShifts = (shifts, assignmentTypeId, date) => {
    const start = startOfDay(date);
    const end = endOfDay(date);
    const shiftsArray = shifts.filter((shift) => shift.assignmentTypeId === assignmentTypeId && isWithinInterval(new Date(shift.start), { end, start }));
    return shiftsArray.reduce((prev, cur) => {
        prev[`${format(new Date(cur.start), 'HH:mm', { locale })}-${format(new Date(cur.end), 'HH:mm', { locale })}`] = [
            ...(prev[`${format(new Date(cur.start), 'HH:mm', { locale })}-${format(new Date(cur.end), 'HH:mm', { locale })}`] || []),
            cur,
        ];
        return prev;
    }, {});
};
export const RuningSchedulesGridSchedule = (props) => {
    const { schedule, onShiftClick, setSelectedShifts, date, className } = props;
    const { filters, highlightedSchedulableEntities, highlightedShifts } = useContext(RunningSchedulesPageContext);
    const { hideEmptySchedules } = filters;
    const classes = useStyles();
    const [oldHighlightedSchedulableEntities, setOldHighlightedSchedulableEntities] = useState(highlightedSchedulableEntities);
    const data = useFragment(graphql `
      fragment RunningSchedulesGridSchedule_schedule on Schedule
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int" }
        fromDate: { type: "DateTime!" }
        toDate: { type: "DateTime!" }
        where: { type: "ScheduleShiftFilterInput" }
        shiftsFilter: { type: "ScheduleShiftsFilterInput" }
      ) {
        runningSchedule(
          after: $after
          first: $first
          model: { fromDate: $fromDate, toDate: $toDate }
          where: $where
          filter: $shiftsFilter
        ) @connection(key: "RuningSchedulesGridSchedule_runningSchedule", filters: []) {
          edges {
            node {
              id
              assignmentTypeId
              end
              start
              assignmentType {
                id
                title
              }
              assignedEntity {
                ... on SchedulableUser {
                  id
                }
                ... on SchedulableAsset {
                  id
                }
              }
              ...ScheduleShift_shift
            }
          }
        }
        id
        name
        currentUserPermissions
      }
    `, schedule);
    const assignmentTypesDict = data.runningSchedule.edges.reduce((prev, cur) => {
        return Object.assign(Object.assign({}, prev), { [cur.node.assignmentType.id]: cur.node.assignmentType.title });
    }, {});
    const assignmentTypes = Object.keys(assignmentTypesDict)
        .map((key) => {
        return [key, assignmentTypesDict[key]];
    })
        .sort(function (first, second) {
        return 0 - (second[1] > first[1] ? 1 : -1);
    });
    const getSchedulableEntities = (comparingItem) => {
        var _a, _b;
        if (!comparingItem)
            return [];
        return (_b = (_a = data === null || data === void 0 ? void 0 : data.runningSchedule) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.reduce((prev, { node }) => {
            var _a, _b, _c;
            if (((_a = node === null || node === void 0 ? void 0 : node.assignmentType) === null || _a === void 0 ? void 0 : _a.id) === (comparingItem === null || comparingItem === void 0 ? void 0 : comparingItem.assignmentTypeId) &&
                ((_b = node === null || node === void 0 ? void 0 : node.assignedEntity) === null || _b === void 0 ? void 0 : _b.id) === (comparingItem === null || comparingItem === void 0 ? void 0 : comparingItem.schedulableEntityId)) {
                return Object.assign(Object.assign({}, prev), { [node.id]: { hasEntity: (_c = node === null || node === void 0 ? void 0 : node.assignedEntity) === null || _c === void 0 ? void 0 : _c.id, assignmentTypeId: comparingItem === null || comparingItem === void 0 ? void 0 : comparingItem.assignmentTypeId } });
            }
            return prev;
        }, {});
    };
    useEffect(() => {
        if (highlightedSchedulableEntities.length > oldHighlightedSchedulableEntities.length) {
            // Added shift
            const newScheduleEntity = (highlightedSchedulableEntities.filter((x) => !oldHighlightedSchedulableEntities.includes(x)) || [])[0];
            if ((newScheduleEntity === null || newScheduleEntity === void 0 ? void 0 : newScheduleEntity.scheduleId) !== data.id)
                return;
            const checkedShifts = getSchedulableEntities(newScheduleEntity);
            setOldHighlightedSchedulableEntities(highlightedSchedulableEntities);
            setSelectedShifts(Object.assign(Object.assign({}, highlightedShifts), checkedShifts));
        }
        else if (highlightedSchedulableEntities.length < oldHighlightedSchedulableEntities.length) {
            // Removed shift
            const removedScheduleEntity = (oldHighlightedSchedulableEntities.filter((x) => !highlightedSchedulableEntities.includes(x)) || [])[0];
            if ((removedScheduleEntity === null || removedScheduleEntity === void 0 ? void 0 : removedScheduleEntity.scheduleId) !== data.id)
                return;
            const checkedShifts = getSchedulableEntities(removedScheduleEntity);
            setOldHighlightedSchedulableEntities(highlightedSchedulableEntities);
            const newHighlighted = {};
            Object.keys(highlightedShifts).map((id) => {
                if (!checkedShifts[id]) {
                    newHighlighted[id] = highlightedShifts[id];
                }
            });
            setSelectedShifts(newHighlighted);
        }
    }, [highlightedSchedulableEntities]);
    if (assignmentTypes.length === 0 && hideEmptySchedules) {
        return null;
    }
    return (<>
      {assignmentTypes.length === 0 && (<div className={[
                className,
                classes.scheduleShiftFirst,
                classes.onHoverEmptyCell,
                isToday(date) && classes.scheduleShiftToday,
            ].join(' ')}>
          {/* <div className='show-on-hover'>
              <Flexbox direction='column' align='center'>
                <Button icon={faPlus} size='small' onClick={() => {
                  console.log('asdf')
                }}>Lägg till pass</Button>
              </Flexbox>
            </div> */}
        </div>)}
      {assignmentTypes.map((assignmentType, assignmentTypeIndex) => {
            const shifts = findShifts(data.runningSchedule.edges.map((edge) => edge.node), assignmentType[0], date);
            return (<div className={[
                    className,
                    isToday(date) && classes.scheduleShiftToday,
                    assignmentTypeIndex === 0 ? classes.scheduleShiftFirst : '',
                ].join(' ')} key={`${date.toString()}-${data.id}-${assignmentType[0]}`}>
            {Object.keys(shifts).map((time) => {
                    const mappedTimeShifts = shifts[time];
                    return (<React.Fragment key={`${date.toString()}-${data.id}-${assignmentType[0]}+${time}`}>
                  <p className={classes.scheduleShiftTime}>{`${time}`}</p>
                  {mappedTimeShifts.map((shift) => (<ScheduleShift currentUserSchedulePermissions={data.currentUserPermissions.map((x) => x)} key={shift.id} checked={!!highlightedShifts[shift.id]} onClick={() => onShiftClick(shift.id)} shift={shift} addHighlightedShift={(id, hasEntity, assignmentTypeId) => setSelectedShifts(Object.assign(Object.assign({}, highlightedShifts), { [id]: { hasEntity, assignmentTypeId } }))} deleteHighlightedShift={(id) => {
                                delete highlightedShifts[id];
                                setSelectedShifts(highlightedShifts);
                            }}/>))}
                </React.Fragment>);
                })}
          </div>);
        })}
    </>);
};
export default RuningSchedulesGridSchedule;
