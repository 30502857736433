/**
 * @generated SignedSource<<84c8d0ad2ccf878c9e1b659ed989036b>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connectionsToPrependEdge"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "runningScheduleModel"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
        }
    ], v3 = {
        "kind": "Variable",
        "name": "model",
        "variableName": "runningScheduleModel"
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleCreateModal_createScheduleMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateSchedulePayload",
                    "kind": "LinkedField",
                    "name": "createSchedule",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfSchedule",
                            "kind": "LinkedField",
                            "name": "scheduleEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "args": [
                                                {
                                                    "kind": "Variable",
                                                    "name": "runningScheduleModel",
                                                    "variableName": "runningScheduleModel"
                                                }
                                            ],
                                            "kind": "FragmentSpread",
                                            "name": "ScheduleCard_schedule"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleCreateModal_createScheduleMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateSchedulePayload",
                    "kind": "LinkedField",
                    "name": "createSchedule",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfSchedule",
                            "kind": "LinkedField",
                            "name": "scheduleEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "archived",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "inactivatedFrom",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "assignmentTypes",
                                            "plural": false,
                                            "selections": (v2 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": [
                                                (v3 /*: any*/),
                                                {
                                                    "kind": "Literal",
                                                    "name": "where",
                                                    "value": {
                                                        "assignedEntityId": {
                                                            "neq": null
                                                        }
                                                    }
                                                }
                                            ],
                                            "concreteType": "ScheduleShiftConnection",
                                            "kind": "LinkedField",
                                            "name": "runningSchedule",
                                            "plural": false,
                                            "selections": (v2 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": [
                                                (v3 /*: any*/)
                                            ],
                                            "concreteType": "ScheduleShiftChangeRequestConnection",
                                            "kind": "LinkedField",
                                            "name": "shiftChangeRequests",
                                            "plural": false,
                                            "selections": (v2 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": "vacantShifts",
                                            "args": [
                                                (v3 /*: any*/),
                                                {
                                                    "kind": "Literal",
                                                    "name": "where",
                                                    "value": {
                                                        "assignedEntityId": {
                                                            "eq": null
                                                        }
                                                    }
                                                }
                                            ],
                                            "concreteType": "ScheduleShiftConnection",
                                            "kind": "LinkedField",
                                            "name": "runningSchedule",
                                            "plural": false,
                                            "selections": (v2 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": "schedulableAssets",
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "where",
                                                    "value": {
                                                        "type": {
                                                            "eq": "ASSET"
                                                        }
                                                    }
                                                }
                                            ],
                                            "concreteType": "SchedulableEntityConnection",
                                            "kind": "LinkedField",
                                            "name": "schedulableEntities",
                                            "plural": false,
                                            "selections": (v2 /*: any*/),
                                            "storageKey": "schedulableEntities(where:{\"type\":{\"eq\":\"ASSET\"}})"
                                        },
                                        {
                                            "alias": "schedulableUser",
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "where",
                                                    "value": {
                                                        "type": {
                                                            "eq": "USER"
                                                        }
                                                    }
                                                }
                                            ],
                                            "concreteType": "SchedulableEntityConnection",
                                            "kind": "LinkedField",
                                            "name": "schedulableEntities",
                                            "plural": false,
                                            "selections": (v2 /*: any*/),
                                            "storageKey": "schedulableEntities(where:{\"type\":{\"eq\":\"USER\"}})"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "prependEdge",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "scheduleEdge",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connectionsToPrependEdge"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "ba15d851372dba93a724ac0f9627dee0",
            "id": null,
            "metadata": {},
            "name": "ScheduleCreateModal_createScheduleMutation",
            "operationKind": "mutation",
            "text": "mutation ScheduleCreateModal_createScheduleMutation(\n  $model: CreateScheduleRequestInput!\n  $runningScheduleModel: GetRunningScheduleRequestInput!\n) {\n  createSchedule(model: $model) {\n    scheduleEdge {\n      node {\n        ...ScheduleCard_schedule_1gb8Rr\n        id\n      }\n    }\n  }\n}\n\nfragment ScheduleCard_schedule_1gb8Rr on Schedule {\n  archived\n  inactivatedFrom\n  assignmentTypes {\n    totalCount\n  }\n  id\n  name\n  runningSchedule(model: $runningScheduleModel, where: {assignedEntityId: {neq: null}}) {\n    totalCount\n  }\n  shiftChangeRequests(model: $runningScheduleModel) {\n    totalCount\n  }\n  vacantShifts: runningSchedule(model: $runningScheduleModel, where: {assignedEntityId: {eq: null}}) {\n    totalCount\n  }\n  schedulableAssets: schedulableEntities(where: {type: {eq: ASSET}}) {\n    totalCount\n  }\n  schedulableUser: schedulableEntities(where: {type: {eq: USER}}) {\n    totalCount\n  }\n}\n"
        }
    };
})();
node.hash = "bce59eae718be2a93f9b769a60e09356";
export default node;
