/**
 * @generated SignedSource<<18447938eb2cc490cd76bbe74a1eaf04>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleTemplateId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "scheduleTemplateId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "periodLength",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "order",
                "value": {
                    "order": "ASC"
                }
            }
        ],
        "concreteType": "ScheduleTemplatePeriodConnection",
        "kind": "LinkedField",
        "name": "periods",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTemplatePeriod",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": "periods(order:{\"order\":\"ASC\"})"
    }, v5 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v6 = {
        "kind": "InlineFragment",
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
            }
        ],
        "type": "ISchedulableEntity",
        "abstractKey": "__isISchedulableEntity"
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startsOnWeekday",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleTemplateShiftFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleTemplate",
                    "kind": "LinkedField",
                    "name": "scheduleTemplate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Schedule",
                            "kind": "LinkedField",
                            "name": "schedule",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleAssignmentTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "assignmentTypes",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentType",
                                            "kind": "LinkedField",
                                            "name": "nodes",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                    "kind": "LinkedField",
                                                    "name": "resources",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleAssignmentTypeResource",
                                                            "kind": "LinkedField",
                                                            "name": "nodes",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": null,
                                                                    "kind": "LinkedField",
                                                                    "name": "schedulableEntity",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v6 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleTemplateShiftFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleTemplate",
                    "kind": "LinkedField",
                    "name": "scheduleTemplate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Schedule",
                            "kind": "LinkedField",
                            "name": "schedule",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleAssignmentTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "assignmentTypes",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentType",
                                            "kind": "LinkedField",
                                            "name": "nodes",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                    "kind": "LinkedField",
                                                    "name": "resources",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleAssignmentTypeResource",
                                                            "kind": "LinkedField",
                                                            "name": "nodes",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": null,
                                                                    "kind": "LinkedField",
                                                                    "name": "schedulableEntity",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "__typename",
                                                                            "storageKey": null
                                                                        },
                                                                        (v6 /*: any*/),
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                (v2 /*: any*/)
                                                                            ],
                                                                            "type": "Node",
                                                                            "abstractKey": "__isNode"
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v2 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "6f101a2ee919ce038ac4fb27acae2887",
            "id": null,
            "metadata": {},
            "name": "ScheduleTemplateShiftFormQuery",
            "operationKind": "query",
            "text": "query ScheduleTemplateShiftFormQuery(\n  $scheduleTemplateId: ID!\n) {\n  scheduleTemplate(id: $scheduleTemplateId) {\n    id\n    periodLength\n    periods(order: {order: ASC}) {\n      nodes {\n        id\n        name\n      }\n    }\n    schedule {\n      assignmentTypes {\n        nodes {\n          id\n          assignmentType {\n            id\n            title\n          }\n          resources {\n            nodes {\n              schedulableEntity {\n                __typename\n                ... on ISchedulableEntity {\n                  __isISchedulableEntity: __typename\n                  id\n                  displayName\n                }\n                ... on Node {\n                  __isNode: __typename\n                  id\n                }\n              }\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n    startsOnWeekday\n  }\n}\n"
        }
    };
})();
node.hash = "147db7ccd3f58cd130cd1d31150d7626";
export default node;
