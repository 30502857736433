import React, { Suspense, useContext, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import { Flexbox, Divider, IconButton } from '@sonika-se/ui-kit/components';
import { faInfo, faUserClock } from '@fortawesome/pro-regular-svg-icons';
import { faInfo as faInfoSolid } from '@fortawesome/pro-solid-svg-icons';
import { createUseStyles } from 'react-jss';
import MySchedulePageContext from '../../MySchedulePageContext';
import MyScheduleFilter from '../MyScheduleFilter/MyScheduleFilter';
import MonthSelect from '../../../components/MonthSelect/MonthSelect';
import ScheduleSwitch from '../../../components/ScheduleSwitch/ScheduleSwitch';
import ChangeRequestDropdown from '../../../components/ChangeRequestDropdown/ChangeRequestDropdown';
import { mySchedulePageQuery } from '../../MySchedulePage';
export const useStyles = createUseStyles(() => {
    return {
        mobileHeaderContainer: {
            padding: '.75rem 1rem',
        },
    };
});
export const MyScheduleHeaderMobile = (props) => {
    var _a;
    const { date, onDateChange, onToggleInformationDrawer, selectedUserId, updateFilters, preloadedQuery } = props;
    const classes = useStyles();
    const { showInformationDrawer, filters } = useContext(MySchedulePageContext);
    const intl = useIntl();
    const data = usePreloadedQuery(mySchedulePageQuery, preloadedQuery);
    const changeRequestVariables = useMemo(() => {
        const { assignmentTypeIds, onlyVacant, resourceIds, scheduleIds } = filters;
        return {
            assignmentTypesFilter: assignmentTypeIds,
            entitiesFilter: {
                assignedResources: resourceIds,
                vacant: onlyVacant,
            },
            fromDate: date[0],
            schedulesFilter: scheduleIds,
            toDate: date[1],
            whereChangeRequest: {
                requestedByUserId: { in: [selectedUserId] },
            },
        };
    }, [filters, date, selectedUserId]);
    return (<>
      <Flexbox align="center" gap="xsmall" justify="space" className={classes.mobileHeaderContainer}>
        <ScheduleSwitch label={intl.formatMessage({ defaultMessage: 'Mitt schema', id: 'general.objects.my_schedule.name.singular' })} userRef={data === null || data === void 0 ? void 0 : data.user}/>
        <Flexbox gap="xsmall">
          <Suspense fallback={<IconButton size="small" appearance="text" color="danger" disabled icon={faUserClock}/>}>
            <ChangeRequestDropdown variables={changeRequestVariables} userId={selectedUserId} iconButtonProps={{
            size: 'small',
            appearance: 'text',
            color: 'danger',
            icon: faUserClock,
        }}/>
          </Suspense>
          <MyScheduleFilter queryRef={(_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.schedulableEntity} updateFilters={updateFilters}/>
          <IconButton appearance={showInformationDrawer ? 'filled' : 'text'} icon={showInformationDrawer ? faInfoSolid : faInfo} size="small" onClick={() => {
            onToggleInformationDrawer();
        }}/>
        </Flexbox>
      </Flexbox>
      <Divider />
      <MonthSelect date={date[0]} onDateChange={onDateChange}/>
    </>);
};
