import React from 'react';
import { Route, Routes } from 'react-router';
import ScheduleLayout from '~/layouts/ScheduleLayout';
import ScheduleDetailsPage from '~/pages/ScheduleDetailsPage';
import ScheduleIndexPage from '~/pages/ScheduleIndexPage';
import SchedulePermissionsPage from '~/pages/SchedulePermissionsPage';
import ScheduleResourcesPage from '~/pages/ScheduleResourcesPage';
import ScheduleTemplateDetailsPage from '~/pages/ScheduleTemplateDetailsPage/ScheduleTemplateDetailsPage';
import ScheduleTemplatesPage from '~/pages/ScheduleTemplatesPage';
export const ScheduleRoutes = () => (<Routes>
    <Route path="" element={<ScheduleIndexPage />}/>
    <Route element={<ScheduleLayout />} path=":scheduleId/*">
      <Route path="" element={<ScheduleDetailsPage />}/>
      <Route path="permissions" element={<SchedulePermissionsPage />}/>
      <Route path="resources" element={<ScheduleResourcesPage />}/>
      <Route path="templates" element={<ScheduleTemplatesPage />}/>
    </Route>
    <Route path=":scheduleId/templates/:scheduleTemplateId" element={<ScheduleTemplateDetailsPage />}/>
  </Routes>);
export default ScheduleRoutes;
