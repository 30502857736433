import React from 'react';
import { Avatar, Flexbox, Text } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import { EditableListItem } from '~/components/EditableList';
export const SchedulableEntityListItem = (props) => {
    const { filter, scheduleAssignmentTypeResourceRef } = props;
    const [removeResource, removeResourceLoading] = useMutation(graphql `
        mutation SchedulableEntityListItem_removeResourceFromScheduleAssignmentTypeMutation(
          $model: RemoveResourceFromScheduleAssignmentTypeRequestInput!
          $filter: String
        ) {
          removeResourceFromScheduleAssignmentType(model: $model) {
            resourceEdge {
              node {
                id
                scheduleAssignmentType {
                  ...ScheduleAssignmentTypeResourcesUpdateModal_scheduleAssignmentType
                  ...RunningScheduleAssignmentType_scheduleAssignmentType @arguments(nameContains: $filter)
                }
              }
            }
            resourceEdge2: resourceEdge {
              node {
                scheduleAssignmentType {
                  ...ScheduleAssignmentTypeResourceTable_scheduleAssignmentType
                }
              }
            }
          }
        }
      `);
    const data = useFragment(graphql `
      fragment SchedulableEntityListItem_scheduleAssignmentTypeResource on ScheduleAssignmentTypeResource {
        id
        scheduleAssignmentType {
          assignmentTypeId
          scheduleId
        }
        schedulableEntity {
          ... on SchedulableAsset {
            color
            displayName
            id
          }
          ... on SchedulableUser {
            color
            displayName
            id
          }
        }
      }
    `, scheduleAssignmentTypeResourceRef);
    return (<EditableListItem disabled={removeResourceLoading} onDelete={() => {
            var _a;
            removeResource({
                variables: {
                    model: {
                        assignmentTypeId: (_a = data.scheduleAssignmentType) === null || _a === void 0 ? void 0 : _a.assignmentTypeId,
                        schedulableEntityId: data.schedulableEntity.id,
                        scheduleId: data.scheduleAssignmentType.scheduleId,
                    },
                    filter,
                },
            });
        }}>
      <Flexbox align="center" gap="xsmall">
        <Avatar text={data.schedulableEntity.displayName} size="small" color={data.schedulableEntity.color || undefined}/>
        <Text style={{ flex: 1 }}>{data.schedulableEntity.displayName}</Text>
      </Flexbox>
    </EditableListItem>);
};
export default SchedulableEntityListItem;
