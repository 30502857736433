import React, { useState, Suspense, useContext, useEffect } from 'react';
import { graphql } from 'react-relay';
import { useQueryLoader } from 'react-relay/hooks';
import { startOfMonth, endOfMonth, getDay, getDaysInMonth, subDays, getWeeksInMonth, addDays, formatISO, } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { AppContext, Skeleton } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import { ScheduleLayout, ScheduleLayoutHeader, ScheduleLayoutMain } from '../components/ScheduleLayout/ScheduleLayout';
import { MySchedulePageContextProvider, defaultScheduleTemplateDetailsPagevalues } from './MySchedulePageContext';
import { MyScheduleHeader } from './components/MyScheduleHeader/MyScheduleHeader';
import { MyScheduleHeaderMobile } from './components/MyScheduleHeaderMobile/MyScheduleHeaderMobile';
import MyScheduleInformationDrawer from './components/MyScheduleInformationDrawer/MyScheduleInformationDrawer';
import { MyScheduleContent } from './components/MyScheduleContent/MyScheduleContent';
import { createChangeRequestFilters } from '../utils/filters';
import { useSearchParams } from 'react-router-dom';
export const mySchedulePageQuery = graphql `
  query MySchedulePageQuery($myScheduleModel: GetRunningScheduleRequestInput!, $userId: ID!) {
    user(id: $userId) {
      id
      schedulableEntity {
        id
        ... on SchedulableUser {
          ...MyScheduleContent_shifts @arguments(model: $myScheduleModel)
          ...MyScheduleFilter_schedulableUser
        }
      }
      ...ScheduleSwitch_user
    }
    ...MyScheduleHeader_query
  }
`;
export const MySchedulePage = () => {
    const { currentUser } = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedUserId, setSelectedUserId] = useState(searchParams.get('selectedUserId') || (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id));
    const [context, setContext] = useState(defaultScheduleTemplateDetailsPagevalues);
    const [refetchGrid, setRefetchGrid] = useState();
    const [dateInterval, setDateInterval] = useState([startOfMonth(new Date()), endOfMonth(new Date())]);
    const now = new Date();
    const monthStartWeekday = getDay(startOfMonth(now)) || 7;
    const daysInMonth = getDaysInMonth(now);
    const startDate = subDays(startOfMonth(now), monthStartWeekday - 1);
    const nrOfWeeks = getWeeksInMonth(now, { locale });
    const endDate = addDays(endOfMonth(now), nrOfWeeks * 7 - daysInMonth - monthStartWeekday + 1);
    const filters = createChangeRequestFilters({
        userId: selectedUserId,
        toDate: endDate,
        fromDate: startDate,
    });
    const { size: pageSize } = useViewport();
    const updateFilters = (filters, name) => {
        setContext(Object.assign(Object.assign({}, context), { filters: Object.assign(Object.assign({}, context.filters), { [name]: filters }) }));
    };
    const [mySchedulePageQueryRef, loadMySchedulePageQuery, disposeMySchedulePageQuery] = useQueryLoader(mySchedulePageQuery);
    useEffect(() => {
        loadMySchedulePageQuery(Object.assign({ myScheduleModel: { fromDate: formatISO(startDate), toDate: formatISO(endDate) }, userId: selectedUserId }, filters));
        setSearchParams(Object.assign(Object.assign({}, searchParams), { selectedUserId }));
        return () => disposeMySchedulePageQuery();
    }, [selectedUserId]);
    if (!mySchedulePageQueryRef)
        return null;
    const getScheduleHeader = () => {
        switch (pageSize) {
            case 'small':
                return (<MyScheduleHeaderMobile onDateChange={(date) => setDateInterval([startOfMonth(date), endOfMonth(date)])} date={dateInterval} updateFilters={updateFilters} preloadedQuery={mySchedulePageQueryRef} selectedUserId={selectedUserId} setRefetchGrid={setRefetchGrid} onToggleInformationDrawer={() => {
                        setContext(Object.assign(Object.assign({}, context), { showInformationDrawer: !context.showInformationDrawer }));
                    }}/>);
            default:
                return (<MyScheduleHeader onDateChange={(date) => setDateInterval([startOfMonth(date), endOfMonth(date)])} date={dateInterval} updateFilters={updateFilters} preloadedQuery={mySchedulePageQueryRef} selectedUserId={selectedUserId} setNewUser={(userId) => setSelectedUserId(userId || (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id))} setRefetchGrid={setRefetchGrid} onToggleInformationDrawer={() => {
                        setContext(Object.assign(Object.assign({}, context), { showInformationDrawer: !context.showInformationDrawer }));
                    }}/>);
        }
    };
    return (<>
      <MySchedulePageContextProvider value={context}>
        <ScheduleLayout>
          <ScheduleLayoutHeader>{getScheduleHeader()}</ScheduleLayoutHeader>
          <Suspense fallback={<Skeleton type="page" height="100%"/>}>
            <ScheduleLayoutMain>
              <MyScheduleContent fromDate={dateInterval[0]} toDate={dateInterval[1]} preloadedQuery={mySchedulePageQueryRef} refetchGrid={refetchGrid} style={{
            margin: '1rem .5rem',
        }}/>
            </ScheduleLayoutMain>
          </Suspense>
        </ScheduleLayout>
      </MySchedulePageContextProvider>
      {context.showInformationDrawer && (<MyScheduleInformationDrawer onClose={() => {
                setContext(Object.assign(Object.assign({}, context), { showInformationDrawer: false }));
            }} defaultFromDate={dateInterval[0]} defaultToDate={dateInterval[1]} open={context.showInformationDrawer} meId={selectedUserId}/>)}
    </>);
};
export default MySchedulePage;
