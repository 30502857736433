import React, { Suspense, useMemo } from 'react';
import { Outlet, useParams } from 'react-router';
import { BasicPage, Flexbox, H1, Menu, MenuItem, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
export const ScheduleLayout = () => {
    const { scheduleId } = useParams();
    const { schedule } = useLazyLoadQuery(graphql `
      query ScheduleLayoutQuery($scheduleId: ID!) {
        schedule(id: $scheduleId) {
          currentUserPermissions
          name
        }
      }
    `, {
        scheduleId,
    });
    const canEditSchedules = usePermissions()(['EDIT_SCHEDULES']);
    const canEditTemplates = useMemo(() => canEditSchedules || schedule.currentUserPermissions.includes('EDIT_TEMPLATES'), [canEditSchedules, schedule]);
    const canEditPermissions = useMemo(() => canEditSchedules || schedule.currentUserPermissions.includes('EDIT_PERMISSIONS'), [canEditSchedules, schedule]);
    const canEditAssignmentTypes = useMemo(() => canEditSchedules || schedule.currentUserPermissions.includes('EDIT_ASSIGNMENT_TYPES'), [canEditSchedules, schedule]);
    return (<BasicPage width="large">
      <Flexbox direction="row" gap="medium" style={{ paddingTop: '3rem' }}>
        <H1>{schedule.name}</H1>
      </Flexbox>
      <Flexbox direction="column" gap="xlarge">
        <Menu>
          <MenuItem index path="" text="Information"/>
          {canEditTemplates && <MenuItem path="templates" text="Mallar"/>}
          {canEditAssignmentTypes && <MenuItem path="resources" text="Användare och enheter"/>}
          {canEditPermissions && <MenuItem path="permissions" text="Rättigheter"/>}
        </Menu>
        <Suspense fallback={<Skeleton height="40vh" type="table"/>}>
          <Outlet />
        </Suspense>
      </Flexbox>
    </BasicPage>);
};
export default ScheduleLayout;
