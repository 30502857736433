import React, { useState } from 'react';
import { date, object, ref } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { H6, Form, Field, DateInput } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
const validationPublishScheduleWizardDate = object({
    startDate: date().required(),
    endDate: date()
        .min(ref('startDate'), ({ min }) => `Datum måste vara efter ${min}`)
        .required(),
});
export const PublishScheduleWizardDate = (props) => {
    var _a, _b, _c, _d;
    const { id, state, onSubmit } = props;
    const intl = useIntl();
    const [data, setData] = useState({
        startDate: undefined,
        endDate: undefined,
    });
    const { control, formState: { errors }, handleSubmit, } = useForm({
        resolver: yupResolver(validationPublishScheduleWizardDate),
    });
    if (state === 'READ') {
        return (<H6>
        {`${format(data.startDate, 'yyyy MM dd', { locale }).replace('.', '')} - ${format(data.endDate, 'yyyy MM dd', {
                locale,
            }).replace('.', '')}`}
      </H6>);
    }
    return (<div>
      <H6 style={{ marginTop: '1rem' }}>
        <FormattedMessage defaultMessage="Välj datum för publicering av schemat" id="ScheduleTemplateDetailsPage.PublishScheduleWizardDate.header"/>
      </H6>
      <Form id={id} columns="1fr 1fr" style={{ marginTop: '1rem' }} onSubmit={handleSubmit((formData) => {
            setData(formData);
            onSubmit(formData);
        })}>
        <Field label={intl.formatMessage({
            defaultMessage: 'Från datum',
            id: 'general.objects.schedule.fields.from_date.label',
        })} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.startDate) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()}>
          <Controller control={control} name="startDate" render={({ field }) => (<DateInput onChange={field.onChange} placeholder={intl.formatMessage({
                defaultMessage: 'Välj datum',
                id: 'general.objects.schedule.fields.from_date.placeholder',
            })} defaultValue={data.startDate}/>)}/>
        </Field>
        <Field label={intl.formatMessage({
            defaultMessage: 'Till datum',
            id: 'general.objects.schedule.fields.to_date.label',
        })} error={(_d = (_c = errors === null || errors === void 0 ? void 0 : errors.endDate) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString()}>
          <Controller control={control} name="endDate" render={({ field }) => (<DateInput onChange={field.onChange} placeholder={intl.formatMessage({
                defaultMessage: 'Välj datum',
                id: 'general.objects.schedule.fields.to_date.placeholder',
            })} defaultValue={data.endDate}/>)}/>
        </Field>
      </Form>
    </div>);
};
export default PublishScheduleWizardDate;
