/**
 * @generated SignedSource<<5184d699c725ba48a2ceef5449d62ed9>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RunningSchedulesChangeResourceForm_query",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RunningSchedulesSelectScheduleForm_query"
        },
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "filterModel",
                    "value": {
                        "withCurrentUserPermissions": [
                            "EDIT_RUNNING"
                        ]
                    }
                }
            ],
            "concreteType": "ScheduleConnection",
            "kind": "LinkedField",
            "name": "schedules",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "schedules(filterModel:{\"withCurrentUserPermissions\":[\"EDIT_RUNNING\"]})"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "a3b1c3b9b522604a7112e88e66576d1d";
export default node;
