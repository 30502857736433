import React from 'react';
import { useRoutes } from 'react-router-dom';
import PrintRunningSchedulePage from './PrintRunningSchedulePage';
export const PrintPages = () => {
    const routes = useRoutes([
        {
            path: '/running-schedules',
            element: <PrintRunningSchedulePage />,
        },
    ]);
    return routes;
};
export default PrintPages;
