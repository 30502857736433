import React, { useEffect } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { Button, Divider, Flexbox, Placeholder } from '@sonika-se/ui-kit/components';
import UserPickerListItem from './UserPickerListItem';
export const UserPickerList = (props) => {
    const { onSelect, queryRef, search } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment UserPickerList_query on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 50 }
        after: { type: "String" }
        where: { type: "UserFilterInput" }
      )
      @refetchable(queryName: "UserPickerList_queryQuery") {
        users(after: $after, first: $first, where: $where) @connection(key: "UserPickerList_query_users") {
          edges {
            node {
              id
              ...UserPickerListItem_user
            }
          }
        }
      }
    `, queryRef);
    useEffect(() => {
        if (search !== undefined) {
            refetch({
                where: {
                    or: [
                        {
                            firstname: { contains: search },
                        },
                        {
                            lastname: { contains: search },
                        },
                    ],
                },
            });
        }
    }, [search]);
    return data.users.edges.length > 0 ? (<Flexbox direction="column" gap="small">
      {data.users.edges.map(({ node: user }) => (<UserPickerListItem key={user.id} onSelect={onSelect} userRef={user}/>))}
      {hasNext && (<>
          <Divider />
          <Button disabled={isLoadingNext} display="block" onClick={() => {
                loadNext(50);
            }}>
            Ladda fler
          </Button>
        </>)}
    </Flexbox>) : (<Placeholder text="Hittade inga användare" height="full"/>);
};
export default UserPickerList;
