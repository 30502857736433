import React from 'react';
// Sonika-UI
import { Flexbox } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
// Components
import CollapseSection from '../CollapseSection';
const DetailedPageContent = (props) => {
    const { children, leftColumn, leftColumnPercentageWidth = 35 } = props;
    const { size: pageSize } = useViewport();
    const rightColumnPercentageWidth = 100 - leftColumnPercentageWidth;
    const LeftContainer = pageSize === 'small' ? (<div style={{ width: '100%', marginTop: '.5rem' }}>
        <CollapseSection>{leftColumn}</CollapseSection>
      </div>) : (<div style={{ width: leftColumnPercentageWidth + '%', marginTop: '.5rem' }}>{leftColumn}</div>);
    return (<Flexbox justify="start" gap="xlarge" direction={pageSize === 'small' ? 'column' : 'row'}>
      {LeftContainer}
      <div style={{
            width: pageSize === 'small' ? '100%' : rightColumnPercentageWidth + '%',
            marginTop: pageSize === 'small' ? '1.5rem' : '1rem',
        }}>
        {children}
      </div>
    </Flexbox>);
};
export default DetailedPageContent;
