import React, { Suspense, useContext, useState } from 'react';
import { faAngleUp, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { useFragment, graphql, useQueryLoader } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { formatISO } from 'date-fns';
import { Divider, Text, Flexbox, Avatar, IconButton, ModalToSheetSkeleton, Skeleton, } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatDuration } from '@sonika-se/ui-kit/utils/time';
import RunningSchedulesPageContext from '../../../RunningSchedulesPageContext';
import UserAbsenceModal, { userAbsenceModalQuery } from '~/components/UserAbsenceModal/UserAbsenceModal';
import MyScheduleInformationDrawerContent from '~/pages/SchedulePages/MySchedule/components/MyScheduleInformationDrawer/MyScheduleInformationDrawerContent';
const useStyles = createUseStyles((theme) => ({
    tableRow: {
        height: theme.helpers.pxToRem(40),
    },
    tableHeader: {
        color: theme.colors.foreground.tertiary,
    },
    tableCell: {
        width: '5rem',
        textAlign: 'center',
        '&:first-child': {
            textAlign: 'left',
        },
        '&:last-child': {
            textAlign: 'right',
            paddingRight: '.5rem',
        },
    },
    tableRowSmall: {
        paddingLeft: '2rem',
        whiteSpace: 'nowrap',
        height: theme.helpers.pxToRem(24),
    },
}));
export const RunningSchedulesInformationTableRow = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { scheduleTime, className, selectedAbsenceFilters, date, update } = props;
    const { informationDrawer, filters } = useContext(RunningSchedulesPageContext);
    const [userAbsenceModalQueryRef, loadUserAbsenceModalQuery, disposeUserAbsenceModalQuery] = useQueryLoader(userAbsenceModalQuery);
    const { tab, type } = informationDrawer;
    const entity = useFragment(graphql `
      fragment RunningSchedulesInformationTableRow_scheduleTime on ScheduleTimeByEntityAndAssignmentType {
        absenceDurationWithoutUnpaidBreaks(unit: HOUR) {
          unit
          value
        }
        absencesByReason {
          absenceDurationWithoutUnpaidBreaks(unit: HOUR) {
            unit
            value
          }
          reasonEvent {
            id
            name
          }
        }
        assignedDurationWithoutUnpaidBreaks(unit: HOUR) {
          unit
          value
        }
        schedulableEntity {
          ... on ISchedulableEntity {
            id
            color
            displayName
          }
          ... on SchedulableUser {
            userId
          }
        }
      }
    `, scheduleTime);
    const intl = useIntl();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const absences = (_a = entity === null || entity === void 0 ? void 0 : entity.absencesByReason) === null || _a === void 0 ? void 0 : _a.reduce((newItem, item) => {
        if (!(selectedAbsenceFilters === null || selectedAbsenceFilters === void 0 ? void 0 : selectedAbsenceFilters.length)) {
            return Object.assign(Object.assign({}, newItem), { [item.reasonEvent.id]: {
                    name: item.reasonEvent.name,
                    total: (newItem[item.reasonEvent.id] || 0) + (item.absenceDurationWithoutUnpaidBreaks.value || 0),
                }, total: newItem.total + (item.absenceDurationWithoutUnpaidBreaks.value || 0) });
        }
        else {
            if (!!selectedAbsenceFilters.find((absenceFilter) => absenceFilter === item.reasonEvent.id)) {
                return Object.assign(Object.assign({}, newItem), { [item.reasonEvent.id]: {
                        name: item.reasonEvent.name,
                        total: (newItem[item.reasonEvent.id] || 0) + (item.absenceDurationWithoutUnpaidBreaks.value || 0),
                    }, total: newItem.total + (item.absenceDurationWithoutUnpaidBreaks.value || 0) });
            }
        }
        return newItem;
    }, {
        total: 0,
    });
    const hasExpectedAbsenceReasons = !(selectedAbsenceFilters === null || selectedAbsenceFilters === void 0 ? void 0 : selectedAbsenceFilters.length) || tab !== 'absences'
        ? true
        : !!((_b = selectedAbsenceFilters === null || selectedAbsenceFilters === void 0 ? void 0 : selectedAbsenceFilters.filter((filter) => {
            return !!entity.absencesByReason.find((item) => item.reasonEvent.id === filter);
        })) === null || _b === void 0 ? void 0 : _b.length);
    const name = ((_c = entity === null || entity === void 0 ? void 0 : entity.schedulableEntity) === null || _c === void 0 ? void 0 : _c.displayName) ||
        intl.formatMessage({
            defaultMessage: 'Vakant',
            id: 'general.misc.schedule_shift.vacant.singular',
        });
    const color = (_d = entity === null || entity === void 0 ? void 0 : entity.schedulableEntity) === null || _d === void 0 ? void 0 : _d.color;
    if ((absences.total === 0 && tab === 'absences') || !hasExpectedAbsenceReasons)
        return null;
    return (<div key={(_e = entity.schedulableEntity) === null || _e === void 0 ? void 0 : _e.id} className={className}>
      <Flexbox justify="space" className={classes.tableRow} align="center">
        <Flexbox gap="xsmall" align="center" onClick={() => {
            if (!entity.schedulableEntity.userId || tab !== 'absences')
                return;
            loadUserAbsenceModalQuery({
                id: entity.schedulableEntity.userId,
                myScheduleModel: {
                    fromDate: formatISO(date.fromDate),
                    toDate: formatISO(date.toDate),
                },
            });
        }}>
          <IconButton icon={open ? faAngleUp : faAngleRight} appearance="text" color="default" size="small" onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setOpen(!open);
        }}/>
          <Avatar size="xsmall" text={name} color={(entity === null || entity === void 0 ? void 0 : entity.schedulableEntity) ? color || '#D5D6D8' : '#fd544f'}/>
          <span>{name}</span>
        </Flexbox>
        <Flexbox>
          <Text className={classes.tableCell}>
            {formatDuration({
            hours: tab === 'absences' ? absences.total : entity.assignedDurationWithoutUnpaidBreaks.value,
        })}
          </Text>
        </Flexbox>
      </Flexbox>
      {open && tab === 'all' && entity.schedulableEntity.userId && (<div style={{ paddingBottom: '0.5rem', paddingLeft: '3rem', paddingRight: '0.5rem' }}>
          <Suspense fallback={<Skeleton height="10vh" type="table"/>}>
            <MyScheduleInformationDrawerContent date={date} meId={entity.schedulableEntity.userId} open={true} update={update} filters={filters}/>
          </Suspense>
        </div>)}
      {open && !(tab === 'all' && entity.schedulableEntity.userId) && (<Flexbox direction="column" style={{ paddingLeft: '3rem' }}>
          <Flexbox direction="column">
            <Flexbox justify="space" align="center" className={classes.tableRow}>
              <Text className={classes.tableCell}>
                {type === 'USER' ? (<FormattedMessage defaultMessage="Frånvaro" id="ScheduleInformation.absence"/>) : (<FormattedMessage defaultMessage="Avvikelser" id="ScheduleInformation.changes"/>)}
              </Text>
              <Text className={classes.tableCell}>{formatDuration({ hours: absences.total })}</Text>
            </Flexbox>
            {(_f = Object.keys(absences)) === null || _f === void 0 ? void 0 : _f.map((key) => {
                if (key === 'total')
                    return '';
                const { total, name } = absences[key];
                return (<Flexbox justify="space" align="center" className={classes.tableRowSmall} key={`ScheduleInformation.changes_${key}`}>
                  <Text size="small" className={classes.tableCell}>
                    {name}
                  </Text>
                  <Text size="small" className={classes.tableCell}>
                    {formatDuration({ hours: total })}
                  </Text>
                </Flexbox>);
            })}
            <Divider style={{ marginTop: '.75rem' }}/>
            <Flexbox justify="space" align="center" className={classes.tableRow} style={{ marginBottom: '1rem' }}>
              <Text className={classes.tableCell} bold>
                <FormattedMessage defaultMessage="Totalt" id="ScheduleInformation.total"/>
              </Text>
              <Text className={classes.tableCell} bold>
                {formatDuration({
                hours: tab === 'absences' ? absences.total : entity.assignedDurationWithoutUnpaidBreaks.value,
            })}
              </Text>
            </Flexbox>
          </Flexbox>
        </Flexbox>)}
      <Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
        {userAbsenceModalQueryRef && (<UserAbsenceModal toDate={date.toDate} fromDate={date.fromDate} preloadedQuery={userAbsenceModalQueryRef} onClose={() => {
                disposeUserAbsenceModalQuery();
            }}/>)}
      </Suspense>
    </div>);
};
