/**
 * @generated SignedSource<<03a3abd5e206497637d26a94e52b4f45>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserPicker_query",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserPickerList_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "48348cb0b17cb9a5a813b183896a3779";
export default node;
