/**
 * @generated SignedSource<<f532e6588054403fc8b2974e778ac84f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleTemplateShiftId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "scheduleTemplateShiftId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = [
        (v2 /*: any*/)
    ], v4 = {
        "kind": "InlineFragment",
        "selections": (v3 /*: any*/),
        "type": "ISchedulableEntity",
        "abstractKey": "__isISchedulableEntity"
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assignmentTypeId",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleTemplatePeriodDay",
        "kind": "LinkedField",
        "name": "day",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dayInPeriod",
                "storageKey": null
            },
            (v2 /*: any*/)
        ],
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endTimeOfDay",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleTemplatePeriod",
        "kind": "LinkedField",
        "name": "period",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduleTemplateId",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "event",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            },
            (v2 /*: any*/)
        ],
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startTimeOfDay",
        "storageKey": null
    }, v12 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "MINUTE"
        }
    ], v13 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        }
    ], v14 = {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleTemplateShiftBreak",
        "kind": "LinkedField",
        "name": "breaks",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": (v12 /*: any*/),
                "concreteType": "TimeSpanUnitValue",
                "kind": "LinkedField",
                "name": "offsetEnd",
                "plural": false,
                "selections": (v13 /*: any*/),
                "storageKey": "offsetEnd(unit:\"MINUTE\")"
            },
            {
                "alias": null,
                "args": (v12 /*: any*/),
                "concreteType": "TimeSpanUnitValue",
                "kind": "LinkedField",
                "name": "offsetStart",
                "plural": false,
                "selections": (v13 /*: any*/),
                "storageKey": "offsetStart(unit:\"MINUTE\")"
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditScheduleTemplateShiftModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleTemplateShift",
                    "kind": "LinkedField",
                    "name": "scheduleTemplateShift",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "assignedEntity",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v14 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditScheduleTemplateShiftModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleTemplateShift",
                    "kind": "LinkedField",
                    "name": "scheduleTemplateShift",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "assignedEntity",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                (v4 /*: any*/),
                                {
                                    "kind": "InlineFragment",
                                    "selections": (v3 /*: any*/),
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                }
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v14 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "17f571f0b449295b756e59e5787a51d3",
            "id": null,
            "metadata": {},
            "name": "EditScheduleTemplateShiftModalQuery",
            "operationKind": "query",
            "text": "query EditScheduleTemplateShiftModalQuery(\n  $scheduleTemplateShiftId: ID!\n) {\n  scheduleTemplateShift(id: $scheduleTemplateShiftId) {\n    id\n    assignedEntity {\n      __typename\n      ... on ISchedulableEntity {\n        __isISchedulableEntity: __typename\n        id\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    assignmentTypeId\n    description\n    day {\n      dayInPeriod\n      id\n    }\n    endTimeOfDay\n    period {\n      id\n      scheduleTemplateId\n    }\n    event {\n      name\n      id\n    }\n    startTimeOfDay\n    breaks {\n      offsetEnd(unit: MINUTE) {\n        value\n      }\n      offsetStart(unit: MINUTE) {\n        value\n      }\n      type\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "d3ed8a0de488a96139b1de429c3cf1c9";
export default node;
