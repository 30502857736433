import React, { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Field, Form, Input, Select2 } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
export const userQueryFormValidationSchema = object({
    search: string().notRequired().nullable(),
    status: string().notRequired().nullable(),
    archived: string().notRequired().nullable(),
});
export const ScheduleQueryForm = (props) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(userQueryFormValidationSchema),
    });
    const statusOptions = useMemo(() => [
        {
            label: 'Alla',
            value: null,
        },
        {
            label: 'Aktiva',
            value: 'ACTIVE',
        },
        {
            label: 'Inaktiva',
            value: 'DISABLED',
        },
    ], []);
    const archivedOptions = useMemo(() => [
        {
            label: 'Alla',
            value: null,
        },
        {
            label: 'Synliga',
            value: 'NOTARCHIVED',
        },
        {
            label: 'Dolda',
            value: 'ARCHIVED',
        },
    ], []);
    return (<Form columns="30% 20% 20% auto 1fr" onSubmit={handleSubmit((data) => {
            console.log({ data });
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(data);
        })}>
      <Field>
        <Controller control={control} name="search" render={({ field }) => {
            var _a;
            return (<Input defaultValue={defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.search} disabled={disabled} display="block" error={(_a = errors === null || errors === void 0 ? void 0 : errors.search) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} placeholder="Fritextsök"/>);
        }}/>
      </Field>
      <Field>
        <Controller control={control} name="status" render={({ field }) => {
            var _a;
            return (<Select2 defaultValue={(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.status) ? [defaultValues.status] : undefined} disabled={disabled} multiple={false} error={(_a = errors === null || errors === void 0 ? void 0 : errors.status) === null || _a === void 0 ? void 0 : _a.message} onBlur={() => field.onBlur()} onChange={({ single }) => field.onChange(single)} options={statusOptions} placeholder="Status"/>);
        }}/>
      </Field>
      <Field>
        <Controller control={control} name="archived" render={({ field }) => {
            var _a;
            return (<Select2 defaultValue={(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.archived) ? [defaultValues.archived] : undefined} error={(_a = errors === null || errors === void 0 ? void 0 : errors.archived) === null || _a === void 0 ? void 0 : _a.message} disabled={disabled} multiple={false} onBlur={() => field.onBlur()} onChange={({ single }) => field.onChange(single)} options={archivedOptions} placeholder="Synlighet"/>);
        }}/>
      </Field>
      <Button type="submit">Filtrera</Button>
    </Form>);
};
export default ScheduleQueryForm;
