import React, { forwardRef, Suspense, useEffect, useImperativeHandle, useMemo, useRef, useTransition } from 'react';
import { array, object, string } from 'yup';
import { graphql, useQueryLoader, useRefetchableFragment } from 'react-relay/hooks';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Field, Textarea, Button, ModalToSheetSkeleton, Flexbox, Chip } from '@sonika-se/ui-kit/components';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import UserSelectModal, { userSelectModalQuery } from '~/shared/modals/UserSelectModal';
export const scheduleGeneralInfoFormValidationSchema = object().shape({
    content: string().required(),
    recipientUserIds: array().of(string()).required().min(1),
});
export const ScheduleShiftVacancyNotifyModalForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit, queryRef } = props;
    const { control, formState: { errors, isValid }, handleSubmit, watch, } = useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(scheduleGeneralInfoFormValidationSchema),
    });
    const { recipientUserIds } = watch();
    const firstRender = useRef(true);
    const [isTransitioning, startTransition] = useTransition();
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        startTransition(() => {
            refetch({
                where: {
                    id: {
                        in: recipientUserIds,
                    },
                },
            });
        });
    }, [recipientUserIds]);
    const where = useMemo(() => ({ id: { nin: recipientUserIds || [] } }), [recipientUserIds]);
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    const [userSelectModalQueryRef, loadUserSelectModalQuery, disposeUserSelectModalQuery] = useQueryLoader(userSelectModalQuery);
    const [{ users }, refetch] = useRefetchableFragment(graphql `
      fragment ScheduleShiftVacancyNotifyModalForm_query on Query
      @argumentDefinitions(where: { type: "UserFilterInput" })
      @refetchable(queryName: "ScheduleShiftVacancyNotifyModalForm_queryQuery") {
        users(where: $where) {
          nodes {
            id
            firstname
            lastname
          }
        }
      }
    `, queryRef);
    return (<>
      <Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        <Field label="Mottagare">
          <Controller control={control} name="recipientUserIds" render={({ field }) => (<>
                <Flexbox direction="row" gap="xsmall" wrap="wrap">
                  {users.nodes.map((user) => (<Chip color={sonikaTheme.colors.primary} key={user.id} deleteProps={{
                    onDelete: () => {
                        const userIds = [...field.value.filter((id) => id !== user.id)];
                        field.onChange([...userIds]);
                    },
                }} text={`${user.firstname} ${user.lastname}`} type="view"/>))}
                  <Button appearance="text" color="primary" icon={faPlus} onClick={() => {
                loadUserSelectModalQuery({
                    where,
                });
            }} size="small">
                    Lägg till mottagare
                  </Button>
                </Flexbox>
                {userSelectModalQueryRef && (<Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
                    <UserSelectModal onClose={() => {
                    disposeUserSelectModalQuery();
                }} onSelect={(newUserIds) => {
                    const userIds = [...(field.value || []), ...newUserIds];
                    field.onChange(userIds);
                    disposeUserSelectModalQuery();
                }} defaultWhere={where} title="Välj mottagare" preloadedQuery={userSelectModalQueryRef}/>
                  </Suspense>)}
              </>)}/>
        </Field>
        <Field label="Innehåll">
          <Controller control={control} name="content" render={({ field }) => {
            var _a;
            return (<Textarea defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.content) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
      </Form>
    </>);
});
export default ScheduleShiftVacancyNotifyModalForm;
