import React from 'react';
import { addMonths, formatISO } from 'date-fns';
const now = new Date();
const fromDate = formatISO(now, { representation: 'date' });
const toDate = formatISO(addMonths(now, 1), { representation: 'date' });
export const defaultScheduleTemplateDetailsPagevalues = {
    companyId: null,
    filters: {
        resourceIds: [],
        assignmentTypeIds: [],
        scheduleIds: [],
        onlyVacant: undefined,
        hideEmptySchedules: undefined,
        tagIds: [],
    },
    highlightedSchedulableEntities: [],
    highlightedShifts: {},
    toDate,
    fromDate,
    informationDrawer: {
        show: false,
        type: 'USER',
        tab: 'all',
    },
    editMode: false,
    defaultSearch: {
        useDefaultSearch: false,
        defaultSearch: undefined,
        defaultSearchSaved: undefined,
        defaultSearchDates: undefined,
    },
};
export const RunningSchedulesPageContext = React.createContext(defaultScheduleTemplateDetailsPagevalues);
export const RunningSchedulesPageContextProvider = RunningSchedulesPageContext.Provider;
export default RunningSchedulesPageContext;
