import React, { useMemo, useState } from 'react';
import { Flexbox, DateInput, Field, Form, Select2, H4, Button, ToggleButtonGroup } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import QuerySchedulableEntitySelect from '~/pages/AbsencePages/AbsenceCalendarPage/components/QuerySchedulableEntitySelect';
import { Controller, useForm } from 'react-hook-form';
import { startOfYear, addYears } from 'date-fns';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
export const SimultaneousShiftsFilterForm = (props) => {
    const { onSubmit, disabled, isLoading } = props;
    const intl = useIntl();
    const { assignmentTypes, events } = useLazyLoadQuery(graphql `
      query SimultaneousShiftsFilterFormQuery {
        assignmentTypes(first: 1000) {
          edges {
            node {
              id
              title
            }
          }
        }
        events(first: 1000, where: { isWork: { eq: true } }, order: { name: ASC }) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, {});
    const availableAssignmentTypes = useMemo(() => {
        var _a;
        return [
            { label: 'Alla roller', value: null },
            ...(_a = assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.edges) === null || _a === void 0 ? void 0 : _a.map(({ node }) => ({
                value: node,
                label: node.title,
            })),
        ];
    }, [assignmentTypes]);
    const { control, handleSubmit, register, formState: { errors }, getValues, setValue, } = useForm({
        defaultValues: {
            schedulableEntityType: 'USER',
            schedulableEntity: null,
            assignmentType: null,
            endDate: addYears(startOfYear(new Date()), 1),
            events: null,
            startDate: startOfYear(new Date()),
            withSchedulableEntities: [],
            selectWithSchedulableEntities: '1',
            withSchedulableEntityType: 'ASSET',
        },
    });
    const [selectWithSchedulableEntities, setWithSelectSchedulableEntities] = useState(false);
    const [schedulableEntityType, setSchedulableEntityType] = useState('USER');
    return (<>
      <Form onSubmit={handleSubmit((formData) => {
            var _a;
            onSubmit({
                assignmentTypeIds: formData.assignmentType ? [formData.assignmentType.id] : null,
                eventsIds: (_a = formData.events) === null || _a === void 0 ? void 0 : _a.map((x) => x.id),
                schedulableEntityId: formData.schedulableEntity.id,
                startDate: formData.startDate,
                endDate: formData.endDate,
                withSchedulableEntityIds: selectWithSchedulableEntities
                    ? formData.withSchedulableEntities.map((x) => x.id)
                    : null,
                withSchedulableEntityTypes: selectWithSchedulableEntities
                    ? null
                    : [schedulableEntityType === 'USER' ? 'ASSET' : 'USER'],
            });
        })}>
        <H4>Filter</H4>
        <Field label="Utgå från">
          <Controller name="schedulableEntityType" control={control} render={({ field }) => {
            return (<ToggleButtonGroup width="full" appearance="default" disabled={disabled} type="radio" defaultValue={field.value} options={[
                    { label: 'Användare', value: 'USER' },
                    { label: 'Enhet', value: 'ASSET' },
                ]} onChange={(event) => {
                    field.onChange(event.target.value);
                    setSchedulableEntityType(event.target.value);
                }}/>);
        }}/>
        </Field>

        <Field /*label={schedulableEntityType === 'USER' ? 'Användare' : 'Enhet'}*/>
          {schedulableEntityType === 'USER' && (<Controller control={control} name="schedulableEntity" rules={{ required: 'Ange en användare' }} render={({ field }) => {
                var _a, _b;
                return (<QuerySchedulableEntitySelect disabled={disabled} onSingleSelected={(value) => {
                        field.onChange(value);
                    }} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.schedulableEntity) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} entityFilterType={schedulableEntityType}/>);
            }}/>)}
          {schedulableEntityType === 'ASSET' && (<Controller control={control} name="schedulableEntity" rules={{ required: 'Ange en enhet' }} render={({ field }) => {
                var _a, _b;
                return (<QuerySchedulableEntitySelect disabled={disabled} onSingleSelected={(value) => {
                        field.onChange(value);
                    }} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.schedulableEntity) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} entityFilterType={schedulableEntityType}/>);
            }}/>)}
        </Field>
        <Field label="Roll">
          <Controller control={control} name="assignmentType" 
    // rules={{ required: 'Ange ett värde' }}
    render={({ field }) => {
            var _a, _b;
            return (<Select2 disabled={disabled} defaultValue={field.value} onChange={({ single }) => field.onChange(single)} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.assignmentType) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} options={availableAssignmentTypes}/>);
        }}/>
        </Field>
        <Flexbox direction="row" gap="large">
          <Field label={intl.formatMessage({
            id: 'AddPlannedAbsenceModal.fields.start.label',
            defaultMessage: 'Startdatum',
        })}>
            <Controller name="startDate" control={control} rules={{ required: 'Ange ett värde' }} render={({ field }) => {
            var _a, _b;
            return (<DateInput disabled={disabled} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.startDate) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} defaultValue={field.value} onChange={field.onChange} placeholder={intl.formatMessage({
                    id: 'AddPlannedAbsenceModal.fields.start.placeholder',
                    defaultMessage: 'Välj startdatum',
                })}/>);
        }}/>
          </Field>
          <Field label={intl.formatMessage({
            id: 'AddPlannedAbsenceModal.fields.end.label',
            defaultMessage: 'Slutdatum',
        })}>
            <Controller name="endDate" control={control} rules={{ required: 'Ange ett värde' }} render={({ field }) => {
            var _a, _b;
            return (<DateInput disabled={disabled} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.endDate) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={field.onChange} defaultValue={field.value} placeholder={intl.formatMessage({
                    id: 'AddPlannedAbsenceModal.fields.end.placeholder',
                    defaultMessage: 'Välj slutdatum',
                })}/>);
        }}/>
          </Field>
        </Flexbox>
        <Field label="Händelser">
          <Controller control={control} name="events" 
    // rules={{ required: 'Välj minst en händelse' }}
    render={({ field }) => {
            var _a, _b, _c;
            return (<Select2 placeholder="Alla händelser" disabled={disabled} defaultValue={field.value} onChange={({ multiple }) => {
                    field.onChange(multiple);
                }} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.events) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} multiple options={(_c = events === null || events === void 0 ? void 0 : events.edges) === null || _c === void 0 ? void 0 : _c.map(({ node }) => ({
                    value: node,
                    label: node.name,
                }))}/>);
        }}/>
        </Field>
        <Controller name="selectWithSchedulableEntities" control={control} render={({ field }) => (<ToggleButtonGroup width="full" appearance="default" disabled={disabled} type="radio" defaultValue={field.value} options={[
                { label: schedulableEntityType === 'USER' ? 'Alla enheter' : 'Alla användare', value: '1' },
                { label: schedulableEntityType === 'USER' ? 'Välj enheter' : 'Välj användare', value: '0' },
            ]} onChange={(event) => {
                const value = event.target.value === '1';
                field.onChange(value);
                setWithSelectSchedulableEntities(!value);
            }}/>)}/>
        {selectWithSchedulableEntities && (<Field>
            {schedulableEntityType === 'USER' && (<Controller name="withSchedulableEntities" control={control} rules={{
                    validate: (value) => {
                        if (!value.length) {
                            return 'Ange minst en enhet';
                        }
                        return true;
                    },
                }} render={({ field }) => {
                    var _a, _b;
                    return (<QuerySchedulableEntitySelect disabled={disabled} onMultipleSelected={(value) => {
                            field.onChange(value);
                        }} multiple={true} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.withSchedulableEntities) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} entityFilterType={'ASSET'}/>);
                }}/>)}
            {schedulableEntityType === 'ASSET' && (<Controller name="withSchedulableEntities" control={control} rules={{
                    validate: (value) => {
                        if (!value.length) {
                            return 'Ange minst en användare';
                        }
                        return true;
                    },
                }} render={({ field }) => {
                    var _a, _b;
                    return (<QuerySchedulableEntitySelect disabled={disabled} onMultipleSelected={(value) => {
                            field.onChange(value);
                        }} multiple={true} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.withSchedulableEntities) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} entityFilterType={'USER'}/>);
                }}/>)}
          </Field>)}
        <Button type="submit" disabled={isLoading} icon={isLoading && faSpinner} iconProps={{ pulse: true }}>
          Hämta pass
        </Button>
      </Form>
    </>);
};
export default SimultaneousShiftsFilterForm;
