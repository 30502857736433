import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Button, Flexbox, Text } from '@sonika-se/ui-kit/components';
export const RolePickerListItem = (props) => {
    const { onSelect, roleRef } = props;
    const { id, name } = useFragment(graphql `
      fragment RolePickerListItem_role on Role {
        id
        name
      }
    `, roleRef);
    return (<Flexbox justify="space">
      <Text>{name}</Text>
      <Button onClick={() => onSelect(id)} size="small">
        Välj
      </Button>
    </Flexbox>);
};
export default RolePickerListItem;
