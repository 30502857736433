/**
 * @generated SignedSource<<a1733bd57cec46e00ee82c16645e97a9>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "scheduleId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v4 = [
        (v2 /*: any*/)
    ], v5 = {
        "kind": "InlineFragment",
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Asset",
                        "kind": "LinkedField",
                        "name": "asset",
                        "plural": false,
                        "selections": (v4 /*: any*/),
                        "storageKey": null
                    }
                ],
                "type": "SchedulableAsset",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": (v4 /*: any*/),
                        "storageKey": null
                    }
                ],
                "type": "SchedulableUser",
                "abstractKey": null
            }
        ],
        "type": "ISchedulableEntity",
        "abstractKey": "__isISchedulableEntity"
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditResourceColorListQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleAssignmentTypeConnection",
                            "kind": "LinkedField",
                            "name": "assignmentTypes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleAssignmentType",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                            "kind": "LinkedField",
                                            "name": "resources",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeResource",
                                                    "kind": "LinkedField",
                                                    "name": "nodes",
                                                    "plural": true,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "schedulableEntity",
                                                            "plural": false,
                                                            "selections": [
                                                                (v5 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditResourceColorListQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleAssignmentTypeConnection",
                            "kind": "LinkedField",
                            "name": "assignmentTypes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleAssignmentType",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                            "kind": "LinkedField",
                                            "name": "resources",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeResource",
                                                    "kind": "LinkedField",
                                                    "name": "nodes",
                                                    "plural": true,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "schedulableEntity",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "__typename",
                                                                    "storageKey": null
                                                                },
                                                                (v5 /*: any*/),
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": (v4 /*: any*/),
                                                                    "type": "Node",
                                                                    "abstractKey": "__isNode"
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "9a69dcca9a5937ec258cc874a3f6b1c4",
            "id": null,
            "metadata": {},
            "name": "EditResourceColorListQuery",
            "operationKind": "query",
            "text": "query EditResourceColorListQuery(\n  $scheduleId: ID!\n) {\n  schedule(id: $scheduleId) {\n    id\n    assignmentTypes {\n      nodes {\n        id\n        assignmentType {\n          id\n          title\n        }\n        resources {\n          nodes {\n            id\n            schedulableEntity {\n              __typename\n              ... on ISchedulableEntity {\n                __isISchedulableEntity: __typename\n                id\n                color\n                displayName\n                ... on SchedulableAsset {\n                  asset {\n                    id\n                  }\n                }\n                ... on SchedulableUser {\n                  user {\n                    id\n                  }\n                }\n              }\n              ... on Node {\n                __isNode: __typename\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "9c1c164331c25bbec59b1fa9f9044b64";
export default node;
