import React, { Suspense, useMemo } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { Chip, Flexbox, Button, Divider, Placeholder, Accordion, AccordionItem } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import EditAssignmentTypesOnScheduleModal, { editAssignmentTypeModalQuery, } from '../../../SchedulePages/components/EditAssignmentTypesOnScheduleModal/EditAssignmentTypesOnScheduleModal';
import ScheduleAssignmentType from '../ScheduleAssignmentType/ScheduleAssignmentType';
import { dragSchedulableEntity } from '../../../SchedulePages/utils/dragSchedulableEntity';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        main: {
            flex: 1,
            overflowY: 'auto',
        },
        actions: {
            backgroundColor: '#FFFFFF',
            borderTop: `${pxToRem(1)} solid #DADADA`,
            padding: pxToRem(16),
        },
        block: {
            '&:not(:first-child)': {
                borderTop: `${pxToRem(1)} solid #DADADA`,
                padding: pxToRem(16),
            },
        },
        vacant: {
            borderBottom: `${pxToRem(1)} solid #DADADA`,
            padding: pxToRem(16),
        },
    };
});
export const ScheduleTemplateSidebar = (props) => {
    var _a;
    const { scheduleTemplate, updateHighlightedResources } = props;
    const classes = useStyles();
    const intl = useIntl();
    const { schedule, shifts } = useFragment(graphql `
      fragment ScheduleTemplateSidebar_scheduleTemplate on ScheduleTemplate {
        id
        shifts {
          edges {
            node {
              assignmentTypeId
              id
              assignedEntity {
                ... on SchedulableUser {
                  id
                  user {
                    id
                    color
                    firstname
                    lastname
                  }
                }
                ... on SchedulableAsset {
                  id
                  asset {
                    id
                    color
                    name
                  }
                }
              }
            }
          }
        }
        schedule {
          id
          assignmentTypes {
            edges {
              node {
                id
                resources {
                  totalCount
                }
                ...ScheduleAssignmentType_scheduleAssignmentType
                assignmentType {
                  title
                }
              }
            }
          }
          currentUserPermissions
        }
      }
    `, scheduleTemplate);
    const [editAssignmentTypeModalQueryRef, loadEditAssignmentTypesOnScheduleModalQuery, disposeEditAssignmentTypesOnScheduleModalQuery,] = useQueryLoader(editAssignmentTypeModalQuery);
    const hasPermission = usePermissions();
    const canEditAssignmentTypes = useMemo(() => hasPermission(['EDIT_SCHEDULES']) || schedule.currentUserPermissions.includes('EDIT_ASSIGNMENT_TYPES'), [schedule.currentUserPermissions, hasPermission]);
    return (<>
      <div className={classes.root}>
        <div className={classes.main}>
          <Flexbox direction="column">
            <div className={classes.vacant}>
              <div draggable="true" onDragStart={(event) => dragSchedulableEntity(event, 'user', null, null)}>
                <Chip specialColor="red-striped" fullWidth={true} text={intl.formatMessage({
            defaultMessage: 'Vakant',
            id: 'general.misc.schedule_shift.vacant.singular',
        })}/>
              </div>
            </div>
            {!((_a = schedule.assignmentTypes.edges) === null || _a === void 0 ? void 0 : _a.length) && <Placeholder text="Det finns inga roller i mallen"/>}
            {schedule.assignmentTypes.edges
            .map(({ node: scheduleAssignmentType }) => scheduleAssignmentType)
            .slice()
            .sort((a, b) => { var _a; return (_a = a.assignmentType.title) === null || _a === void 0 ? void 0 : _a.localeCompare(b.assignmentType.title); })
            .map((scheduleAssignmentType, assignmentTypeIndex) => (<div key={scheduleAssignmentType.id}>
                  {assignmentTypeIndex > 0 && <Divider />}
                  <Accordion>
                    <AccordionItem size="medium" subText={{
                children: (<>
                            <span>
                              {scheduleAssignmentType.assignmentType.title} (
                              {scheduleAssignmentType.resources.totalCount})
                            </span>
                          </>),
            }}>
                      <ScheduleAssignmentType scheduleAssignmentType={scheduleAssignmentType} shifts={shifts.edges.map(({ node: shift }) => {
                var _a;
                return ({
                    id: shift.id,
                    assignmentTypeId: shift.assignmentTypeId,
                    assignedId: (_a = shift.assignedEntity) === null || _a === void 0 ? void 0 : _a.id,
                });
            })} updateHighlightedResources={updateHighlightedResources}/>
                    </AccordionItem>
                  </Accordion>
                </div>))}
          </Flexbox>
        </div>
        {canEditAssignmentTypes && (<div className={classes.actions}>
            <Button display="block" onClick={() => {
                loadEditAssignmentTypesOnScheduleModalQuery({ scheduleId: schedule.id }, { fetchPolicy: 'network-only' });
            }}>
              {!!schedule.assignmentTypes.edges.length
                ? intl.formatMessage({
                    id: 'general.actions.update',
                    defaultMessage: 'Redigera',
                })
                : intl.formatMessage({ id: 'general.actions.create', defaultMessage: 'Lägg till' })}
            </Button>
          </div>)}
      </div>
      <Suspense fallback={<></>}>
        {editAssignmentTypeModalQueryRef && (<EditAssignmentTypesOnScheduleModal onCancel={() => disposeEditAssignmentTypesOnScheduleModalQuery()} query={editAssignmentTypeModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleTemplateSidebar;
