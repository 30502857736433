/**
 * @generated SignedSource<<0bd5e52cca46e59a45f6f409e57dd090>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "refetch": {
            "connection": null,
            "fragmentPathInResult": [],
            "operation": require('./ScheduleShiftClashGroupList_queryQuery.graphql')
        }
    },
    "name": "ScheduleShiftClashGroupList_query",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "ClashGroup",
            "kind": "LinkedField",
            "name": "currentScheduleShiftClashes",
            "plural": true,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftClashCard_clashGroup"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "bc878bdf199a24212eab6ca248eef087";
export default node;
