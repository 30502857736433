/**
 * @generated SignedSource<<b514ca12603fd35d14b666fd918a5a71>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "model"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "schedulesFilter"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "typeFilter"
    }, v3 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        },
        {
            "kind": "Variable",
            "name": "schedulesFilter",
            "variableName": "schedulesFilter"
        },
        {
            "kind": "Variable",
            "name": "typeFilter",
            "variableName": "typeFilter"
        }
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "HOUR"
        }
    ], v6 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        }
    ], v7 = {
        "alias": null,
        "args": (v5 /*: any*/),
        "concreteType": "TimeSpanUnitValue",
        "kind": "LinkedField",
        "name": "absenceDurationWithoutUnpaidBreaks",
        "plural": false,
        "selections": (v6 /*: any*/),
        "storageKey": "absenceDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesInformationQuery",
            "selections": [
                {
                    "args": (v3 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "RunningSchedulesInformation_scheduleTimes"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v2 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "RunningSchedulesInformationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "ScheduleTimesPayload",
                    "kind": "LinkedField",
                    "name": "scheduleTimes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                            "kind": "LinkedField",
                            "name": "timesByEntityAndAssignmentType",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "assignmentType",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "schedulableEntity",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "color",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "displayName",
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "ISchedulableEntity",
                                            "abstractKey": "__isISchedulableEntity"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v4 /*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "userId",
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "SchedulableUser",
                                            "abstractKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleTimeAbsenceByReason",
                                    "kind": "LinkedField",
                                    "name": "absencesByReason",
                                    "plural": true,
                                    "selections": [
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Event",
                                            "kind": "LinkedField",
                                            "name": "reasonEvent",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "concreteType": "TimeSpanUnitValue",
                                    "kind": "LinkedField",
                                    "name": "assignedDurationWithoutUnpaidBreaks",
                                    "plural": false,
                                    "selections": (v6 /*: any*/),
                                    "storageKey": "assignedDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "2441a3fc769abdff9e4ff1afd2bc3331",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesInformationQuery",
            "operationKind": "query",
            "text": "query RunningSchedulesInformationQuery(\n  $model: GetRunningScheduleRequestInput!\n  $typeFilter: SchedulableEntityTypeFilterRequestInput\n  $schedulesFilter: [ID!]\n) {\n  ...RunningSchedulesInformation_scheduleTimes_YgdhF\n}\n\nfragment RunningSchedulesInformationTableRow_scheduleTime on ScheduleTimeByEntityAndAssignmentType {\n  absenceDurationWithoutUnpaidBreaks(unit: HOUR) {\n    unit\n    value\n  }\n  absencesByReason {\n    absenceDurationWithoutUnpaidBreaks(unit: HOUR) {\n      unit\n      value\n    }\n    reasonEvent {\n      id\n      name\n    }\n  }\n  assignedDurationWithoutUnpaidBreaks(unit: HOUR) {\n    unit\n    value\n  }\n  schedulableEntity {\n    __typename\n    ... on ISchedulableEntity {\n      __isISchedulableEntity: __typename\n      id\n      color\n      displayName\n    }\n    ... on SchedulableUser {\n      userId\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment RunningSchedulesInformationTable_scheduleTimes on ScheduleTimesPayload {\n  timesByEntityAndAssignmentType {\n    assignmentType {\n      id\n      title\n    }\n    schedulableEntity {\n      __typename\n      ... on ISchedulableEntity {\n        __isISchedulableEntity: __typename\n        id\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    ...RunningSchedulesInformationTableRow_scheduleTime\n  }\n}\n\nfragment RunningSchedulesInformation_scheduleTimes_YgdhF on Query {\n  scheduleTimes(model: $model, typeFilter: $typeFilter, schedulesFilter: $schedulesFilter) {\n    timesByEntityAndAssignmentType {\n      assignmentType {\n        id\n        title\n      }\n    }\n    ...RunningSchedulesInformationTable_scheduleTimes\n  }\n}\n"
        }
    };
})();
node.hash = "e36e71e77490351fa8fdac8de546d1d3";
export default node;
