import React, { useEffect } from 'react';
import { Avatar, Flexbox, Logo, Text } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
import { graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { eachDayOfInterval, format, formatISO } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { isSameDay, isWeekend } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import Company from '~/components/Company';
import { createRunningSchedulesFilterFromUrl } from '../SchedulePages/utils/filters';
const useStyles = createUseStyles((theme) => ({
    logo: {
        height: theme.helpers.pxToRem(24),
        width: theme.helpers.pxToRem(114),
    },
    printContentCell: {
        padding: '0.75rem 0.5rem 0',
        borderTop: `${theme.helpers.pxToRem(1)} solid ${theme.colors.foreground.separation}`,
    },
    printHeader: {
        '& th': {
            padding: theme.helpers.pxToRem(10),
            textAlign: 'left',
        },
    },
    printFooter: {
        padding: theme.helpers.pxToRem(10),
    },
    printTable: {
        pageBreakAfter: 'always',
        width: '100vw',
    },
}));
export const printRunningSchedulePageQuery = graphql `
  query PrintRunningSchedulePageQuery(
    $after: String
    $first: Int
    $fromDate: DateTime!
    $toDate: DateTime!
    $where: ScheduleShiftFilterInput
    $whereSchedules: ScheduleFilterInput
    $filterModel: ScheduleFilterRequestInput
  ) {
    currentCompany {
      name
      ...Company
    }
    schedules(where: $whereSchedules, filterModel: $filterModel) {
      edges {
        node {
          id
          runningSchedule(
            after: $after
            first: $first
            model: { fromDate: $fromDate, toDate: $toDate }
            where: $where
          ) {
            edges {
              node {
                id
                assignmentType {
                  id
                  title
                }
                end
                start
                assignedEntity {
                  ... on ISchedulableEntity {
                    id
                    displayName
                    color
                  }
                }
                isVacant
              }
            }
          }
          name
        }
      }
    }
  }
`;
const Footer = (props) => {
    const { style } = props;
    const classes = useStyles();
    return (<Flexbox align="center" className={classes.printFooter} justify="space" style={style}>
      <Text size="small">
        <FormattedMessage defaultMessage="Nedladdat {date}" id="PrintRunningSchedulePage.download" values={{ date: `${formatISO(new Date(), { representation: 'date' })}` }}/>
      </Text>
      <Logo className={classes.logo}/>
    </Flexbox>);
};
const PrintRunningSchedulePage = () => {
    var _a;
    const filters = createRunningSchedulesFilterFromUrl();
    const { currentCompany, schedules } = useLazyLoadQuery(printRunningSchedulePageQuery, filters);
    const intl = useIntl();
    const classes = useStyles();
    const { fromDate, toDate } = filters;
    const dateRange = eachDayOfInterval({ start: new Date(fromDate), end: new Date(toDate) });
    useEffect(() => {
        window.print();
    }, []);
    return (<div>
      <style>{`
        @page {
          margin: 6.35mm !important;
        }

        body {
          background-color: white;
        }

        html {
          font-size: 14px !important;
        }
      `}</style>
      {(_a = schedules === null || schedules === void 0 ? void 0 : schedules.edges) === null || _a === void 0 ? void 0 : _a.map(({ node: schedule }) => {
            var _a, _b, _c, _d;
            const uniqueAssignmentTypes = (_b = (_a = schedule === null || schedule === void 0 ? void 0 : schedule.runningSchedule) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.reduce((prev, item) => {
                return Object.assign(Object.assign({}, prev), { [item.node.assignmentType.id]: item.node.assignmentType.title });
            }, {});
            return (<table className={classes.printTable} key={schedule.id}>
            <thead className={classes.printHeader}>
              <tr>
                <th colSpan={(((_c = Object.keys(uniqueAssignmentTypes)) === null || _c === void 0 ? void 0 : _c.length) || 0) + 1}>
                  <Flexbox direction="column" align="normal" gap="small">
                    <Flexbox justify="space" align="center">
                      <Flexbox gap="xsmall" align="center">
                        <Company parent={currentCompany} size="small"/>
                        <Flexbox direction="column" gap="xxsmall">
                          <Flexbox gap="xxsmall">
                            <Text bold size="small">
                              {currentCompany.name}
                            </Text>
                            <Text bold style={{ color: '#ccc' }} size="small">
                              •
                            </Text>
                            <Text bold size="small">
                              {schedule.name}
                            </Text>
                          </Flexbox>
                          <Text size="small">
                            <FormattedMessage defaultMessage="Schema {date}" id="PrintRunningSchedulePage.schedule_date" values={{
                    date: `${formatISO(fromDate, { representation: 'date' })} - ${formatISO(toDate, {
                        representation: 'date',
                    })}`,
                }}/>
                          </Text>
                        </Flexbox>
                      </Flexbox>
                    </Flexbox>
                  </Flexbox>
                </th>
              </tr>
              <tr>
                <th>
                  <Text style={{ textTransform: 'uppercase' }}>
                    <FormattedMessage defaultMessage="Datum" id="PrintRunningSchedulePage.date"/>
                  </Text>
                </th>
                {Object.keys(uniqueAssignmentTypes).map((key) => (<th key={`${key}_${schedule.id}`}>
                    <Text style={{ textTransform: 'uppercase' }}>{uniqueAssignmentTypes[key]}</Text>
                  </th>))}
              </tr>
            </thead>
            <tbody>
              {dateRange.map((date) => {
                    return (<tr key={`${schedule.id}_${formatISO(date, { representation: 'date' })}`}>
                    <td className={classes.printContentCell}>
                      <div>
                        <Text size="small" style={{
                            color: isWeekend(date) ? '#FE544F' : null,
                            textTransform: 'capitalize',
                        }}>
                          {format(date, 'E', { locale })}
                        </Text>
                        <Text bold style={{ marginTop: '.5rem', marginBottom: '.75rem' }}>
                          {format(date, 'dd/MM', { locale })}
                        </Text>
                      </div>
                    </td>
                    {Object.keys(uniqueAssignmentTypes).map((key) => {
                            var _a, _b;
                            const shifts = (_b = (_a = schedule === null || schedule === void 0 ? void 0 : schedule.runningSchedule) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.filter(({ node: runningSchedule }) => {
                                return (isSameDay(new Date(runningSchedule.start), date) &&
                                    runningSchedule.assignmentType.id === key);
                            });
                            if (!(shifts === null || shifts === void 0 ? void 0 : shifts.length)) {
                                return (<td key={`${schedule.id}_${key}_${formatISO(date, { representation: 'date' })}`} className={classes.printContentCell}/>);
                            }
                            return (<td key={`${schedule.id}_${key}_${formatISO(date, { representation: 'date' })}`} className={classes.printContentCell}>
                          <div>
                            {shifts.map(({ node: shift }) => {
                                    var _a, _b, _c;
                                    return (<div key={shift.id}>
                                <Text size="small">{`${format(new Date(shift.start), 'HH:mm', {
                                            locale,
                                        })}-${format(new Date(shift.end), 'HH:mm', { locale })}`}</Text>
                                <div style={{
                                            display: 'flex',
                                            marginTop: '.25rem',
                                            marginBottom: '.75rem',
                                        }}>
                                  <Avatar text={((_a = shift.assignedEntity) === null || _a === void 0 ? void 0 : _a.displayName) ||
                                            intl.formatMessage({
                                                defaultMessage: 'Vakant',
                                                id: 'general.misc.schedule_shift.vacant.singular',
                                            })} size="xxsmall" color={((_b = shift.assignedEntity) === null || _b === void 0 ? void 0 : _b.color) || '#F44336'} specialColor={shift.isVacant ? 'red-striped' : undefined}/>
                                  <Text style={{ fontWeight: 'bold', marginLeft: '.5rem' }}>
                                    {((_c = shift.assignedEntity) === null || _c === void 0 ? void 0 : _c.displayName) ||
                                            intl.formatMessage({
                                                defaultMessage: 'Vakant',
                                                id: 'general.misc.schedule_shift.vacant.singular',
                                            })}
                                  </Text>
                                </div>
                              </div>);
                                })}
                          </div>
                        </td>);
                        })}
                  </tr>);
                })}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={(((_d = Object.keys(uniqueAssignmentTypes)) === null || _d === void 0 ? void 0 : _d.length) || 0) + 1}>
                  <Footer style={{ visibility: 'hidden' }}/>
                </th>
              </tr>
            </tfoot>
          </table>);
        })}
      <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
        <Footer />
      </div>
    </div>);
};
export default PrintRunningSchedulePage;
