import { formatDateSpan } from '@sonika-se/ui-kit/utils/time';
import { isSameDay } from 'date-fns';
export const dateRangeInWords = (date, start, end) => {
    return formatDateSpan(isSameDay(date, start) ? start : date, isSameDay(date, end) ? end : date, 'shortTime');
    /*
  
    const duration = end.getTime() - start.getTime()
  
    if (isSameDay(start, end) || duration < 24 * 60 * 60 * 1000) {
      return `${timeStart} - ${timeEnd}`
    }
  
    return `${timeStart} - ${timeEnd} ${format(end, 'dd/MM', { locale })}`
    */
};
export default dateRangeInWords;
