import React from 'react';
import { Card, CardContent, Chip, Divider, Flexbox, H4, Text, Tooltip } from '@sonika-se/ui-kit/components';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { graphql, useFragment } from 'react-relay/hooks';
import { Link } from 'react-router-dom';
import { pluralize } from '@sonika-se/ui-kit/utils/text';
import KpiBar from '~/components/KpiBar';
import KpiBarItem from '~/components/KpiBarItem';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
export const ScheduleCard = (props) => {
    const { scheduleRef } = props;
    const schedule = useFragment(graphql `
      fragment ScheduleCard_schedule on Schedule
      @argumentDefinitions(runningScheduleModel: { type: "GetRunningScheduleRequestInput!" }) {
        archived
        inactivatedFrom
        assignmentTypes {
          totalCount
        }
        id
        name
        runningSchedule(model: $runningScheduleModel, where: { assignedEntityId: { neq: null } }) {
          totalCount
        }
        shiftChangeRequests(model: $runningScheduleModel) {
          totalCount
        }
        vacantShifts: runningSchedule(model: $runningScheduleModel, where: { assignedEntityId: { eq: null } }) {
          totalCount
        }
        schedulableAssets: schedulableEntities(where: { type: { eq: ASSET } }) {
          totalCount
        }
        schedulableUser: schedulableEntities(where: { type: { eq: USER } }) {
          totalCount
        }
      }
    `, scheduleRef);
    return (<Card>
      <CardContent padding="large">
        <Flexbox direction="column" gap="medium">
          <Flexbox align="center" direction="row" gap="xsmall">
            <Tooltip content={schedule.archived ? 'Dolt' : 'Synligt'}>
              <div style={{
            backgroundColor: schedule.archived ? sonikaTheme.colors.system.stop : sonikaTheme.colors.system.go,
            borderRadius: '0.375rem',
            height: '0.75rem',
            width: '0.75rem',
        }}></div>
            </Tooltip>
            <H4>
              <Link to={`/schedules/${schedule.id}`}>{schedule.name}</Link>
            </H4>
          </Flexbox>
          {!!schedule.inactivatedFrom && (<Flexbox direction="column" gap="xsmall">
              <Flexbox direction="row" gap="xxsmall">
                <div style={{
                backgroundColor: sonikaTheme.colors.system.stop,
                borderRadius: '0.375rem',
                height: '0.45rem',
                width: '0.45rem',
                position: 'relative',
                top: '0.45rem',
            }}></div>

                <Text bold size="medium">
                  Inaktiverat från {formatDate(toDate(schedule.inactivatedFrom), 'dateTime')}
                </Text>
              </Flexbox>
            </Flexbox>)}
          <KpiBar>
            <KpiBarItem label={pluralize `${[schedule.assignmentTypes.totalCount, null]}Roll[|er]`} value={schedule.assignmentTypes.totalCount}/>
            <KpiBarItem label="Användare" value={schedule.schedulableUser.totalCount}/>
            <KpiBarItem label={pluralize `${[schedule.schedulableAssets.totalCount, null]}Enhet[|er]`} value={schedule.schedulableAssets.totalCount}/>
          </KpiBar>
          <Flexbox direction="column" gap="xsmall">
            <Flexbox direction="row" gap="xxsmall">
              <Text bold size="medium">
                {schedule.runningSchedule.totalCount}
              </Text>
              <Flexbox style={{ flex: 1 }}>
                <Text appearance="light" size="medium" style={{ flex: 1 }}>
                  pass kommande sju dagar
                </Text>
                {schedule.vacantShifts.totalCount > 0 && (<Chip specialColor="red-striped" size="xsmall" text={pluralize `${schedule.vacantShifts.totalCount} vakant[|a]`} type="view"/>)}
              </Flexbox>
            </Flexbox>
            <Divider />
            <Flexbox direction="row" gap="xxsmall">
              <Text bold size="medium">
                {schedule.shiftChangeRequests.totalCount}
              </Text>
              <Text appearance="light" size="medium">
                {pluralize `${[schedule.shiftChangeRequests.totalCount, null]} begärd[|a] förändring[|ar]`}
              </Text>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </CardContent>
    </Card>);
};
