import React, { useState } from 'react';
import { graphql } from 'react-relay';
import { usePreloadedQuery, useMutation, usePaginationFragment } from 'react-relay/hooks';
import { ModalToSheetHeader, ModalToSheetContent, ModalToSheet, Placeholder } from '@sonika-se/ui-kit/components';
import EditableList, { EditableListContent, EditableListHeader, EditableListSearch } from '~/components/EditableList';
import SchedulableEntityListItem from '../pages/SchedulePages/components/SchedulableEntitiesSearch/SchedulableEntityListItem';
import SchedulableEntitiesSearch from '../pages/SchedulePages/components/SchedulableEntitiesSearch/SchedulableEntitiesSearch';
export const scheduleAssignmentTypeResourcesUpdateModalQuery = graphql `
  query ScheduleAssignmentTypeResourcesUpdateModalQuery($scheduleAssignmentTypeId: ID!) {
    scheduleAssignmentType: node(id: $scheduleAssignmentTypeId) {
      ...ScheduleAssignmentTypeResourcesUpdateModal_scheduleAssignmentType
    }
  }
`;
export const ScheduleAssignmentTypeResourcesUpdateModal = (props) => {
    var _a, _b;
    const { filter, onClose } = props;
    const data = usePreloadedQuery(scheduleAssignmentTypeResourcesUpdateModalQuery, props.preloadedQuery);
    const { data: scheduleAssignmentType, hasNext, isLoadingNext, loadNext, } = usePaginationFragment(graphql `
      fragment ScheduleAssignmentTypeResourcesUpdateModal_scheduleAssignmentType on ScheduleAssignmentType
      @argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "String" })
      @refetchable(queryName: "ScheduleAssignmentTypeResourcesUpdateModalRefetchQuery") {
        id
        assignmentType {
          id
          assets {
            edges {
              node {
                schedulableEntity {
                  id
                }
              }
            }
          }
          title
          users {
            edges {
              node {
                schedulableEntity {
                  id
                }
              }
            }
          }
        }
        resources(after: $after, first: $first)
          @connection(key: "ScheduleAssignmentTypeResourcesUpdateModal_scheduleAssignmentType_resources") {
          edges {
            node {
              id
              schedulableEntityId
              ...SchedulableEntityListItem_scheduleAssignmentTypeResource
            }
          }
        }
        scheduleId
      }
    `, data.scheduleAssignmentType);
    const [selectedItems, setSelectedItems] = useState([]);
    const [addResource] = useMutation(graphql `
        mutation ScheduleAssignmentTypeResourcesUpdateModal_addResourceToScheduleAssignmentTypeMutation(
          $model: AddResourceToScheduleAssignmentTypeRequestInput!
          $filter: String
        ) {
          addResourceToScheduleAssignmentType(model: $model) {
            resourceEdge {
              node {
                scheduleAssignmentType {
                  ...ScheduleAssignmentTypeResourcesUpdateModal_scheduleAssignmentType
                  ...RunningScheduleAssignmentType_scheduleAssignmentType @arguments(nameContains: $filter)
                }
              }
            }
            resourceEdge2: resourceEdge {
              node {
                scheduleAssignmentType {
                  ...ScheduleAssignmentTypeResourceTable_scheduleAssignmentType
                }
              }
            }
          }
        }
      `);
    return (<ModalToSheet onClose={() => onClose()}>
      <ModalToSheetHeader onClose={() => onClose()} closeButtonLabel="Avbryt">
        Uppdatera användare/enheter
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <EditableList hasNext={hasNext} isLoadingNext={isLoadingNext} loadNext={() => loadNext(5)}>
          <EditableListHeader>
            <EditableListSearch options={(query) => {
            var _a, _b, _c, _d;
            return (<SchedulableEntitiesSearch allScheduleableEntityIds={[
                    ...(_a = scheduleAssignmentType.assignmentType) === null || _a === void 0 ? void 0 : _a.assets.edges.filter((x) => !!x.node.schedulableEntity).map(({ node: asset }) => { var _a; return (_a = asset.schedulableEntity) === null || _a === void 0 ? void 0 : _a.id; }),
                    ...(_b = scheduleAssignmentType.assignmentType) === null || _b === void 0 ? void 0 : _b.users.edges.filter((x) => !!x.node.schedulableEntity).map(({ node: user }) => { var _a; return (_a = user.schedulableEntity) === null || _a === void 0 ? void 0 : _a.id; }),
                ]} onSelect={(schedulableEntityId) => {
                    var _a;
                    setSelectedItems([schedulableEntityId]);
                    addResource({
                        variables: {
                            model: {
                                assignmentTypeId: (_a = scheduleAssignmentType === null || scheduleAssignmentType === void 0 ? void 0 : scheduleAssignmentType.assignmentType) === null || _a === void 0 ? void 0 : _a.id,
                                scheduleId: scheduleAssignmentType.scheduleId,
                                schedulableEntityId,
                            },
                            filter,
                        },
                    });
                }} query={query} selectedScheduleableEntityIds={(_d = (_c = scheduleAssignmentType === null || scheduleAssignmentType === void 0 ? void 0 : scheduleAssignmentType.resources) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(({ node: resource }) => resource.schedulableEntityId)}/>);
        }} placeholder="Sök och lägg till användare/enhet" selectedItems={selectedItems}/>
          </EditableListHeader>
          <EditableListContent>
            {((_a = scheduleAssignmentType === null || scheduleAssignmentType === void 0 ? void 0 : scheduleAssignmentType.resources) === null || _a === void 0 ? void 0 : _a.edges.length) === 0 ? (<Placeholder text="Användare/enheter saknas"/>) : ((_b = scheduleAssignmentType === null || scheduleAssignmentType === void 0 ? void 0 : scheduleAssignmentType.resources) === null || _b === void 0 ? void 0 : _b.edges.map(({ node: resource }) => (<SchedulableEntityListItem filter={filter} key={resource.id} scheduleAssignmentTypeResourceRef={resource}/>)))}
          </EditableListContent>
        </EditableList>
      </ModalToSheetContent>
    </ModalToSheet>);
};
