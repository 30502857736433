import React, { useCallback, useState } from 'react';
import { Confirm, Flexbox, Switch, Text } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
export const AssignmentTypeOnScheduleSwitch = (props) => {
    const { activeAssignmentTypeIds, resourceCount, onChange, scheduleAssignmentTypeRef, scheduleId } = props;
    const data = useFragment(graphql `
      fragment AssignmentTypeOnScheduleSwitch_assignmentType on AssignmentType {
        id
        title
      }
    `, scheduleAssignmentTypeRef);
    const [addAssignmentTypeToSchedule, addAssignmentTypeToScheduleLoading] = useMutation(graphql `
        mutation AssignmentTypeOnScheduleSwitch_addAssignmentTypeToScheduleMutation(
          $model: AddScheduleAssignmentTypeRequestInput!
        ) {
          addAssignmentTypeToSchedule(model: $model) {
            scheduleAssignmentTypeEdge {
              node {
                schedule {
                  ...EditAssignmentTypesOnScheduleModal_schedule
                  templates {
                    nodes {
                      ...ScheduleTemplateSidebar_scheduleTemplate
                    }
                  }
                }
              }
            }
          }
        }
      `);
    const [removeAssignmentTypeFromSchedule, removeAssignmentTypeFromScheduleLoading] = useMutation(graphql `
        mutation AssignmentTypeOnScheduleSwitch_removeAssignmentTypeFromScheduleMutation(
          $model: RemoveScheduleAssignmentTypeRequestInput!
        ) {
          removeAssignmentTypeFromSchedule(model: $model) {
            scheduleAssignmentTypeEdge {
              node {
                schedule {
                  ...EditAssignmentTypesOnScheduleModal_schedule
                  templates {
                    nodes {
                      ...ScheduleTemplateSidebar_scheduleTemplate
                    }
                  }
                }
              }
            }
          }
        }
      `);
    const [active, setActive] = useState(activeAssignmentTypeIds.includes(data.id));
    const [showConfirm, setShowConfirm] = useState(false);
    const update = useCallback(() => {
        const updateMutation = active ? removeAssignmentTypeFromSchedule : addAssignmentTypeToSchedule;
        updateMutation({
            onCompleted: () => {
                setActive(!active);
                if (onChange) {
                    onChange();
                }
            },
            variables: {
                model: {
                    assignmentTypeId: data.id,
                    scheduleId,
                },
            },
        });
    }, [active]);
    return (<>
      <Flexbox justify="space">
        <Text>{`${data.title}${resourceCount && resourceCount > 0 ? ` (${resourceCount})` : ''}`}</Text>
        <Switch checked={active} disabled={addAssignmentTypeToScheduleLoading || removeAssignmentTypeFromScheduleLoading} onChange={() => {
            if (active && resourceCount > 0) {
                setShowConfirm(true);
                return;
            }
            update();
        }}/>
      </Flexbox>
      {showConfirm && (<Confirm appearance="danger" headerText="Är du säker på att du vill inaktivera passtypen?" confirmButton={{
                onConfirm: () => {
                    setShowConfirm(false);
                    update();
                },
                text: 'Inaktivera',
            }} onCancel={() => {
                setShowConfirm(false);
            }} text={`Passtypen har ${resourceCount} kopplade användare/enheter som kommer tas bort i samband med inaktivering.`}/>)}
    </>);
};
export default AssignmentTypeOnScheduleSwitch;
