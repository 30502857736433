import React, { useState } from 'react';
import { string, object } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { H6, Text, Form, Field, MultiSelect } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
const validationRunningSchedulesSelectScheduleForm = object({
    scheduleId: string().required(),
});
export const RunningSchedulesSelectScheduleForm = (props) => {
    var _a;
    const { id, state, onSubmit, queryRef } = props;
    const { schedules } = useFragment(graphql `
      fragment RunningSchedulesSelectScheduleForm_query on Query {
        schedules(filterModel: { withCurrentUserPermissions: [EDIT_RUNNING] }) {
          nodes {
            id
            name
          }
        }
      }
    `, queryRef);
    const [data, setData] = useState({ scheduleId: '' });
    const { control, formState: { errors }, handleSubmit, } = useForm({
        resolver: yupResolver(validationRunningSchedulesSelectScheduleForm),
    });
    if (state === 'READ') {
        return <H6>{(_a = schedules.nodes.find((schedule) => schedule.id === data.scheduleId)) === null || _a === void 0 ? void 0 : _a.name}</H6>;
    }
    return (<Form id={id} style={{ marginTop: '1rem' }} onSubmit={handleSubmit((formData) => {
            setData(formData);
            onSubmit(formData);
        })}>
      <H6>Välj schema för bytet</H6>
      <Text>Utifrån schemat får du välja anställd och enhet att byta </Text>
      <Field label="Schema för byte">
        <Controller control={control} name="scheduleId" render={({ field }) => {
            var _a, _b;
            return (<MultiSelect error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.scheduleId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} stayOpenOnSelect={false} placeholder="Välj schema" options={schedules.nodes.map((schedule) => ({ label: schedule.name, value: schedule.id }))} onChange={(value) => (value ? field.onChange(value[0]) : undefined)} defaultValue={field.value}/>);
        }}/>
      </Field>
    </Form>);
};
export default RunningSchedulesSelectScheduleForm;
