/**
 * @generated SignedSource<<8a17a2aed3eacde1d779a2a21775dfe5>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "checkedWhere"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "refetch": {
            "connection": null,
            "fragmentPathInResult": [],
            "operation": require('./SchedulableResourceCheckedNumber_resourcesCheckedQuery.graphql')
        }
    },
    "name": "SchedulableResourceCheckedNumber_resourcesChecked",
    "selections": [
        {
            "alias": "checkedNumber",
            "args": [
                {
                    "kind": "Variable",
                    "name": "where",
                    "variableName": "checkedWhere"
                }
            ],
            "concreteType": "SchedulableEntityConnection",
            "kind": "LinkedField",
            "name": "schedulableEntities",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "e3026e57453c9a76a4b1c5b62444463d";
export default node;
