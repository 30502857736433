/**
 * @generated SignedSource<<bd87070e0a66df9240c072634d99c31e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "nameContains"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./RunningScheduleAssignmentType_scheduleAssignmentTypeQuery.graphql'),
                "identifierField": "id"
            }
        },
        "name": "RunningScheduleAssignmentType_scheduleAssignmentType",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentType",
                "kind": "LinkedField",
                "name": "assignmentType",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": "filteredResources",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "nameContains",
                        "variableName": "nameContains"
                    }
                ],
                "concreteType": "ScheduleAssignmentTypeResourceConnection",
                "kind": "LinkedField",
                "name": "resources",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleAssignmentTypeResourceEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleAssignmentTypeResource",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "schedulableEntity",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "color",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "displayName",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "Asset",
                                                                "kind": "LinkedField",
                                                                "name": "asset",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v0 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "type": "SchedulableAsset",
                                                        "abstractKey": null
                                                    }
                                                ],
                                                "type": "ISchedulableEntity",
                                                "abstractKey": "__isISchedulableEntity"
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentUserPermissions",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ScheduleAssignmentType",
        "abstractKey": null
    };
})();
node.hash = "b25f2de0f3bd107e5568c98b4ba6d337";
export default node;
