/**
 * @generated SignedSource<<a29b693a140e514abc0ceed60c0c901a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "SubmitDispatchPayload",
            "kind": "LinkedField",
            "name": "submitDispatch",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dispatch",
                    "kind": "LinkedField",
                    "name": "dispatch",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftVacancyNotifyModal_submitDispatchMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleShiftVacancyNotifyModal_submitDispatchMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "1013c95430b2ef62c87e7e9bb21a085e",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftVacancyNotifyModal_submitDispatchMutation",
            "operationKind": "mutation",
            "text": "mutation ScheduleShiftVacancyNotifyModal_submitDispatchMutation(\n  $model: SubmitDispatchInput!\n) {\n  submitDispatch(model: $model) {\n    dispatch {\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "8f343aba35f668ad13b73fa248aa9de2";
export default node;
