import React from 'react';
import { Card, CardContent, CardHeader, Text } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import ScheduleShiftClashList from '~/lists/ScheduleShiftClashList';
export const ScheduleShiftClashCard = (props) => {
    var _a;
    const { clashGroupRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftClashCard_clashGroup on ClashGroup {
        ...ScheduleShiftClashList_clashGroup
        clashes {
          assignedEntity {
            __typename
            ... on SchedulableUser {
              user {
                firstname
                lastname
              }
            }
            ... on SchedulableAsset {
              asset {
                name
              }
            }
          }
        }
      }
    `, clashGroupRef);
    const assignedEntity = (_a = data.clashes[0]) === null || _a === void 0 ? void 0 : _a.assignedEntity;
    if (assignedEntity == null) {
        return null;
    }
    return (<Card>
      <CardHeader>
        <Text bold>
          {assignedEntity.__typename === 'SchedulableUser' ? (<>
              {assignedEntity.user.firstname} {assignedEntity.user.lastname}
            </>) : assignedEntity.__typename === 'SchedulableAsset' ? (assignedEntity.asset.name) : null}
        </Text>
      </CardHeader>
      <CardContent>
        <ScheduleShiftClashList clashGroupRef={data}/>
      </CardContent>
    </Card>);
};
export default ScheduleShiftClashCard;
