import React, { useState, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { graphql, useMutation } from 'react-relay/hooks';
import { Avatar, getColorFromHex, Icon, Text, Popover, ColorPicker, Flexbox, } from '@sonika-se/ui-kit/components';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        rootSchedulableEntityColorRow: {
            marginTop: pxToRem(8),
            '&:not(:last-child)': {
                '& $textContainer': {
                    borderBottom: `${pxToRem(1)} solid ${theme.colors.divider}`,
                },
            },
        },
        textContainer: {
            lineHeight: pxToRem(32),
            paddingBottom: pxToRem(6),
            width: '100%',
        },
    };
});
export const SchedulableEntityColorRow = (props) => {
    const { name, color, id, type, onChange } = props;
    const intl = useIntl();
    const colorObject = getColorFromHex(color);
    const [showMenu, setShowMenu] = useState(false);
    const classes = useStyles();
    const menuRef = useRef(null);
    const [updateUserColor, updateUserColorLoading] = useMutation(graphql `
    mutation SchedulableEntityColorRowUpdateUserMutation($model: UpdateUserRequestInput!) {
      updateUser(model: $model) {
        userEdge {
          node {
            id
            color
          }
        }
      }
    }
  `);
    const [updateAssetColor, updateAssetColorLoading] = useMutation(graphql `
    mutation SchedulableEntityColorRowUpdateAssetMutation($model: UpdateAssetRequestInput!) {
      updateAsset(model: $model) {
        assetEdge {
          node {
            id
            color
          }
        }
      }
    }
  `);
    return (<Flexbox className={classes.rootSchedulableEntityColorRow} gap="medium">
      <Avatar text={name} size="small" color={(colorObject === null || colorObject === void 0 ? void 0 : colorObject.hex) || undefined}/>
      <Flexbox justify="space" align="center" className={classes.textContainer}>
        <Text>{name}</Text>
        <div onClick={() => {
            if (updateAssetColorLoading || updateUserColorLoading)
                return;
            setShowMenu(true);
        }} ref={menuRef} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <Text style={{ marginRight: 8 }}>
            {' '}
            {colorObject
            ? colorObject.name
            : intl.formatMessage({ id: 'general.misc.color.actions.select', defaultMessage: 'Välj färg' })}
          </Text>
          <Icon icon={faCircle} color={colorObject ? colorObject.hex : '#848484'}/>
        </div>
        <Popover anchorEl={showMenu ? menuRef.current : null} placement="bottom-start" onClose={() => setShowMenu(false)}>
          <div style={{ minWidth: '240px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '12px 12px 0px ' }}>
              <Text size="small" style={{ lineHeight: '10px' }}>
                <FormattedMessage defaultMessage="Välj färg för {title}" values={{ title: name }} id="general.misc.color.actions.select_for"/>
              </Text>
            </div>
            <div style={{ padding: 2, overflowY: 'auto', maxHeight: 234 }}>
              <ColorPicker setSelectedColor={(color) => {
            if (type === 'asset') {
                updateAssetColor({
                    variables: {
                        model: {
                            id,
                            color: color === null || color === void 0 ? void 0 : color.hex,
                        },
                    },
                    onCompleted: () => {
                        setShowMenu(false);
                        onChange(color);
                    },
                });
            }
            else {
                updateUserColor({
                    variables: {
                        model: {
                            id,
                            color: color === null || color === void 0 ? void 0 : color.hex,
                        },
                    },
                    onCompleted: () => {
                        setShowMenu(false);
                        onChange(color);
                    },
                });
            }
        }}/>
            </div>
          </div>
        </Popover>
      </Flexbox>
    </Flexbox>);
};
export default SchedulableEntityColorRow;
