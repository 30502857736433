import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { boolean, date, object, string } from 'yup';
import { Form, Input, Field, Switch, NewDateTimeInput, Button, Flexbox } from '@sonika-se/ui-kit/components';
export const scheduleGeneralInfoFormValidationSchema = object().shape({
    archived: boolean().nullable().notRequired(),
    inactivatedFrom: date().nullable().notRequired(),
    name: string().required(),
});
export const ScheduleGeneralInfoForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors, isValid }, handleSubmit, setValue, } = useForm({
        defaultValues,
        resolver: yupResolver(scheduleGeneralInfoFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
      <Field label="Namn">
        <Controller control={control} name="name" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
      </Field>
      <Field label="Dolt i översikten">
        <Controller control={control} name="archived" render={({ field }) => {
            var _a;
            return (<Switch defaultChecked={field.value} error={(_a = errors === null || errors === void 0 ? void 0 : errors.archived) === null || _a === void 0 ? void 0 : _a.message} disabled={disabled} onBlur={field.onBlur} onChange={(event) => field.onChange(event.target.checked)}/>);
        }}/>
      </Field>

      <Field label="Inaktiverat från">
        <Controller control={control} name="inactivatedFrom" render={({ field }) => {
            var _a, _b;
            return (<Flexbox direction="row" justify="space" gap="small">
              <div style={{ flex: 1 }}>
                <NewDateTimeInput header="" value={field.value} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.inactivatedFrom) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={(date) => field.onChange(date)} placeholder="Ej inaktiverat"/>
              </div>
              <Button disabled={!field.value} size="small" color="warning" onClick={() => setValue('inactivatedFrom', null)}>
                Rensa
              </Button>
            </Flexbox>);
        }}/>
      </Field>
    </Form>);
});
export default ScheduleGeneralInfoForm;
