import React, { Fragment, useMemo, useState } from 'react';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { Divider, Flexbox, H5, Icon, ModalToSheet, ModalToSheetHeader, ReadViewContainer, Text, } from '@sonika-se/ui-kit/components';
import { usePermissions, useViewport } from '@sonika-se/ui-kit/hooks';
import { createRandomId } from '@sonika-se/ui-kit/utils/misc';
import { isSameDay } from 'date-fns';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import PlaceholderText from '~/components/PlaceholderText';
import AddScheduleShiftBreak from './AddScheduleShiftBreak';
import ScheduleShiftBreakCard from './ScheduleShiftBreakCard';
import { toDate } from '@sonika-se/ui-kit/utils/time';
const ScheduleShiftEditBreaks = (props) => {
    const data = useFragment(graphql `
      fragment ScheduleShiftEditBreaks_scheduleShift on ScheduleShift {
        start
        id
        end
        breaks {
          end
          start
          type
        }
        schedule {
          currentUserPermissions
        }
      }
    `, props.scheduleShift);
    const hasPermissions = usePermissions();
    const [editMode, setEditMode] = useState(false);
    const [updateScheduleShiftBreak, updateScheduleShiftBreakLoading] = useMutation(graphql `
      mutation ScheduleShiftEditBreaksMutation($model: UpdateScheduleShiftTimeRequestInput!) {
        updateScheduleShiftTime(model: $model) {
          shiftEdge {
            node {
              id
              breaks {
                end
                start
                type
              }
            }
          }
          scheduleShiftHistoryTimeChangeEdge {
            node {
              id
              scheduleShift {
                ...ScheduleShift_shift
                ...ScheduleShiftHistory_scheduleShift
              }
              ...ScheduleShiftHistoryTimeChange_history
            }
          }
        }
      }
    `);
    const { size } = useViewport();
    const { breaks, start, end } = data;
    const sameDay = start && end && isSameDay(new Date(end), new Date(start));
    const content = (<>
      <Flexbox direction="column">
        {breaks === null || breaks === void 0 ? void 0 : breaks.map((breakItem, index) => {
            return (<Fragment key={createRandomId()}>
              <div style={{ margin: '.75rem 1rem' }}>
                <ScheduleShiftBreakCard breakItem={breakItem} sameDay={sameDay} editable isLoading={updateScheduleShiftBreakLoading} onDelete={() => {
                    const newBreaks = breaks.filter((item, breakIndex) => index !== breakIndex);
                    updateScheduleShiftBreak({
                        variables: {
                            model: {
                                shiftId: data === null || data === void 0 ? void 0 : data.id,
                                breaks: newBreaks,
                            },
                        },
                        optimisticResponse: {
                            updateScheduleShiftTime: {
                                shiftEdge: {
                                    node: {
                                        id: data.id,
                                        breaks: newBreaks || null,
                                    },
                                },
                            },
                        },
                    });
                }} onUpdate={(values) => {
                    const newBreaks = breaks.map((item, breakIndex) => index === breakIndex
                        ? {
                            start: values.start,
                            end: values.end,
                            type: values.type,
                        }
                        : item);
                    updateScheduleShiftBreak({
                        variables: {
                            model: {
                                shiftId: data === null || data === void 0 ? void 0 : data.id,
                                breaks: newBreaks,
                            },
                        },
                        optimisticResponse: {
                            updateScheduleShiftTime: {
                                shiftEdge: {
                                    node: {
                                        id: data.id,
                                        breaks: newBreaks || null,
                                    },
                                },
                            },
                        },
                    });
                }}/>
              </div>
              <Divider />
            </Fragment>);
        })}
      </Flexbox>
      <AddScheduleShiftBreak shiftStart={toDate(start)} defaultOpen={breaks === null} sameDay={sameDay} shiftId={data === null || data === void 0 ? void 0 : data.id} breaks={(data === null || data === void 0 ? void 0 : data.breaks) || []}/>
    </>);
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || data.schedule.currentUserPermissions.includes('EDIT_RUNNING'), [data, hasPermissions]);
    return (<Flexbox>
      <ReadViewContainer disabled={!canEditRunning}>
        <Flexbox gap="small" direction="column" onClick={() => {
            if (canEditRunning) {
                setEditMode(true);
            }
        }}>
          {breaks === null || breaks === void 0 ? void 0 : breaks.map((breakItem) => {
            return (<Fragment key={createRandomId()}>
                <ScheduleShiftBreakCard breakItem={breakItem} sameDay={sameDay}/>
              </Fragment>);
        })}
          {(breaks === null || (breaks === null || breaks === void 0 ? void 0 : breaks.length) === 0) && (<Flexbox gap="xsmall" align="center" style={{ color: '#848484' }}>
              {canEditRunning ? (<>
                  <Icon icon={faPencil}/>
                  <Text>Lägg till raster</Text>
                </>) : (<PlaceholderText>Ingen beskrivning</PlaceholderText>)}
            </Flexbox>)}
        </Flexbox>
      </ReadViewContainer>
      {editMode && (<ModalToSheet onClose={() => setEditMode(false)} size="small">
          <ModalToSheetHeader onClose={() => setEditMode(false)} closeButtonLabel="Stäng">
            <H5>Redigera raster</H5>
          </ModalToSheetHeader>
          {content}
        </ModalToSheet>)}
    </Flexbox>);
};
export default ScheduleShiftEditBreaks;
