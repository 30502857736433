import React, { Suspense } from 'react';
import { Flexbox, Text, ModalToSheetSkeleton, Chip } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { useQueryLoader } from 'react-relay/hooks';
import { FormattedMessage } from 'react-intl';
import User from '~/components/User/User';
import EditPlannedAbsenceModal, { editPlannedAbsenceModalQuery } from '../EditPlannedAbsenceModal';
import Asset from '~/components/Asset';
import { useGetPlannedAbsences } from '~/hooks/useGetPlannedAbsences';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        userContainer: {
            padding: '1rem',
        },
        noData: {
            background: theme.colors.background.primary,
            padding: '1rem',
            textAlign: 'center',
            fontWeight: 'bold',
        },
        eventCard: {
            padding: `${pxToRem(12)}`,
            borderRadius: pxToRem(4),
            cursor: 'pointer',
        },
    };
});
const colors = {
    danger: '#FD544F',
    success: '#57BE56',
};
const lightColors = {
    danger: '#FFEEED',
    success: '#E6F5E6',
};
const AbsenceCalendarGridRowMobile = (props) => {
    const { plannedAbsences, id } = useGetPlannedAbsences(props.queryRef);
    const connectedAbsences = plannedAbsences === null || plannedAbsences === void 0 ? void 0 : plannedAbsences.edges;
    const classes = useStyles();
    const [editPlannedAbsenceModalQueryRef, loadEditPlannedAbsenceModalQuery, disposeEditPlannedAbsenceModalQuery] = useQueryLoader(editPlannedAbsenceModalQuery);
    if ((connectedAbsences === null || connectedAbsences === void 0 ? void 0 : connectedAbsences.length) === 0) {
        return (<div className={classes.noData}>
        <FormattedMessage id="AbsenceCalendarGridContentMobile.no_events" defaultMessage="Inga händelser"/>
      </div>);
    }
    return (<>
      {connectedAbsences === null || connectedAbsences === void 0 ? void 0 : connectedAbsences.map(({ node: absence }) => {
            var _a, _b, _c, _d;
            return (<div className={classes.eventCard} style={{
                    background: ((_a = absence.reasonEvent) === null || _a === void 0 ? void 0 : _a.color) ? `${(_b = absence.reasonEvent) === null || _b === void 0 ? void 0 : _b.color}33` : lightColors['danger'],
                    borderLeft: `.25rem solid ${((_c = absence.reasonEvent) === null || _c === void 0 ? void 0 : _c.color) || colors['danger']}`,
                }} key={absence.id} role="button" onClick={() => {
                    loadEditPlannedAbsenceModalQuery({ id: absence.id });
                }}>
            <Flexbox align="center" justify="space">
              <Flexbox direction="column" gap="small">
                <Text bold>
                  {`${format(new Date(absence === null || absence === void 0 ? void 0 : absence.start), 'dd/M HH:mm', { locale })} - ${format(new Date(absence === null || absence === void 0 ? void 0 : absence.end), 'dd/M HH:mm', { locale })}`}
                </Text>
                <Text>{absence.assignmentType.title}</Text>
              </Flexbox>
              <Chip color={(_d = absence.reasonEvent) === null || _d === void 0 ? void 0 : _d.color} appearance="danger" text={absence.reasonEvent.name}/>
            </Flexbox>
          </div>);
        })}
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {!!editPlannedAbsenceModalQueryRef && (<EditPlannedAbsenceModal preloadedQuery={editPlannedAbsenceModalQueryRef} onClose={() => {
                disposeEditPlannedAbsenceModalQuery();
            }}/>)}
      </Suspense>
    </>);
};
export const AbsenceCalendarGridContentMobile = (props) => {
    var _a, _b;
    const { data } = props;
    const classes = useStyles();
    return (<Flexbox direction="column" style={{ background: '#fff' }}>
      {(_b = (_a = data === null || data === void 0 ? void 0 : data.schedulableEntities) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(({ node }) => {
            return (<Flexbox direction="column" gap="small" key={node.id} className={classes.userContainer}>
            <Flexbox gap="small" align="center">
              {!!node.user ? <User parent={node.user} size="xsmall"/> : <Asset parent={node.asset} size="xsmall"/>}
              <Text>{node.displayName}</Text>
            </Flexbox>
            <AbsenceCalendarGridRowMobile queryRef={node}/>
          </Flexbox>);
        })}
    </Flexbox>);
};
export default AbsenceCalendarGridContentMobile;
