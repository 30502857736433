/**
 * @generated SignedSource<<590c29c9286c7e654532757ff08893d9>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        "schedulableEntities"
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "after"
            },
            {
                "defaultValue": 100,
                "kind": "LocalArgument",
                "name": "first"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "fromDate"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "model"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "toDate"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "where"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "whereAbsences"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "first",
                    "cursor": "after",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "first",
                        "cursor": "after"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [],
                "operation": require('./AbsenceCalendarGrid_schedulableEntitiesQuery.graphql')
            }
        },
        "name": "AbsenceCalendarGridQuery",
        "selections": [
            {
                "alias": "schedulableEntities",
                "args": null,
                "concreteType": "SchedulableEntityConnection",
                "kind": "LinkedField",
                "name": "__AbsenceCalendarGrid_schedulableEntities_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SchedulableEntityEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "id",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "displayName",
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "ISchedulableEntity",
                                        "abstractKey": "__isISchedulableEntity"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "User",
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "args": null,
                                                        "kind": "FragmentSpread",
                                                        "name": "User"
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "SchedulableUser",
                                        "abstractKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Asset",
                                                "kind": "LinkedField",
                                                "name": "asset",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "args": null,
                                                        "kind": "FragmentSpread",
                                                        "name": "Asset"
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "SchedulableAsset",
                                        "abstractKey": null
                                    },
                                    {
                                        "args": [
                                            {
                                                "kind": "Variable",
                                                "name": "fromDate",
                                                "variableName": "fromDate"
                                            },
                                            {
                                                "kind": "Variable",
                                                "name": "toDate",
                                                "variableName": "toDate"
                                            },
                                            {
                                                "kind": "Variable",
                                                "name": "where",
                                                "variableName": "whereAbsences"
                                            }
                                        ],
                                        "kind": "FragmentSpread",
                                        "name": "useGetPlannedAbsences_plannedAbsences"
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "1908f717695fd7f5c54e70212c0f353b";
export default node;
