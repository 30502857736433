import React, { Suspense, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate, useParams } from 'react-router';
import { BasicPage, Flexbox, Tabs, Text, H3, ContextMenuItem, ContextMenu, ModalToSheetSkeleton, } from '@sonika-se/ui-kit/components';
import { graphql } from 'relay-runtime';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { useIntl } from 'react-intl';
import ScheduleShiftContent from './components/ScheduleShiftContent';
import DeleteScheduleShiftConfirm from './components/ScheduleShiftInformationTab/components/DeleteScheduleShiftConfirm';
import displayScheduleShiftStartEndDates from '~/utils/displayScheduleShiftStartEndDates';
import ChangeShiftTemplateTimeModal from '~/modals/ChangeShiftTemplateTimeModal';
export const scheduleShiftPageQuery = graphql `
  query ScheduleShiftPageQuery($id: ID) {
    scheduleShift(id: $id) {
      id
      schedule {
        name
        id
      }
      assignmentTypeId
      assignmentType {
        title
      }
      assignedEntity {
        ... on SchedulableUser {
          user {
            id
          }
        }
      }
      schedule {
        currentUserPermissions
      }
      ...ScheduleShiftContent_ScheduleShift
      isVacant
      start
      end
      status
      originatingScheduleTemplateShift {
        id
      }
    }
    currentUser {
      me {
        id
        schedulableEntity {
          id
          resourceInScheduleAssignmentTypes {
            edges {
              node {
                scheduleAssignmentType {
                  id
                  assignmentType {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const useStyles = createUseStyles((theme) => ({
    backLink: {
        padding: '2rem 0rem 0rem',
    },
}));
const ScheduleShiftPage = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const { id } = useParams();
    const data = useLazyLoadQuery(scheduleShiftPageQuery, { id });
    const [selectedTab, setSelectedTab] = useState('information');
    const classes = useStyles();
    const intl = useIntl();
    const navigate = useNavigate();
    const [showDeleteShiftConfirm, setShowDeleteShiftConfirm] = useState(false);
    const [showChangeShiftTemplateTime, setShowChangeShiftTemplateTime] = useState(false);
    const hasPermissions = usePermissions();
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || data.scheduleShift.schedule.currentUserPermissions.includes('EDIT_RUNNING'), [data, hasPermissions]);
    const canRequestShift = ((_a = data === null || data === void 0 ? void 0 : data.currentUser.me) === null || _a === void 0 ? void 0 : _a.schedulableEntity) &&
        !!((_e = (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.currentUser.me) === null || _b === void 0 ? void 0 : _b.schedulableEntity) === null || _c === void 0 ? void 0 : _c.resourceInScheduleAssignmentTypes) === null || _d === void 0 ? void 0 : _d.edges) === null || _e === void 0 ? void 0 : _e.find(({ node: item }) => { var _a; return (item === null || item === void 0 ? void 0 : item.scheduleAssignmentType.assignmentType.id) === ((_a = data === null || data === void 0 ? void 0 : data.scheduleShift) === null || _a === void 0 ? void 0 : _a.assignmentTypeId); }));
    const goBack = () => {
        if ((document === null || document === void 0 ? void 0 : document.referrer.includes('localhost')) || document.referrer.includes('sonika')) {
            window.history.back();
        }
        else {
            navigate('/running-schedules');
        }
    };
    return (<BasicPage width="large">
      <Flexbox justify="space" align="center" style={{ paddingTop: '3rem' }}>
        <div>
          <Flexbox align="center" gap="medium">
            <Text size="large">
              {data.scheduleShift.schedule.name} - {(_g = (_f = data === null || data === void 0 ? void 0 : data.scheduleShift) === null || _f === void 0 ? void 0 : _f.assignmentType) === null || _g === void 0 ? void 0 : _g.title}
            </Text>{' '}
          </Flexbox>
          <H3>{displayScheduleShiftStartEndDates(data.scheduleShift.start, data.scheduleShift.end)}</H3>
        </div>
        {canEditRunning && (<ContextMenu>
            {data.scheduleShift.originatingScheduleTemplateShift != null && (<ContextMenuItem onClick={() => {
                    setShowChangeShiftTemplateTime(true);
                }} label="Ändra malltid"/>)}
            <ContextMenuItem onClick={() => {
                setShowDeleteShiftConfirm(true);
            }} label={intl.formatMessage({ id: 'general.actions.delete', defaultMessage: 'Ta bort' })}/>
          </ContextMenu>)}
      </Flexbox>
      {(canEditRunning || data.currentUser.me.id === ((_k = (_j = (_h = data === null || data === void 0 ? void 0 : data.scheduleShift) === null || _h === void 0 ? void 0 : _h.assignedEntity) === null || _j === void 0 ? void 0 : _j.user) === null || _k === void 0 ? void 0 : _k.id)) && (<Tabs divider tabs={[
                {
                    label: 'Information',
                    value: 'information',
                    key: 'information',
                },
                {
                    label: 'Begär förändring',
                    value: 'request_change',
                    key: 'request_change',
                },
                ...(canEditRunning
                    ? [
                        { label: 'Anteckningar', key: 'notes', value: 'notes' },
                        { label: 'Historik', value: 'history', key: 'history' },
                    ]
                    : []),
            ]} onChange={(value) => {
                setSelectedTab(value);
            }}/>)}
      <ScheduleShiftContent schedulableEntityId={(_m = (_l = data === null || data === void 0 ? void 0 : data.currentUser.me) === null || _l === void 0 ? void 0 : _l.schedulableEntity) === null || _m === void 0 ? void 0 : _m.id} selectedTab={selectedTab} scheduleShift={data === null || data === void 0 ? void 0 : data.scheduleShift} canRequestShift={canRequestShift}/>
      <DeleteScheduleShiftConfirm scheduleShiftId={data.scheduleShift.id} scheduleId={data.scheduleShift.schedule.id} open={showDeleteShiftConfirm} onClose={(deleted) => {
            setShowDeleteShiftConfirm(false);
            if (!!deleted) {
                goBack();
            }
        }}/>
      {showChangeShiftTemplateTime && (<Suspense fallback={<ModalToSheetSkeleton type="loader"/>}>
          <ChangeShiftTemplateTimeModal onClose={() => {
                console.log('tid uppdaterad');
                setShowChangeShiftTemplateTime(false);
            }} onDismiss={() => {
                setShowChangeShiftTemplateTime(false);
            }} shiftTemplateId={(_o = data.scheduleShift.originatingScheduleTemplateShift) === null || _o === void 0 ? void 0 : _o.id}/>
        </Suspense>)}
    </BasicPage>);
};
export default ScheduleShiftPage;
