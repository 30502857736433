/**
 * @generated SignedSource<<64c380031f137f22cfbc5dd7386f6fd1>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ScheduleTemplateShiftClashGroup_scheduleTemplateShiftClashGroup",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTemplateShift",
                "kind": "LinkedField",
                "name": "clashes",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "assignedEntity",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Asset",
                                        "kind": "LinkedField",
                                        "name": "asset",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "name",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "type": "SchedulableAsset",
                                "abstractKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "user",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "args": null,
                                                "kind": "FragmentSpread",
                                                "name": "User"
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "firstname",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "lastname",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "type": "SchedulableUser",
                                "abstractKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ScheduleTemplateShiftClash_scheduleTemplateShift"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ScheduleTemplateShiftClashGroup",
        "abstractKey": null
    };
})();
node.hash = "f33b2629d42974e222d5077c4bf850d0";
export default node;
