/**
 * @generated SignedSource<<a87c6d876dc977b1f19f3c1c4facef16>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "UpdateScheduleShiftTimePayload",
            "kind": "LinkedField",
            "name": "updateScheduleTemplateShiftTimes",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleShift",
                    "kind": "LinkedField",
                    "name": "shift",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ChangeShiftTemplateTimeModal_updateMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ChangeShiftTemplateTimeModal_updateMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "1e62fbbafb6e677db4279f362d9b9029",
            "id": null,
            "metadata": {},
            "name": "ChangeShiftTemplateTimeModal_updateMutation",
            "operationKind": "mutation",
            "text": "mutation ChangeShiftTemplateTimeModal_updateMutation(\n  $model: UpdateScheduleTemplateShiftTimesInput!\n) {\n  updateScheduleTemplateShiftTimes(model: $model) {\n    shift {\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "ff78401f8df6c24dc260906408528844";
export default node;
