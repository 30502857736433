import React, { Children } from 'react';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles((theme) => ({
    root: {
        borderRadius: theme.helpers.pxToRem(4),
        border: `${theme.helpers.pxToRem(1)} solid ${theme.colors.foreground.separation}`,
        display: 'flex',
    },
    item: {
        backgroundColor: theme.colors.foreground.separation,
        width: theme.helpers.pxToRem(1),
    },
}));
export const KpiBar = (props) => {
    const { children } = props;
    const classes = useStyles();
    return (<div className={classes.root}>
      {Children.map(children, (child, index) => (<>
          {index > 0 && <div className={classes.item}></div>}
          {child}
        </>))}
    </div>);
};
export default KpiBar;
