/**
 * @generated SignedSource<<34200d60eed3cd2f76d28f7968c41347>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleTemplateEditNavbar_scheduleTemplate",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "order",
                    "value": {
                        "order": "ASC"
                    }
                }
            ],
            "concreteType": "ScheduleTemplatePeriodConnection",
            "kind": "LinkedField",
            "name": "periods",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SortPeriodsModal_scheduleTemplatePeriodConnection"
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                }
            ],
            "storageKey": "periods(order:{\"order\":\"ASC\"})"
        }
    ],
    "type": "ScheduleTemplate",
    "abstractKey": null
};
node.hash = "dbe7de67dfdecde6fe41779a4b861c2a";
export default node;
