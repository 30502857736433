import React from 'react';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
export const ShiftHistoryEvent = (props) => {
    const timestamp = new Date(props.timestamp);
    const { children } = props;
    return (<Flexbox direction="column" gap="xsmall">
      <p style={{
            color: '#6A6A6A',
            fontSize: '0.875rem',
        }}>
        {format(timestamp, 'yyyy-MM-dd HH:mm', { locale })}
      </p>
      <div>{children}</div>
    </Flexbox>);
};
