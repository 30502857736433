import React, { useCallback, useMemo, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import AssignmentTypesSelectForm from '~/forms/AssignmentTypesSelectForm';
export const scheduleAssignmentTypesUpdateModalQuery = graphql `
  query ScheduleAssignmentTypesUpdateModalQuery($scheduleId: ID!) {
    schedule(id: $scheduleId) {
      id
      assignmentTypes {
        nodes {
          assignmentTypeId
        }
      }
    }
  }
`;
export const ScheduleAssignmentTypesUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { schedule } = usePreloadedQuery(scheduleAssignmentTypesUpdateModalQuery, preloadedQuery);
    const assignmentTypeIds = useMemo(() => schedule.assignmentTypes.nodes.map(({ assignmentTypeId }) => assignmentTypeId), [schedule]);
    const [setAssignmentTypesOnSchedule, setAssignmentTypesOnScheduleLoading] = useMutation(graphql `
        mutation ScheduleAssignmentTypesUpdateModal_setAssignmentTypesOnScheduleMutation(
          $model: SetAssignmentTypesOnScheduleRequestInput!
        ) {
          setAssignmentTypesOnSchedule(model: $model) {
            scheduleEdge {
              node {
                ...ScheduleAssignmentTypesPanel_schedule
              }
            }
          }
        }
      `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        setAssignmentTypesOnSchedule({
            onCompleted: () => {
                onClose();
            },
            variables: {
                model: {
                    assignmentTypeIds: form.assignmentTypeIds,
                    scheduleId: schedule.id,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={setAssignmentTypesOnScheduleLoading} title="Ändra roller">
      <AssignmentTypesSelectForm defaultValues={{
            assignmentTypeIds,
        }} disabled={setAssignmentTypesOnScheduleLoading} ref={formRef} onSubmit={handleSubmit} where={{
            or: [
                {
                    disabledDate: {
                        eq: null,
                    },
                },
                {
                    id: {
                        in: assignmentTypeIds,
                    },
                },
            ],
        }}/>
    </FormModal>);
};
export default ScheduleAssignmentTypesUpdateModal;
