import React, { useContext, useMemo, useRef, useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { compareAsc } from 'date-fns';
import { Label, Form, NewInlineDateTimeInput, Flexbox, Button, InlineTextArea, Accordion, AccordionItem, AppContext, Placeholder, Notification, } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import PlaceholderText from '~/components/PlaceholderText';
import ScheduleShiftChangeRequest from '~/components/ScheduleShiftChangeRequest';
import { faInfo } from '@fortawesome/pro-regular-svg-icons';
export const ScheduleShiftChangeTab = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const data = useFragment(graphql `
      fragment ScheduleShiftChangeTab_shift on ScheduleShift {
        id
        end
        schedule {
          currentUserPermissions
        }
        start
        assignedEntity {
          ... on SchedulableUser {
            user {
              id
            }
          }
        }
        changeRequests(after: null, first: null) @connection(key: "ScheduleShiftChangeTab_changeRequests") {
          edges {
            node {
              id
              status
              requestedByUserId
              ...ScheduleShiftChangeRequest_shift
            }
          }
        }
      }
    `, props.scheduleShift);
    const { currentUser } = useContext(AppContext);
    const intl = useIntl();
    const [createChangeRequest, createChangeRequestLoading] = useMutation(graphql `
      mutation ScheduleShiftChangeTabCreateChangeMutation($model: CreateScheduleShiftChangeRequestRequestInput!) {
        createScheduleShiftChangeRequest(model: $model) {
          changeRequestEdge {
            node {
              id
              status
              requestedByUserId
              ...ScheduleShiftChangeRequest_shift
            }
          }
        }
      }
    `);
    const [end, setEnd] = useState(data.end);
    const [start, setStart] = useState(data.start);
    const [comment, setComment] = useState('');
    const { control, handleSubmit, reset } = useForm({
        defaultValues: { comment },
        mode: 'onBlur',
    });
    const hasPermissions = usePermissions();
    const hasScheduleEditRunningPermission = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || data.schedule.currentUserPermissions.includes('EDIT_RUNNING'), [data, hasPermissions]);
    const openRequests = (_b = (_a = data.changeRequests) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.filter((item) => {
        var _a;
        return item.node.status === 'REQUESTED' &&
            (((_a = item.node) === null || _a === void 0 ? void 0 : _a.requestedByUserId) === currentUser.id || hasScheduleEditRunningPermission);
    });
    const closedRequests = (_d = (_c = data.changeRequests) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.filter((item) => {
        var _a;
        return item.node.status !== 'REQUESTED' ||
            (((_a = item.node) === null || _a === void 0 ? void 0 : _a.requestedByUserId) !== currentUser.id && !hasScheduleEditRunningPermission);
    });
    const textAreaRef = useRef(null);
    return (<Flexbox direction="column" gap="large">
      {!!(closedRequests === null || closedRequests === void 0 ? void 0 : closedRequests.length) && (<div>
          <Accordion>
            <AccordionItem title={intl.formatMessage({
                defaultMessage: 'Tidigare begärda förändringar',
                id: 'ScheduleShiftChangeTab.earlier_requests',
            })} size="medium" defaultOpen={!(openRequests === null || openRequests === void 0 ? void 0 : openRequests.length) && currentUser.id !== ((_f = (_e = data.assignedEntity) === null || _e === void 0 ? void 0 : _e.user) === null || _f === void 0 ? void 0 : _f.id)}>
              <Flexbox direction="column" gap="medium" style={{ padding: '0 1.5rem' }}>
                {closedRequests.map(({ node: request }) => (<ScheduleShiftChangeRequest scheduleShiftChangeRequest={request} key={request.id}/>))}
              </Flexbox>
            </AccordionItem>
          </Accordion>
        </div>)}

      {openRequests.map(({ node: request }) => (<ScheduleShiftChangeRequest scheduleShiftChangeRequest={request} key={request.id}/>))}
      {!(openRequests === null || openRequests === void 0 ? void 0 : openRequests.length) && currentUser.id !== ((_h = (_g = data.assignedEntity) === null || _g === void 0 ? void 0 : _g.user) === null || _h === void 0 ? void 0 : _h.id) && (<Placeholder text={intl.formatMessage({
                defaultMessage: 'Det finns inga förändringar att godkänna på det här passet',
                id: 'ScheduleShiftChangeTab.no_changes',
            })}/>)}
      {!(openRequests === null || openRequests === void 0 ? void 0 : openRequests.length) && currentUser.id === ((_k = (_j = data.assignedEntity) === null || _j === void 0 ? void 0 : _j.user) === null || _k === void 0 ? void 0 : _k.id) && (<>
          <Notification shadow={false} icon={faInfo} title="Ange den totala tiden du arbetade. Påverkas raster så anger du det i kommentaren." color="primary"/>
          <Form columns="auto 1fr">
            <Label htmlFor="start" text={intl.formatMessage({
                id: 'general.misc.schedule_shift_change_request.fields.start.label',
                defaultMessage: 'Började',
            })}/>
            <NewInlineDateTimeInput displayActionButtons header={intl.formatMessage({
                id: 'ScheduleShiftChangeTab.chooseTimeHeader',
                defaultMessage: 'Välj ny tid',
            })} defaultValue={new Date(start)} onChange={(value) => setStart(value)}/>
            <Label htmlFor="end" text={intl.formatMessage({
                id: 'general.misc.schedule_shift_change_request.fields.end.label',
                defaultMessage: 'Slutade',
            })}/>
            <NewInlineDateTimeInput defaultValue={new Date(end)} displayActionButtons header={intl.formatMessage({
                id: 'ScheduleShiftChangeTab.chooseTimeHeader',
                defaultMessage: 'Välj ny tid',
            })} onChange={(value) => setEnd(value)}/>
            <Label htmlFor="comment" text={intl.formatMessage({
                id: 'general.misc.schedule_shift_change_request.fields.comment.label',
                defaultMessage: 'Kommentar',
            })}/>
            <Controller control={control} name="comment" render={({ field }) => {
                var _a, _b;
                return (<InlineTextArea defaultValue={field.value} onChange={() => {
                        field.onChange(textAreaRef.current.value);
                        handleSubmit((formData) => {
                            reset(formData);
                            setComment(formData.comment);
                        })();
                    }} inputRef={textAreaRef} view={!!((_b = (_a = data.changeRequests) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length) && !comment
                        ? () => (<PlaceholderText>
                            <FormattedMessage defaultMessage="Ingen {commentLabel}" id="ScheduleShiftChangeTab.fields.comment.no_data" values={{
                                commentLabel: intl.formatMessage({
                                    id: 'general.misc.schedule_shift_change_request.fields.comment.label',
                                    defaultMessage: 'Kommentar',
                                }),
                            }}/>
                          </PlaceholderText>)
                        : undefined} placeholder={intl.formatMessage({
                        defaultMessage: 'Beskriv anledning till begäran och om t.ex. raster påverkats',
                        id: 'ScheduleShiftChangeTab.fields.comment.placeholder',
                    })}/>);
            }}/>
          </Form>
          <Button disabled={createChangeRequestLoading ||
                (comment === '' &&
                    compareAsc(new Date(start), new Date(data.start)) === 0 &&
                    compareAsc(new Date(end), new Date(data.end)) === 0)} style={{ marginTop: '2.5rem' }} onClick={() => {
                createChangeRequest({
                    variables: {
                        model: {
                            scheduleShiftId: data.id,
                            comment,
                            start,
                            end,
                        },
                    },
                    configs: [
                        {
                            parentID: data.id,
                            connectionInfo: [
                                {
                                    key: 'ScheduleShiftChangeTab_changeRequests',
                                    rangeBehavior: 'prepend',
                                },
                            ],
                            edgeName: 'changeRequestEdge',
                            type: 'RANGE_ADD',
                        },
                    ],
                });
            }}>
            <FormattedMessage defaultMessage="Skicka" id="general.actions.send"/>
          </Button>
        </>)}
    </Flexbox>);
};
export default ScheduleShiftChangeTab;
