import React, { Suspense, useState } from 'react';
import { Select, SelectDropdown } from '@sonika-se/ui-kit/components';
export const EditableListSearch = (props) => {
    const { options, placeholder, selectedItems } = props;
    const [query, setQuery] = useState('');
    return (<Select disabled={false} isLoading={false} multiple={false} onSearch={(value) => {
            setQuery(value);
        }} optionsList={<Suspense fallback={<SelectDropdown searchString="" isLoading setSelected={() => {
                    //
                }} selectedItems={[]}/>}>
          {options(query)}
        </Suspense>} placeholder={placeholder} searchString={query} selectedItems={selectedItems} stayOpenOnSelect={false}/>);
};
export default EditableListSearch;
