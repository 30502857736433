/**
 * @generated SignedSource<<f43658f90743bb89150c5c34a6222f6b>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "entitiesFilter"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "schedulesFilter"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "typeFilter"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "entitiesFilter",
            "variableName": "entitiesFilter"
        },
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        },
        {
            "kind": "Variable",
            "name": "schedulesFilter",
            "variableName": "schedulesFilter"
        },
        {
            "kind": "Variable",
            "name": "typeFilter",
            "variableName": "typeFilter"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "HOUR"
        }
    ], v4 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        }
    ], v5 = {
        "alias": null,
        "args": (v3 /*: any*/),
        "concreteType": "TimeSpanUnitValue",
        "kind": "LinkedField",
        "name": "absenceDurationWithoutUnpaidBreaks",
        "plural": false,
        "selections": (v4 /*: any*/),
        "storageKey": "absenceDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesInformation_scheduleTimesQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "RunningSchedulesInformation_scheduleTimes"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RunningSchedulesInformation_scheduleTimesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleTimesPayload",
                    "kind": "LinkedField",
                    "name": "scheduleTimes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                            "kind": "LinkedField",
                            "name": "timesByEntityAndAssignmentType",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "assignmentType",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "schedulableEntity",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "color",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "displayName",
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "ISchedulableEntity",
                                            "abstractKey": "__isISchedulableEntity"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v2 /*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "userId",
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "SchedulableUser",
                                            "abstractKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleTimeAbsenceByReason",
                                    "kind": "LinkedField",
                                    "name": "absencesByReason",
                                    "plural": true,
                                    "selections": [
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Event",
                                            "kind": "LinkedField",
                                            "name": "reasonEvent",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v3 /*: any*/),
                                    "concreteType": "TimeSpanUnitValue",
                                    "kind": "LinkedField",
                                    "name": "assignedDurationWithoutUnpaidBreaks",
                                    "plural": false,
                                    "selections": (v4 /*: any*/),
                                    "storageKey": "assignedDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "36c4b5860edb160bf77944edb9c0ef64",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesInformation_scheduleTimesQuery",
            "operationKind": "query",
            "text": "query RunningSchedulesInformation_scheduleTimesQuery(\n  $entitiesFilter: ScheduleTemplateShiftFilterRequestInput\n  $model: GetRunningScheduleRequestInput!\n  $schedulesFilter: [ID!]\n  $typeFilter: SchedulableEntityTypeFilterRequestInput\n) {\n  ...RunningSchedulesInformation_scheduleTimes_TwlYH\n}\n\nfragment RunningSchedulesInformationTableRow_scheduleTime on ScheduleTimeByEntityAndAssignmentType {\n  absenceDurationWithoutUnpaidBreaks(unit: HOUR) {\n    unit\n    value\n  }\n  absencesByReason {\n    absenceDurationWithoutUnpaidBreaks(unit: HOUR) {\n      unit\n      value\n    }\n    reasonEvent {\n      id\n      name\n    }\n  }\n  assignedDurationWithoutUnpaidBreaks(unit: HOUR) {\n    unit\n    value\n  }\n  schedulableEntity {\n    __typename\n    ... on ISchedulableEntity {\n      __isISchedulableEntity: __typename\n      id\n      color\n      displayName\n    }\n    ... on SchedulableUser {\n      userId\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment RunningSchedulesInformationTable_scheduleTimes on ScheduleTimesPayload {\n  timesByEntityAndAssignmentType {\n    assignmentType {\n      id\n      title\n    }\n    schedulableEntity {\n      __typename\n      ... on ISchedulableEntity {\n        __isISchedulableEntity: __typename\n        id\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    ...RunningSchedulesInformationTableRow_scheduleTime\n  }\n}\n\nfragment RunningSchedulesInformation_scheduleTimes_TwlYH on Query {\n  scheduleTimes(model: $model, typeFilter: $typeFilter, entitiesFilter: $entitiesFilter, schedulesFilter: $schedulesFilter) {\n    timesByEntityAndAssignmentType {\n      assignmentType {\n        id\n        title\n      }\n    }\n    ...RunningSchedulesInformationTable_scheduleTimes\n  }\n}\n"
        }
    };
})();
node.hash = "20432087f50cfd99ea3927ddc2c5a99e";
export default node;
