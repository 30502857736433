/**
 * @generated SignedSource<<d438ad54709f14de72040fb9a79762f2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        (v0 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "App_currentUserQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "App_currentUser"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "App_currentUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "impersonatedBy",
                            "plural": false,
                            "selections": (v1 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "me",
                            "plural": false,
                            "selections": (v1 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "permissions",
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "352c65be6df840cc052211389b9bf0d9",
            "id": null,
            "metadata": {},
            "name": "App_currentUserQuery",
            "operationKind": "query",
            "text": "query App_currentUserQuery {\n  ...App_currentUser\n}\n\nfragment App_currentUser on Query {\n  currentUser {\n    impersonatedBy {\n      id\n      firstname\n      lastname\n    }\n    me {\n      id\n      firstname\n      lastname\n    }\n    permissions\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "79551e3600b7cb4b8cb1b9852406a100";
export default node;
