/**
 * @generated SignedSource<<6f1cf0b23e3917a10136e704d84a62c9>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "where",
                    "variableName": "where"
                }
            ],
            "concreteType": "AssignmentTypeConnection",
            "kind": "LinkedField",
            "name": "assignmentTypes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentType",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignmentTypesSelectFormQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignmentTypesSelectFormQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "6de862fd9692ebd89618b0b54b2c485c",
            "id": null,
            "metadata": {},
            "name": "AssignmentTypesSelectFormQuery",
            "operationKind": "query",
            "text": "query AssignmentTypesSelectFormQuery(\n  $where: AssignmentTypeFilterInput\n) {\n  assignmentTypes(where: $where) {\n    nodes {\n      id\n      title\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "1c3db7d9813702eaf521e065438eabfd";
export default node;
