import React from 'react';
import { Chip, ContextMenu, ContextMenuItem, Flexbox, Text } from '@sonika-se/ui-kit/components';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import UserSnippet from '~/shared/snippets/UserSnippet';
export const ScheduleShiftHistoryVacancyInterestListItem = (props) => {
    const { scheduleShiftHistoryVacancyInterestRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftHistoryVacancyInterestListItem_scheduleShiftHistoryVacancyInterest on ScheduleShiftHistoryVacancyInterest {
        byUser {
          ...UserSnippet_user
        }
        id
        schedulableEntityId
        scheduleShift {
          id
          assignedEntityId
        }
        status
        timestamp
      }
    `, scheduleShiftHistoryVacancyInterestRef);
    const [updateScheduleShiftAssignee, updateScheduleShiftAssigneeLoading] = useMutation(graphql `
        mutation ScheduleShiftHistoryVacancyInterestListItem_updateScheduleShiftAssigneeMutation(
          $model: UpdateScheduleShiftAssigneeRequestInput!
        ) {
          updateScheduleShiftAssignee(model: $model) {
            shift {
              ...ScheduleShiftHistory_scheduleShift
              ...ScheduleShiftContent_ScheduleShift
            }
          }
        }
      `);
    const [createScheduleShiftVacancyInterest, createScheduleShiftVacancyInterestLoading] = useMutation(graphql `
        mutation ScheduleShiftHistoryVacancyInterestListItem_createScheduleShiftVacancyInterestMutation(
          $model: CreateScheduleShiftVacancyInterestRequestInput!
        ) {
          createScheduleShiftVacancyInterest(model: $model) {
            shift {
              ...ScheduleShiftHistory_scheduleShift
              ...ScheduleShiftContent_ScheduleShift
              ...ScheduleShiftVacancyCard_scheduleShift
              vacancyInterests {
                ...ScheduleShiftHistoryVacancyInterestList_scheduleShiftHistoryVacancyInterestConnection
              }
            }
          }
        }
      `);
    return (<Flexbox align="center" direction="row" gap="medium">
      <div style={{ flex: 1 }}>
        <UserSnippet linkDisabled userRef={data.byUser}>
          <Text appearance="light" size="small">
            {formatDate(toDate(data.timestamp), 'dateTime')}
          </Text>
        </UserSnippet>
      </div>
      <Flexbox direction="row" gap="medium">
        {data.status === 'INTERESTED' && <Chip color={sonikaTheme.colors.system.go} size="xsmall" text="Intresserad"/>}
        {data.status === 'NOT_INTERESTED' && (<Chip color={sonikaTheme.colors.system.stop} size="xsmall" text="Ej intresserad"/>)}
      </Flexbox>
      <ContextMenu disabled={!!data.scheduleShift.assignedEntityId ||
            createScheduleShiftVacancyInterestLoading ||
            updateScheduleShiftAssigneeLoading}>
        <ContextMenuItem onClick={() => {
            updateScheduleShiftAssignee({
                variables: {
                    model: {
                        newAssignedEntityId: data.schedulableEntityId,
                        reasonEventId: null,
                        shiftId: data.scheduleShift.id,
                    },
                },
            });
        }} label="Tilldela pass"/>
        <ContextMenuItem onClick={() => {
            createScheduleShiftVacancyInterest({
                variables: {
                    model: {
                        schedulableEntityId: data.schedulableEntityId,
                        shiftId: data.scheduleShift.id,
                        status: data.status === 'INTERESTED' ? 'NOT_INTERESTED' : 'INTERESTED',
                    },
                },
            });
        }} label={data.status === 'INTERESTED' ? 'Ändra till ej intresserad' : 'Ändra till intresserad'}/>
      </ContextMenu>
    </Flexbox>);
};
export default ScheduleShiftHistoryVacancyInterestListItem;
