/**
 * @generated SignedSource<<77ed679972763954916d5f595bcf0da7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ScheduleTemplateHeader_scheduleTemplate",
        "selections": [
            (v0 /*: any*/),
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentUserPermissions",
                        "storageKey": null
                    },
                    (v1 /*: any*/),
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ScheduleTemplateAssignmentTypeFilter_assignmentTypes"
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ScheduleTemplateResourceFilterList_resources"
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ScheduleTemplateShiftClashes_scheduleTemplate"
            }
        ],
        "type": "ScheduleTemplate",
        "abstractKey": null
    };
})();
node.hash = "ae710b9a55f2dfb43f25c884a178477b";
export default node;
