import React from 'react';
import { H1 } from '@sonika-se/ui-kit/components';
import { FormattedMessage } from 'react-intl';
const NotFoundPage = () => {
    return (<>
      <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        }}>
        <H1 appearance="h3">
          <FormattedMessage defaultMessage="Sidan kunde inte hittas" id="NotFound.page_message"/>
        </H1>
      </div>
    </>);
};
export default NotFoundPage;
