import React, { Fragment } from 'react';
import { Flexbox, H6, Text } from '@sonika-se/ui-kit/components';
import { formatTimeSpan } from '@sonika-se/ui-kit/utils/time';
import { graphql, useFragment } from 'react-relay/hooks';
import enums from '~/enums.json';
import { formatValueUnit } from '~/pages/SchedulePages/utils/formatValueUnit';
const { ValueUnit } = enums;
export const ScheduleTimesPayload = (props) => {
    const { iHaveScheduleTimesRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleTimesPayload_iHaveScheduleTimes on IHaveScheduleTimes {
        timesByEntityAndAssignmentType {
          calculatedTimes {
            employmentSummaries: summaryByEmployment {
              employment {
                employmentSetting {
                  title
                }
              }
              eventSummaries: calculatedSummary {
                event {
                  id
                  name
                }
                isWork
                nonRegularPartsSummary {
                  event {
                    id
                    name
                  }
                  duration(unit: SECOND) {
                    value
                    unit
                  }
                }
                unit
                value
              }
            }
          }
        }
      }
    `, iHaveScheduleTimesRef);
    return (<Flexbox direction="column" gap="small">
      {data.timesByEntityAndAssignmentType.map((entityAndAssignmentType, index1) => {
            var _a;
            return (<React.Fragment key={`entityAndAssignmentType_${index1}`}>
          {(_a = entityAndAssignmentType.calculatedTimes.employmentSummaries) === null || _a === void 0 ? void 0 : _a.map((summary, index) => {
                    var _a, _b;
                    return (<Flexbox direction="column" gap="small" key={`summary-${index}`}>
              <H6>{((_b = (_a = summary.employment) === null || _a === void 0 ? void 0 : _a.employmentSetting) === null || _b === void 0 ? void 0 : _b.title) || 'Utanför anställning'}</H6>
              {summary.eventSummaries
                            .flat()
                            .sort((time) => (time.isWork ? -1 : 1))
                            .map((time) => (<Fragment key={time.event.id}>
                    <Flexbox justify="space">
                      <Text size="small">{time.event.name}</Text>
                      <Text size="small">{formatValueUnit(time)}</Text>
                    </Flexbox>
                    {time.nonRegularPartsSummary.map((part) => (<Flexbox justify="space" key={part.event.id} style={{ marginLeft: '1rem' }}>
                        <Text size="small">{part.event.name}</Text>
                        <Text size="small">{formatTimeSpan(part.duration)}</Text>
                      </Flexbox>))}
                  </Fragment>))}
            </Flexbox>);
                })}
        </React.Fragment>);
        })}
    </Flexbox>);
};
export default ScheduleTimesPayload;
