import React, { useEffect, useTransition } from 'react';
import { graphql, useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks';
import { Badge } from '@sonika-se/ui-kit/components';
import { formatISO } from 'date-fns';
const REFETCH_INTERVAL_IN_MS = 30 * 1000;
export const ScheduleShiftClashCountBadge = (props) => {
    const { children, fromDate, toDate } = props;
    const data = useLazyLoadQuery(graphql `
      query ScheduleShiftClashCountBadgeQuery($currentScheduleShiftClashesModel: GetRunningScheduleRequestInput!) {
        ...ScheduleShiftClashCountBadge_query @arguments(model: $currentScheduleShiftClashesModel)
      }
    `, {
        currentScheduleShiftClashesModel: {
            fromDate: formatISO(fromDate),
            toDate: formatISO(toDate),
        },
    });
    const [{ currentScheduleShiftClashes }, refetch] = useRefetchableFragment(graphql `
      fragment ScheduleShiftClashCountBadge_query on Query
      @argumentDefinitions(model: { type: "GetRunningScheduleRequestInput!" })
      @refetchable(queryName: "ScheduleShiftClashCountBadge_queryQuery") {
        currentScheduleShiftClashes(model: $model) {
          clashes {
            __typename
          }
        }
      }
    `, data);
    const [, startTransition] = useTransition();
    useEffect(() => {
        const intervalId = setInterval(() => {
            startTransition(() => {
                refetch({}, { fetchPolicy: 'network-only' });
            });
        }, REFETCH_INTERVAL_IN_MS);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    return (<Badge appearance="danger" text={currentScheduleShiftClashes.length.toString()}>
      {children}
    </Badge>);
};
export default ScheduleShiftClashCountBadge;
