import { toDate } from '@sonika-se/ui-kit/utils/time';
import { isSameDay } from 'date-fns';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import capitalizeFirstLetter from './capitalizeFirstLetter';
export const displayScheduleShiftStartEndDates = (start, end) => {
    const startDate = toDate(start);
    const endDate = toDate(end);
    const sameDay = isSameDay(startDate, endDate);
    if (sameDay) {
        return `${capitalizeFirstLetter(format(startDate, 'EEEE d/M', { locale }))}`;
    }
    return `${capitalizeFirstLetter(format(startDate, 'EEEE d/M', { locale }))} - ${capitalizeFirstLetter(format(endDate, 'EEEE d/M', { locale }))}`;
};
export default displayScheduleShiftStartEndDates;
