/**
 * @generated SignedSource<<1db375c55dc4f7cb45786be6647c4fac>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "myScheduleModel"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "reasonEvent",
        "plural": false,
        "selections": [
            (v1 /*: any*/),
            (v2 /*: any*/)
        ],
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "myScheduleModel"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./UserAbsenceModal_scheduleAbsencesQuery.graphql'),
                "identifierField": "id"
            }
        },
        "name": "UserAbsenceModal_scheduleAbsences",
        "selections": [
            {
                "alias": null,
                "args": (v0 /*: any*/),
                "concreteType": "ScheduleShiftAssigneeReplacementConnection",
                "kind": "LinkedField",
                "name": "scheduleAbsences",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleShiftAssigneeReplacementEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleShiftAssigneeReplacement",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v1 /*: any*/),
                                    (v3 /*: any*/),
                                    (v4 /*: any*/),
                                    (v5 /*: any*/),
                                    (v6 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Schedule",
                                        "kind": "LinkedField",
                                        "name": "schedule",
                                        "plural": false,
                                        "selections": [
                                            (v2 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": (v0 /*: any*/),
                "concreteType": "PlannedAbsenceConnection",
                "kind": "LinkedField",
                "name": "plannedAbsences",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlannedAbsenceEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PlannedAbsence",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v1 /*: any*/),
                                    (v3 /*: any*/),
                                    (v4 /*: any*/),
                                    (v5 /*: any*/),
                                    (v6 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v1 /*: any*/)
        ],
        "type": "SchedulableUser",
        "abstractKey": null
    };
})();
node.hash = "3b9eea31b8fb70703062fa77f57adf75";
export default node;
