/**
 * @generated SignedSource<<5dc80f2e31e5a03a81af4e1e42fe8c27>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SchedulableEntityWithText_iScheduleableEntity",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        }
    ],
    "type": "ISchedulableEntity",
    "abstractKey": "__isISchedulableEntity"
};
node.hash = "d005e0787b10b606fd287f1f6b2520fb";
export default node;
