import React, { useContext, useMemo, useState } from 'react';
import { graphql } from 'react-relay';
import { faAngleRight, faAngleUp, faCube as assetIcon, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import { Divider, Flexbox, Icon, Placeholder, Text } from '@sonika-se/ui-kit/components';
import { RunningSchedulesInformationTable } from './RunningSchedulesInformationTable';
import RunningSchedulesPageContext from '../../../RunningSchedulesPageContext';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
export const runningSchedulesInformationQuery = graphql `
  query RunningSchedulesInformationQuery(
    $model: GetRunningScheduleRequestInput!
    $typeFilter: SchedulableEntityTypeFilterRequestInput
    $schedulesFilter: [ID!]
  ) {
    ...RunningSchedulesInformation_scheduleTimes
      @arguments(model: $model, typeFilter: $typeFilter, schedulesFilter: $schedulesFilter)
  }
`;
const useStyles = createUseStyles((theme) => ({
    root: {
        overflowY: 'auto',
        background: '#F8F9FA',
        height: '100%',
    },
}));
export const RunningSchedulesInformation = (props) => {
    const { date, update, filter, selectedAbsenceFilters } = props;
    const { informationDrawer, filters } = useContext(RunningSchedulesPageContext);
    const { show, type } = informationDrawer;
    const classes = useStyles();
    const scheduleTimes = useLazyLoadQuery(runningSchedulesInformationQuery, {
        model: Object.assign({}, date),
        typeFilter: {
            types: [type],
        },
        schedulesFilter: (filters === null || filters === void 0 ? void 0 : filters.scheduleIds) || [],
    });
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment RunningSchedulesInformation_scheduleTimes on Query
      @argumentDefinitions(
        model: { type: "GetRunningScheduleRequestInput!" }
        typeFilter: { type: "SchedulableEntityTypeFilterRequestInput" }
        entitiesFilter: { type: "ScheduleTemplateShiftFilterRequestInput" }
        schedulesFilter: { type: "[ID!]" }
      )
      @refetchable(queryName: "RunningSchedulesInformation_scheduleTimesQuery") {
        scheduleTimes(
          model: $model
          typeFilter: $typeFilter
          entitiesFilter: $entitiesFilter
          schedulesFilter: $schedulesFilter
        ) {
          timesByEntityAndAssignmentType {
            assignmentType {
              id
              title
            }
          }
          ...RunningSchedulesInformationTable_scheduleTimes
        }
      }
    `, scheduleTimes);
    useDidMountEffect(() => {
        if (!show)
            return;
        refetch({
            model: {
                toDate: date.toDate,
                fromDate: date.fromDate,
            },
            entitiesFilter: filter ? { nameContains: filter } : undefined,
            schedulesFilter: (filters === null || filters === void 0 ? void 0 : filters.scheduleIds) || [],
        }, { fetchPolicy: 'network-only' });
    }, [date, show, update, filter]);
    const assignmentTypes = useMemo(() => {
        var _a, _b;
        return (_b = (_a = data === null || data === void 0 ? void 0 : data.scheduleTimes) === null || _a === void 0 ? void 0 : _a.timesByEntityAndAssignmentType) === null || _b === void 0 ? void 0 : _b.map(({ assignmentType }) => assignmentType).filter((value, index, items) => items.findIndex((x) => x.id === value.id) === index).sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
    }, [data]);
    const intl = useIntl();
    return data.scheduleTimes.timesByEntityAndAssignmentType.length > 0 ? (<div className={classes.root}>
      {assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.map((assignmentType, index) => (<AssignmentTypeCollapse key={`${assignmentType.id}-${index}`} title={assignmentType.title}>
          <RunningSchedulesInformationTable date={date} assignmentTypeId={assignmentType.id} scheduleTimes={data.scheduleTimes} selectedAbsenceFilters={selectedAbsenceFilters === null || selectedAbsenceFilters === void 0 ? void 0 : selectedAbsenceFilters.map(({ value }) => value)} update={update}/>
          <Divider />
        </AssignmentTypeCollapse>))}
    </div>) : (<Placeholder height="full" icon={type === 'USER' ? faUsers : assetIcon} text={intl.formatMessage({
            id: 'RunningSchedulesInformation.no_data',
            defaultMessage: 'Det finns inga {type} {filter}',
        }, {
            type: type === 'ASSET'
                ? intl.formatMessage({
                    defaultMessage: 'Enheter',
                    id: 'general.objects.asset.name.plural',
                })
                : intl.formatMessage({
                    defaultMessage: 'Användare',
                    id: 'general.objects.user.name.plural',
                }),
            filter: filter
                ? intl.formatMessage({
                    id: 'RunningSchedulesInformation.match',
                    defaultMessage: 'som matchar sökningen "{filter}"',
                }, {
                    filter,
                })
                : '',
        })}/>);
};
const AssignmentTypeCollapse = (props) => {
    const { children, title } = props;
    const [open, setOpen] = useState(true);
    return (<>
      <button onClick={() => setOpen(!open)} style={{
            appearance: 'none',
            background: 'none',
            border: 'none',
            display: 'block',
            margin: 0,
            padding: 0,
            width: '100%',
        }}>
        <Flexbox gap="xsmall" align="center">
          <Icon icon={open ? faAngleUp : faAngleRight} style={{
            fontSize: '1.5rem',
            padding: '0.75rem',
            color: '#848484',
        }}/>
          <Text>{title}</Text>
        </Flexbox>
      </button>
      {open && children}
    </>);
};
