/**
 * @generated SignedSource<<dc60b30009f5dbfe219f8d1717bf961a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "checkedWhere"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "model"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "where"
    }, v3 = {
        "kind": "Variable",
        "name": "model",
        "variableName": "model"
    }, v4 = {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
    }, v5 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 30
        },
        (v3 /*: any*/),
        (v4 /*: any*/)
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v6 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                            (v6 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstname",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastname",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "type": "SchedulableUser",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v6 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Asset",
                        "kind": "LinkedField",
                        "name": "asset",
                        "plural": false,
                        "selections": [
                            (v6 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "type": "SchedulableAsset",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v6 /*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "SchedulableResourceFilterQuery",
            "selections": [
                {
                    "args": [
                        (v3 /*: any*/),
                        (v4 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "SchedulableResourceFilterList_resources"
                },
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "checkedWhere",
                            "variableName": "checkedWhere"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "SchedulableResourceFilterList_resourcesChecked"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v2 /*: any*/),
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "SchedulableResourceFilterQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": "SchedulableEntityConnection",
                    "kind": "LinkedField",
                    "name": "schedulableEntities",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableEntityEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                (v7 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "filters": [
                        "model",
                        "where"
                    ],
                    "handle": "connection",
                    "key": "SchedulableResourceFilterList_schedulableEntities",
                    "kind": "LinkedHandle",
                    "name": "schedulableEntities"
                },
                {
                    "alias": "checked",
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "first",
                            "value": 100
                        },
                        {
                            "kind": "Variable",
                            "name": "where",
                            "variableName": "checkedWhere"
                        }
                    ],
                    "concreteType": "SchedulableEntityConnection",
                    "kind": "LinkedField",
                    "name": "schedulableEntities",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableEntityEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "308d29365d761e7273fc4687495aa2d6",
            "id": null,
            "metadata": {},
            "name": "SchedulableResourceFilterQuery",
            "operationKind": "query",
            "text": "query SchedulableResourceFilterQuery(\n  $where: SchedulableEntityFilterInput\n  $model: SchedulableEntitiesQueryRequestInput\n  $checkedWhere: SchedulableEntityFilterInput\n) {\n  ...SchedulableResourceFilterList_resources_4tkwZV\n  ...SchedulableResourceFilterList_resourcesChecked_309nRW\n}\n\nfragment SchedulableResourceFilterList_resourcesChecked_309nRW on Query {\n  checked: schedulableEntities(first: 100, where: $checkedWhere) {\n    edges {\n      node {\n        __typename\n        ... on SchedulableUser {\n          id\n          user {\n            id\n            firstname\n            lastname\n          }\n        }\n        ... on SchedulableAsset {\n          id\n          asset {\n            id\n            name\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment SchedulableResourceFilterList_resources_4tkwZV on Query {\n  schedulableEntities(first: 30, model: $model, where: $where) {\n    edges {\n      node {\n        __typename\n        ... on SchedulableUser {\n          id\n          user {\n            id\n            firstname\n            lastname\n          }\n        }\n        ... on SchedulableAsset {\n          id\n          asset {\n            id\n            name\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "b67163515bd4e8a0e681478b7992087c";
export default node;
