/**
 * @generated SignedSource<<38624e9cc855742e440e96b9378e4620>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "scheduleId"
        }
    ], v2 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 50
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserPermissions",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SchedulePermissionsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "SchedulePermissionsPanel_schedule"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SchedulePermissionsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v2 /*: any*/),
                            "concreteType": "SchedulePermissionConnection",
                            "kind": "LinkedField",
                            "name": "permissions",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SchedulePermissionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SchedulePermission",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "permissions",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Role",
                                                    "kind": "LinkedField",
                                                    "name": "role",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Schedule",
                                                    "kind": "LinkedField",
                                                    "name": "schedule",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "scheduleId",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "firstname",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "lastname",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "permissions(first:50)"
                        },
                        {
                            "alias": null,
                            "args": (v2 /*: any*/),
                            "filters": [],
                            "handle": "connection",
                            "key": "SchedulePermissionList_schedule_permissions",
                            "kind": "LinkedHandle",
                            "name": "permissions"
                        },
                        (v3 /*: any*/),
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "1401a56df86e9a858b3e97b348364b0d",
            "id": null,
            "metadata": {},
            "name": "SchedulePermissionsPageQuery",
            "operationKind": "query",
            "text": "query SchedulePermissionsPageQuery(\n  $scheduleId: ID!\n) {\n  schedule(id: $scheduleId) {\n    ...SchedulePermissionsPanel_schedule\n    id\n  }\n}\n\nfragment SchedulePermissionListItem_schedulePermission on SchedulePermission {\n  id\n  permissions\n  role {\n    id\n    name\n  }\n  schedule {\n    currentUserPermissions\n    id\n  }\n  scheduleId\n  user {\n    id\n    firstname\n    lastname\n  }\n}\n\nfragment SchedulePermissionList_schedule on Schedule {\n  permissions(first: 50) {\n    edges {\n      node {\n        id\n        ...SchedulePermissionListItem_schedulePermission\n        role {\n          name\n          id\n        }\n        user {\n          firstname\n          lastname\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SchedulePermissionsPanel_schedule on Schedule {\n  ...SchedulePermissionList_schedule\n  id\n  currentUserPermissions\n}\n"
        }
    };
})();
node.hash = "6787fa661c32b54bd9e85b02e712efd1";
export default node;
