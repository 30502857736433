import React, { useMemo } from 'react';
import { usePreloadedQuery, graphql, useFragment } from 'react-relay/hooks';
import { ModalToSheet, ModalToSheetHeader, ModalToSheetContent, Flexbox, } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import ScheduleAssignmentTypeOnScheduleSwitch from './AssignmentTypeOnScheduleSwitch';
export const editAssignmentTypeModalQuery = graphql `
  query EditAssignmentTypesOnScheduleModalQuery($scheduleId: ID!) {
    assignmentTypes(where: { disabledDate: { eq: null } }) {
      nodes {
        id
        ...AssignmentTypeOnScheduleSwitch_assignmentType
      }
    }
    schedule(id: $scheduleId) {
      id
      ...EditAssignmentTypesOnScheduleModal_schedule
    }
  }
`;
export const EditAssignmentTypesOnScheduleModal = (props) => {
    const { onCancel, onChange, query } = props;
    const intl = useIntl();
    const data = usePreloadedQuery(editAssignmentTypeModalQuery, query);
    const { assignmentTypes } = useFragment(graphql `
      fragment EditAssignmentTypesOnScheduleModal_schedule on Schedule {
        assignmentTypes {
          nodes {
            assignmentTypeId
            resources {
              totalCount
            }
          }
        }
      }
    `, data.schedule);
    const activeAssignmentTypeIds = useMemo(() => {
        return assignmentTypes.nodes.map(({ assignmentTypeId }) => assignmentTypeId);
    }, [assignmentTypes]);
    return (<ModalToSheet onClose={onCancel} size="small">
      <ModalToSheetHeader onClose={onCancel} closeButtonLabel="Avbryt">
        {intl.formatMessage({
            id: 'general.actions.update',
            defaultMessage: 'Redigera',
        })}
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Flexbox direction="column" gap="small">
          {data.assignmentTypes.nodes.map((assignmentType) => {
            var _a;
            return (<ScheduleAssignmentTypeOnScheduleSwitch activeAssignmentTypeIds={activeAssignmentTypeIds} key={assignmentType.id} onChange={() => {
                    if (onChange) {
                        onChange();
                    }
                }} resourceCount={(_a = assignmentTypes.nodes.find((activeAssignmentType) => activeAssignmentType.assignmentTypeId === assignmentType.id)) === null || _a === void 0 ? void 0 : _a.resources.totalCount} scheduleAssignmentTypeRef={assignmentType} scheduleId={data.schedule.id}/>);
        })}
        </Flexbox>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default EditAssignmentTypesOnScheduleModal;
