import React, { useState } from 'react';
import { graphql } from 'react-relay';
import { useFragment, useMutation } from 'react-relay/hooks';
import { Button, Confirm, Flexbox, IconButton, Placeholder, Tooltip } from '@sonika-se/ui-kit/components';
import { ScheduleShiftHistoryAssigneeChange } from './ScheduleShiftHistoryAssigneeChange';
import ScheduleShiftHistoryTimeChange from './ScheduleShiftHistoryTimeChange';
import { faUndoAlt } from '@fortawesome/pro-regular-svg-icons';
import { ScheduleShiftHistoryVacancyInterest } from './ScheduleShiftHistoryVacancyInterest';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScheduleShiftHistoryEventChange } from './ScheduleShiftHistoryEventChange';
import ResetScheduleShiftConfirm from '../ScheduleShiftInformationTab/components/ResetScheduleShiftConfirm';
import { ShiftHistoryEvent } from './ScheduleShiftHistoryEvent';
import User from '../User';
import { ScheduleShiftHistoryStatusChange } from './ScheduleShiftHistoryStatusChange';
export const ScheduleShiftHistory = (props) => {
    const { scheduleShift } = props;
    const [showResetConfirm, setShowResetConfirm] = useState(false);
    const [confirmProps, setConfirmProps] = useState(null);
    const intl = useIntl();
    const data = useFragment(graphql `
      fragment ScheduleShiftHistory_scheduleShift on ScheduleShift {
        id
        history(after: null, first: null, order: { timestamp: DESC }) @connection(key: "ScheduleShiftHistory_history") {
          edges {
            node {
              ... on IHaveScheduleShiftHistory {
                id
                timestamp
                description
                type
                byUser {
                  ...User_user
                }
              }
              ... on ScheduleShiftHistoryAssignmentTypeChange {
                newAssignmentType {
                  id
                  title
                }
                previousAssignmentType {
                  id
                  title
                }
              }
              ...ScheduleShiftHistoryAssigneeChange_history
              ...ScheduleShiftHistoryVacancyInterest_history
              ...ScheduleShiftHistoryTimeChange_history
              ...ScheduleShiftHistoryEventChange_history
              ...ScheduleShiftHistoryStatusChange_history
            }
          }
          totalCount
        }
      }
    `, scheduleShift);
    const [resetScheduleShift, resetScheduleShiftLoading] = useMutation(graphql `
      mutation ScheduleShiftHistoryResetMutation($model: ResetScheduleShiftRequestInput!) {
        resetScheduleShift(model: $model) {
          shiftEdge {
            node {
              id
              history(after: null, first: null, order: { timestamp: DESC }) {
                totalCount
              }
              schedule {
                id
                name
              }
              end
              start
              ...ScheduleShiftInformationTab_ScheduleShift
              ...useScheduleShiftAttachments_scheduleShift
              ...useScheduleShiftNotes_scheduleShift
              ...ScheduleShiftHistory_scheduleShift
              ...ScheduleShiftChangeTab_shift
            }
          }
        }
      }
    `);
    return (<>
      <Flexbox direction="column" gap="large" style={{ marginBottom: '1rem' }}>
        {data.history.edges.map(({ node: event }, index) => (<Flexbox key={`event-${index}`} justify="space">
            {(() => {
                var _a, _b;
                switch (event.type) {
                    case 'ASSIGNEE_CHANGED':
                        return <ScheduleShiftHistoryAssigneeChange history={event} key={index}/>;
                    case 'ASSIGNMENT_TYPE_CHANGE':
                        return (<ShiftHistoryEvent timestamp={event.timestamp.toString()}>
                      <p>
                        <FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryAssignmentTypeChange.default" defaultMessage="{user} ändrade roll från {previousAssignmentType} till {newAssignmentType}." values={{
                                user: <User user={event.byUser}/>,
                                previousAssignmentType: (<strong style={{ fontWeight: 'bold' }}>{(_a = event.previousAssignmentType) === null || _a === void 0 ? void 0 : _a.title}</strong>),
                                newAssignmentType: (<strong style={{ fontWeight: 'bold' }}>{(_b = event.newAssignmentType) === null || _b === void 0 ? void 0 : _b.title}</strong>),
                            }}/>
                      </p>
                    </ShiftHistoryEvent>);
                    case 'CREATED':
                        return (<ShiftHistoryEvent timestamp={event.timestamp.toString()}>
                      <p>
                        <FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryCreated.default" defaultMessage="{user} skapade passet." values={{
                                user: event.byUser ? <User user={event.byUser}/> : null,
                            }}/>
                      </p>
                    </ShiftHistoryEvent>);
                    case 'EVENT_CHANGE':
                        return <ScheduleShiftHistoryEventChange history={event} key={index}/>;
                    case 'TIME_UPDATED':
                        return <ScheduleShiftHistoryTimeChange history={event} key={index}/>;
                    case 'VACANCY_INTEREST':
                        return <ScheduleShiftHistoryVacancyInterest history={event} key={index}/>;
                    case 'STATUS_CHANGE':
                        return <ScheduleShiftHistoryStatusChange history={event} key={index}/>;
                    case 'NONE':
                        return null;
                    default:
                        return null;
                }
            })()}
            {index !== 0 && (<Tooltip content={intl.formatMessage({
                    id: 'ScheduleShiftModal.ScheduleShiftHistory.Tooltip.reset_to',
                    defaultMessage: 'Återställ pass till denna händelse',
                })}>
                <IconButton icon={faUndoAlt} size="small" onClick={() => setConfirmProps({
                    headerText: intl.formatMessage({
                        id: 'ScheduleShiftModal.ScheduleShiftHistory.Confirm.reset.header_text',
                        defaultMessage: 'Säkert att du vill återställa pass?',
                    }),
                    text: intl.formatMessage({
                        id: 'ScheduleShiftModal.ScheduleShiftHistory.Confirm.reset.text',
                        defaultMessage: 'Allt som hänt med passet innan kommer att försvinna och det går inte att ändra.',
                    }),
                    appearance: 'danger',
                    onCancel: () => {
                        setConfirmProps(null);
                    },
                    confirmButton: {
                        onConfirm: () => {
                            resetScheduleShift({
                                variables: {
                                    model: {
                                        shiftId: data.id,
                                        targetScheduleShiftHistoryId: event.id,
                                    },
                                },
                                onCompleted: () => {
                                    setConfirmProps(null);
                                },
                            });
                        },
                        text: intl.formatMessage({ defaultMessage: 'Återställ', id: 'general.actions.reset' }),
                    },
                })}/>
              </Tooltip>)}
          </Flexbox>))}
        {data.history.edges.length === 0 && (<Placeholder text={intl.formatMessage({
                id: 'ScheduleShiftModal.ScheduleShiftHistory.no_data',
                defaultMessage: 'Det finns ingen historik på det här passet',
            })}/>)}
      </Flexbox>

      <ResetScheduleShiftConfirm scheduleShiftId={data.id} open={showResetConfirm} onClose={() => setShowResetConfirm(false)}/>
      {confirmProps && <Confirm {...confirmProps}/>}
      {!!data.history.edges.length && (<Button style={{ marginTop: 'auto' }} onClick={() => setShowResetConfirm(true)}>
          <FormattedMessage id="general.misc.schedule_shift.actions.reset" defaultMessage="Återställ pass"/>
        </Button>)}
    </>);
};
export default ScheduleShiftHistory;
