import React, { Suspense, useEffect } from 'react';
import { graphql } from 'react-relay';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { Placeholder, Skeleton } from '@sonika-se/ui-kit/components';
import { faCalendarEdit } from '@fortawesome/pro-solid-svg-icons';
import { useIntl } from 'react-intl';
import ScheduleTimesPayload from './ScheduleTimesPayload';
export const myScheduleInformationDrawerContentQuery = graphql `
  query MyScheduleInformationDrawerContentQuery(
    $model: GetRunningScheduleRequestInput!
    $userId: ID!
    $schedulesFilter: [ID!]
  ) {
    user(id: $userId) {
      schedulableEntity {
        ...MyScheduleInformationDrawerContent_shifts @arguments(model: $model, schedulesFilter: $schedulesFilter)
      }
    }
  }
`;
const useStyles = createUseStyles((theme) => ({
    root: {
        overflowY: 'auto',
        height: '100%',
    },
}));
export const MyScheduleInformationDrawerContent = (props) => {
    var _a;
    const { date, open, update, meId, filters } = props;
    const intl = useIntl();
    const classes = useStyles();
    const data = useLazyLoadQuery(myScheduleInformationDrawerContentQuery, {
        model: Object.assign({}, date),
        userId: meId,
        schedulesFilter: (filters === null || filters === void 0 ? void 0 : filters.scheduleIds) || [],
    });
    const [{ scheduleTimes }, refetch] = useRefetchableFragment(graphql `
      fragment MyScheduleInformationDrawerContent_shifts on SchedulableUser
      @argumentDefinitions(model: { type: "GetRunningScheduleRequestInput!" }, schedulesFilter: { type: "[ID!]" })
      @refetchable(queryName: "MyScheduleInformationDrawerContent_shiftsQuery") {
        scheduleTimes(model: $model, schedulesFilter: $schedulesFilter) {
          ...ScheduleTimesPayload_iHaveScheduleTimes
        }
      }
    `, (_a = data.user) === null || _a === void 0 ? void 0 : _a.schedulableEntity);
    useEffect(() => {
        if (!open || !update)
            return;
        refetch({
            model: {
                toDate: date.toDate,
                fromDate: date.fromDate,
            },
            schedulesFilter: (filters === null || filters === void 0 ? void 0 : filters.scheduleIds) || [],
        }, { fetchPolicy: 'network-only' });
    }, [date, open, update]);
    return true ? (<div className={classes.root}>
      <Suspense fallback={<Skeleton type="table"/>}>
        <ScheduleTimesPayload iHaveScheduleTimesRef={scheduleTimes}/>
      </Suspense>
    </div>) : (<div style={{ padding: '1rem 0 0', flex: 1 }}>
      <Placeholder height="full" icon={faCalendarEdit} text={intl.formatMessage({
            defaultMessage: 'Det finns inga pass på det angivna datumet',
            id: 'ScheduleInformation.no_data_on_date',
        })}/>
    </div>);
};
export default MyScheduleInformationDrawerContent;
