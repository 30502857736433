import React, { Suspense, useMemo } from 'react';
import { ModalToSheetSkeleton, Panel, PanelButton, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import ScheduleTemplateTable from '~/tables/ScheduleTemplateTable';
import { NewScheduleTemplateModal, newScheduleTemplateModalQuery, } from '~/pages/SchedulePages/ScheduleListPage/NewScheduleTemplateModal';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
export const ScheduleTemplateTablePanel = (props) => {
    const { scheduleRef } = props;
    const schedule = useFragment(graphql `
      fragment ScheduleTemplateTablePanel_schedule on Schedule {
        ...ScheduleTemplateTable_schedule
        currentUserPermissions
        id
      }
    `, scheduleRef);
    const [newScheduleTemplateModalQueryRef, loadNewScheduleTemplateModalQuery, disposeNewScheduleTemplateModalQuery] = useQueryLoader(newScheduleTemplateModalQuery);
    const canEditSchedules = usePermissions()(['EDIT_SCHEDULES']);
    const canEditTemplates = useMemo(() => canEditSchedules || schedule.currentUserPermissions.includes('EDIT_TEMPLATES'), [canEditSchedules, schedule]);
    return (<>
      <Panel actions={canEditTemplates && (<PanelButton onClick={() => {
                loadNewScheduleTemplateModalQuery({});
            }}>
              Ny mall
            </PanelButton>)} title="Mallar">
        <Suspense fallback={<Skeleton type="table"/>}>
          <ScheduleTemplateTable scheduleRef={schedule}/>
        </Suspense>
      </Panel>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {newScheduleTemplateModalQueryRef && (<NewScheduleTemplateModal defaultScheduleId={schedule.id} hideScheduleSelect onCancel={() => {
                disposeNewScheduleTemplateModalQuery();
            }} onSubmit={() => {
                disposeNewScheduleTemplateModalQuery();
            }} preloadedQuery={newScheduleTemplateModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleTemplateTablePanel;
