import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { FormattedMessage } from 'react-intl';
import { ShiftHistoryEvent } from './ScheduleShiftHistoryEvent';
import User from '../User';
import ShiftStatusChip from '../ShiftStatusChip';
export const ScheduleShiftHistoryStatusChange = (props) => {
    const data = useFragment(graphql `
      fragment ScheduleShiftHistoryStatusChange_history on ScheduleShiftHistoryStatusChange {
        id
        newStatus
        previousStatus
        byUser {
          ...User_user
        }
        timestamp
      }
    `, props.history);
    return (<ShiftHistoryEvent timestamp={data.timestamp.toString()}>
      <p>
        <FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryStatusChange.default" defaultMessage="{user} ändrade status på passet från {previousStatus} till {newStatus}." values={{
            user: data.byUser ? <User user={data.byUser}/> : null,
            previousStatus: <ShiftStatusChip status={data.previousStatus}/>,
            newStatus: <ShiftStatusChip status={data.newStatus}/>,
        }}/>
      </p>
    </ShiftHistoryEvent>);
};
