/**
 * @generated SignedSource<<79d3aeb23e6a00d86e02f5fa10a96a6d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "potentialValidationsModel"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v5 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "potentialValidationsModel"
        }
    ], v6 = {
        "alias": null,
        "args": null,
        "concreteType": "AssetProficiencyValidation",
        "kind": "LinkedField",
        "name": "assetProficiency",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isValid",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "Asset",
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetNumber",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validateProficiency",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesChangeResourceFormDryRunMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateScheduleShiftAssigneePayload",
                    "kind": "LinkedField",
                    "name": "updateScheduleShiftAssigneeDuring",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfScheduleShift",
                            "kind": "LinkedField",
                            "name": "shiftEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShift",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v5 /*: any*/),
                                            "concreteType": "ScheduleShiftAssetValidation",
                                            "kind": "LinkedField",
                                            "name": "potentialAssetValidations",
                                            "plural": true,
                                            "selections": [
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SchedulableAsset",
                                                    "kind": "LinkedField",
                                                    "name": "schedulableAsset",
                                                    "plural": false,
                                                    "selections": [
                                                        (v7 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SchedulableUser",
                                                    "kind": "LinkedField",
                                                    "name": "schedulableUser",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": [
                                                                (v8 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AssignmentType",
                                                    "kind": "LinkedField",
                                                    "name": "userAssignmentType",
                                                    "plural": false,
                                                    "selections": [
                                                        (v9 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RunningSchedulesChangeResourceFormDryRunMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateScheduleShiftAssigneePayload",
                    "kind": "LinkedField",
                    "name": "updateScheduleShiftAssigneeDuring",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfScheduleShift",
                            "kind": "LinkedField",
                            "name": "shiftEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShift",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v5 /*: any*/),
                                            "concreteType": "ScheduleShiftAssetValidation",
                                            "kind": "LinkedField",
                                            "name": "potentialAssetValidations",
                                            "plural": true,
                                            "selections": [
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SchedulableAsset",
                                                    "kind": "LinkedField",
                                                    "name": "schedulableAsset",
                                                    "plural": false,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SchedulableUser",
                                                    "kind": "LinkedField",
                                                    "name": "schedulableUser",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": [
                                                                (v8 /*: any*/),
                                                                (v2 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AssignmentType",
                                                    "kind": "LinkedField",
                                                    "name": "userAssignmentType",
                                                    "plural": false,
                                                    "selections": [
                                                        (v9 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "c611f358584614e7776aff4168d88105",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesChangeResourceFormDryRunMutation",
            "operationKind": "mutation",
            "text": "mutation RunningSchedulesChangeResourceFormDryRunMutation(\n  $model: UpdateScheduleShiftAssigneeDuringRequestInput!\n  $potentialValidationsModel: PotentialScheduleShiftAssetValidationsInput!\n) {\n  updateScheduleShiftAssigneeDuring(model: $model) {\n    shiftEdge {\n      node {\n        id\n        start\n        end\n        potentialAssetValidations(model: $potentialValidationsModel) {\n          assetProficiency {\n            isValid\n          }\n          schedulableAsset {\n            asset {\n              id\n              name\n              assetNumber\n              validateProficiency\n            }\n            id\n          }\n          schedulableUser {\n            user {\n              fullName\n              id\n            }\n            id\n          }\n          userAssignmentType {\n            title\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "fec7e1618afe061e02fccc304e9eda5d";
export default node;
