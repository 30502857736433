export const getAssignedEntityAvatarProps = (isVacant, shiftStatus, entityDisplayName, entityColor) => {
    const assignedEntityName = isVacant ? 'Vakant' : entityDisplayName !== null && entityDisplayName !== void 0 ? entityDisplayName : 'Okänd';
    const text = shiftStatus == 'WITHDRAWN' ? `Inställt (${assignedEntityName})` : assignedEntityName;
    const avatarText = shiftStatus == 'WITHDRAWN' ? 'X' : isVacant ? '?' : assignedEntityName;
    const avatarColor = isVacant ? undefined : entityColor || '#eee';
    const specialAvatarColor = shiftStatus == 'WITHDRAWN' ? 'gray-striped' : isVacant ? 'red-striped' : undefined;
    return {
        appearance: 'square',
        color: avatarColor,
        specialColor: specialAvatarColor,
        text: avatarText,
    };
};
export default getAssignedEntityAvatarProps;
