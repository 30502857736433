/**
 * @generated SignedSource<<5ff185bc91f7bfbf70896bf5a3633571>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "nameContains"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v3 = [
        {
            "kind": "Variable",
            "name": "nameContains",
            "variableName": "nameContains"
        }
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v6 = [
        (v5 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningScheduleAssignmentType_scheduleAssignmentTypeQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": (v3 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "RunningScheduleAssignmentType_scheduleAssignmentType"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "RunningScheduleAssignmentType_scheduleAssignmentTypeQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "assignmentType",
                                    "plural": false,
                                    "selections": [
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": "filteredResources",
                                    "args": (v3 /*: any*/),
                                    "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                    "kind": "LinkedField",
                                    "name": "resources",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeResource",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "schedulableEntity",
                                                            "plural": false,
                                                            "selections": [
                                                                (v4 /*: any*/),
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v5 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "color",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "displayName",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "Asset",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "asset",
                                                                                    "plural": false,
                                                                                    "selections": (v6 /*: any*/),
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "type": "SchedulableAsset",
                                                                            "abstractKey": null
                                                                        }
                                                                    ],
                                                                    "type": "ISchedulableEntity",
                                                                    "abstractKey": "__isISchedulableEntity"
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": (v6 /*: any*/),
                                                                    "type": "Node",
                                                                    "abstractKey": "__isNode"
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "schedule",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "currentUserPermissions",
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "ScheduleAssignmentType",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "cce05e413bf197a08dbc27d18b6eb097",
            "id": null,
            "metadata": {},
            "name": "RunningScheduleAssignmentType_scheduleAssignmentTypeQuery",
            "operationKind": "query",
            "text": "query RunningScheduleAssignmentType_scheduleAssignmentTypeQuery(\n  $nameContains: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...RunningScheduleAssignmentType_scheduleAssignmentType_1WForM\n    id\n  }\n}\n\nfragment RunningScheduleAssignmentType_scheduleAssignmentType_1WForM on ScheduleAssignmentType {\n  id\n  assignmentType {\n    id\n    title\n  }\n  filteredResources: resources(nameContains: $nameContains) {\n    edges {\n      node {\n        id\n        schedulableEntity {\n          __typename\n          ... on ISchedulableEntity {\n            __isISchedulableEntity: __typename\n            id\n            color\n            displayName\n            ... on SchedulableAsset {\n              asset {\n                id\n              }\n            }\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n  }\n  schedule {\n    currentUserPermissions\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "b25f2de0f3bd107e5568c98b4ba6d337";
export default node;
