var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useMemo, useState, Suspense } from 'react';
import { graphql, usePreloadedQuery, useRefetchableFragment, useQueryLoader } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { Chip, Flexbox, Button, Divider, MultiSelect, Text, ModalToSheetSkeleton, Input, Skeleton, AccordionItem, Accordion, } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import { dragSchedulableEntity } from '../../../utils/dragSchedulableEntity';
import RunningScheduleAssignmentType from './components/RunningScheduleAssignmentType';
import EditAssignmentTypesOnScheduleModal, { editAssignmentTypeModalQuery, } from '../../../components/EditAssignmentTypesOnScheduleModal/EditAssignmentTypesOnScheduleModal';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            minWidth: pxToRem(300),
            borderRight: `${pxToRem(1)} solid #DADADA`,
        },
        filterSection: {
            padding: pxToRem(16),
            backgroundColor: '#FFFFFF',
            borderBottom: `${pxToRem(1)} solid #DADADA`,
        },
        main: {
            flex: 1,
            overflowY: 'auto',
        },
        actions: {
            backgroundColor: '#FFFFFF',
            borderTop: `${pxToRem(1)} solid #DADADA`,
            padding: pxToRem(16),
        },
        block: {
            '&:not(:first-child)': {
                borderTop: `${pxToRem(1)} solid #DADADA`,
                padding: pxToRem(16),
            },
        },
        vacant: {
            borderBottom: `${pxToRem(1)} solid #DADADA`,
            padding: pxToRem(16),
        },
        noDataText: {
            textAlign: 'center',
            padding: '1rem',
            margin: 'auto !important',
        },
    };
});
export const runningSchedulesEditSidebarQuery = graphql `
  query RunningSchedulesEditSidebarQuery {
    ...RunningSchedulesEditSidebar_schedules
  }
`;
export const RunningSchedulesEditSidebar = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const classes = useStyles();
    const intl = useIntl();
    const { setHighlightedSchedulableEntities, preloadedQuery, refetch } = props;
    const [filter, setFilter] = useState('');
    const data = usePreloadedQuery(runningSchedulesEditSidebarQuery, preloadedQuery);
    const [{ schedules }, refetchSchedules] = useRefetchableFragment(graphql `
      fragment RunningSchedulesEditSidebar_schedules on Query
      @argumentDefinitions(nameContains: { type: "String" })
      @refetchable(queryName: "RunningSchedulesEditSidebar_schedulesQuery") {
        schedules(filterModel: { withCurrentUserPermissions: [EDIT_RUNNING] }) {
          edges {
            node {
              id
              currentUserPermissions
              name
              assignmentTypes(order: { timestampId: ASC }) {
                edges {
                  node {
                    resources {
                      totalCount
                    }
                    id
                    assignmentType {
                      id
                      title
                    }
                    ...RunningScheduleAssignmentType_scheduleAssignmentType @arguments(nameContains: $nameContains)
                  }
                }
              }
            }
          }
        }
      }
    `, data);
    const [selectedSchedule, setSelectedSchedule] = useState(((_a = schedules === null || schedules === void 0 ? void 0 : schedules.edges) === null || _a === void 0 ? void 0 : _a.length) ? schedules === null || schedules === void 0 ? void 0 : schedules.edges[0].node.id : undefined);
    const [editAssignmentTypeModalQueryRef, loadEditAssignmentTypesOnScheduleModalQuery, disposeEditAssignmentTypesOnScheduleModalQuery,] = useQueryLoader(editAssignmentTypeModalQuery);
    const { node: schedule } = ((_b = schedules === null || schedules === void 0 ? void 0 : schedules.edges) === null || _b === void 0 ? void 0 : _b.find(({ node: schedule }) => schedule.id === selectedSchedule)) || {};
    const hasPermission = usePermissions();
    const canEditAssignmentTypes = useMemo(() => hasPermission(['EDIT_SCHEDULES']) || schedule.currentUserPermissions.includes('EDIT_ASSIGNMENT_TYPES'), [schedule, hasPermission]);
    return (<div>
      <div className={classes.root}>
        <Flexbox className={classes.filterSection} direction="column" gap="xsmall">
          <MultiSelect defaultValue={selectedSchedule} onChange={(value) => {
            setSelectedSchedule(value ? value[0] : undefined);
        }} placeholder="Välj schema" options={(_c = schedules === null || schedules === void 0 ? void 0 : schedules.edges) === null || _c === void 0 ? void 0 : _c.map(({ node: schedule }) => ({
            label: schedule.name,
            value: schedule.id,
            key: schedule.id,
        }))}/>
          <Input placeholder="Sök.." onChange={(value) => setFilter(value)}/>
        </Flexbox>
        <div className={classes.main}>
          <Flexbox direction="column" style={{ height: '100%' }}>
            <div className={classes.vacant}>
              <div draggable="true" onDragStart={(event) => dragSchedulableEntity(event, 'null', 'null', 'null')}>
                <Chip specialColor="red-striped" fullWidth={true} text={intl.formatMessage({
            defaultMessage: 'Vakant',
            id: 'general.misc.schedule_shift.vacant.singular',
        })}/>
              </div>
            </div>
            <Suspense fallback={<Skeleton type="table" height="100%"/>}>
              {(_e = (_d = schedule === null || schedule === void 0 ? void 0 : schedule.assignmentTypes) === null || _d === void 0 ? void 0 : _d.edges) === null || _e === void 0 ? void 0 : _e.map(({ node: scheduleAssignmentType }) => scheduleAssignmentType).slice().sort((a, b) => { var _a; return (_a = a.assignmentType.title) === null || _a === void 0 ? void 0 : _a.localeCompare(b.assignmentType.title); }).map((scheduleAssignmentType, assignmentTypeIndex) => (<div key={scheduleAssignmentType.id}>
                    {assignmentTypeIndex > 0 && <Divider />}
                    <Accordion>
                      <AccordionItem size="medium" subText={{
                children: (<>
                              <span>
                                {scheduleAssignmentType.assignmentType.title} (
                                {scheduleAssignmentType.resources.totalCount})
                              </span>
                            </>),
            }}>
                        <RunningScheduleAssignmentType filter={filter} scheduleAssignmentType={scheduleAssignmentType} scheduleId={schedule.id} setHighlightedSchedulableEntities={setHighlightedSchedulableEntities}/>
                      </AccordionItem>
                    </Accordion>
                  </div>))}
            </Suspense>
            {((_g = (_f = schedule === null || schedule === void 0 ? void 0 : schedule.assignmentTypes) === null || _f === void 0 ? void 0 : _f.edges) === null || _g === void 0 ? void 0 : _g.length) === 0 && (<Text className={classes.noDataText}>{`Det finns inga roller i ${(_h = schedule === null || schedule === void 0 ? void 0 : schedule.name) !== null && _h !== void 0 ? _h : 'schemat'}`}</Text>)}
            {!schedule && <Text className={classes.noDataText}>Du måste välja ett schema</Text>}
          </Flexbox>
        </div>
        {!!schedule && canEditAssignmentTypes && (<div className={classes.actions}>
            <Button display="block" onClick={() => {
                loadEditAssignmentTypesOnScheduleModalQuery({ scheduleId: schedule.id }, { fetchPolicy: 'network-only' });
            }}>
              {!!((_k = (_j = schedule === null || schedule === void 0 ? void 0 : schedule.assignmentTypes) === null || _j === void 0 ? void 0 : _j.edges) === null || _k === void 0 ? void 0 : _k.length) ? 'Redigera roller' : 'Lägg till roll'}
            </Button>
          </div>)}
      </div>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {editAssignmentTypeModalQueryRef && (<EditAssignmentTypesOnScheduleModal onCancel={() => disposeEditAssignmentTypesOnScheduleModalQuery()} onChange={() => __awaiter(void 0, void 0, void 0, function* () {
                refetchSchedules({ nameContains: filter }, { fetchPolicy: 'network-only' });
            })} query={editAssignmentTypeModalQueryRef}/>)}
      </Suspense>
    </div>);
};
export default RunningSchedulesEditSidebar;
