import React from 'react';
import { Button } from '@sonika-se/ui-kit/components';
import { graphql } from 'react-relay';
import { useFragment } from 'react-relay/hooks';
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
import PlaceholderText from './PlaceholderText';
const getDeviceSpecificUrl = (address) => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return `http://maps.apple.com?q=${address}`;
    }
    return `https://www.google.com/maps/search/?api=1&query=${address}`;
};
export const AssetAddress = (props) => {
    const { asset } = props;
    const { street, city, postalCode } = useFragment(graphql `
      fragment AssetAddress on Asset {
        street
        city
        postalCode
      }
    `, asset);
    const address = `${street ? `${street},` : ''} ${postalCode || ''} ${city || ''}`;
    if (!street && !city && !postalCode) {
        return <PlaceholderText>Ingen adress</PlaceholderText>;
    }
    const url = getDeviceSpecificUrl(address);
    return (<a target="_blank" href={url} rel="noopener noreferrer">
      <Button appearance="text" padding={false} icon={faMapMarkerAlt} iconProps={{ fixedWidth: true }} style={{ textAlign: 'left', alignItems: 'start', marginLeft: '-3px' }}>
        {address}
      </Button>
    </a>);
};
export default AssetAddress;
