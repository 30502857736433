import React from 'react';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { Avatar, IconButton, Text, Flexbox } from '@sonika-se/ui-kit/components';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem, spacing } = theme.helpers;
    return {
        noteDescription: {
            marginTop: spacing(1),
            marginLeft: spacing(2),
        },
        note: {
            '& > button': {
                visibility: 'hidden',
                [`@media (max-width: ${theme.breakPoints.low})`]: {
                    visibility: 'visible',
                },
            },
            '&:hover': {
                '& > button': {
                    visibility: 'visible',
                },
            },
        },
        noteCreatedAt: {
            color: '#6A6A6A',
            fontSize: pxToRem(12),
            marginLeft: spacing(2),
        },
        noteUser: {
            color: '#000000',
            fontSize: pxToRem(12),
            fontWeight: 600,
            marginLeft: spacing(2),
        },
    };
});
export const Note = (props) => {
    var _a, _b, _c;
    const { canEditRunning, note, removeNote } = props;
    const classes = useStyles();
    return (<Flexbox key={note.id} justify="space" className={classes.note}>
      <Flexbox>
        <Avatar appearance="round" size="small" text={(_a = note === null || note === void 0 ? void 0 : note.byUser) === null || _a === void 0 ? void 0 : _a.firstname} style={{ flexShrink: 0 }}/>
        <div>
          <Flexbox>
            <span className={classes.noteUser}>{`${(_b = note === null || note === void 0 ? void 0 : note.byUser) === null || _b === void 0 ? void 0 : _b.firstname} ${(_c = note === null || note === void 0 ? void 0 : note.byUser) === null || _c === void 0 ? void 0 : _c.lastname}`}</span>
            <span className={classes.noteCreatedAt}>
              {(note === null || note === void 0 ? void 0 : note.createdAt) && format(new Date(note.createdAt.toString()), 'yyyy-MM-dd HH:mm', { locale })}
            </span>
          </Flexbox>
          <Text className={classes.noteDescription} size="small">
            {note.text}
          </Text>
        </div>
      </Flexbox>
      {canEditRunning && (<IconButton icon={faTimes} size="xsmall" appearance="text" color="danger" onClick={() => {
                removeNote({
                    noteId: note.id,
                });
            }}/>)}
    </Flexbox>);
};
export default Note;
