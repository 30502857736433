import React, { useMemo, useTransition } from 'react';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { DrawerSkeleton, IconButton, Tooltip } from '@sonika-se/ui-kit/components';
import { faSmilePlus } from '@fortawesome/pro-regular-svg-icons';
import { useQueryLoader } from 'react-relay/hooks';
import { formatISO } from 'date-fns';
import ScheduleShiftVacancyDrawer, { scheduleShiftVacancyDrawerQuery } from '~/drawers/ScheduleShiftVacancyDrawer';
import ScheduleShiftVacancyCountBadge from '~/badges/ScheduleShiftVacancyCountBadge';
export const ScheduleShiftVacancyDrawerAction = (props) => {
    const { fromDate, toDate } = props;
    const hasPermissions = usePermissions();
    const canEditSchedules = useMemo(() => hasPermissions(['EDIT_SCHEDULES']), []);
    const [isTransitioning, startTransition] = useTransition();
    const [scheduleShiftVacancyDrawerQueryRef, loadScheduleShiftVacancyDrawerQuery, disposeScheduleShiftVacancyDrawerQuery,] = useQueryLoader(scheduleShiftVacancyDrawerQuery);
    if (!canEditSchedules) {
        return null;
    }
    return (<>
      <Tooltip content="Vakanta pass">
        <ScheduleShiftVacancyCountBadge fromDate={fromDate} toDate={toDate}>
          <IconButton appearance={scheduleShiftVacancyDrawerQueryRef ? 'filled' : 'outlined'} disabled={isTransitioning} icon={faSmilePlus} onClick={() => {
            if (scheduleShiftVacancyDrawerQueryRef) {
                disposeScheduleShiftVacancyDrawerQuery();
            }
            else {
                startTransition(() => {
                    loadScheduleShiftVacancyDrawerQuery({
                        scheduleShiftVacancyListModel: {
                            fromDate: formatISO(fromDate),
                            toDate: formatISO(toDate),
                        },
                    }, {
                        fetchPolicy: 'network-only',
                    });
                });
            }
        }}/>
        </ScheduleShiftVacancyCountBadge>
      </Tooltip>
      {isTransitioning && (<DrawerSkeleton onClose={() => {
                disposeScheduleShiftVacancyDrawerQuery();
            }} type="loader"/>)}
      {scheduleShiftVacancyDrawerQueryRef && (<ScheduleShiftVacancyDrawer onClose={() => {
                disposeScheduleShiftVacancyDrawerQuery();
            }} preloadedQuery={scheduleShiftVacancyDrawerQueryRef}/>)}
    </>);
};
export default ScheduleShiftVacancyDrawerAction;
