/**
 * @generated SignedSource<<a1fcdd5a18475fedae08b28ae7eb4599>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "UpdateScheduleShiftEventPayload",
            "kind": "LinkedField",
            "name": "updateScheduleShiftEvent",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfScheduleShift",
                    "kind": "LinkedField",
                    "name": "shiftEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShift",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Event",
                                    "kind": "LinkedField",
                                    "name": "event",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditScheduleShiftEventMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditScheduleShiftEventMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "23839a2d921263ffe93e2a2b4a76fe86",
            "id": null,
            "metadata": {},
            "name": "EditScheduleShiftEventMutation",
            "operationKind": "mutation",
            "text": "mutation EditScheduleShiftEventMutation(\n  $model: UpdateScheduleShiftEventRequestInput!\n) {\n  updateScheduleShiftEvent(model: $model) {\n    shiftEdge {\n      node {\n        id\n        event {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "5ea259629a7796f3915976490a7afc21";
export default node;
