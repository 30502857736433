import React from 'react';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { useIntl } from 'react-intl';
import { Label, Flexbox, Icon, Tooltip } from '@sonika-se/ui-kit/components';
import NewNoteForm from './NewNoteForm';
import Note from './Note';
export const Notes = (props) => {
    const { notes, addNote, canEditRunning, removeNote, showHidden = false, addNoteInProgress = false, placeholder, } = props;
    const intl = useIntl();
    return (<Flexbox direction="column" gap={notes.length > 0 ? 'medium' : 'small'}>
      <Label text={intl.formatMessage({ defaultMessage: 'Anteckningar', id: 'Notes.header' })} style={{ display: 'flex' }}>
        {showHidden && (<Tooltip content="Ej synlig för anställda">
            <Icon style={{
                marginLeft: '.5rem',
                color: '#848484',
            }} icon={faEyeSlash}/>
          </Tooltip>)}
      </Label>
      {(notes === null || notes === void 0 ? void 0 : notes.length) > 0 && (<div>
          <Flexbox direction="column" gap="medium">
            {notes === null || notes === void 0 ? void 0 : notes.map((note) => (<Note key={note.id} canEditRunning={canEditRunning} note={note} removeNote={removeNote}/>))}
          </Flexbox>
        </div>)}
      {!canEditRunning && (notes === null || notes === void 0 ? void 0 : notes.length) === 0 && 'Inga Anteckningar'}
      {canEditRunning && (<NewNoteForm addNote={addNote} addNoteInProgress={addNoteInProgress} placeholder={placeholder}/>)}
    </Flexbox>);
};
export default Notes;
