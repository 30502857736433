/**
 * @generated SignedSource<<98b0934f500400e860a6c1c962edb7da>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AddScheduleShiftModalQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AddScheduleShiftForm_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AddScheduleShiftModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Literal",
                            "name": "filterModel",
                            "value": {
                                "withCurrentUserPermissions": [
                                    "EDIT_RUNNING"
                                ]
                            }
                        }
                    ],
                    "concreteType": "ScheduleConnection",
                    "kind": "LinkedField",
                    "name": "schedules",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Schedule",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleAssignmentTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "assignmentTypes",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleAssignmentTypeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleAssignmentType",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v0 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "AssignmentType",
                                                                    "kind": "LinkedField",
                                                                    "name": "assignmentType",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v0 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "title",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                                    "kind": "LinkedField",
                                                                    "name": "resources",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                                                            "kind": "LinkedField",
                                                                            "name": "edges",
                                                                            "plural": true,
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "ScheduleAssignmentTypeResource",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "node",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        (v0 /*: any*/),
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "concreteType": null,
                                                                                            "kind": "LinkedField",
                                                                                            "name": "schedulableEntity",
                                                                                            "plural": false,
                                                                                            "selections": [
                                                                                                {
                                                                                                    "alias": null,
                                                                                                    "args": null,
                                                                                                    "kind": "ScalarField",
                                                                                                    "name": "__typename",
                                                                                                    "storageKey": null
                                                                                                },
                                                                                                {
                                                                                                    "kind": "InlineFragment",
                                                                                                    "selections": [
                                                                                                        (v0 /*: any*/),
                                                                                                        {
                                                                                                            "alias": null,
                                                                                                            "args": null,
                                                                                                            "kind": "ScalarField",
                                                                                                            "name": "displayName",
                                                                                                            "storageKey": null
                                                                                                        }
                                                                                                    ],
                                                                                                    "type": "ISchedulableEntity",
                                                                                                    "abstractKey": "__isISchedulableEntity"
                                                                                                },
                                                                                                {
                                                                                                    "kind": "InlineFragment",
                                                                                                    "selections": [
                                                                                                        (v0 /*: any*/)
                                                                                                    ],
                                                                                                    "type": "Node",
                                                                                                    "abstractKey": "__isNode"
                                                                                                }
                                                                                            ],
                                                                                            "storageKey": null
                                                                                        }
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "schedules(filterModel:{\"withCurrentUserPermissions\":[\"EDIT_RUNNING\"]})"
                }
            ]
        },
        "params": {
            "cacheID": "3ba68c02be3dbc0e1202e2544eaa8b19",
            "id": null,
            "metadata": {},
            "name": "AddScheduleShiftModalQuery",
            "operationKind": "query",
            "text": "query AddScheduleShiftModalQuery {\n  ...AddScheduleShiftForm_query\n}\n\nfragment AddScheduleShiftForm_query on Query {\n  schedules(filterModel: {withCurrentUserPermissions: [EDIT_RUNNING]}) {\n    edges {\n      node {\n        id\n        assignmentTypes {\n          edges {\n            node {\n              id\n              assignmentType {\n                id\n                title\n              }\n              resources {\n                edges {\n                  node {\n                    id\n                    schedulableEntity {\n                      __typename\n                      ... on ISchedulableEntity {\n                        __isISchedulableEntity: __typename\n                        id\n                        displayName\n                      }\n                      ... on Node {\n                        __isNode: __typename\n                        id\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n        name\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "1e3a1b080ef4748353fd48359c724fa3";
export default node;
