import React, { Suspense, useState } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Card, CardContent, CardHeader, Input, Skeleton } from '@sonika-se/ui-kit/components';
import { useDebounce } from '@sonika-se/ui-kit/hooks';
import UserPickerList from './UserPickerList';
export const UserPicker = (props) => {
    const { onSelect, queryRef } = props;
    const data = useFragment(graphql `
      fragment UserPicker_query on Query {
        ...UserPickerList_query
      }
    `, queryRef);
    const [search, setSearch] = useState();
    const delayedSearch = useDebounce(search, 100);
    return (<Card borders shadow="none">
      <CardHeader>
        <Input display="block" onChange={(value) => setSearch(value)} placeholder="Sök användare"/>
      </CardHeader>
      <CardContent style={{ height: '40vh', overflowY: 'auto' }}>
        <Suspense fallback={<Skeleton height="100%" type="table"/>}>
          <UserPickerList onSelect={onSelect} queryRef={data} search={delayedSearch}/>
        </Suspense>
      </CardContent>
    </Card>);
};
export default UserPicker;
