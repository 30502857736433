import React from 'react';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import ScheduleShiftClashListItem from '~/lists/ScheduleShiftClashListItem';
export const ScheduleShiftClashList = (props) => {
    const { clashGroupRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftClashList_clashGroup on ClashGroup {
        clashes {
          ...ScheduleShiftClashListItem_iCanBeUsedToCalculateScheduleTimesConnection
          ... on Node {
            id
          }
        }
      }
    `, clashGroupRef);
    return (<Flexbox direction="column" gap="medium">
      {data.clashes.map((clash) => (<ScheduleShiftClashListItem key={clash.id} iCanBeUsedToCalculateScheduleTimesRef={clash}/>))}
    </Flexbox>);
};
export default ScheduleShiftClashList;
