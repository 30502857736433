/**
 * @generated SignedSource<<9e10992b0f9810986a57b0e3a6934b7a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleShiftFiles_scheduleShift",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useScheduleShiftAttachments_scheduleShift"
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "schedule",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentUserPermissions",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "ScheduleShift",
    "abstractKey": null
};
node.hash = "2946dec31db4ca2361c9e4dde1da4582";
export default node;
