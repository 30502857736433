/**
 * @generated SignedSource<<4030824d97112220308d0178dd25d44a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleConnection",
            "kind": "LinkedField",
            "name": "schedules",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Schedule",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "where",
                                            "variableName": "where"
                                        }
                                    ],
                                    "concreteType": "ScheduleShiftConnection",
                                    "kind": "LinkedField",
                                    "name": "shifts",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleShiftEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleShift",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "start",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "end",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesEditUpdateTimesModalQuery",
            "selections": (v2 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RunningSchedulesEditUpdateTimesModalQuery",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "a2306b8b0088fdda2e6a5dbcab77b90a",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesEditUpdateTimesModalQuery",
            "operationKind": "query",
            "text": "query RunningSchedulesEditUpdateTimesModalQuery(\n  $where: ScheduleShiftFilterInput\n) {\n  schedules {\n    edges {\n      node {\n        id\n        shifts(where: $where) {\n          edges {\n            node {\n              id\n              start\n              end\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "21347eacf67d66090894f4756decc4fd";
export default node;
