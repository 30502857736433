import React, { Suspense, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { faUsers, faCube as assetIcon } from '@fortawesome/pro-regular-svg-icons';
import { faUsers as faUsersSolid, faCube as assetIconSolid } from '@fortawesome/pro-solid-svg-icons';
import { useQueryLoader } from 'react-relay/hooks';
import { Button, Popover, PopoverHeader, PopoverContent, PopoverFooter, Drawer, DrawerHeader, DrawerContent, Skeleton, IconButton, Tooltip, Badge, } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import EditResourceColorList, { editResourceColorListQuery } from '../EditResourceColorList/EditResourceColorList';
import ScheduleTemplateResourceFilterList from './ScheduleTemplateResourceFilterList';
const useStyles = createUseStyles(() => ({
    button: {},
    container: {
        padding: '10px 16px',
        margin: 0,
        borderBottom: '1px solid #ccc',
    },
    resourceRow: {
        fontSize: 16,
        paddingTop: 5,
        paddingBottom: 5,
        '&:first-child': {
            marginTop: '.5rem',
        },
    },
    noDataText: {
        textAlign: 'center',
        margin: '1rem',
    },
    resourceCheckbox: {
        marginRight: 10,
        cursor: 'pointer',
    },
}));
const ResourceFilter = (props) => {
    const { updateFilters, scheduleTemplate, title, type, scheduleId } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [numberOfChecked, setNumberOfChecked] = useState(0);
    const classes = useStyles();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [editResourceColorListQueryRef, loadEditResourceColorListQuery, disposeEditResourceColorListQuery] = useQueryLoader(editResourceColorListQuery);
    const intl = useIntl();
    const solidIcon = type === 'user' ? faUsersSolid : assetIconSolid;
    const lightIcon = type === 'user' ? faUsers : assetIcon;
    return (<>
      <div className={classes.button}>
        <Tooltip content={title}>
          <Badge text={`${numberOfChecked}`} appearance={numberOfChecked ? 'danger' : 'none'}>
            <IconButton appearance={open ? 'filled' : 'outlined'} onClick={handleClick} icon={open ? solidIcon : lightIcon} size="medium"/>
          </Badge>
        </Tooltip>
      </div>
      <Popover anchorEl={open ? anchorEl : undefined} onClose={handleClose}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverContent>
          <Suspense fallback={<Skeleton type="table" height="150px"/>}>
            <ScheduleTemplateResourceFilterList scheduleTemplate={scheduleTemplate} type={type} updateFilters={updateFilters} setNumberOfChecked={(number) => setNumberOfChecked(number)}/>
          </Suspense>
        </PopoverContent>
        <PopoverFooter>
          <Button appearance="text" size="small" display="block" onClick={() => {
            handleClose();
            loadEditResourceColorListQuery({ scheduleId });
        }}>
            <FormattedMessage defaultMessage="Välj färg för {title}" values={{ title }} id="general.misc.color.actions.select_for"/>
          </Button>
        </PopoverFooter>
      </Popover>
      <Drawer open={!!editResourceColorListQueryRef} onClose={() => disposeEditResourceColorListQuery()} size="small">
        <DrawerHeader title={intl.formatMessage({
            defaultMessage: 'Välj färg för {title}',
            id: 'general.misc.color.actions.select_for',
        }, {
            title: type === 'user'
                ? intl.formatMessage({ id: 'general.objects.user.name.plural', defaultMessage: 'Användare' })
                : type === 'asset'
                    ? intl.formatMessage({ id: 'general.objects.asset.name.plural', defaultMessage: 'Enheter' })
                    : '',
        })}/>
        <Suspense fallback={<Skeleton type="table" height="calc(var(--vh, 1vh) * 100)"/>}>
          {editResourceColorListQueryRef && (<DrawerContent>
              <EditResourceColorList preloadedQuery={editResourceColorListQueryRef} type={type}/>
            </DrawerContent>)}
        </Suspense>
      </Drawer>
    </>);
};
export default ResourceFilter;
