/**
 * @generated SignedSource<<bc6293a5469ead8d4b8b281e9a75b589>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleId"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "scheduleId"
                }
            ],
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "schedule",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inactivatedFrom",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleGeneralInfoUpdateModalQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleGeneralInfoUpdateModalQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "022d5b2a5d5030c82fbb5aafb0472b40",
            "id": null,
            "metadata": {},
            "name": "ScheduleGeneralInfoUpdateModalQuery",
            "operationKind": "query",
            "text": "query ScheduleGeneralInfoUpdateModalQuery(\n  $scheduleId: ID!\n) {\n  schedule(id: $scheduleId) {\n    archived\n    inactivatedFrom\n    id\n    name\n  }\n}\n"
        }
    };
})();
node.hash = "fc222c3a5f43f048ab9e4e59121c530a";
export default node;
