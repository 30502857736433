import React, { Suspense, useContext, useEffect, useState } from 'react';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { Avatar, Divider, Flexbox, IconButton, ModalToSheetSkeleton, Popover, PopoverContent, PopoverHeader, Text, Tooltip, } from '@sonika-se/ui-kit/components';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import User from '~/components/User/User';
import ScheduleTemplateShiftClashGroup from './ScheduleTemplateShiftClashGroup';
import ScheduleTemplateDetailsPageContext from '../../ScheduleTemplateDetailsPageContext';
export const ScheduleTemplateShiftClashes = (props) => {
    var _a;
    const { scheduleTemplateRef } = props;
    const intl = useIntl();
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment ScheduleTemplateShiftClashes_scheduleTemplate on ScheduleTemplate
      @refetchable(queryName: "ScheduleTemplateShiftClashesRefetchQuery") {
        id
        shiftClashes {
          clashes {
            assignedEntity {
              ... on SchedulableAsset {
                id
                asset {
                  name
                }
              }
              ... on SchedulableUser {
                id
                user {
                  ...User
                  firstname
                  lastname
                }
              }
            }
          }
          ...ScheduleTemplateShiftClashGroup_scheduleTemplateShiftClashGroup
        }
      }
    `, scheduleTemplateRef);
    const context = useContext(ScheduleTemplateDetailsPageContext);
    /*
    TODO: Subscriptions - reactivate after BE support
  
    useSubscription<ScheduleTemplateShiftClashes_onScheduleTemplateUpdatedSubscription>({
      onCompleted: () => {
        console.log('ScheduleTemplateShiftClashes_onScheduleTemplateUpdatedSubscription completed')
      },
      onError: (error) => {
        console.log({ error })
      },
      onNext: (next) => {
        console.log({ next })
  
        refetch(
          {
            id: data.id,
          },
          {
            fetchPolicy: 'network-only',
          },
        )
      },
      subscription: graphql`
        subscription ScheduleTemplateShiftClashes_onScheduleTemplateUpdatedSubscription(
          $topic: OnScheduleTemplateUpdatedTopicInput!
        ) {
          onScheduleTemplateUpdated(topic: $topic) {
            scheduleTemplateId
          }
        }
      `,
      variables: {
        topic: {
          companyId: context.companyId,
          scheduleTemplateId: data.id,
        },
      },
    })
    */
    const handleScheduleTemplateUpdated = (e) => {
        refetch({
            id: data.id,
        }, {
            fetchPolicy: 'network-only',
        });
    };
    useEffect(() => {
        context.subscribeToScheduleTemplateUpdatedEvent(handleScheduleTemplateUpdated);
        return () => {
            context.unSubscribeToScheduleTemplateUpdatedEvent(handleScheduleTemplateUpdated);
        };
    }, []);
    // TODO: Remove temp polling fix when subscriptions are active in BE
    // usePolling(
    //   () => {
    //     refetch(
    //       {
    //         id: data.id,
    //       },
    //       {
    //         fetchPolicy: 'network-only',
    //       },
    //     )
    //   },
    //   {
    //     interval: 20 * 1000,
    //   },
    // )
    const [anchorEl, setAnchorEl] = useState(null);
    if (!data || ((_a = data.shiftClashes) === null || _a === void 0 ? void 0 : _a.length) === 0) {
        return null;
    }
    return (<Suspense fallback={<IconButton appearance="filled" color="danger" disabled icon={faExclamationTriangle}/>}>
      <Tooltip content={intl.formatMessage({
            defaultMessage: 'Dubbelbokade pass',
            id: 'general.misc.schedule_shift_clashes.name.plural',
        })}>
        <IconButton color="danger" appearance="filled" icon={faExclamationTriangle} onClick={(event) => {
            setAnchorEl(!anchorEl ? event.currentTarget : null);
        }}/>
      </Tooltip>
      <Popover anchorEl={anchorEl} onClose={() => setAnchorEl(null)} size="auto">
        <PopoverHeader>
          <FormattedMessage defaultMessage="Dubbelbokade pass" id="general.misc.schedule_shift_clashes.name.plural"/>
        </PopoverHeader>
        <PopoverContent>
          <Flexbox direction="column" gap="xsmall">
            {data.shiftClashes.map((clashGroup, index) => {
            const assignedEntity = data.shiftClashes[index].clashes[0].assignedEntity;
            const name = assignedEntity.user
                ? `${assignedEntity.user.firstname} ${assignedEntity.user.lastname}`
                : assignedEntity.asset.name;
            const diffEntityFromPrev = index === 0 ||
                data.shiftClashes[index].clashes[0].assignedEntity.id !==
                    data.shiftClashes[index - 1].clashes[0].assignedEntity.id;
            return (<React.Fragment key={`clashGroup_${index}`}>
                  {diffEntityFromPrev && (<>
                      {index > 0 && <Divider />}
                      <Flexbox align="center" gap="xsmall">
                        {assignedEntity.asset ? (<Avatar size="xsmall" text={name}/>) : (<User parent={assignedEntity.user} size="xsmall"/>)}
                        <Text bold>{name}</Text>
                      </Flexbox>
                    </>)}
                  {!diffEntityFromPrev && <Divider style={{ marginLeft: '2rem' }}/>}
                  <div style={{ marginLeft: '2rem' }}>
                    <ScheduleTemplateShiftClashGroup scheduleTemplateShiftClashGroupRef={clashGroup}/>
                  </div>
                </React.Fragment>);
        })}
          </Flexbox>
        </PopoverContent>
      </Popover>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}></Suspense>
    </Suspense>);
};
export default ScheduleTemplateShiftClashes;
