import React, { forwardRef, useImperativeHandle } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, object, string } from 'yup';
import { Checkbox, Field, Form } from '@sonika-se/ui-kit/components';
export const assignmentTypesSelectFormValidationSchema = object({
    assignmentTypeIds: array().of(string()).required(),
});
export const AssignmentTypesSelectForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit, where } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(assignmentTypesSelectFormValidationSchema),
    });
    const { assignmentTypes } = useLazyLoadQuery(graphql `
      query AssignmentTypesSelectFormQuery($where: AssignmentTypeFilterInput) {
        assignmentTypes(where: $where) {
          nodes {
            id
            title
          }
        }
      }
    `, {
        where,
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form columns="1fr 1fr 1fr" onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
      <Controller control={control} name="assignmentTypeIds" render={({ field }) => (<>
            {assignmentTypes.nodes.map((assignmentType) => (<Field direction="row-reverse" key={assignmentType.id} label={assignmentType.title}>
                <Checkbox defaultChecked={defaultValues.assignmentTypeIds.includes(assignmentType.id)} disabled={disabled} onBlur={field.onBlur} onChange={(event) => {
                    if (event.target.checked) {
                        field.onChange([...field.value, assignmentType.id]);
                    }
                    else {
                        field.onChange([...field.value].filter((id) => id !== assignmentType.id));
                    }
                }}/>
              </Field>))}
          </>)}/>
    </Form>);
});
export default AssignmentTypesSelectForm;
