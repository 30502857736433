/**
 * @generated SignedSource<<2b97fb284be010871d920d96d0f7ee73>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleShiftInformationTab_ScheduleShift",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "schedule",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentUserPermissions",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AssignedEntity_ScheduleShift"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ShiftInformation_ScheduleShift"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SimultaneousShifts_scheduleShift"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleShiftFiles_scheduleShift"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleShiftInterestForm_scheduleShift"
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isVacant",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleShiftHistoryVacancyInterestConnection",
            "kind": "LinkedField",
            "name": "vacancyInterests",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftHistoryVacancyInterestList_scheduleShiftHistoryVacancyInterestConnection"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "ScheduleShift",
    "abstractKey": null
};
node.hash = "a8c09a1f7866b8082041c1417606f38b";
export default node;
