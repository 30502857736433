import React from 'react';
import { graphql } from 'react-relay';
import { usePreloadedQuery } from 'react-relay/hooks';
import { ModalToSheet, ModalToSheetHeader } from '@sonika-se/ui-kit/components';
import RunningSchedulesDeleteShiftsWizard from './components/RunningSchedulesDeleteShiftsWizard';
export const runningSchedulesDeleteShiftsModalQuery = graphql `
  query RunningSchedulesDeleteShiftsModalQuery {
    ...RunningSchedulesDeleteShiftsWizard_query
  }
`;
export const RunningSchedulesDeleteShiftsModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const data = usePreloadedQuery(runningSchedulesDeleteShiftsModalQuery, preloadedQuery);
    return (<ModalToSheet onClose={onClose} size="large">
      <ModalToSheetHeader onClose={onClose} closeButtonLabel="Avbryt">
        Ta bort pass
      </ModalToSheetHeader>
      <RunningSchedulesDeleteShiftsWizard onClose={() => {
            onClose();
        }} onSubmit={() => {
            onClose();
        }} query={data}/>
    </ModalToSheet>);
};
export default RunningSchedulesDeleteShiftsModal;
