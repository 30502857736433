/**
 * @generated SignedSource<<f895b23e86cfbc0d3ec65b8066a306cc>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "DeleteScheduleShiftPayload",
            "kind": "LinkedField",
            "name": "deleteScheduleShifts",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfScheduleShift",
                    "kind": "LinkedField",
                    "name": "shiftEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShift",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "assignedEntityId",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesEditNavbar_deleteScheduleShiftsMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RunningSchedulesEditNavbar_deleteScheduleShiftsMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "a63cac22d0937261c13814f778ddd76c",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesEditNavbar_deleteScheduleShiftsMutation",
            "operationKind": "mutation",
            "text": "mutation RunningSchedulesEditNavbar_deleteScheduleShiftsMutation(\n  $model: [DeleteScheduleShiftRequestInput!]!\n) {\n  deleteScheduleShifts(model: $model) {\n    shiftEdge {\n      node {\n        id\n        assignedEntityId\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "80b7ec7b3d25f80bb906ad9b446217d3";
export default node;
