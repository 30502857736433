import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { Form, Input, Button } from '@sonika-se/ui-kit/components';
import { FormattedMessage } from 'react-intl';
const defaultValues = {
    text: '',
};
const validationSchema = object({
    text: string().required(),
});
export const NewNoteForm = (props) => {
    const { className, addNote, addNoteInProgress, placeholder } = props;
    const { control, formState: { errors }, handleSubmit, reset, } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    return (<Form className={className} columns="4fr 1fr" gap="small" onSubmit={handleSubmit((data) => {
            addNote({
                text: data.text,
            });
            reset();
        })}>
      <Controller control={control} name="text" render={({ field }) => {
            var _a, _b;
            return (<Input disabled={addNoteInProgress} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.text) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} placeholder={placeholder} onBlur={field.onBlur} onChange={field.onChange} value={field.value}/>);
        }}/>
      <Button disabled={addNoteInProgress} type="submit">
        <FormattedMessage id="general.actions.save" defaultMessage="Spara"/>
      </Button>
    </Form>);
};
export default NewNoteForm;
