import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import ScheduleShiftHistory from './ScheduleShiftHistory/ScheduleShiftHistory';
import ScheduleShiftChangeTab from './ScheduleShiftChangeTab';
import ScheduleShiftInformationTab from './ScheduleShiftInformationTab/ScheduleShiftInformationTab';
import ScheduleShiftNotesTab from './ScheduleShiftNotesTab';
const ScheduleShiftContent = (props) => {
    const { canRequestShift, schedulableEntityId, scheduleShift, selectedTab } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftContent_ScheduleShift on ScheduleShift {
        ...AssignedEntity_ScheduleShift
        ...ScheduleShiftInformationTab_ScheduleShift
        ...useScheduleShiftAttachments_scheduleShift
        ...ScheduleShiftHistory_scheduleShift
        ...ScheduleShiftChangeTab_shift
        ...ScheduleShiftNotesTab_scheduleShift
      }
    `, scheduleShift);
    return (<div>
      {selectedTab === 'information' && (<ScheduleShiftInformationTab canRequestShift={canRequestShift} scheduleShift={data} schedulableEntityId={schedulableEntityId}/>)}
      {selectedTab === 'notes' && <ScheduleShiftNotesTab scheduleShiftRef={data}/>}
      {selectedTab === 'history' && <ScheduleShiftHistory scheduleShift={data}/>}
      {selectedTab === 'request_change' && <ScheduleShiftChangeTab scheduleShift={data}/>}
    </div>);
};
export default ScheduleShiftContent;
