import React from 'react';
import { graphql, useMutation } from 'react-relay/hooks';
import { Confirm } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
export const DeleteScheduleShiftConfirm = (props) => {
    const { onClose, open, scheduleShiftId, scheduleId } = props;
    const intl = useIntl();
    const [deleteScheduleShift, deleteScheduleShiftLoading] = useMutation(graphql `
      mutation DeleteScheduleShiftConfirmMutation($model: DeleteScheduleShiftRequestInput!) {
        deleteScheduleShift(model: $model) {
          shiftEdge {
            node {
              id
            }
          }
        }
      }
    `);
    if (!open)
        return null;
    return (<Confirm headerText={intl.formatMessage({
            id: 'ScheduleShiftModal.Confirm.delete_shift.header_text',
            defaultMessage: 'Är du säker på att du vill ta bort det här passet?',
        })} text={intl.formatMessage({
            id: 'DeleteScheduleShiftConfirm.Confirm.reset_shift.text',
            defaultMessage: 'Allt som hänt med passet innan kommer att försvinna och det går inte att ändra.',
        })} appearance="danger" onCancel={() => {
            onClose();
        }} disabled={deleteScheduleShiftLoading} confirmButton={{
            onConfirm: () => {
                deleteScheduleShift({
                    configs: [
                        {
                            connectionKeys: [
                                {
                                    key: 'RuningSchedulesGridSchedule_runningSchedule',
                                },
                            ],
                            parentID: scheduleId,
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['shiftEdge', 'node'],
                            pathToConnection: [scheduleId, 'runningSchedule'],
                        },
                        {
                            connectionKeys: [
                                {
                                    key: 'MySchedulePanel_scheduleItems',
                                },
                            ],
                            parentID: 'client:root',
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['shiftEdge', 'node'],
                            pathToConnection: ['client:root', 'currentUser', 'me', 'schedulableEntity', 'scheduleItems'],
                        },
                        {
                            connectionKeys: [
                                {
                                    key: 'AvailableVacanciesPanel_availableVacantShifts',
                                },
                            ],
                            parentID: 'client:root',
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['shiftEdge', 'node'],
                            pathToConnection: ['client:root', 'currentUser', 'me', 'schedulableEntity', 'availableVacantShifts'],
                        },
                    ],
                    variables: {
                        model: {
                            shiftId: scheduleShiftId,
                        },
                    },
                    onCompleted: () => {
                        onClose(true);
                    },
                });
            },
            text: intl.formatMessage({ id: 'general.actions.delete', defaultMessage: 'Ta bort' }),
        }}/>);
};
export default DeleteScheduleShiftConfirm;
