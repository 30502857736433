import React from 'react';
import { format, isToday, isWeekend } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        root: {
            backgroundColor: '#FFFFFF',
            gridColumn: 1,
            left: 0,
            padding: pxToRem(8),
            position: 'sticky',
            borderRight: `${pxToRem(1)} solid ${theme.colors.foreground.separation}`,
            margin: `${pxToRem(0)} ${pxToRem(-1)}`,
        },
        dateNumber: ({ isToday }) => ({
            color: isToday ? '#0C2AF3' : '#131313',
            display: 'block',
            fontSize: pxToRem(15),
            lineHeight: pxToRem(24),
            fontWeight: '600',
        }),
        dateWeekday: ({ isWeekend, isToday }) => ({
            color: isToday || !isWeekend ? '#6A6A6A' : theme.colors.system.stop,
            textTransform: 'capitalize',
            lineHeight: pxToRem(24),
            display: 'block',
            fontSize: pxToRem(14),
        }),
    };
});
export const RunningSchedulesGridDate = (props) => {
    const { date } = props;
    const classes = useStyles({
        isToday: isToday(date),
        isWeekend: isWeekend(date),
    });
    return (<div className={classes.root}>
      <span className={classes.dateWeekday}>{format(date, 'E', { locale })}</span>
      <span className={classes.dateNumber}>{format(date, 'dd/MM', { locale })}</span>
    </div>);
};
