import React, { Fragment, useState } from 'react';
import { graphql } from 'react-relay';
import { useRefetchableFragment } from 'react-relay/hooks';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { Text, Divider, Flexbox, Icon, Tooltip, Avatar, IconButton } from '@sonika-se/ui-kit/components';
import { faAngleRight, faAngleUp, faCalendar, faClock, faExclamationTriangle, } from '@fortawesome/pro-regular-svg-icons';
import { formatTimeSpan } from '@sonika-se/ui-kit/utils/time';
import { formatValueUnit } from '~/pages/SchedulePages/utils/formatValueUnit';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
export const RunningSchedulesInformationByMonthRow = (props) => {
    const { queryRef } = props;
    const { frontUrls } = useEnvironment();
    const [schedulableEntity, refetch] = useRefetchableFragment(graphql `
      fragment RunningSchedulesInformationByMonthRow_fragment on ISchedulableEntity
      @argumentDefinitions(month: { type: "DateTime!" })
      @refetchable(queryName: "RunningSchedulesInformationByMonthRow_fragmentQuery") {
        id
        name
        color
        scheduleTimesByMonth(month: $month) {
          timeReport {
            id
          }
          summary {
            totalTime(unit: HOUR) {
              value
              unit
            }
            workedTime(unit: HOUR) {
              value
              unit
            }
            absenceTime(unit: HOUR) {
              value
              unit
            }
          }
          employmentSummaries {
            employment {
              id
              title
            }
            timeReportSummary {
              expectedWorkTime(unit: HOUR) {
                value
                unit
              }
              eventSummaries {
                event {
                  id
                  name
                }
                isWork
                nonRegularPartsSummary {
                  event {
                    id
                    name
                  }
                  duration(unit: SECOND) {
                    value
                    unit
                  }
                }
                unit
                value
              }
            }
          }
          compensatoryTimes {
            employmentSetting {
              id
              title
            }
            timeStore {
              id
              name
              timeStoreEvent {
                id
                name
              }
            }
            times {
              description
              time(unit: HOUR) {
                value
                unit
              }
            }
            totalTime {
              value
              unit
            }
          }
        }
      }
    `, queryRef);
    const [isOpen, setIsOpen] = useState(false);
    const hasNoTimes = schedulableEntity.scheduleTimesByMonth.employmentSummaries.length == 0;
    return (<div key={schedulableEntity.id}>
      <Flexbox justify="space" gap="xxsmall" align="center">
        <Flexbox direction="row" gap="xxsmall" align="center">
          <IconButton icon={hasNoTimes ? null : isOpen ? faAngleUp : faAngleRight} appearance="text" color="default" size="xsmall" onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (hasNoTimes) {
                return;
            }
            setIsOpen(!isOpen);
        }}/>
          <Avatar size="xsmall" text={schedulableEntity.name} color={schedulableEntity.color || '#D5D6D8'}/>
          <span>{schedulableEntity.name}</span>
        </Flexbox>
        <Text size="small">
          {schedulableEntity.scheduleTimesByMonth.timeReport != null && (<Tooltip content="Från tidrapport" style={{ marginRight: '0.3em' }}>
              <a target="_blank" href={`${frontUrls['TIME']}time-reports/all/${schedulableEntity.scheduleTimesByMonth.timeReport.id}`} rel="noreferrer">
                <Icon icon={faClock} color={sonikaTheme.colors.system.go}/>
              </a>
            </Tooltip>)}
          {schedulableEntity.scheduleTimesByMonth.timeReport == null && (<Tooltip content="Från schema" style={{ marginRight: '0.3em' }}>
              <Icon icon={faCalendar} color={sonikaTheme.colors.primary}/>
            </Tooltip>)}
          {hasNoTimes ? ('-') : (<>
              {formatTimeSpan(schedulableEntity.scheduleTimesByMonth.summary.totalTime) !=
                formatTimeSpan(schedulableEntity.scheduleTimesByMonth.summary.workedTime) && (<>
                  <Tooltip content="Total tid">
                    {formatTimeSpan(schedulableEntity.scheduleTimesByMonth.summary.totalTime)}
                  </Tooltip>
                  <span> / </span>
                </>)}
              <Tooltip content="Arbetstid">
                {formatTimeSpan(schedulableEntity.scheduleTimesByMonth.summary.workedTime)}
              </Tooltip>
            </>)}
        </Text>
      </Flexbox>
      {isOpen && (<Flexbox direction="column" justify="equal" gap="xxsmall" style={{ marginLeft: '3.5rem' }}>
          {schedulableEntity.scheduleTimesByMonth.employmentSummaries.map((summary, index) => {
                var _a, _b, _c;
                return (<Flexbox direction="column" gap="xxsmall" key={`summary-${schedulableEntity.id}-${index}`}>
              <Flexbox justify="space">
                <Text size="medium">
                  {((_a = summary.employment) === null || _a === void 0 ? void 0 : _a.title) || (<Tooltip placement="top" content="Anställning saknas. Deltider (OB, etc) kan inte beräknas.">
                      <span>
                        <Icon color={sonikaTheme.colors.system.stop} icon={faExclamationTriangle}/> Utanför anställning
                      </span>
                    </Tooltip>)}
                </Text>
                <Text size="small">
                  Förväntad: {formatTimeSpan((_b = summary.timeReportSummary) === null || _b === void 0 ? void 0 : _b.expectedWorkTime, { nullText: '-' })}
                </Text>
              </Flexbox>
              {(_c = summary.timeReportSummary) === null || _c === void 0 ? void 0 : _c.eventSummaries.flat().sort((time) => (time.isWork ? -1 : 1)).map((time, index) => (<Fragment key={`${schedulableEntity.id}-${index}-time-${time.event.id}`}>
                    <Flexbox justify="space">
                      <Text size="small">{time.event.name}</Text>
                      <Text size="small">{formatValueUnit(time)}</Text>
                    </Flexbox>
                    {time.nonRegularPartsSummary.map((part, index) => (<Flexbox justify="space" key={`${schedulableEntity.id}-${index}-part-${part.event.id}`} style={{ marginLeft: '1rem' }}>
                        <Text size="small">{part.event.name}</Text>
                        <Text size="small">{formatTimeSpan(part.duration)}</Text>
                      </Flexbox>))}
                  </Fragment>))}
            </Flexbox>);
            })}
          <>
            {schedulableEntity.scheduleTimesByMonth.compensatoryTimes != null && (<Fragment>
                {schedulableEntity.scheduleTimesByMonth.compensatoryTimes.length == 0 ? (<Text size="small"></Text>) : (<>
                    <Text size="small" bold>
                      Timbanker
                    </Text>
                    {schedulableEntity.scheduleTimesByMonth.compensatoryTimes.map((timesByEvent) => (<Fragment key={`${schedulableEntity.id}-timesByEvent-${timesByEvent.timeStore.id}-${timesByEvent.employmentSetting.id}`}>
                        <Text size="small" bold>
                          {timesByEvent.timeStore.name}
                        </Text>
                        <Flexbox justify="space">
                          <Text size="small">{timesByEvent.employmentSetting.title}, totalt</Text>
                          <Text size="small">{formatTimeSpan(timesByEvent.totalTime)}</Text>
                        </Flexbox>
                        {timesByEvent.times.map((time, index) => (<Fragment key={`${schedulableEntity.id}-${index}-timesByEvent2-${timesByEvent.employmentSetting.id}-${timesByEvent.timeStore.id}-${index}`}>
                            <Flexbox justify="space" style={{ marginLeft: '1rem' }}>
                              <Text size="small">{time.description}</Text>
                              <Text size="small">{formatTimeSpan(time.time)}</Text>
                            </Flexbox>
                          </Fragment>))}
                      </Fragment>))}
                  </>)}
              </Fragment>)}
          </>
        </Flexbox>)}
      <Divider style={{ marginTop: '0.2em' }}/>
    </div>);
};
