import React, { Suspense, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import AbsenceCalendarHeader from './components/AbsenceCalendarHeader';
import { ScheduleLayout, ScheduleLayoutContent, ScheduleLayoutHeader, ScheduleLayoutMain, } from '~/pages/SchedulePages/components/ScheduleLayout/ScheduleLayout';
import { AbsenceCalendarPageContextProvider, defaultAbsenceCalendarPageValues } from './AbsenceCalendarPageContext';
import { endOfMonth, startOfMonth } from 'date-fns';
import AbsenceCalendarGrid from './components/AbsenceCalendarGrid/AbsenceCalendarGrid';
import { Skeleton } from '@sonika-se/ui-kit/components';
import { formatISO } from 'date-fns/esm';
import AbsenceCalendarGridDates from './components/AbsenceCalendarGrid/AbsenceCalendarGridDates';
export const absenceCalendarPageQuery = graphql `
  query AbsenceCalendarPageQuery($toDate: DateTime!, $fromDate: DateTime!, $where: SchedulableEntityFilterInput!) {
    ...AbsenceCalendarGridQuery @arguments(fromDate: $fromDate, toDate: $toDate, where: $where)
    ...AbsenceCalendarFilter_options
  }
`;
export const AbsenceCalendarPage = () => {
    const data = useLazyLoadQuery(absenceCalendarPageQuery, {
        fromDate: formatISO(defaultAbsenceCalendarPageValues.fromDate, { representation: 'complete' }),
        toDate: formatISO(defaultAbsenceCalendarPageValues.toDate, { representation: 'complete' }),
        where: {
            type: { eq: 'USER' },
            schedulable: { eq: true },
        },
    });
    const [context, setContext] = useState(Object.assign({}, defaultAbsenceCalendarPageValues));
    return (<AbsenceCalendarPageContextProvider value={context}>
      <ScheduleLayout>
        <ScheduleLayoutHeader>
          <AbsenceCalendarHeader queryRef={data} onDateChange={(date) => {
            setContext(Object.assign(Object.assign({}, context), { toDate: endOfMonth(date), fromDate: startOfMonth(date) }));
        }} onFilterChange={(filters) => {
            setContext(Object.assign(Object.assign({}, context), { filters: Object.assign(Object.assign({}, context.filters), filters) }));
        }}/>
        </ScheduleLayoutHeader>
        <ScheduleLayoutMain>
          <ScheduleLayoutContent border={true} padding={false}>
            <AbsenceCalendarGridDates onFilterChange={(filters) => {
            setContext(Object.assign(Object.assign({}, context), { filters: Object.assign(Object.assign(Object.assign({}, context.filters), filters), { resourceIds: [] }) }));
        }}/>
            <Suspense fallback={<Skeleton type="page" height="100%"/>}>
              <AbsenceCalendarGrid queryRefSchedulableEntities={data} onFilterChange={(filters) => {
            setContext(Object.assign(Object.assign({}, context), { filters: Object.assign(Object.assign(Object.assign({}, context.filters), filters), { resourceIds: [] }) }));
        }}/>
            </Suspense>
          </ScheduleLayoutContent>
        </ScheduleLayoutMain>
      </ScheduleLayout>
    </AbsenceCalendarPageContextProvider>);
};
export default AbsenceCalendarPage;
