import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { Field, Form, Input } from '@sonika-se/ui-kit/components';
export const userCreateFormValidationSchema = object({
    name: string().required(),
});
export const ScheduleBasicForm = forwardRef((props, forwardedRef) => {
    var _a;
    const { disabled, defaultValues, onSubmit } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(userCreateFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        <Field error={(_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message} label="Namn">
          <Controller control={control} name="name" render={({ field }) => {
            var _a;
            return (<Input disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
        </Field>
      </Form>);
});
