import React, { Suspense } from 'react';
import { Flexbox, H4, ModalToSheetSkeleton, Placeholder } from '@sonika-se/ui-kit/components';
import { addMonths, format, formatISO } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { createUseStyles } from 'react-jss';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import AssetModal, { assetModalQuery } from '~/components/AssetModal';
import ColleagueModal, { colleagueModalQuery } from '~/components/ColleagueModal';
import SchedulableEntityWithText from '~/components/SchedulableEntityWithText';
import ScheduleShiftAssetValidations from '~/components/ScheduleShiftAssetValidations';
const useStyles = createUseStyles((theme) => ({
    simultaneousScheduleShifts: {
        display: 'grid',
        gridGap: '1rem',
        gridTemplateColumns: '1fr 1fr',
        [`@media (max-width: ${theme.breakPoints.low})`]: {
            gridTemplateColumns: '1fr',
        },
    },
}));
const SimultaneousShifts = (props) => {
    const classes = useStyles();
    const [colleagueModalQueryRef, loadColleagueModalQuery, disposeColleagueModalQuery] = useQueryLoader(colleagueModalQuery);
    const [assetModalQueryRef, loadAssetModalQuery, disposeAssetModalQuery] = useQueryLoader(assetModalQuery);
    const fromDate = new Date();
    const toDate = new Date(addMonths(fromDate, 1));
    const data = useFragment(graphql `
      fragment SimultaneousShifts_scheduleShift on ScheduleShift {
        simultaneousScheduleShift {
          edges {
            node {
              id
              assignedEntity {
                ... on SchedulableUser {
                  user {
                    id
                  }
                }
                ... on SchedulableAsset {
                  asset {
                    id
                  }
                }
                ...SchedulableEntityWithText_iScheduleableEntity
              }
              assignmentType {
                title
              }
              end
              start
              isVacant
              status
              ...ScheduleShiftAssetValidations_scheduleShift
            }
          }
        }
      }
    `, props.scheduleShiftRef);
    return (<Flexbox direction="column" gap="xsmall">
      <H4>Samtidiga pass</H4>
      {!data.simultaneousScheduleShift.edges.length && <Placeholder text="Inga samtidiga pass"/>}
      <div className={classes.simultaneousScheduleShifts}>
        {data.simultaneousScheduleShift.edges.map(({ node: shift }) => {
            const { assignedEntity } = shift;
            return (<SchedulableEntityWithText shiftStatus={shift.status} isVacant={shift.isVacant} key={shift.id} iScheduleableEntityRef={shift.assignedEntity} appearance="square" onClick={!shift.assignedEntity
                    ? null
                    : () => {
                        var _a, _b;
                        if ((_a = shift.assignedEntity) === null || _a === void 0 ? void 0 : _a.user) {
                            loadColleagueModalQuery({
                                id: assignedEntity.user.id,
                                myScheduleModel: {
                                    fromDate: formatISO(fromDate),
                                    toDate: formatISO(toDate),
                                },
                            });
                        }
                        else if ((_b = shift.assignedEntity) === null || _b === void 0 ? void 0 : _b.asset) {
                            loadAssetModalQuery({
                                id: assignedEntity.asset.id,
                                myScheduleModel: {
                                    fromDate: formatISO(fromDate),
                                    toDate: formatISO(toDate),
                                },
                            });
                        }
                    }}>
              <ScheduleShiftAssetValidations parent={shift}/>
              &nbsp;
              {`${shift.assignmentType.title}, `}
              {`${format(new Date(shift.start.toString()), 'HH:mm', { locale })}-${format(new Date(shift.end.toString()), 'HH:mm', { locale })}`}
            </SchedulableEntityWithText>);
        })}
      </div>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
        {colleagueModalQueryRef && (<ColleagueModal fromDate={fromDate} toDate={toDate} onClose={() => disposeColleagueModalQuery()} preloadedQuery={colleagueModalQueryRef}/>)}
      </Suspense>

      <Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
        {assetModalQueryRef && (<AssetModal fromDate={fromDate} toDate={toDate} onClose={() => disposeAssetModalQuery()} preloadedQuery={assetModalQueryRef}/>)}
      </Suspense>
    </Flexbox>);
};
export default SimultaneousShifts;
