/**
 * @generated SignedSource<<a084a05cfa3c7bd8cda57a07a9deef9b>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "entityId"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "includeEntity"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "entityId"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentTypeConnection",
        "kind": "LinkedField",
        "name": "assignmentTypes",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentType",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    },
                    (v3 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v6 = [
        (v5 /*: any*/)
    ], v7 = [
        (v5 /*: any*/),
        (v3 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "AddPlannedAbsenceModalQuery",
            "selections": [
                {
                    "condition": "includeEntity",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v2 /*: any*/),
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "schedulableEntity",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": (v6 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "SchedulableUser",
                                            "abstractKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Asset",
                                                    "kind": "LinkedField",
                                                    "name": "asset",
                                                    "plural": false,
                                                    "selections": (v6 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "SchedulableAsset",
                                            "abstractKey": null
                                        }
                                    ],
                                    "type": "ISchedulableEntity",
                                    "abstractKey": "__isISchedulableEntity"
                                }
                            ],
                            "storageKey": null
                        }
                    ]
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "AddPlannedAbsenceModalQuery",
            "selections": [
                {
                    "condition": "includeEntity",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v2 /*: any*/),
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "schedulableEntity",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": (v7 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "SchedulableUser",
                                            "abstractKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Asset",
                                                    "kind": "LinkedField",
                                                    "name": "asset",
                                                    "plural": false,
                                                    "selections": (v7 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "SchedulableAsset",
                                            "abstractKey": null
                                        }
                                    ],
                                    "type": "ISchedulableEntity",
                                    "abstractKey": "__isISchedulableEntity"
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v3 /*: any*/)
                                    ],
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                }
                            ],
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "cacheID": "764608a24cb1b9b31ec73630e86e3517",
            "id": null,
            "metadata": {},
            "name": "AddPlannedAbsenceModalQuery",
            "operationKind": "query",
            "text": "query AddPlannedAbsenceModalQuery(\n  $includeEntity: Boolean!\n  $entityId: ID\n) {\n  schedulableEntity(id: $entityId) @include(if: $includeEntity) {\n    __typename\n    ... on ISchedulableEntity {\n      __isISchedulableEntity: __typename\n      id\n      displayName\n      ... on SchedulableUser {\n        user {\n          assignmentTypes {\n            nodes {\n              title\n              id\n            }\n          }\n          id\n        }\n      }\n      ... on SchedulableAsset {\n        asset {\n          assignmentTypes {\n            nodes {\n              title\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "480df687645c9a082b9f8db073705c06";
export default node;
