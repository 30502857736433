import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { Badge, Icon, Tooltip } from '@sonika-se/ui-kit/components';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { groupBy } from '@sonika-se/ui-kit/utils/misc';
import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
const ScheduleTemplateShiftAssetValidationGroup = (props) => {
    const { parent } = props;
    const data = useFragment(graphql `
      fragment ScheduleTemplateShiftAssetValidationGroup_scheduleTemplateShift on ScheduleTemplateShiftGroup {
        shifts {
          assetValidations {
            schedulableAsset {
              asset {
                id
                name
                assetNumber
                validateProficiency
              }
            }
            schedulableUser {
              user {
                fullName
              }
            }
            userAssignmentType {
              title
            }
            assetProficiency {
              isValid
            }
          }
        }
      }
    `, parent);
    const validations = useMemo(() => {
        const filtered = data.shifts
            .flatMap((x) => x.assetValidations)
            .filter((x) => x.schedulableAsset.asset.validateProficiency)
            .filter((x) => !x.assetProficiency.isValid);
        const groupedByAssetName = groupBy(filtered, (x) => x.schedulableAsset.asset.name);
        if (groupedByAssetName.length) {
            console.log({ filtered, groupedByAssetName });
        }
        const result = groupedByAssetName.map((x) => {
            const text = x.items
                .map((y) => `${y.schedulableUser.user.fullName} saknar behörighet ${y.userAssignmentType.title} för ${x.group}`)
                .join('\n');
            return {
                count: x.items.length,
                text: text,
            };
        });
        return result;
    }, [data]);
    return (<>
      {validations.map((data, index) => (<div key={`proficiency-${index}`}>
          <Tooltip placement="top" content={data.text} style={{ display: 'flex', alignItems: 'center' }}>
            {data.count > 1 && (<Badge appearance={'danger'} text={data.count.toString()} position={'inline'} icon={undefined}></Badge>)}
            &nbsp;
            <Icon icon={faExclamationTriangle} size={'xs'} color={sonikaTheme.colors.system.stop}/>
          </Tooltip>
        </div>))}
    </>);
};
export default ScheduleTemplateShiftAssetValidationGroup;
