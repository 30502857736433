/**
 * @generated SignedSource<<8908c13b8175d0bc85768e730ff7a774>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "endDate"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "startDate"
    }, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "startPeriodId"
    }, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "typeFilter"
    }, v5 = {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
    }, v6 = {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
    }, v7 = {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
    }, v8 = {
        "kind": "Variable",
        "name": "startPeriodId",
        "variableName": "startPeriodId"
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleTemplateInformationResourceQuery",
            "selections": [
                {
                    "args": [
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        {
                            "kind": "Variable",
                            "name": "typeFilter",
                            "variableName": "typeFilter"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "ScheduleTemplateInformationResource_scheduleTimes"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v2 /*: any*/),
                (v0 /*: any*/),
                (v3 /*: any*/),
                (v1 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ScheduleTemplateInformationResourceQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "fields": [
                                {
                                    "kind": "Literal",
                                    "name": "dryRun",
                                    "value": false
                                },
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/)
                            ],
                            "kind": "ObjectValue",
                            "name": "model"
                        },
                        {
                            "fields": [
                                {
                                    "kind": "Variable",
                                    "name": "types",
                                    "variableName": "typeFilter"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "typeFilter"
                        }
                    ],
                    "concreteType": "ScheduleTimesPayload",
                    "kind": "LinkedField",
                    "name": "scheduleTemplateTimes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleTimeByEntityAndAssignmentType",
                            "kind": "LinkedField",
                            "name": "timesByEntityAndAssignmentType",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "unit",
                                            "value": "HOUR"
                                        }
                                    ],
                                    "concreteType": "TimeSpanUnitValue",
                                    "kind": "LinkedField",
                                    "name": "assignedDurationWithoutUnpaidBreaks",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "unit",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "value",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "assignedDurationWithoutUnpaidBreaks(unit:\"HOUR\")"
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "assignmentType",
                                    "plural": false,
                                    "selections": [
                                        (v9 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "schedulableEntity",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v9 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "color",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "displayName",
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "ISchedulableEntity",
                                            "abstractKey": "__isISchedulableEntity"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v9 /*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "42faa575c5714c92ffa544863866601a",
            "id": null,
            "metadata": {},
            "name": "ScheduleTemplateInformationResourceQuery",
            "operationKind": "query",
            "text": "query ScheduleTemplateInformationResourceQuery(\n  $startDate: DateTime!\n  $endDate: DateTime!\n  $startPeriodId: ID!\n  $id: ID!\n  $typeFilter: [SchedulableEntityType!]\n) {\n  ...ScheduleTemplateInformationResource_scheduleTimes_3N107r\n}\n\nfragment ScheduleTemplateInformationResource_scheduleTimes_3N107r on Query {\n  scheduleTemplateTimes(model: {startDate: $startDate, endDate: $endDate, dryRun: false, id: $id, startPeriodId: $startPeriodId}, typeFilter: {types: $typeFilter}) {\n    timesByEntityAndAssignmentType {\n      assignedDurationWithoutUnpaidBreaks(unit: HOUR) {\n        unit\n        value\n      }\n      assignmentType {\n        id\n        title\n      }\n      schedulableEntity {\n        __typename\n        ... on ISchedulableEntity {\n          __isISchedulableEntity: __typename\n          id\n          color\n          displayName\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "18b0513d59c5c5de188f2394a31038d9";
export default node;
