import React, { useState } from 'react';
import { Button, Collapse, Divider } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles(() => {
    return {
        openButton: ({ showInformation }) => ({
            background: 'white',
            visibility: showInformation ? 'hidden' : 'visible',
            transitionDelay: showInformation ? '0s' : '.15s',
        }),
        buttonContainer: {
            position: 'relative',
            padding: '1rem 0',
            marginLeft: '-1rem',
            marginRight: '-1rem',
            textAlign: 'center',
        },
        bottomButtonContainer: ({ showInformation }) => ({
            padding: showInformation ? '1rem 0' : '0',
            visibility: showInformation ? 'visible' : 'hidden',
            transitionDelay: showInformation ? '0s' : '.15s',
        }),
        button: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    };
});
const CollapseSection = (props) => {
    const { children } = props;
    const [showInformation, setShowInformation] = useState(false);
    const classes = useStyles({ showInformation });
    return (<div>
      <div className={classes.buttonContainer}>
        <Divider />
        <div className={classes.button}>
          <Button size="small" appearance="outlined" className={classes.openButton} onClick={() => setShowInformation(!showInformation)}>
            Mer information
          </Button>
        </div>
      </div>
      <Collapse open={showInformation}>
        <div style={{ padding: '1rem 0' }}>{children}</div>
      </Collapse>
      <div className={[classes.buttonContainer, classes.bottomButtonContainer].join(' ')}>
        <Divider />
        <div className={classes.button}>
          <Button size="small" appearance="outlined" style={{ background: 'white' }} onClick={() => setShowInformation(!showInformation)}>
            Dölj information
          </Button>
        </div>
      </div>
    </div>);
};
export default CollapseSection;
