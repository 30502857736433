/**
 * @generated SignedSource<<de02408e8f5d6c8e99f9a83e97757be0>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "after"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "first"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "SchedulableResourceEditColor_resources",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "after",
                        "variableName": "after"
                    },
                    {
                        "kind": "Variable",
                        "name": "first",
                        "variableName": "first"
                    }
                ],
                "concreteType": "SchedulableEntityConnection",
                "kind": "LinkedField",
                "name": "schedulableEntities",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SchedulableEntityEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                "kind": "LinkedField",
                                                "name": "resourceInScheduleAssignmentTypes",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ScheduleAssignmentTypeResource",
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v0 /*: any*/),
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "scheduleAssignmentTypeId",
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "ScheduleAssignmentType",
                                                                        "kind": "LinkedField",
                                                                        "name": "scheduleAssignmentType",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "concreteType": "AssignmentType",
                                                                                "kind": "LinkedField",
                                                                                "name": "assignmentType",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    (v0 /*: any*/),
                                                                                    {
                                                                                        "alias": null,
                                                                                        "args": null,
                                                                                        "kind": "ScalarField",
                                                                                        "name": "title",
                                                                                        "storageKey": null
                                                                                    }
                                                                                ],
                                                                                "storageKey": null
                                                                            }
                                                                        ],
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "ISchedulableEntity",
                                        "abstractKey": "__isISchedulableEntity"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "User",
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "firstname",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "lastname",
                                                        "storageKey": null
                                                    },
                                                    (v1 /*: any*/)
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "SchedulableUser",
                                        "abstractKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Asset",
                                                "kind": "LinkedField",
                                                "name": "asset",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "name",
                                                        "storageKey": null
                                                    },
                                                    (v1 /*: any*/)
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "SchedulableAsset",
                                        "abstractKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "e72f42ddfaed2d4aba713f4b8d9e45b0";
export default node;
