import React, { Suspense, useState } from 'react';
import { graphql, usePaginationFragment, useMutation } from 'react-relay/hooks';
import { ModalToSheetSkeleton, Placeholder, Button, Accordion } from '@sonika-se/ui-kit/components';
import { FormattedMessage } from 'react-intl';
import { faColumns } from '@fortawesome/pro-light-svg-icons';
import EditScheduleModal from '../components/EditScheduleModal/EditScheduleModal';
import ScheduleListRow from './ScheduleListRow';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
graphql `
  fragment SchedulesTable_schedule on Schedule {
    id
    name
    templates {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
const SchedulesTable = (props) => {
    const { queryRef, setShowNewScheduleModal, loadNewTemplateModal } = props;
    const [scheduleToEdit, setScheduleToEdit] = useState();
    const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment(graphql `
      fragment SchedulesTable_query on Query
      @argumentDefinitions(first: { type: "Int", defaultValue: 30 }, after: { type: "String" })
      @refetchable(queryName: "SchedulesTablePaginationQuery") {
        schedules(after: $after, first: $first) @connection(key: "SchedulesTable_schedules", filters: []) {
          edges {
            node {
              id
              name
              ...ScheduleListRow_schedule
            }
          }
        }
      }
    `, queryRef);
    const [deleteSchedule, deleteScheduleLoading] = useMutation(graphql `
    mutation SchedulesTableDeleteScheduleMutation($model: DeleteScheduleRequestInput!) {
      deleteSchedule(model: $model) {
        scheduleEdge {
          node {
            id
            name
          }
        }
      }
    }
  `);
    const [updateSchedule, updateScheduleLoading] = useMutation(graphql `
    mutation SchedulesTableUpdateScheduleMutation($model: UpdateScheduleRequestInput!) {
      updateSchedule(model: $model) {
        scheduleEdge {
          node {
            id
            name
            ...ScheduleListRow_schedule
          }
        }
      }
    }
  `);
    const hasPermissions = usePermissions();
    return (<>
      {!data.schedules.edges.length && hasPermissions(['EDIT_SCHEDULES']) && (<Placeholder icon={faColumns} title="Det behövs ett schema för att komma igång">
          <Button onClick={() => setShowNewScheduleModal(true)}>
            <FormattedMessage id="general.objects.schedule.actions.create" defaultMessage="Nytt schema"/>
          </Button>
        </Placeholder>)}
      <Accordion>
        {data.schedules.edges.map(({ node: schedule }) => (<ScheduleListRow disabled={deleteScheduleLoading} key={schedule.id} schedule={schedule} loadNewTemplateModal={loadNewTemplateModal} setResult={(id) => {
                setScheduleToEdit(id);
            }} archiveSchedule={(id, archive, inactivatedFrom) => {
                updateSchedule({
                    variables: {
                        model: {
                            scheduleId: id,
                            archived: archive,
                            inactivatedFrom: inactivatedFrom,
                        },
                    },
                });
            }} deleteSchedule={(id) => {
                deleteSchedule({
                    variables: {
                        model: {
                            scheduleId: id,
                        },
                    },
                    configs: [
                        {
                            connectionKeys: [
                                {
                                    key: 'SchedulesTable_schedules',
                                },
                            ],
                            parentID: 'client:root',
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['scheduleEdge', 'node'],
                            pathToConnection: ['client:root', 'schedules'],
                        },
                    ],
                });
            }}/>))}
      </Accordion>
      {hasNext && (<Button disabled={isLoadingNext || deleteScheduleLoading} onClick={() => {
                loadNext(30);
            }}>
          <FormattedMessage defaultMessage="Ladda fler" id="general.actions.load_more"/>
        </Button>)}
      {scheduleToEdit && (<Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
          <EditScheduleModal id={scheduleToEdit} onClose={() => {
                setScheduleToEdit(undefined);
            }}/>
        </Suspense>)}
    </>);
};
export default SchedulesTable;
