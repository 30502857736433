/**
 * @generated SignedSource<<9533153d2aeec8903ffba5e7fa3dc660>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        },
        {
            "defaultValue": 10,
            "kind": "LocalArgument",
            "name": "first"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ], v1 = {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
    }, v2 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    }, v3 = {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
    }, v4 = [
        (v1 /*: any*/),
        (v2 /*: any*/),
        {
            "kind": "Literal",
            "name": "model",
            "value": {
                "onlyActive": true
            }
        },
        (v3 /*: any*/)
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SchedulableEntitiesSearchRefetchQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "SchedulableEntitiesSearch_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SchedulableEntitiesSearchRefetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "SchedulableEntityConnection",
                    "kind": "LinkedField",
                    "name": "schedulableEntities",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableEntityEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "displayName",
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "ISchedulableEntity",
                                            "abstractKey": "__isISchedulableEntity"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v5 /*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "filters": [
                        "model",
                        "where"
                    ],
                    "handle": "connection",
                    "key": "SchedulableEntitiesSearch_query_schedulableEntities",
                    "kind": "LinkedHandle",
                    "name": "schedulableEntities"
                }
            ]
        },
        "params": {
            "cacheID": "cff3b274fcb711cc793536911af3a552",
            "id": null,
            "metadata": {},
            "name": "SchedulableEntitiesSearchRefetchQuery",
            "operationKind": "query",
            "text": "query SchedulableEntitiesSearchRefetchQuery(\n  $after: String\n  $first: Int = 10\n  $where: SchedulableEntityFilterInput\n) {\n  ...SchedulableEntitiesSearch_query_3sueo6\n}\n\nfragment SchedulableEntitiesSearch_query_3sueo6 on Query {\n  schedulableEntities(after: $after, first: $first, model: {onlyActive: true}, where: $where) {\n    edges {\n      node {\n        __typename\n        ... on ISchedulableEntity {\n          __isISchedulableEntity: __typename\n          id\n          displayName\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "ca129a4bf23c1bde83344edf183be1e5";
export default node;
