import React from 'react';
export const defaultScheduleTemplateDetailsPagevalues = {
    filters: {
        resourceIds: [],
        assignmentTypeIds: [],
        scheduleIds: [],
        onlyVacant: undefined,
    },
    showInformationDrawer: false,
};
export const MySchedulePageContext = React.createContext(defaultScheduleTemplateDetailsPagevalues);
export const MySchedulePageContextProvider = MySchedulePageContext.Provider;
export default MySchedulePageContext;
