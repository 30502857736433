import React, { useState, useEffect } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { formatISO, addDays } from 'date-fns';
import { getConnection } from 'relay-runtime/lib/handlers/connection/ConnectionHandler';
import { ConnectionHandler } from 'relay-runtime';
import { Wizard, Flexbox, Button, ModalToSheetContent } from '@sonika-se/ui-kit/components';
import RunningSchedulesDeleteShiftsWizardStep1 from './RunningSchedulesDeleteShiftsWizardStep1';
import RunningSchedulesDeleteShiftsWizardStep2 from './RunningSchedulesDeleteShiftsWizardStep2';
import RunningSchedulesDeleteShiftsWizardStep3 from './RunningSchedulesDeleteShiftsWizardStep3';
import RunningSchedulesDeleteShiftsWizardStep4 from './RunningSchedulesDeleteShiftsWizardStep4';
import { FormattedMessage } from 'react-intl';
export const RunningSchedulesDeleteShiftsWizard = (props) => {
    var _a;
    const { onClose, onSubmit, query } = props;
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [form, setForm] = useState({
        assignmentTypeId: '',
        currentlyAssignedEntityId: '',
        fromDate: null,
        scheduleId: '',
        toDate: null,
    });
    const data = useFragment(graphql `
      fragment RunningSchedulesDeleteShiftsWizard_query on Query {
        schedules(filterModel: { withCurrentUserPermissions: [EDIT_RUNNING] }) {
          edges {
            node {
              id
              ...RunningSchedulesDeleteShiftsWizardStep2_schedule
            }
          }
        }
        ...RunningSchedulesDeleteShiftsWizardStep1_query
      }
    `, query);
    const schedule = (_a = data.schedules.edges.find(({ node: schedule }) => schedule.id === (form === null || form === void 0 ? void 0 : form.scheduleId))) === null || _a === void 0 ? void 0 : _a.node;
    const onStepSubmit = (step) => {
        setForm(Object.assign(Object.assign({}, form), step));
        setCurrentStepIndex(currentStepIndex + 1);
    };
    const [deleteScheduleShiftDuring, deleteScheduleShiftDuringLoading] = useMutation(graphql `
        mutation RunningSchedulesDeleteShiftsWizard_deleteScheduleShiftDuringMutation(
          $model: DeleteScheduleShiftDuringRequestInput!
        ) {
          deleteScheduleShiftDuring(model: $model) {
            shiftEdge {
              node {
                id
                end
                start
              }
            }
          }
        }
      `);
    const [numShiftsToBeDeleted, setNumShiftsToBeDeleted] = useState(null);
    const [isLastStep, setIsLastStep] = useState(false);
    useEffect(() => {
        setIsLastStep(currentStepIndex === 3);
    }, [currentStepIndex]);
    useEffect(() => {
        if (!isLastStep) {
            return;
        }
        setNumShiftsToBeDeleted(null);
        deleteScheduleShiftDuring({
            onCompleted: (response) => {
                var _a;
                setNumShiftsToBeDeleted(((_a = response === null || response === void 0 ? void 0 : response.deleteScheduleShiftDuring) === null || _a === void 0 ? void 0 : _a.length) || 0);
            },
            variables: {
                model: {
                    dryRun: true,
                    fromDate: formatISO(form.fromDate, { representation: 'date' }),
                    toDate: formatISO(addDays(form.toDate, 1), { representation: 'date' }),
                    scheduleId: form.scheduleId,
                    assignmentTypeId: form.assignmentTypeId,
                    currentlyAssignedEntityId: form.currentlyAssignedEntityId === 'vakant' ? null : form.currentlyAssignedEntityId,
                },
            },
        });
    }, [isLastStep, form]);
    return (<>
      <Wizard currentStep={currentStepIndex} onStepChange={setCurrentStepIndex} steps={[
            {
                render: ({ id, state }) => (<RunningSchedulesDeleteShiftsWizardStep1 data={{
                        scheduleId: form.scheduleId,
                    }} id={id} state={state} onSubmit={onStepSubmit} query={data}/>),
                title: 'Inom schema',
            },
            {
                render: ({ id, state }) => (<RunningSchedulesDeleteShiftsWizardStep2 data={{
                        assignmentTypeId: form.assignmentTypeId,
                        currentlyAssignedEntityId: form.currentlyAssignedEntityId,
                    }} id={id} state={state} schedule={schedule} onSubmit={onStepSubmit}/>),
                title: 'Pass för',
            },
            {
                render: ({ id, state }) => (<RunningSchedulesDeleteShiftsWizardStep3 data={{
                        fromDate: form.fromDate,
                        toDate: form.toDate,
                    }} id={id} state={state} onSubmit={onStepSubmit}/>),
                title: 'Mellan datum',
            },
            {
                render: ({ id, state }) => (<RunningSchedulesDeleteShiftsWizardStep4 id={id} numShiftsToBeDeleted={numShiftsToBeDeleted} state={state}/>),
                title: 'Bekräfta',
            },
        ]}/>
      <ModalToSheetContent>
        <Flexbox justify="end" gap="xsmall">
          <Button appearance="text" color="dark" onClick={() => onClose()}>
            <FormattedMessage defaultMessage="Avbryt" id="general.actions.cancel"/>
          </Button>
          <Button disabled={!isLastStep || deleteScheduleShiftDuringLoading || numShiftsToBeDeleted === 0} onClick={() => {
            deleteScheduleShiftDuring({
                updater: (store) => {
                    const payload = store.getPluralRootField('deleteScheduleShiftDuring');
                    const deletedIds = payload.map((p) => p.getLinkedRecord('shiftEdge').getLinkedRecord('node').getDataID());
                    const schedule = store.get(form.scheduleId);
                    const connection = getConnection(schedule, 'RuningSchedulesGridSchedule_runningSchedule');
                    deletedIds.forEach((id) => {
                        ConnectionHandler.deleteNode(connection, id);
                    });
                },
                onCompleted: () => {
                    onSubmit();
                },
                variables: {
                    model: {
                        dryRun: false,
                        fromDate: formatISO(form.fromDate, { representation: 'date' }),
                        toDate: formatISO(addDays(form.toDate, 1), { representation: 'date' }),
                        scheduleId: form.scheduleId,
                        assignmentTypeId: form.assignmentTypeId,
                        currentlyAssignedEntityId: form.currentlyAssignedEntityId === 'vakant' ? null : form.currentlyAssignedEntityId,
                    },
                },
            });
        }}>
            Ta bort pass
          </Button>
        </Flexbox>
      </ModalToSheetContent>
    </>);
};
export default RunningSchedulesDeleteShiftsWizard;
