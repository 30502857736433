/**
 * @generated SignedSource<<a9c73ef0c78e016bb25d191461e6a23e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "User_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "1e68d0a9eb2d1863d46e85f434bc4a8c";
export default node;
