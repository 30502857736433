import React, { useMemo } from 'react';
import { Avatar, Flexbox, Text } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import getAssignedEntityAvatarProps from '~/utils/getAssignedEntityAvatarProps';
import getAssignedEntityText from '~/utils/getAssignedEntityText';
export const SchedulableEntityWithText = (props) => {
    const { children, iScheduleableEntityRef, onClick, appearance = 'round', childTextProps, isVacant, size = 'small', shiftStatus, } = props;
    const data = useFragment(graphql `
      fragment SchedulableEntityWithText_iScheduleableEntity on ISchedulableEntity {
        id
        displayName
        color
      }
    `, iScheduleableEntityRef);
    const avatarProps = useMemo(() => getAssignedEntityAvatarProps(isVacant, shiftStatus, data === null || data === void 0 ? void 0 : data.displayName, data === null || data === void 0 ? void 0 : data.color), [data, isVacant, shiftStatus]);
    const displayText = useMemo(() => getAssignedEntityText(isVacant, shiftStatus, data === null || data === void 0 ? void 0 : data.displayName), [data, isVacant, shiftStatus]);
    return (<div onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default' }}>
      <Flexbox align="start" gap="xsmall" style={{ marginTop: '.25rem' }}>
        <Avatar appearance={appearance} size={size} {...avatarProps}/>
        <div>
          <Text>{displayText}</Text>
          <Text {...childTextProps} style={{ color: '#484848' }}>
            {children}
          </Text>
        </div>
      </Flexbox>
    </div>);
};
export default SchedulableEntityWithText;
