var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { Avatar } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import { blobToDataURL } from '@sonika-se/ui-kit/utils/misc';
import { useApi } from '@sonika-se/ui-kit/hooks';
const Company = (props) => {
    const { parent, onClick } = props, rest = __rest(props, ["parent", "onClick"]);
    const [image, setImage] = useState('');
    const { name, avatarImage } = useFragment(graphql `
      fragment Company on Company {
        id
        name
        avatarImage: avatar {
          id
          storageId
          url(queryTransform: "?width=68&height=68")
        }
      }
    `, parent);
    const { fetch } = useApi();
    useEffect(() => {
        let isMounted = true;
        if (avatarImage === null || avatarImage === void 0 ? void 0 : avatarImage.url) {
            fetch(avatarImage.url)
                .then((response) => response.blob())
                .then((blob) => blobToDataURL(blob))
                .then((dataUrl) => {
                if (isMounted) {
                    setImage(dataUrl);
                }
            });
        }
        else {
            setImage(undefined);
        }
        return () => {
            isMounted = false;
        };
    }, [avatarImage]);
    return <Avatar {...rest} text={name} image={image} onClick={onClick}/>;
};
export default Company;
