/**
 * @generated SignedSource<<7e2f2c03a23a1ca820240459b4c50f81>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "after"
    }, v1 = {
        "defaultValue": 10,
        "kind": "LocalArgument",
        "name": "first"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    }, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "runningScheduleModel"
    }, v4 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v5 = {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
    }, v6 = {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v9 = [
        (v5 /*: any*/),
        (v6 /*: any*/),
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "runningScheduleModel"
        }
    ], v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "concreteType": "Avatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "queryTransform",
                        "value": "width=300&height=300&mode=crop&scale=both"
                    }
                ],
                "kind": "ScalarField",
                "name": "url",
                "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
            },
            (v8 /*: any*/)
        ],
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v13 = [
        (v12 /*: any*/)
    ], v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftTable_scheduleQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                {
                                    "kind": "Variable",
                                    "name": "runningScheduleModel",
                                    "variableName": "runningScheduleModel"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "ScheduleShiftTable_schedule"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v3 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ScheduleShiftTable_scheduleQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "currentUserPermissions",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v9 /*: any*/),
                                    "concreteType": "ScheduleShiftConnection",
                                    "kind": "LinkedField",
                                    "name": "runningSchedule",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleShiftEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleShift",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "assignedEntity",
                                                            "plural": false,
                                                            "selections": [
                                                                (v7 /*: any*/),
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Asset",
                                                                            "kind": "LinkedField",
                                                                            "name": "asset",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v10 /*: any*/),
                                                                                (v8 /*: any*/),
                                                                                {
                                                                                    "kind": "InlineFragment",
                                                                                    "selections": [
                                                                                        (v11 /*: any*/),
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": (v13 /*: any*/),
                                                                                            "type": "Asset",
                                                                                            "abstractKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v12 /*: any*/),
                                                                                                (v14 /*: any*/),
                                                                                                (v15 /*: any*/)
                                                                                            ],
                                                                                            "type": "User",
                                                                                            "abstractKey": null
                                                                                        }
                                                                                    ],
                                                                                    "type": "IHaveAvatar",
                                                                                    "abstractKey": "__isIHaveAvatar"
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "SchedulableAsset",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "User",
                                                                            "kind": "LinkedField",
                                                                            "name": "user",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v14 /*: any*/),
                                                                                (v15 /*: any*/),
                                                                                (v8 /*: any*/),
                                                                                {
                                                                                    "kind": "InlineFragment",
                                                                                    "selections": [
                                                                                        (v11 /*: any*/),
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v12 /*: any*/),
                                                                                                (v10 /*: any*/)
                                                                                            ],
                                                                                            "type": "Asset",
                                                                                            "abstractKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v10 /*: any*/)
                                                                                            ],
                                                                                            "type": "Company",
                                                                                            "abstractKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": (v13 /*: any*/),
                                                                                            "type": "User",
                                                                                            "abstractKey": null
                                                                                        }
                                                                                    ],
                                                                                    "type": "IHaveAvatar",
                                                                                    "abstractKey": "__isIHaveAvatar"
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "SchedulableUser",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v8 /*: any*/)
                                                                    ],
                                                                    "type": "Node",
                                                                    "abstractKey": "__isNode"
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "AssignmentType",
                                                            "kind": "LinkedField",
                                                            "name": "assignmentType",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "title",
                                                                    "storageKey": null
                                                                },
                                                                (v8 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleShiftChangeRequestConnection",
                                                            "kind": "LinkedField",
                                                            "name": "changeRequests",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "totalCount",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "end",
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "start",
                                                            "storageKey": null
                                                        },
                                                        (v7 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v9 /*: any*/),
                                    "filters": [
                                        "model"
                                    ],
                                    "handle": "connection",
                                    "key": "ScheduleShiftTable_schedule_runningSchedule",
                                    "kind": "LinkedHandle",
                                    "name": "runningSchedule"
                                }
                            ],
                            "type": "Schedule",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a6e316d70aa6a3aa190d93649b8375a7",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftTable_scheduleQuery",
            "operationKind": "query",
            "text": "query ScheduleShiftTable_scheduleQuery(\n  $after: String\n  $first: Int = 10\n  $runningScheduleModel: GetRunningScheduleRequestInput!\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ScheduleShiftTable_schedule_4B5fcG\n    id\n  }\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n\nfragment ScheduleShiftTable_schedule_4B5fcG on Schedule {\n  currentUserPermissions\n  id\n  runningSchedule(after: $after, first: $first, model: $runningScheduleModel) {\n    edges {\n      node {\n        assignedEntity {\n          __typename\n          ... on SchedulableAsset {\n            asset {\n              ...Avatar_iHaveAvatar\n              name\n              id\n            }\n          }\n          ... on SchedulableUser {\n            user {\n              ...Avatar_iHaveAvatar\n              firstname\n              lastname\n              id\n            }\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n        assignmentType {\n          title\n          id\n        }\n        changeRequests {\n          totalCount\n        }\n        end\n        id\n        start\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "12883938e860acdbe30f424c08577d55";
export default node;
