import { faCheck, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { Notification } from '@sonika-se/ui-kit/components';
import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
const AssetProficiencyValidationNotification = (props) => {
    const { parent } = props;
    const data = useFragment(graphql `
      fragment AssetProficiencyValidationNotification_scheduleShiftAssetValidation on ScheduleShiftAssetValidation {
        schedulableAsset {
          asset {
            id
            name
            assetNumber
            validateProficiency
          }
        }
        schedulableUser {
          user {
            fullName
          }
        }
        userAssignmentType {
          title
        }
        assetProficiency {
          isValid
        }
      }
    `, parent);
    return (<>
      {data.assetProficiency.isValid ? (<>
          <Notification color="success" shadow={false} icon={faCheck} title="Behörighet" text={`${data.schedulableUser.user.fullName} har behörighet ${data.userAssignmentType.title} för ${data.schedulableAsset.asset.name}`}/>
        </>) : (<>
          <Notification color="error" shadow={false} icon={faExclamationTriangle} title="Behörighet" text={`${data.schedulableUser.user.fullName} saknar behörighet ${data.userAssignmentType.title} för ${data.schedulableAsset.asset.name}`}></Notification>
        </>)}
    </>);
};
export default AssetProficiencyValidationNotification;
