/**
 * @generated SignedSource<<9d2ccc6ba2f4e48e7a9bf7824589b291>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "checkedWhere"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SchedulableResourceCheckedNumberCheckedNumberQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "checkedWhere",
                            "variableName": "checkedWhere"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "SchedulableResourceCheckedNumber_resourcesChecked"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SchedulableResourceCheckedNumberCheckedNumberQuery",
            "selections": [
                {
                    "alias": "checkedNumber",
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "where",
                            "variableName": "checkedWhere"
                        }
                    ],
                    "concreteType": "SchedulableEntityConnection",
                    "kind": "LinkedField",
                    "name": "schedulableEntities",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalCount",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "889ee8126d9135067255510d5b051df1",
            "id": null,
            "metadata": {},
            "name": "SchedulableResourceCheckedNumberCheckedNumberQuery",
            "operationKind": "query",
            "text": "query SchedulableResourceCheckedNumberCheckedNumberQuery(\n  $checkedWhere: SchedulableEntityFilterInput\n) {\n  ...SchedulableResourceCheckedNumber_resourcesChecked_309nRW\n}\n\nfragment SchedulableResourceCheckedNumber_resourcesChecked_309nRW on Query {\n  checkedNumber: schedulableEntities(where: $checkedWhere) {\n    totalCount\n  }\n}\n"
        }
    };
})();
node.hash = "38f99ddaf347c4407cd3bf7f754f0d2c";
export default node;
