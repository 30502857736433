import React from 'react';
import { Flexbox, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import ScheduleShiftHistoryVacancyInterestListItem from './ScheduleShiftHistoryVacancyInterestListItem';
export const ScheduleShiftHistoryVacancyInterestList = (props) => {
    const { scheduleShiftHistoryVacancyInterestConnectionRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftHistoryVacancyInterestList_scheduleShiftHistoryVacancyInterestConnection on ScheduleShiftHistoryVacancyInterestConnection {
        nodes {
          ...ScheduleShiftHistoryVacancyInterestListItem_scheduleShiftHistoryVacancyInterest
          id
        }
        totalCount
      }
    `, scheduleShiftHistoryVacancyInterestConnectionRef);
    if (data.totalCount === 0) {
        return <Placeholder text="Inga intresseanmälningar"/>;
    }
    return (<Flexbox direction="column" gap="medium">
      {data.nodes.map((vacancyInterest) => (<ScheduleShiftHistoryVacancyInterestListItem key={vacancyInterest.id} scheduleShiftHistoryVacancyInterestRef={vacancyInterest}/>))}
    </Flexbox>);
};
export default ScheduleShiftHistoryVacancyInterestList;
