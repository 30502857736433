/**
 * @generated SignedSource<<df65571108106d55331b752ed6f7d4f7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "scheduleId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleAssignmentTypeConnection",
        "kind": "LinkedField",
        "name": "assignmentTypes",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleAssignmentTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleAssignmentType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v2 /*: any*/),
                            (v3 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scheduleAssignmentTypeId",
        "storageKey": null
    }, v6 = {
        "kind": "InlineFragment",
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstname",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastname",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "SchedulableUser",
        "abstractKey": null
    }, v7 = {
        "kind": "InlineFragment",
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "SchedulableAsset",
        "abstractKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesSelectFromUserFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableEntityConnection",
                            "kind": "LinkedField",
                            "name": "schedulableEntities",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SchedulableEntityEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                            "kind": "LinkedField",
                                                            "name": "resourceInScheduleAssignmentTypes",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                                                    "kind": "LinkedField",
                                                                    "name": "edges",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ScheduleAssignmentTypeResource",
                                                                            "kind": "LinkedField",
                                                                            "name": "node",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v2 /*: any*/),
                                                                                (v5 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "ScheduleAssignmentType",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "scheduleAssignmentType",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        (v3 /*: any*/)
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "ISchedulableEntity",
                                                    "abstractKey": "__isISchedulableEntity"
                                                },
                                                (v6 /*: any*/),
                                                (v7 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RunningSchedulesSelectFromUserFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableEntityConnection",
                            "kind": "LinkedField",
                            "name": "schedulableEntities",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SchedulableEntityEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                            "kind": "LinkedField",
                                                            "name": "resourceInScheduleAssignmentTypes",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ScheduleAssignmentTypeResourceEdge",
                                                                    "kind": "LinkedField",
                                                                    "name": "edges",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ScheduleAssignmentTypeResource",
                                                                            "kind": "LinkedField",
                                                                            "name": "node",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v2 /*: any*/),
                                                                                (v5 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "ScheduleAssignmentType",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "scheduleAssignmentType",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        (v3 /*: any*/),
                                                                                        (v2 /*: any*/)
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "ISchedulableEntity",
                                                    "abstractKey": "__isISchedulableEntity"
                                                },
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v2 /*: any*/)
                                                    ],
                                                    "type": "Node",
                                                    "abstractKey": "__isNode"
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f2489fae17be0eb44b1eed816385c4f3",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesSelectFromUserFormQuery",
            "operationKind": "query",
            "text": "query RunningSchedulesSelectFromUserFormQuery(\n  $scheduleId: ID!\n) {\n  schedule(id: $scheduleId) {\n    assignmentTypes {\n      edges {\n        node {\n          id\n          assignmentType {\n            id\n            title\n          }\n        }\n      }\n    }\n    schedulableEntities {\n      edges {\n        node {\n          __typename\n          ... on ISchedulableEntity {\n            __isISchedulableEntity: __typename\n            resourceInScheduleAssignmentTypes {\n              edges {\n                node {\n                  id\n                  scheduleAssignmentTypeId\n                  scheduleAssignmentType {\n                    assignmentType {\n                      id\n                      title\n                    }\n                    id\n                  }\n                }\n              }\n            }\n          }\n          ... on SchedulableUser {\n            id\n            user {\n              id\n              firstname\n              lastname\n            }\n          }\n          ... on SchedulableAsset {\n            id\n            asset {\n              id\n              name\n            }\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "e2cbdb9dd9e63eadb8666276e3489515";
export default node;
