import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { Button, Flexbox, Grid, Panel, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { ScheduleCard } from '../cards/ScheduleCard';
export const ScheduleGrid = forwardRef((props, forwardedRef) => {
    const { queryRef, filter } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment ScheduleGrid_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 50 }
        runningScheduleModel: { type: "GetRunningScheduleRequestInput!" }
        where: { type: "ScheduleFilterInput" }
        filter: { type: "ScheduleFilterRequestInput" }
      )
      @refetchable(queryName: "ScheduleGrid_queryQuery") {
        schedules(after: $after, first: $first, order: { sortOrder: ASC }, where: $where, filterModel: $filter)
          @connection(key: "ScheduleGrid_query_schedules", filters: []) {
          edges {
            node {
              ...ScheduleCard_schedule @arguments(runningScheduleModel: $runningScheduleModel)
              id
            }
          }
        }
      }
    `, queryRef);
    useImperativeHandle(forwardedRef, () => ({
        refetch: (vars) => {
            refetch(vars, { fetchPolicy: 'network-only' });
        },
    }), []);
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        refetch({ filter: filter }, { fetchPolicy: 'network-only' });
    }, [filter]);
    const schedules = useMemo(() => data.schedules.edges.map(({ node }) => node), [data]);
    return schedules.length === 0 ? (<Panel>
      <Placeholder text="Inga scheman."/>
    </Panel>) : (<Flexbox direction="column" gap="medium">
      <Grid columns="repeat(3, 1fr)" gap="large">
        {schedules.map((schedule) => (<ScheduleCard key={schedule.id} scheduleRef={schedule}/>))}
      </Grid>
      {hasNext && (<div>
          <Button onClick={() => loadNext(50)} disabled={isLoadingNext}>
            Ladda fler
          </Button>
        </div>)}
    </Flexbox>);
});
export default ScheduleGrid;
