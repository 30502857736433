import React, { Suspense, useEffect, useMemo } from 'react';
import { graphql, useLazyLoadQuery, useQueryLoader } from 'react-relay/hooks';
import { ModalToSheetContent, ModalToSheet, Skeleton, ModalToSheetHeader } from '@sonika-se/ui-kit/components';
import { FormattedMessage } from 'react-intl';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import EditScheduleModalGeneralTab, { editScheduleModalGeneralTabQuery } from './EditScheduleModalGeneralTab';
import EditScheduleModalPermissionsTab, { editScheduleModalPermissionsTabQuery, } from './EditScheduleModalPermissionsTab';
export const editRunningSchedulesModalQuery = graphql `
  query EditScheduleModalQuery($id: ID!) {
    schedule(id: $id) {
      id
      currentUserPermissions
      name
    }
  }
`;
export const EditScheduleModal = (props) => {
    const { id, onClose } = props;
    const { schedule } = useLazyLoadQuery(editRunningSchedulesModalQuery, { id });
    const [editScheduleModalGeneralTabQueryRef, loadEditScheduleModalGeneralTabQuery, disposeEditScheduleModalGeneralTabQuery,] = useQueryLoader(editScheduleModalGeneralTabQuery);
    const [editScheduleModalPermissionsTabQueryRef, loadEditScheduleModalPermissionsTabQuery, disposeEditScheduleModalPermissionsTabQuery,] = useQueryLoader(editScheduleModalPermissionsTabQuery);
    const hasPermissions = usePermissions();
    const canEditInformation = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || schedule.currentUserPermissions.includes('EDIT_INFORMATION'), [hasPermissions, schedule]);
    const canEditPermissions = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || schedule.currentUserPermissions.includes('EDIT_PERMISSIONS'), [hasPermissions, schedule]);
    useEffect(() => {
        if (canEditInformation) {
            loadEditScheduleModalGeneralTabQuery({
                id,
            });
        }
        else if (canEditPermissions) {
            loadEditScheduleModalPermissionsTabQuery({
                id,
            });
        }
    }, []);
    return (<ModalToSheet onClose={() => onClose()}>
      <ModalToSheetHeader closeButtonLabel="Stäng" onClose={() => onClose()} tabs={{
            onChange: (tab) => {
                disposeEditScheduleModalGeneralTabQuery();
                disposeEditScheduleModalPermissionsTabQuery();
                switch (tab) {
                    case 'general':
                        loadEditScheduleModalGeneralTabQuery({ id }, { fetchPolicy: 'network-only' });
                        break;
                    case 'permissions':
                        loadEditScheduleModalPermissionsTabQuery({ id }, { fetchPolicy: 'network-only' });
                        break;
                }
            },
            tabs: [
                canEditInformation && {
                    key: 'general',
                    label: 'Allmänt',
                    value: 'general',
                },
                canEditPermissions && {
                    key: 'permissions',
                    label: 'Rättigheter',
                    value: 'permissions',
                },
            ].filter((tab) => tab),
        }}>
        <FormattedMessage id="general.actions.update" defaultMessage="Redigera"/>
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Suspense fallback={<Skeleton height="40vh"/>}>
          {editScheduleModalGeneralTabQueryRef && (<EditScheduleModalGeneralTab preloadedQuery={editScheduleModalGeneralTabQueryRef}/>)}
          {editScheduleModalPermissionsTabQueryRef && (<EditScheduleModalPermissionsTab preloadedQuery={editScheduleModalPermissionsTabQueryRef}/>)}
        </Suspense>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default EditScheduleModal;
