import React, { useContext } from 'react';
import { usePreloadedQuery, graphql, useMutation } from 'react-relay/hooks';
import { addMinutes, format } from 'date-fns';
import { ModalToSheet, ModalToSheetHeader, ModalToSheetContent, Button } from '@sonika-se/ui-kit/components';
import { durationInMinutes } from '@sonika-se/ui-kit/utils/time';
import { FormattedMessage, useIntl } from 'react-intl';
import { calcOffsetInMinutes } from '../ScheduleTemplateShiftForm';
import { ScheduleTemplateShiftForm } from '../ScheduleTemplateShiftForm';
import ScheduleTemplateDetailsPageContext from '../../ScheduleTemplateDetailsPageContext';
export const calculateBreakTime = (startTime, offset) => {
    const [hours, minutes] = startTime.split(':').map((value) => +value);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    const dateWithOffset = addMinutes(date, offset);
    return format(dateWithOffset, 'HH:mm');
};
export const editScheduleTemplateShiftModalQuery = graphql `
  query EditScheduleTemplateShiftModalQuery($scheduleTemplateShiftId: ID!) {
    scheduleTemplateShift(id: $scheduleTemplateShiftId) {
      id
      assignedEntity {
        ... on ISchedulableEntity {
          id
        }
      }
      assignmentTypeId
      description
      day {
        dayInPeriod
        id
      }
      endTimeOfDay
      period {
        id
        scheduleTemplateId
      }
      event {
        name
        id
      }
      startTimeOfDay
      breaks {
        offsetEnd(unit: MINUTE) {
          value
        }
        offsetStart(unit: MINUTE) {
          value
        }
        type
      }
    }
  }
`;
export const EditScheduleTemplateShiftModal = (props) => {
    var _a, _b;
    const { onCancel, onSubmit, preloadedQuery } = props;
    const context = useContext(ScheduleTemplateDetailsPageContext);
    const data = usePreloadedQuery(editScheduleTemplateShiftModalQuery, preloadedQuery);
    const [updateScheduleTemplateShift, updateScheduleTemplateShiftLoading] = useMutation(graphql `
        mutation EditScheduleTemplateShiftModalUpdateMutation($model: UpdateScheduleTemplateShiftRequestInput!) {
          updateScheduleTemplateShift(model: $model) {
            shiftEdge {
              node {
                id
                ...ScheduleTemplateShift_shift
                description
                endTimeOfDay
                startTimeOfDay
                event {
                  name
                  id
                }
                breaks {
                  offsetEnd(unit: MINUTE) {
                    value
                  }
                  offsetStart(unit: MINUTE) {
                    value
                  }
                  type
                }
                period {
                  ...ScheduleTemplatePeriod_period
                }
              }
            }
          }
        }
      `);
    const [deleteScheduleTemplateShift, deleteScheduleTemplateShiftLoading] = useMutation(graphql `
        mutation EditScheduleTemplateShiftModalDeleteMutation($model: DeleteScheduleTemplateShiftRequestInput!) {
          deleteScheduleTemplateShift(model: $model) {
            shiftEdge {
              node {
                period {
                  ...ScheduleTemplatePeriod_period
                }
              }
            }
          }
        }
      `);
    const intl = useIntl();
    return (<ModalToSheet onClose={() => onCancel()}>
      <ModalToSheetHeader closeButtonLabel="Avbryt" onClose={() => onCancel()}>
        Ändra pass
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <ScheduleTemplateShiftForm actions={<>
              <Button color="danger" disabled={updateScheduleTemplateShiftLoading || deleteScheduleTemplateShiftLoading} onClick={() => {
                deleteScheduleTemplateShift({
                    onCompleted: () => {
                        context.dispatchScheduleTemplateUpdatedEvent();
                        onCancel();
                    },
                    variables: {
                        model: {
                            shiftId: data.scheduleTemplateShift.id,
                        },
                    },
                });
            }}>
                <FormattedMessage id="general.actions.delete" defaultMessage="Ta bort"/>
              </Button>
            </>} defaultValues={{
            assignedEntityId: (_a = data.scheduleTemplateShift.assignedEntity) === null || _a === void 0 ? void 0 : _a.id,
            assignmentTypeId: data.scheduleTemplateShift.assignmentTypeId,
            description: data.scheduleTemplateShift.description,
            endTimeOfDay: data.scheduleTemplateShift.endTimeOfDay,
            eventId: data.scheduleTemplateShift.event.id,
            startTimeOfDay: data.scheduleTemplateShift.startTimeOfDay,
            breaks: (_b = data.scheduleTemplateShift.breaks) === null || _b === void 0 ? void 0 : _b.map((item) => (Object.assign(Object.assign({}, item), { offsetStart: calculateBreakTime(data.scheduleTemplateShift.startTimeOfDay, item.offsetStart.value), offsetEnd: calculateBreakTime(data.scheduleTemplateShift.startTimeOfDay, item.offsetEnd.value) }))),
        }} assignmentTypeId={data.scheduleTemplateShift.assignmentTypeId} disabled={updateScheduleTemplateShiftLoading} onClose={() => {
            onCancel();
        }} onSubmit={(formData) => {
            updateScheduleTemplateShift({
                onCompleted: () => {
                    context.dispatchScheduleTemplateUpdatedEvent();
                    onSubmit();
                },
                variables: {
                    model: {
                        assignedEntityId: formData.assignedEntityId || null,
                        assignmentTypeId: formData.assignmentTypeId,
                        eventId: formData.eventId,
                        shiftId: data.scheduleTemplateShift.id,
                        description: formData.description,
                        endTimeOffset: calcOffsetInMinutes(formData.startTimeOfDay > formData.endTimeOfDay
                            ? data.scheduleTemplateShift.day.dayInPeriod + 1
                            : data.scheduleTemplateShift.day.dayInPeriod, formData.endTimeOfDay),
                        startTimeOffset: calcOffsetInMinutes(data.scheduleTemplateShift.day.dayInPeriod, formData.startTimeOfDay),
                        breaks: formData.breaks
                            ? formData.breaks.map((item) => (Object.assign(Object.assign({}, item), { offsetStart: {
                                    value: durationInMinutes(formData.startTimeOfDay, item.offsetStart),
                                    unit: 'MINUTE',
                                }, offsetEnd: {
                                    value: durationInMinutes(formData.startTimeOfDay, item.offsetStart) +
                                        durationInMinutes(item.offsetStart, item.offsetEnd),
                                    unit: 'MINUTE',
                                } })))
                            : null,
                    },
                },
            });
        }} scheduleTemplateId={data.scheduleTemplateShift.period.scheduleTemplateId}/>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default EditScheduleTemplateShiftModal;
