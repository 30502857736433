import React, { useCallback } from 'react';
import { Field, Form, IntervalInput, Select2 } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
import { graphql, useFragment } from 'react-relay/hooks';
export const ScheduleShiftHistoryVacancyInterestQueryForm = (props) => {
    const { defaultValues, onSubmit, queryRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftHistoryVacancyInterestQueryForm_query on Query {
        schedules {
          nodes {
            id
            name
          }
        }
      }
    `, queryRef);
    const { control, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues,
    });
    const submitForm = useCallback(() => {
        handleSubmit((form) => {
            onSubmit(form);
        })();
    }, [onSubmit]);
    return (<Form columns="1fr 1fr">
      <Field label="Vald period">
        <Controller name="interval" control={control} render={({ field }) => (<IntervalInput onChange={(value) => {
                field.onChange(value);
                submitForm();
            }} value={field.value}/>)}/>
      </Field>
      <Field label="Schema">
        <Controller name="scheduleId" control={control} render={({ field }) => (<Select2 defaultValue={field.value} onChange={({ single }) => {
                field.onChange(single);
                submitForm();
            }} options={[
                {
                    label: 'Alla scheman',
                    value: null,
                },
                ...data.schedules.nodes.map((schedule) => ({
                    label: schedule.name,
                    value: schedule.id,
                })),
            ]}/>)}/>
      </Field>
    </Form>);
};
export default ScheduleShiftHistoryVacancyInterestQueryForm;
