import React, { useState } from 'react';
import { string, object } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { H6, Form, Field, MultiSelect } from '@sonika-se/ui-kit/components';
import enums from '~/enums.json';
const existingShiftsOptionEnum = enums.PublishScheduleTemplateExistingShiftsOptionEnum;
const validationPublishScheduleWizardOther = object({
    existingShiftsOption: string().required(),
});
export const PublishScheduleWizardOther = (props) => {
    var _a, _b, _c, _d;
    const { id, state, onSubmit } = props;
    const label = 'Pass som finns i schemat';
    const [data, setData] = useState({
        existingShiftsOption: 'DELETE_ALL',
    });
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues: { existingShiftsOption: 'DELETE_ALL' },
        resolver: yupResolver(validationPublishScheduleWizardOther),
    });
    if (state === 'READ') {
        return (<H6>{`${label}: ${(_b = (_a = existingShiftsOptionEnum[data.existingShiftsOption]) === null || _a === void 0 ? void 0 : _a.description) !== null && _b !== void 0 ? _b : data.existingShiftsOption}`}</H6>);
    }
    return (<div>
      {/* <H6 style={{ marginTop: '1rem' }}>
          Övrigt
        </H6> */}
      <Form id={id} columns="1fr 1fr" style={{ marginTop: '1rem' }} onSubmit={handleSubmit((formData) => {
            setData(formData);
            onSubmit(formData);
        })}>
        <Field label={label} error={(_d = (_c = errors === null || errors === void 0 ? void 0 : errors.existingShiftsOption) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString()} columnSpan={3}>
          <Controller control={control} name="existingShiftsOption" render={({ field }) => (<MultiSelect inputName="existingShiftsOption" placeholder="Välj" stayOpenOnSelect={false} options={[existingShiftsOptionEnum.DELETE_ALL, existingShiftsOptionEnum.KEEP_ALL].map((x) => ({
                label: x.description,
                value: x.key,
            }))} onChange={(value) => {
                field.onChange(value ? value[0] : null);
                setData(Object.assign(Object.assign({}, data), { existingShiftsOption: value ? value[0] : undefined }));
            }} defaultValue={field.value}/>)}/>
        </Field>
      </Form>
    </div>);
};
export default PublishScheduleWizardOther;
