/**
 * @generated SignedSource<<d1f9dec1aee04a4432c499a14892a041>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserImageLightBox",
    "selections": [
        {
            "alias": "imageUrl",
            "args": [
                {
                    "kind": "Literal",
                    "name": "queryTransform",
                    "value": "width=400&height=400&mode=crop&scale=both"
                }
            ],
            "kind": "ScalarField",
            "name": "url",
            "storageKey": "url(queryTransform:\"width=400&height=400&mode=crop&scale=both\")"
        }
    ],
    "type": "Avatar",
    "abstractKey": null
};
node.hash = "16a6580d1390f4559869b7dde04b7f33";
export default node;
