import React, { useEffect } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { Button, Flexbox, Placeholder } from '@sonika-se/ui-kit/components';
import RolePickerListItem from './RolePickerListItem';
export const RolePickerList = (props) => {
    const { onSelect, queryRef, search } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment RolePickerList_query on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 50 }
        after: { type: "String" }
        where: { type: "RoleFilterInput" }
      )
      @refetchable(queryName: "RolePickerList_queryQuery") {
        roles(after: $after, first: $first, where: $where) @connection(key: "RolePickerList_query_roles") {
          edges {
            node {
              id
              ...RolePickerListItem_role
            }
          }
        }
      }
    `, queryRef);
    useEffect(() => {
        if (search !== undefined) {
            refetch({
                where: {
                    name: { contains: search },
                },
            });
        }
    }, [search]);
    return data.roles.edges.length > 0 ? (<Flexbox direction="column" gap="small">
      {data.roles.edges.map(({ node: role }) => (<RolePickerListItem key={role.id} onSelect={onSelect} roleRef={role}/>))}
      {hasNext && (<Button disabled={isLoadingNext} display="block" onClick={() => {
                loadNext(50);
            }}>
          Ladda fler
        </Button>)}
    </Flexbox>) : (<Placeholder text="Hittade inga grupper" height="full"/>);
};
export default RolePickerList;
