/**
 * @generated SignedSource<<cc4d922469405d7ae9aeb428fff03fe7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connectionsToPrependEdge"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "EdgeOfScheduleTemplate",
        "kind": "LinkedField",
        "name": "scheduleTemplateEdge",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canEdit",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleTemplateTable_cloneScheduleTemplateMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CloneScheduleTemplatePayload",
                    "kind": "LinkedField",
                    "name": "cloneScheduleTemplate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleTemplateTable_cloneScheduleTemplateMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CloneScheduleTemplatePayload",
                    "kind": "LinkedField",
                    "name": "cloneScheduleTemplate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "prependEdge",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "scheduleTemplateEdge",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connectionsToPrependEdge"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b30cc237f711879b5862d25e378e6ade",
            "id": null,
            "metadata": {},
            "name": "ScheduleTemplateTable_cloneScheduleTemplateMutation",
            "operationKind": "mutation",
            "text": "mutation ScheduleTemplateTable_cloneScheduleTemplateMutation(\n  $model: CloneScheduleTemplateInput!\n) {\n  cloneScheduleTemplate(model: $model) {\n    scheduleTemplateEdge {\n      node {\n        canEdit\n        id\n        name\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "f08734af6526faf7bb2ef5af3af57800";
export default node;
