import React, { useState } from 'react';
import { Button, Confirm, FormFooter, ModalToSheet, ModalToSheetContent, ModalToSheetHeader, } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql } from 'react-relay';
import { useMutation, usePreloadedQuery } from 'react-relay/hooks';
import PlannedAbsenceForm from './PlannedAbsenceForm';
import enums from '~/enums.json';
import { formatISO } from 'date-fns';
const timeSpanUnitMinute = enums.TimeSpanUnit.MINUTE.key;
export const editPlannedAbsenceModalQuery = graphql `
  query EditPlannedAbsenceModalQuery($id: ID!) {
    node(id: $id) {
      ... on PlannedAbsence {
        id
        assignedEntity {
          ... on ISchedulableEntity {
            id
            displayName
            ... on SchedulableUser {
              user {
                assignmentTypes {
                  nodes {
                    title
                    id
                  }
                }
              }
            }
            ... on SchedulableAsset {
              asset {
                assignmentTypes {
                  nodes {
                    title
                    id
                  }
                }
              }
            }
          }
        }
        assignmentTypeId
        description
        end
        id
        reasonEvent {
          id
          name
        }
        start
        times {
          weekday
          period {
            start(unit: MINUTE) {
              value
              unit
            }
            end(unit: MINUTE) {
              value
              unit
            }
          }
        }
      }
    }
  }
`;
const EditPlannedAbsenceModal = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const intl = useIntl();
    const { node } = usePreloadedQuery(editPlannedAbsenceModalQuery, props.preloadedQuery);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const assignedEntity = {
        id: node.assignedEntity.id,
        displayName: node.assignedEntity.displayName,
        assignmentTypes: ((_h = (_d = (_c = (_b = (_a = node.assignedEntity) === null || _a === void 0 ? void 0 : _a.asset) === null || _b === void 0 ? void 0 : _b.assignmentTypes) === null || _c === void 0 ? void 0 : _c.nodes) !== null && _d !== void 0 ? _d : (_g = (_f = (_e = node.assignedEntity) === null || _e === void 0 ? void 0 : _e.user) === null || _f === void 0 ? void 0 : _f.assignmentTypes) === null || _g === void 0 ? void 0 : _g.nodes) !== null && _h !== void 0 ? _h : []).map((x) => x),
    };
    const [updatePlannedAbsence, updatePlannedAbsenceLoading] = useMutation(graphql `
      mutation EditPlannedAbsenceModalMutation($model: UpdatePlannedAbsenceRequestInput!) {
        updatePlannedAbsence(model: $model) {
          plannedAbsenceEdge {
            node {
              assignedEntity {
                ... on ISchedulableEntity {
                  id
                }
              }
              assignmentType {
                title
                id
              }
              assignmentTypeId
              description
              end
              id
              reasonEventId
              reasonEvent {
                name
                id
                color
              }
              start
              times {
                weekday
                period {
                  start(unit: MINUTE) {
                    value
                    unit
                  }
                  end(unit: MINUTE) {
                    value
                    unit
                  }
                }
              }
            }
          }
        }
      }
    `);
    const [deletePlannedAbsence, deletePlannedAbsenceLoading] = useMutation(graphql `
      mutation EditPlannedAbsenceModalDeleteMutation($model: DeletePlannedAbsenceRequestInput!) {
        deletePlannedAbsence(model: $model) {
          plannedAbsenceEdge {
            node {
              id
            }
          }
        }
      }
    `);
    return (<ModalToSheet size="medium" onClose={props.onClose}>
      <ModalToSheetHeader onClose={props.onClose} closeButtonLabel="Stäng">
        <FormattedMessage id="EditPlannedAbsenceModal.title" defaultMessage="Visa frånvaro"/>
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <PlannedAbsenceForm onSubmit={(data) => {
            var _a;
            console.log({ data });
            updatePlannedAbsence({
                variables: {
                    model: {
                        id: node.id,
                        assignmentTypeId: data.assignmentTypeId,
                        reasonEventId: data.reasonEventId,
                        start: formatISO(data.start),
                        end: formatISO(data.end),
                        description: data.description,
                        times: (_a = data.times) === null || _a === void 0 ? void 0 : _a.map((x) => {
                            return {
                                weekday: x.weekday,
                                period: {
                                    start: {
                                        value: x.startMinute,
                                        unit: timeSpanUnitMinute,
                                    },
                                    end: {
                                        value: x.endMinute,
                                        unit: timeSpanUnitMinute,
                                    },
                                },
                            };
                        }),
                    },
                },
                onCompleted: () => {
                    props.onClose();
                },
            });
        }} defaultValues={{
            assignedEntity: assignedEntity,
            assignmentTypeId: node.assignmentTypeId,
            description: node.description,
            end: new Date(node.end),
            start: new Date(node.start),
            reasonEventId: node.reasonEvent.id,
            times: (_j = node.times) === null || _j === void 0 ? void 0 : _j.map((x) => {
                return {
                    startMinute: x.period.start.value,
                    endMinute: x.period.end.value,
                    weekday: x.weekday,
                };
            }),
        }} formFooter={<FormFooter disabled={updatePlannedAbsenceLoading || deletePlannedAbsenceLoading} onCancel={props.onClose} submitText={intl.formatMessage({
                id: 'general.actions.save',
                defaultMessage: 'Spara',
            })}>
              <Button color="danger" type="button" disabled={updatePlannedAbsenceLoading || deletePlannedAbsenceLoading} onClick={() => setDeleteConfirmOpen(true)}>
                <FormattedMessage id="general.actions.delete" defaultMessage="Radera"/>
              </Button>
            </FormFooter>}></PlannedAbsenceForm>
      </ModalToSheetContent>
      {deleteConfirmOpen && (<Confirm appearance="danger" confirmButton={{
                text: intl.formatMessage({
                    id: 'general.actions.delete',
                    defaultMessage: 'Radera',
                }),
                onConfirm: () => {
                    deletePlannedAbsence({
                        variables: {
                            model: {
                                id: node.id,
                            },
                        },
                        configs: [
                            {
                                connectionKeys: [
                                    {
                                        key: 'useGetPlannedAbsences_plannedAbsences',
                                    },
                                ],
                                parentID: node.assignedEntity.id,
                                type: 'RANGE_DELETE',
                                deletedIDFieldName: ['plannedAbsenceEdge', 'node'],
                                pathToConnection: ['SchedulableEntity', 'plannedAbsences'],
                            },
                        ],
                        onCompleted: () => {
                            props.onClose();
                        },
                    });
                },
            }} headerText={intl.formatMessage({
                id: 'EditPlannedAbsenceModal.delete_confirm.header_text',
                defaultMessage: 'Är du säker på att du vill ta bort den här frånvaron?',
            })} onCancel={() => setDeleteConfirmOpen(false)}/>)}
    </ModalToSheet>);
};
export default EditPlannedAbsenceModal;
