/**
 * @generated SignedSource<<6663fefe800b6d760281613de7105356>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "Schedule",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleTemplateConnection",
                "kind": "LinkedField",
                "name": "templates",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleTemplateEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleTemplate",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v2 /*: any*/),
                                    (v3 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v5 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 50
        }
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "NewScheduleTemplateModalCreateMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateScheduleTemplatePayload",
                    "kind": "LinkedField",
                    "name": "createScheduleTemplate",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfScheduleTemplate",
                            "kind": "LinkedField",
                            "name": "templateEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleTemplate",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": "schedule2",
                                            "args": null,
                                            "concreteType": "Schedule",
                                            "kind": "LinkedField",
                                            "name": "schedule",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "args": null,
                                                    "kind": "FragmentSpread",
                                                    "name": "ScheduleTemplateTable_schedule"
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "NewScheduleTemplateModalCreateMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateScheduleTemplatePayload",
                    "kind": "LinkedField",
                    "name": "createScheduleTemplate",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfScheduleTemplate",
                            "kind": "LinkedField",
                            "name": "templateEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleTemplate",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "alias": "schedule2",
                                            "args": null,
                                            "concreteType": "Schedule",
                                            "kind": "LinkedField",
                                            "name": "schedule",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "currentUserPermissions",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": (v5 /*: any*/),
                                                    "concreteType": "ScheduleTemplateConnection",
                                                    "kind": "LinkedField",
                                                    "name": "templates",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleTemplateEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ScheduleTemplate",
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "canEdit",
                                                                            "storageKey": null
                                                                        },
                                                                        (v2 /*: any*/),
                                                                        (v3 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": [
                                                                                {
                                                                                    "kind": "Literal",
                                                                                    "name": "order",
                                                                                    "value": {
                                                                                        "timestampId": "DESC"
                                                                                    }
                                                                                }
                                                                            ],
                                                                            "concreteType": "ScheduleTemplatePublicationConnection",
                                                                            "kind": "LinkedField",
                                                                            "name": "publications",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": "ScheduleTemplatePublication",
                                                                                    "kind": "LinkedField",
                                                                                    "name": "nodes",
                                                                                    "plural": true,
                                                                                    "selections": [
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "concreteType": "User",
                                                                                            "kind": "LinkedField",
                                                                                            "name": "publishedByUser",
                                                                                            "plural": false,
                                                                                            "selections": [
                                                                                                {
                                                                                                    "alias": null,
                                                                                                    "args": null,
                                                                                                    "kind": "ScalarField",
                                                                                                    "name": "firstname",
                                                                                                    "storageKey": null
                                                                                                },
                                                                                                {
                                                                                                    "alias": null,
                                                                                                    "args": null,
                                                                                                    "kind": "ScalarField",
                                                                                                    "name": "lastname",
                                                                                                    "storageKey": null
                                                                                                },
                                                                                                (v2 /*: any*/),
                                                                                                {
                                                                                                    "kind": "InlineFragment",
                                                                                                    "selections": [
                                                                                                        {
                                                                                                            "alias": null,
                                                                                                            "args": null,
                                                                                                            "concreteType": "Avatar",
                                                                                                            "kind": "LinkedField",
                                                                                                            "name": "avatar",
                                                                                                            "plural": false,
                                                                                                            "selections": [
                                                                                                                {
                                                                                                                    "alias": null,
                                                                                                                    "args": [
                                                                                                                        {
                                                                                                                            "kind": "Literal",
                                                                                                                            "name": "queryTransform",
                                                                                                                            "value": "width=300&height=300&mode=crop&scale=both"
                                                                                                                        }
                                                                                                                    ],
                                                                                                                    "kind": "ScalarField",
                                                                                                                    "name": "url",
                                                                                                                    "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
                                                                                                                },
                                                                                                                (v2 /*: any*/)
                                                                                                            ],
                                                                                                            "storageKey": null
                                                                                                        },
                                                                                                        {
                                                                                                            "kind": "InlineFragment",
                                                                                                            "selections": [
                                                                                                                (v6 /*: any*/),
                                                                                                                (v3 /*: any*/)
                                                                                                            ],
                                                                                                            "type": "Asset",
                                                                                                            "abstractKey": null
                                                                                                        },
                                                                                                        {
                                                                                                            "kind": "InlineFragment",
                                                                                                            "selections": [
                                                                                                                (v3 /*: any*/)
                                                                                                            ],
                                                                                                            "type": "Company",
                                                                                                            "abstractKey": null
                                                                                                        },
                                                                                                        {
                                                                                                            "kind": "InlineFragment",
                                                                                                            "selections": [
                                                                                                                (v6 /*: any*/)
                                                                                                            ],
                                                                                                            "type": "User",
                                                                                                            "abstractKey": null
                                                                                                        }
                                                                                                    ],
                                                                                                    "type": "IHaveAvatar",
                                                                                                    "abstractKey": "__isIHaveAvatar"
                                                                                                }
                                                                                            ],
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "kind": "ScalarField",
                                                                                            "name": "timestampId",
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "kind": "ScalarField",
                                                                                            "name": "startDate",
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "alias": null,
                                                                                            "args": null,
                                                                                            "kind": "ScalarField",
                                                                                            "name": "endDate",
                                                                                            "storageKey": null
                                                                                        },
                                                                                        (v2 /*: any*/)
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": "publications(order:{\"timestampId\":\"DESC\"})"
                                                                        },
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "__typename",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "cursor",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "PageInfo",
                                                            "kind": "LinkedField",
                                                            "name": "pageInfo",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "endCursor",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "hasNextPage",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": "templates(first:50)"
                                                },
                                                {
                                                    "alias": null,
                                                    "args": (v5 /*: any*/),
                                                    "filters": [],
                                                    "handle": "connection",
                                                    "key": "ScheduleTemplateTable_schedule_templates",
                                                    "kind": "LinkedHandle",
                                                    "name": "templates"
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f23792ba333da1dc3c11c279c8956757",
            "id": null,
            "metadata": {},
            "name": "NewScheduleTemplateModalCreateMutation",
            "operationKind": "mutation",
            "text": "mutation NewScheduleTemplateModalCreateMutation(\n  $model: CreateTemplateRequestInput!\n) {\n  createScheduleTemplate(model: $model) {\n    templateEdge {\n      node {\n        id\n        name\n        schedule {\n          id\n          templates {\n            edges {\n              node {\n                id\n                name\n              }\n            }\n          }\n        }\n        schedule2: schedule {\n          ...ScheduleTemplateTable_schedule\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n\nfragment ScheduleTemplateTable_schedule on Schedule {\n  currentUserPermissions\n  id\n  templates(first: 50) {\n    edges {\n      node {\n        canEdit\n        id\n        name\n        publications(order: {timestampId: DESC}) {\n          nodes {\n            publishedByUser {\n              ...Avatar_iHaveAvatar\n              firstname\n              lastname\n              id\n            }\n            timestampId\n            startDate\n            endDate\n            id\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "de40706f77502f997fcba25532e8892a";
export default node;
