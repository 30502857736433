import React, { Suspense, useMemo, useRef, useState } from 'react';
import { faArrowsH, faPhone, faUserTimes, faUndoAlt, faCalendarTimes } from '@fortawesome/pro-regular-svg-icons';
import { Avatar, Button, Flexbox, H3, H4, IconButton, PopoverToSheet, Tooltip } from '@sonika-se/ui-kit/components';
import { usePermissions, useViewport } from '@sonika-se/ui-kit/hooks';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { ModalToSheetSkeleton } from '@sonika-se/ui-kit/components';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import RemoveAssignedEntity from './RemoveAssignedEntity';
import { UpdateAssignedEntityOnShift } from './UpdateAssignedEntityOnShift';
import ToggleShiftStatusConfirm from './ToggleShiftStatusConfirm';
import getAssignedEntityAvatarProps from '~/utils/getAssignedEntityAvatarProps';
import getAssignedEntityText from '~/utils/getAssignedEntityText';
import ScheduleShiftVacancyNotifyModal, { scheduleShiftVacancyNotifyModalQuery, } from '~/modals/ScheduleShiftVacancyNotifyModal';
import ScheduleShiftAssetValidations from '~/components/ScheduleShiftAssetValidations';
const AssignedEntity = (props) => {
    var _a, _b;
    const data = useFragment(graphql `
      fragment AssignedEntity_ScheduleShift on ScheduleShift {
        id
        assignedEntity {
          ... on ISchedulableEntity {
            id
            displayName
            color
            ... on SchedulableUser {
              user {
                phone
              }
            }
          }
          ...SchedulableEntityWithText_iScheduleableEntity
        }
        scheduleAssignmentType {
          resources(where: { schedulableEntity: { type: { eq: USER } } }) {
            nodes {
              schedulableEntity {
                ... on SchedulableUser {
                  user {
                    id
                  }
                }
              }
            }
          }
        }
        schedule {
          currentUserPermissions
        }
        ...RemoveAssignedEntity
        ...UpdateAssignedEntityOnShift_scheduleShift
        isVacant
        status
        ...ScheduleShiftAssetValidations_scheduleShift
      }
    `, props.assignedEntityRef);
    const hasPermissions = usePermissions();
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || data.schedule.currentUserPermissions.includes('EDIT_RUNNING'), [data, hasPermissions]);
    const [showRemoveAssigneePopover, setShowRemoveAssigneePopover] = useState(false);
    const removeAssigneeButtonRef = useRef();
    const [showUpdateAssigneePopover, setShowUpdateAssigneePopover] = useState(false);
    const updateAssigneeButtonRef = useRef();
    const [showToggleShiftStatusConfirm, setShowToggleShiftStatusConfirm] = useState(false);
    const { size } = useViewport();
    const avatar = useMemo(() => {
        var _a, _b, _c;
        const avatarProps = getAssignedEntityAvatarProps(data.isVacant, data.status, (_a = data.assignedEntity) === null || _a === void 0 ? void 0 : _a.displayName, (_b = data.assignedEntity) === null || _b === void 0 ? void 0 : _b.color);
        const text = getAssignedEntityText(data.isVacant, data.status, (_c = data.assignedEntity) === null || _c === void 0 ? void 0 : _c.displayName);
        const textElement = size == 'small' ? <H4>{text}</H4> : <H3>{text}</H3>;
        return (<>
        <Avatar size="medium" {...avatarProps}/>
        {textElement}
      </>);
    }, [data, size]);
    const [scheduleShiftVacancyNotifyModalQueryRef, loadScheduleShiftVacancyNotifyModalQuery, disposeScheduleShiftVacancyNotifyModalQuery,] = useQueryLoader(scheduleShiftVacancyNotifyModalQuery);
    return (<>
      <Flexbox justify="space" align={size === 'small' ? 'normal' : 'center'} direction={size === 'small' ? 'column' : 'row'} gap="medium">
        <Flexbox gap="xsmall" align="center">
          <>
            {avatar}
            {((_b = (_a = data.assignedEntity) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.phone) && (<Tooltip content={`Ring ${data.assignedEntity.user.phone}`} placement="top">
                <IconButton appearance="filled" color="secondary" icon={faPhone} onClick={() => {
                window.open(`tel:${data.assignedEntity.user.phone}`);
            }} size="xsmall"/>
              </Tooltip>)}
          </>
          <div>
            <ScheduleShiftAssetValidations iconSize="lg" parent={data}/>
          </div>
        </Flexbox>
        {canEditRunning && (<Flexbox gap="medium" justify={size === 'small' ? 'equal' : 'start'}>
            {data.status == 'WITHDRAWN' && (<>
                <Button icon={faUndoAlt} appearance="outlined" size={size === 'small' ? 'medium' : 'small'} onClick={() => setShowToggleShiftStatusConfirm(true)}>
                  Återaktivera
                </Button>
              </>)}
            {data.status == 'ACTIVE' && (<>
                {false && data.isVacant && (<Button appearance="outlined" icon={faPaperPlane} onClick={() => {
                        loadScheduleShiftVacancyNotifyModalQuery({
                            scheduleShiftId: data.id,
                            where: {
                                id: {
                                    in: data.scheduleAssignmentType.resources.nodes.map((resource) => resource.schedulableEntity.user.id),
                                },
                            },
                        });
                    }} size={size === 'small' ? 'medium' : 'small'}>
                    Meddela tillgängliga
                  </Button>)}
                <Button icon={faArrowsH} appearance="outlined" size={size === 'small' ? 'medium' : 'small'} ref={updateAssigneeButtonRef} onClick={() => setShowUpdateAssigneePopover(true)}>
                  Ersätt
                </Button>
                {!data.isVacant && (<Button onClick={() => setShowRemoveAssigneePopover(true)} icon={faUserTimes} appearance="outlined" size={size === 'small' ? 'medium' : 'small'} ref={removeAssigneeButtonRef}>
                    Gör vakant
                  </Button>)}
                <Button icon={faCalendarTimes} appearance="outlined" size={size === 'small' ? 'medium' : 'small'} onClick={() => setShowToggleShiftStatusConfirm(true)}>
                  Ställ in
                </Button>
                {showRemoveAssigneePopover && (<PopoverToSheet anchorEl={removeAssigneeButtonRef.current} onClose={() => setShowRemoveAssigneePopover(false)} size="small">
                    <RemoveAssignedEntity onSubmit={() => setShowRemoveAssigneePopover(false)} scheduleShift={data}/>
                  </PopoverToSheet>)}
                {showUpdateAssigneePopover && (<PopoverToSheet anchorEl={updateAssigneeButtonRef.current} onClose={() => setShowUpdateAssigneePopover(false)} size="auto">
                    <UpdateAssignedEntityOnShift onSubmit={() => setShowUpdateAssigneePopover(false)} scheduleShift={data}/>
                  </PopoverToSheet>)}
              </>)}
          </Flexbox>)}
        <ToggleShiftStatusConfirm scheduleShiftId={data.id} open={showToggleShiftStatusConfirm} currentStatus={data.status} onClose={() => {
            setShowToggleShiftStatusConfirm(false);
        }}/>
      </Flexbox>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
        {scheduleShiftVacancyNotifyModalQueryRef && (<ScheduleShiftVacancyNotifyModal onClose={() => {
                disposeScheduleShiftVacancyNotifyModalQuery();
            }} preloadedQuery={scheduleShiftVacancyNotifyModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default AssignedEntity;
