import React, { useMemo } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { Chip, DataTable, Flexbox, Placeholder, Text, Tooltip } from '@sonika-se/ui-kit/components';
import { formatDateSpan, toDate } from '@sonika-se/ui-kit/utils/time';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { faUserClock } from '@fortawesome/pro-solid-svg-icons';
import { pluralize } from '@sonika-se/ui-kit/utils/text';
import Avatar from '~/shared/Avatar';
import { Link } from 'react-router-dom';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
export const ScheduleShiftTable = (props) => {
    const { scheduleRef } = props;
    const { data: schedule, hasNext, isLoadingNext, loadNext, refetch, } = usePaginationFragment(graphql `
      fragment ScheduleShiftTable_schedule on Schedule
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 10 }
        runningScheduleModel: { type: "GetRunningScheduleRequestInput!" }
      )
      @refetchable(queryName: "ScheduleShiftTable_scheduleQuery") {
        currentUserPermissions
        id
        runningSchedule(after: $after, first: $first, model: $runningScheduleModel)
          @connection(key: "ScheduleShiftTable_schedule_runningSchedule", filters: ["model"]) {
          edges {
            node {
              assignedEntity {
                ... on SchedulableAsset {
                  asset {
                    ...Avatar_iHaveAvatar
                    name
                  }
                }
                ... on SchedulableUser {
                  user {
                    ...Avatar_iHaveAvatar
                    firstname
                    lastname
                  }
                }
              }
              assignmentType {
                title
              }
              changeRequests {
                totalCount
              }
              end
              id
              start
            }
          }
        }
      }
    `, scheduleRef);
    const scheduleShifts = useMemo(() => schedule.runningSchedule.edges.map(({ node: scheduleShift }) => ({
        dates: {
            fromDate: toDate(scheduleShift.start),
            id: scheduleShift.id,
            toDate: toDate(scheduleShift.end),
            changeRequestsCount: scheduleShift.changeRequests.totalCount,
        },
        assignmentType: scheduleShift.assignmentType.title,
        assignedEntity: scheduleShift.assignedEntity,
    })), [schedule]);
    const canEditSchedules = usePermissions()(['EDIT_SCHEDULES']);
    const canEditRunning = useMemo(() => canEditSchedules || schedule.currentUserPermissions.includes('EDIT_RUNNING'), [canEditSchedules, schedule]);
    return scheduleShifts.length === 0 ? (<Placeholder text="Inga pass."/>) : (<>
      <DataTable columns={{
            dates: {
                render: ({ changeRequestsCount, fromDate, id, toDate }) => (<Flexbox align="center" direction="row" gap="small">
                {canEditRunning ? (<Link to={`/running-schedules/${id}`}>
                    <Text bold>{formatDateSpan(fromDate, toDate, 'short')}</Text>
                  </Link>) : (<Text bold>{formatDateSpan(fromDate, toDate, 'short')}</Text>)}
                {changeRequestsCount > 0 && (<Tooltip content={pluralize `${changeRequestsCount} förändringsbegär[an|ningar]`}>
                    <Chip color={sonikaTheme.colors.system.stop} icon={faUserClock} size="xsmall" text={`${changeRequestsCount}`} type="view"/>
                  </Tooltip>)}
              </Flexbox>),
                text: 'Tid',
            },
            assignmentType: {
                text: 'Roll',
            },
            assignedEntity: {
                render: (assignedEntity) => {
                    var _a, _b;
                    return assignedEntity && (<Flexbox align="center" gap="xsmall">
                  <Avatar iHaveAvatarRef={assignedEntity.asset || assignedEntity.user} size="xsmall"/>
                  <span>
                    {((_a = assignedEntity.asset) === null || _a === void 0 ? void 0 : _a.name) || `${(_b = assignedEntity.user) === null || _b === void 0 ? void 0 : _b.firstname} ${assignedEntity.user.lastname}`}
                  </span>
                </Flexbox>);
                },
                text: 'Användare/enhet',
            },
        }} loading={isLoadingNext} onPaginate={() => {
            loadNext(50);
        }} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={scheduleShifts}/>
    </>);
};
export default ScheduleShiftTable;
