import React, { useCallback, useMemo } from 'react';
import { graphql, useQueryLoader, useMutation, useFragment } from 'react-relay/hooks';
import { Flexbox, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import SchedulePermissionList from '../components/SchedulePermissionList';
import UserPickerModal, { userPickerModalQuery } from '../components/UserPickerModal/UserPickerModal';
import RolePickerModal, { rolePickerModalQuery } from '../components/RolePickerModal/RolePickerModal';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
export const SchedulePermissionsPanel = (props) => {
    const { scheduleRef } = props;
    const schedule = useFragment(graphql `
      fragment SchedulePermissionsPanel_schedule on Schedule {
        ...SchedulePermissionList_schedule
        id
        currentUserPermissions
      }
    `, scheduleRef);
    const [userPickerModalQueryRef, loadUserPickerModalQuery, disposeUserPickerModalQuery] = useQueryLoader(userPickerModalQuery);
    const [rolePickerModalQueryRef, loadRolePickerModalQuery, disposeRolePickerModalQuery] = useQueryLoader(rolePickerModalQuery);
    const [createSchedulePermissionMutation, createSchedulePermissionMutationLoading] = useMutation(graphql `
        mutation SchedulePermissionsPanel_createSchedulePermissionMutation($model: CreateSchedulePermissionInput!) {
          createSchedulePermission(model: $model) {
            schedulePermissionEdge {
              node {
                ...SchedulePermissionListItem_schedulePermission
              }
            }
          }
        }
      `);
    const createSchedulePermission = useCallback(({ roleId, userId }) => {
        createSchedulePermissionMutation({
            configs: [
                {
                    connectionInfo: [
                        {
                            key: 'SchedulePermissionList_schedule_permissions',
                            rangeBehavior: 'prepend',
                        },
                    ],
                    parentID: schedule.id,
                    edgeName: 'schedulePermissionEdge',
                    type: 'RANGE_ADD',
                },
            ],
            variables: {
                model: {
                    permissions: [],
                    roleId,
                    userId,
                    scheduleId: schedule.id,
                },
            },
        });
    }, [createSchedulePermissionMutation, schedule]);
    const canEditSchedules = usePermissions()(['EDIT_SCHEDULES']);
    const canEditPermissions = useMemo(() => canEditSchedules || schedule.currentUserPermissions.includes('EDIT_PERMISSIONS'), [canEditSchedules, schedule]);
    return (<>
      <Panel actions={canEditPermissions && (<Flexbox direction="row" gap="xsmall">
              <PanelButton onClick={() => {
                loadUserPickerModalQuery({});
            }}>
                Lägg till användare
              </PanelButton>
              <PanelButton onClick={() => {
                loadRolePickerModalQuery({});
            }}>
                Lägg till grupp
              </PanelButton>
            </Flexbox>)} title="Rättigheter">
        <SchedulePermissionList scheduleRef={schedule}/>
      </Panel>
      {userPickerModalQueryRef && (<UserPickerModal onClose={() => {
                disposeUserPickerModalQuery();
            }} onSelect={(userId) => {
                disposeUserPickerModalQuery();
                createSchedulePermission({ userId });
            }} preloadedQuery={userPickerModalQueryRef}/>)}
      {rolePickerModalQueryRef && (<RolePickerModal onClose={() => {
                disposeRolePickerModalQuery();
            }} onSelect={(roleId) => {
                disposeRolePickerModalQuery();
                createSchedulePermission({ roleId });
            }} preloadedQuery={rolePickerModalQueryRef}/>)}
    </>);
};
export default SchedulePermissionsPanel;
