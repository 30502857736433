/**
 * @generated SignedSource<<f5f802210dd526b4fc281bddd82c3260>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "checkedWhere"
        }
    ], v1 = [
        {
            "alias": "checkedNumber",
            "args": [
                {
                    "kind": "Variable",
                    "name": "where",
                    "variableName": "checkedWhere"
                }
            ],
            "concreteType": "SchedulableEntityConnection",
            "kind": "LinkedField",
            "name": "schedulableEntities",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SchedulableResourceFilterCheckedNumberQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SchedulableResourceFilterCheckedNumberQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "d8cbb6f094efc9dd06a54a46c3499a67",
            "id": null,
            "metadata": {},
            "name": "SchedulableResourceFilterCheckedNumberQuery",
            "operationKind": "query",
            "text": "query SchedulableResourceFilterCheckedNumberQuery(\n  $checkedWhere: SchedulableEntityFilterInput\n) {\n  checkedNumber: schedulableEntities(where: $checkedWhere) {\n    totalCount\n  }\n}\n"
        }
    };
})();
node.hash = "1ae2cb19e1479d6e0a9f07692788cbd8";
export default node;
