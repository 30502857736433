import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field, Form, Textarea, Checkbox, Table, TableHead, TableBody, TableHeader, TableRow, TableCell, FormSection, Tooltip, H6, MultiSelect, Box, DateInput, Flexbox, KeyValue, Panel, Button, TimeInput, } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { array, mixed, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { date } from 'yup';
import enums from '~/enums.json';
import { AbsenceCalendarPageContext } from '../AbsenceCalendarPageContext';
import QuerySchedulableEntitySelect from './QuerySchedulableEntitySelect';
import EventField from '~/components/EventField';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { formatISO } from 'date-fns';
import { formatTimeSpan } from '@sonika-se/ui-kit/utils/time';
const format = (value, unit) => `${value > 0 ? `${value}${unit} ` : ''}`;
const formatDuration = ({ days = 0, hours = 0, minutes = 0, seconds = 0, }) => {
    const totalSeconds = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
    const h = Math.floor(totalSeconds / 3600);
    const m = Math.floor((totalSeconds % 3600) / 60);
    const s = Math.floor((totalSeconds % 3600) % 60);
    return `${format(h, 't')}${format(m, 'm')}${format(s, 's')}`.trim();
};
const weekdayList = Object.keys(enums.Weekday).map((key) => {
    const weekday = enums.Weekday[key];
    return {
        key: key,
        description: weekday.description,
    };
});
const validationSchema = (intl) => object({
    assignedEntityId: string().required('Du måste välja en anställd'),
    assignmentTypeId: string().required('Du måste välja en anställning'),
    reasonEventId: string().required('Du måste välja en typ av frånvaro'),
    start: date().required('Du måste välja ett startdatum'),
    end: date().required('Du måste välja ett slutdatum'),
    description: string().notRequired().nullable(),
    endHour: date().notRequired().nullable(),
    startHour: date().notRequired().nullable(),
    times: array().of(mixed()).notRequired(),
});
const PlannedAbsenceForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { defaultValues } = props;
    const intl = useIntl();
    const { filters } = useContext(AbsenceCalendarPageContext);
    const [setTimes, setTimesValue] = useState(((_a = defaultValues.times) === null || _a === void 0 ? void 0 : _a.length) > 0);
    const getDefaultTimeItems = () => {
        const result = weekdayList.map((x) => {
            var _a, _b, _c;
            const existingTime = (_a = defaultValues.times) === null || _a === void 0 ? void 0 : _a.find((y) => y.weekday == x.key);
            return {
                weekday: x,
                start: new Date(new Date(new Date().setHours(0)).setMinutes((_b = existingTime === null || existingTime === void 0 ? void 0 : existingTime.startMinute) !== null && _b !== void 0 ? _b : 0)),
                end: new Date(new Date(new Date().setHours(0)).setMinutes((_c = existingTime === null || existingTime === void 0 ? void 0 : existingTime.endMinute) !== null && _c !== void 0 ? _c : 24 * 60)),
                selected: !!existingTime,
            };
        });
        return result;
    };
    const { handleSubmit, formState: { errors }, watch, setValue, control, } = useForm({
        defaultValues: {
            assignedEntityId: (_b = defaultValues.assignedEntity) === null || _b === void 0 ? void 0 : _b.id,
            assignmentTypeId: defaultValues.assignmentTypeId,
            description: defaultValues.description || '',
            end: defaultValues.end,
            reasonEventId: defaultValues.reasonEventId,
            start: defaultValues.start,
            times: getDefaultTimeItems(),
        },
        resolver: yupResolver(validationSchema(intl)),
    });
    const { fields: timeFields, update: updateTimeField } = useFieldArray({ name: 'times', control: control });
    const selectedStart = watch('start', defaultValues.start);
    const selectedEnd = watch('end', defaultValues.end);
    const selectedStartHour = watch('startHour', defaultValues.start);
    const selectedEndHour = watch('endHour', defaultValues.end);
    const selectedTimes = watch('times');
    const selectedAssignmentTypeId = watch('assignmentTypeId', defaultValues.assignmentTypeId);
    const [selectedAssignedEntity, setSelectedAssignedEntity] = useState(defaultValues.assignedEntity);
    const showActiveEmploymentPanel = useMemo(() => {
        const result = setTimes &&
            selectedEnd &&
            selectedStart &&
            selectedAssignedEntity != null &&
            filters.type == 'USER' &&
            selectedAssignmentTypeId != null;
        return result;
    }, [selectedAssignmentTypeId, selectedAssignedEntity, setTimes, selectedEnd, selectedStart]);
    const { schedulableEntity } = useLazyLoadQuery(graphql `
      query PlannedAbsenceFormEmploymentQuery(
        $schedulableUserId: ID!
        $assignmentTypeId: ID!
        $startTime: DateTime!
        $endTime: DateTime!
      ) {
        schedulableEntity(id: $schedulableUserId) {
          ... on SchedulableUser {
            id
            user {
              id
              activeEmployment(assignmentTypeId: $assignmentTypeId, dateTime: $startTime) {
                id
                expectedWorkTime(fromDate: $startTime, toDate: $endTime, unit: HOUR) {
                  value
                  unit
                }
                employmentSetting {
                  id
                  title
                  workTimeSetting {
                    currentPeriod(currentTime: $startTime) {
                      id
                      ordinaryWorkTimePerDay(unit: HOUR) {
                        value
                        unit
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `, {
        assignmentTypeId: selectedAssignmentTypeId,
        schedulableUserId: selectedAssignedEntity.id,
        endTime: selectedEnd ? formatISO(selectedEnd) : '',
        startTime: selectedStart ? formatISO(selectedStart) : '',
    }, { fetchPolicy: showActiveEmploymentPanel ? 'network-only' : 'store-only' });
    const activeEmployment = useMemo(() => {
        var _a;
        return (_a = schedulableEntity === null || schedulableEntity === void 0 ? void 0 : schedulableEntity.user) === null || _a === void 0 ? void 0 : _a.activeEmployment;
    }, [schedulableEntity]);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        if (!((_c = (_b = (_a = activeEmployment === null || activeEmployment === void 0 ? void 0 : activeEmployment.employmentSetting) === null || _a === void 0 ? void 0 : _a.workTimeSetting) === null || _b === void 0 ? void 0 : _b.currentPeriod) === null || _c === void 0 ? void 0 : _c.ordinaryWorkTimePerDay)) {
            return;
        }
        const startHour = new Date();
        startHour.setHours(8);
        startHour.setMinutes(0);
        setValue('startHour', startHour);
        const endHour = new Date(startHour);
        endHour.setHours(0);
        endHour.setMinutes(startHour.getHours() * 60 +
            ((_f = (_e = (_d = activeEmployment.employmentSetting) === null || _d === void 0 ? void 0 : _d.workTimeSetting) === null || _e === void 0 ? void 0 : _e.currentPeriod) === null || _f === void 0 ? void 0 : _f.ordinaryWorkTimePerDay.value) * 60);
        setValue('endHour', endHour);
    }, [activeEmployment]);
    const setDefaultTimes = () => {
        const newFields = weekdayList.map((x, index) => {
            const newField = {
                weekday: x,
                start: selectedStartHour,
                end: selectedEndHour,
                selected: true,
            };
            updateTimeField(index, newField);
            return newField;
        });
    };
    return (<Form onSubmit={handleSubmit((data) => {
            props.onSubmit({
                assignedEntityId: data.assignedEntityId,
                assignmentTypeId: data.assignmentTypeId,
                description: data.description,
                end: data.end,
                reasonEventId: data.reasonEventId,
                start: data.start,
                times: setTimes
                    ? selectedTimes
                        .filter((x) => x.selected)
                        .map((x) => ({
                        weekday: x.weekday.key,
                        startMinute: x.start.getMinutes() + x.start.getHours() * 60,
                        endMinute: x.end.getMinutes() + x.end.getHours() * 60 + (x.end.getDate() - x.start.getDate()) * 24 * 60,
                    }))
                    : [],
            });
        })}>
      {defaultValues.assignedEntity && <H6>{defaultValues.assignedEntity.displayName}</H6>}
      {!defaultValues.assignedEntity && (<Field label={(filters === null || filters === void 0 ? void 0 : filters.type) === 'USER'
                ? intl.formatMessage({
                    id: 'AddPlannedAbsenceModal.fields.assigned_entity_id.label',
                    defaultMessage: 'Anställd',
                })
                : intl.formatMessage({
                    id: 'general.objects.asset.name.singular',
                    defaultMessage: 'Enhet',
                })}>
          <Controller control={control} name="assignedEntityId" render={({ field }) => {
                var _a, _b;
                return (<QuerySchedulableEntitySelect entityFilterType={filters.type} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.assignedEntityId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onSingleSelected={(value) => {
                        field.onChange(value === null || value === void 0 ? void 0 : value.id);
                        setValue('assignedEntityId', value === null || value === void 0 ? void 0 : value.id);
                        setSelectedAssignedEntity(value);
                    }} filterModel={{ onlyActive: true }}/>);
            }}/>
        </Field>)}
      <FormSection columns="1fr 1fr">
        <Field label={intl.formatMessage({
            id: 'AddPlannedAbsenceModal.fields.assignment_type_id.label',
            defaultMessage: 'Roll',
        })}>
          <Controller control={control} name="assignmentTypeId" render={({ field }) => {
            var _a, _b;
            return (<MultiSelect error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.assignmentTypeId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} defaultValue={field.value} options={selectedAssignedEntity
                    ? selectedAssignedEntity.assignmentTypes.map((x) => ({ label: x.title, value: x.id }))
                    : undefined} disabled={!selectedAssignedEntity} placeholder={intl.formatMessage({
                    id: 'AddPlannedAbsenceModal.fields.assignment_type_id.placeholder',
                    defaultMessage: 'Välj roll',
                })} onBlur={field.onBlur} onChange={(value) => field.onChange(value ? value[0] : undefined)}/>);
        }}/>
        </Field>
        <Field label={intl.formatMessage({
            id: 'AddPlannedAbsenceModalReasonField.fields.reasonEventId.label',
            defaultMessage: 'Typ av frånvaro',
        })}>
          <Controller control={control} name="reasonEventId" render={({ field }) => {
            var _a, _b;
            return (<EventField defaultValue={field.value} disabled={!selectedAssignmentTypeId} assignmentTypeId={selectedAssignmentTypeId} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.reasonEventId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} where={{ isAbsence: { eq: true } }} placeholder={intl.formatMessage({
                    id: 'AddPlannedAbsenceModalReasonField.fields.reason.placeholder',
                    defaultMessage: 'Välj typ av frånvaro',
                })} onBlur={field.onBlur} onChange={(value) => field.onChange(value ? value[0] : undefined)}/>);
        }}/>
        </Field>
      </FormSection>
      <FormSection columns="1fr 1fr">
        <Field label={intl.formatMessage({
            id: 'AddPlannedAbsenceModal.fields.start.label',
            defaultMessage: 'Frånvaro startar',
        })}>
          <Controller control={control} name="start" render={({ field }) => {
            var _a, _b;
            return (<DateInput value={field.value} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.start) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={(date) => field.onChange(date)} placeholder="Välj startdatum"/>);
        }}/>
        </Field>
        <Field label={intl.formatMessage({
            id: 'AddPlannedAbsenceModal.fields.end.label',
            defaultMessage: 'Åter i arbete',
        })}>
          <Controller control={control} name="end" render={({ field }) => {
            var _a, _b;
            return (<DateInput value={field.value} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.end) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={(date) => field.onChange(date)} placeholder="Välj slutdatum"/>);
        }}/>
        </Field>
      </FormSection>
      <Field label={intl.formatMessage({
            id: 'AddPlannedAbsenceModal.fields.description.label',
            defaultMessage: 'Kommentar',
        })}>
        <Controller control={control} name="description" render={({ field }) => (<Textarea placeholder={intl.formatMessage({
                id: 'AddPlannedAbsenceModal.fields.description.placeholder',
                defaultMessage: 'Kommentera frånvaro',
            })} onBlur={field.onBlur} onChange={field.onChange} value={field.value}/>)}/>
      </Field>
      <Tooltip content="För t.ex. export till tidrapport och schemainformation" placement="top">
        <Field label="Överför till tidrapport" direction="row-reverse">
          <Checkbox checked={!!setTimes} onChange={() => setTimesValue((old) => !old)}/>
        </Field>
      </Tooltip>
      {activeEmployment && showActiveEmploymentPanel && (<Panel title={activeEmployment.employmentSetting.title}>
          <Flexbox direction="row" gap="xlarge">
            {((_e = (_d = (_c = activeEmployment.employmentSetting) === null || _c === void 0 ? void 0 : _c.workTimeSetting) === null || _d === void 0 ? void 0 : _d.currentPeriod) === null || _e === void 0 ? void 0 : _e.ordinaryWorkTimePerDay) && (<KeyValue direction="row" label="Arbetstid per dag" value={formatTimeSpan(activeEmployment.employmentSetting.workTimeSetting.currentPeriod.ordinaryWorkTimePerDay)}/>)}
            {/* <KeyValue label="Förväntad arbetstid i perioden" value={formatTimeSpan(activeEmployment.expectedWorkTime)} /> */}
          </Flexbox>
          {((_h = (_g = (_f = activeEmployment.employmentSetting) === null || _f === void 0 ? void 0 : _f.workTimeSetting) === null || _g === void 0 ? void 0 : _g.currentPeriod) === null || _h === void 0 ? void 0 : _h.ordinaryWorkTimePerDay) && (<Flexbox direction="row" gap="small" justify="equal" align="end">
              <Field label="Start på dagen">
                <Controller control={control} name="startHour" render={({ field }) => {
                    var _a, _b;
                    return (<TimeInput value={field.value} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.startHour) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={(date) => field.onChange(date)}/>);
                }}/>
              </Field>
              <Field label="Slut på dagen">
                <Controller control={control} name="endHour" render={({ field }) => {
                    var _a, _b;
                    return (<TimeInput value={field.value} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.endHour) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={(date) => field.onChange(date)}/>);
                }}/>
              </Field>
              <Button size="small" disabled={!selectedStartHour || !selectedEndHour} onClick={() => {
                    setDefaultTimes();
                }}>
                Ange som standard
              </Button>
            </Flexbox>)}
        </Panel>)}
      <div style={{ display: !setTimes ? 'none' : undefined }}>
        <Box padding="small" background="lm-background-primary">
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>
                  <H6>Veckodag</H6>
                </TableHeader>
                <TableHeader>
                  <H6>Start</H6>
                </TableHeader>
                <TableHeader>
                  <H6>Slut</H6>
                </TableHeader>
                <TableHeader align="right" width={80}>
                  <H6>Tid</H6>
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedTimes.map((time, index) => {
            var _a, _b, _c, _d, _e, _f;
            return (<TableRow key={`time-${index}`}>
                    <TableCell>
                      <Field label={time.weekday.description} direction="row-reverse">
                        <Controller control={control} name={`times.${index}.selected`} render={({ field }) => <Checkbox checked={time.selected} onChange={field.onChange}/>}/>
                      </Field>
                    </TableCell>
                    <TableCell>
                      <Field error={(_c = (_b = (_a = errors.times) === null || _a === void 0 ? void 0 : _a.at(index)) === null || _b === void 0 ? void 0 : _b.start) === null || _c === void 0 ? void 0 : _c.message}>
                        <TimeInput value={time.start} disabled={!selectedTimes[index].selected} onChange={(date) => updateTimeField(index, Object.assign(Object.assign({}, time), { start: date }))}/>
                      </Field>
                    </TableCell>
                    <TableCell>
                      <Field error={(_f = (_e = (_d = errors.times) === null || _d === void 0 ? void 0 : _d.at(index)) === null || _e === void 0 ? void 0 : _e.end) === null || _f === void 0 ? void 0 : _f.message}>
                        <TimeInput value={time.end} disabled={!selectedTimes[index].selected} onChange={(date) => {
                    let newDate = date;
                    newDate.setDate(time.start.getDate());
                    if (date.getHours() == 0 && date.getMinutes() == 0) {
                        newDate = new Date(newDate.setDate(newDate.getDate() + 1));
                    }
                    updateTimeField(index, Object.assign(Object.assign({}, time), { end: newDate }));
                }}/>
                      </Field>
                    </TableCell>
                    <TableCell align="right">
                      {time.selected && formatDuration({ seconds: (time.end.getTime() - time.start.getTime()) / 1000 })}
                    </TableCell>
                  </TableRow>);
        })}
            </TableBody>
          </Table>
        </Box>
      </div>
      {props.formFooter}
    </Form>);
};
export default PlannedAbsenceForm;
