/**
 * @generated SignedSource<<799f1648afab6049f3b7340906ad1119>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AbsenceCalendarFilter_options",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                            "disabledDate": {
                                "eq": null
                            }
                        }
                    }
                ],
                "concreteType": "AssignmentTypeConnection",
                "kind": "LinkedField",
                "name": "assignmentTypes",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "AssignmentType",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "assignmentTypes(where:{\"disabledDate\":{\"eq\":null}})"
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                            "isAbsence": {
                                "eq": true
                            }
                        }
                    }
                ],
                "concreteType": "EventConnection",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "EventEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Event",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    },
                                    (v0 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "events(where:{\"isAbsence\":{\"eq\":true}})"
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "c6469776d6ed4cd9b88017cddba6be7a";
export default node;
