import React from 'react';
import { useRoutes } from 'react-router-dom';
import { RunningSchedulesPage } from './RunningSchedulesPage/RunningSchedulesPage';
import { MySchedulePage } from './MySchedule/MySchedulePage';
import ScheduleShiftPage from './ScheduleShiftPage/ScheduleShiftPage';
export const SchedulePages = () => {
    const routes = useRoutes([
        {
            path: '/',
            element: <RunningSchedulesPage />,
        },
        {
            path: '/:id',
            element: <ScheduleShiftPage />,
        },
    ]);
    return routes;
};
export const MySchedulePages = () => {
    const routes = useRoutes([
        {
            path: '/',
            element: <MySchedulePage />,
        },
    ]);
    return routes;
};
export default SchedulePages;
