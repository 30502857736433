/**
 * @generated SignedSource<<fb8e50c04d188df697248b2581dd61f6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "nameContains"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "refetch": {
                "connection": null,
                "fragmentPathInResult": [],
                "operation": require('./RunningSchedulesEditSidebar_schedulesQuery.graphql')
            }
        },
        "name": "RunningSchedulesEditSidebar_schedules",
        "selections": [
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "filterModel",
                        "value": {
                            "withCurrentUserPermissions": [
                                "EDIT_RUNNING"
                            ]
                        }
                    }
                ],
                "concreteType": "ScheduleConnection",
                "kind": "LinkedField",
                "name": "schedules",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Schedule",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "currentUserPermissions",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "order",
                                                "value": {
                                                    "timestampId": "ASC"
                                                }
                                            }
                                        ],
                                        "concreteType": "ScheduleAssignmentTypeConnection",
                                        "kind": "LinkedField",
                                        "name": "assignmentTypes",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ScheduleAssignmentTypeEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ScheduleAssignmentType",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ScheduleAssignmentTypeResourceConnection",
                                                                "kind": "LinkedField",
                                                                "name": "resources",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "totalCount",
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            (v0 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "AssignmentType",
                                                                "kind": "LinkedField",
                                                                "name": "assignmentType",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v0 /*: any*/),
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "kind": "ScalarField",
                                                                        "name": "title",
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            {
                                                                "args": [
                                                                    {
                                                                        "kind": "Variable",
                                                                        "name": "nameContains",
                                                                        "variableName": "nameContains"
                                                                    }
                                                                ],
                                                                "kind": "FragmentSpread",
                                                                "name": "RunningScheduleAssignmentType_scheduleAssignmentType"
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": "assignmentTypes(order:{\"timestampId\":\"ASC\"})"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "schedules(filterModel:{\"withCurrentUserPermissions\":[\"EDIT_RUNNING\"]})"
            }
        ],
        "type": "Query",
        "abstractKey": null
    };
})();
node.hash = "2c4cc202537c4ad21561b228d9c6204b";
export default node;
