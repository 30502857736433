import React, { Suspense } from 'react';
import { ModalToSheet, ModalToSheetContent, ModalToSheetHeader, Skeleton, } from '@sonika-se/ui-kit/components';
import { graphql, usePreloadedQuery } from 'react-relay/hooks';
import RunningSchedulesChangeResourceForm from './components/RunningSchedulesChangeResourceForm';
export const runningSchedulesChangeResourceModalQuery = graphql `
  query RunningSchedulesChangeResourceModalQuery {
    ...RunningSchedulesChangeResourceForm_query
  }
`;
export const RunningSchedulesChangeResourceModal = (props) => {
    const { className, onClose, preloadedQuery } = props;
    const data = usePreloadedQuery(runningSchedulesChangeResourceModalQuery, preloadedQuery);
    return (<ModalToSheet className={className}>
      <ModalToSheetHeader onClose={onClose} closeButtonLabel="Stäng">
        Byt anställd eller enhet
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Suspense fallback={<Skeleton type="form" height="30vh"/>}>
          <RunningSchedulesChangeResourceForm onCancel={onClose} queryRef={data}/>
        </Suspense>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default RunningSchedulesChangeResourceModal;
