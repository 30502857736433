/**
 * @generated SignedSource<<65c8ffcc4e98a8b3a1d1ba90d5af6abb>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "DryRunPublishScheduleTemplatePayload",
            "kind": "LinkedField",
            "name": "dryRunPublishScheduleTemplate",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "validationErrors",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "validationWarnings",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfShiftsToCreate",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfShiftsToDelete",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfShiftsWithChangesToDelete",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfRemainingShifts",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfResultingClashes",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "PublishScheduleWizardSummationDryRunMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "PublishScheduleWizardSummationDryRunMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "7c395b4083e941e1d2a1842fa82f57fe",
            "id": null,
            "metadata": {},
            "name": "PublishScheduleWizardSummationDryRunMutation",
            "operationKind": "mutation",
            "text": "mutation PublishScheduleWizardSummationDryRunMutation(\n  $model: DryRunPublishScheduleTemplateInput!\n) {\n  dryRunPublishScheduleTemplate(model: $model) {\n    validationErrors\n    validationWarnings\n    numberOfShiftsToCreate\n    numberOfShiftsToDelete\n    numberOfShiftsWithChangesToDelete\n    numberOfRemainingShifts\n    numberOfResultingClashes\n  }\n}\n"
        }
    };
})();
node.hash = "917e09825f1a518078c40f6846668514";
export default node;
