/**
 * @generated SignedSource<<c3050899e7d8e2f41ed94e4df250a7f6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        (v0 /*: any*/),
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
        }
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": [
                        "history"
                    ]
                }
            ]
        },
        "name": "ScheduleShiftHistory_scheduleShift",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": "history",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "order",
                        "value": {
                            "timestamp": "DESC"
                        }
                    }
                ],
                "concreteType": "ScheduleShiftHistoryConnection",
                "kind": "LinkedField",
                "name": "__ScheduleShiftHistory_history_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleShiftHistoryEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "timestamp",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "description",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "type",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "User",
                                                "kind": "LinkedField",
                                                "name": "byUser",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "args": null,
                                                        "kind": "FragmentSpread",
                                                        "name": "User_user"
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "IHaveScheduleShiftHistory",
                                        "abstractKey": "__isIHaveScheduleShiftHistory"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AssignmentType",
                                                "kind": "LinkedField",
                                                "name": "newAssignmentType",
                                                "plural": false,
                                                "selections": (v1 /*: any*/),
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AssignmentType",
                                                "kind": "LinkedField",
                                                "name": "previousAssignmentType",
                                                "plural": false,
                                                "selections": (v1 /*: any*/),
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "ScheduleShiftHistoryAssignmentTypeChange",
                                        "abstractKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleShiftHistoryAssigneeChange_history"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleShiftHistoryVacancyInterest_history"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleShiftHistoryTimeChange_history"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleShiftHistoryEventChange_history"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleShiftHistoryStatusChange_history"
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "__ScheduleShiftHistory_history_connection(order:{\"timestamp\":\"DESC\"})"
            }
        ],
        "type": "ScheduleShift",
        "abstractKey": null
    };
})();
node.hash = "7bb7bd20b8cd43620ca7a5c928402d85";
export default node;
