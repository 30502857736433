import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { useMutation, graphql, usePreloadedQuery } from 'react-relay/hooks';
import { Form, FormFooter, Input, Field } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
const validationSchema = (intl) => object({
    name: string().required(intl.formatMessage({
        defaultMessage: 'Du måste ange ett namn för schemat',
        id: 'general.objects.route.fields.name.required',
    })),
});
export const editScheduleModalGeneralTabQuery = graphql `
  query EditScheduleModalGeneralTabQuery($id: ID!) {
    schedule(id: $id) {
      id
      name
    }
  }
`;
export const EditScheduleModalGeneralTab = (props) => {
    var _a, _b;
    const { preloadedQuery } = props;
    const intl = useIntl();
    const { schedule } = usePreloadedQuery(editScheduleModalGeneralTabQuery, preloadedQuery);
    const [updateSchedule, updateScheduleLoading] = useMutation(graphql `
      mutation EditScheduleModalGeneralTab_updateScheduleMutation($model: UpdateScheduleRequestInput!) {
        updateSchedule(model: $model) {
          scheduleEdge {
            node {
              id
              name
            }
          }
        }
      }
    `);
    const { control, formState: { errors, isDirty }, handleSubmit, reset, } = useForm({
        defaultValues: {
            name: schedule.name,
        },
        resolver: yupResolver(validationSchema(intl)),
    });
    return (<Form onSubmit={handleSubmit((data) => {
            updateSchedule({
                onCompleted: () => {
                    reset(data);
                },
                variables: {
                    model: {
                        scheduleId: schedule.id,
                        name: data.name,
                    },
                },
            });
        })}>
      <Field error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} label={intl.formatMessage({ defaultMessage: 'Namn', id: 'general.objects.schedule.fields.name.label' })}>
        <Controller control={control} name="name" render={({ field }) => (<Input disabled={updateScheduleLoading} display="block" onBlur={field.onBlur} onChange={field.onChange} value={field.value}/>)}/>
      </Field>
      <FormFooter disabled={!isDirty || updateScheduleLoading} onCancel={isDirty && !updateScheduleLoading ? reset : undefined} submitText="Uppdatera"/>
    </Form>);
};
export default EditScheduleModalGeneralTab;
