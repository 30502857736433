import React, { useContext, useState } from 'react';
import AbsenceCalendarPageContext from '../../AbsenceCalendarPageContext';
import { eachDayOfInterval, formatISO } from 'date-fns';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
import AbsenceCalendarGridContent from './AbsenceCalendarGridContent';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import AbsenceCalendarGridContentMobile from './AbsenceCalendarGridContentMobile';
import { faCalendarWeek, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { Button, Flexbox, Icon, Placeholder, VisibleContainer } from '@sonika-se/ui-kit/components';
import { FormattedMessage } from 'react-intl';
export const AbsenceCalendarGrid = (props) => {
    var _a, _b;
    const { size: pageSize } = useViewport();
    const { toDate: contextEndDate, fromDate: contextStartDate, filters } = useContext(AbsenceCalendarPageContext);
    const { hideEmptyResources, resourceIds, absenceReasons, assignmentTypes, type, search, schedulable } = filters;
    const [days, setDays] = useState(eachDayOfInterval({ start: contextStartDate, end: contextEndDate }));
    const [fromDate, setFromDate] = useState(contextStartDate);
    const [toDate, setToDate] = useState(contextEndDate);
    const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment(graphql `
      fragment AbsenceCalendarGridQuery on Query
      @argumentDefinitions(
        where: { type: "SchedulableEntityFilterInput" }
        whereAbsences: { type: "PlannedAbsenceFilterInput" }
        fromDate: { type: "DateTime!" }
        toDate: { type: "DateTime!" }
        model: { type: "SchedulableEntitiesQueryRequestInput" }
        first: { type: "Int", defaultValue: 100 }
        after: { type: "String" }
      )
      @refetchable(queryName: "AbsenceCalendarGrid_schedulableEntitiesQuery") {
        schedulableEntities(where: $where, first: $first, after: $after, model: $model)
          @connection(key: "AbsenceCalendarGrid_schedulableEntities", filters: []) {
          edges {
            node {
              ... on ISchedulableEntity {
                id
                displayName
              }
              ... on SchedulableUser {
                user {
                  ...User
                }
              }
              ... on SchedulableAsset {
                asset {
                  ...Asset
                }
              }
              ...useGetPlannedAbsences_plannedAbsences
                @arguments(toDate: $toDate, fromDate: $fromDate, where: $whereAbsences)
            }
          }
        }
      }
    `, props.queryRefSchedulableEntities);
    useDidMountEffect(() => {
        var _a;
        const where = {};
        const whereSchedulableEntities = { type: { eq: type }, schedulable: { eq: schedulable } };
        if (!!(assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.length)) {
            where['assignmentTypeId'] = { in: assignmentTypes };
        }
        if (!!(absenceReasons === null || absenceReasons === void 0 ? void 0 : absenceReasons.length)) {
            where['reasonEventId'] = { in: absenceReasons };
        }
        if (!!search) {
            whereSchedulableEntities['name'] = { contains: search };
        }
        if (resourceIds === null || resourceIds === void 0 ? void 0 : resourceIds.length) {
            whereSchedulableEntities['id'] = { in: resourceIds };
        }
        refetch({
            toDate: formatISO(toDate, { representation: 'complete' }),
            fromDate: formatISO(fromDate, { representation: 'complete' }),
            whereAbsences: !!((_a = Object.keys(where)) === null || _a === void 0 ? void 0 : _a.length) ? where : undefined,
            model: hideEmptyResources
                ? {
                    hasPlannedAbsenceDuring: {
                        toDate: formatISO(toDate, { representation: 'complete' }),
                        fromDate: formatISO(fromDate, { representation: 'complete' }),
                    },
                }
                : undefined,
            where: whereSchedulableEntities,
        }, { fetchPolicy: 'network-only' });
    }, [resourceIds, type, absenceReasons, assignmentTypes, hideEmptyResources, search]);
    useDidMountEffect(() => {
        var _a;
        setDays(eachDayOfInterval({ start: contextStartDate, end: contextEndDate }));
        setFromDate(contextStartDate);
        setToDate(contextEndDate);
        const where = {};
        const whereSchedulableEntities = { type: { eq: type }, schedulable: { eq: schedulable } };
        if (!!(assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.length)) {
            where['assignmentTypeId'] = { in: assignmentTypes };
        }
        if (!!(absenceReasons === null || absenceReasons === void 0 ? void 0 : absenceReasons.length)) {
            where['reasonEventId'] = { in: absenceReasons };
        }
        if (!!search) {
            whereSchedulableEntities['name'] = { contains: search };
        }
        if (resourceIds === null || resourceIds === void 0 ? void 0 : resourceIds.length) {
            whereSchedulableEntities['id'] = { in: resourceIds };
        }
        refetch({
            whereAbsences: !!((_a = Object.keys(where)) === null || _a === void 0 ? void 0 : _a.length) ? where : undefined,
            where: whereSchedulableEntities,
            toDate: formatISO(contextEndDate, { representation: 'complete' }),
            model: hideEmptyResources
                ? {
                    hasPlannedAbsenceDuring: {
                        toDate: formatISO(contextEndDate, { representation: 'complete' }),
                        fromDate: formatISO(contextStartDate, { representation: 'complete' }),
                    },
                }
                : undefined,
            fromDate: formatISO(contextStartDate, { representation: 'complete' }),
        }, { fetchPolicy: 'network-only' });
    }, [contextEndDate, contextStartDate]);
    if (!((_b = (_a = data === null || data === void 0 ? void 0 : data.schedulableEntities) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length)) {
        return <Placeholder height="full" icon={faCalendarWeek} text="Din sökning gav inga resultat"/>;
    }
    if (pageSize === 'small') {
        return (<>
        <AbsenceCalendarGridContentMobile data={data}/>
        {hasNext && (<Button onClick={() => loadNext(100)} size="large" disabled={isLoadingNext} color="secondary">
            <FormattedMessage id="general.actions.load_more" defaultMessage="Ladda fler"/>
          </Button>)}
      </>);
    }
    return (<>
      <AbsenceCalendarGridContent days={days} fromDate={fromDate} toDate={toDate} data={data}/>
      {hasNext && (<VisibleContainer callback={() => loadNext(100)}>
          <Flexbox justify="center" style={{ background: 'white', padding: '1rem' }}>
            <Icon icon={faSpinner} spin/>
          </Flexbox>
        </VisibleContainer>)}
    </>);
};
export default AbsenceCalendarGrid;
