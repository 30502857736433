import React, { useCallback, useRef } from 'react';
import { useMutation, graphql } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import { ScheduleBasicForm } from '~/forms/ScheduleBasicForm';
export const ScheduleCreateModal = (props) => {
    const { connectionsToPrependEdge, onClose, runningScheduleModel } = props;
    const [createSchedule, createScheduleLoading] = useMutation(graphql `
    mutation ScheduleCreateModal_createScheduleMutation(
      $connectionsToPrependEdge: [ID!]!
      $model: CreateScheduleRequestInput!
      $runningScheduleModel: GetRunningScheduleRequestInput!
    ) {
      createSchedule(model: $model) {
        scheduleEdge @prependEdge(connections: $connectionsToPrependEdge) {
          node {
            ...ScheduleCard_schedule @arguments(runningScheduleModel: $runningScheduleModel)
          }
        }
      }
    }
  `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        createSchedule({
            variables: {
                connectionsToPrependEdge,
                model: {
                    name: form.name,
                },
                runningScheduleModel,
            },
            onCompleted: () => {
                onClose();
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={createScheduleLoading} title="Lägg till schema">
      <ScheduleBasicForm defaultValues={{
            name: '',
        }} disabled={createScheduleLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
