import React, { useState, useContext } from 'react';
import { string, object, mixed, array } from 'yup';
import { FieldList, ModalToSheet, ModalToSheetContent, Form, FormSection, Field, Checkbox, FormFooter, MultiSelect, ModalToSheetHeader, Input, } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, graphql, useFragment } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import { durationInMinutes } from '@sonika-se/ui-kit/utils/time';
import enums from '~/enums.json';
import ScheduleTemplateDetailsPageContext from '../../../ScheduleTemplateDetailsPageContext';
import { calcOffsetInMinutes } from '../../ScheduleTemplateShiftForm';
const validationSchema = object({
    endTimeOfDay: string().required('Du måste ange sluttid'),
    startTimeOfDay: string().required('Du måste ange starttid'),
    breaks: array()
        .of(object().shape({
        offsetEnd: string().matches(/[0-9][0-9]:[0-9][0-9]/),
        offsetStart: string().matches(/[0-9][0-9]:[0-9][0-9]/),
        type: mixed().oneOf(Object.keys(enums.BreakEnum)),
    }))
        .notRequired(),
});
const ScheduleTemplateEditTimeModal = (props) => {
    var _a, _b;
    const { onCancel, scheduleTemplate } = props;
    const { highlightedShifts } = useContext(ScheduleTemplateDetailsPageContext);
    const [hasBreak, setHasBreak] = useState(false);
    const intl = useIntl();
    const scheduleShiftBreakType = enums.BreakEnum;
    const { formState: { errors }, handleSubmit, control, } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {},
    });
    const template = useFragment(graphql `
      fragment ScheduleTemplateEditTimeModal_shifts on ScheduleTemplate {
        id
        shifts {
          edges {
            node {
              id
              day {
                dayInPeriod
                id
              }
              endTimeOfDay
              startTimeOfDay
              period {
                id
              }
              breaks {
                offsetEnd(unit: MINUTE) {
                  value
                }
                offsetStart(unit: MINUTE) {
                  value
                }
                type
              }
            }
          }
        }
      }
    `, scheduleTemplate);
    const [updateScheduleTemplateShift, updateScheduleTemplateShiftLoading] = useMutation(graphql `
        mutation ScheduleTemplateEditTimeModalUpdateMutation($model: [UpdateScheduleTemplateShiftRequestInput!]!) {
          updateScheduleTemplateShifts(model: $model) {
            shiftEdge {
              node {
                id
                ...ScheduleTemplateShift_shift
                description
                endTimeOfDay
                startTimeOfDay
                breaks {
                  offsetEnd(unit: MINUTE) {
                    value
                  }
                  offsetStart(unit: MINUTE) {
                    value
                  }
                  type
                }
              }
            }
          }
        }
      `);
    const shifts = template.shifts.edges.filter(({ node: shift }) => !!highlightedShifts.find((shiftId) => shiftId === shift.id));
    return (<ModalToSheet onClose={() => onCancel()}>
      <ModalToSheetHeader onClose={() => onCancel()} closeButtonLabel="Avbryt">
        {`Uppdatera tiden för ${shifts.length} pass`}
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Form onSubmit={handleSubmit((formData) => {
            updateScheduleTemplateShift({
                onCompleted: () => {
                    onCancel();
                },
                variables: {
                    model: shifts.map(({ node: shift }) => ({
                        shiftId: shift.id,
                        endTimeOffset: calcOffsetInMinutes(formData.startTimeOfDay > formData.endTimeOfDay ? shift.day.dayInPeriod + 1 : shift.day.dayInPeriod, formData.endTimeOfDay),
                        startTimeOffset: calcOffsetInMinutes(shift.day.dayInPeriod, formData.startTimeOfDay),
                        breaks: formData.breaks
                            ? formData.breaks.map((item) => (Object.assign(Object.assign({}, item), { offsetStart: {
                                    value: durationInMinutes(formData.startTimeOfDay, item.offsetStart),
                                    unit: 'MINUTE',
                                }, offsetEnd: {
                                    value: durationInMinutes(formData.startTimeOfDay, item.offsetStart) +
                                        durationInMinutes(item.offsetStart, item.offsetEnd),
                                    unit: 'MINUTE',
                                } })))
                            : null,
                    })),
                },
            });
        })}>
          <FormSection columns="1fr 1fr">
            <Field error={(_a = errors.startTimeOfDay) === null || _a === void 0 ? void 0 : _a.message.toString()} label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.start.label',
            defaultMessage: 'Starttid',
        })}>
              <Controller control={control} name="startTimeOfDay" render={({ field }) => (<Input disabled={updateScheduleTemplateShiftLoading} onChange={field.onChange} type="time" value={field.value}/>)}/>
            </Field>
            <Field error={(_b = errors.endTimeOfDay) === null || _b === void 0 ? void 0 : _b.message.toString()} label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.end.label',
            defaultMessage: 'Sluttid',
        })}>
              <Controller control={control} name="endTimeOfDay" render={({ field }) => (<Input disabled={updateScheduleTemplateShiftLoading} onChange={field.onChange} type="time" value={field.value}/>)}/>
            </Field>
            <Field label={intl.formatMessage({
            id: 'general.misc.schedule_shift.fields.contains_breaks.placeholder',
            defaultMessage: 'Passet innehåller raster',
        })} direction="row-reverse" columnSpan={2}>
              <Checkbox inputName="hasBreak" disabled={updateScheduleTemplateShiftLoading} onChange={(e) => setHasBreak(e.target.checked)} checked={hasBreak}/>
            </Field>
          </FormSection>
          {hasBreak && (<FormSection>
              <FieldList appendText={intl.formatMessage({
                id: 'general.misc.breaks.actions.create',
                defaultMessage: 'Lägg till rast',
            })} compact control={control} disabled={updateScheduleTemplateShiftLoading} name="breaks" label={intl.formatMessage({
                id: 'general.misc.breaks.name.plural',
                defaultMessage: 'Raster',
            })} render={(field, index) => (<FormSection columns="1fr 1fr 1fr" key={field.id}>
                    <Field>
                      <Controller control={control} name={`breaks.${index}.offsetStart`} render={({ field }) => (<Input defaultValue={field.value} onChange={field.onChange} type="time" value={field.value}/>)}/>
                    </Field>
                    <Field>
                      <Controller control={control} name={`breaks.${index}.offsetEnd`} render={({ field }) => (<Input defaultValue={field.value} onChange={field.onChange} type="time" value={field.value}/>)}/>
                    </Field>
                    <Field>
                      <Controller control={control} name={`breaks.${index}.type`} render={({ field }) => {
                    var _a;
                    return (<MultiSelect disabled={updateScheduleTemplateShiftLoading} onChange={(value) => field.onChange(value ? value[0] : undefined)} options={(_a = Object.keys(scheduleShiftBreakType)) === null || _a === void 0 ? void 0 : _a.map((key) => {
                            var _a;
                            return ({
                                label: intl.formatMessage({
                                    id: `BreakEnum_${key}`,
                                    defaultMessage: (_a = scheduleShiftBreakType[key]) === null || _a === void 0 ? void 0 : _a.description,
                                }),
                                value: key,
                            });
                        })} placeholder={intl.formatMessage({
                            id: 'general.misc.breaks.fields.type.placeholder',
                            defaultMessage: 'Välj typ',
                        })} value={field.value}/>);
                }}/>
                    </Field>
                  </FormSection>)}/>
            </FormSection>)}
          <FormFooter onCancel={onCancel} submitText={intl.formatMessage({
            id: 'ScheduleTemplateEditTimeModal.update_time',
            defaultMessage: 'Uppdatera tider',
        })}/>
        </Form>
      </ModalToSheetContent>
    </ModalToSheet>);
};
ScheduleTemplateEditTimeModal.propTypes = {};
export default ScheduleTemplateEditTimeModal;
