var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Field, Flexbox, Form, MultiSelect, NewDateTimeInput, TimeInput } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
import enums from '~/enums.json';
import { mixed, object, date } from 'yup';
import { useIntl } from 'react-intl';
import { toDate } from '@sonika-se/ui-kit/utils/time';
const validationSchema = object({
    start: date().required(),
    end: date().required(),
    type: mixed().oneOf(Object.keys(enums.BreakEnum)),
});
const ScheduleShiftBreakForm = (props) => {
    const { children, onClose, onSubmit, sameDay, isLoading, defaultDisplayValue } = props, rest = __rest(props, ["children", "onClose", "onSubmit", "sameDay", "isLoading", "defaultDisplayValue"]);
    const scheduleShiftBreakType = enums.BreakEnum;
    const intl = useIntl();
    const { control, handleSubmit, register, formState: { errors }, } = useForm({
        defaultValues: Object.assign(Object.assign({}, rest), { start: (rest === null || rest === void 0 ? void 0 : rest.start) ? toDate(new Date(rest === null || rest === void 0 ? void 0 : rest.start)) : undefined, end: (rest === null || rest === void 0 ? void 0 : rest.end) ? toDate(new Date(rest === null || rest === void 0 ? void 0 : rest.end)) : undefined }),
        resolver: yupResolver(validationSchema),
    });
    return (<Form onSubmit={handleSubmit((values) => {
            onSubmit(values);
        })}>
      <Field label="Start">
        {sameDay ? (<Controller control={control} name="start" render={({ field }) => {
                var _a;
                return (<TimeInput disabled={isLoading} error={(_a = errors.start) === null || _a === void 0 ? void 0 : _a.message} onChange={field.onChange} value={field.value}/>);
            }}/>) : (<Controller control={control} name="start" render={({ field }) => {
                var _a, _b;
                return (<NewDateTimeInput header="" value={field.value} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.start) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={(date) => field.onChange(date)} placeholder="Välj starttid"/>);
            }}/>)}
      </Field>
      <Field label="Slut">
        {sameDay ? (<Controller control={control} name="end" render={({ field }) => {
                var _a;
                return (<TimeInput disabled={isLoading} error={(_a = errors.end) === null || _a === void 0 ? void 0 : _a.message} onChange={field.onChange} value={field.value}/>);
            }}/>) : (<Controller control={control} name="end" render={({ field }) => {
                var _a, _b;
                return (<NewDateTimeInput header="" value={field.value} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.end) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onChange={(date) => field.onChange(date)} placeholder="Välj sluttid"/>);
            }}/>)}
      </Field>
      <Field label="Typ av rast">
        <Controller control={control} name="type" render={({ field }) => {
            var _a, _b, _c;
            return (<MultiSelect error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.type) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} defaultValue={field.value} disabled={isLoading} onChange={(value) => field.onChange(value ? value[0] : undefined)} options={(_c = Object.keys(scheduleShiftBreakType)) === null || _c === void 0 ? void 0 : _c.map((key) => {
                    var _a;
                    return ({
                        label: intl.formatMessage({
                            id: `BreakEnum_${key}`,
                            defaultMessage: (_a = scheduleShiftBreakType[key]) === null || _a === void 0 ? void 0 : _a.description,
                        }),
                        value: key,
                    });
                })} placeholder="Välj typ"/>);
        }}/>
      </Field>
      <Flexbox justify="space">
        {children || <div />}
        <Flexbox justify="end" gap="small">
          <Button size="small" appearance="outlined" disabled={isLoading} onClick={onClose}>
            Avbryt
          </Button>
          <Button disabled={isLoading} size="small" type="submit">
            Spara
          </Button>
        </Flexbox>
      </Flexbox>
    </Form>);
};
export default ScheduleShiftBreakForm;
