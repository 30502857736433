/**
 * @generated SignedSource<<4a01494d343683782d1f8709ac333b19>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "myScheduleModel"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "myScheduleModel"
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "reasonEvent",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            (v5 /*: any*/)
        ],
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            (v3 /*: any*/)
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "UserAbsenceModal_scheduleAbsencesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "myScheduleModel",
                                    "variableName": "myScheduleModel"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "UserAbsenceModal_scheduleAbsences"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "UserAbsenceModal_scheduleAbsencesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v3 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "ScheduleShiftAssigneeReplacementConnection",
                                    "kind": "LinkedField",
                                    "name": "scheduleAbsences",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleShiftAssigneeReplacementEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ScheduleShiftAssigneeReplacement",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v8 /*: any*/),
                                                        (v9 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Schedule",
                                                            "kind": "LinkedField",
                                                            "name": "schedule",
                                                            "plural": false,
                                                            "selections": [
                                                                (v5 /*: any*/),
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "PlannedAbsenceConnection",
                                    "kind": "LinkedField",
                                    "name": "plannedAbsences",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PlannedAbsenceEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PlannedAbsence",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v8 /*: any*/),
                                                        (v9 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "type": "SchedulableUser",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "97189d4e456b9545cf3f7b1b091406b8",
            "id": null,
            "metadata": {},
            "name": "UserAbsenceModal_scheduleAbsencesQuery",
            "operationKind": "query",
            "text": "query UserAbsenceModal_scheduleAbsencesQuery(\n  $myScheduleModel: GetRunningScheduleRequestInput!\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...UserAbsenceModal_scheduleAbsences_4dGZpP\n    id\n  }\n}\n\nfragment UserAbsenceModal_scheduleAbsences_4dGZpP on SchedulableUser {\n  scheduleAbsences(model: $myScheduleModel) {\n    edges {\n      node {\n        id\n        reasonEvent {\n          id\n          name\n        }\n        start\n        end\n        assignmentType {\n          title\n          id\n        }\n        schedule {\n          name\n          id\n        }\n      }\n    }\n  }\n  plannedAbsences(model: $myScheduleModel) {\n    edges {\n      node {\n        id\n        reasonEvent {\n          id\n          name\n        }\n        start\n        end\n        assignmentType {\n          title\n          id\n        }\n      }\n    }\n  }\n  id\n}\n"
        }
    };
})();
node.hash = "3b9eea31b8fb70703062fa77f57adf75";
export default node;
