import React from 'react';
import { graphql, usePreloadedQuery, useMutation } from 'react-relay/hooks';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { ModalToSheet, ModalToSheetHeader, Form, FormFooter, ModalToSheetContent, Field, Input, } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
const validationSchema = (intl) => object({
    name: string().required(intl.formatMessage({
        defaultMessage: 'Du måste ange ett namn',
        id: 'general.misc.period.fields.name.required',
    })),
});
export const editScheduleTemplatePeriodModalQuery = graphql `
  query EditScheduleTemplatePeriodModalQuery($scheduleTemplatePeriod: ID!) {
    period: node(id: $scheduleTemplatePeriod) {
      ... on ScheduleTemplatePeriod {
        id
        name
        scheduleTemplateId
      }
    }
  }
`;
export const EditScheduleTemplatePeriodModal = (props) => {
    var _a;
    const { period } = usePreloadedQuery(editScheduleTemplatePeriodModalQuery, props.preloadedQuery);
    const intl = useIntl();
    const { control, formState: { errors }, handleSubmit, } = useForm({
        resolver: yupResolver(validationSchema(intl)),
        defaultValues: {
            name: period.name,
        },
    });
    const [updateScheduleTemplatePeriod, updateScheduleTemplatePeriodLoading] = useMutation(graphql `
        mutation EditScheduleTemplatePeriodModalUpdateMutation($model: UpdateScheduleTemplatePeriodRequestInput!) {
          updateScheduleTemplatePeriod(model: $model) {
            periodEdge {
              node {
                id
                name
              }
            }
          }
        }
      `);
    const { onClose, onSubmit } = props;
    return (<ModalToSheet onClose={() => onClose()}>
      <ModalToSheetHeader onClose={() => onClose()} closeButtonLabel="Avbryt">
        <FormattedMessage defaultMessage="Byt namn på period" id="general.misc.period.actions.update_name"/>
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Form onSubmit={handleSubmit((form) => {
            updateScheduleTemplatePeriod({
                onCompleted: () => {
                    onSubmit();
                },
                variables: {
                    model: {
                        id: period.id,
                        name: form.name,
                    },
                },
            });
        })}>
          <Field error={(_a = errors.name) === null || _a === void 0 ? void 0 : _a.message.toString()} label={intl.formatMessage({
            defaultMessage: 'Namn',
            id: 'general.misc.period.fields.name.label',
        })}>
            <Controller control={control} name="name" render={({ field }) => (<Input disabled={updateScheduleTemplatePeriodLoading} onChange={field.onChange} value={field.value}/>)}/>
          </Field>
          <FormFooter disabled={updateScheduleTemplatePeriodLoading} submitText={intl.formatMessage({ id: 'general.actions.save', defaultMessage: 'Spara' })}/>
        </Form>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default EditScheduleTemplatePeriodModal;
