/**
 * @generated SignedSource<<c6cc297686ed95e96a60f212de153b91>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
            "plannedAbsences"
        ]
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "where"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "assignedEntity",
        "plural": false,
        "selections": [
            {
                "kind": "InlineFragment",
                "selections": [
                    (v1 /*: any*/)
                ],
                "type": "ISchedulableEntity",
                "abstractKey": "__isISchedulableEntity"
            }
        ],
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assignmentTypeId",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            (v1 /*: any*/)
        ],
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "first"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "fromDate"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "toDate"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "where"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                (v0 /*: any*/),
                (v0 /*: any*/)
            ]
        },
        "name": "useGetPlannedAbsences_plannedAbsences",
        "selections": [
            {
                "kind": "InlineFragment",
                "selections": [
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                    }
                ],
                "type": "ISchedulableEntity",
                "abstractKey": "__isISchedulableEntity"
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": "plannedAbsences",
                        "args": (v2 /*: any*/),
                        "concreteType": "PlannedAbsenceConnection",
                        "kind": "LinkedField",
                        "name": "__useGetPlannedAbsences_plannedAbsences_connection",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PlannedAbsenceEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PlannedAbsence",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v3 /*: any*/),
                                            (v4 /*: any*/),
                                            (v5 /*: any*/),
                                            (v6 /*: any*/),
                                            (v7 /*: any*/),
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Event",
                                                "kind": "LinkedField",
                                                "name": "reasonEvent",
                                                "plural": false,
                                                "selections": [
                                                    (v8 /*: any*/),
                                                    (v1 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "color",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            (v9 /*: any*/),
                                            (v10 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    (v11 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            (v12 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "type": "SchedulableUser",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    {
                        "alias": "plannedAbsences",
                        "args": (v2 /*: any*/),
                        "concreteType": "PlannedAbsenceConnection",
                        "kind": "LinkedField",
                        "name": "__useGetPlannedAbsences_plannedAbsences_connection",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PlannedAbsenceEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PlannedAbsence",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v3 /*: any*/),
                                            (v4 /*: any*/),
                                            (v5 /*: any*/),
                                            (v6 /*: any*/),
                                            (v7 /*: any*/),
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Event",
                                                "kind": "LinkedField",
                                                "name": "reasonEvent",
                                                "plural": false,
                                                "selections": [
                                                    (v8 /*: any*/),
                                                    (v1 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            (v9 /*: any*/),
                                            (v10 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    (v11 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            (v12 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "type": "SchedulableAsset",
                "abstractKey": null
            }
        ],
        "type": "SchedulableEntity",
        "abstractKey": "__isSchedulableEntity"
    };
})();
node.hash = "8a5c99dbe37ca33a98fb6df47443de87";
export default node;
