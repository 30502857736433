/**
 * @generated SignedSource<<2980d7879fcf5eccd7b93a26073590d4>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "potentialValidationsModel"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "where"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "where"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            (v4 /*: any*/)
        ],
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v8 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "potentialValidationsModel"
        }
    ], v9 = {
        "alias": null,
        "args": null,
        "concreteType": "AssetProficiencyValidation",
        "kind": "LinkedField",
        "name": "assetProficiency",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isValid",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "concreteType": "Asset",
        "kind": "LinkedField",
        "name": "asset",
        "plural": false,
        "selections": [
            (v3 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetNumber",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validateProficiency",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateScheduleShiftAssigneesFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "ScheduleShiftConnection",
                    "kind": "LinkedField",
                    "name": "scheduleShifts",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShiftEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShift",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v8 /*: any*/),
                                            "concreteType": "ScheduleShiftAssetValidation",
                                            "kind": "LinkedField",
                                            "name": "potentialAssetValidations",
                                            "plural": true,
                                            "selections": [
                                                (v9 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SchedulableAsset",
                                                    "kind": "LinkedField",
                                                    "name": "schedulableAsset",
                                                    "plural": false,
                                                    "selections": [
                                                        (v10 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SchedulableUser",
                                                    "kind": "LinkedField",
                                                    "name": "schedulableUser",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": [
                                                                (v11 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AssignmentType",
                                                    "kind": "LinkedField",
                                                    "name": "userAssignmentType",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "UpdateScheduleShiftAssigneesFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "ScheduleShiftConnection",
                    "kind": "LinkedField",
                    "name": "scheduleShifts",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShiftEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShift",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v8 /*: any*/),
                                            "concreteType": "ScheduleShiftAssetValidation",
                                            "kind": "LinkedField",
                                            "name": "potentialAssetValidations",
                                            "plural": true,
                                            "selections": [
                                                (v9 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SchedulableAsset",
                                                    "kind": "LinkedField",
                                                    "name": "schedulableAsset",
                                                    "plural": false,
                                                    "selections": [
                                                        (v10 /*: any*/),
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SchedulableUser",
                                                    "kind": "LinkedField",
                                                    "name": "schedulableUser",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": [
                                                                (v11 /*: any*/),
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AssignmentType",
                                                    "kind": "LinkedField",
                                                    "name": "userAssignmentType",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "bbbd452fb4889b4238a58f4d5ce7265b",
            "id": null,
            "metadata": {},
            "name": "UpdateScheduleShiftAssigneesFormQuery",
            "operationKind": "query",
            "text": "query UpdateScheduleShiftAssigneesFormQuery(\n  $where: ScheduleShiftFilterInput\n  $potentialValidationsModel: PotentialScheduleShiftAssetValidationsInput!\n) {\n  scheduleShifts(where: $where) {\n    edges {\n      node {\n        id\n        assignmentType {\n          id\n          title\n        }\n        start\n        end\n        potentialAssetValidations(model: $potentialValidationsModel) {\n          assetProficiency {\n            isValid\n          }\n          schedulableAsset {\n            asset {\n              id\n              name\n              assetNumber\n              validateProficiency\n            }\n            id\n          }\n          schedulableUser {\n            user {\n              fullName\n              id\n            }\n            id\n          }\n          userAssignmentType {\n            title\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "0cb6368f9190f9751d01179e007360a9";
export default node;
