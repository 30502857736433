/**
 * @generated SignedSource<<da3bc62ebce7d3cc6775282eadec1031>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleShiftContent_ScheduleShift",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AssignedEntity_ScheduleShift"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleShiftInformationTab_ScheduleShift"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useScheduleShiftAttachments_scheduleShift"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleShiftHistory_scheduleShift"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleShiftChangeTab_shift"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleShiftNotesTab_scheduleShift"
        }
    ],
    "type": "ScheduleShift",
    "abstractKey": null
};
node.hash = "09b7c0545856f3dc7da19ab90d9fe5cc";
export default node;
