import React, { useMemo } from 'react';
import { graphql, useMutation } from 'react-relay/hooks';
import { Confirm } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
export const ToggleShiftStatusConfirm = (props) => {
    const { onClose, open, scheduleShiftId, currentStatus } = props;
    const intl = useIntl();
    const [updateShiftStatus, updateShiftStatusLoading] = useMutation(graphql `
      mutation ToggleShiftStatusConfirm_updateShiftStatusMutation($model: UpdateScheduleShiftStatusInput!) {
        updateScheduleShiftStatus(model: $model) {
          shift {
            ...ScheduleShiftInformationTab_ScheduleShift
            ...useScheduleShiftAttachments_scheduleShift
            ...useScheduleShiftNotes_scheduleShift
            ...ScheduleShiftHistory_scheduleShift
            ...ScheduleShiftChangeTab_shift
          }
        }
      }
    `);
    const confirmValues = useMemo(() => {
        switch (currentStatus) {
            case 'ACTIVE':
                return {
                    newStatus: 'WITHDRAWN',
                    confirmText: 'Är du säker på att du vill ställa in passet?',
                    title: 'Ställ in pass',
                };
            case 'WITHDRAWN':
                return {
                    newStatus: 'ACTIVE',
                    confirmText: 'Vill du återaktivera passet?',
                    title: 'Återaktivera pass',
                };
        }
        throw `Ogiltig status ${currentStatus}`;
    }, [currentStatus]);
    if (!open)
        return null;
    return (<Confirm headerText={confirmValues.title} text={confirmValues.confirmText} appearance="danger" onCancel={() => {
            onClose();
        }} disabled={updateShiftStatusLoading} confirmButton={{
            onConfirm: () => {
                updateShiftStatus({
                    variables: {
                        model: {
                            shiftId: scheduleShiftId,
                            newStatus: confirmValues.newStatus,
                        },
                    },
                    onCompleted: () => {
                        onClose();
                    },
                });
            },
            text: intl.formatMessage({ id: 'general.actions.reset', defaultMessage: 'Spara' }),
        }}/>);
};
export default ToggleShiftStatusConfirm;
