import React, { Fragment, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { faUsers, faCube as assetIcon, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { graphql, usePaginationFragment, usePreloadedQuery, useRefetchableFragment, } from 'react-relay/hooks';
import { Checkbox, Label, Flexbox, Divider, Button, Icon, VisibleContainer, Placeholder, } from '@sonika-se/ui-kit/components';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { schedulableResourceFilterQuery } from '../SchedulableResourceFilter';
const useStyles = createUseStyles((theme) => ({
    container: {
        [`@media (max-width: ${theme.breakPoints.low})`]: {
            marginTop: '3rem',
        },
    },
    smallHeader: {
        color: theme.colors.foreground.tertiary,
    },
    resourceCheckbox: {
        marginRight: 10,
        cursor: 'pointer',
    },
    icon: {
        color: theme.colors.primary,
    },
}));
const SchedulableResourceFilterList = (props) => {
    const { updateFilters, queryRef, type = 'USER', filter, selectedResourceIds, setNumberOfChecked } = props;
    const preloadedData = usePreloadedQuery(schedulableResourceFilterQuery, queryRef);
    const classes = useStyles();
    const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment(graphql `
      fragment SchedulableResourceFilterList_resources on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 30 }
        after: { type: "String" }
        where: { type: "SchedulableEntityFilterInput" }
        model: { type: "SchedulableEntitiesQueryRequestInput" }
      )
      @refetchable(queryName: "SchedulableResourceFilterList_resourcesQuery") {
        schedulableEntities(after: $after, first: $first, model: $model, where: $where)
          @connection(key: "SchedulableResourceFilterList_schedulableEntities") {
          edges {
            node {
              ... on SchedulableUser {
                id
                user {
                  id
                  firstname
                  lastname
                }
              }
              ... on SchedulableAsset {
                id
                asset {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `, preloadedData);
    const [checkedData, refetchCheckedData] = useRefetchableFragment(graphql `
      fragment SchedulableResourceFilterList_resourcesChecked on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 100 }
        after: { type: "String" }
        checkedWhere: { type: "SchedulableEntityFilterInput" }
      )
      @refetchable(queryName: "SchedulableResourceFilterList_resourcesCheckedQuery") {
        checked: schedulableEntities(after: $after, first: $first, where: $checkedWhere) {
          edges {
            node {
              ... on SchedulableUser {
                id
                user {
                  id
                  firstname
                  lastname
                }
              }
              ... on SchedulableAsset {
                id
                asset {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `, preloadedData);
    const [checked, setChecked] = useState(checkedData.checked.edges);
    const [unchecked, setUnchecked] = useState(data.schedulableEntities.edges);
    const intl = useIntl();
    useDidMountEffect(() => {
        refetch({
            model: { nameContains: filter || undefined },
            where: { type: { eq: type }, id: { nin: selectedResourceIds } },
        }, { fetchPolicy: 'network-only' });
        refetchCheckedData({
            checkedWhere: { type: { eq: type }, id: { in: selectedResourceIds } },
        }, { fetchPolicy: 'network-only' });
    }, [filter]);
    useDidMountEffect(() => {
        setChecked(checkedData.checked.edges);
        setUnchecked(data.schedulableEntities.edges);
    }, [data, checkedData]);
    useEffect(() => {
        setNumberOfChecked((checked === null || checked === void 0 ? void 0 : checked.length) || 0);
    }, [checked]);
    return (<>
      <Flexbox direction="column" gap="medium" className={classes.container}>
        {!!(checked === null || checked === void 0 ? void 0 : checked.length) && (<Flexbox justify="space" align="center">
            <Label text={intl.formatMessage({ id: 'general.actions.selected', defaultMessage: 'Valda' })} className={classes.smallHeader}/>
            <Button appearance="text" size="small" style={{ padding: 0 }} onClick={() => {
                setChecked([]);
                setUnchecked([...checked, ...unchecked]);
                updateFilters(selectedResourceIds.filter((checkedValue) => !(checked === null || checked === void 0 ? void 0 : checked.find(({ node }) => {
                    return checkedValue === node.id;
                }))), 'resourceIds');
            }}>
              <FormattedMessage id="general.actions.clear" defaultMessage="Rensa"/>
            </Button>
          </Flexbox>)}
        {checked === null || checked === void 0 ? void 0 : checked.map(({ node }) => {
            const { user, id, asset } = node;
            const name = asset ? asset.name : `${user.firstname} ${user.lastname}`;
            return (<Flexbox key={`assignmenttype_checkbox_${id}`} gap="xsmall" align="center">
              <Checkbox id={`checkedAssignmentType_${id}`} checked={!!selectedResourceIds.find((checkedId) => checkedId === id)} className={classes.resourceCheckbox} onChange={() => {
                    setChecked(checked.filter(({ node }) => node.id !== id));
                    setUnchecked([{ node }, ...unchecked]);
                    updateFilters(selectedResourceIds.filter((checkedValue) => checkedValue !== id), 'resourceIds');
                }}/>
              <Label style={{ fontSize: '1rem' }} text={name} htmlFor={`checkedAssignmentType_${id}`}/>
            </Flexbox>);
        })}
        {!!(checked === null || checked === void 0 ? void 0 : checked.length) && <Divider />}
        {!!(unchecked === null || unchecked === void 0 ? void 0 : unchecked.length) && (<Label text={intl.formatMessage({ defaultMessage: 'Visa endast', id: 'general.actions.only_show' })} className={classes.smallHeader}/>)}
        {unchecked === null || unchecked === void 0 ? void 0 : unchecked.map(({ node }, index) => {
            const { user, id, asset } = node;
            const name = asset ? asset.name : `${user.firstname} ${user.lastname}`;
            return (<Fragment key={`assignmenttype_checkbox_${id}`}>
              <Flexbox gap="xsmall" align="center">
                <Checkbox id={`checkedAssignmentType_${id}`} checked={!!selectedResourceIds.find((checkedId) => checkedId === id)} className={classes.resourceCheckbox} onChange={(event) => {
                    setChecked([...checked, { node }]);
                    setUnchecked(unchecked.filter(({ node }) => node.id !== id));
                    updateFilters([...selectedResourceIds, id], 'resourceIds');
                }}/>
                <Label style={{ fontSize: '1rem' }} text={name} htmlFor={`checkedAssignmentType_${id}`}/>
              </Flexbox>
              {hasNext && index + 1 === (unchecked === null || unchecked === void 0 ? void 0 : unchecked.length) && (<VisibleContainer callback={() => loadNext(30)}>
                  {isLoadingNext && (<Flexbox align="center" justify="center">
                      <Icon icon={faSpinner} spin/>
                    </Flexbox>)}
                </VisibleContainer>)}
            </Fragment>);
        })}
        {(unchecked === null || unchecked === void 0 ? void 0 : unchecked.length) === 0 && (<Placeholder icon={type === 'USER' ? faUsers : assetIcon} text={intl.formatMessage({
                id: 'SchedulableResourceFilterList.no_data',
                defaultMessage: 'Det finns inga {type}',
            }, {
                type: type === 'ASSET'
                    ? intl.formatMessage({ id: 'general.objects.asset.name.plural', defaultMessage: 'Enheter' })
                    : intl.formatMessage({ id: 'general.objects.user.name.plural', defaultMessage: 'Användare' }),
            })}/>)}
      </Flexbox>
    </>);
};
export default SchedulableResourceFilterList;
