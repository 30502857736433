/**
 * @generated SignedSource<<cbf8bef815620b70589a6360bfde722b>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityDisabled",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schedulable",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RunningSchedulesEditNavbar_resetScheduleShiftsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ResetScheduleShiftPayload",
                    "kind": "LinkedField",
                    "name": "resetScheduleShifts",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfScheduleShift",
                            "kind": "LinkedField",
                            "name": "shiftEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShift",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "ScheduleShift_shift"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RunningSchedulesEditNavbar_resetScheduleShiftsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ResetScheduleShiftPayload",
                    "kind": "LinkedField",
                    "name": "resetScheduleShifts",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfScheduleShift",
                            "kind": "LinkedField",
                            "name": "shiftEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShift",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "assignedEntity",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Asset",
                                                            "kind": "LinkedField",
                                                            "name": "asset",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "name",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/)
                                                    ],
                                                    "type": "SchedulableAsset",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "firstname",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "lastname",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "SchedulableUser",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v2 /*: any*/)
                                                    ],
                                                    "type": "Node",
                                                    "abstractKey": "__isNode"
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "assignedEntityId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AssignmentType",
                                            "kind": "LinkedField",
                                            "name": "assignmentType",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "title",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "end",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "start",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isVacant",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "589ea677e3b11da5bc677b0f62f6d932",
            "id": null,
            "metadata": {},
            "name": "RunningSchedulesEditNavbar_resetScheduleShiftsMutation",
            "operationKind": "mutation",
            "text": "mutation RunningSchedulesEditNavbar_resetScheduleShiftsMutation(\n  $model: [ResetScheduleShiftRequestInput!]!\n) {\n  resetScheduleShifts(model: $model) {\n    shiftEdge {\n      node {\n        ...ScheduleShift_shift\n        id\n      }\n    }\n  }\n}\n\nfragment SchedulableAsset_entity on SchedulableAsset {\n  id\n  asset {\n    id\n    color\n    name\n  }\n  entityDisabled\n  schedulable\n}\n\nfragment SchedulableUser_entity on SchedulableUser {\n  id\n  entityDisabled\n  schedulable\n  user {\n    id\n    color\n    firstname\n    lastname\n  }\n}\n\nfragment ScheduleShift_shift on ScheduleShift {\n  id\n  assignedEntity {\n    ... on SchedulableAsset {\n      id\n      ...SchedulableAsset_entity\n    }\n    ... on SchedulableUser {\n      id\n      ...SchedulableUser_entity\n    }\n    __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  assignedEntityId\n  assignmentType {\n    id\n    title\n  }\n  end\n  start\n  isVacant\n  status\n}\n"
        }
    };
})();
node.hash = "7e31b2203c7a00a933fb9b4528abbb70";
export default node;
