/**
 * @generated SignedSource<<c5afce5f98293a28fdb8ab8bd9c66649>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DisplaySchedulableEntity",
    "selections": [
        {
            "kind": "InlineFragment",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                }
            ],
            "type": "ISchedulableEntity",
            "abstractKey": "__isISchedulableEntity"
        },
        {
            "kind": "InlineFragment",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "User"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "SchedulableUser",
            "abstractKey": null
        },
        {
            "kind": "InlineFragment",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Asset",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Asset"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "SchedulableAsset",
            "abstractKey": null
        }
    ],
    "type": "SchedulableEntity",
    "abstractKey": "__isSchedulableEntity"
};
node.hash = "89dbbc72c1a18803c8edc75d418f84f0";
export default node;
