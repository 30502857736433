/**
 * @generated SignedSource<<64db0014347210872dd21cb2ea66ed69>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
        },
        (v0 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "SchedulableEntityListItem_scheduleAssignmentTypeResource",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleAssignmentType",
                "kind": "LinkedField",
                "name": "scheduleAssignmentType",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "assignmentTypeId",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduleId",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "schedulableEntity",
                "plural": false,
                "selections": [
                    {
                        "kind": "InlineFragment",
                        "selections": (v1 /*: any*/),
                        "type": "SchedulableAsset",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v1 /*: any*/),
                        "type": "SchedulableUser",
                        "abstractKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "ScheduleAssignmentTypeResource",
        "abstractKey": null
    };
})();
node.hash = "ac4ebb6d1974b593923fb7f213902499";
export default node;
