import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon } from '@sonika-se/ui-kit/components';
import { RunningSchedulesGridDate } from './RunningSchedulesGridDate';
import { faAngleRight, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import RuningSchedulesGridSchedule from './RunningSchedulesGridSchedule';
import { sv as locale } from 'date-fns/locale';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        weekHeader: {
            background: theme.colors.background.secondary,
            gridArea: ({ numColumns }) => `auto / 1 / auto / span ${numColumns + 1}`,
            backgroundColor: ({ open }) => (open ? '#FFFFFF' : '#EEF7FF'),
            gridColumn: 1,
            textTransform: 'capitalize',
            padding: pxToRem(8),
            paddingRight: 0,
            position: 'sticky',
            top: pxToRem(60),
            borderBottom: `${pxToRem(1)} solid ${theme.colors.foreground.separation}`,
            borderTop: `${pxToRem(1)} solid ${theme.colors.foreground.separation}`,
            margin: `${pxToRem(-1)} ${pxToRem(0)}`,
            zIndex: 1,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#DEEFFF',
            },
        },
        scheduleShift: {
            backgroundColor: '#F2F4F6',
            padding: pxToRem(8),
            paddingBottom: 0,
            paddingLeft: pxToRem(10),
        },
        gridRow: {
            display: 'contents',
            '&:hover': {
                '& $scheduleShift': {
                    background: '#DEEFFF !important',
                },
            },
        },
        weekName: ({ open }) => ({
            position: 'sticky',
            left: '.5rem',
            fontWeight: open ? 'normal' : 'bold',
            display: 'initial',
            lineHeight: pxToRem(20),
            fontSize: pxToRem(14),
        }),
        icon: {
            width: '1.5rem',
            textAlign: 'center',
            color: ({ open }) => (open ? 'unset' : theme.colors.primary),
        },
    };
});
export const RunningSchedulesGridWeek = (props) => {
    const { weekNumber, numColumns, dates, defaultOpen = false, schedules, onShiftClick, setSelectedShifts } = props;
    const [open, setOpen] = useState(defaultOpen);
    const classes = useStyles({ numColumns, open });
    return (<>
      <div className={classes.weekHeader} onClick={() => setOpen(!open)}>
        <div className={classes.weekName}>
          <Icon icon={open ? faAngleUp : faAngleRight} className={classes.icon}/>
          <FormattedMessage defaultMessage="Vecka {weekNumber} ({datesInWeek})" id="RunningSchedulesGridWeek.week" values={{
            weekNumber,
            datesInWeek: `${format(dates[0], 'dd/MM', { locale })}-${format(dates[dates.length - 1], 'dd/MM', {
                locale,
            })}`,
        }}/>
        </div>
      </div>
      {open &&
            (dates === null || dates === void 0 ? void 0 : dates.map((date) => (<div key={`runningschedule_grid_${date}`} className={classes === null || classes === void 0 ? void 0 : classes.gridRow}>
            <RunningSchedulesGridDate date={date}/>
            {schedules.gridSchedules.edges.map(({ node: schedule }) => (<RuningSchedulesGridSchedule date={date} className={classes === null || classes === void 0 ? void 0 : classes.scheduleShift} key={`runningschedule_grid_${date}_${schedule.id}`} schedule={schedule} setSelectedShifts={setSelectedShifts} onShiftClick={onShiftClick}/>))}
          </div>)))}
    </>);
};
export default RunningSchedulesGridWeek;
