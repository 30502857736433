import React, { useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InlineTextArea } from '@sonika-se/ui-kit/components';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import PlaceholderText from '../../PlaceholderText';
export const EditScheduleShiftDescription = (props) => {
    const { scheduleShift, placeholder } = props;
    const { id: shiftId, description, schedule, } = useFragment(graphql `
      fragment EditScheduleShiftDescription on ScheduleShift {
        id
        description
        schedule {
          currentUserPermissions
        }
      }
    `, scheduleShift);
    const [updateScheduleShiftDescription] = useMutation(graphql `
    mutation EditScheduleShiftDescriptionMutation($model: UpdateScheduleShiftRequestInput!) {
      updateScheduleShift(model: $model) {
        shiftEdge {
          node {
            id
            description
          }
        }
      }
    }
  `);
    const { control, handleSubmit, reset } = useForm({
        defaultValues: { description },
        mode: 'onBlur',
    });
    const hasPermissions = usePermissions();
    const canEditRunning = useMemo(() => hasPermissions(['EDIT_SCHEDULES']) || schedule.currentUserPermissions.includes('EDIT_RUNNING'), [hasPermissions, schedule]);
    const textAreaRef = useRef(null);
    return (<Controller control={control} name="description" render={({ field }) => (<InlineTextArea inputName={undefined} inputRef={textAreaRef} disabled={!canEditRunning} defaultValue={field.value} placeholder={canEditRunning ? placeholder : 'Ingen beskrivning'} view={!canEditRunning && !description ? () => <PlaceholderText>Ingen beskrivning</PlaceholderText> : undefined} onChange={() => {
                field.onChange(textAreaRef.current.value);
                handleSubmit((formData) => {
                    reset(formData);
                    updateScheduleShiftDescription({
                        variables: {
                            model: Object.assign({ shiftId }, formData),
                        },
                        optimisticResponse: {
                            updateScheduleShift: {
                                shiftEdge: {
                                    node: Object.assign({ id: shiftId }, formData),
                                },
                            },
                        },
                    });
                })();
            }}/>)}/>);
};
export default EditScheduleShiftDescription;
