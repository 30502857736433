/**
 * @generated SignedSource<<29df8236c563ba3422dfbbd13c2936e7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        "scheduleItems"
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
        }
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "after"
            },
            {
                "defaultValue": 20,
                "kind": "LocalArgument",
                "name": "first"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "model"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "first",
                    "cursor": "after",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "first",
                        "cursor": "after"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./MySchedulePaginationContent_shiftsQuery.graphql'),
                "identifierField": "id"
            }
        },
        "name": "MySchedulePaginationContent_shifts",
        "selections": [
            {
                "alias": "scheduleItems",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "model",
                        "variableName": "model"
                    }
                ],
                "concreteType": "ICanBeUsedToCalculateScheduleTimesUnionTypeConnection",
                "kind": "LinkedField",
                "name": "__MySchedulePaginationContent_scheduleItems_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ICanBeUsedToCalculateScheduleTimesUnionTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v1 /*: any*/)
                                        ],
                                        "type": "Node",
                                        "abstractKey": "__isNode"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": (v2 /*: any*/),
                                        "type": "ScheduleShift",
                                        "abstractKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ScheduleShift",
                                                "kind": "LinkedField",
                                                "name": "scheduleShift",
                                                "plural": false,
                                                "selections": (v2 /*: any*/),
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "ScheduleShiftAssigneeReplacement",
                                        "abstractKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleShiftCard_scheduleShift"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleShiftAbsenceCard_scheduleShiftAssigneeReplacement"
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v1 /*: any*/)
        ],
        "type": "SchedulableUser",
        "abstractKey": null
    };
})();
node.hash = "01a0ceeda1e270f5ad1fa014af0bf39b";
export default node;
