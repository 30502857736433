import React, { Suspense } from 'react';
import { Chip, Flexbox, ModalToSheetSkeleton, Text } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import ScheduleShiftModal, { scheduleShiftModalQuery, } from '~/pages/SchedulePages/components/ScheduleShiftModal/ScheduleShiftModal';
import dateRangeInText from '~/utils/dateRangeInText';
export const ScheduleShiftAbsenceCard = (props) => {
    var _a, _b;
    const { date, scheduleShiftAssigneeReplacementRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftAbsenceCard_scheduleShiftAssigneeReplacement on ScheduleShiftAssigneeReplacement {
        reasonEvent {
          name
          id
          color
        }
        start
        end
        scheduleShift {
          id
        }
      }
    `, scheduleShiftAssigneeReplacementRef);
    const intl = useIntl();
    const [scheduleShiftModalQueryRef, loadScheduleShiftModalQuery, disposeScheduleShiftModalQuery] = useQueryLoader(scheduleShiftModalQuery);
    return (<>
      <div onClick={() => {
            loadScheduleShiftModalQuery({
                shiftId: data.scheduleShift.id,
            });
        }}>
        <Flexbox direction="column" gap="xsmall">
          <Text size="small">
            {dateRangeInText(date, new Date(data === null || data === void 0 ? void 0 : data.start.toString()), new Date(data === null || data === void 0 ? void 0 : data.end.toString()))}
          </Text>
          <Chip style={{
            cursor: 'pointer',
        }} color={((_a = data === null || data === void 0 ? void 0 : data.reasonEvent) === null || _a === void 0 ? void 0 : _a.color) || '#fd544f'} text={(_b = data === null || data === void 0 ? void 0 : data.reasonEvent) === null || _b === void 0 ? void 0 : _b.name}/>
        </Flexbox>
      </div>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh" onClose={() => disposeScheduleShiftModalQuery()} type="loader"/>}>
        {scheduleShiftModalQueryRef && (<ScheduleShiftModal onClose={() => disposeScheduleShiftModalQuery()} preloadedQuery={scheduleShiftModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleShiftAbsenceCard;
