import React, { useState } from 'react';
import { usePreloadedQuery, graphql } from 'react-relay/hooks';
import SchedulableEntityColorRow from '../../../SchedulePages/components/SchedulableEntityColorRow/SchedulableEntityColorRow';
import { H6, Divider, Flexbox, IconButton, Collapse } from '@sonika-se/ui-kit/components';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles(() => ({
    assignmentTypeColumnRoot: {
        '&:not(:first-child)': {
            marginTop: '1rem',
        },
    },
}));
export const editResourceColorListQuery = graphql `
  query EditResourceColorListQuery($scheduleId: ID!) {
    schedule(id: $scheduleId) {
      id
      assignmentTypes {
        nodes {
          id
          assignmentType {
            id
            title
          }
          resources {
            nodes {
              id
              schedulableEntity {
                ... on ISchedulableEntity {
                  id
                  color
                  displayName
                  ... on SchedulableAsset {
                    asset {
                      id
                    }
                  }
                  ... on SchedulableUser {
                    user {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const AssignmentTypeColumn = (props) => {
    const { assignmentType } = props;
    const [open, setOpen] = useState(true);
    const [updatedColor, setUpdatedColor] = useState({});
    const classes = useStyles();
    return (<>
      <Flexbox align="center" justify="space" className={classes.assignmentTypeColumnRoot}>
        <H6>{assignmentType.assignmentType.title}</H6>
        <IconButton icon={open ? faAngleUp : faAngleDown} appearance="text" color="dark" onClick={() => setOpen(!open)}/>
      </Flexbox>
      <Divider />
      <Collapse open={open}>
        {assignmentType.resources.map((resource) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return (<SchedulableEntityColorRow color={updatedColor[((_b = (_a = resource.schedulableEntity) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id) || ((_d = (_c = resource.schedulableEntity) === null || _c === void 0 ? void 0 : _c.asset) === null || _d === void 0 ? void 0 : _d.id)] ||
                    resource.schedulableEntity.color} type={((_e = resource.schedulableEntity) === null || _e === void 0 ? void 0 : _e.user) ? 'user' : 'asset'} id={((_g = (_f = resource.schedulableEntity) === null || _f === void 0 ? void 0 : _f.user) === null || _g === void 0 ? void 0 : _g.id) || ((_j = (_h = resource.schedulableEntity) === null || _h === void 0 ? void 0 : _h.asset) === null || _j === void 0 ? void 0 : _j.id)} name={resource.schedulableEntity.displayName} key={resource.id} onChange={(colorObject) => {
                    var _a, _b, _c, _d;
                    return setUpdatedColor(Object.assign(Object.assign({}, updatedColor), { [((_b = (_a = resource.schedulableEntity) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id) || ((_d = (_c = resource.schedulableEntity) === null || _c === void 0 ? void 0 : _c.asset) === null || _d === void 0 ? void 0 : _d.id)]: colorObject.hex }));
                }}/>);
        })}
      </Collapse>
    </>);
};
export const EditResourceColorList = (props) => {
    var _a, _b, _c;
    const { type = 'all', preloadedQuery } = props;
    const { schedule } = usePreloadedQuery(editResourceColorListQuery, preloadedQuery);
    const filterTypes = (entities) => {
        switch (type) {
            case 'all': {
                return entities;
            }
            case 'user': {
                return entities.filter((entity) => entity.schedulableEntity.user);
            }
            case 'asset': {
                return entities.filter((entity) => entity.schedulableEntity.asset);
            }
            default: {
                return entities;
            }
        }
    };
    const assignmentTypesWithFilteredResources = (_b = (_a = schedule === null || schedule === void 0 ? void 0 : schedule.assignmentTypes) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.map((scheduleItem) => (Object.assign(Object.assign({}, scheduleItem), { resources: filterTypes(scheduleItem.resources.nodes) })));
    return (<div>
      {(_c = assignmentTypesWithFilteredResources === null || assignmentTypesWithFilteredResources === void 0 ? void 0 : assignmentTypesWithFilteredResources.filter((assignmentType) => assignmentType.resources.length)) === null || _c === void 0 ? void 0 : _c.map((assignmentType) => (<AssignmentTypeColumn assignmentType={assignmentType} key={assignmentType.id}/>))}
    </div>);
};
export default EditResourceColorList;
