import React, { Suspense, useContext, useEffect } from 'react';
import { ModalToSheetSkeleton } from '@sonika-se/ui-kit/components';
import { useQueryLoader } from 'react-relay/hooks';
import RunningSchedulesPageContext from '../../RunningSchedulesPageContext';
import { AddScheduleShiftModal, addScheduleShiftModalQuery } from '../AddScheduleShiftModal';
import RunningSchedulesEditNavbar from '../RunningSchedulesEditNavbar/RunningSchedulesEditNavbar';
import RunningSchedulesEditSidebar, { runningSchedulesEditSidebarQuery, } from '../RunningSchedulesEditSidebar/RunningSchedulesEditSidebar';
export const RunningSchedulesEditContainer = (props) => {
    const { setRefetchGrid, setHighlightedSchedulableEntities, unsetAllHighlighted } = props;
    const { editMode } = useContext(RunningSchedulesPageContext);
    const [runningSchedulesEditSidebarQueryRef, loadRunningSchedulesEditSidebarQuery] = useQueryLoader(runningSchedulesEditSidebarQuery);
    const [addScheduleShiftModalQueryRef, loadAddScheduleShiftModalQuery, disposeAddScheduleShiftModalQuery] = useQueryLoader(addScheduleShiftModalQuery);
    useEffect(() => {
        loadRunningSchedulesEditSidebarQuery({});
    }, []);
    if (!editMode)
        return null;
    return (<>
      {runningSchedulesEditSidebarQueryRef && (<RunningSchedulesEditSidebar preloadedQuery={runningSchedulesEditSidebarQueryRef} refetch={() => {
                setRefetchGrid();
            }} setHighlightedSchedulableEntities={setHighlightedSchedulableEntities}/>)}
      <RunningSchedulesEditNavbar refetch={() => {
            setRefetchGrid();
        }} unsetAllHighlighted={unsetAllHighlighted} onAddScheduleShift={() => {
            loadAddScheduleShiftModalQuery({});
        }}/>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {addScheduleShiftModalQueryRef && (<AddScheduleShiftModal onClose={() => {
                disposeAddScheduleShiftModalQuery();
                setRefetchGrid();
            }} preloadedQuery={addScheduleShiftModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default RunningSchedulesEditContainer;
