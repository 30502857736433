import React, { Suspense, useContext, useState } from 'react';
import { string, object, date, ref } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { H6, Form, Field, DateInput, Input } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatISO } from 'date-fns';
import RunningSchedulesPageContext from '../../../RunningSchedulesPageContext';
import EventField from '~/components/EventField';
const validationRunningSchedulesSelectDateForm = (intl) => object({
    reasonEventId: string().required(),
    fromDate: date()
        .typeError(intl.formatMessage({
        id: 'RunningSchedulesSelectDateForm.fields.from_date.required',
        defaultMessage: 'Du måste ange ett startdatum',
    }))
        .required(),
    toDate: date()
        .typeError(intl.formatMessage({
        id: 'RunningSchedulesSelectDateForm.fields.to_date.required',
        defaultMessage: 'Du måste ange ett slutdatum',
    }))
        .min(ref('fromDate'), ({ min }) => `Datum måste vara efter ${formatISO(min, { representation: 'date' })}`)
        .required(),
});
export const RunningSchedulesSelectDateForm = (props) => {
    const { id, state, onSubmit, currentlyAssignedAssignmentType, currentlyAssignedEntityId } = props;
    const intl = useIntl();
    const { fromDate } = useContext(RunningSchedulesPageContext);
    const [lastPickedDate, setLastPickedDate] = useState(new Date(fromDate));
    const [data, setData] = useState({
        reasonEventId: '',
        fromDate: undefined,
        toDate: undefined,
    });
    const { control, formState: { errors }, handleSubmit, } = useForm({
        resolver: yupResolver(validationRunningSchedulesSelectDateForm(intl)),
    });
    if (state === 'READ') {
        return (<H6>
        {`${format(data.fromDate, 'd MMM', { locale }).replace('.', '')} - ${format(data.toDate, 'd MMM', {
                locale,
            }).replace('.', '')}`}
      </H6>);
    }
    return (<>
      <H6 style={{ marginTop: '1rem' }}>
        <FormattedMessage defaultMessage="Välj datum och anledning till bytet" id="RunningSchedulesSelectDateForm.header"/>
      </H6>
      <Form id={id} columns="1fr 1fr" style={{ marginTop: '1rem' }} onSubmit={handleSubmit((formData) => {
            setData(formData);
            onSubmit(formData);
        })}>
        <Field label={intl.formatMessage({
            defaultMessage: 'Från datum',
            id: 'RunningSchedulesSelectDateForm.fromDateFieldlabel',
        })}>
          <Controller control={control} name="fromDate" render={({ field }) => {
            var _a, _b;
            return (<DateInput error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.fromDate) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} defaultDisplayValue={lastPickedDate} onChange={(date) => {
                    field.onChange(date);
                    setLastPickedDate(date);
                }} placeholder={intl.formatMessage({
                    defaultMessage: 'Välj datum',
                    id: 'RunningSchedulesSelectDateForm.fromDateField.placeholder',
                })} defaultValue={data.fromDate} value={field.value}/>);
        }}/>
        </Field>
        <Field label={intl.formatMessage({
            defaultMessage: 'Till och med datum',
            id: 'RunningSchedulesSelectDateForm.toDateField.label',
        })}>
          <Controller control={control} name="toDate" render={({ field }) => {
            var _a, _b;
            return (<DateInput error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.toDate) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} defaultDisplayValue={lastPickedDate} onChange={(date) => {
                    field.onChange(date);
                    setLastPickedDate(date);
                }} placeholder={intl.formatMessage({
                    defaultMessage: 'Välj datum',
                    id: 'RunningSchedulesSelectDateForm.toDateField.placeholder',
                })} defaultValue={data.toDate} value={field.value}/>);
        }}/>
        </Field>
        {currentlyAssignedAssignmentType && currentlyAssignedEntityId !== 'vakant' ? (<Suspense fallback={<div />}>
            <Field label={intl.formatMessage({
                defaultMessage: 'Anledning till byte',
                id: 'RunningSchedulesSelectDateForm.reason_event_id.label',
            })} columnSpan={5}>
              <Controller control={control} name="reasonEventId" render={({ field }) => {
                var _a, _b;
                return (<EventField error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.reasonEventId) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} placeholder={intl.formatMessage({
                        defaultMessage: 'Välj anledning',
                        id: 'RunningSchedulesSelectDateForm.reason_event_id.placeholder',
                    })} where={{ isAssigneeReplacementReason: { eq: true } }} stayOpenOnSelect={false} defaultValue={data.reasonEventId} assignmentTypeId={currentlyAssignedAssignmentType} onChange={(value) => field.onChange(value ? value[0] : undefined)}/>);
            }}/>
            </Field>
          </Suspense>) : (<Controller control={control} name="reasonEventId" render={({ field }) => <Input type="hidden" defaultValue="null" value={field.value}/>}/>)}
      </Form>
    </>);
};
export default RunningSchedulesSelectDateForm;
