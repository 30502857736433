import { formatDuration } from '@sonika-se/ui-kit/utils/time';
import enums from '~/enums.json';
const { ValueUnit: ValueUnitEnum } = enums;
const timeUnits = ['DAY', 'HOUR', 'MINUTE'];
export const formatValueUnit = (input) => {
    var _a, _b;
    if (timeUnits.find((x) => x === input.unit)) {
        return formatDuration({
            days: input.unit === 'DAY' ? input.value : 0,
            hours: input.unit === 'HOUR' ? input.value : 0,
            minutes: input.unit === 'MINUTE' ? input.value : 0,
        });
    }
    return `${input.value} ${(_b = (_a = ValueUnitEnum[input.unit]) === null || _a === void 0 ? void 0 : _a.description.toLowerCase()) !== null && _b !== void 0 ? _b : '?'}`;
};
