import React from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Flexbox, IconButton } from '@sonika-se/ui-kit/components';
export const EditableListItem = (props) => {
    const { children, disabled = false, onDelete } = props;
    return (<Flexbox direction="row" gap="medium" justify="space">
      {children}
      {onDelete && (<IconButton appearance="text" color="default" disabled={disabled} icon={faTimes} onClick={() => onDelete()}/>)}
    </Flexbox>);
};
export default EditableListItem;
