/**
 * @generated SignedSource<<eac5133acf1bdc16c2a67dd2adb0859a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = {
        "alias": "breakTypes",
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "BreakEnum"
            }
        ],
        "concreteType": "EnumValueDetails",
        "kind": "LinkedField",
        "name": "enumValues",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
            }
        ],
        "storageKey": "enumValues(name:\"BreakEnum\")"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v3 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "MINUTE"
        }
    ], v4 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ChangeShiftTemplateTimeModalQuery",
            "selections": [
                (v1 /*: any*/),
                {
                    "args": (v2 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "ChangeShiftTemplateTimeModal_ScheduleTemplate"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ChangeShiftTemplateTimeModalQuery",
            "selections": [
                (v1 /*: any*/),
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "ScheduleTemplateShift",
                    "kind": "LinkedField",
                    "name": "scheduleTemplateShift",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTimeOfDay",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTimeOfDay",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleTemplateShiftBreak",
                            "kind": "LinkedField",
                            "name": "breaks",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v3 /*: any*/),
                                    "concreteType": "TimeSpanUnitValue",
                                    "kind": "LinkedField",
                                    "name": "offsetStart",
                                    "plural": false,
                                    "selections": (v4 /*: any*/),
                                    "storageKey": "offsetStart(unit:\"MINUTE\")"
                                },
                                {
                                    "alias": null,
                                    "args": (v3 /*: any*/),
                                    "concreteType": "TimeSpanUnitValue",
                                    "kind": "LinkedField",
                                    "name": "offsetEnd",
                                    "plural": false,
                                    "selections": (v4 /*: any*/),
                                    "storageKey": "offsetEnd(unit:\"MINUTE\")"
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "type",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 100
                                },
                                {
                                    "kind": "Literal",
                                    "name": "order",
                                    "value": [
                                        {
                                            "start": "ASC"
                                        }
                                    ]
                                }
                            ],
                            "concreteType": "ScheduleShiftConnection",
                            "kind": "LinkedField",
                            "name": "generatedScheduleShifts",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "totalCount",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShift",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "start",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "end",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "assignedEntity",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "displayName",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "ISchedulableEntity",
                                                    "abstractKey": "__isISchedulableEntity"
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v5 /*: any*/)
                                                    ],
                                                    "type": "Node",
                                                    "abstractKey": "__isNode"
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "generatedScheduleShifts(first:100,order:[{\"start\":\"ASC\"}])"
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b6633c45ada4b30ce2888bbaab09f2ea",
            "id": null,
            "metadata": {},
            "name": "ChangeShiftTemplateTimeModalQuery",
            "operationKind": "query",
            "text": "query ChangeShiftTemplateTimeModalQuery(\n  $id: ID!\n) {\n  breakTypes: enumValues(name: \"BreakEnum\") {\n    key\n    name\n    description\n  }\n  ...ChangeShiftTemplateTimeModal_ScheduleTemplate_1Bmzm5\n}\n\nfragment ChangeShiftTemplateTimeModal_ScheduleTemplate_1Bmzm5 on Query {\n  scheduleTemplateShift(id: $id) {\n    startTimeOfDay\n    endTimeOfDay\n    breaks {\n      offsetStart(unit: MINUTE) {\n        value\n        unit\n      }\n      offsetEnd(unit: MINUTE) {\n        value\n        unit\n      }\n      type\n    }\n    generatedScheduleShifts(first: 100, order: [{start: ASC}]) {\n      totalCount\n      nodes {\n        id\n        start\n        end\n        assignedEntity {\n          __typename\n          ... on ISchedulableEntity {\n            __isISchedulableEntity: __typename\n            id\n            displayName\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "2446168a21ae193e400315ca10a19149";
export default node;
