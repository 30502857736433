/**
 * @generated SignedSource<<6048af05be20bbd13ee59f98998b2f04>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "EnqueuePublishScheduleTemplateJobPayload",
            "kind": "LinkedField",
            "name": "enqueuePublishScheduleTemplateJob",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "queuedJobMessageId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "PublishScheduleModalEnqueueJobMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "PublishScheduleModalEnqueueJobMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "d2eac7fa21712cbd3865e13437cbc7bb",
            "id": null,
            "metadata": {},
            "name": "PublishScheduleModalEnqueueJobMutation",
            "operationKind": "mutation",
            "text": "mutation PublishScheduleModalEnqueueJobMutation(\n  $model: EnqueuePublishScheduleTemplateJobInput!\n) {\n  enqueuePublishScheduleTemplateJob(model: $model) {\n    queuedJobMessageId\n  }\n}\n"
        }
    };
})();
node.hash = "0fbf1e2aba2af29e94da3e94855f1681";
export default node;
