import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { faCalendarStar, faInfo } from '@fortawesome/pro-regular-svg-icons';
import { faCalendarStar as faCalendarStarSolid, faCheck, faInfo as faInfoSolid } from '@fortawesome/pro-solid-svg-icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { sv as locale } from 'date-fns/locale';
// sonika-lib
import { Button, Collapse, Tooltip, Badge } from '@sonika-se/ui-kit/components';
import { Text, Popover, PopoverHeader, PopoverContent, Switch, IconButton, Flexbox, Notification, } from '@sonika-se/ui-kit/components';
import RunningSchedulesPageContext from '../../RunningSchedulesPageContext';
import { format } from 'date-fns';
const useStyles = createUseStyles((theme) => ({
    text: ({ useDefaultSearch }) => ({
        color: useDefaultSearch ? theme.colors.foreground.primary : theme.colors.foreground.secondary,
    }),
    smallText: {
        color: theme.colors.foreground.tertiary,
    },
}));
const RunningSchedulesSaveSearch = (props) => {
    const { saveDefaultSearch, displayDefaultSearch } = props;
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [displayInformation, setDisplayInformation] = React.useState(false);
    const { useDefaultSearch, defaultSearch, defaultSearchSaved } = useContext(RunningSchedulesPageContext).defaultSearch;
    const classes = useStyles({ useDefaultSearch });
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (<>
      <Tooltip content={intl.formatMessage({ defaultMessage: 'Sparad sökning', id: 'RunningSchedulesSaveSearch.header' })}>
        <Badge appearance={useDefaultSearch ? 'success' : 'none'} icon={faCheck}>
          <IconButton appearance={open ? 'filled' : 'outlined'} onClick={handleClick} icon={open ? faCalendarStarSolid : faCalendarStar} size="medium"/>
        </Badge>
      </Tooltip>
      <Popover size="auto" anchorEl={open ? anchorEl : undefined} onClose={handleClose} style={{ width: '21.25rem' }}>
        <>
          <PopoverHeader>
            <FormattedMessage id="RunningSchedulesSaveSearch.header" defaultMessage="Sparad sökning"/>
          </PopoverHeader>
          <PopoverContent>
            <Flexbox direction="column" gap="small">
              <Flexbox justify="space">
                <Flexbox gap="small">
                  {defaultSearch ? (<Switch defaultChecked={useDefaultSearch} onChange={(event) => {
                displayDefaultSearch(event.target.checked);
            }}/>) : (<Tooltip content={intl.formatMessage({
                defaultMessage: 'Spara en sökning för att aktivera den',
                id: 'RunningSchedulesSaveSearch.save_search_to_activate',
            })} placement="right">
                      <Switch disabled/>
                    </Tooltip>)}
                  <Flexbox direction="column" gap="xxsmall">
                    <Text className={classes.text}>
                      <FormattedMessage id="RunningSchedulesSaveSearch.activate_search" defaultMessage="Aktivera sparad sökning"/>
                    </Text>
                    <Text size="small" className={classes.smallText}>
                      {!!defaultSearch ? (<FormattedMessage id="RunningSchedulesSaveSearch.saved_at_date" defaultMessage="Sparad {date}" values={{ date: format(new Date(defaultSearchSaved), 'yyyy-MM-dd HH:mm', { locale }) }}/>) : (<FormattedMessage id="RunningSchedulesSaveSearch.no_saved_search" defaultMessage="Du har ingen sparad sökning"/>)}
                    </Text>
                  </Flexbox>
                </Flexbox>
                <IconButton appearance={displayInformation ? 'filled' : 'outlined'} onClick={() => {
            setDisplayInformation(!displayInformation);
        }} icon={displayInformation ? faInfoSolid : faInfo} size="small"/>
              </Flexbox>
              <Collapse open={displayInformation}>
                <Notification color="primary" title={intl.formatMessage({
            id: 'RunningSchedulesSaveSearch.saved_search_information.title',
            defaultMessage: 'Information om sparad sökning',
        })} text={intl.formatMessage({
            id: 'RunningSchedulesSaveSearch.saved_search_information.text',
            defaultMessage: 'Sökning sparas i din webbläsare och försvinner om du tömmer cache. Sparar du nuvarande sökning skrivs den tidigare över.',
        })}/>
              </Collapse>
              <Button onClick={() => {
            saveDefaultSearch(true);
            handleClose();
        }}>
                {!!defaultSearch ? (<FormattedMessage id="RunningSchedulesSaveSearch.update_search" defaultMessage="Uppdatera sparad sökning"/>) : (<FormattedMessage id="RunningSchedulesSaveSearch.save_search" defaultMessage="Spara nuvarande sökning"/>)}
              </Button>
            </Flexbox>
          </PopoverContent>
        </>
      </Popover>
    </>);
};
export default RunningSchedulesSaveSearch;
