import React, { useState, Suspense, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { faCalendarPlus, faCalendarWeek, faTimes, faExclamationTriangle, faMinusSquare, faUserSlash, faClock, faSortAlt, } from '@fortawesome/pro-light-svg-icons';
import { useMutation, graphql, useFragment } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import { IconButton, Flexbox, ModalToSheetSkeleton, Tooltip, Divider, Confirm } from '@sonika-se/ui-kit/components';
import NewScheduleTemplateShiftModal from '../NewScheduleTemplateShiftModal/NewScheduleTemplateShiftModal';
import NewScheduleTemplatePeriodModal from '../NewScheduleTemplatePeriodModal/NewScheduleTemplatePeriodModal';
import ScheduleTemplateDetailsPageContext from '../../ScheduleTemplateDetailsPageContext';
import ScheduleTemplateEditTimeModal from './components/ScheduleTemplateEditTimeModal';
import SortPeriodsModal from './components/SortPeriodsModal';
const useStyles = createUseStyles(() => ({
    buttonColum: {
        padding: '1rem .5rem',
        borderRight: '1px solid #D5D6D8',
    },
}));
const ScheduleTemplateEditNavbar = (props) => {
    const { scheduleTemplate, scheduleTemplateRef, resetHighlightedResources } = props;
    const context = useContext(ScheduleTemplateDetailsPageContext);
    const { highlightedShifts } = context;
    const [openModal, setOpenModal] = useState(undefined);
    const intl = useIntl();
    const classes = useStyles();
    const data = useFragment(graphql `
      fragment ScheduleTemplateEditNavbar_scheduleTemplate on ScheduleTemplate {
        id
        periods(first: null, order: { order: ASC }) {
          ...SortPeriodsModal_scheduleTemplatePeriodConnection
          totalCount
        }
      }
    `, scheduleTemplateRef);
    const [deleteScheduleTemplateShifts] = useMutation(graphql `
      mutation ScheduleTemplateEditNavbarDeleteMutation($model: [DeleteScheduleTemplateShiftRequestInput!]!) {
        deleteScheduleTemplateShifts(model: $model) {
          shiftEdge {
            node {
              period {
                ...ScheduleTemplatePeriod_period
              }
            }
          }
        }
      }
    `);
    const [updateScheduleTemplateShift] = useMutation(graphql `
      mutation ScheduleTemplateEditNavbarUpdateMutation($model: [UpdateScheduleTemplateShiftRequestInput!]!) {
        updateScheduleTemplateShifts(model: $model) {
          shiftEdge {
            node {
              id
              ...ScheduleTemplateShift_shift
              description
              endTimeOfDay
              startTimeOfDay
              breaks {
                offsetEnd(unit: MINUTE) {
                  value
                }
                offsetStart(unit: MINUTE) {
                  value
                }
                type
              }
            }
          }
        }
      }
    `);
    return (<>
      <Flexbox direction="column" gap="xsmall" className={classes.buttonColum}>
        <div>
          <Tooltip content={intl.formatMessage({
            id: 'general.misc.period.actions.create',
            defaultMessage: 'Lägg till period',
        })} placement="right">
            <IconButton icon={faCalendarWeek} onClick={() => setOpenModal('addPeriods')}/>
          </Tooltip>
        </div>
        <div>
          <Tooltip content={intl.formatMessage({
            id: 'ScheduleTemplateEditNavbar.sort_periods',
            defaultMessage: 'Sortera perioder',
        })} placement="right">
            <IconButton disabled={data.periods.totalCount < 1} icon={faSortAlt} onClick={() => setOpenModal('sortPeriods')}/>
          </Tooltip>
        </div>
        <div>
          <Tooltip content={intl.formatMessage({
            id: 'general.misc.schedule_shift.actions.create',
            defaultMessage: 'Lägg till pass',
        })} placement="right">
            <IconButton disabled={data.periods.totalCount === 0} icon={faCalendarPlus} onClick={() => setOpenModal('addShifts')}/>
          </Tooltip>
        </div>
        {!!highlightedShifts.length && (<>
            <Divider />
            <div>
              <Tooltip content={intl.formatMessage({
                id: 'general.misc.highlighted_shift.actions.unmark_selected',
                defaultMessage: 'Avmarkera alla valda pass',
            })} placement="right">
                <IconButton icon={faMinusSquare} onClick={() => resetHighlightedResources()}/>
              </Tooltip>
            </div>
            <div>
              <Tooltip content={intl.formatMessage({
                id: 'general.misc.highlighted_shift.actions.change_time',
                defaultMessage: 'Ändra tid för markerade pass',
            })} placement="right">
                <IconButton icon={faClock} onClick={() => {
                setOpenModal('changeTime');
            }}/>
              </Tooltip>
            </div>
            <div>
              <Tooltip content={intl.formatMessage({
                id: 'general.misc.highlighted_shift.actions.make_highlighted_vacant',
                defaultMessage: 'Sätt markerade pass {vacant}',
            }, {
                vacant: intl.formatMessage({
                    id: 'general.misc.schedule_shift.vacant.name.plural',
                    defaultMessage: 'Vakanta',
                }),
            })} placement="right">
                <IconButton icon={faUserSlash} onClick={() => {
                setOpenModal('removeAssignees');
            }}/>
              </Tooltip>
            </div>
            <div>
              <Tooltip content={intl.formatMessage({
                id: 'general.misc.highlighted_shift.actions.delete',
                defaultMessage: 'Ta bort markerade pass',
            })} placement="right">
                <IconButton icon={faTimes} onClick={() => {
                setOpenModal('deleteShifts');
            }}/>
              </Tooltip>
            </div>
          </>)}
      </Flexbox>
      {openModal === 'addShifts' && (<Suspense fallback={<ModalToSheetSkeleton />}>
          <NewScheduleTemplateShiftModal onCancel={() => setOpenModal(undefined)} onSubmit={() => {
                setOpenModal(undefined);
                context.dispatchScheduleTemplateUpdatedEvent();
            }} scheduleTemplateId={scheduleTemplate.id}/>
        </Suspense>)}
      {openModal === 'sortPeriods' && (<Suspense fallback={<ModalToSheetSkeleton />}>
          <SortPeriodsModal onCancel={() => setOpenModal(undefined)} periodsRef={data === null || data === void 0 ? void 0 : data.periods} scheduleTemplateId={data.id}/>
        </Suspense>)}
      {openModal === 'addPeriods' && (<Suspense fallback={<ModalToSheetSkeleton />}>
          <NewScheduleTemplatePeriodModal onCancel={() => setOpenModal(undefined)} onSubmit={() => setOpenModal(undefined)} scheduleTemplateId={scheduleTemplate.id}/>
        </Suspense>)}
      {openModal === 'deleteShifts' && (<Confirm appearance="danger" headerText={intl.formatMessage({
                id: 'ScheduleTemplateDetailsPage.ScheduleTemplateEditNavbar.delete_confirm.header_text',
                defaultMessage: 'Vill du ta bort {number} pass?',
            }, { number: highlightedShifts.length })} text={intl.formatMessage({
                id: 'ScheduleTemplateDetailsPage.ScheduleTemplateEditNavbar.delete_confirm.description',
                defaultMessage: 'Passen kommer försvinna och du kan inte ångra det i efterhand',
            })} onCancel={() => setOpenModal(undefined)} icon={faExclamationTriangle} confirmButton={{
                text: intl.formatMessage({
                    id: 'general.actions.delete',
                    defaultMessage: 'Ta bort',
                }),
                onConfirm: () => {
                    deleteScheduleTemplateShifts({
                        variables: {
                            model: highlightedShifts.map((shiftId) => ({ shiftId })),
                        },
                        onCompleted: () => {
                            resetHighlightedResources();
                            context.dispatchScheduleTemplateUpdatedEvent();
                        },
                    });
                    setOpenModal(undefined);
                },
            }}/>)}
      {openModal === 'changeTime' && (<ScheduleTemplateEditTimeModal scheduleTemplate={scheduleTemplate} onCancel={() => {
                setOpenModal(undefined);
                resetHighlightedResources();
            }}/>)}
      {openModal === 'removeAssignees' && (<Confirm appearance="danger" headerText={intl.formatMessage({
                id: 'general.misc.highlighted_shift.actions.make_vacant.description',
                defaultMessage: 'Vill du sätta {number} pass {vacant}?',
            }, {
                number: highlightedShifts.length,
                vacant: intl.formatMessage({
                    id: 'general.misc.schedule_shift.vacant.name.plural',
                    defaultMessage: 'Vakanta',
                }),
            })} onCancel={() => setOpenModal(undefined)} icon={faExclamationTriangle} confirmButton={{
                text: intl.formatMessage({
                    id: 'general.misc.highlighted_shift.actions.make_vacant',
                    defaultMessage: 'Sätt {vacant}',
                }, {
                    vacant: intl.formatMessage({
                        id: 'general.misc.schedule_shift.vacant.name.plural',
                        defaultMessage: 'Vakanta',
                    }),
                }),
                onConfirm: () => {
                    updateScheduleTemplateShift({
                        variables: {
                            model: highlightedShifts.map((shiftId) => ({ shiftId, assignedEntityId: null })),
                        },
                        onCompleted: () => {
                            resetHighlightedResources();
                            context.dispatchScheduleTemplateUpdatedEvent();
                        },
                    });
                    setOpenModal(undefined);
                },
            }}/>)}
    </>);
};
export default ScheduleTemplateEditNavbar;
