/**
 * @generated SignedSource<<4a15309a7ce17d049fba1f17b8b444e1>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                        "isWork": {
                            "eq": true
                        }
                    }
                }
            ],
            "concreteType": "EventConnection",
            "kind": "LinkedField",
            "name": "events",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Event",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "assignmentTypes",
                                    "plural": true,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "events(where:{\"isWork\":{\"eq\":true}})"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "EditScheduleShiftEventQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "EditScheduleShiftEventQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "fbee86d7071dd50949d49761dc1434e8",
            "id": null,
            "metadata": {},
            "name": "EditScheduleShiftEventQuery",
            "operationKind": "query",
            "text": "query EditScheduleShiftEventQuery {\n  events(where: {isWork: {eq: true}}) {\n    edges {\n      node {\n        id\n        name\n        assignmentTypes {\n          id\n          title\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "9b92f3da7529f32c0d788f5163d537d5";
export default node;
