import React, { Suspense, useState } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { Button, Card, CardContent, CardHeader, Chip, Flexbox, H6, IconButton, ModalToSheetSkeleton, Text, } from '@sonika-se/ui-kit/components';
import { formatDateSpan, toDate } from '@sonika-se/ui-kit/utils/time';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import ScheduleShiftHistoryVacancyInterestList from '~/lists/ScheduleShiftHistoryVacancyInterestList';
import ScheduleShiftModal, { scheduleShiftModalQuery, } from '~/pages/SchedulePages/components/ScheduleShiftModal/ScheduleShiftModal';
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
export const ScheduleShiftVacancyCard = (props) => {
    const { scheduleShiftRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftVacancyCard_scheduleShift on ScheduleShift {
        assignmentType {
          title
        }
        assignedEntityId
        end
        id
        schedule {
          name
        }
        start
        vacancyInterests {
          ...ScheduleShiftHistoryVacancyInterestList_scheduleShiftHistoryVacancyInterestConnection
        }
        numInterested: vacancyInterests(where: { status: { eq: INTERESTED } }) {
          totalCount
        }
        numNotInterested: vacancyInterests(where: { status: { eq: NOT_INTERESTED } }) {
          totalCount
        }
      }
    `, scheduleShiftRef);
    const [scheduleShiftModalQueryRef, loadScheduleShiftModalQuery, disposeScheduleShiftModalQuery] = useQueryLoader(scheduleShiftModalQuery);
    const [expanded, setExpanded] = useState(false);
    return (<>
      <Card style={{ opacity: !!data.assignedEntityId ? 0.5 : 1 }}>
        <CardHeader>
          <Flexbox align="center" direction="row" gap="small">
            <IconButton icon={expanded ? faCaretDown : faCaretRight} onClick={() => {
            setExpanded((expanded) => !expanded);
        }} size="small"/>
            <Flexbox direction="column" gap="none" style={{ flex: 1 }}>
              <H6>
                <Button appearance="text" color="primary" onClick={() => {
            loadScheduleShiftModalQuery({ shiftId: data.id });
        }} padding={false}>
                  {formatDateSpan(toDate(data.start), toDate(data.end), 'medium')}
                </Button>
              </H6>
              <Text appearance="light" size="small">
                {`${data.assignmentType.title} - ${data.schedule.name}`}
              </Text>
            </Flexbox>
            <Flexbox direction="row" gap="xsmall">
              {data.numInterested.totalCount > 0 && (<Chip color={sonikaTheme.colors.system.go} icon={faThumbsUp} size="xsmall" text={data.numInterested.totalCount.toString()}/>)}
              {data.numNotInterested.totalCount > 0 && (<Chip color={sonikaTheme.colors.system.stop} icon={faThumbsDown} size="xsmall" text={data.numNotInterested.totalCount.toString()}/>)}
            </Flexbox>
          </Flexbox>
        </CardHeader>
        {expanded && (<CardContent>
            <ScheduleShiftHistoryVacancyInterestList scheduleShiftHistoryVacancyInterestConnectionRef={data.vacancyInterests}/>
          </CardContent>)}
      </Card>
      <Suspense fallback={<ModalToSheetSkeleton height="20vh" onClose={() => disposeScheduleShiftModalQuery()} type="loader"/>}>
        {scheduleShiftModalQueryRef && (<ScheduleShiftModal onClose={() => disposeScheduleShiftModalQuery()} preloadedQuery={scheduleShiftModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ScheduleShiftVacancyCard;
