/**
 * @generated SignedSource<<5bfa6b572fa021836be0d5a974b2ff00>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "fromDate"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "toDate"
        }
    ], v1 = {
        "kind": "Variable",
        "name": "fromDate",
        "variableName": "fromDate"
    }, v2 = {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
    }, v3 = {
        "kind": "Variable",
        "name": "toDate",
        "variableName": "toDate"
    }, v4 = [
        {
            "kind": "Literal",
            "name": "unit",
            "value": "MINUTE"
        }
    ], v5 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
        }
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ChangeShiftTemplateTimeModal_ScheduleTemplateQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "ChangeShiftTemplateTimeModal_ScheduleTemplate"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ChangeShiftTemplateTimeModal_ScheduleTemplateQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        (v2 /*: any*/)
                    ],
                    "concreteType": "ScheduleTemplateShift",
                    "kind": "LinkedField",
                    "name": "scheduleTemplateShift",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTimeOfDay",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTimeOfDay",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleTemplateShiftBreak",
                            "kind": "LinkedField",
                            "name": "breaks",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "TimeSpanUnitValue",
                                    "kind": "LinkedField",
                                    "name": "offsetStart",
                                    "plural": false,
                                    "selections": (v5 /*: any*/),
                                    "storageKey": "offsetStart(unit:\"MINUTE\")"
                                },
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "TimeSpanUnitValue",
                                    "kind": "LinkedField",
                                    "name": "offsetEnd",
                                    "plural": false,
                                    "selections": (v5 /*: any*/),
                                    "storageKey": "offsetEnd(unit:\"MINUTE\")"
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "type",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 100
                                },
                                (v1 /*: any*/),
                                {
                                    "kind": "Literal",
                                    "name": "order",
                                    "value": [
                                        {
                                            "start": "ASC"
                                        }
                                    ]
                                },
                                (v3 /*: any*/)
                            ],
                            "concreteType": "ScheduleShiftConnection",
                            "kind": "LinkedField",
                            "name": "generatedScheduleShifts",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "totalCount",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ScheduleShift",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        (v6 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "start",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "end",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "assignedEntity",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v6 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "displayName",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "ISchedulableEntity",
                                                    "abstractKey": "__isISchedulableEntity"
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v6 /*: any*/)
                                                    ],
                                                    "type": "Node",
                                                    "abstractKey": "__isNode"
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "73a09a1d30ff495e37a686965a0af3e1",
            "id": null,
            "metadata": {},
            "name": "ChangeShiftTemplateTimeModal_ScheduleTemplateQuery",
            "operationKind": "query",
            "text": "query ChangeShiftTemplateTimeModal_ScheduleTemplateQuery(\n  $fromDate: DateTime\n  $id: ID!\n  $toDate: DateTime\n) {\n  ...ChangeShiftTemplateTimeModal_ScheduleTemplate_121uL4\n}\n\nfragment ChangeShiftTemplateTimeModal_ScheduleTemplate_121uL4 on Query {\n  scheduleTemplateShift(id: $id) {\n    startTimeOfDay\n    endTimeOfDay\n    breaks {\n      offsetStart(unit: MINUTE) {\n        value\n        unit\n      }\n      offsetEnd(unit: MINUTE) {\n        value\n        unit\n      }\n      type\n    }\n    generatedScheduleShifts(first: 100, order: [{start: ASC}], fromDate: $fromDate, toDate: $toDate) {\n      totalCount\n      nodes {\n        id\n        start\n        end\n        assignedEntity {\n          __typename\n          ... on ISchedulableEntity {\n            __isISchedulableEntity: __typename\n            id\n            displayName\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "8c8e01ab818a80ad35e5fc76484de1c8";
export default node;
