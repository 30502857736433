import React from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShiftHistoryEvent } from './ScheduleShiftHistoryEvent';
import User from '../User';
export const ScheduleShiftHistoryEventChange = (props) => {
    const data = useFragment(graphql `
      fragment ScheduleShiftHistoryEventChange_history on ScheduleShiftHistoryEventChange {
        id
        newEvent {
          name
          id
        }
        previousEvent {
          name
          id
        }
        byUser {
          ...User_user
        }
        timestamp
      }
    `, props.history);
    const intl = useIntl();
    const { newEvent, byUser, previousEvent, timestamp } = data;
    return (<ShiftHistoryEvent timestamp={timestamp.toString()}>
      <p>
        <FormattedMessage id="ScheduleShiftModal.ScheduleShiftHistoryEventChange.replaced" defaultMessage="{user} bytte händelse på passet från {originalEvent} till {newEvent}." values={{
            user: <User user={byUser}/>,
            originalEvent: <strong style={{ fontWeight: 'bold' }}>{previousEvent.name}</strong>,
            newEvent: <strong style={{ fontWeight: 'bold' }}>{newEvent.name}</strong>,
        }}/>
      </p>
    </ShiftHistoryEvent>);
};
