/**
 * @generated SignedSource<<a4d71041e91fb022cc0e70b5dadf78a0>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyScheduleHeader_query",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                        "schedulable": {
                            "eq": true
                        }
                    }
                }
            ],
            "concreteType": "SchedulableEntityConnection",
            "kind": "LinkedField",
            "name": "schedulableEntities",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchedulableEntityEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "userId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "type": "SchedulableUser",
                                    "abstractKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "schedulableEntities(where:{\"schedulable\":{\"eq\":true}})"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "ad33f91e908f32497faa83de1a6b64e5";
export default node;
