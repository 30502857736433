/**
 * @generated SignedSource<<157948e7a2f2d3886be43fe6c67a96d3>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "scheduleShiftClashGroupListModel"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "scheduleShiftClashGroupListModel"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentType",
        "kind": "LinkedField",
        "name": "assignmentType",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            (v3 /*: any*/)
        ],
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v7 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        (v3 /*: any*/)
    ], v8 = {
        "alias": null,
        "args": null,
        "concreteType": "Schedule",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": (v7 /*: any*/),
        "storageKey": null
    }, v9 = {
        "kind": "InlineFragment",
        "selections": [
            (v3 /*: any*/)
        ],
        "type": "Node",
        "abstractKey": "__isNode"
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleShiftClashDrawerQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftClashGroupList_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleShiftClashDrawerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ClashGroup",
                    "kind": "LinkedField",
                    "name": "currentScheduleShiftClashes",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "clashes",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isICanBeUsedToCalculateScheduleTimes"
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/)
                                    ],
                                    "type": "PlannedAbsence",
                                    "abstractKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v8 /*: any*/),
                                        (v6 /*: any*/)
                                    ],
                                    "type": "ScheduleShift",
                                    "abstractKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ScheduleShift",
                                            "kind": "LinkedField",
                                            "name": "scheduleShift",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                (v8 /*: any*/),
                                                (v6 /*: any*/),
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "type": "ScheduleShiftAssigneeReplacement",
                                    "abstractKey": null
                                },
                                (v9 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "assignedEntity",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "firstname",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "lastname",
                                                            "storageKey": null
                                                        },
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "SchedulableUser",
                                            "abstractKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Asset",
                                                    "kind": "LinkedField",
                                                    "name": "asset",
                                                    "plural": false,
                                                    "selections": (v7 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "SchedulableAsset",
                                            "abstractKey": null
                                        },
                                        (v9 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "40a3be6f5dfdf5ea9cc81fbd2ca0d74f",
            "id": null,
            "metadata": {},
            "name": "ScheduleShiftClashDrawerQuery",
            "operationKind": "query",
            "text": "query ScheduleShiftClashDrawerQuery(\n  $scheduleShiftClashGroupListModel: GetRunningScheduleRequestInput!\n) {\n  ...ScheduleShiftClashGroupList_query_3OciD3\n}\n\nfragment ScheduleShiftClashCard_clashGroup on ClashGroup {\n  ...ScheduleShiftClashList_clashGroup\n  clashes {\n    __typename\n    assignedEntity {\n      __typename\n      ... on SchedulableUser {\n        user {\n          firstname\n          lastname\n          id\n        }\n      }\n      ... on SchedulableAsset {\n        asset {\n          name\n          id\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment ScheduleShiftClashGroupList_query_3OciD3 on Query {\n  currentScheduleShiftClashes(model: $scheduleShiftClashGroupListModel) {\n    ...ScheduleShiftClashCard_clashGroup\n  }\n}\n\nfragment ScheduleShiftClashListItem_iCanBeUsedToCalculateScheduleTimesConnection on ICanBeUsedToCalculateScheduleTimes {\n  __isICanBeUsedToCalculateScheduleTimes: __typename\n  __typename\n  ... on PlannedAbsence {\n    id\n    assignmentType {\n      title\n      id\n    }\n    end\n    start\n  }\n  ... on ScheduleShift {\n    id\n    assignmentType {\n      title\n      id\n    }\n    end\n    schedule {\n      name\n      id\n    }\n    start\n  }\n  ... on ScheduleShiftAssigneeReplacement {\n    id\n    scheduleShift {\n      assignmentType {\n        title\n        id\n      }\n      end\n      schedule {\n        name\n        id\n      }\n      start\n      id\n    }\n  }\n}\n\nfragment ScheduleShiftClashList_clashGroup on ClashGroup {\n  clashes {\n    __typename\n    ...ScheduleShiftClashListItem_iCanBeUsedToCalculateScheduleTimesConnection\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "e6d397c46b445c2b3d6fdd0f191b8667";
export default node;
