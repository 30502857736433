import React, { useEffect, useState } from 'react';
import { Flexbox, Icon, LightBox } from '@sonika-se/ui-kit/components';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay/hooks';
import { createUseStyles } from 'react-jss';
import { useApi } from '@sonika-se/ui-kit/hooks';
import { blobToDataURL } from '@sonika-se/ui-kit/utils/misc';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
const useStyles = createUseStyles((theme) => {
    return {
        avatarImage: {
            height: '22rem',
            width: '22rem',
            borderRadius: '.25rem',
            [`@media (max-width: ${theme.breakPoints.low})`]: {
                height: 'calc(100vw - 3rem)',
                width: 'calc(100vw - 3rem)',
            },
        },
    };
});
const UserImageLightBox = (props) => {
    const { onClose, parent } = props;
    const [image, setImage] = useState('');
    const intl = useIntl();
    const classes = useStyles();
    const { imageUrl } = useFragment(graphql `
      fragment UserImageLightBox on Avatar {
        imageUrl: url(queryTransform: "width=400&height=400&mode=crop&scale=both")
      }
    `, parent);
    const { fetch } = useApi();
    useEffect(() => {
        if (imageUrl) {
            fetch(imageUrl)
                .then((response) => response.blob())
                .then((blob) => blobToDataURL(blob))
                .then((dataUrl) => {
                setImage(dataUrl);
            });
        }
        else {
            setImage(undefined);
        }
    }, [imageUrl]);
    return (<LightBox onClose={() => onClose()} closeLabel={intl.formatMessage({ id: 'general.actions.close', defaultMessage: 'Stäng' })}>
      <object data={image} type="image/png" className={classes.avatarImage}>
        <Flexbox align="center" justify="center" style={{ height: '100%', fontSize: '2rem' }}>
          <Icon icon={faSpinner} spin/>
        </Flexbox>
      </object>
    </LightBox>);
};
export default UserImageLightBox;
